import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import {
  BASE_URL,
  BASE_URL_HEALTH,
  FOUNDATION_URL,
  MISC_URL,
  LEADS_URL,
  EQUITAS_URL,
  ACL_URL,
  LIFE_URL,
  PRODUCT_URL,
} from 'Constants'
import { IApiResponse } from 'Models/ResponseModels'
import { Navigate } from 'react-router-dom'
import { dispatchLogoutActions } from 'Utils'
import { store } from '../app/store'
import { authenticationApi } from './API/authentication.api'
import { ToastService } from './toast.service'

export const getDefaultHeaders = (token?: string) => {
  const tempHeaders = { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' }
  if (token) {
    return {
      ...tempHeaders,
      Authorization: `Token ${token}`,
    }
  }
  return tempHeaders
}

export const getDefaultHeaders2 = ({ token, baseUrl }: { token: string; baseUrl?: string }) => {
  const tempHeaders: any = { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' }
  if (token) {
    tempHeaders['Authorization'] = `Token ${token}`
    return tempHeaders
  }
  return tempHeaders
}

export const getFileHeaders = (token: string) => {
  const tempHeaders = { 'Content-Type': 'multipart/form-data', Source: 'Web', Lang: 'EN' }
  if (token) {
    return {
      ...tempHeaders,
      Authorization: `Token ${token}`,
    }
  }
  return tempHeaders
}

export const axiosBaseQuery =
  ({
    baseUrl,
  }: {
    baseUrl: string
  }): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      body?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
      responseType?: AxiosRequestConfig['responseType']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body, params, headers, responseType }) => {
    const token = store.getState().user.authenticatedUser?.authtoken
    const dispatch = store.dispatch
    const navigate = Navigate
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers: headers ?? getDefaultHeaders(token),
        responseType,
      })
      if (result.data.status.toString() !== '200' && result.data.status.toString() !== '201') {
        if (result.data.message) {
          // toast.error(result.data.message)
          console.log(result.data.message)
        }
        throw result.data
      }
      return { data: result.data.data }
    } catch (axiosError) {
      if (axios.isAxiosError(axiosError)) {
        const err = axiosError as AxiosError
        ToastService.showErrorToast({
          title: 'Error',
          message: (axiosError.response?.data as Error).message,
        })
        if (err.response?.status === 401 || err.response?.status === 403) {
          // dispatch(authenticationApi.endpoints.logout.initiate(null))
          dispatchLogoutActions(dispatch)
        }

        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      } else {
        const err = axiosError as IApiResponse
        return {
          error: {
            status: err.status,
            data: err.data,
            message: err.message,
          },
        }
      }
    }
  }

const baseUrlQuery = axiosBaseQuery({
  baseUrl: BASE_URL,
})

const baseUrlHealthQuery = axiosBaseQuery({
  baseUrl: BASE_URL_HEALTH,
})

// const equitasUrlQuery = axiosBaseQuery({
//   baseUrl: FOUNDATION_URL,
// })

const foundationUrlQuery = axiosBaseQuery({
  baseUrl: FOUNDATION_URL,
})

const equitasUrlQuery = axiosBaseQuery({
  baseUrl: EQUITAS_URL,
})

const miscUrlQueryWrapper = axiosBaseQuery({
  baseUrl: MISC_URL,
})

const leadsUrlQuery = axiosBaseQuery({
  baseUrl: LEADS_URL,
})

const aclUrlQuery = axiosBaseQuery({
  baseUrl: ACL_URL,
})

const lifeUrlQuery = axiosBaseQuery({
  baseUrl: LIFE_URL,
})

const productUrlQuery = axiosBaseQuery({
  baseUrl: PRODUCT_URL,
})

export {
  baseUrlQuery,
  foundationUrlQuery,
  baseUrlHealthQuery,
  miscUrlQueryWrapper,
  equitasUrlQuery,
  leadsUrlQuery,
  aclUrlQuery,
  lifeUrlQuery,
  productUrlQuery,
}
