import React, { useEffect, useState } from 'react'
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Button,
} from '@chakra-ui/react'
import SachetsApplicantForm from './SachetsApplicantForm'
import SachetsNomineeForm from './SachetsNomineeForm'
import { useAppSelector } from 'app/hooks'
import SachetsSelectPlanForm from './SachetsSelectPlanForm'
import { Select } from 'chakra-react-select'
import CancerCoverForm from './CancerCoverForm'
import SachetsApplicantFormSpouse from './SachetsApplicantFormSpouse'

const SachetsForm = ({
  selectedProductData,
  selectedAccount,
  otherCustomerDetails,
  fullCustomerDetails,
}: {
  selectedProductData: any
  selectedAccount: any
  otherCustomerDetails: any
  fullCustomerDetails: any
}) => {
  const [index, setIndex] = useState(0)
  const [ApplicantAddress, setApplicantAddress] = useState<{
    address1: string
    address2: string
    address3: string
  }>({
    address1: '',
    address2: '',
    address3: '',
  })

  const [formAccordianState, setFormAccordianState] = useState({
    accordian1: false,
    accordian2: true,
    accordian3: true,
  })

  const productCode = selectedProductData?.code

  const [isProceed, setIsProceed] = useState(false)
  const allUserData = useAppSelector((state) => state.sachetDataDetails)

  const getVariant = selectedProductData && selectedProductData?.get_variant
  const FormLength = getVariant == true ? 3 : 2

  const [formData, setFormData] = useState(-1)
  const [blockEdit, setBlockEdit] = useState(false)

  return (
    <Flex w='full' justifyContent='center' alignItems='center' direction='column'>
      {productCode !== 'CIC' && (
        <Accordion index={[index]} allowMultiple w='full'>
          <AccordionItem
            bgColor='white'
            mb='4'
            borderRadius='10px'
            border='1px'
            p='1'
            isDisabled={index != 0}
          >
            <h2>
              <AccordionButton onClick={() => setIndex(0)}>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontWeight='bold'>1. Applicant Details</Text>
                </Box>
                <Text fontSize='12px' color='gray.500'>
                  Step 1 of {FormLength}
                </Text>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {/* {productCode !== 'CIC' && (
              <SachetsApplicantForm
                selectedProductData={selectedProductData}
                index={index}
                setIndex={setIndex}
                setApplcantAddress={setApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setFormAccordianState={setFormAccordianState}
                setIsProceed={setIsProceed}
              />
            )}
            {productCode == 'CIC' && (
              <CancerCoverForm/>
             
            )} */}
              <SachetsApplicantForm
                selectedProductData={selectedProductData}
                index={index}
                setIndex={setIndex}
                setApplcantAddress={setApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setFormAccordianState={setFormAccordianState}
                setIsProceed={setIsProceed}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor='white'
            mb='4'
            borderRadius='10px'
            border='1px'
            p='1'
            isDisabled={index != 1}
          >
            <h2>
              <AccordionButton
                onClick={(e) => {
                  setIndex(1)
                }}
              >
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontWeight='bold'>2. Nominee Details</Text>
                </Box>

                <Flex justifyContent='center' alignItems='center' gap='4'>
                  <Button
                    py='1'
                    px='3'
                    fontSize='12px'
                    onClick={(e) => {
                      setIndex(0)
                      e.stopPropagation()
                    }}
                    variant='link'
                  >
                    Back to Applicant Form
                  </Button>
                  <Text fontSize='12px' color='gray.500'>
                    Step 2 of {FormLength}
                  </Text>
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <SachetsNomineeForm
                setIndex={setIndex}
                getVariant={getVariant}
                ApplicantAddress={ApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setIsProceed={setIsProceed}
                isProceed={isProceed}
                setFormAccordianState={setFormAccordianState}
              />
            </AccordionPanel>
          </AccordionItem>
          {getVariant && (
            <>
              <AccordionItem
                bgColor='white'
                mb='4'
                borderRadius='10px'
                border='1px'
                p='1'
                isDisabled={index != 2}
              >
                <h2>
                  <AccordionButton
                    onClick={(e) => {
                      setIndex(2)
                    }}
                  >
                    <Box as='span' flex='1' textAlign='left'>
                      <Text fontWeight='bold'>3. Select Plan</Text>
                    </Box>

                    <Flex justifyContent='center' alignItems='center' gap='4'>
                      <Button
                        py='1'
                        px='3'
                        fontSize='12px'
                        onClick={(e) => {
                          setIndex(1)
                          e.stopPropagation()
                        }}
                        variant='link'
                        disabled={blockEdit}
                      >
                        Back to Nominee Form
                      </Button>
                      <Text fontSize='12px' color='gray.500'>
                        Step 3 of {FormLength}
                      </Text>
                    </Flex>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <SachetsSelectPlanForm
                    setBlockEdit={setBlockEdit}
                    blockEdit={blockEdit}
                    ApplicantAddress={ApplicantAddress}
                    selectedAccount={selectedAccount}
                    otherCustomerDetails={otherCustomerDetails}
                    fullCustomerDetails={fullCustomerDetails}
                    setIsProceed={setIsProceed}
                    isProceed={isProceed}
                    selectedProductData={selectedProductData}
                  />
                </AccordionPanel>
              </AccordionItem>
            </>
          )}
        </Accordion>
      )}
      {productCode == 'CIC' && formData == -1 && <CancerCoverForm setFormData={setFormData} />}
      {formData === 0 && (
        <Accordion index={[index]} allowMultiple w='full'>
          <AccordionItem
            bgColor='white'
            mb='4'
            borderRadius='10px'
            border='1px'
            p='1'
            isDisabled={index != 0}
          >
            <h2>
              <AccordionButton onClick={() => setIndex(0)}>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontWeight='bold'>1. Applicant Details</Text>
                </Box>
                <Text fontSize='12px' color='gray.500'>
                  Step 1 of {FormLength}
                </Text>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {/* {productCode !== 'CIC' && (
            <SachetsApplicantForm
              selectedProductData={selectedProductData}
              index={index}
              setIndex={setIndex}
              setApplcantAddress={setApplicantAddress}
              selectedAccount={selectedAccount}
              otherCustomerDetails={otherCustomerDetails}
              fullCustomerDetails={fullCustomerDetails}
              setFormAccordianState={setFormAccordianState}
              setIsProceed={setIsProceed}
            />
          )}
          {productCode == 'CIC' && (
            <CancerCoverForm/>
           
          )} */}
              <SachetsApplicantForm
                selectedProductData={selectedProductData}
                index={index}
                setIndex={setIndex}
                setApplcantAddress={setApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setFormAccordianState={setFormAccordianState}
                setIsProceed={setIsProceed}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor='white'
            mb='4'
            borderRadius='10px'
            border='1px'
            p='1'
            isDisabled={index != 1}
          >
            <h2>
              <AccordionButton
                onClick={(e) => {
                  setIndex(1)
                }}
              >
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontWeight='bold'>2. Nominee Details</Text>
                </Box>

                <Flex justifyContent='center' alignItems='center' gap='4'>
                  <Button
                    py='1'
                    px='3'
                    fontSize='12px'
                    onClick={(e) => {
                      setIndex(0)
                      e.stopPropagation()
                    }}
                    variant='link'
                  >
                    Back to Applicant Form
                  </Button>
                  <Text fontSize='12px' color='gray.500'>
                    Step 2 of {FormLength}
                  </Text>
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <SachetsNomineeForm
                setIndex={setIndex}
                getVariant={getVariant}
                ApplicantAddress={ApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setIsProceed={setIsProceed}
                isProceed={isProceed}
                setFormAccordianState={setFormAccordianState}
              />
            </AccordionPanel>
          </AccordionItem>
          {getVariant && (
            <>
              <AccordionItem
                bgColor='white'
                mb='4'
                borderRadius='10px'
                border='1px'
                p='1'
                isDisabled={index != 2}
              >
                <h2>
                  <AccordionButton
                    onClick={(e) => {
                      setIndex(2)
                    }}
                  >
                    <Box as='span' flex='1' textAlign='left'>
                      <Text fontWeight='bold'>3. Select Plan</Text>
                    </Box>

                    <Flex justifyContent='center' alignItems='center' gap='4'>
                      <Button
                        py='1'
                        px='3'
                        fontSize='12px'
                        onClick={(e) => {
                          setIndex(1)
                          e.stopPropagation()
                        }}
                        variant='link'
                        disabled={blockEdit}
                      >
                        Back to Nominee Form
                      </Button>
                      <Text fontSize='12px' color='gray.500'>
                        Step 3 of {FormLength}
                      </Text>
                    </Flex>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <SachetsSelectPlanForm
                    setBlockEdit={setBlockEdit}
                    blockEdit={blockEdit}
                    ApplicantAddress={ApplicantAddress}
                    selectedAccount={selectedAccount}
                    otherCustomerDetails={otherCustomerDetails}
                    fullCustomerDetails={fullCustomerDetails}
                    setIsProceed={setIsProceed}
                    isProceed={isProceed}
                    selectedProductData={selectedProductData}
                  />
                </AccordionPanel>
              </AccordionItem>
            </>
          )}
        </Accordion>
      )}
      {formData === 1 && (
        <Accordion index={[index]} allowMultiple w='full'>
          <AccordionItem
            bgColor='white'
            mb='4'
            borderRadius='10px'
            border='1px'
            p='1'
            isDisabled={index != 0}
          >
            <h2>
              <AccordionButton onClick={() => setIndex(0)}>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontWeight='bold'>1. Applicant Details (Self)</Text>
                </Box>
                <Text fontSize='12px' color='gray.500'>
                  Step 1 of 4
                </Text>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <SachetsApplicantForm
                selectedProductData={selectedProductData}
                index={index}
                setIndex={setIndex}
                setApplcantAddress={setApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setFormAccordianState={setFormAccordianState}
                setIsProceed={setIsProceed}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor='white'
            mb='4'
            borderRadius='10px'
            border='1px'
            p='1'
            isDisabled={index != 1}
          >
            <h2>
              <AccordionButton onClick={() => setIndex(0)}>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontWeight='bold'>2. Applicant Details (Spouse)</Text>
                </Box>
                <Flex justifyContent='center' alignItems='center' gap='4'>
                  <Button
                    py='1'
                    px='3'
                    fontSize='12px'
                    onClick={(e) => {
                      setIndex(0)
                      e.stopPropagation()
                    }}
                    variant='link'
                  >
                    Back to Applicant Form (Self)
                  </Button>
                  <Text fontSize='12px' color='gray.500'>
                    Step 2 of 4
                  </Text>
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <SachetsApplicantFormSpouse
                selectedProductData={selectedProductData}
                index={index}
                setIndex={setIndex}
                setApplcantAddress={setApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setFormAccordianState={setFormAccordianState}
                setIsProceed={setIsProceed}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor='white'
            mb='4'
            borderRadius='10px'
            border='1px'
            p='1'
            isDisabled={index != 2}
          >
            <h2>
              <AccordionButton
                onClick={(e) => {
                  setIndex(1)
                }}
              >
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontWeight='bold'>3. Nominee Details</Text>
                </Box>

                <Flex justifyContent='center' alignItems='center' gap='4'>
                  <Button
                    py='1'
                    px='3'
                    fontSize='12px'
                    onClick={(e) => {
                      setIndex(1)
                      e.stopPropagation()
                    }}
                    variant='link'
                  >
                    Back to Applicant Form (Spouse)
                  </Button>
                  <Text fontSize='12px' color='gray.500'>
                    Step 3 of 4
                  </Text>
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <SachetsNomineeForm
                cancerCoverType='2'
                setIndex={setIndex}
                getVariant={getVariant}
                ApplicantAddress={ApplicantAddress}
                selectedAccount={selectedAccount}
                otherCustomerDetails={otherCustomerDetails}
                fullCustomerDetails={fullCustomerDetails}
                setIsProceed={setIsProceed}
                isProceed={isProceed}
                setFormAccordianState={setFormAccordianState}
              />
            </AccordionPanel>
          </AccordionItem>
          {getVariant && (
            <>
              <AccordionItem
                bgColor='white'
                mb='4'
                borderRadius='10px'
                border='1px'
                p='1'
                isDisabled={index != 3}
              >
                <h2>
                  <AccordionButton
                    onClick={(e) => {
                      setIndex(2)
                    }}
                  >
                    <Box as='span' flex='1' textAlign='left'>
                      <Text fontWeight='bold'>4. Select Plan</Text>
                    </Box>

                    <Flex justifyContent='center' alignItems='center' gap='4'>
                      <Button
                        py='1'
                        px='3'
                        fontSize='12px'
                        onClick={(e) => {
                          setIndex(2)
                          e.stopPropagation()
                        }}
                        variant='link'
                        disabled={blockEdit}
                      >
                        Back to Nominee Form
                      </Button>
                      <Text fontSize='12px' color='gray.500'>
                        Step 4 of 4
                      </Text>
                    </Flex>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <SachetsSelectPlanForm
                    setBlockEdit={setBlockEdit}
                    blockEdit={blockEdit}
                    ApplicantAddress={ApplicantAddress}
                    selectedAccount={selectedAccount}
                    otherCustomerDetails={otherCustomerDetails}
                    fullCustomerDetails={fullCustomerDetails}
                    setIsProceed={setIsProceed}
                    isProceed={isProceed}
                    selectedProductData={selectedProductData}
                  />
                </AccordionPanel>
              </AccordionItem>
            </>
          )}
        </Accordion>
      )}
    </Flex>
  )
}

export default SachetsForm
