import { Box, BoxProps, FormErrorMessage, Text } from '@chakra-ui/react'
import { Select, SingleValue } from 'chakra-react-select'
import { ElementWithLabel, ErrorText } from 'Core'

import React, { useEffect, useState } from 'react'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
// import { getDropdownArrayFromRecord } from 'Services/general'
import RenderChild from './RenderChild'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import {
  calculateAgeFromDob,
  getAgeObjFromDob2,
  getDropdownArrayFromRecord,
} from 'Services/general'
import axios, { AxiosResponse } from 'axios'
import { getDefaultHeaders2 } from 'Services/baseQuery'
import { store } from 'app/store'
import { IApiResponse2 } from 'Models/ResponseModels'
import { PRODUCT_URL } from 'Constants'
import { useAppSelector } from 'app/hooks'

interface IProps extends BoxProps {
  isRoot: boolean
  parentData: { name: string; value: string | number }
  name: string
  dropdownItems: Record<string, string | number>
  childrenData?: Record<string, INestedQuestionSet[]>
  form: UseFormReturn<any, any>
  // isDefaultSelected?: boolean
  value?: { label: string; value: string | number }
  label?: string
  initialValues?: Record<string, string | number | boolean | string[]>
  cover_type?: INestedQuestionSet['cover_type']
  factor?: number
  operation?: string
  affect?: string
  isWithoutLabel?: boolean
  min?: number | null
  max?: number | null
  isDisabled?: boolean
  defaultValue?: string
  onAffect?: (name: string, value: string | number, affect: string, factor?: number) => void
  disabledCodes?: string[]
  required?: boolean
  // url?: string
  call_api?: boolean
}

const RenderDropdown = (props: IProps) => {
  const {
    name,
    dropdownItems,
    childrenData,
    form,
    isRoot,
    parentData,
    // isDefaultSelected = false,
    value,
    label,
    initialValues,
    cover_type,
    affect,
    onAffect,
    isWithoutLabel,
    defaultValue,
    isDisabled = false,
    disabledCodes,
    factor,
    min,
    max,
    operation,
    call_api,
    required,
    ...rest
  } = props

  // useEffect(() => {
  //   console.log(call_api)
  //   console.log(required)
  //   console.log(name)
  // }, [required])

  const { businessrole_name, id } = useAppSelector((state) => state.user.authenticatedUser)
  const { selectedSp } = useAppSelector((state) => state.spTagging)
  const [customError, setCustomError] = useState('')
  const [apiRequired, setApiRequired] = useState(false)
  const [dropdownArray, setDropdownArray] = useState<
    {
      label: string
      value: string | number
    }[]
  >([])

  const [dropdownValue, setDropdownValue] = useState<{
    label: string
    value: string | number
  } | null>(value ? value : null)

  const updateListing = async () => {
    if (call_api) {
      const insurer = store.getState().lifeQuotationDetails.finalSelectedPlan?.insurer
      const insurerProductCode = store.getState().lifeQuotationDetails.finalSelectedPlan?.code
      const dob = store.getState().lifeCustomerDetails.lifeAssuredDetailsTyped?.dob
      const lead_id = store.getState().leadDetails.lead_uid
      const age = getAgeObjFromDob2(new Date(dob ?? ''))
      try {
        const resp: AxiosResponse<
          IApiResponse2<{
            values: Record<string, string>
            default: string
            is_disabled: boolean
            field_validation: {
              min: string | number
              max: string | number
              req_fields?: string[]
              error_msg?: string
            }
          }>
        > = await axios({
          url: `${PRODUCT_URL}/product/get-choices/${insurer}/${insurerProductCode}/${name}/`,
          method: 'POST',
          data: {
            ...form.getValues(),
            entry_age: Math.floor(age),
            lead_id: lead_id,
            is_same:
              store.getState().lifeQuotationDetails.relationship?.toLowerCase() === 'self'
                ? true
                : false,
            agent_id: businessrole_name === 'SP' ? id?.toString() : selectedSp?.id?.toString(),
          },
          headers: getDefaultHeaders2({
            token: store.getState().user.authenticatedUser.authtoken ?? '',
          }),
        })

        const fieldValidationData = resp.data.data?.field_validation
        setApiRequired(resp.data.data.required)
        if (fieldValidationData) {
          if (fieldValidationData?.error_msg) {
            setCustomError(fieldValidationData?.error_msg)
            // await form.trigger(name)
          } else {
            setCustomError('')
          }
        }

        if (resp.data.message?.toLowerCase() !== 'success') {
          throw Error('Show default values!')
        }
        // const listingData = getKeyValueObjectFromArray(resp.data.data?.values ?? [])
        const listingData = resp.data.data?.values
        if (listingData) setDropdownArray(getDropdownArrayFromRecord(listingData))
      } catch (e) {
        console.log('Unable to get data from url!!')
        setDropdownArray(getDropdownArrayFromRecord(dropdownItems))
      }
    } else {
      setDropdownArray(getDropdownArrayFromRecord(dropdownItems))
    }
  }

  useEffect(() => {
    updateListing()
  }, [call_api, parentData.value])

  const handleChange = async (
    newVal: SingleValue<{
      label: string
      value: string | number
    }>,
  ) => {
    form.setValue(name, newVal?.value)
    form.clearErrors(name)
    setDropdownValue({ label: newVal?.label ?? '', value: newVal?.value ?? '' })

    // console.log(affect, onAffect)
    if (affect && onAffect) {
      onAffect(name, newVal?.value ?? '', affect, factor)
    }
  }

  useEffect(() => {
    return () => {
      form.unregister(name)
    }
  }, [])

  useEffect(() => {
    if (
      dropdownArray.length &&
      dropdownValue &&
      dropdownArray.findIndex(
        (item) => item.value.toString() === dropdownValue?.value.toString(),
      ) === -1
    ) {
      if (name !== 'entry_age') {
        setDropdownValue(null)
      }
    }
  }, [dropdownArray])

  /**
   * This will set the value of child dropdown (first index value in dropdown array) if parent value changes
   */
  // useEffect(() => {
  //   if (dropdownValue && !dropdownArray.includes(dropdownValue)) {
  //     handleChange(dropdownArray[0])
  //   }
  // }, [parentData?.value])

  /**
   * This will clear the value of child dropdown if parent value changes
   */
  useEffect(() => {
    if (name !== 'entry_age') {
      form.resetField(name)
      setDropdownValue(null)
    }
  }, [parentData?.value])

  /**
   * This will set the initial value of dropdown as the first index value in dropdown array
   */
  // useEffect(() => {
  //   if (isDefaultSelected) {
  //     handleChange(dropdownArray[0])
  //   }
  // }, [])

  /**
   * This will set the initial value of dropdown as given in the `default` key if it exists in dropdown array
   */
  useEffect(() => {
    if (defaultValue !== undefined) {
      const newValue:
        | SingleValue<{
            label: string
            value: string | number
          }>
        | undefined = dropdownArray.find(
        (item) => item.value?.toString() === defaultValue?.toString(),
      )
      if (newValue) {
        handleChange(newValue)
      }
    }
  }, [])

  // useEffect(() => {
  //   if (initialVal !== undefined) {
  //     console.log('initialVal')
  //     console.log(initialVal)
  //     console.log('initialVal')

  //     const newValue:
  //       | SingleValue<{
  //           label: string
  //           value: string | number
  //         }>
  //       | undefined = dropdownArray.find((item) => item.label === initialVal)
  //     if (newValue) {
  //       handleChange(newValue)
  //     }
  //   }
  // }, [initialVal])

  useEffect(() => {
    if (initialValues && initialValues[name] != null) {
      // if (name === 'wop_cover' && dropdownArray) {
      // }
      const newValue:
        | SingleValue<{
            label: string
            value: string | number
          }>
        | undefined = call_api
        ? name === 'wop_cover' && dropdownArray.length > 0
          ? dropdownArray.find((item) => item.value === initialValues[name].toString())
          : { label: initialValues[name].toString(), value: initialValues[name].toString() }
        : dropdownArray.find((item) => item.value.toString() === initialValues[name].toString())
      if (newValue) {
        handleChange(newValue)
      }
    }
  }, [dropdownArray])

  return (
    <>
      <ElementWithLabel
        required={call_api ? apiRequired : required}
        marginY='0.7rem'
        height='fit-content'
        label={isWithoutLabel ? '' : label || name}
        {...rest}
      >
        <Box fontWeight={'normal'}>
          <Select
            focusBorderColor='primary.300'
            colorScheme='primary'
            isSearchable={false}
            menuPosition='absolute'
            useBasicStyles
            onFocus={updateListing}
            options={
              operation === 'multiply_each_value_with_premium'
                ? dropdownArray.map((dropdown) => {
                    return {
                      label: (
                        parseInt(dropdown.label) * parseInt(form.getValues('annualPremium'))
                      ).toString(),
                      value: (
                        parseInt(dropdown.label) * parseInt(form.getValues('annualPremium'))
                      ).toString(),
                    }
                  })
                : operation === 'upper_limit_parent_subtract_factor'
                ? dropdownArray.filter(
                    (dropdown) =>
                      parseInt(dropdown.label) >= parseInt(parentData.value.toString()) - factor!,
                  )
                : dropdownArray
            }
            // options={dropdownArray.filter(
            //   (item) =>
            //     min &&
            //     max &&
            //     parseInt(item.value.toString()) < min &&
            //     parseInt(item.value.toString()) > max,
            // )}
            selectedOptionStyle='color'
            selectedOptionColor='primary'
            value={dropdownValue}
            isDisabled={
              isDisabled ||
              (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false)
            }
            {...form.register(name, {
              required: call_api ? apiRequired : required,
              onBlur: () => updateListing(),
            })}
            onChange={handleChange}
          />
        </Box>
        {form.formState.errors[name] && <ErrorText text={`${label} is required!`} />}
      </ElementWithLabel>
      {/* BASECASES -
       If childrenData(values key in response) is empty
      OR
        parent dropdown doesn't have selected value
      OR 
        if childrenData doesn't have that value key which is selected in parent dropdown */}
      {childrenData &&
        Object.keys(childrenData).length > 0 &&
        dropdownValue?.value &&
        childrenData[dropdownValue.value] &&
        childrenData[dropdownValue.value].map((item, index) => {
          return (
            <RenderChild
              key={index}
              childField={item}
              form={form}
              parentData={{
                name: name,
                value: dropdownValue.value,
                cover_type: cover_type,
              }}
              defaultValue={item.default}
              initialValues={initialValues}
              factor={item.factor}
              operation={item.operation}
              affect={item.affect}
              onAffect={onAffect}
              disabledCodes={disabledCodes}
              required={item.required}
            />
          )
        })}
    </>
  )
}

export default RenderDropdown
