import { CloseIcon } from '@chakra-ui/icons'
import {
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ElevatedButton } from 'Core/ElevatedButton'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import { BsFileEarmarkBinaryFill } from 'react-icons/bs'
interface IFileUploadProps {
  uploadDesc?: string
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  isLoading?: boolean
  fileName?: string
  imagePreviewUrl?: string
  isImage?: boolean
  handleDelete?: () => void
}

/**
 * @depends react-dropzone(https://www.npmjs.com/package/react-dropzone)
 * @param IFileUploadProps
 */
export const FileUpload: React.FC<IFileUploadProps> = (props: IFileUploadProps) => {
  const {
    uploadDesc = 'Drag and drop here or upload image/pdf',
    getRootProps,
    getInputProps,
    isLoading = false,
    fileName,
    imagePreviewUrl,
    isImage = false,
    handleDelete,
  } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex
        direction='column'
        alignItems='center'
        border='1px'
        borderStyle='dashed'
        borderColor='grey'
        borderRadius='lg'
        maxW='250'
        padding='2'
        {...getRootProps()}
        aria-label='drag and drop area'
        position='relative'
      >
        {fileName && handleDelete && (
          <CloseIcon
            cursor='pointer'
            onClick={(e) => {
              handleDelete()
              e.stopPropagation()
            }}
            width='0.7rem'
            position='absolute'
            right='2'
          />
        )}
        <Icon marginTop='3' w='6' h='6' as={BsFileEarmarkBinaryFill} />
        <Text textAlign='center' padding='2' fontSize='sm' color='grey'>
          {uploadDesc}
        </Text>
        <input {...getInputProps()} />
        {!isImage && fileName && (
          <Text
            wordBreak='break-word'
            paddingY='2'
            fontSize='sm'
            fontWeight='bold'
            color='secondary.800'
          >
            {fileName}
          </Text>
        )}
        {isImage && imagePreviewUrl && (
          // <ElevatedButton
          //   onClick={(e) => {
          //     onOpen()
          //     e.stopPropagation()
          //   }}
          //   color='secondary.800'
          //   variant='link'
          //   size='sm'
          //   marginBottom='2'
          // >
          //   {fileName ?? ''}
          // </ElevatedButton>
          <Text
            onClick={(e) => {
              onOpen()
              e.stopPropagation()
            }}
            wordBreak='break-word'
            fontSize='sm'
            color='secondary.800'
            as='u'
            marginBottom='2'
          >
            {fileName ?? ''}
          </Text>
        )}
        <ElevatedButton isLoading={isLoading} size='sm' marginBottom='3'>
          UPLOAD FILE
        </ElevatedButton>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={imagePreviewUrl} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
