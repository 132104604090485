import { Box, Center, Flex, Spinner, Text, Button } from '@chakra-ui/react'
import { Header } from 'Components'

import { useEffect, useState } from 'react'
import { FilterDialog } from './FilterDialog'
import { FilterSideBar } from './FilterSideBar'
import { QuoteItemCard } from './QuoteItemCard'
import { useLifeQuotation } from './useLifeQuotation'
import { ToastService } from 'Services/toast.service'
import { If } from 'Core'
import { BbButton } from 'Core/BbButton'
import { useNavigate } from 'react-router-dom'
import { useCreateConsentDataMutation, useGetBiQuoteMutation } from 'Services/API/life.api'
import {
  updateConsentId,
  updatePlanQuoteId,
  updateRedirectionURL,
} from 'features/Life/LifeQuotationDetails.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { copyToClipboard, decryptData, getAccountTypeBasedOnIndex } from 'Services/general'
import { getAccountStatus, getCountryName } from 'Constants'
import { useLazyGetGeoDataQuery } from 'Services/API/motor.api'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ISaveLeadResponse } from 'Models/ResponseModels'

const LifeQuotation = () => {
  const {
    selected_plan,
    //  onProceedToProposal,
    ridersQuestions,
    isGetQuotationsApiLoading,
    planQuoteResponse,
    // onQuickBuy,
    // relationship,
    // isProposalSubmissionLoading,
    // isCreateProposalLoading,
    // isGetSendConsentLoading,
    // isQuickBuy,
    isBiFormGenerated,
    toggleIsBiFormGenerated,
  } = useLifeQuotation()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [cityName, setCityName] = useState('')
  const navigate = useNavigate()
  const [callBiQuote, { isLoading }] = useGetBiQuoteMutation()
  const [generateConsent, { isLoading: generatingConsent }] = useCreateConsentDataMutation()
  const [saveLeads, { isError }] = useSaveLeadsMutation()
  const [getCity] = useLazyGetGeoDataQuery()

  const dispatch = useAppDispatch()
  const customerDetails = useAppSelector((state) => state.lifeCustomerDetails)
  const customerProposerDetails = useAppSelector((state) => state.customerDetails)
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)
  const filterData = useAppSelector((state) => state.planData)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const spTaggingDetails = useAppSelector((state) => state.spTagging)

  useEffect(() => {
    if (customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntCty)
      getCity({
        city_id: parseInt(
          customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntCty,
        ),
      })
        .unwrap()
        .then((response) => {
          setCityName(response.data)
        })
    dispatch(updateRedirectionURL(''))
  }, [])

  const createLead = async () => {
    const payload: ISaveLead = formParam('Quotation', 'life')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  useEffect(() => {
    if (filterData) {
      if (lifeQuotationDetails.consent_status === 'UNAPPROVED') {
        createLead()
      }
    }
  }, [filterData])

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      premium: filterData.planQuoteResponse?.premium,
      insurer_name: lifeQuotationDetails.finalSelectedPlan?.insurer?.toUpperCase() ?? '-',
      lead_data: {
        // cart_details: cart_details,
        // member_details: memberDetails,
        filterData: filterData,
        lifeCustomerDetails: customerDetails,
        lifeQuotationDetails: lifeQuotationDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: spTaggingDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createConsent = async () => {
    try {
      ToastService.showLoadingToast({
        title: 'Generating Customer Consent Form Link!',
      })
      const response = await generateConsent({
        data: generateConsentData(),
        lead_id: leadDetails.lead_uid ?? '',
        plan_id: lifeQuotationDetails.finalSelectedPlan?.id ?? '',
        quote_id: planQuoteResponse?.quote_id ?? '',
        type: 'BI-APPROVAL',
      }).unwrap()
      dispatch(updateConsentId(response.consent_id))
      navigate('/consent-pending')
    } catch (e: any) {
      console.log(e)
      ToastService.closeAllToast()
      ToastService.showErrorToast({
        title: 'Error',
        message: e?.data?.message ?? 'Something went wrong',
      })
      console.log(e)
    }
  }

  //   "data": {
  //     "selectedProductName":"name",
  //     "email":"",
  //     "downloadBrochure":"https://api.iciciprulife.com/quote/pdf//EBI/KFD_New01082023175747236_E35.pdf",
  //     "downloadBusinessIllustration":"https://api.iciciprulife.com/quote/pdf//EBI/KFD_New01082023175747236_E35.pdf",
  //     "policyData":{
  //         "logo":"https://insuranceuat.equitasbank.com/foundation/foundation/media/RRqhEwJB9cRgAEfIi24mYg==",
  //         "name":"ICICI Prudential GIFT Long Term",
  //         "sumInsured":"",
  //         "policyFor":"",
  //         "pincode":"",
  //         "finalPremium":"",
  //         "paymentMode":"",
  //         "paymentTerm":"",
  //         "policyTerm":"",
  //         "planOption":"",
  //         "paymentMethod":"",
  //         "proposalNumber":"",
  //         "proposalData":""
  //     },
  //     "customerAccountDetails": {
  //         "Account Lead": "12323",
  //         "Account Title": "Name",
  //         "first name":""
  //     },
  //     "intermediaryDetails": {
  //         "LG Code": "C0WS",
  //         "LG Name": "Name"
  //     },
  //     "proposerDetails": {
  //         "Salutation": "Mr.",
  //         "First Name": "Name"
  //     },
  //     "insuredDetails": {
  //         "Salutation": "Mr.",
  //         "First Name": "Name"
  //     },
  //     "nomineeDetails": {
  //         "Nominee Relationship": "Self",
  //         "Nominee Name": "Name"
  //     },
  //     "suitabilityAnalysis": {
  //         "Customer Age": "23"
  //     },
  //     "recommendedProducts": ["Product 1 Name", "Product 2 Name"],
  //     "productDetails": {
  //         "Proposal Number": "1243254",
  //         "Plan Name": "Name"
  //     },
  //     "addRiders": [
  //         {
  //             "name": "Rider 1",
  //             "suminsured": "200000",
  //             "premium": "20000",
  //             "paymentTermOption": "Manual",
  //             "riderTerm": "",
  //             "riderPaymentTerm": ""
  //         }
  //     ]
  // },

  const getMaritalStatus = (data: string) => {
    switch (data) {
      case '1':
        return 'Unmarried'
      case '2':
        return 'Married'
      case '3':
        return 'Divorced'
      case '4':
        return 'Widow(er)'
      default:
        return '-'
    }
  }

  const getAnnualIncome = (data: string) => {
    switch (data) {
      case '1':
        return '0 - 50K'
      case '2':
        return '50K - 1L'
      case '3':
        return '1L - 2.5L'
      case '4':
        return '2.5L - 5L'
      case '5':
        return '5L - 7.5L'
      case '6':
        return '7.5L - 10L'
      case '7':
        return '10L - 15L'
      case '8':
        return '15L - 20L'
      case '9':
        return '20L - 30L'
      case '10':
        return '30L - 50L'
      case '11':
        return '50L - 1C'
      case '12':
        return '10000001 to 9999999999'
      default:
        return '-'
    }
  }

  const generateConsentData = () => {
    return {
      selectedProductName: planQuoteResponse?.plan_name,
      email: '',
      downloadBrochure: planQuoteResponse?.brochure_url,
      downloadBusinessIllustration: planQuoteResponse?.bi_url,
      policyData: {
        logo: planQuoteResponse?.logo,
        name: planQuoteResponse?.plan_name,
        proposalDate: new Date().toDateString(),
        finalPremium: `₹ ${parseInt(planQuoteResponse?.premium?.toString() ?? '').toLocaleString(
          'en-IN',
        )}`,
        sumInsured: `₹ ${parseInt(planQuoteResponse?.sum_assured?.toString() ?? '').toLocaleString(
          'en-IN',
        )}`,
        policyFor: customerDetails.relationship,
        pincode: customerDetails.lifeAssuredDetailsTyped?.pincode,
        paymentMode:
          filterData.filter && filterData.filter['premium_frequency']
            ? filterData.filter['premium_frequency']
            : '-',
        paymentTerm:
          filterData.filter && filterData.filter['premium_paying_term']
            ? filterData.filter['premium_paying_term']
            : '-',
        planOption:
          filterData.filter && filterData.filter['premium_paying_option']
            ? filterData.filter['premium_paying_option']
            : '-',
        policyTerm:
          filterData.filter && filterData.filter['plan_term']
            ? filterData.filter['plan_term']
            : '-',
      },
      customerAccountDetails: [
        {
          label: 'Account Lead',
          value: customerProposerDetails.selectedAccount?.account_lead ?? '-',
        },
        {
          label: 'Account Title',
          value: customerProposerDetails.selectedAccount?.account_title ?? '-',
        },
        {
          label: 'Source Branch',
          value: customerProposerDetails.selectedAccount?.source_branch ?? '-',
        },
        {
          label: 'Opening Branch',
          value: customerProposerDetails.selectedAccount?.opening_branch ?? '-',
        },
        {
          label: 'Account Type',
          value: getAccountTypeBasedOnIndex(
            customerProposerDetails.selectedAccount?.account_type ?? '-',
          ),
        },
        {
          label: 'Account Number',
          value: customerProposerDetails.selectedAccount?.show_account_number ?? '-',
        },
        { label: 'Account ID', value: customerProposerDetails.selectedAccount?.account_id ?? '-' },
        {
          label: 'Account Status',
          value: getAccountStatus(customerProposerDetails.selectedAccount?.accStsCode ?? ''),
        },
        {
          label: 'IFSC Code',
          value: customerProposerDetails.selectedAccount?.ifsc_code ?? '-',
          'UCIC ID': customerProposerDetails.ucic ?? '-',
        },
      ],
      intermediaryCode: {},
      proposerDetails: [
        {
          label: 'First Name',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
              ?.IndCustDtlsRp![0].frsNm ?? '-',
        },
        {
          label: 'Middle Name',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
              ?.IndCustDtlsRp![0].mdlNm ?? '-',
        },
        {
          label: 'Last Name',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
              ?.IndCustDtlsRp![0].lstNm ?? '-',
        },
        {
          label: 'DOB',
          value: customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob ?? '-',
        },
        {
          label: 'Gender',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr ?? '-',
        },
        {
          label: 'Mobile Number',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
              .IndCustDtlsRp![0].mblNb ?? '-',
        },
        {
          label: 'Email ID',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
              .IndCustDtlsRp![0].email ?? '-',
        },
        {
          label: 'Address 1',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn1 ??
            '-',
        },
        {
          label: 'Address 2',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn2 ??
            '-',
        },
        {
          label: 'Address 3',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn3 ??
            '-',
        },
        {
          label: 'Pincode',
          value:
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntPncd ?? '-',
        },
        { label: 'City', value: cityName ?? '-' },
        {
          label: 'Nationality',
          value:
            getCountryName(
              customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.crntCntry?.toString() ??
                '-',
            ) ?? '-',
          // countryMapping[customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntCntry] ??
          // '-',
        },
        // {
        //   label: 'Annual Income',
        //   value: getAnnualIncome(
        //     customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.grossAnnlIncm ??
        //       '-',
        //   ),
        // },
        {
          label: 'Marital Status',
          value: getMaritalStatus(
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.mrtlSts ?? '-',
          ),
        },
        { label: 'Relationship with insured', value: customerDetails.relationship },
      ],
      insuredDetails: [
        { label: 'Salutation', value: customerDetails.lifeAssuredDetailsTyped?.title.name },
        { label: 'First Name', value: customerDetails.lifeAssuredDetailsTyped?.firstName ?? '-' },
        { label: 'Middle Name', value: customerDetails.lifeAssuredDetailsTyped?.middleName ?? '-' },
        { label: 'Last Name', value: customerDetails.lifeAssuredDetailsTyped?.lastName ?? '-' },
        { label: 'DOB', value: customerDetails.lifeAssuredDetailsTyped?.dob },
        { label: 'Gender', value: customerDetails.lifeAssuredDetailsTyped?.gender.name },
        { label: 'Mobile Number', value: customerDetails.lifeAssuredDetailsTyped?.mobile },
        { label: 'Email ID', value: customerDetails.lifeAssuredDetailsTyped?.email },
        { label: 'Address 1', value: customerDetails.lifeAssuredDetailsTyped?.address1 },
        { label: 'Address 2', value: customerDetails.lifeAssuredDetailsTyped?.address2 },
        { label: 'Pincode', value: customerDetails.lifeAssuredDetailsTyped?.pincode },
        // Nationality: 'Indian'},
        { label: 'Landmark', value: customerDetails.lifeAssuredDetailsTyped?.landmark ?? '-' },
        { label: 'Marital Status', value: customerDetails.lifeAssuredDetailsTyped?.marital.name },
        { label: 'Relationship with insured', value: customerDetails.relationship },
        { label: 'Is Life Insured a smoker ?', value: customerDetails.lifeAssuredSmokerStatus },
      ],
      // nomineeDetails: {},
      suitabilityAnalysis: [
        { label: 'Customer Age', value: lifeQuotationDetails.suitabilityData.age_band },
        // {
        //   label: 'Annual Income',
        //   value: lifeQuotationDetails.suitabilityData.annual_disposable_income,
        // },
        {
          label: 'Which of the following best describes your risk taking behavior ?',
          value: lifeQuotationDetails.suitabilityData.risk,
        },
        { label: 'Insurance Purpose', value: lifeQuotationDetails.suitabilityData.need },
        { label: 'Life Stage', value: lifeQuotationDetails.suitabilityData.life_stage },
        { label: 'Goal Horizon', value: lifeQuotationDetails.suitabilityData.goal_horizon },
        {
          label: 'Payment Preference',
          value: lifeQuotationDetails.suitabilityData.payment_preference,
        },
      ],
      recommendedProducts: lifeQuotationDetails.finalRecommendedProducts?.map((plan) => plan.name),
      productDetails: null,
      addRiders:
        filterData.filter && filterData.filter['additional_riders']
          ? filterData.filter['additional_riders']
          : [],
    }
  }

  // const biQuoteGeneration = async () => {
  //   try {
  //     const response = await callBiQuote({
  //       plan_id: lifeQuotationDetails.finalSelectedPlan?.id ?? '',
  //       lead_id: leadDetails.lead_uid!,
  //       quote_id: planQuoteResponse?.quote_id ?? '',
  //       ucic_id: equitasDetails.ucic!,
  //       relationship: customerDetails.relationship ?? '',
  //     }).unwrap()
  //     dispatch(updateRedirectionURL(response.insurer_data.URL))
  //     dispatch(updatePlanQuoteId(response.plan_quote))
  //     try {
  //       createConsent(response.plan_quote)
  //     } catch (e: any) {
  //       ToastService.showErrorToast({ title: 'Error', message: e['data']['message'] })
  //     }
  //   } catch (e: any) {
  //     ToastService.showErrorToast({ title: 'Error', message: e['data']['message'] })
  //   }
  // }

  return (
    <>
      <Header />
      <Flex
        direction={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }}
        height={{ lg: 'calc(100vh - 70px)', md: 'auto', sm: 'auto', xs: 'auto' }}
        overflow={'hidden'}
      >
        <Box
          boxShadow={{ lg: 'DEFAULT', md: 'none', sm: 'none', xs: 'none' }}
          w={'100%'}
          height={'100%'}
          position={{ lg: 'relative', md: 'absolute', sm: 'absolute', xs: 'absolute' }}
          overflowY='scroll'
          flexBasis='25%'
        >
          <FilterSideBar setvisible={setIsFilterOpen} visible={isFilterOpen} />
        </Box>
        <Box flexBasis='75%' overflow={'auto'}>
          <Center mt='2rem'>
            <Flex justifyContent='flex-end' width='75%'>
              <Button
                // leftIcon={<MdBuild />}
                colorScheme='secondary'
                fontSize='sm'
                marginX='2'
                variant='outline'
                onClick={() => {
                  copyToClipboard(leadDetails.lead_uid ?? '')
                  // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
                  // ToastService.showSuccessTaost({
                  //   title: 'Success',
                  //   message: 'Lead Saved Successfully',
                  // })
                }}
              >
                {leadDetails.lead_uid ?? '-'}
              </Button>
            </Flex>
          </Center>
          <If
            fallback={
              <Center paddingY='3rem'>
                <If
                  condition={isGetQuotationsApiLoading}
                  fallback={
                    <Text fontSize='2xl' color='red'>
                      No Quotes Found!!
                    </Text>
                  }
                >
                  <Spinner />
                </If>
              </Center>
            }
            condition={!!planQuoteResponse}
          >
            <Flex direction='column' mt='3rem' mx='2rem'>
              <QuoteItemCard
                biUrl={planQuoteResponse?.bi_url ?? ''}
                planDescription={planQuoteResponse?.description ?? ''}
                planName={planQuoteResponse?.plan_name ?? ''}
                quoteId={planQuoteResponse?.quote_id ?? ''}
                startDate={planQuoteResponse?.proposal_start_date ?? ''}
                logo={planQuoteResponse?.logo ?? ''}
                sumAssured={planQuoteResponse?.sum_assured ?? -1}
                annualPremium={planQuoteResponse?.premium?.toString() ?? 'NA'}
                toggleIsBiFormGenerated={toggleIsBiFormGenerated}
                filters={filterData}
              />
              <Center paddingY='2rem'>
                <BbButton
                  isLoading={isLoading}
                  // onClick={createConsent}
                  onClick={
                    () => {
                      if (isBiFormGenerated === false) {
                        ToastService.showInfoToast({
                          title: 'Kindly click on View BI before proceeding!',
                        })
                        return
                      }
                      createConsent()
                      // biQuoteGeneration()
                    }
                    //   () => {
                    //   callBiQuote({
                    //     plan_id: '15',
                    //     lead_id: '1313131',
                    //     quote_id: planQuoteResponse?.quote_id ?? '',
                    //   })
                    //     .unwrap()
                    //     .then((response) => {
                    //       dispatch(updateRedirectionURL(response.insurer_data.URL))
                    //       navigate('/consent-pending')
                    //     })
                    //     .catch((error: any) => {
                    //       ToastService.showErrorToast({
                    //         title: 'Error',
                    //         message: error['data']['message'],
                    //       })
                    //     })
                    // }
                  }
                >
                  PROCEED TO PROPOSAL
                </BbButton>
              </Center>
            </Flex>
          </If>
        </Box>
      </Flex>
    </>
  )
}

export { LifeQuotation }
