import { Box, Button, Center, Flex, FormLabel, Grid, Select } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { store } from 'app/store'
import BimabossDropdown from 'BimabossDropdown'
import { AsyncSelect, Select as ChakraSelect, SingleValue, components } from 'chakra-react-select'
import { emailRegex, gstRegex, mobileNumRegex, onlyAlphabetsRegex, panNumRegex } from 'Constants'
import { onlyAlphabetsWithSpaceRegex } from 'Constants/regex'
import { BimabossDatePicker, ElevatedButton, ErrorText, TextInput, ToggleSwitch } from 'Core'
import { subYears } from 'date-fns'
import { toggleProposalScreen, updateProposalDetails } from 'features/Motor'
// import { updateIsGenderMale, updateIsMarried, updateSalutation } from 'features/Motor'
import { IPincodeOption } from 'Models/ResponseModels'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useGetOccupationsQuery, useLazyGetPincodeQuery } from 'Services/API/motor.api'
import {
  onlyAllowTypingNumbers,
  onlyAllowTypingNumbers2,
  pincodeLabelFormatter,
} from 'Services/general'
import { TMotorProposalFormType } from './FormModel'

interface IProposalCustomerDetailsForm {
  form: UseFormReturn<TMotorProposalFormType, any>
}

const salutationItems = [
  {
    value: 'mr',
    label: 'Mr.',
  },
  {
    value: 'ms',
    label: 'Ms.',
  },
  {
    value: 'mrs',
    label: 'Mrs.',
  },
]

const ProposalCustomerDetailsForm = (props: IProposalCustomerDetailsForm) => {
  const { isDesktop } = useDeviceType()
  const { fieldsFetchedFromVahanMapping } = useAppSelector(
    (state) => state.vehicleDetails.vehicleDetails,
  )
  const { spConsentStatus } = useAppSelector((state) => state.spTagging)
  const { selectedQuote, isIndividual } = useAppSelector((state) => state.quotationsDetails)
  const { proposalScreen, proposalStatus } = useAppSelector((state) => state.proposalPage)
  const { proposalDetails: proposalDetailsInStore } = useAppSelector((state) => state.proposalPage)
  const { fieldsFetchedFromCbsMapping } = useAppSelector((state) => state.customerDetails)
  const {
    // form: { register, formState, trigger, setValue, clearErrors, watch, getValues },
    form,
  } = props

  const { register, formState, trigger, setValue, clearErrors, watch, getValues } = form

  const dispatch = useAppDispatch()

  const [isGenderMale, setIsGenderMale] = useState(proposalDetailsInStore.isGenderMale ?? true)
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState(
    proposalDetailsInStore.isPoliticallyExposed ?? false,
  )
  const [isIndianResident, setIsIndianResident] = useState(proposalDetailsInStore.isIndian ?? true)

  const genderToggleChangeHandler = (isGenderM: boolean) => {
    setIsGenderMale(isGenderM)
    form.setValue('isGenderMale', isGenderM)
    dispatch(
      updateProposalDetails({
        isGenderMale: isGenderM,
      }),
    )
  }

  const politicallyExposedToggleChangeHandler = (isExposed: boolean) => {
    setIsPoliticallyExposed(isExposed)
    form.setValue('isPoliticallyExposed', isExposed)
    dispatch(
      updateProposalDetails({
        isPoliticallyExposed: isExposed,
      }),
    )
  }

  const IndianToggleChangeHandler = (isIndian: boolean) => {
    setIsIndianResident(isIndian)
    form.setValue('isIndian', isIndian)
    dispatch(
      updateProposalDetails({
        isIndian: isIndian,
      }),
    )
  }

  const [isMarried, setIsMarried] = useState(proposalDetailsInStore.isMarried ?? false)

  const maritalStatusToggleHandler = (isMarr: boolean) => {
    setIsMarried(isMarr)
    form.setValue('isMarried', isMarr)
    dispatch(
      updateProposalDetails({
        isMarried: isMarr,
      }),
    )
  }

  const [pincodeLazyQuery] = useLazyGetPincodeQuery()
  const [pincodeValue, setPincodeValue] = useState<IPincodeOption | undefined>(
    proposalDetailsInStore.pincodeArea ?? undefined,
  )
  const [pincodeInput, setPincodeInput] = useState('')

  const loadPincodeOptions = (
    inputSearchValue: string,
    callback: (options: IPincodeOption[]) => void,
  ) => {
    console.log(inputSearchValue)
    if (inputSearchValue.length > 2 && inputSearchValue.length < 7) {
      pincodeLazyQuery({
        insurance_id: selectedQuote?.insurer_id ?? 0,
        pincode: parseInt(inputSearchValue),
      }).then((resp) => {
        const pincodes = resp.data
        if (pincodes) callback(pincodeLabelFormatter(pincodes))
      })
    }
  }

  const handlePincodeSelectChange = (selectedPincode: SingleValue<IPincodeOption>) => {
    console.log('selectedPincode')
    console.log(selectedPincode)
    if (selectedPincode?.pincode) {
      const selected = {
        ...selectedPincode,
        label: selectedPincode.pincode.toString(),
      }
      setPincodeValue(selected)
      setValue('pincodeArea', selected)
      clearErrors('pincodeArea')
      console.log('selected')
      console.log(selected)
      dispatch(
        updateProposalDetails({
          pincodeArea: selected,
        }),
      )
    }
  }

  const { data: occupationResponse, isLoading: isOccupationListingFetching } =
    useGetOccupationsQuery()
  const [occupationListing, setOccupationListing] = useState<string[]>([])
  const [annualIncomeListing, setAnnualIncomeListing] = useState<string[]>([
    '0-2.5 lacs',
    '2.5-5 lacs',
    '5-15 lacs',
    '15-20 lacs',
    '20-30 lacs',
    '30+ lacs',
  ])
  const [organizationTypeListing, setOrganizationTypeListing] = useState<string[]>([
    'Government',
    'Private Limited',
    'Public Limited',
    'Trust',
    'Other',
  ])
  const [selectedOccupation, setSelectedOccupation] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.occupation
      ? {
          label: proposalDetailsInStore.occupation,
          value: proposalDetailsInStore.occupation,
        }
      : {
          label: 'Select Occupation',
          value: '',
        },
  )

  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.annualIncome
      ? {
          label: proposalDetailsInStore.annualIncome,
          value: proposalDetailsInStore.annualIncome,
        }
      : {
          label: 'Select Annual Income',
          value: '',
        },
  )

  const [selectedOrganizationType, setSelectedOrganizationType] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.organizationType
      ? {
          label: proposalDetailsInStore.organizationType,
          value: proposalDetailsInStore.organizationType,
        }
      : {
          label: 'Select Organization Type',
          value: '',
        },
  )

  const [selectedSalutation, setSelectedSalutation] = useState<{
    value: string
    label: string
  } | null>(
    salutationItems.find((salutation) => salutation.label === proposalDetailsInStore.salutation) ??
      salutationItems[0],
  )

  useEffect(() => {
    if (selectedSalutation === salutationItems[0]) {
      setIsGenderMale(true)
    } else {
      setIsGenderMale(false)
    }
  }, [selectedSalutation])

  useEffect(() => {
    if (selectedSalutation === salutationItems[2]) {
      setIsMarried(true)
    } else if (selectedSalutation === salutationItems[1]) {
      setIsMarried(false)
    }
  }, [selectedSalutation])

  useEffect(() => {
    console.log(selectedSalutation?.label)
    form.setValue('salutation', selectedSalutation?.label)
    dispatch(
      updateProposalDetails({
        salutation: selectedSalutation?.label,
      }),
    )
  }, [selectedSalutation])

  useEffect(() => {
    if (occupationResponse) {
      setOccupationListing(Object.values(occupationResponse))
    }
  }, [occupationResponse])

  const [dob, setDob] = useState(proposalDetailsInStore.dob ? proposalDetailsInStore.dob : '')

  useEffect(() => {
    form.setValue('dob', dob)
    dispatch(
      updateProposalDetails({
        dob: dob,
      }),
    )
  }, [dob])

  return (
    <Grid
      gridTemplateColumns={isDesktop ? '1fr 1fr' : '1fr'}
      className={proposalStatus === 'success' || spConsentStatus !== 'not-sent' ? 'disabled' : ''}
    >
      <Box>
        {isIndividual && (
          <>
            <Flex direction='column' marginY='4' marginX='6'>
              <FormLabel>First Name*</FormLabel>
              <Flex>
                <BimabossDropdown
                  items={salutationItems}
                  keyGenerator={(item) => item.value}
                  renderItem={(item) => {
                    return <h1>{item.label}</h1>
                  }}
                  selectedDropdownItem={selectedSalutation}
                  setSelectedDropdownItem={setSelectedSalutation}
                />
                <TextInput
                  {...register('firstName', {
                    required: true,
                    pattern: onlyAlphabetsWithSpaceRegex,
                  })}
                  placeholder='Enter First name'
                  isReadOnly={fieldsFetchedFromCbsMapping.customerFirstName}
                />
              </Flex>
              {formState.errors.firstName && formState.errors.firstName?.type === 'required' && (
                <ErrorText text='First name is required' />
              )}
              {formState.errors.firstName && formState.errors.firstName?.type === 'pattern' && (
                <ErrorText text='First name is Invalid' />
              )}
            </Flex>
            <Flex direction='column' marginY='4' marginX='6'>
              <FormLabel>Middle Name</FormLabel>
              <TextInput
                {...register('middleName', { pattern: onlyAlphabetsWithSpaceRegex })}
                placeholder='Enter Middle name'
                isReadOnly={fieldsFetchedFromVahanMapping.customerMiddleName}
              />
              {formState.errors.middleName && formState.errors.middleName?.type === 'pattern' && (
                <ErrorText text='Middle name is Invalid' />
              )}
            </Flex>
            <Flex direction='column' marginY='4' marginX='6'>
              <FormLabel>Last Name*</FormLabel>
              <TextInput
                {...register('lastName', { required: true, pattern: onlyAlphabetsWithSpaceRegex })}
                placeholder='Enter Last name'
                isReadOnly={fieldsFetchedFromCbsMapping.customerLastName}
              />
              {formState.errors.lastName && formState.errors.lastName?.type === 'required' && (
                <ErrorText text='Last name is required' />
              )}
              {formState.errors.lastName && formState.errors.lastName?.type === 'pattern' && (
                <ErrorText text='Last name is Invalid' />
              )}
            </Flex>
          </>
        )}
        {!isIndividual && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Company Name*</FormLabel>
            <TextInput
              {...register('companyName', { required: true })}
              placeholder='Enter Company Name'
            />
            {formState.errors.companyName && formState.errors.companyName?.type === 'required' && (
              <ErrorText text='Company Name is required' />
            )}
          </Flex>
        )}
        {isIndividual && (
          <Grid marginY='4' marginX='6' gridTemplateColumns='1fr 1fr'>
            <Flex direction='column'>
              <FormLabel>Gender*</FormLabel>
              <Box className='disabled'>
                <ToggleSwitch
                  enabled={isGenderMale}
                  setEnabled={setIsGenderMale}
                  changeHandler={genderToggleChangeHandler}
                  enabledLabel='M'
                  disabledLabel='F'
                />
              </Box>
            </Flex>
            <Flex direction='column'>
              <FormLabel>Marital Status*</FormLabel>
              <Box
                className={
                  selectedSalutation === salutationItems[1] ||
                  selectedSalutation === salutationItems[2]
                    ? 'disabled'
                    : ''
                }
              >
                <ToggleSwitch
                  enabled={isMarried}
                  setEnabled={setIsMarried}
                  changeHandler={maritalStatusToggleHandler}
                />
              </Box>
            </Flex>
          </Grid>
        )}
        {selectedQuote?.premium_total && selectedQuote?.premium_total > 50000 && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Pan Card*</FormLabel>
            <TextInput
              {...register('panCard', { required: true, pattern: panNumRegex })}
              placeholder='Enter Pan Card no.'
            />
            {formState.errors.panCard && formState.errors.panCard?.type === 'required' && (
              <ErrorText text='Pan Card is required' />
            )}
            {formState.errors.panCard && formState.errors.panCard?.type === 'pattern' && (
              <ErrorText text={'Invalid Pan Number'} />
            )}
          </Flex>
        )}
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Mobile Number*</FormLabel>
          <TextInput
            maxLength={10}
            onKeyPress={onlyAllowTypingNumbers2}
            {...register('mobileNumber', { required: true, pattern: mobileNumRegex })}
            placeholder='Enter Mobile no.'
            isReadOnly={fieldsFetchedFromCbsMapping.customerMobile}
          />
          {formState.errors.mobileNumber && formState.errors.mobileNumber?.type === 'required' && (
            <ErrorText text='Mobile no. is required' />
          )}
          {formState.errors.mobileNumber && formState.errors.mobileNumber?.type === 'pattern' && (
            <ErrorText text={'Invalid Mobile Number'} />
          )}
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Email*</FormLabel>
          <TextInput
            {...register('email', { required: true, pattern: emailRegex })}
            placeholder='Enter Email'
            isReadOnly={fieldsFetchedFromCbsMapping.customerEmail}
          />
          {formState.errors.email && formState.errors.email?.type === 'required' && (
            <ErrorText text='Email is required' />
          )}
          {formState.errors.email && formState.errors.email?.type === 'pattern' && (
            <ErrorText text='Email is invalid' />
          )}
        </Flex>
        {selectedQuote?.insurance_name.includes('HDFC') && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Mother&apos;s Name*</FormLabel>
            <TextInput
              {...register('mothersName', { required: true })}
              placeholder="Enter Mother's Name"
            />
            {formState.errors.mothersName && formState.errors.mothersName?.type === 'required' && (
              <ErrorText text="Mother's Name is required" />
            )}
          </Flex>
        )}
        {selectedQuote?.insurance_name.includes('HDFC') && (
          <Grid marginY='4' marginX='6' gridTemplateColumns='1fr 1fr'>
            <Flex direction='column'>
              <FormLabel>Is proposer politically exposed?</FormLabel>
              <Box>
                <ToggleSwitch
                  enabled={isPoliticallyExposed}
                  setEnabled={setIsPoliticallyExposed}
                  changeHandler={politicallyExposedToggleChangeHandler}
                  enabledLabel='Yes'
                  disabledLabel='No'
                />
              </Box>
            </Flex>
            <Flex direction='column'>
              <FormLabel>Is proposer a citizen of India?</FormLabel>
              <Box>
                <ToggleSwitch
                  enabled={isIndianResident}
                  setEnabled={setIsIndianResident}
                  changeHandler={IndianToggleChangeHandler}
                  enabledLabel='Yes'
                  disabledLabel='No'
                />
              </Box>
            </Flex>
          </Grid>
        )}
        {!isIndividual && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>GST Number*</FormLabel>
            <TextInput
              {...register('gstNumber', { required: true, pattern: gstRegex })}
              placeholder='Enter GST no.'
            />
            {formState.errors.gstNumber && formState.errors.gstNumber?.type === 'required' && (
              <ErrorText text='GST no. is required' />
            )}
            {formState.errors.gstNumber && formState.errors.gstNumber?.type === 'pattern' && (
              <ErrorText text={'Invalid GST Number'} />
            )}
          </Flex>
        )}
      </Box>
      {/* <Button onClick={() => console.log(store.getState())}>Log store</Button> */}
      <Box>
        {isIndividual && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>DOB*</FormLabel>
            <BimabossDatePicker
              clearErrors={() => clearErrors('dob')}
              inputDate={dob}
              setInputDate={setDob}
              maxDate={subYears(new Date(), 18)}
              {...register('dob', { required: true })}
              isNonEditable={fieldsFetchedFromCbsMapping.customerDob}
            />
            {/* <TextInput {...register('dob', { required: true })} placeholder='Enter DOB' /> */}
            {formState.errors.dob && formState.errors.dob?.type === 'required' && (
              <ErrorText text='DOB is required' />
            )}
          </Flex>
        )}
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Pin Code*</FormLabel>
          <AsyncSelect
            {...register('pincodeArea', { required: true })}
            placeholder='Select Pincode'
            onInputChange={(input) => {
              if (input.length < 7) setPincodeInput(input)
            }}
            inputValue={pincodeInput}
            loadOptions={loadPincodeOptions}
            onChange={handlePincodeSelectChange}
            value={pincodeValue}
            defaultInputValue={pincodeValue?.pincode.toString()}
            onKeyDown={onlyAllowTypingNumbers}
          />
          {formState.errors.pincodeArea && formState.errors.pincodeArea?.type === 'required' && (
            <ErrorText text='Pincode is required' />
          )}
        </Flex>
        {isIndividual && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Occupation</FormLabel>
            <ChakraSelect
              isSearchable={false}
              selectedOptionColor='primary'
              menuPosition='fixed'
              placeholder='Select Occupation'
              {...register('occupation')}
              value={selectedOccupation}
              onChange={(item) => {
                setSelectedOccupation({
                  label: item?.label ?? '',
                  value: item?.value ?? '',
                })
                setValue('occupation', item?.value ?? '')
                clearErrors('occupation')
                dispatch(
                  updateProposalDetails({
                    occupation: item?.value,
                  }),
                )
              }}
              isLoading={isOccupationListingFetching}
              options={occupationListing.map((occupation) => {
                return {
                  label: occupation,
                  value: occupation,
                }
              })}
            />
            {formState.errors.occupation && formState.errors.occupation?.type === 'required' && (
              <ErrorText text='Occupation is required' />
            )}
          </Flex>
        )}
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Address1*</FormLabel>
          <TextInput
            {...register('address1', { required: true })}
            placeholder='Enter Address1'
            isReadOnly={fieldsFetchedFromCbsMapping.customerAddress1}
          />
          {formState.errors.address1 && formState.errors.address1?.type === 'required' && (
            <ErrorText text='Address1 is required' />
          )}
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Address2*</FormLabel>
          <TextInput
            {...register('address2', { required: true })}
            placeholder='Enter Address2'
            isReadOnly={fieldsFetchedFromCbsMapping.customerAddress2}
          />
          {formState.errors.address2 && formState.errors.address2?.type === 'required' && (
            <ErrorText text='Address2 is required' />
          )}
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Landmark</FormLabel>
          <TextInput {...register('landmark')} placeholder='Enter Landmark' />
          {formState.errors.landmark && <ErrorText text='Landmark is required' />}
        </Flex>
        {selectedQuote?.insurance_name.includes('HDFC') && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Organization Type</FormLabel>
            <ChakraSelect
              isSearchable={false}
              selectedOptionColor='primary'
              menuPosition='fixed'
              placeholder='Select Organization Type'
              {...register('organizationType', { required: true })}
              value={selectedOrganizationType}
              onChange={(item) => {
                setSelectedOrganizationType({
                  label: item?.label ?? '',
                  value: item?.value ?? '',
                })
                setValue('organizationType', item?.value ?? '')
                clearErrors('organizationType')
                dispatch(
                  updateProposalDetails({
                    organizationType: item?.value,
                  }),
                )
              }}
              options={organizationTypeListing.map((organizationType) => {
                return {
                  label: organizationType,
                  value: organizationType,
                }
              })}
            />
            {formState.errors.organizationType && (
              <ErrorText text='Organization Type is required' />
            )}
          </Flex>
        )}
        {selectedQuote?.insurance_name.includes('HDFC') && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Annual Income</FormLabel>
            <ChakraSelect
              isSearchable={false}
              selectedOptionColor='primary'
              menuPosition='fixed'
              placeholder='Select Annual Income'
              {...register('annualIncome', { required: true })}
              value={selectedAnnualIncome}
              onChange={(item) => {
                setSelectedAnnualIncome({
                  label: item?.label ?? '',
                  value: item?.value ?? '',
                })
                setValue('annualIncome', item?.value ?? '')
                clearErrors('annualIncome')
                dispatch(
                  updateProposalDetails({
                    annualIncome: item?.value,
                  }),
                )
              }}
              options={annualIncomeListing.map((annualIncome) => {
                return {
                  label: annualIncome,
                  value: annualIncome,
                }
              })}
            />
            {formState.errors.annualIncome && <ErrorText text='Annual Income is required' />}
          </Flex>
        )}
        {/* {JSON.stringify(formState.errors)} */}
        {/* </Grid> */}
        {isDesktop && (
          <Center padding='4'>
            <ElevatedButton
              onClick={
                proposalScreen === 'one'
                  ? async () => {
                      console.log(getValues())
                      const isFormValid = await trigger()
                      // if (isFormValid) setShowScreenOne((prev) => !prev)

                      if (isFormValid) {
                        // setProposalValueInForm(proposalDetailsInStore, form)
                        dispatch(updateProposalDetails(getValues()))
                        dispatch(toggleProposalScreen('two'))
                      }
                    }
                  : () => {
                      console.log('this is will never print!')
                    }
              }
              size='md'
              marginBottom='3'
              colorScheme='primary'
            >
              NEXT
            </ElevatedButton>
          </Center>
        )}
      </Box>
    </Grid>
  )
}

export { ProposalCustomerDetailsForm }
