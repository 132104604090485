import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Grid,
  GridItem,
  Checkbox,
} from '@chakra-ui/react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useLazyGetGeoDataQuery } from 'Services/API/motor.api'
import { getMasterPolicyNumber, getStateName, onlyNumbersRegex } from 'Constants'
import {
  onlyAllowTypingAlphabet,
  onlyAllowTypingAlphabetNumeric,
  onlyAllowTypingAlphabetNumericAndAt,
  onlyAllowTypingAlphabetNumericAndSpace,
  onlyAllowTypingNumbers,
  onlyAllowTypingNumbers2,
} from 'Services/general'
import { calcAge18, isMoreThan18 } from './SachetsNomineeForm'
import { ToastService } from 'Services/toast.service'
import {
  IApplicantFormSpouse,
  setApplicantFormDetailsSpouse,
  setIsSameAddressSpouse,
} from 'features/Sachets/sachetData.slice'

const ApplicantSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  middleName: yup.string(),
  pinCode: yup
    .string()
    .required('Pin Code is required')
    .matches(/^\d{6}$/, 'Code must be exactly 6 digits'),
  dob: yup.date().required('Date of Birth is required'),
  address1: yup.string().required('Address Line 1 is required'),
  city: yup.string().required('City is required'),
  district: yup.string().required('District is required'),
  salutation: yup.string().required('Salutation is required'),
  gender: yup.string().required('Gender is required'),

  address2: yup.string(),
  address3: yup.string(),
  state: yup.string().required('State is required'),
})

const SachetsApplicantFormSpouse = ({
  selectedProductData,
  index,
  setIndex,
  setApplcantAddress,
  selectedAccount,
  otherCustomerDetails,
  fullCustomerDetails,
  setFormAccordianState,
  setIsProceed,
}: {
  selectedProductData: any
  index: any
  setIndex: any
  setApplcantAddress: any
  selectedAccount: any
  otherCustomerDetails: any
  fullCustomerDetails: any
  setFormAccordianState: any
  setIsProceed: any
}) => {
  const [getCity] = useLazyGetGeoDataQuery()
  const customerProposerDetails = useAppSelector((state) => state.customerDetails)
  // const [cityName, setCityName] = useState('Fetching...')

  const [stateName, setStateName] = useState('')
  const stateCode = customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crnSt
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IApplicantFormSpouse>({
    resolver: yupResolver(ApplicantSchema),
  })

  useEffect(() => {
    if (customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntCty)
      getCity({
        city_id: parseInt(
          customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntCty,
        ),
      })
        .unwrap()
        .then((response) => {
          // setCityName(response.data)
          // setFormData((formData) => {
          //   return { ...formData, city: response.data }
          // })
          const stateCode =
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crnSt
          setStateName(getStateName[stateCode as string] as string)
        })
  }, [])
  const applicantDetails = useAppSelector((state) => state?.sachetDataDetails?.applicantFormDetails)

  const dispatch = useAppDispatch()

  const onSubmit: SubmitHandler<IApplicantFormSpouse> = (_, e) => {
    e?.preventDefault()

    dispatch(setIsSameAddressSpouse(isCheckboxchecked))

    if (!isMoreThan18(new Date(formData.dob))) {
      return ToastService.showErrorToast({
        title: 'Spouse age should be more than or equal to 18',
      })
    }

    setIndex(2)
  }

  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    pinCode: '',
    dob: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    district: '',
    state: '',
    gender: '',
    salutation: '',
    relationship: 'Spouse',
  })
  const [isCheckboxchecked, setIsCheckboxchecked] = useState(false)

  return (
    <Flex direction='column' justifyContent='center' alignItems='center' gap='3'>
      <Grid templateColumns='repeat(3, 1fr)' gap={6} w='full'>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>First Name</FormLabel>
            <Flex>
              <FormControl
                w='45%'
                isRequired
                isInvalid={!formData?.salutation && !!errors.salutation}
              >
                <Select
                  disabled={formData?.gender == '' ? true : false}
                  fontSize='11px'
                  p='0'
                  {...register('salutation')}
                  placeholder='Select Salutation'
                  value={formData?.salutation}
                  onChange={(e) => {
                    setFormData((formData) => {
                      return {
                        ...formData,
                        salutation: formData?.gender == 'm' ? 'Mr' : e.target.value,
                      }
                    })
                  }}
                >
                  <option value='Mr' hidden={formData?.gender == 'f'}>
                    Mr
                  </option>
                  <option value='Mrs' hidden={formData?.gender == 'm'}>
                    Mrs
                  </option>
                  <option value='Ms' hidden={formData?.gender == 'm'}>
                    Ms
                  </option>
                </Select>
                {!formData?.salutation && (
                  <FormErrorMessage>{errors.salutation?.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                w='full'
                isRequired
                isInvalid={!formData?.firstName && !!errors.firstName}
              >
                <Input
                  onKeyDown={onlyAllowTypingAlphabet}
                  placeholder='Enter First name'
                  maxLength={50}
                  required
                  {...register('firstName')}
                  value={formData?.firstName}
                  onChange={(e) => {
                    setFormData((formData) => {
                      return {
                        ...formData,
                        firstName: e.target.value.trim().length > 0 ? e.target.value : '',
                      }
                    })
                  }}
                />
                {!formData?.firstName && (
                  <FormErrorMessage color='red.500'>{errors.firstName?.message}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!formData?.middleName && !!errors?.middleName}>
            <FormLabel>Middle Name</FormLabel>
            <Input
              maxLength={50}
              onKeyDown={onlyAllowTypingAlphabet}
              {...register('middleName')}
              placeholder='Enter middle name'
              value={formData?.middleName}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    middleName: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.middleName && (
              <FormErrorMessage color='red.500'>{errors.middleName?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.lastName && !!errors.lastName}>
            <FormLabel>Last Name</FormLabel>
            <Input
              maxLength={50}
              onKeyDown={onlyAllowTypingAlphabet}
              required
              {...register('lastName')}
              placeholder='Enter last name'
              value={formData?.lastName}
              // disabled={fetchedData?.lastName ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    lastName: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.lastName && (
              <FormErrorMessage color='red.500'>{errors.lastName?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Relationship</FormLabel>
            <Input
              maxLength={50}
              onKeyDown={onlyAllowTypingAlphabet}
              required
              {...register('relationship')}
              placeholder='Enter last name'
              value={formData?.relationship}
              disabled={formData?.relationship ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    relationship: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.relationship && (
              <FormErrorMessage color='red.500'>{errors.relationship?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            isRequired
            isInvalid={!formData?.gender && !!errors.gender}
            //  isInvalid={formData?.gender ? false : !!errors.gender}
          >
            <FormLabel>Gender</FormLabel>
            <Select
              {...register('gender')}
              placeholder='Select gender'
              value={formData?.gender}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    gender: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
                setValue('salutation', '')
                setFormData((formData) => {
                  return {
                    ...formData,
                    salutation: '',
                  }
                })
              }}
            >
              <option value='m'>Male</option>
              <option value='f'>Female</option>
            </Select>
            {!formData?.gender && (
              <FormErrorMessage color='red.500'>{errors.gender?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            isRequired
            isInvalid={!formData?.dob && !!errors.dob}
            // isInvalid={fetchedData?.dob ? false : !!errors.dob}
          >
            <FormLabel>Date of Birth</FormLabel>
            <Input
              max={calcAge18().toISOString().split('T')[0]}
              {...register('dob')}
              type='date'
              value={formData?.dob}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    dob: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.dob && (
              <FormErrorMessage color='red.500'>Date of Birth is required</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl
            isRequired
            isInvalid={!formData?.address1 && !!errors.address1}
            //  isInvalid={fetchedData?.address1 ? false : !!errors.address1}
          >
            <FormLabel>Address Line 1</FormLabel>
            <Input
              maxLength={100}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              disabled={isCheckboxchecked}
              {...register('address1')}
              placeholder='Address Line 1'
              value={formData?.address1}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    address1: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.address1 && (
              <FormErrorMessage color='red.500'>{errors.address1?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!formData?.address2 && !!errors.address2}>
            <FormLabel>Address Line 2</FormLabel>
            <Input
              maxLength={100}
              disabled={isCheckboxchecked}
              {...register('address2')}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              placeholder='Address Line 2'
              // TODO: Changed the value here , Look here
              value={formData?.address2}
              // value={fetchedData.address2 || formData?.address2}
              // disabled={fetchedData?.address2 ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    address2: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.address2 && (
              <FormErrorMessage color='red.500'>{errors.address2?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors.address3}>
            <FormLabel>Address Line 3</FormLabel>
            <Input
              disabled={isCheckboxchecked}
              maxLength={100}
              {...register('address3')}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              placeholder='Address Line 3'
              // TODO: Changed the value here , Look here
              value={formData?.address3}
              // value={fetchedData.address2 || formData?.address2}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    address3: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.address3 && (
              <FormErrorMessage color='red.500'>{errors.address3?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl
            isRequired
            isInvalid={!formData?.city && !!errors.city}
            // isInvalid={cityName ? false : !!errors.city}
          >
            <FormLabel>City</FormLabel>
            <Input
              // disabled
              // disabled={isCheckboxchecked}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              type='text'
              {...register('city')}
              placeholder='Enter City'
              // value={cityName}
              value={formData?.city}
              // disabled={fetchedData?.city ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    city: e.target.value,
                    district: e.target.value,
                  }
                })
                setValue('district', e.target.value)
              }}
            />
            {!formData?.city && (
              <FormErrorMessage color='red.500'>{errors.city?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.district && !!errors.district}>
            <FormLabel>District</FormLabel>
            <Input
              disabled
              // disabled={isCheckboxchecked}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              type='text'
              {...register('district')}
              placeholder='Enter District'
              // value={formData?.district}
              value={formData?.city}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    district: e.target.value,
                  }
                })
              }}
            />
            {!formData?.district && (
              <FormErrorMessage color='red.500'>{errors.district?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            isRequired
            isInvalid={!!errors.pinCode}

            // isInvalid={fetchedData?.pinCode ? false : !!errors.pinCode}
          >
            <FormLabel>Pin Code</FormLabel>
            <Input
              disabled={isCheckboxchecked}
              onKeyDown={onlyAllowTypingNumbers2}
              // maxLength={6}
              // minLength={6}
              type='number'
              {...register('pinCode')}
              placeholder='Pin Code'
              value={formData?.pinCode}
              // disabled={fetchedData?.pinCode ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    pinCode: e.target.value,
                  }
                })
              }}
            />

            <FormErrorMessage color='red.500'>{errors.pinCode?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.state && !!errors.state}>
            <FormLabel>State</FormLabel>
            <Input
              disabled={isCheckboxchecked}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              type='text'
              {...register('state')}
              placeholder='Select state'
              value={formData?.state}
              // disabled={fetchedData?.state ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    state: e.target.value,
                  }
                })
              }}
            />
            {!formData?.state && (
              <FormErrorMessage color='red.500'>{errors.state?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
      </Grid>
      <Flex w='full' justifyContent='space-between' alignItems='start'>
        <Checkbox
          onChange={(e) => {
            setIsCheckboxchecked(e.target.checked)
            if (e.target.checked) {
              setValue('address1', applicantDetails.address1)
              setValue('address2', applicantDetails.address2)
              setValue('address3', applicantDetails.address3)
              setValue('city', applicantDetails.city)
              setValue('district', applicantDetails.city)
              setValue('state', applicantDetails.state)
              setValue('pinCode', applicantDetails.pinCode)

              setFormData((formData: any) => {
                return {
                  ...formData,
                  address1: applicantDetails.address1,
                  address2: applicantDetails.address2,
                  address3: applicantDetails.address3,
                  city: applicantDetails.city,
                  pinCode: applicantDetails.pinCode,
                  state: applicantDetails.state,
                  district: applicantDetails.city,
                }
              })
            } else {
              setValue('address1', '')
              setValue('address2', '')
              setValue('address3', '')
              setValue('city', '')
              setValue('district', '')
              setValue('state', '')
              setValue('pinCode', '')

              setFormData((formData) => {
                return {
                  ...formData,
                  address1: '',
                  address2: '',
                  address3: '',
                  city: '',
                  pinCode: '',
                  state: '',
                  district: '',
                }
              })
            }
          }}
        >
          Same as Applicant Address
        </Checkbox>
      </Flex>
      <Button
        onClick={() => {
          dispatch(setApplicantFormDetailsSpouse(formData))
          setIsProceed(true)
          return handleSubmit(onSubmit)()
        }}
        cursor='pointer'
        type='submit'
        border='1px'
        bgColor='#1C4589'
        px='20'
        py='5'
      >
        Proceed
      </Button>
    </Flex>
  )
}

export default SachetsApplicantFormSpouse
