import { Box, Flex, FormLabel, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType, useVehicleType } from 'app/hooks'
import { store } from 'app/store'
import { AsyncSelect, Select, SingleValue } from 'chakra-react-select'
import { hypothecationCheckInsurers } from 'Constants'
import { ErrorText, TextInput, ToggleSwitch } from 'Core'
import { updateProposalDetails } from 'features/Motor'
import { IHypothecation } from 'Models/ResponseModels'
import { IHypothecationOption } from 'Models/ResponseModels/motor'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useLazyGetHypothecationQuery } from 'Services/API/motor.api'
import { bankHypothecationLabelFormatter } from 'Services/general'
import { TMotorProposalFormType } from './FormModel'

interface IProposalVehicleDetailsForm {
  form: UseFormReturn<TMotorProposalFormType, any>
}

const ProposalVehicleDetailsForm = (props: IProposalVehicleDetailsForm) => {
  const { proposalDetails: proposalDetailsInStore, proposalStatus } = useAppSelector(
    (state) => state.proposalPage,
  )
  const { selectedQuote } = useAppSelector((state) => state.quotationsDetails)
  const { spConsentStatus } = useAppSelector((state) => state.spTagging)

  const { isDesktop } = useDeviceType()

  const { hasPreviousPolicy } = useAppSelector(
    (state) => state.vehicleDetails.vehicleInsuranceDetails,
  )

  const [getLazyBanknames] = useLazyGetHypothecationQuery()

  const [selectedUsesType, setSelectedUsesType] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.usesType
      ? {
          label: proposalDetailsInStore.usesType.name,
          value: proposalDetailsInStore.usesType.code,
        }
      : {
          label: 'Select Uses Type',
          value: '',
        },
  )

  const [selectedAgreementType, setSelectedAgreementType] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.agreementType
      ? {
          label: proposalDetailsInStore.agreementType.name,
          value: proposalDetailsInStore.agreementType.code,
        }
      : {
          label: 'Select Agreement Type',
          value: '',
        },
  )

  const dispatch = useAppDispatch()

  const { param: vehicleType } = useVehicleType()

  const {
    form: { register, formState, watch, setValue, clearErrors },
  } = props
  const [isCarOnLoan, setIsCarOnLoan] = useState(proposalDetailsInStore.isLoan ?? false)

  const loanToggleChangeHandler = (isL: boolean) => {
    // console.log('isLoan', isLoan)
    setIsCarOnLoan(isL)
    setValue('isLoan', isL)
    dispatch(
      updateProposalDetails({
        isLoan: isL,
      }),
    )
  }

  const [bankHypothecation, setBankHypothecation] = useState<IHypothecationOption | undefined>(
    proposalDetailsInStore.hypothecation
      ? {
          ...proposalDetailsInStore.hypothecation,
          label: proposalDetailsInStore.hypothecation?.bank_name ?? '',
          value: proposalDetailsInStore.hypothecation?.id ?? '',
        }
      : undefined,
  )

  const loadBankNameOptions = (
    inputSearchValue: string,
    callback: (options: IHypothecationOption[]) => void,
  ) => {
    getLazyBanknames({
      bank_name: inputSearchValue,
      category: 'hypothecation',
      insurance_id: selectedQuote?.insurer_id ?? '',
    }).then((resp) => {
      const banks = resp.data
      if (banks) callback(bankHypothecationLabelFormatter(banks))
    })
  }

  const handleBankHypothecationSelectChange = (selectedBank: SingleValue<IHypothecationOption>) => {
    if (selectedBank?.value) {
      setValue('hypothecation', {
        bank_name: selectedBank.bank_name,
        id: selectedBank.id,
      })
      setBankHypothecation(selectedBank)
      clearErrors('hypothecation')
      dispatch(
        updateProposalDetails({
          hypothecation: {
            bank_name: selectedBank.bank_name,
            id: selectedBank.id,
          },
        }),
      )
    }
  }

  const vehicleNum = useAppSelector((state) => state.homePage.vehicleNumber)

  return (
    <Box
      className={proposalStatus === 'success' || spConsentStatus !== 'not-sent' ? 'disabled' : ''}
    >
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Vehicle Number*</FormLabel>
        <TextInput value={vehicleNum} isReadOnly placeholder='Enter Vehicle No.' />
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Engine Number*</FormLabel>
        <TextInput
          {...register('engineNumber', { required: true, minLength: 6, pattern: /[0-9]/ })}
          placeholder='Enter Engine No.'
          maxLength={20}
        />
        {formState.errors.engineNumber && formState.errors.engineNumber?.type === 'required' && (
          <ErrorText text='Engine no. is required' />
        )}
        {formState.errors.engineNumber && formState.errors.engineNumber?.type === 'minLength' && (
          <ErrorText text='Enter atleast last 6 digits' />
        )}
        {formState.errors.engineNumber && formState.errors.engineNumber?.type === 'pattern' && (
          <ErrorText text='Engine no. can be numeric or alpha-numeric only' />
        )}
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Chassis Number*</FormLabel>
        <TextInput
          {...register('chassisNumber', { required: true, minLength: 6, pattern: /[0-9]/ })}
          placeholder='Enter Chassis No.'
          maxLength={20}
        />
        {formState.errors.chassisNumber && formState.errors.chassisNumber?.type === 'required' && (
          <ErrorText text='Chassis no. is required' />
        )}
        {formState.errors.chassisNumber && formState.errors.chassisNumber?.type === 'minLength' && (
          <ErrorText text='Enter atleast last 6 digits' />
        )}
        {formState.errors.chassisNumber && formState.errors.chassisNumber?.type === 'pattern' && (
          <ErrorText text='Chassis no. can be numeric or alpha-numeric only' />
        )}
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel onClick={() => console.log(store.getState())}>
          Is your vehicle on loan?*
        </FormLabel>
        <ToggleSwitch
          enabled={isCarOnLoan}
          setEnabled={setIsCarOnLoan}
          changeHandler={loanToggleChangeHandler}
        />
      </Flex>
      {isCarOnLoan && !hypothecationCheckInsurers.includes(selectedQuote?.insurer_code ?? '') && (
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Bank Name*</FormLabel>
          <TextInput {...register('bankName', { required: true })} placeholder='Enter Bank Name' />
          {formState.errors.bankName && formState.errors.bankName?.type === 'required' && (
            <ErrorText text='Bank Name is required' />
          )}
        </Flex>
      )}
      {isCarOnLoan &&
        ['HE4W', 'HE2W', 'HDGCV', 'HDFCPC'].includes(selectedQuote?.insurer_code ?? '') && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Agreement Type*</FormLabel>
            {/* <TextInput {...register('bankName', { required: true })} placeholder='Enter Bank Name' /> */}
            <Select
              isSearchable={false}
              placeholder='Select Agreement Type'
              {...register('agreementType', { required: true })}
              value={selectedAgreementType}
              onChange={(item) => {
                setSelectedAgreementType({
                  label: item?.label ?? '',
                  value: item?.value ?? '',
                })
                setValue('agreementType', {
                  name: item?.label ?? '',
                  code: item?.value ?? '',
                })
                clearErrors('agreementType')
                dispatch(
                  updateProposalDetails({
                    agreementType: {
                      name: item?.label ?? '',
                      code: item?.value ?? '',
                    },
                  }),
                )
              }}
              options={[
                {
                  label: 'Hire Purchase',
                  value: 'hire_purchase',
                },
                {
                  label: 'Hypothecation',
                  value: 'hypothecation',
                },
                {
                  label: 'Lease',
                  value: 'lease',
                },
              ]}
            />
            {/* <AsyncSelect
            {...register('agreementType', { required: true })}
            placeholder='Select Bank Name'
            loadOptions={loadBankNameOptions}
            onChange={handleBankHypothecationSelectChange}
            value={bankHypothecation}
          /> */}
            {formState.errors.agreementType &&
              formState.errors.agreementType?.type === 'required' && (
                <ErrorText text='Bank Name is required' />
              )}
          </Flex>
        )}
      {isCarOnLoan && hypothecationCheckInsurers.includes(selectedQuote?.insurer_code ?? '') && (
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Bank Name*</FormLabel>
          {/* <TextInput {...register('bankName', { required: true })} placeholder='Enter Bank Name' /> */}
          <AsyncSelect
            {...register('hypothecation', { required: true })}
            placeholder='Select Bank Name'
            loadOptions={loadBankNameOptions}
            onChange={handleBankHypothecationSelectChange}
            value={bankHypothecation}
          />
          {formState.errors.hypothecation &&
            formState.errors.hypothecation?.type === 'required' && (
              <ErrorText text='Bank Name is required' />
            )}
        </Flex>
      )}
      {isCarOnLoan &&
        ['HE4W', 'HE2W', 'HDGCV', 'HDFCPC'].includes(selectedQuote?.insurer_code ?? '') && (
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Branch Name</FormLabel>
            {/* <TextInput {...register('bankName', { required: true })} placeholder='Enter Bank Name' /> */}
            <TextInput
              placeholder='Enter Branch Name'
              {...register('branchName', { required: true })}
            />
            {formState.errors.branchName && formState.errors.branchName?.type === 'required' && (
              <ErrorText text='Branch Name is required' />
            )}
            {/* <AsyncSelect
            {...register('hypothecation', { required: true })}
            placeholder='Select Bank Name'
            loadOptions={loadBankNameOptions}
            onChange={handleBankHypothecationSelectChange}
            value={bankHypothecation}
          /> */}
            {/* {formState.errors.hypothecation &&
            formState.errors.hypothecation?.type === 'required' && (
              <ErrorText text='Bank Name is required' />
            )} */}
          </Flex>
        )}
      {(vehicleType === 'is_gcv' || vehicleType === 'is_pcv') && (
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel onClick={() => console.log(store.getState())}>Uses Type*</FormLabel>
          <Select
            isSearchable={false}
            placeholder='Select Uses Type'
            {...register('usesType', { required: true })}
            value={selectedUsesType}
            onChange={(item) => {
              setSelectedUsesType({
                label: item?.label ?? '',
                value: item?.value ?? '',
              })
              setValue('usesType', {
                name: item?.label ?? '',
                code: item?.value ?? '',
              })
              clearErrors('usesType')
              dispatch(
                updateProposalDetails({
                  usesType: {
                    name: item?.label ?? '',
                    code: item?.value ?? '',
                  },
                }),
              )
            }}
            options={[
              {
                label: 'State Permit',
                value: 'state_permit',
              },
              {
                label: 'National Permit',
                value: 'national_permit',
              },
            ]}
          />
          {formState.errors.usesType && formState.errors.usesType?.type === 'required' && (
            <ErrorText text='Uses type is required' />
          )}
        </Flex>
      )}
      {hasPreviousPolicy && (
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Previous Policy Number*</FormLabel>
          <TextInput
            {...register('previousPolicyNumber', { required: true })}
            placeholder='Enter Previous Policy No.'
          />
          {formState.errors.previousPolicyNumber &&
            formState.errors.previousPolicyNumber?.type === 'required' && (
              <ErrorText text='Prev. Policy Number is required' />
            )}
        </Flex>
      )}
      {/* <pre>watch{JSON.stringify(watch(), null, 2)}</pre>
      <h1>____________________</h1>
      <pre>store{JSON.stringify(proposalDetailsInStore, null, 2)}</pre> */}
    </Box>
  )
}

export { ProposalVehicleDetailsForm }
