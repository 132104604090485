import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import nonSmoker from 'Assets/Common/nonSmoker.svg'
import smoker from 'Assets/Common/smoker.svg'
import { NestedForm } from 'Core/NestedForm'
import { useGetSuitabilityDataQuery } from 'Services/API/life.api'
import { calculateAgeFromDob, getAgeObjFromDob2 } from 'Services/general'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { Select } from 'chakra-react-select'
import {
  updateSuitabilityData,
  updateSuitabilityData2,
} from 'features/Life/LifeQuotationDetails.slice'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import * as yup from 'yup'

const Requirement = (props: { dob: string }) => {
  const lifeCustomerData = useAppSelector((state) => state.lifeCustomerDetails)
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)
  const suitabilityData = useAppSelector((state) => state.lifeQuotationDetails.suitabilityData2)
  // const dob = lifeCustomerData.lifeAssuredDetailsTyped?.dob
  const [ageBand, setAgeBand] = useState({})
  const { data: RES } = useGetSuitabilityDataQuery(
    { question_type: 'equitas_life' },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (RES) {
      getAgeBand()
    }
  }, [RES, props.dob])

  const getAgeBand = () => {
    let age = 0

    age = getAgeObjFromDob2(new Date(props.dob ?? ''))

    RES!['equitas_life']['life_assurance_requirement'].map((question) => {
      if (question.code === 'age_band') {
        Object.keys(question.values).map((val) => {
          const lowerLimit = parseInt(val.split('-')[0])
          const upperLimit = parseInt(val.split('-')[1])
          if (age >= lowerLimit && age <= upperLimit) {
            suitabilityForm.setValue('age_band', val)
            dispatch(updateSuitabilityData2({ age_band: val }))
            setAgeBand({ age_band: val })
            // return val
          }
        })
      }
    })
    // return ''
  }

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const suitabilityForm = useForm({
    shouldUseNativeValidation: false,
    mode: 'onChange',
  })

  const { isDesktop } = useDeviceType()

  const onFormSubmit = (data: any) => {
    dispatch(
      updateSuitabilityData({
        payment_preference: data.payment_preference,
        need: data.need,
        risk: data.risk,
        annual_disposable_income: data.annual_disposal_income,
        age_band: data.age_band,
        goal_horizon: data.goal_horizon,
        life_stage: data.life_stage,
      }),
    )
    dispatch(updateSuitabilityData2(data))
    navigate('/recommended-plan')
  }
  const onFormError = (errors: any) => {
    console.log(errors, 'eerrr')
  }

  useEffect(() => {
    if (suitabilityData) {
      Object.entries(suitabilityData).map(([key, value]) => suitabilityForm.setValue(key, value))
    }
  }, [])

  return (
    <>
      <form onSubmit={suitabilityForm.handleSubmit(onFormSubmit)}>
        <Box m='5'>
          <Grid gap={'4'} gridTemplateColumns={'1fr 1fr'} alignItems='end'>
            {RES &&
              ageBand &&
              NestedForm({
                fieldsData: RES?.equitas_life?.life_assurance_requirement,
                form: suitabilityForm,
                disabledCodes: ['age_band'],
                // initialValues: { ...suitabilityData, ...ageBand },
                initialValues: suitabilityData,
              })}
          </Grid>
          <Center>
            <Button type='submit' mt='5'>
              Submit
            </Button>
          </Center>
        </Box>
      </form>
    </>
  )
}

export { Requirement }
