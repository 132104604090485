import { Text, TextProps } from '@chakra-ui/react'

interface IHeadingText extends TextProps {
  label: string
}

const HeadingText = (props: IHeadingText) => {
  const { label, ...rest } = props

  return (
    <Text
      textAlign='center'
      fontSize={['2xl', '3xl', '4xl', '5xl']}
      color='primary.500'
      fontWeight='medium'
      {...rest}
    >
      {label}
    </Text>
  )
}

export { HeadingText }
