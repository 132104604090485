import { forwardRef, Textarea, TextareaProps } from '@chakra-ui/react'

const TextArea = forwardRef((props: TextareaProps, ref) => {
  return (
    <Textarea
      fontWeight={'normal'}
      height='full'
      isDisabled={props.isDisabled}
      resize={'none'}
      _hover={props.isDisabled ? {} : { borderColor: 'secondary.400' }}
      borderRadius='4'
      bg={props.isDisabled ? '#EBECEC' : ''}
      _placeholder={props.isDisabled ? { color: '#000' } : {}}
      color={props.isDisabled ? '#000' : ''}
      ref={ref}
      {...props}
    ></Textarea>
  )
})

export { TextArea }
