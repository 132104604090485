import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useAppSelector, useDeviceType } from 'app/hooks'
import uuid from 'react-uuid'
import { AnimatePresence, motion } from 'framer-motion'
import { getAgeObjFromDob, onlyAllowTypingNumbers } from 'Services/general'
import { ToastService } from 'Services/toast.service'
import { useNavigate } from 'react-router-dom'
import { ErrorText } from 'Core'

interface IMemberProps {
  addMultiple?: boolean
  type: 'proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse'
  isChecked: boolean
  isInvalid?: boolean
  code?: string
  age?: string
  dob?: string
  toggleSelect?: (code: string) => void
  ageChange?: ({ code, age }: { code: string; age: string }) => void
  dobChange?: ({ code, dob }: { code: string; dob: string }) => void
  addMember?: ({ member_type, age, dob }: { member_type: any; age?: any; dob?: any }) => void
  errorText?: string
  isDisabled: boolean
}

interface IMemberState {
  member_type: 'proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse'
  age: string
  selected: boolean
  validated: boolean
  code: string
  errorText: string
  dob?: string
}

export const MemberForm = ({ onSubmit }: { onSubmit: (members: IMemberState[]) => void }) => {
  const { isDesktop } = useDeviceType()
  const { proposer } = useAppSelector((state) => state.memberDetails)
  const navigate = useNavigate()

  const initialMembers: IMemberState[] = [
    {
      member_type: 'proposer',
      age: proposer.age,
      selected: false,
      code: uuid(),
      validated: true,
      errorText: '',
    },
    {
      member_type: 'spouse',
      age: '',
      selected: false,
      code: uuid(),
      validated: true,
      errorText: '',
    },
    {
      member_type: 'mother',
      age: '',
      selected: false,
      code: uuid(),
      validated: true,
      errorText: '',
    },
    {
      member_type: 'father',
      age: '',
      selected: false,
      code: uuid(),
      validated: true,
      errorText: '',
    },
  ]

  const [members, setMembers] = useState<IMemberState[]>([...initialMembers])
  const { memberDetails, isProposerIncluded } = useAppSelector((state) => state.memberDetails)
  const { proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)

  useEffect(() => {
    let fatherIncluded = false
    let motherIncluded = false
    let spouseIncluded = false

    console.log(memberDetails)

    if (Object.keys(memberDetails).length > 0) {
      setMembers(
        Object.keys(memberDetails).map((key) => {
          if (memberDetails[key].member_type === 'father') {
            fatherIncluded = true
          }
          if (memberDetails[key].member_type === 'mother') {
            motherIncluded = true
          }
          if (memberDetails[key].member_type === 'spouse') {
            spouseIncluded = true
          }
          return {
            member_type: memberDetails[key].member_type,
            age: memberDetails[key].age,
            selected: true,
            code: key,
            validated: true,
            errorText: '',
            dob: memberDetails[key].dob ?? '',
          }
        }),
      )

      const initArray: IMemberState[] = []

      initArray.push({
        member_type: 'proposer',
        age: proposer.age,
        selected: isProposerIncluded,
        code: uuid(),
        validated: true,
        errorText: '',
      })
      if (!spouseIncluded) {
        initArray.push({
          member_type: 'spouse',
          age: '',
          selected: false,
          code: uuid(),
          validated: true,
          errorText: '',
        })
      }
      if (!fatherIncluded) {
        initArray.push({
          member_type: 'father',
          age: '',
          selected: false,
          code: uuid(),
          validated: true,
          errorText: '',
        })
      }

      if (!motherIncluded) {
        initArray.push({
          member_type: 'mother',
          age: '',
          selected: false,
          code: uuid(),
          validated: true,
          errorText: '',
        })
      }
      setMembers((prevMember) => {
        return [...initArray, ...prevMember]
      })
    }
    // return () => {}
  }, [])

  const getChildCount = () => {
    let count = 0
    members.map((member) => {
      if (member.member_type === 'son' || member.member_type === 'daughter') {
        if (member.selected) count = count + 1
      }
    })
    return count
  }

  const addMemberHandler = ({
    member_type,
    age,
    dob,
  }: {
    member_type: 'son' | 'daughter'
    age?: string
    dob?: string
  }) => {
    console.log(member_type, age, dob, 'hello')

    if (getChildCount() > 3) {
      ToastService.showErrorToast({
        title: 'Maximum number of children added',
      })
      return
    }

    setMembers((addedMembers) => {
      return [
        ...addedMembers,
        {
          member_type,
          age: age ?? '0',
          code: uuid(),
          selected: true,
          validated: true,
          errorText: '',
          dob: dob,
        },
      ]
    })
  }

  const toggleMemberHandler = (code: string) => {
    setMembers((addedMembers) => {
      addedMembers.forEach((member) => {
        if (member.code === code) {
          member.selected = !member.selected
        }
      })
      return [...addedMembers]
    })
  }
  const onAgeChangeHandler = ({ code, age }: { code: string; age: string }) => {
    setMembers((addedMembers) => {
      addedMembers.forEach((member) => {
        if (member.code === code) {
          ;(member.age = age), (member.validated = true)
        }
      })
      return [...addedMembers]
    })
  }
  const onDobChangeHandler = ({ code, dob }: { code: string; dob: string }) => {
    setMembers((addedMembers) => {
      addedMembers.forEach((member) => {
        if (member.code === code) {
          ;(member.dob = dob), (member.validated = true)
        }
      })
      return [...addedMembers]
    })
  }

  const onFormSubmit = () => {
    let validationFailed = false
    members.forEach((member) => {
      console.log(member.selected && member.age === '')
      if (member.selected) {
        if (member.age === '') {
          member.validated = false
          validationFailed = true
          member.errorText = 'Required'
        }
        if (member.member_type == 'son' || member.member_type == 'daughter') {
          if (parseInt(member.age) > 25) {
            member.validated = false
            validationFailed = true
            member.errorText = 'Age should be less than 25 years'
          }
        } else {
          // for parents
          if (
            (parseInt(member.age) < 25 || parseInt(member.age) > 65) &&
            (member.member_type == 'father' || member.member_type == 'mother')
          ) {
            member.validated = false
            validationFailed = true
            member.errorText = 'Age should be between 25 years to 65 year'
          }
          // for proposer and spouse
          if (
            (parseInt(member.age) < 18 || parseInt(member.age) > 65) &&
            (member.member_type == 'proposer' || member.member_type == 'spouse')
          ) {
            member.validated = false
            validationFailed = true
            member.errorText = 'Age should be between 18 years to 65 year'
          }
        }
      }
    })

    setMembers([...members])

    if (validationFailed) {
      ToastService.showErrorToast({
        title: 'Fill all the details',
      })
    }

    if (!validationFailed) {
      // update on redux state
      const selectedMember = members.filter((member) => member.selected)
      if (selectedMember.map((member) => member.member_type).includes('proposer')) {
        if (selectedMember.length < 2) {
          ToastService.showErrorToast({
            title: 'Please select atleast 1 family member',
          })
          return
        }
      } else {
        if (selectedMember.length < 1) {
          ToastService.showErrorToast({
            title: 'Please select atleast 1 family member',
          })
          return
        }
      }
      onSubmit(members.filter((member) => member.selected))
      ToastService.showSuccessTaost({
        title: 'Members Added',
      })
    }
  }

  return (
    <>
      <Box>
        {members.map((member) => (
          <Member
            key={member.code}
            member={{
              code: member.code,
              isInvalid: !member.validated,
              isChecked: member.selected,
              type: member.member_type,
              age: member.age,
              toggleSelect: toggleMemberHandler,
              ageChange: onAgeChangeHandler,
              errorText: member.errorText,
              dob: member.dob,
              dobChange: onDobChangeHandler,
              isDisabled:
                member.member_type === 'proposer'
                  ? proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.dob
                    ? true
                    : false
                  : false,
            }}
          />
        ))}

        <Member
          member={{
            type: 'son',
            addMember: addMemberHandler,
            isChecked: false,
            addMultiple: true,
            isDisabled: false,
          }}
        />
        <Member
          member={{
            type: 'daughter',
            addMember: addMemberHandler,
            isChecked: false,
            addMultiple: true,
            isDisabled: false,
          }}
        />

        {isDesktop ? (
          <Flex py='4' justifyContent='center' alignItems='center' w='full' gap='4'>
            <Button
              onClick={() => navigate('/')}
              size='lg'
              variant='outline'
              rounded='full'
              textTransform='uppercase'
            >
              Back
            </Button>
            <Button
              onClick={onFormSubmit}
              size='lg'
              variant='solid'
              rounded='full'
              paddingX='8'
              paddingY='2'
            >
              Next
            </Button>
          </Flex>
        ) : (
          <Flex py='4' justifyContent='center' alignItems='center' w='full'>
            <Button onClick={onFormSubmit} variant='solid' rounded='full' w='80%'>
              Next
            </Button>
          </Flex>
        )}
      </Box>
    </>
  )
}

export type { IMemberState }

const Member = ({ member }: { member: IMemberProps }) => {
  const {
    addMultiple = false,
    type,
    isChecked = false,
    isInvalid,
    code,
    age: memberAge,
    addMember,
    toggleSelect,
    ageChange,
    errorText,
    isDisabled = false,
  } = member

  const [age, setAge] = useState(memberAge || '')
  const [childlessThan1, setChildlessThan1] = useState(false)
  const [dob, setDob] = useState('')
  const { isDesktop } = useDeviceType()

  const onCheckBoxClickHandler = (event: ChangeEvent<HTMLInputElement>) => {
    toggleSelect!(code!)
  }

  const onAgeInputChangeHandler = (event: any) => {
    setAge(event.target.value)
    ageChange &&
      ageChange({
        code: code!,
        age: event.target.value,
      })
  }

  const onAddMemberHandler = () => {
    setAge('')

    addMember!({
      member_type: type,
      age: age,
    })
  }
  const onDobMemberHandler = () => {
    console.log('dob', dob)
    if (!dob) {
      ToastService.showErrorToast({
        title: 'Dob is required',
      })
      return
    }
    addMember &&
      addMember({
        member_type: type,
        dob: dob,
      })
  }
  const todayDate = new Date().toISOString().slice(0, 10)
  const date = new Date(todayDate)
  date.setDate(date.getDate() - 91)

  const previousDate = new Date()
  function converNumberFormator(d: number) {
    return d < 10 ? '0' + d.toString() : d.toString()
  }
  const oneYearsFromCurrentDate =
    previousDate.getFullYear() -
    1 +
    '-' +
    converNumberFormator(previousDate.getMonth() + 1) +
    '-' +
    converNumberFormator(previousDate.getDate() + 1)

  return (
    <Flex
      justifyContent='space-between'
      alignItems='center'
      px={isDesktop ? '8' : '3'}
      py='4'
      gap='2'
      borderBottom='1px'
    >
      {/* -------------------- checkbox to include member to store -------------------- */}
      <Stack>
        <Checkbox
          defaultChecked={isChecked}
          disabled={addMultiple}
          onChange={onCheckBoxClickHandler}
          size='lg'
          checked={isChecked}
          colorScheme='primary'
          value='1'
        >
          <Text textTransform='capitalize'>{type}</Text>
        </Checkbox>
      </Stack>
      {/* -------------------- For Son And Daughter Block  -------------------- */}
      {addMultiple && (
        <Box w='full'>
          <Flex justifyContent={'space-between'} w='full' gap='8'>
            <Button
              my='auto'
              onClick={() => {
                if (childlessThan1) {
                  onDobMemberHandler()
                } else onAddMemberHandler()
              }}
              size='xs'
              variant='solid'
            >
              Add
            </Button>
            <Flex direction='column'>
              {/* -------------------- Checkbox for child case  -------------------- */}
              <Box>
                <Checkbox
                  onChange={() => {
                    setChildlessThan1(!childlessThan1)
                  }}
                  size='sm'
                  colorScheme='primary'
                  value='1'
                >
                  Is your Child Less Than 1 year?
                </Checkbox>
              </Box>
              {!childlessThan1 && (
                <>
                  <Input
                    onChange={onAgeInputChangeHandler}
                    value={age}
                    isInvalid={isInvalid}
                    onKeyDown={onlyAllowTypingNumbers}
                    maxLength={2}
                    size='sm'
                    placeholder='Enter Age '
                  />
                  {errorText && isInvalid && <ErrorText text={errorText}></ErrorText>}
                </>
              )}
              {childlessThan1 && (
                <input
                  type='date'
                  onKeyDown={(e) => e.preventDefault()}
                  className='outline-none border text-sm px-3 bg-[#E2E8F0] w-36 py-1 rounded-sm '
                  onChange={(e) => {
                    console.log(e.target.value)
                    setDob(e.target.value)
                  }}
                  max={date.toISOString().slice(0, 10)}
                  min={oneYearsFromCurrentDate}
                  id={code}
                  name='dob'
                />
              )}
            </Flex>
          </Flex>
        </Box>
      )}

      {/* -------------------- For Proposer Spouse Mother Father -------------------- */}

      {!addMultiple && (
        <Flex direction={'column'} justifyContent='between'>
          <Box>
            <Flex w='28' direction='column'>
              <Input
                onChange={onAgeInputChangeHandler}
                value={age == '0' && member.dob ? getAgeObjFromDob(new Date(member.dob!)) : age}
                isInvalid={isInvalid}
                isDisabled={(age == '0' && member.dob) || isDisabled ? true : false}
                onKeyDown={onlyAllowTypingNumbers}
                maxLength={2}
                size='sm'
                placeholder='Enter Age '
              />
              {errorText && isInvalid && <ErrorText text={errorText}></ErrorText>}
            </Flex>
          </Box>
        </Flex>
      )}
    </Flex>
  )
}
