import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useAppSelector, useDeviceType } from 'app/hooks'
import { updateMember, updateProposer } from 'features/Health/memberDetails.health.slice'
import { MemberData } from 'Models/Common/Health.General'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { calculateBMI, getArrayFromObj } from 'Services/general'
import { SelfData } from './SelfData'

export const DataComponent = ({ onSubmit }: { onSubmit: () => void }) => {
  const { isDesktop } = useDeviceType()
  const dispatch = useDispatch()
  const { memberDetails, isProposerIncluded, proposer } = useAppSelector(
    (state) => state.memberDetails,
  )
  const { selectedPlanType, totalAddedMembers } = useAppSelector((state) => state.cart)
  const navigate = useNavigate()

  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const onSubmitHandler = (memberCode: string, data: MemberData, isSelf: boolean) => {
    let totalMemberCount = getArrayFromObj(memberDetails).filter((member) =>
      totalAddedMembers.includes(member.member_type),
    ).length
    if (isProposerIncluded) totalMemberCount = totalMemberCount + 1

    if (isSelf) {
      dispatch(
        updateProposer({
          isProposerIncluded,
          data: {
            ...proposer,
            firstname: data.firstname,
            lastname: data.lastname,
            middlename: data.middlename,
            pincode: data.pincode,
            pincode_map: data.pincode_map,
            permanent_address: data.permanent_address,
            permanentPincode: data.permanentPincode,
            permanent_address_pincode_map: data.permanent_address_pincode_map,
            pan_number: data.pan_number,
            aadhar_card_number: data.aadhar_card_number,
            annual_income: data.annual_income,

            nominee_details: {
              salutation: data.salutation,
              firstname: data.nominee_details.firstname,
              middlename: '',
              lastname: data.nominee_details.lastname,
              dob: data.nominee_details.dob,
              gender: data.nominee_details.gender,
              relationship: data.nominee_details.relationship,
              mobile: data.nominee_details.mobile,
              occupation: data.nominee_details.occupation,
              marital_status: data.nominee_details.marital_status,
              address: data.nominee_details.address,
              pincode_map: data.pincode_map,
            },
            salutation: data.salutation,

            height: data.height,

            educational_qualification: data.educational_qualification,

            weight: data.weight,
            bmi: calculateBMI(parseInt(data.height), parseInt(data.weight)).toString(),
            mobile: data.mobile,
            dob: data.dob,
            marital_status: data.marital_status,
            occupation: data.occupation,
            current_address: data.current_address,
            email: data.email,
            nationality: 'Indian',
          },
        }),
      )
      if (selectedPlanType === 'Individual') {
        onSubmit()
        navigate('/health/proposal/health-questions')
      } else {
        setCurrentTabIndex((prevState) => prevState + 1)
      }

      return
    }
    if (memberDetails) {
      dispatch(
        updateMember({
          memberDetail: data,
          code: memberCode,
        }),
      )
    }

    if (totalMemberCount === currentTabIndex + 1) {
      onSubmit()
      navigate('/health/proposal/health-questions')
    } else {
      setCurrentTabIndex((prevState) => prevState + 1)
    }
  }

  return (
    <>
      <Tabs
        variant='unstyled'
        borderColor='primary'
        borderRadius={'none'}
        bg='white'
        isLazy={true}
        isFitted
        orientation={isDesktop ? 'horizontal' : 'vertical'}
        index={currentTabIndex}
      >
        <TabList>
          {isProposerIncluded && (
            <Tab
              _selected={{ color: 'white', bg: 'primary.200' }}
              onClick={() => {
                setCurrentTabIndex(0)
              }}
            >
              self
            </Tab>
          )}
          {getArrayFromObj(memberDetails)
            .filter((member) => totalAddedMembers.includes(member.member_type))
            .map((member, index) => {
              return (
                <Tab
                  _selected={{ color: 'white', bg: 'primary.200' }}
                  onClick={() => {
                    let offset = 0
                    if (isProposerIncluded) offset = 1
                    if (index + offset < currentTabIndex) {
                      setCurrentTabIndex(index + offset)
                    }
                  }}
                  key={member.code}
                >
                  {member.member_type}
                </Tab>
              )
            })}
        </TabList>

        <TabPanels>
          {isProposerIncluded && (
            <TabPanel>
              <SelfData onSubmit={onSubmitHandler} isSelf={true} memberData={proposer} />
            </TabPanel>
          )}
          {getArrayFromObj(memberDetails)
            .filter((member) => totalAddedMembers.includes(member.member_type))
            .map((member) => {
              return (
                <TabPanel key={member.code}>
                  <SelfData
                    onSubmit={onSubmitHandler}
                    code={member.code}
                    memberData={member}
                    isSelf={false}
                  />
                </TabPanel>
              )
            })}
        </TabPanels>
      </Tabs>
    </>
  )
}
