import { lifeUrlQuery } from 'Services/baseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from 'Constants'
import { ISendEmail, TCustomerDetails } from 'Models/RequestModels'
import { ICustomerSearchDetails, ISpData } from 'Models/ResponseModels/Equitas'
import { ICustomerDetails } from 'Models/ResponseModels/Equitas/CustomerDetails'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import {
  BiQuotesResponse,
  GenericResponse,
  ILifeSalesBankItem,
  ISingleQuotesResponse,
  Life,
  QuotationPostResponse,
} from 'Models/ResponseModels/Life'
import {
  BiQuotesRequest,
  ILifeSalesBankParams,
  ISingleQuotesRequest,
  QuotationPostRequest,
} from 'Models/RequestModels/Life'
// import { IPlanQuotesRequest } from 'Pages/Life/lifeQuotesRequest'
// import { IPlanQuotesResponse } from 'Pages/Life/lifeQuotesResponse'
import { IPlanQuestionsResponse } from 'Pages/Life/PlanQuestionsResponse'
import { IFilterResponse } from 'Models/ResponseModels/Life/FilterResponse'
import { IMotorSalesReportBank } from 'Models/ResponseModels/motor'

export const lifeApi = createApi({
  reducerPath: 'lifeApi',
  baseQuery: lifeUrlQuery,
  endpoints: (builder) => ({
    searchCustomer: builder.query<ICustomerSearchDetails, TCustomerDetails>({
      query: (searchParams) => ({
        url: API_ENDPOINTS.CUSTOMER_SEARCH,
        method: 'GET',
        params: searchParams,
        // headers: { 'Content-Type': 'multipart/form-data', Source: 'Web', Lang: 'EN' },
      }),
    }),
    getCustomerDetails: builder.query<ICustomerDetails, { ucic: string }>({
      query: (searchParams) => ({
        url: API_ENDPOINTS.CUSTOMER_DETAILS,
        method: 'GET',
        params: searchParams,
        // headers: { 'Content-Type': 'multipart/form-data', Source: 'Web', Lang: 'EN' },
      }),
    }),
    getSuitabilityData: builder.query<
      Record<string, Record<string, INestedQuestionSet[]>>,
      { question_type: string }
    >({
      query: (params) => ({
        url: API_ENDPOINTS.GET_SUITIBILITY_QUESTIONS_LIST,
        method: 'GET',
        params: params,
      }),
    }),
    customerDataRequest: builder.mutation<{ life_assured_id: number; proposer_id: number }, any>({
      query: (body: any) => ({
        url: API_ENDPOINTS.CUSTOMER_DATA,
        method: 'POST',
        body,
      }),
    }),

    getLifeRecommendedPlan: builder.mutation<QuotationPostResponse, QuotationPostRequest>({
      query: (body: QuotationPostRequest) => ({
        url: API_ENDPOINTS.LIFE_RECOMMENDED_PLAN,
        method: 'POST',
        body: body,
      }),
    }),
    // getLifeQuotes: builder.mutation<IPlanQuotesResponse, IPlanQuotesRequest>({
    //   query: (body: IPlanQuotesRequest) => ({
    //     url: API_ENDPOINTS.LIFE_QUOTES,
    //     method: 'POST',
    //     body: body,
    //   }),
    // }),
    getLifeFilters: builder.query<IFilterResponse, any>({
      query: (searchParams) => ({
        url: API_ENDPOINTS.GET_SUITIBILITY_QUESTIONS_LIST,
        method: 'GET',
        // params: searchParams,
      }),
    }),
    getRiderMaster: builder.query<
      INestedQuestionSet[],
      {
        plan: string
        plan_data: Record<string, string | number | boolean | string[]>
        la_pr_equal: boolean
      }
    >({
      query: (body) => ({
        url: API_ENDPOINTS.GET_RIDER,
        method: 'POST',
        body: body,
      }),
    }),
    getChoices: builder.query<GenericResponse, any>({
      query: (params) => ({
        url: API_ENDPOINTS.GET_CHOICES,
        method: 'GET',
        params: params,
      }),
    }),
    getLifeSingleQuotes: builder.mutation<ISingleQuotesResponse, ISingleQuotesRequest>({
      query: (body: ISingleQuotesRequest) => ({
        url: API_ENDPOINTS.LIFE_SINGLE_QUOTES,
        method: 'POST',
        body: body,
      }),
    }),
    lifeSalesBank: builder.query<ILifeSalesBankItem, ILifeSalesBankParams>({
      query: (queryParams) => ({
        url: API_ENDPOINTS.LIFE_SALES_REPORT,
        method: 'GET',
        params: queryParams,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    getBiQuote: builder.mutation<BiQuotesResponse, BiQuotesRequest>({
      query: (body: BiQuotesRequest) => ({
        url: API_ENDPOINTS.LIFE_BI_QUOTE,
        method: 'POST',
        body: body,
      }),
    }),
    getPlanQuestionsMaster: builder.query<
      IPlanQuestionsResponse,
      {
        plan: string
        question_type?: string
        is_same?: boolean
      }
    >({
      query: (params) => ({
        url: API_ENDPOINTS.GET_MASTER_QUESTIONS_LIST,
        method: 'GET',
        params: params,
      }),
    }),
    getConsentData: builder.query<
      {
        plan_quote: string
        data: any
        type: string
        status: string
        quote_id?: string
        lead_id?: string
      },
      {
        id: string
      }
    >({
      query: (params) => ({
        url: API_ENDPOINTS.GET_LIFE_CONSENT + '/' + params.id,
        method: 'GET',
      }),
    }),
    createConsentData: builder.mutation<
      { consent_id: number },
      {
        lead_id: string
        data: any
        type: 'BI-APPROVAL'
        quote_id: string
        plan_id: string
        resend?: number
      }
    >({
      query: (body) => ({
        url: API_ENDPOINTS.GET_LIFE_CONSENT,
        method: 'POST',
        body,
      }),
    }),
    updateConsentData: builder.mutation<
      any,
      {
        consent_id: string
        status: 'APPROVED' | 'UNAPPROVED'
      }
    >({
      query: (body) => ({
        url: API_ENDPOINTS.GET_LIFE_CONSENT,
        method: 'PATCH',
        body,
      }),
    }),
    lifeDMSDataPush: builder.mutation<any, any>({
      query: (body: any) => ({
        url: API_ENDPOINTS.LIFE_DMS_DATA_PUSH,
        method: 'POST',
        body: body,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
  }),
})

export const {
  useLazySearchCustomerQuery,
  useLazyGetCustomerDetailsQuery,
  useGetSuitabilityDataQuery,
  useCustomerDataRequestMutation,
  useGetLifeRecommendedPlanMutation,
  // useGetLifeQuotesMutation,
  useLazyGetRiderMasterQuery,
  useLazyGetPlanQuestionsMasterQuery,
  useGetLifeSingleQuotesMutation,
  useGetBiQuoteMutation,
  useLazyGetChoicesQuery,
  useGetChoicesQuery,
  useGetConsentDataQuery,
  useLazyGetConsentDataQuery,
  useUpdateConsentDataMutation,
  useCreateConsentDataMutation,
  useLifeSalesBankQuery,
  useLifeDMSDataPushMutation,
} = lifeApi
