import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISpData } from 'Models/ResponseModels/Equitas'

interface IState {
  selectedSp?: ISpData
  isOtpVerified: boolean
  spConsentStatus: 'not-sent' | 'pending' | 'success'
}

const initialState: IState = {
  isOtpVerified: false,
  spConsentStatus: 'not-sent',
}

const spTaggingSlice = createSlice({
  name: 'spTaggingSlice',
  initialState: initialState,
  reducers: {
    updateSelectedSp: (state, action: PayloadAction<ISpData>) => {
      state.selectedSp = action.payload
    },
    updateIsOtpVerified: (state, action: PayloadAction<boolean>) => {
      state.isOtpVerified = action.payload
    },
    updateSpConsentStatus: (state, action: PayloadAction<'not-sent' | 'pending' | 'success'>) => {
      state.spConsentStatus = action.payload
    },
    updateAllDetailsSpTagging: (state, action: PayloadAction<IState>) => {
      const { selectedSp, isOtpVerified, spConsentStatus } = action.payload
      if (selectedSp) {
        state.selectedSp = selectedSp
      }
      if (isOtpVerified) {
        state.isOtpVerified = isOtpVerified
      }
      if (spConsentStatus) {
        state.spConsentStatus = spConsentStatus
      }
    },
    resetSpTaggingForm: () => initialState,
  },
})

const { reducer } = spTaggingSlice

const {
  updateSelectedSp,
  resetSpTaggingForm,
  updateIsOtpVerified,
  updateSpConsentStatus,
  updateAllDetailsSpTagging,
} = spTaggingSlice.actions

export {
  reducer as spTaggingReducer,
  updateSelectedSp,
  resetSpTaggingForm,
  updateIsOtpVerified,
  updateSpConsentStatus,
  updateAllDetailsSpTagging,
}
