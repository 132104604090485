import {
  Box,
  FormLabel,
  Flex,
  FormControl,
  Spacer,
  FormErrorMessage,
  Text,
  Textarea,
  Grid,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType, useWindowDimensions } from 'app/hooks'
import { BimabossDatePicker, TextInput, ToggleSwitch } from 'Core'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonComponent } from './ButtonComponent'
import {
  decryptData,
  getArrayFromObj,
  onlyAllowTypingAlphabet,
  onlyAllowTypingAlphabetNumeric,
  onlyAllowTypingNumbers,
  pincodeLabelFormatterHealth,
} from 'Services/general'
import {
  useGetMiscellaneousListingQuery,
  useLazyGetPincodeListingQuery,
} from 'Services/API/health.api'
import {
  Select as ChakraSelect,
  ChakraStylesConfig,
  AsyncSelect,
  SingleValue,
} from 'chakra-react-select'
import { updateProposer } from 'features/Health/memberDetails.health.slice'
import { useEffect, useState } from 'react'
import { subYears } from 'date-fns'
import { MemberData } from 'Models/Common/Health.General'
import { HEADER_HEIGHT, mobileNumRegex, onlyAlphabetsRegex, panNumRegex } from 'Constants'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { IHealthPincode, IHealthPincodeOption } from 'Models/ResponseModels/Health'
import { ISaveLeadResponse } from 'Models/ResponseModels'

const chakraStyles: ChakraStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    fontSize: '14px',
    paddingX: '4px',
    width: '12',
    border: '1px solid black.900',
  }),
  dropdownIndicator: (provided, state) => ({
    marginX: '',
  }),
  inputContainer: (provided, state) => ({
    boxSize: '3',
  }),
}

export const LeftComponent = (
  { onSubmit, onBackPress }: { onSubmit: (data: MemberData) => void; onBackPress: () => void },
  data: MemberData,
) => {
  const { isDesktop } = useDeviceType()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { selectedQuotes } = useAppSelector((state) => state.cart)

  const { isProposerIncluded, proposer } = useAppSelector((state) => state.memberDetails)

  const { data: SalutationData } = useGetMiscellaneousListingQuery({
    is_salutation: '1',
    insurance_id: proposer.selectedInsuranceNumber!,
  })
  const { data: MaritalData } = useGetMiscellaneousListingQuery({
    is_marital_status: '1',
    insurance_id: proposer.selectedInsuranceNumber!,
  })

  const { data: OccupationData } = useGetMiscellaneousListingQuery({
    is_occupation: '1',
    insurance_id: proposer.selectedInsuranceNumber!,
  })
  const { data: NomineeRelationshipData } = useGetMiscellaneousListingQuery({
    is_nominee_relationship: '1',
    insurance_id: proposer.selectedInsuranceNumber!,
  })

  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const memberDetails = useAppSelector((state) => state.memberDetails)

  const cart_details = useAppSelector((state) => state.cart)
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()

  const getDobFormat = (date: string) => {
    if (date.includes('-')) {
      return date.split('-').reverse().join('/')
    }
    return date
  }

  const proposerSchema = yup.object().shape({
    pan: yup.string().required('PAN is required!').matches(panNumRegex, 'Invalid Format!'),
    firstName: yup
      .string()
      .required('First Name is required!')
      .min(1, 'Min length is 1 letters')
      .test('len', 'Min length is 1 letters', (text) => {
        if (text?.trim() == '') {
          return false
        }
        if (text!.trim()!.length < 1) {
          return false
        }
        return true
      })
      .test('custom', 'Invalid format', (input) => {
        let shouldAllow = true
        if (input) {
          for (let i = 0; i < input.length; i++) {
            const char = input[i]
            console.log(input[i])

            if (!(char >= 'a' && char <= 'z') && !(char >= 'A' && char <= 'Z') && char !== ' ') {
              console.log('not aphabet')
              shouldAllow = false
              continue
            }
          }
        }
        return shouldAllow
      }),
    middleName: yup.string().test('custom', 'Invalid format', (input) => {
      let shouldAllow = true
      if (input) {
        for (let i = 0; i < input.length; i++) {
          const char = input[i]
          console.log(input[i])

          if (!(char >= 'a' && char <= 'z') && !(char >= 'A' && char <= 'Z') && char !== ' ') {
            console.log('not aphabet')
            shouldAllow = false
            continue
          }
        }
      }
      return shouldAllow
    }),

    salutation: yup.object({
      name: yup.string(),
      code: yup.string().required(),
    }),
    lastName: yup
      .string()
      .required('Last Name is required!')
      .min(1, 'Min length is 1 letters')
      .test('len', 'Min length is 1 letters', (text) => {
        if (text?.trim() == '') {
          return false
        }
        if (text!.trim()!.length < 1) {
          return false
        }
        return true
      })
      .test('custom', 'Invalid format', (input) => {
        let shouldAllow = true
        if (input) {
          for (let i = 0; i < input.length; i++) {
            const char = input[i]
            console.log(input[i])

            if (!(char >= 'a' && char <= 'z') && !(char >= 'A' && char <= 'Z') && char !== ' ') {
              console.log('not aphabet')
              shouldAllow = false
              continue
            }
          }
        }
        return shouldAllow
      }),
    occupation: yup.object({
      name: yup.string(),
      code: yup.string().required('Occupation is required!'),
    }),
    annual_income: yup.string().required('Annual Income is required!').max(14, 'Max limit is 14'),

    maritalStatus: yup
      .object({
        name: yup.string(),
        code: yup.string().required('Marital Status is required!'),
      })
      .required('Marital Status is required!'),
    phoneNumber: yup
      .string()
      .required('Phone Number is required!')
      .matches(mobileNumRegex, 'Invalid Format!'),
    email: yup.string().required('Email is required!').email('Invalid format!'),
    dob: yup.string().required('Date of Birth is required!'),

    // --------------- new added ---------------

    current_address: yup.string().required('Current Address is required!'),
    pincode: yup.string().required('Pincode is required!'),
    nomineeSalutation: yup.object({
      name: yup.string(),
      code: yup.string().required('Nominee Salutation is required!'),
    }),
    // yup.string().required('Nominee Salutation is required!'),
    nomineeFullName: yup
      .string()
      .required(' Nominee Full Name is required!')
      .min(1, 'Min length is 1 letters')
      .test('len', 'Min length is 1 letters', (text) => {
        if (text?.trim() == '') {
          return false
        }
        if (text!.trim()!.length < 1) {
          return false
        }
        return true
      }),
    nomineeRelationship: yup.object({
      name: yup.string(),
      code: yup.string().required('Nominee Relationship is required!'),
    }),
    nomineeDob: yup.string().required('Nominee Date Of Birth is required!'),
    nomineeGender: yup.string().required(' Nominee Gender is required!'),
    nomineeAddress: yup.string().required(' Nominee Address is required!'),
    nomineeMobile: yup
      .string()
      .required(' Nominee Mobile is required!')
      .matches(mobileNumRegex, 'Invalid Format!'),
    nomineeMaritalStatus: yup.object({
      name: yup.string(),
      code: yup.string().required(' Nominee Marital Status is required!'),
    }),
    nomineeOccupation: yup.object({
      name: yup.string(),
      code: yup.string().required(' Nominee Occupation is required!'),
    }),
    mothersName: yup.string().when('showPehchaanData', {
      is: true,
      then: yup.string().required('Mother&apos;s Name is required!'),
    }),
    organizationType: yup.string().when('showPehchaanData', {
      is: true,
      then: yup.string().required('Organization Type is required!'),
    }),
    showPehchaanData: yup.boolean(),
  })

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: memberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('proposer-insurer-details', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState(
    proposer.is_politically_exposed ?? false,
  )
  const [isIndianResident, setIsIndianResident] = useState(proposer.resident_indian_flag ?? true)

  const politicallyExposedToggleChangeHandler = (isExposed: boolean) => {
    setIsPoliticallyExposed(isExposed)
    setValue('isPoliticallyExposed', isExposed)
  }
  const IndianToggleChangeHandler = (isIndian: boolean) => {
    setIsIndianResident(isIndian)
    setValue('isIndian', isIndian)
  }
  const [annualIncomeListing, setAnnualIncomeListing] = useState<string[]>([
    '0-2.5 lacs',
    '2.5-5 lacs',
    '5-15 lacs',
    '15-20 lacs',
    '20-30 lacs',
    '30+ lacs',
  ])
  const [organizationTypeListing, setOrganizationTypeListing] = useState<string[]>([
    'Government',
    'Private Limited',
    'Public Limited',
    'Trust',
    'Other',
  ])

  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposer.annual_income
      ? {
          label: proposer.annual_income,
          value: proposer.annual_income,
        }
      : {
          label: 'Select Annual Income',
          value: '',
        },
  )

  const [selectedOrganizationType, setSelectedOrganizationType] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposer.organization_type
      ? {
          label: proposer.organization_type,
          value: proposer.organization_type,
        }
      : {
          label: 'Select Organization Type',
          value: '',
        },
  )

  type FormValues = {
    firstName: string
    middleName: string
    lastName: string
    salutation: {
      name: string
      code: string
    } | null
    maritalStatus: {
      name: string
      code: string
    } | null
    phoneNumber: string
    email: string
    dob: string
    pan: string

    // New Added Keys
    occupation: {
      name: string
      code: string
    }
    annual_income: string
    pincode: string
    current_address: string
    nomineeFullName: string
    nomineeRelationship: {
      name: string
      code: string
    }
    nomineeDob: string
    nomineeGender: string
    nomineeMobile: string
    nomineeSalutation: {
      name: string
      code: string
    }
    nomineeAddress: string
    nomineeMaritalStatus: {
      name: string
      code: string
    }
    nomineeOccupation: {
      name: string
      code: string
    }
    isPoliticallyExposed: boolean
    isIndian: boolean
    mothersName: string
    organizationType: string
    showPehchaanData: boolean
  }

  const [dob, setDob] = useState(proposer.dob)
  const [nomineeDob, setNomineeDob] = useState(
    proposer.nominee_details.dob ? proposer.nominee_details.dob : '',
  )
  const [pincodeInput, setPincodeInput] = useState('')

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(proposerSchema),
    defaultValues: {
      pan: proposer.pan_number ?? '',
      firstName: proposer.firstname ?? '',
      middleName: proposer.middlename ?? '',

      lastName: proposer.lastname ?? '',
      salutation: proposer.salutation,
      phoneNumber: proposer.mobile,
      email: proposer.email ?? '',
      dob: getDobFormat(proposer.dob),
      maritalStatus: proposer.marital_status,
      // New Keys Added
      occupation: proposer.occupation,
      annual_income: proposer.annual_income ?? '',
      pincode: proposer.pincode_map.pincode,
      current_address: proposer.current_address ?? '',

      nomineeFullName:
        proposer.nominee_details.firstname && proposer.nominee_details.lastname
          ? proposer.nominee_details.firstname + ' ' + proposer.nominee_details.lastname
          : '',
      nomineeRelationship: proposer.nominee_details.relationship,
      nomineeDob: proposer.nominee_details.dob ?? '',

      nomineeGender: proposer.nominee_details.gender ?? '',
      nomineeMobile: proposer.nominee_details.mobile ?? '',
      nomineeSalutation: proposer.nominee_details.salutation,
      nomineeAddress: proposer.nominee_details.address ?? '',
      nomineeMaritalStatus: proposer.nominee_details.marital_status,
      nomineeOccupation: proposer.nominee_details.occupation,
      mothersName: proposer.mother_name,
      organizationType: proposer.organization_type,
      showPehchaanData: Object.values(selectedQuotes)[0]?.insuranceName.includes('HDFC'),
    },
  })

  useEffect(() => {
    setValue('dob', dob)
  }, [dob])

  const onFormSubmit = (data: any) => {
    const nomineeName = data.nomineeFullName.trim().split(' ')
    const nomineeFirstName = nomineeName[0]
    const nomineeLastNameWithJoin = nomineeName.slice(1).join(' ')
    const nomineeLastname = nomineeLastNameWithJoin

    if (Object.keys(errors).length) {
      return
    }
    console.log(data)
    dispatch(
      updateProposer({
        isProposerIncluded,
        data: {
          ...proposer,
          dob: data.dob,
          marital_status: data.maritalStatus,
          pan_number: data.pan,
          mobile: data.phoneNumber,
          salutation: data.salutation,
          firstname: data.firstName,
          middlename: data.middleName,
          lastname: data.lastName,
          email: data.email,
          pincode: pincode!.pincode!,
          pincode_map: pincode!,
          annual_income: data.annual_income,
          occupation: data.occupation,
          current_address: data.current_address,
          nationality: 'Indian',
          mother_name: data.mothersName,
          organization_type: data.organizationType,
          resident_indian_flag: data.isIndian,
          is_politically_exposed: data.isPoliticallyExposed,
          nominee_details: {
            salutation: data.nomineeSalutation,
            firstname: nomineeFirstName,
            middlename: '',
            lastname: nomineeLastname ? nomineeLastname : '.',
            dob: data.nomineeDob,
            gender: data.nomineeGender,
            relationship: data.nomineeRelationship,
            mobile: data.nomineeMobile,
            occupation: data.nomineeOccupation,
            marital_status: data.nomineeMaritalStatus,
            address: data.nomineeAddress,
            pincode_map: pincode!,
          },
        },
      }),
    )
    createLead()
    console.log(data, 'formData')
    onSubmit(data)
  }

  const onFormError = (errors: any) => {
    console.log(errors)
  }

  const [pincode, setPincode] = useState<IHealthPincode>(proposer.pincode_map)
  const [getPincode] = useLazyGetPincodeListingQuery()

  const handlePincodeSelectChange = (selectedPincode: SingleValue<IHealthPincode>) => {
    if (selectedPincode?.pincode) {
      const selected = {
        ...selectedPincode,
        label: selectedPincode.pincode.toString(),
      }
      setPincode(selected)
      setValue('pincode', selected.pincode)
      clearErrors('pincode')
    }
  }

  useEffect(() => {
    setValue('dob', dob)
  }, [dob])

  useEffect(() => {
    setValue('nomineeDob', nomineeDob)
  }, [nomineeDob])

  useEffect(() => {
    if (proposer.pincode.toString().length === 6) {
      getPincode({
        pincode: proposer.pincode,
        insurance_id: proposer.selectedInsuranceNumber!,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes && pincodes.length) {
          const selected = {
            ...pincodes[0],
            label: pincodes[0].pincode.toString(),
          }
          setPincode({
            ...selected,
          })

          setValue('pincode', selected.pincode)
          clearErrors('pincode')
        }
      })
    }
  }, [proposer.pincode])

  const loadPincodeOptions = (
    inputSearchValue: string,
    callback: (options: IHealthPincodeOption[]) => void,
  ) => {
    if (inputSearchValue.length > 2 && inputSearchValue.length < 7) {
      getPincode({
        pincode: inputSearchValue,
        insurance_id: proposer.selectedInsuranceNumber!,
      }).then((resp: any) => {
        const pincodes = resp.data?.result
        if (pincodes) callback(pincodeLabelFormatterHealth(pincodes))
      })
    }
  }
  return (
    <>
      <Box
        h='600px'
        overflowY='scroll'
        position='relative'
        borderRight={isDesktop ? '1px solid #D8D8D8' : ''}
      >
        <Box>
          <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
            <Box p={isDesktop ? '4' : '0'}>
              <Box marginY='2' w='full'>
                <FormControl isInvalid={errors.pan ? true : false}>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    Pan Card <span className=' text-red-500'> *</span>
                  </FormLabel>
                  <TextInput
                    onKeyDown={onlyAllowTypingAlphabetNumeric}
                    isDisabled={
                      !!equitasDetails?.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.panNb
                    }
                    {...register('pan')}
                    placeholder='Enter Pan Card'
                    maxLength={10}
                  />
                  {errors.pan && (
                    <FormErrorMessage>{errors.pan?.message?.toString()}</FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Flex>
                <Box marginY='2' w='100%'>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    First Name <span className=' text-red-500'> *</span>
                  </FormLabel>
                  <Flex>
                    <FormControl isInvalid={errors.salutation ? true : false}>
                      <Box minW='30%'>
                        <ChakraSelect
                          {...register('salutation')}
                          placeholder='Select'
                          maxMenuHeight={200}
                          isInvalid={errors.salutation ? true : false}
                          useBasicStyles
                          // chakraStyles={chakraStyles}

                          isSearchable={false}
                          defaultValue={{
                            label: proposer.salutation?.name,
                            value: proposer.salutation?.code,
                          }}
                          options={getArrayFromObj(SalutationData?.salutations)?.map((item) => {
                            return {
                              label: item.name.toString(),
                              value: item.code.toString(),
                            }
                          })}
                          onChange={(item: any) => {
                            if (getMasterSalutaionFromInsurer(item.value) === 'OTHER') {
                              setValue('salutation', { name: item!.label!, code: item!.value! })
                              clearErrors('salutation')
                              return
                            } else if (
                              getMasterSalutaionFromInsurer(item.value) ===
                              proposer.gender.toUpperCase()
                            ) {
                              setValue('salutation', { name: item!.label!, code: item!.value! })
                              clearErrors('salutation')
                            } else {
                              // setValue('salutation', { name: item!.label!, code: item!.value! })
                              console.log('this should not come')
                              setError('salutation', {
                                type: 'custom',
                                message: 'Not matching with gender',
                              })
                            }
                          }}
                        />
                        {errors.salutation && (
                          <FormErrorMessage>
                            {errors.salutation?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </Box>
                    </FormControl>
                    <FormControl isInvalid={errors.firstName ? true : false}>
                      <TextInput
                        {...register('firstName')}
                        isDisabled={
                          !!equitasDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                            ?.IndCustDtlsRp?.[0].frsNm
                        }
                        placeholder='Enter First Name'
                        onKeyDown={onlyAllowTypingAlphabet}
                      />
                      {errors.firstName && (
                        <FormErrorMessage>{errors.firstName?.message?.toString()}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Flex>
                </Box>
              </Flex>
              <Flex w='full' gap='2'>
                <FormControl>
                  <Box marginY='2'>
                    <FormLabel fontSize='sm' fontWeight='medium'>
                      Middle Name
                    </FormLabel>

                    <TextInput
                      {...register('middleName')}
                      isDisabled={
                        !!equitasDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                          ?.IndCustDtlsRp?.[0].mdlNm
                      }
                      placeholder='Enter Middle Name'
                      onKeyDown={onlyAllowTypingAlphabet}
                    />
                  </Box>
                </FormControl>
                <FormControl isInvalid={errors.lastName ? true : false}>
                  <Box marginY='2'>
                    <FormLabel fontSize='sm' fontWeight='medium'>
                      Last Name <span className=' text-red-500'> *</span>
                    </FormLabel>

                    <TextInput
                      {...register('lastName')}
                      isDisabled={
                        !!equitasDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                          ?.IndCustDtlsRp?.[0].lstNm
                      }
                      placeholder='Enter Last Name'
                      onKeyDown={onlyAllowTypingAlphabet}
                    />
                    {errors.lastName && (
                      <FormErrorMessage>{errors.lastName?.message?.toString()}</FormErrorMessage>
                    )}
                  </Box>
                </FormControl>
              </Flex>
              <Flex>
                <FormControl isInvalid={errors.maritalStatus ? true : false}>
                  <Box marginY='2'>
                    <FormLabel fontSize='sm' fontWeight='medium'>
                      Marital Status <span className=' text-red-500'> *</span>
                    </FormLabel>
                    <Box width={'100%'}>
                      <ChakraSelect
                        {...register('maritalStatus')}
                        maxMenuHeight={200}
                        isInvalid={errors.maritalStatus ? true : false}
                        placeholder='Select'
                        defaultValue={{
                          label: proposer.marital_status?.name,
                          value: proposer.marital_status?.code,
                        }}
                        isRequired
                        isSearchable={false}
                        options={getArrayFromObj(MaritalData?.marital_status)?.map((item) => {
                          return {
                            label: item.name.toString(),
                            value: item.code.toString(),
                          }
                        })}
                        onChange={(item: any) => {
                          setValue('maritalStatus', { name: item!.label!, code: item!.value! })
                          clearErrors('maritalStatus')
                        }}
                        useBasicStyles
                        // chakraStyles={chakraStyles}
                      />
                    </Box>
                    {errors.maritalStatus && (
                      <FormErrorMessage>
                        {errors.maritalStatus?.code?.message?.toString()}
                      </FormErrorMessage>
                    )}
                  </Box>
                </FormControl>
                <Box marginY='2' w='full'>
                  <FormControl isInvalid={errors.phoneNumber ? true : false}>
                    <FormLabel fontSize='sm' fontWeight='medium'>
                      Mobile Number <span className=' text-red-500'> *</span>
                    </FormLabel>
                    <TextInput
                      placeholder='+91'
                      {...register('phoneNumber')}
                      isDisabled={
                        !!equitasDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                          ?.IndCustDtlsRp?.[0].mblNb
                      }
                      onKeyDown={onlyAllowTypingNumbers}
                      maxLength={10}
                      minLength={10}
                    />
                    {errors.phoneNumber && (
                      <FormErrorMessage>{errors.phoneNumber?.message?.toString()}</FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              </Flex>

              <FormControl isInvalid={errors.email ? true : false}>
                <Box marginY='2' w='full'>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    Email <span className=' text-red-500'> *</span>
                  </FormLabel>

                  <TextInput
                    {...register('email')}
                    isDisabled={
                      !!equitasDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                        ?.IndCustDtlsRp?.[0].email
                    }
                    placeholder='Enter Email'
                    type={'email'}
                  />
                  {errors.email && (
                    <FormErrorMessage>{errors.email?.message?.toString()}</FormErrorMessage>
                  )}
                </Box>
              </FormControl>
              <FormControl isInvalid={errors.dob ? true : false}>
                <Box marginY='2' w='full'>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    Date Of Birth <span className=' text-red-500'> *</span>
                  </FormLabel>
                  <BimabossDatePicker
                    clearErrors={() => clearErrors('dob')}
                    inputDate={dob}
                    isNonEditable={
                      !!equitasDetails?.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob
                    }
                    isDisabled={
                      !!equitasDetails?.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob
                    }
                    setInputDate={setDob}
                    // maxDate={subYears(new Date(), 18)}
                    minDate={subYears(new Date(), parseInt(proposer.age) + 1)}
                    maxDate={subYears(new Date(), parseInt(proposer.age))}
                    {...register('dob', { required: true })}
                    isRequired
                  />
                  {errors.dob && (
                    <FormErrorMessage>{errors.dob?.message?.toString()}</FormErrorMessage>
                  )}
                </Box>
              </FormControl>

              {/* New fields Added  */}
              <Box marginY='2'>
                <FormControl isInvalid={errors.occupation ? true : false}>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    Occupation <span className=' text-red-500'> *</span>
                  </FormLabel>

                  <ChakraSelect
                    {...register('occupation')}
                    maxMenuHeight={200}
                    isInvalid={errors.occupation ? true : false}
                    placeholder='Select'
                    useBasicStyles
                    // chakraStyles={chakraStyles}
                    // isSearchable={false}
                    defaultValue={{
                      label: proposer.occupation?.name,
                      value: proposer.occupation?.code,
                    }}
                    options={getArrayFromObj(OccupationData?.occupations)?.map((item) => {
                      return {
                        label: item.name.toString(),
                        value: item.code.toString(),
                      }
                    })}
                    onChange={(item: any) => {
                      setValue('occupation', { name: item!.label, code: item!.value })
                      clearErrors('occupation')
                    }}
                  />
                  {errors.occupation && (
                    <FormErrorMessage>
                      {errors.occupation.code?.message?.toString()}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>

              <Box marginY='2'>
                <FormControl isInvalid={errors.annual_income ? true : false}>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    Annual Income<span className=' text-red-500'> *</span>
                  </FormLabel>

                  <TextInput
                    {...register('annual_income')}
                    placeholder='Enter Annual Income'
                    onKeyDown={onlyAllowTypingNumbers}
                    maxLength={14}
                  />
                  {errors.annual_income && (
                    <FormErrorMessage>{errors.annual_income?.message?.toString()}</FormErrorMessage>
                  )}
                </FormControl>
              </Box>

              <Box marginY='2'>
                <FormControl isInvalid={errors.pincode ? true : false}>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    Pin Code <span className=' text-red-500'> *</span>
                  </FormLabel>
                  <AsyncSelect
                    className='w-full'
                    placeholder='Select Pincode'
                    onInputChange={(input) => {
                      if (input.length < 7) setPincodeInput(input)
                    }}
                    inputValue={pincodeInput}
                    {...register('pincode')}
                    onKeyDown={onlyAllowTypingNumbers}
                    loadOptions={loadPincodeOptions}
                    onChange={handlePincodeSelectChange}
                    value={pincode}
                    defaultInputValue={pincode?.pincode.toString()}
                    isDisabled={equitasDetails.fieldsFetchedFromCbsMapping.customerPincode}
                  />
                  {errors.pincode && (
                    <FormErrorMessage>{errors.pincode?.message?.toString()}</FormErrorMessage>
                  )}
                </FormControl>
              </Box>

              <Box marginY='2'>
                <FormControl isInvalid={errors.current_address ? true : false}>
                  <FormLabel fontSize='sm' fontWeight='medium'>
                    Current Address1 <span className=' text-red-500'> *</span>
                  </FormLabel>
                  <Textarea
                    placeholder='Enter Current Address'
                    {...register('current_address')}
                    isDisabled={
                      !!equitasDetails?.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp
                        ?.crntAddrLn1
                    }
                  />
                  {errors.current_address && (
                    <FormErrorMessage>
                      {errors.current_address?.message?.toString()}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              {getValues('showPehchaanData') && (
                <Flex direction='column' marginY='4'>
                  <FormControl isInvalid={errors.mothersName ? true : false}>
                    <FormLabel>Mother&apos;s Name*</FormLabel>
                    <TextInput
                      {...register('mothersName')}
                      placeholder="Enter Mother's Name"
                      onKeyDown={onlyAllowTypingAlphabet}
                    />
                    {errors.mothersName && errors.mothersName?.type === 'required' && (
                      <FormErrorMessage>{errors.mothersName?.message?.toString()}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              )}
              {getValues('showPehchaanData') && (
                <Grid marginY='4' gap='2' gridTemplateColumns='1fr 1fr'>
                  <Flex direction='column'>
                    <FormLabel>Is proposer politically exposed?</FormLabel>
                    <Box>
                      <ToggleSwitch
                        enabled={isPoliticallyExposed}
                        setEnabled={setIsPoliticallyExposed}
                        changeHandler={politicallyExposedToggleChangeHandler}
                        enabledLabel='Yes'
                        disabledLabel='No'
                      />
                    </Box>
                  </Flex>
                  <Flex direction='column'>
                    <FormLabel>Is proposer a citizen of India?</FormLabel>
                    <Box>
                      <ToggleSwitch
                        enabled={isIndianResident}
                        setEnabled={setIsIndianResident}
                        changeHandler={IndianToggleChangeHandler}
                        enabledLabel='Yes'
                        disabledLabel='No'
                      />
                    </Box>
                  </Flex>
                </Grid>
              )}
              {getValues('showPehchaanData') && (
                <Flex direction='column' marginY='4'>
                  <FormControl isInvalid={errors.organizationType ? true : false}>
                    <FormLabel>Organization Type</FormLabel>
                    <ChakraSelect
                      isSearchable={false}
                      selectedOptionColor='primary'
                      menuPosition='fixed'
                      placeholder='Select Organization Type'
                      {...register('organizationType', { required: true })}
                      value={selectedOrganizationType}
                      onChange={(item) => {
                        setSelectedOrganizationType({
                          label: item?.label ?? '',
                          value: item?.value ?? '',
                        })
                        setValue('organizationType', item?.value ?? '')
                        clearErrors('organizationType')
                      }}
                      options={organizationTypeListing.map((organizationType) => {
                        return {
                          label: organizationType,
                          value: organizationType,
                        }
                      })}
                    />
                    {errors.organizationType && (
                      <FormErrorMessage>
                        {errors.organizationType?.message?.toString()}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              )}
              {/* {getValues('showPehchaanData') && (
                <Flex direction='column' marginY='4'>
                  <FormControl isInvalid={errors.annual_income ? true : false}>
                    <FormLabel>Annual Income</FormLabel>
                    <ChakraSelect
                      isSearchable={false}
                      selectedOptionColor='primary'
                      menuPosition='fixed'
                      placeholder='Select Annual Income'
                      {...register('annual_income')}
                      value={selectedAnnualIncome}
                      onChange={(item) => {
                        setSelectedAnnualIncome({
                          label: item?.label ?? '',
                          value: item?.value ?? '',
                        })
                        setValue('annual_income', item?.value ?? '')
                        clearErrors('annual_income')
                      }}
                      options={annualIncomeListing.map((annualIncome) => {
                        return {
                          label: annualIncome,
                          value: annualIncome,
                        }
                      })}
                    />
                    {errors.annual_income && (
                      <FormErrorMessage>
                        {errors.annual_income?.message?.toString()}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              )} */}
              <Box>
                <Box>
                  <Box marginY='2'>
                    <FormLabel fontSize='sm' fontWeight='medium'>
                      Nominee Full Name <span className=' text-red-500'> *</span>
                    </FormLabel>
                    <Flex>
                      <Box width='40%'>
                        <FormControl isInvalid={errors.nomineeSalutation ? true : false}>
                          <ChakraSelect
                            {...register('nomineeSalutation')}
                            maxMenuHeight={200}
                            placeholder='Select'
                            useBasicStyles
                            // chakraStyles={chakraStyles}
                            isSearchable={false}
                            defaultValue={{
                              label: proposer.nominee_details.salutation?.name,
                              value: proposer.nominee_details.salutation?.code,
                            }}
                            options={getArrayFromObj(SalutationData?.salutations)?.map((item) => {
                              return {
                                label: item.name.toString(),
                                value: item.code.toString(),
                              }
                            })}
                            onChange={(item: any) => {
                              if (getValues('nomineeGender')) {
                                if (getMasterSalutaionFromInsurer(item.label) === 'OTHER') {
                                  setValue('nomineeSalutation', {
                                    name: item!.label,
                                    code: item!.value,
                                  })
                                  clearErrors('nomineeSalutation')
                                  clearErrors('nomineeGender')
                                  return
                                } else if (
                                  getMasterSalutaionFromInsurer(item.label) ===
                                  getValues('nomineeGender').toUpperCase()
                                ) {
                                  setValue('nomineeSalutation', {
                                    name: item!.label,
                                    code: item!.value,
                                  })
                                  clearErrors('nomineeSalutation')
                                  clearErrors('nomineeGender')
                                } else {
                                  setValue('nomineeSalutation', {
                                    name: item!.label,
                                    code: item!.value,
                                  })
                                  setError('nomineeSalutation', {
                                    type: 'custom',
                                    message: 'Not matching with gender',
                                  })
                                }
                              } else {
                                setValue('nomineeSalutation', {
                                  name: item!.label,
                                  code: item!.value,
                                })
                                clearErrors('nomineeSalutation')
                                clearErrors('nomineeGender')
                              }
                            }}
                          />
                          {errors.nomineeSalutation && (
                            <FormErrorMessage>
                              {errors.nomineeSalutation?.message?.toString()}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <FormControl isInvalid={errors.nomineeFullName ? true : false}>
                        <TextInput
                          placeholder='Enter Nominee Name'
                          {...register('nomineeFullName')}
                          onKeyDown={onlyAllowTypingAlphabet}
                        />
                        {errors.nomineeFullName && (
                          <FormErrorMessage>
                            {errors.nomineeFullName?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeDob ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Date of Birth <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <BimabossDatePicker
                        clearErrors={() => clearErrors('nomineeDob')}
                        inputDate={nomineeDob}
                        setInputDate={setNomineeDob}
                        maxDate={subYears(new Date(), 18)}
                        {...register('nomineeDob', { required: true })}
                      />
                      {errors.nomineeDob && (
                        <FormErrorMessage>
                          {errors.nomineeDob?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeGender ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Gender <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <ChakraSelect
                        {...register('nomineeGender')}
                        isInvalid={errors.nomineeGender ? true : false}
                        placeholder='Select'
                        useBasicStyles
                        chakraStyles={chakraStyles}
                        defaultValue={{
                          label: proposer.nominee_details.gender,
                          value: proposer.nominee_details.gender,
                        }}
                        isSearchable={false}
                        options={[
                          {
                            label: 'Male',
                            value: 'Male',
                          },
                          {
                            label: 'Female',
                            value: 'Female',
                          },
                        ]}
                        onChange={(item: any) => {
                          console.log(getValues('nomineeSalutation'), 'check meee ')
                          if (getValues('nomineeSalutation').code) {
                            if (
                              getMasterSalutaionFromInsurer(getValues('nomineeSalutation').name) ===
                              'OTHER'
                            ) {
                              setValue('nomineeGender', item!.value)
                              clearErrors('nomineeGender')
                              clearErrors('nomineeSalutation')
                              console.log('item for other', item)
                              return
                            }
                            if (
                              getMasterSalutaionFromInsurer(getValues('nomineeSalutation').name) ===
                              item!.value.toUpperCase()
                            ) {
                              setValue('nomineeGender', item!.value)
                              clearErrors('nomineeGender')
                              clearErrors('nomineeSalutation')
                              console.log('item', item)
                            } else {
                              setValue('nomineeGender', item!.value)
                              setError('nomineeGender', {
                                type: 'custom',
                                message: 'Not matchting with salutation',
                              })
                            }
                          } else {
                            setValue('nomineeGender', item!.value)
                            clearErrors('nomineeGender')
                            clearErrors('nomineeSalutation')
                          }
                        }}
                      />
                      {errors.nomineeGender && (
                        <FormErrorMessage>
                          {errors.nomineeGender?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeMobile ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Mobile Number <span className=' text-red-500'> *</span>
                      </FormLabel>

                      <TextInput
                        placeholder='Enter Nominee Mobile Number'
                        {...register('nomineeMobile')}
                        onKeyDown={onlyAllowTypingNumbers}
                        maxLength={10}
                      />
                      {errors.nomineeMobile && (
                        <FormErrorMessage>
                          {errors.nomineeMobile?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeOccupation ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Occupation <span className=' text-red-500'> *</span>
                      </FormLabel>

                      <ChakraSelect
                        {...register('nomineeOccupation')}
                        isInvalid={errors.nomineeOccupation ? true : false}
                        placeholder='Select'
                        useBasicStyles
                        // chakraStyles={chakraStyles}
                        maxMenuHeight={200}
                        // isSearchable={false}
                        defaultValue={{
                          label: proposer.nominee_details.occupation?.name,
                          value: proposer.nominee_details.occupation?.code,
                        }}
                        options={getArrayFromObj(OccupationData?.occupations)?.map((item) => {
                          return {
                            label: item.name.toString(),
                            value: item.code.toString(),
                          }
                        })}
                        onChange={(item: any) => {
                          setValue('nomineeOccupation', { name: item!.label, code: item!.value })
                          clearErrors('nomineeOccupation')
                        }}
                      />
                      {errors.nomineeOccupation && (
                        <FormErrorMessage>
                          {errors.nomineeOccupation.code?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeMaritalStatus ? true : false}>
                      <Box marginY='2'>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Nominee Marital Status <span className=' text-red-500'> *</span>
                        </FormLabel>
                        <Box width={'100%'}>
                          <ChakraSelect
                            {...register('nomineeMaritalStatus')}
                            maxMenuHeight={200}
                            isInvalid={errors.nomineeMaritalStatus ? true : false}
                            placeholder='Select'
                            useBasicStyles
                            // chakraStyles={chakraStyles}
                            isSearchable={false}
                            defaultValue={{
                              label: proposer.nominee_details.marital_status?.name,
                              value: proposer.nominee_details.marital_status?.code,
                            }}
                            options={getArrayFromObj(MaritalData?.marital_status)?.map((item) => {
                              return {
                                label: item.name.toString(),
                                value: item.code.toString(),
                              }
                            })}
                            onChange={(item: any) => {
                              setValue('nomineeMaritalStatus', {
                                name: item!.label,
                                code: item!.value,
                              })
                              clearErrors('nomineeMaritalStatus')
                            }}
                          />
                        </Box>
                        {errors.nomineeMaritalStatus && (
                          <FormErrorMessage>
                            {errors.nomineeMaritalStatus.code?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </Box>
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeRelationship ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Relationship <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <ChakraSelect
                        {...register('nomineeRelationship')}
                        isInvalid={errors.nomineeRelationship ? true : false}
                        placeholder='Select'
                        useBasicStyles
                        // chakraStyles={chakraStyles}
                        maxMenuHeight={150}
                        isSearchable={false}
                        defaultValue={{
                          label: proposer.nominee_details.relationship?.name,
                          value: proposer.nominee_details.relationship?.code,
                        }}
                        options={getArrayFromObj(
                          NomineeRelationshipData?.nominee_relationship,
                        )?.map((item) => {
                          return {
                            label: item.name.toString(),
                            value: item.code.toString(),
                          }
                        })}
                        onChange={(item: any) => {
                          setValue('nomineeRelationship', { name: item!.label, code: item!.value })
                          clearErrors('nomineeRelationship')
                        }}
                      />
                      {errors.nomineeRelationship && (
                        <FormErrorMessage>
                          {errors.nomineeRelationship.code?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeAddress ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Address <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <Textarea placeholder='Enter Address' {...register('nomineeAddress')} />
                      {errors.nomineeAddress && (
                        <FormErrorMessage>
                          {errors.nomineeAddress?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              position='sticky'
              bottom='0'
              left='0'
              w='full'
              zIndex='modal'
              opacity='inherit'
              roundedBottomLeft='2xl'
              boxShadow='0px -2px 6px #00000029'
            >
              <ButtonComponent
                onLeftButtonClick={() => {
                  onBackPress()
                }}
                left='Back'
                right='Next'
              />
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

const getMasterSalutaionFromInsurer = (insuranceSalutaion: string) => {
  if (['mr', 'mr.', 'master', 'mister'].includes(insuranceSalutaion.toLowerCase())) {
    return 'MALE'
  }
  if (
    ['mrs', 'ms', 'ms.', 'mrs.', 'miss', 'miss.', 'm/s'].includes(insuranceSalutaion.toLowerCase())
  ) {
    return 'FEMALE'
  } else {
    return 'OTHER'
  }
}
