import { Box, Flex, FormLabel } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useVehicleType } from 'app/hooks'
import { store } from 'app/store'
import { Select, SingleValue } from 'chakra-react-select'
import { BimabossDatePicker, ElevatedButton, TextInput } from 'Core'
import {
  resetModel,
  resetVariant,
  updateManufacturer,
  updateManufacturingYear,
  updateModel,
  updateRegistrationDate,
  updateTypeOfVehicle_commercial,
  updateVariant,
} from 'features/Motor'
import { useEffect, useState } from 'react'
import {
  useGetManufacturersQuery,
  useLazyGetModelsQuery,
  useLazyGetVariantsQuery,
} from 'Services/API/motor.api'
import {
  getDateFromDDMMYYYY,
  getManufacturingYearArr,
  getRegistrationDateFromManufacturingYear,
} from 'Services/general'
import { IManufacturer, IModel, IVariant } from 'Models/ResponseModels'
import { addDays } from 'date-fns'
import { setVehicleDetailsValueInForm } from '../VehicleDetailsUtils'

const manufacturerDefaultValue = {
  label: 'Select Manufacturer',
  value: '',
}

const modelDefaultValue = {
  label: 'Select Model',
  value: '',
}

const variantDefaultValue = {
  label: 'Select Varient',
  value: '',
}

const manufacturingYearDefaultValue = {
  label: 'Select Manufacturing year',
  value: '',
}

const typeOfVehicleDefaultValue = {
  label: 'Select Vehicle Type',
  value: '',
}

const registrationDefaultMinDate = getDateFromDDMMYYYY('1/1/2010')

const VehicleDetailsForm = () => {
  const vehicleDetailsInStore = useAppSelector((state) => state.vehicleDetails.vehicleDetails)

  const { type, param: vehicleSelected } = useVehicleType()

  const [manufacturer, setManufacturer] = useState<{
    value: string | number
    label: string
  }>(
    vehicleDetailsInStore.selectedManufacturer
      ? {
          label: vehicleDetailsInStore.selectedManufacturer.name,
          value: vehicleDetailsInStore.selectedManufacturer.code,
        }
      : manufacturerDefaultValue,
  )

  const [model, setModel] = useState<{
    value: string | number
    label: string
  }>(
    vehicleDetailsInStore.selectedModel
      ? {
          label: vehicleDetailsInStore.selectedModel.name,
          value: vehicleDetailsInStore.selectedModel.code,
        }
      : modelDefaultValue,
  )

  const [variant, setVariant] = useState<{
    value: string | number
    label: string
  }>(
    vehicleDetailsInStore.selectedVariant
      ? {
          label: vehicleDetailsInStore.selectedVariant.name,
          value: vehicleDetailsInStore.selectedVariant.code,
        }
      : variantDefaultValue,
  )

  const [typeOfVehicleCommercial, setTypeOfVehicleCommercial] = useState<{
    value: string
    label: string
  }>(
    vehicleDetailsInStore.typeOfVehicle_commercial
      ? {
          label: vehicleDetailsInStore.typeOfVehicle_commercial.name,
          value: vehicleDetailsInStore.typeOfVehicle_commercial.code,
        }
      : typeOfVehicleDefaultValue,
  )

  // make
  const { data: manufacturersDropdownData, isFetching: isManufacturersDataLoading } =
    useGetManufacturersQuery({ type: type.toString() })

  // model
  const [getModels, { data: modelDropdownData, isFetching: isModelDataLoading }] =
    useLazyGetModelsQuery()

  // variant
  const [getVariant, { data: variantDropdownData, isFetching: isVariantDataLoading }] =
    useLazyGetVariantsQuery()

  const dispatch = useAppDispatch()

  const manufacturerChangeHandler = async (
    allManufacturers: IManufacturer[],
    selectedManufacturerToDisplay: SingleValue<{
      value: string | number
      label: string
    }>,
  ) => {
    console.log(selectedManufacturerToDisplay)
    if (selectedManufacturerToDisplay) {
      // set value in useState for displaying
      setManufacturer(selectedManufacturerToDisplay)

      // reset model
      setModel(modelDefaultValue)
      dispatch(resetModel())

      // reset variant
      setVariant(variantDefaultValue)
      dispatch(resetVariant())

      // get actual manufacturer to be stored in store
      const selectedManufacturer = allManufacturers.find(
        (manufacturer) =>
          manufacturer.code.toString() === selectedManufacturerToDisplay?.value.toString(),
      )

      // update manufacturer to store
      selectedManufacturer && dispatch(updateManufacturer(selectedManufacturer))
    }
  }

  // const typeOfVehicleChangeHandler =

  // populate models dropdown if manufacturer is changed
  useEffect(() => {
    ;(async () => {
      if (manufacturer.value) {
        await getModels({ manufacturer: manufacturer.value.toString() })
      }
    })()
  }, [manufacturer])

  const modelChangeHandler = async (
    allModels: IModel[],
    selectedModelToDisplay: SingleValue<{
      label: string
      value: string | number
    }>,
  ) => {
    if (selectedModelToDisplay) {
      // set value in useState for displaying
      setModel(selectedModelToDisplay)

      // reset variant
      setVariant(variantDefaultValue)
      dispatch(resetVariant())

      // get actual model to be stored in store
      const selectedModel = allModels.find(
        (model) => model.code.toString() === selectedModelToDisplay.value.toString(),
      )

      // update model to store
      selectedModel && dispatch(updateModel(selectedModel))
    }
  }

  // populate variant dropdown if manufacturer is changed
  useEffect(() => {
    ;(async () => {
      if (model.value) {
        await getVariant({ model: model.value.toString() })
      }
    })()
  }, [model])

  const variantChangeHandler = (
    allVariants: IVariant[],
    selectedVariantToDisplay: SingleValue<{
      label: string
      value: string | number
    }>,
  ) => {
    if (selectedVariantToDisplay) {
      // set value in useState for displaying
      setVariant(selectedVariantToDisplay)

      // get actual variant to be stored in store
      const selectedVariant = allVariants.find(
        (variant) => variant.code.toString() === selectedVariantToDisplay?.value.toString(),
      )

      // update variant to store
      selectedVariant && dispatch(updateVariant(selectedVariant))
    }
  }

  const [manufacturingYear, setManufacturingYear] = useState<{
    label: string
    value: string | number
  }>(
    vehicleDetailsInStore.manufacturingYear
      ? {
          label: vehicleDetailsInStore.manufacturingYear.toString(),
          value: vehicleDetailsInStore.manufacturingYear,
        }
      : manufacturingYearDefaultValue,
  )

  const [registrationDate, setRegistrationDate] = useState(
    vehicleDetailsInStore.registrationDate ?? '',
    // format(new Date(), 'dd/MM/yyyy'),
  )

  const manufacturingYearChangeHandler = (
    yearToDisplay: SingleValue<{
      label: string
      value: string | number
    }>,
  ) => {
    if (yearToDisplay) {
      // set value in useState for displaying
      setManufacturingYear(yearToDisplay)

      // update manufacturing year to store
      dispatch(updateManufacturingYear(yearToDisplay?.value))

      setRegistrationDate(`01/01/${yearToDisplay.value}`)
    }
  }

  useEffect(() => {
    if (registrationDate) dispatch(updateRegistrationDate(registrationDate))
    else dispatch(updateRegistrationDate(''))
  }, [registrationDate])

  const [registrationMinDate, setRegistrationMinDate] = useState(registrationDefaultMinDate)

  useEffect(() => {
    setRegistrationMinDate(getDateFromDDMMYYYY(`01/01/${manufacturingYear.value}`))
  }, [manufacturingYear])

  useEffect(() => {
    setVehicleDetailsValueInForm({
      setManufacturer: setManufacturer,
      setModel: setModel,
      setVariant: setVariant,
      setManufacturingYear: setManufacturingYear,
      setRegistrationDate: setRegistrationDate,
    })
  }, [vehicleDetailsInStore])

  // useEffect(() => {}, [])

  return (
    <Box paddingY='4'>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Manufacturers*</FormLabel>
        <Select
          selectedOptionColor='primary'
          menuPosition='fixed'
          isLoading={isManufacturersDataLoading}
          placeholder='Select Manufacturer'
          value={manufacturer}
          onChange={(data) => manufacturerChangeHandler(manufacturersDropdownData!, data)}
          options={manufacturersDropdownData?.map((manufacturer) => {
            return {
              label: manufacturer.name,
              value: manufacturer.code,
            }
          })}
        />
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Models*</FormLabel>
        <Select
          selectedOptionColor='primary'
          menuPosition='fixed'
          isDisabled={manufacturer.value === ''}
          isLoading={isModelDataLoading}
          placeholder='Select Model'
          value={model}
          onChange={(data) => modelChangeHandler(modelDropdownData!, data)}
          options={modelDropdownData?.map((model) => {
            return {
              label: model.name,
              value: model.code,
            }
          })}
        />
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Variants*</FormLabel>
        <Select
          selectedOptionColor='primary'
          menuPosition='fixed'
          isDisabled={model.value === ''}
          isLoading={isVariantDataLoading}
          placeholder='Select Variant'
          value={variant}
          onChange={(data) => variantChangeHandler(variantDropdownData!, data)}
          options={variantDropdownData?.map((variant) => {
            return {
              label: variant.name,
              value: variant.code,
            }
          })}
        />
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Manufacturing Year*</FormLabel>
        <Select
          isSearchable={false}
          selectedOptionColor='primary'
          menuPosition='fixed'
          placeholder='Select Manufacturing year'
          value={manufacturingYear}
          onChange={manufacturingYearChangeHandler}
          options={getManufacturingYearArr(new Date().getFullYear() - 15)
            .map((year) => {
              return {
                label: year.toString(),
                value: year,
              }
            })
            .reverse()}
        />
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Registration Date*</FormLabel>
        <BimabossDatePicker
          placeholder='Enter Reg. date'
          inputDate={registrationDate}
          setInputDate={setRegistrationDate}
          minDate={registrationMinDate}
          // maxDate={)}
        />
      </Flex>
      {vehicleDetailsInStore.seatsGreaterThanSix === true && vehicleSelected === 'is_pcv' && (
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Vehicle Type*</FormLabel>
          <Select
            isSearchable={false}
            selectedOptionColor='primary'
            menuPosition='fixed'
            placeholder='Select Vehicle Type'
            // onChange={manufacturingYearChangeHandler}
            value={typeOfVehicleCommercial}
            onChange={(item) => {
              setTypeOfVehicleCommercial({
                label: item?.label ?? '',
                value: item?.value ?? '',
              })
              dispatch(
                updateTypeOfVehicle_commercial({
                  name: item?.label ?? '',
                  code: item?.value ?? '',
                }),
              )
            }}
            options={[
              {
                label: 'Staff Bus',
                value: 'Staff',
              },
              {
                label: 'School Bus',
                value: 'School',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ]}
          />
        </Flex>
      )}
      {/* <ElevatedButton onClick={() => console.log(store.getState())}>Log</ElevatedButton> */}
    </Box>
  )
}

export { VehicleDetailsForm }
