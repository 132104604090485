import { Button, Flex, Box, FormLabel, Center } from '@chakra-ui/react'
import { TextInput } from 'Core/TextInput'
import { Select } from 'chakra-react-select'
import { useDeviceType } from 'app/hooks'

export const Form = () => {
  const { isDesktop } = useDeviceType()
  return (
    <Box paddingX={isDesktop ? '4' : ''}>
      <Flex direction='column'>
        <Box
          fontSize='md'
          fontWeight='bold'
          paddingY='4'
          boxShadow={isDesktop ? '' : 'lg'}
          textAlign={'center'}
          borderBottom='1px'
        >
          Locate Cashless Hospitals Near You
        </Box>

        <Box padding='4'>
          <Box marginY='2'>
            <FormLabel fontSize='17px' fontWeight='medium'>
              Pin Code
            </FormLabel>

            <TextInput placeholder='Enter Pin Code' rounded='md' />
          </Box>
          <Flex direction='column' marginY='2'>
            <FormLabel fontSize='17px' fontWeight='medium'>
              State
            </FormLabel>
            <Select
              placeholder='Enter State'
              options={[
                {
                  label: 'Delhi',
                  value: 'delhi',
                },
                {
                  label: 'Rajasthan',
                  value: 'rajasthan',
                },
              ]}
            />
          </Flex>
          <Flex direction='column' marginY='2'>
            <FormLabel fontSize='17px' fontWeight='medium'>
              City
            </FormLabel>
            <Select
              placeholder='Enter City'
              options={[
                {
                  label: 'Delhi',
                  value: 'delhi',
                },
                {
                  label: 'Ghaziabad',
                  value: 'ghaziabad',
                },
              ]}
            />
          </Flex>
          <Flex direction='column' marginY='2'>
            <FormLabel fontSize='17px' fontWeight='medium'>
              Hospital Name
            </FormLabel>
            <Select
              placeholder='Enter Hospital Name'
              options={[
                {
                  label: 'Manas Hospital',
                  value: 'manas hospital',
                },
                {
                  label: 'Prakash Hospital',
                  value: 'prakash hospital',
                },
              ]}
            />
          </Flex>
          <Center marginTop='8'>
            <Button backgroundColor='primary' color='white' variant='solid' rounded='full' w='70%'>
              Submit
            </Button>
          </Center>
        </Box>
      </Flex>
    </Box>
  )
}
