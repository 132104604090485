import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface cartItemData {
  insurerName: string
  quoteNumber: string
  insuranceNumber: number
  insuranceName: string
  premium: number
  sumInsured: number
  code: string
  insurerLogo: string
  insurerId: string
  pincode: string
  addons: {
    code: string
    name: string
    si_type: string
    si_value: string
    amount: string
    value: string
  }[]
  loading?: boolean
  addonsTotal?: string
  discountTotal?: string
  loadingTotal?: string
  netPremium?: string
  basePremium?: string
  GST?: string
  addedMemberTypes?: ('proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse')[]
  memberType?: 'parents' | 'selfSpouseKids' | 'allMembers'
  uniqueIdentifier?: string
  duration: number
  isAutoDebitSupported: boolean
  isAutoPaySupported: boolean
}

interface IState {
  cartDetails: {
    parents: cartItemData | null
    selfSpouseKids: cartItemData | null
    allMembers: cartItemData | null
  }
  selectedQuotes: { [key: string]: cartItemData }
  selectedPlanType: 'Family Floater' | 'Individual' | 'Multi Individual' | ''
  requestId: string
  totalAddedMembers: ('proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse')[]
  sp_consent_identifier?: string
  customer_consent_identifier?: string
}

const initialState: IState = {
  cartDetails: {
    parents: null,
    selfSpouseKids: null,
    allMembers: null,
  },
  selectedQuotes: {},
  selectedPlanType: '',
  requestId: '',
  totalAddedMembers: [],
  sp_consent_identifier: '',
  customer_consent_identifier: '',
}

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    setSPConsentIndentifier: (state, action: PayloadAction<string>) => {
      state.sp_consent_identifier = action.payload
    },
    setCustomerConsentIndentifier: (state, action: PayloadAction<string>) => {
      state.customer_consent_identifier = action.payload
    },
    resetCartDetails: () => initialState,
    addToCart: (
      state,
      action: PayloadAction<{
        memberCategory: 'parents' | 'selfSpouseKids' | 'allMembers'
        data: cartItemData
      }>,
    ) => {
      state.cartDetails[action.payload.memberCategory] = action.payload.data
    },
    removeFromCart: (
      state,
      action: PayloadAction<{
        memberCategory: 'parents' | 'selfSpouseKids' | 'allMembers'
      }>,
    ) => {
      state.cartDetails[action.payload.memberCategory] = null
    },
    clearCart: (state) => {
      state.cartDetails = {
        parents: null,
        selfSpouseKids: null,
        allMembers: null,
      }
    },
    updateSelectedQuotes: (
      state,
      action: PayloadAction<{
        key: string
        data: cartItemData
      }>,
    ) => {
      state.selectedQuotes[action.payload.key] = action.payload.data
    },
    removeSelectedQuotes: (state) => {
      state.selectedQuotes = {}
    },
    updatedPlanType: (
      state,
      action: PayloadAction<'Family Floater' | 'Individual' | 'Multi Individual' | ''>,
    ) => {
      state.selectedPlanType = action.payload
    },
    updateRequestId: (state, action: PayloadAction<string>) => {
      state.requestId = action.payload
    },
    updateTotalMembers: (
      state,
      action: PayloadAction<('proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse')[]>,
    ) => {
      state.totalAddedMembers = action.payload
    },
    updateCompleteCartData: (state, action: PayloadAction<IState>) => {
      state.cartDetails = action.payload.cartDetails
      state.selectedQuotes = action.payload.selectedQuotes
      state.selectedPlanType = action.payload.selectedPlanType
      state.requestId = action.payload.requestId
      state.totalAddedMembers = action.payload.totalAddedMembers
    },
  },
})

const { reducer } = cartSlice

export const {
  addToCart,
  removeFromCart,
  clearCart,
  updateSelectedQuotes,
  updatedPlanType,
  removeSelectedQuotes,
  updateRequestId,
  updateTotalMembers,
  updateCompleteCartData,
  resetCartDetails,
  setSPConsentIndentifier,
  setCustomerConsentIndentifier,
} = cartSlice.actions

export { reducer as cartReducer }
