import { Box, Flex, Text } from '@chakra-ui/react'
import { CustomerDetailsForm } from '../Forms/CustomerDetailsForm'

const CustomerDetailsDesktop = () => {
  return (
    <Box flexBasis='50%' borderRight='1px'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        paddingY='4'
        paddingX='6'
        bgColor='appDarkGrey'
        boxShadow='excludingTop'
        borderTopLeftRadius='2xl'
      >
        <Text fontSize='md' fontWeight='md'>
          Customer Account Details
        </Text>
      </Flex>
      <CustomerDetailsForm />
    </Box>
  )
}

export { CustomerDetailsDesktop }
