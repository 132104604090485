import { useAppSelector, useDeviceType, useVehicleType } from 'app/hooks'
import { store } from 'app/store'
import { Header } from 'Components'
import { ElevatedButton } from 'Core'
import {
  resetProposalStatus,
  toggleProposalScreen,
  updateConsentFormLink,
  updateProposalButtonLoadingStatus,
  updateProposalDetails,
} from 'features/Motor'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  useGetCustomerConsentFormLinkMutation,
  usePostProposalMutation,
} from 'Services/API/motor.api'
import { MotorProposalDesktop } from './Desktop'
import { TMotorProposalFormType } from './Forms/FormModel'
import { getConsentFormPayload, getProposalPayload, setProposalValueInForm } from './ProposalUtils'
import { MotorProposalMobile } from './Mobile'
import { ProposalTopBar } from './ProposalTopBar'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { resetSpTaggingForm } from 'features/Equitas'
import { ToastService } from 'Services/toast.service'
import { ISaveLeadResponse } from 'Models/ResponseModels'
import { decryptData } from 'Services/general'

const MotorProposal = () => {
  const { isDesktop } = useDeviceType()
  const dispatch = useDispatch()
  const { param: vehicleType, name: vehicleName } = useVehicleType()

  const [postProposal, { isLoading, isError, isSuccess }] = usePostProposalMutation()
  const [saveLeads, { isLoading: isSaveLeadsLoading, isError: isSaveLeadsError }] =
    useSaveLeadsMutation()

  const { proposalDetails: proposalDetailsInStore, proposalStatus } = useAppSelector(
    (state) => state.proposalPage,
  )

  const proposalForm = useForm<TMotorProposalFormType>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const onSubmit: SubmitHandler<TMotorProposalFormType> = async (data) => {
    dispatch(updateProposalDetails(data))
    const payload = getProposalPayload({ proposalDetails: data, vehicleType: vehicleType })
    await postProposal(payload)
  }

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const homePageDetails = useAppSelector((state) => state.homePage)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const proposalDetails = useAppSelector((state) => state.proposalPage)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const spDetails = useAppSelector((state) => state.spTagging)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      vehicle_number: homePageDetails.vehicleNumber,
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      insurer_name: quotationDetails.selectedQuote?.insurance_name,
      dump_unique_identifier: quotationDetails.selectedQuote?.unique_identifier,
      product_name: product_name,
      premium: quotationDetails.selectedQuote?.premium_total,
      lead_data: {
        homepage_details: homePageDetails,
        vehicle_details: vehicleDetails,
        insurance_details: insuranceDetails,
        quotation_details: quotationDetails,
        proposal_details: proposalDetails,
        lead_details: leadDetails,
        equitas_details: equitasDetails,
        spTagging_details: spDetails,
      },
      stp_trigger_by: {
        user_firstname: spDetails.selectedSp?.firstname,
        user_lastname: spDetails.selectedSp?.lastname,
        username: spDetails.selectedSp?.user__username,
        stp_trigger_user_id: spDetails.selectedSp?.id?.toString(),
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    if (!payload.stp_trigger_by) {
      delete payload.stp_trigger_by
    }
    return payload
  }

  useEffect(() => {
    if (leadDetails.lead_uid) {
      createLead()
    }
  }, [leadDetails, proposalDetails, spDetails])

  const createLead = async () => {
    let dropOff = ''
    console.log(proposalDetails)
    console.log(spDetails)
    if (proposalDetails.proposalScreen === 'one') {
      dropOff = 'Proposal'
    } else if (
      proposalDetails.proposalScreen === 'two' &&
      (spDetails.spConsentStatus === 'not-sent' || spDetails.spConsentStatus === 'pending')
    ) {
      dropOff = 'SP Validation'
    } else if (
      proposalDetails.proposalScreen === 'two' &&
      spDetails.spConsentStatus === 'success'
    ) {
      dropOff = 'SP Validation Complete'
    } else if (
      proposalDetails.proposalScreen === 'three' &&
      proposalDetails.consentFormLink !== ''
    ) {
      dropOff = 'Customer Consent'
    } else if (proposalDetails.proposalDetails === 'three' && proposalDetails.paymentActivated) {
      dropOff = 'Payment Pending'
    }
    const payload: ISaveLead = formParam(dropOff, homePageDetails.selectedSubType)
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  useEffect(() => {
    setProposalValueInForm(proposalDetailsInStore, proposalForm)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetProposalStatus())
      dispatch(resetSpTaggingForm())
    }
  }, [])

  const [getCustomerConsentForm] = useGetCustomerConsentFormLinkMutation()

  useEffect(() => {
    ;(async () => {
      if (proposalStatus === 'success') {
        try {
          ToastService.showLoadingToast({
            title: 'Generating Customer Consent Form Link!',
          })

          // call generate consent form api - POST - /policy/motor-constent
          // const payload = {
          //   lead_uid: leadDetails.lead_uid ?? '',
          //   payment_link: proposalDetails.proposalSuccessResponse.payment_url ?? '',
          //   unique_identifier: quotationDetails.selectedQuote?.unique_identifier ?? '',
          //   customer: getConsentFormPayload(vehicleType, vehicleName),
          // }
          const resp = await getCustomerConsentForm({
            customer: {
              premium_breakup_details: {
                addOns: quotationDetails.addOns,
                discountCovers: quotationDetails.discountCovers,
                liabilityCovers: quotationDetails.liabilityCovers,
                ownDamageCovers: quotationDetails.ownDamageCovers,
                selectedQuote: quotationDetails.selectedQuote,
                homePageDetails: homePageDetails,
                vehicleDetails: vehicleDetails,
                insuranceDetails: insuranceDetails,
                quotationDetails: quotationDetails,
              },
              customer_data: getConsentFormPayload(vehicleType, vehicleName),
              email_id: proposalDetails.proposalDetails.email ?? '',
              phone: proposalDetails.proposalDetails.mobileNumber
                ? '91' + proposalDetails.proposalDetails.mobileNumber
                : '',
              lead_uid: leadDetails.lead_uid ?? '',
            },
            lead_uid: leadDetails.lead_uid ?? '',
            payment_link: proposalDetails.proposalSuccessResponse.payment_url ?? '',
            unique_identifier: quotationDetails.selectedQuote?.unique_identifier ?? '',
          }).unwrap()

          console.log(resp, 'resp')
          // toggleProposalScreen('three') --- success
          dispatch(toggleProposalScreen('three'))
          dispatch(updateConsentFormLink(resp ?? ''))
          ToastService.closeAllToast()
        } catch (e) {
          console.log(e)
          console.log('e')
          // show toast in case of error
          ToastService.showErrorToast({
            title: 'Something went wrong!',
            message: 'Failed to generate consent form link!',
          })
        } finally {
          setTimeout(() => {
            ToastService.closeAllToast()
          }, 2000)
        }
      }
    })()
  }, [proposalStatus])

  return (
    <>
      <Header />
      <ProposalTopBar />
      <form onSubmit={proposalForm.handleSubmit(onSubmit)}>
        {isDesktop ? (
          <MotorProposalDesktop form={proposalForm} />
        ) : (
          <MotorProposalMobile form={proposalForm} />
        )}
      </form>
    </>
  )
}

export { MotorProposal }
