import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import { useAppSelector, useDeviceType, useAppDispatch } from 'app/hooks'
import { CommonImages } from 'Assets/Common'
import { Modal, TextInput, ToggleSwitch } from 'Core'
import { useEffect, useState } from 'react'
import {
  AsyncSelect,
  ChakraStylesConfig,
  Select as ChakraSelect,
  SingleValue,
} from 'chakra-react-select'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useGetGenericSumInsuredQuery,
  useGetPincodeListingQuery,
  useLazyGetPincodeListingQuery,
} from 'Services/API/health.api'
import {
  updateProposer,
  updateSumInsured,
  deleteMembers,
  updateProposerPincode,
  resetMemberDetails,
} from 'features/Health/memberDetails.health.slice'
import { IMemberState } from '../../../Health/MemberDetails/Forms/MemberForm'
import { MemberData } from 'Models/Common/Health.General'
import {
  clearCart,
  removeSelectedQuotes,
  resetCartDetails,
  updatedPlanType,
} from 'features/Health/cart.health.slice'
// import { Select, SingleValue } from 'chakra-react-select'
import { IPincodeOption, ISaveLeadResponse } from 'Models/ResponseModels'
import * as yup from 'yup'

import {
  getDOBfromAge,
  getMemberTypeFromAge,
  onlyAllowTypingNumbers,
  pincodeLabelFormatter,
  pincodeLabelFormatterHealth,
  getAgeFromDob,
  decryptData,
} from 'Services/general'
import { IHealthPincode, IHealthPincodeOption } from 'Models/ResponseModels/Health'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { CustomerAccountDetails } from 'Pages/Motor'
import { ToastService } from 'Services/toast.service'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateFieldsFetchedFromCbsMapping } from 'features/Equitas'

const {
  HomeImages: { healthInsurance },
} = CommonImages
const chakraStyles: ChakraStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '5px 0px 0px 5px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px 8px',
    fontSize: '12px',
  }),
  dropdownIndicator: (provided, state) => ({
    margin: '0px',
  }),
}

// const [pincodeLazyQuery] = useLazyGetPincodeListingQuery()
// const [pincodeValue, setPincodeValue] = useState<IPincodeOption | undefined>(undefined)

// const loadPincodeOptions = (
//   inputSearchValue: string,
//   callback: (options: IPincodeOption[]) => void,
// ) => {
//   if (inputSearchValue.length > 2) {
//     pincodeLazyQuery({
//       pincode: inputSearchValue,
//     }).then((resp) => {
//       const pincodes = resp.data
//       if (pincodes) callback(pincodeLabelFormatter(pincodes))
//     })
//   }
// }

const HomeHealthForm = () => {
  const navigate = useNavigate()
  const [isSelf, setIsSelf] = useState(true)

  const { isDesktop, isMobile, isTablet } = useDeviceType()
  const { proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)

  // const { data: pincodeData, isFetching: pinCodeDataFetching } = useGetPincodeListingQuery({
  //   insurer: '5',
  //   pincode: '201301',
  // })

  const [getPincode] = useLazyGetPincodeListingQuery()

  const { proposer, selfPincode } = useAppSelector((state) => state.memberDetails)
  const memberDetails = useAppSelector((state) => state.memberDetails)

  const cart_details = useAppSelector((state) => state.cart)

  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()
  const [pincodeInput, setPincodeInput] = useState('')

  const dispatch = useAppDispatch()
  const { data: sumInsuredList, isFetching: sumInsuredFetcing } = useGetGenericSumInsuredQuery()

  const [proposerPincode, setProposerPincode] = useState<IHealthPincodeOption>()

  useEffect(() => {
    dispatch(resetMemberDetails())
    dispatch(resetCartDetails())
  }, [])

  useEffect(() => {
    if (proposer.pincode.length === 6) {
      getPincode({
        pincode: proposer.pincode,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes && pincodes.length) {
          setProposerPincode({
            value: `${pincodes[0].area_name}(${pincodes[0].pincode})`,
            label: pincodes[0].pincode.toString(),
            ...pincodes[0],
          })
        }
      })
    }
  }, [proposer.pincode])

  const getCBSProposerAge = () => {
    if (proposerDetailsFromCBS)
      if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.dob) {
        return Math.trunc(
          getAgeFromDob(new Date(proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.dob)),
        ).toString()
      }
    return ''
  }

  const getCBSGender = () => {
    if (proposerDetailsFromCBS)
      if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.gndr) {
        if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.gndr === 'M') return true
        else return false
      }

    return true
  }
  const [isMale, setIsMale] = useState(getCBSGender)

  const healthFormSchema = yup.object({
    sumInsured: yup.object({
      value: yup.string().required('Sum Insured is required'),
      name: yup.string(),
    }),
    age: yup
      .string()
      .required()
      .test('Required', 'Age greater than 18 accepted!', (val) => parseInt(val!) >= 18)
      .test('Required', 'Age Less than 65 accepted!', (val) => parseInt(val!) <= 65),
  })

  // Infer the TS type according to the zod schema.
  type HealthForm = {
    sumInsured: {
      value: string
      name: string
    }
    age: string
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting, isSubmitted, isDirty, isValid },
  } = useForm<HealthForm>({
    resolver: yupResolver(healthFormSchema),
    defaultValues: {
      sumInsured: {
        name: '',
        value: '',
      },
      age: getCBSProposerAge(),
    }, // Configuration the validation with the zod schema.
  })

  const onSubmit = (data: any) => {
    let firstName = ''
    let lastName = ''
    let middleName = ''
    let phoneNo = ''
    let email = ''
    let pan = ''
    let dob = getDOBfromAge(data.age)
    let current_address = ''
    let permanent_address = ''
    let permanentPincode = ''
    let currentPincode = ''
    // let annualIncome = ''

    if (proposerDetailsFromCBS) {
      const customerData =
        proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp &&
        proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp[0]
      if (customerData?.frsNm) firstName = customerData.frsNm
      if (customerData?.mdlNm) middleName = customerData.mdlNm
      if (customerData?.lstNm) lastName = customerData.lstNm
      if (customerData?.mblNb)
        phoneNo = customerData.mblNb.substring(customerData.mblNb.length - 10)
      if (customerData?.email) email = customerData.email
      // if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.grossAnnlIncm) {
      //   annualIncome = proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.grossAnnlIncm
      // }

      if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.panNb)
        pan = proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.panNb

      const {
        crntAddrLn1,
        crntAddrLn2,
        crntAddrLn3,
        prmntAddrLn1,
        prmntAddrLn2,
        prmntAddrLn3,
        prmntPncd,
        crntPncd,
      } = proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp
      if (crntAddrLn1) {
        current_address = current_address + ' ' + crntAddrLn1
      }
      if (crntAddrLn2) {
        current_address = current_address + ' ' + crntAddrLn2
      }
      if (crntAddrLn3) {
        current_address = current_address + ' ' + crntAddrLn3
      }

      // Also add address

      if (prmntAddrLn1) {
        permanent_address = permanent_address + ' ' + prmntAddrLn1
      }
      if (prmntAddrLn2) {
        permanent_address = permanent_address + ' ' + prmntAddrLn2
      }
      if (prmntAddrLn3) {
        permanent_address = permanent_address + ' ' + prmntAddrLn3
      }
      if (prmntPncd) {
        permanentPincode = prmntPncd
        dispatch(updateFieldsFetchedFromCbsMapping('customerPermanentPincode'))
      }
      if (crntPncd) {
        currentPincode = crntPncd
        dispatch(updateFieldsFetchedFromCbsMapping('customerPincode'))
      }
    }

    if (proposerDetailsFromCBS) {
      if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.dob)
        dob = proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.dob.split('-').reverse().join('/')
    }

    dispatch(
      updateProposer({
        data: {
          ...proposer,
          current_address: current_address,
          permanent_address: permanent_address,
          firstname: firstName,
          lastname: lastName,
          middlename: middleName,
          pan_number: pan,
          mobile: phoneNo,
          email: email,
          age: getValues('age'),
          // type: getMemberTypeFromAge(data.age.toString()),
          type: 'adult',
          gender: isMale ? 'Male' : !isMale ? 'Female' : 'Other',
          dob: dob,
          // annual_income: annualIncome,
          // TODO value harcoded
          marital_status: { name: 'Married', code: 'Married' },
          pincode: currentPincode,
          permanentPincode: permanentPincode,
        },
        isProposerIncluded: true,
      }),
    )

    dispatch(updatedPlanType(isSelf ? 'Individual' : 'Family Floater'))

    dispatch(updateSumInsured(getValues('sumInsured').value))
    if (isSelf) {
      setIsPincodeModalOpen(true)
    } else {
      createLead()
      navigate('health/member-details')
    }
  }

  const onError = (errors: any) => {
    console.log(errors)
  }

  const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false)

  const PincodeModalSubmitHandler = () => {
    // if (pincodeData) {
    //   const filteredPincode = pincodeData.result.filter(
    //     (code) => code.pincode.toString() === proposerPincode,
    //   )
    //   if (filteredPincode.length > 0) {
    //     dispatch(updateProposer({
    //       data: {
    //         ...proposer,
    //         pincode: proposerPincode
    //       },
    //       isProposerIncluded: true
    //     }))
    //     navigate('health/quotes')
    //   } else {
    //     setInvalidPincode(true)
    //   }
    // }

    if (proposerPincode?.pincode.toString() === '') {
      ToastService.showErrorToast({
        title: 'Please select your pincode',
      })
      return
    }
    dispatch(deleteMembers())
    dispatch(clearCart())
    dispatch(removeSelectedQuotes())
    dispatch(updateProposerPincode(proposerPincode!))
    dispatch(
      updateProposer({
        data: {
          ...proposer,
          pincode: proposerPincode!.pincode,
          pincode_map: proposerPincode!,
          // permanent_address_pincode_map: {
          //   area_name: proposerPincode!.area_name,
          //   city_name: proposerPincode!.city_name,
          //   state_name: proposerPincode!.state_name,
          //   id: proposerPincode!.pincode,
          //   pincode: proposerPincode!.pincode,
          // },
        },
        isProposerIncluded: true,
      }),
    )
    createLead()
    navigate('health/quotes')
  }
  const loadPincodeOptions = (
    inputSearchValue: string,
    callback: (options: IHealthPincodeOption[]) => void,
  ) => {
    if (inputSearchValue.length > 2) {
      getPincode({
        pincode: inputSearchValue,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes) callback(pincodeLabelFormatterHealth(pincodes))
      })
    }
  }

  const handlePincodeSelectChange = (selectedPincode: SingleValue<IHealthPincode>) => {
    if (selectedPincode?.pincode) {
      const selected = {
        ...selectedPincode,
        label: selectedPincode.pincode.toString(),
        value: selectedPincode.pincode.toString(),
      }
      setProposerPincode(selected)
      // setValue('pincodeArea', selected)
      // clearErrors('pincodeArea')
      // dispatch(
      //   updateProposalDetails({
      //     pincodeArea: selected,
      //   }),
      // )
    }
  }

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      lead_data: {
        cart_details: cart_details,
        member_details: memberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('prequote', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  return (
    <>
      <Box w={isDesktop ? '' : 'full'}>
        <Flex
          padding={isDesktop ? '8' : '6'}
          justifyContent={isDesktop ? 'center' : ''}
          w={isDesktop ? '' : 'full'}
          // paddingX={'5'}
        >
          <form onSubmit={handleSubmit(onSubmit, onError)} className={isDesktop ? '' : 'w-full'}>
            <Flex
              justifyContent='space-between'
              direction='column'
              px={isDesktop ? 4 : 0}
              height='100%'
              w={isDesktop ? '' : 'full'}
              // flexBasis={isDesktop ? '80%' : '100%'}
            >
              <Flex gap={4} direction={isDesktop || isTablet ? 'row' : 'column'}>
                <Flex direction='column' w='full'>
                  <FormLabel fontWeight='medium'>Members to be insured</FormLabel>
                  <ToggleSwitch
                    enabledLabel='Self'
                    disabledLabel='Family'
                    enabled={isSelf}
                    setEnabled={() => setIsSelf(!isSelf)}
                  />
                </Flex>
                <Flex direction='column' w='full'>
                  <FormLabel fontWeight='medium'>Proposer Gender</FormLabel>
                  <Flex
                    className={
                      proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr ? 'disabled' : ''
                    }
                    direction='column'
                  >
                    <ToggleSwitch
                      enabledLabel='Male'
                      disabledLabel='Female'
                      enabled={isMale}
                      setEnabled={() => setIsMale(!isMale)}
                    />
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                w={isDesktop ? '' : 'full'}
                direction={isDesktop ? 'row' : 'column'}
                gap={4}
                mt={4}
              >
                <Flex direction='column' w='full'>
                  <FormControl isInvalid={errors.sumInsured?.value ? true : false}>
                    <FormLabel fontWeight='medium'>Sum Insured</FormLabel>
                    <ChakraSelect
                      {...register('sumInsured')}
                      // maxMenuHeight={120}
                      isInvalid={errors.sumInsured ? true : false}
                      placeholder='Select'
                      useBasicStyles
                      // chakraStyles={chakraStyles}
                      // defaultValue={{
                      //   label: memberDetails.sum_insured,
                      //   value: memberDetails.sum_insured,
                      // }}
                      isSearchable={false}
                      options={sumInsuredList?.map((item) => {
                        return {
                          label: item.toString(),
                          value: item.toString(),
                        }
                      })}
                      onChange={(item) => {
                        setValue('sumInsured', {
                          name: item?.label ?? '',
                          value: item?.value ?? '',
                        })
                        clearErrors('sumInsured')
                      }}
                    />
                    {errors.sumInsured && (
                      <FormErrorMessage>{errors.sumInsured.value?.message}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                {/* {JSON.stringify(proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob)} */}
                <Flex direction='column' w='full'>
                  <FormControl isInvalid={errors.age ? true : false}>
                    <FormLabel fontWeight='medium'>Age</FormLabel>
                    <TextInput
                      id='age'
                      isDisabled={!!proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.dob}
                      border='solid 1px'
                      placeholder='Enter Age'
                      rounded={'lg'}
                      {...register('age', { required: true })}
                      onKeyDown={onlyAllowTypingNumbers}
                      maxLength={2}
                    />
                    {errors.age && <FormErrorMessage>{errors.age?.message}</FormErrorMessage>}
                  </FormControl>
                </Flex>
              </Flex>
              <Flex paddingTop='1rem' alignContent={'center'} justifyContent={'center'}>
                <Button
                  type='submit'
                  w={'40%'}
                  variant={'solid'}
                  colorScheme={'primary'}
                  rounded={'full'}
                >
                  Find Plans
                </Button>
              </Flex>
            </Flex>
          </form>
          {isDesktop && (
            <Flex justifyContent='center' flexBasis='50%'>
              <Image
                paddingY={['4rem', '4rem', '2rem']}
                height={['5rem', '10rem', '15rem', '15rem', '20rem']}
                alt='car'
                src={healthInsurance}
              />
            </Flex>
          )}
        </Flex>
      </Box>
      {/* pincode modal */}
      <Modal isOpen={isPincodeModalOpen} onClose={() => setIsPincodeModalOpen(false)}>
        <Flex
          direction='column'
          gap={isDesktop ? '4' : '1'}
          p={isDesktop ? '8' : '4'}
          alignItems='center'
          w='full'
        >
          <Text textStyle='heading2' my={isDesktop ? '2' : '1'}>
            Select Your Pincode
          </Text>
          <Text my={isDesktop ? '2' : '1'} textAlign={isDesktop ? 'left' : 'center'}>
            Health insurance premium might differ based on your location of residence
          </Text>
          <Text textStyle='heading5' my='1'>
            Your Pincode
          </Text>
          <AsyncSelect
            className={isDesktop ? 'w-1/2' : 'w-[70%]'}
            placeholder='Select Pincode'
            onInputChange={(input) => {
              if (input.length < 7) setPincodeInput(input)
            }}
            inputValue={pincodeInput}
            isDisabled={equitasDetails.fieldsFetchedFromCbsMapping.customerPincode}
            onKeyDown={onlyAllowTypingNumbers}
            loadOptions={loadPincodeOptions}
            onChange={handlePincodeSelectChange}
            value={proposerPincode}
            defaultInputValue={proposerPincode?.pincode.toString()}
          />
          <Button
            onClick={PincodeModalSubmitHandler}
            size='md'
            variant='solid'
            rounded='full'
            w='44'
            my={isDesktop ? '7' : '3'}
          >
            Next
          </Button>
        </Flex>
      </Modal>
    </>
  )
}

export { HomeHealthForm }
