import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { updateSelectedSubType } from 'features/Home'
import { useEffect, useState } from 'react'
import { HomeBikeForm } from './HomeBikeForm'
import { HomeCarForm } from './HomeCarForm'
import { IMotorFormProps } from './MotorFormProps'

const HomePrivateForm = (props: IMotorFormProps) => {
  const [tabIndex, setTabIndex] = useState(0)

  const { selectedSubType } = useAppSelector((state) => state.homePage)

  useEffect(() => {
    if (selectedSubType === 'car') {
      setTabIndex(0)
    } else if (selectedSubType === 'bike') {
      setTabIndex(1)
    }
  }, [])

  const dispatch = useAppDispatch()

  return (
    <Tabs index={tabIndex} paddingY='1rem' colorScheme='tertiary' isFitted>
      <TabList>
        <Tab
          onClick={() => {
            setTabIndex(0)
            dispatch(updateSelectedSubType('car'))
          }}
        >
          <Text fontWeight='semibold'>Car</Text>
        </Tab>
        <Tab
          onClick={() => {
            dispatch(updateSelectedSubType('bike'))
            setTabIndex(1)
          }}
        >
          <Text fontWeight='semibold'>Bike</Text>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel padding='0'>
          <HomeCarForm {...props} />
        </TabPanel>
        <TabPanel padding='0'>
          <HomeBikeForm {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export { HomePrivateForm }
