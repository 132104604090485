import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from 'Constants'
import { productUrlQuery } from 'Services/baseQuery'
export const sachetApi = createApi({
  reducerPath: 'sachetApi',
  baseQuery: productUrlQuery,
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (param) => ({
        url: API_ENDPOINTS.GET_PRODUCTS,
        method: 'GET',
        params: param,
      }),
    }),
    getCriticalIllnessPlans: builder.query({
      query: (param) => ({
        url: API_ENDPOINTS.GET_CRITICAL_ILLNESS_PLANS + '/' + param + '/',
        method: 'GET',
      }),
    }),
    getPaymentStatus: builder.query({
      query: (param) => ({
        url: API_ENDPOINTS.GET_PAYMENY_STATUS,
        method: 'GET',
        params: param,
      }),
    }),
    generateQuote: builder.mutation<any, any>({
      query: (param) => ({
        url: API_ENDPOINTS.GENERATE_QUOTE,
        method: 'POST',
        body: param,
      }),
    }),

    addCustomer: builder.mutation<any, any>({
      query: ({ param, data }) => ({
        url: API_ENDPOINTS.ADD_CUSTOMER + param + '/',
        method: 'POST',
        body: data,
      }),
    }),

    createConsent: builder.mutation<any, any>({
      query: (data) => ({
        url: API_ENDPOINTS.CREATE_CONSENT,
        method: 'POST',
        body: data,
      }),
    }),
    getConsent: builder.query<
      any,
      {
        quote_id: string
      }
    >({
      query: (param) => ({
        url: API_ENDPOINTS.GET_SACHET_CONSENT + param.quote_id + '/',
        method: 'GET',
      }),
    }),
    consentUploadSachet: builder.mutation<any, any>({
      query: (param) => ({
        url: API_ENDPOINTS.SAVE_SACHET_CONSENT,
        method: 'POST',
        body: {
          data: {
            checks: [
              {
                status: true,
                heading: 'Standing Instruction for Auto-Renewal',
                description: 'DESC_1',
              },
              {
                status: true,
                heading: 'Declaration of Good Health',
                description: 'DESC_2',
              },
              {
                status: true,
                heading: 'Terms & Conditions',
                description: 'DESC_3',
              },
              {
                status: true,
                heading: 'OTP Verified',
                description: 'DESC_4',
              },
            ],
          },
        },
        params: param,
      }),
    }),
    directDebit: builder.mutation<any, any>({
      query: (data) => ({
        url: API_ENDPOINTS.DIRECT_DEBIT_SACHET,
        method: 'POST',
        body: data,
      }),
    }),
    saveSelectedPlan: builder.mutation<any, any>({
      query: (data) => ({
        url: API_ENDPOINTS.SAVE_SELECTED_PLAN,
        method: 'POST',
        body: data,
      }),
    }),
    callDMSAPI: builder.mutation<any, any>({
      query: (data) => ({
        url: API_ENDPOINTS.SACHET_DMS_API,
        method: 'POST',
        body: data,
      }),
    }),
    sachetSalesReport: builder.query<any, any>({
      query: (data) => ({
        url: API_ENDPOINTS.SACHET_SALES_REPORT,
        method: 'GET',
        params: data,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
  }),
})

export const {
  useLazyGetProductsQuery,
  useGenerateQuoteMutation,
  useAddCustomerMutation,
  useCreateConsentMutation,
  useLazyGetConsentQuery,
  useConsentUploadSachetMutation,
  useDirectDebitMutation,
  useLazyGetPaymentStatusQuery,
  useLazyGetCriticalIllnessPlansQuery,
  useSachetSalesReportQuery,
  useSaveSelectedPlanMutation,
  useCallDMSAPIMutation,
} = sachetApi
