import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

interface IErrorText extends TextProps {
  text: string
}

const ErrorText = (props: IErrorText) => {
  const { text } = props

  return (
    <Text paddingX='0.2rem' paddingY='0.2rem' color='red' fontSize='x-small'>
      {text}
    </Text>
  )
}

export { ErrorText }
