import borders from './borders'
import colors from './colors'
import layerStyles from './layerStyles'
import radii from './radius'
import shadows from './shadows'
import sizes from './sizes'
import space from './space'
import textStyles from './textStyles'

export default {
  space,
  colors,
  textStyles,
  radii,
  shadows,
  borders,
  layerStyles,
  sizes,
}
