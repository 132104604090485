import {
  Box,
  Flex,
  Center,
  Text,
  RadioGroup,
  Radio,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Tooltip,
  ModalFooter,
} from '@chakra-ui/react'
import { EditIcon, InfoIcon } from '@chakra-ui/icons'

import { useAppDispatch, useAppSelector, useVehicleType, useDeviceType } from 'app/hooks'
import {
  updateAscending,
  updateOwnedBy,
  updatePolicyType,
  updateCertificateStatus,
} from 'features/Motor'
import { ToggleButton } from 'Core/ToggleButton'
import { EditVehicleDetail } from './EditVehicleDetails'
import { EditPolicyDetails } from './EditPolicyDetails'
import { ElevatedButton } from 'Core'
import { copyToClipboard } from 'Services/general'

const TopDetailBar = ({ length, resetAll }: { length: number; resetAll: any }) => {
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const homePageDetails = useAppSelector((state) => state.homePage)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const dispatch = useAppDispatch()
  const { name: vehicleType } = useVehicleType()
  const { isDesktop, isMobile, isTablet } = useDeviceType()

  const ascending = quotationDetails.isAscending
  const isComprehensive = quotationDetails.isComprehensive
  const isIndividual = quotationDetails.isIndividual

  const {
    isOpen: isEditVehiclesOpen,
    onOpen: onEditVehiclesOpen,
    onClose: onEditVehiclesClose,
  } = useDisclosure()

  const {
    isOpen: isEditPolicyOpen,
    onOpen: onEditPolicyOpen,
    onClose: onEditPolicyClose,
  } = useDisclosure()

  const { isOpen: isPUCOpen, onOpen: onPUCOpen, onClose: onPUCClose } = useDisclosure()

  const { isOpen: isPackageOpen, onOpen: onPackageOpen, onClose: onPackageClose } = useDisclosure()
  const { isOpen: isOwnedByOpen, onOpen: onOwnedByOpen, onClose: onOwnedByClose } = useDisclosure()

  const EditPUC = () => {
    return (
      <Modal isCentered isOpen={isPUCOpen} onClose={onPUCClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>PUC Certificate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup
              flexBasis='70%'
              value={quotationDetails.pucCertificate ? 'yes' : 'no'}
              onChange={() =>
                dispatch(
                  updateCertificateStatus({ pucCertificate: !quotationDetails.pucCertificate }),
                )
              }
            >
              <Radio value='yes'>Yes</Radio>
              <Radio value='no' marginLeft='2'>
                No
              </Radio>
            </RadioGroup>
          </ModalBody>
          <Center>
            <ModalFooter>
              <Button onClick={onPUCClose}>Okay</Button>
            </ModalFooter>
          </Center>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Flex direction='column' fontSize='12px' width='100%'>
      <Flex paddingX='3' bgColor='white' alignItems='center'>
        <Box flexBasis={isDesktop ? '35%' : '33%'} paddingY={isDesktop ? '4' : '3'}>
          <Flex alignItems='center' justifyContent='space-around'>
            <Flex direction='column'>
              <Text
                fontWeight='semibold'
                fontSize={isDesktop ? '12px' : '14px'}
                textTransform='capitalize'
              >
                {vehicleType.toLowerCase()}
              </Text>
              <Text fontWeight='semibold' fontSize={isDesktop ? '12px' : '14px'}>
                Details
              </Text>
            </Flex>
            <Flex direction='column' flexBasis='70%'>
              <Text display={isDesktop ? 'block' : 'none'}>{homePageDetails.vehicleNumber} </Text>
              <Text display={isDesktop ? 'block' : 'none'} textTransform='capitalize'>
                {vehicleDetails.selectedManufacturer?.name.toLowerCase()}{' '}
                {vehicleDetails.selectedModel?.name.toLowerCase()}{' '}
                {vehicleDetails.selectedVariant?.name.replaceAll('-', '')}
              </Text>
              <Text display={isDesktop ? 'block' : 'none'} textTransform='capitalize'>
                {vehicleDetails.registrationDate}
              </Text>
            </Flex>
            <Text
              cursor='pointer'
              color='#00548C'
              _hover={{ textDecoration: 'underline' }}
              marginRight='4'
              onClick={onEditVehiclesOpen}
              display={isDesktop ? 'block' : 'none'}
            >
              Edit
            </Text>
            <EditIcon
              onClick={onEditVehiclesOpen}
              display={isDesktop ? 'none' : 'block'}
              color='#00548C'
            />
            <EditVehicleDetail
              isOpen={isEditVehiclesOpen}
              onClose={onEditVehiclesClose}
              onOpen={onEditVehiclesOpen}
              resetAll={resetAll}
            />
          </Flex>
        </Box>
        <Center>
          <Divider orientation='vertical' height='80px' />
        </Center>
        <Box
          flexBasis={isDesktop ? '45%' : '33%'}
          padding='4'
          display={isDesktop ? 'block' : 'none'}
        >
          <Flex
            alignItems='center'
            className={insuranceDetails.hasPreviousPolicy ? '' : 'disabled'}
          >
            <Flex flexBasis='60%' justifyContent='space-around'>
              <Flex direction='column'>
                <Text fontWeight='semibold'>Existing Policy</Text>
                <Text fontWeight='semibold'>Expiry Date</Text>
                <Text fontWeight='semibold'>Existing Insurer</Text>
              </Flex>
              <Flex direction='column'>
                <Text>
                  {insuranceDetails.isSaod
                    ? 'Stand Alone OD'
                    : insuranceDetails.hasPreviousPolicy
                    ? insuranceDetails.previousPolicyType?.name
                    : 'NA'}
                </Text>
                <Text>
                  {insuranceDetails.hasPreviousPolicy
                    ? insuranceDetails.existingPolicyExpiryDate
                    : 'NA'}
                </Text>
                <Text>
                  {insuranceDetails.hasPreviousPolicy
                    ? insuranceDetails.previousInsurer?.name
                    : 'NA'}
                </Text>
              </Flex>
            </Flex>
            <Flex flexBasis='45%' justifyContent='space-around'>
              <Flex direction='column'>
                <Text fontWeight='semibold'>No Claim</Text>
                <Text fontWeight='semibold'>Bonus</Text>
              </Flex>
              {insuranceDetails.claimsMadeInExistingPolicy ||
              insuranceDetails.previousPolicyType?.value === 'TP' ? (
                <Flex alignItems='center' justifyContent='center'>
                  <Text>{insuranceDetails.hasPreviousPolicy ? 'Claimed' : 'NA'}</Text>
                </Flex>
              ) : (
                <Flex direction='column'>
                  <Text>{insuranceDetails.hasPreviousPolicy ? 'Not Claimed' : 'NA'}</Text>
                  <Text>
                    {insuranceDetails.hasPreviousPolicy
                      ? `Previous NCB ${insuranceDetails.ncb}%`
                      : 'NA'}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Text
              cursor='pointer'
              color='#00548C'
              _hover={{ textDecoration: 'underline' }}
              onClick={onEditPolicyOpen}
            >
              Edit
            </Text>
            <EditPolicyDetails
              isOpen={isEditPolicyOpen}
              onClose={onEditPolicyClose}
              onOpen={onEditPolicyOpen}
            />
          </Flex>
        </Box>
        <Box
          flexBasis={isDesktop ? '45%' : '33%'}
          padding='3'
          display={isDesktop ? 'none' : 'block'}
        >
          <Flex
            alignItems='center'
            justifyContent='space-around'
            className={insuranceDetails.hasPreviousPolicy ? '' : 'disabled'}
          >
            <Text fontWeight='semibold' fontSize={isDesktop ? '12px' : '14px'}>
              Policy Details
            </Text>
            <EditIcon
              onClick={() => {
                if (insuranceDetails.hasPreviousPolicy) {
                  onEditPolicyOpen()
                }
              }}
              color='#00548C'
            />
          </Flex>
        </Box>
        <Center>
          <Divider orientation='vertical' height='80px' />
        </Center>
        <Box flexBasis={isDesktop ? '25%' : '33%'} padding='3'>
          <Flex
            direction={isMobile ? 'row' : 'column'}
            justifyContent='space-around'
            alignItems={isMobile ? 'center' : 'start'}
          >
            <Flex alignItems='center'>
              <Text fontWeight='semibold' fontSize={isDesktop ? '12px' : '14px'}>
                PUC Certificate
              </Text>
              <Tooltip
                display={isMobile ? 'none' : 'flex'}
                label="As per the latest guidelines from IRDAI, the valid PUC certificate is mandatory for the issuance of the policy. Assuming a PUC certificate is valid for your vehicle. If you don't have the PUC certificate kindly get the same ASAP."
              >
                <InfoIcon
                  color='#1A6092'
                  w={4}
                  h={4}
                  marginLeft='20px'
                  display={isMobile ? 'none' : 'flex'}
                />
              </Tooltip>
            </Flex>
            <RadioGroup
              flexBasis='70%'
              value={quotationDetails.pucCertificate ? 'yes' : 'no'}
              display={isDesktop ? 'block' : 'none'}
              onChange={() =>
                dispatch(
                  updateCertificateStatus({ pucCertificate: !quotationDetails.pucCertificate }),
                )
              }
            >
              <Radio value='yes'>Yes</Radio>
              <Radio value='no' marginLeft='2'>
                No
              </Radio>
            </RadioGroup>
            <EditIcon onClick={onPUCOpen} color='#00548C' display={isDesktop ? 'none' : 'block'} />
            <EditPUC />
          </Flex>
        </Box>
      </Flex>
      <Divider />
      <Flex
        direction='column'
        paddingX='3'
        paddingY='1'
        bgColor='#F5F6FA'
        display={isDesktop ? 'flex' : 'none'}
      >
        <Text fontSize='20px' fontWeight='semibold' marginBottom='3'>
          Showing Results for {vehicleType} Insurance ({length} Policies)
        </Text>
        <Flex alignItems='center'>
          <ToggleButton
            title='Show Policies By Premium'
            firstLabel='Low to High'
            secondLabel='High to Low'
            enabled={ascending}
            firstOnClick={() => dispatch(updateAscending({ isAscending: true }))}
            secondOnClick={() => dispatch(updateAscending({ isAscending: false }))}
          />
          <Center>
            <Divider orientation='vertical' height='65px' />
          </Center>
          {insuranceDetails.isSaod ? (
            <Text marginX='4'>Package Type: StandAlone OD</Text>
          ) : (
            <ToggleButton
              title='Choose Package Type'
              firstLabel='Comprehensive'
              secondLabel='Third Party'
              enabled={isComprehensive}
              firstOnClick={() => dispatch(updatePolicyType({ isComprehensive: true }))}
              secondOnClick={() => dispatch(updatePolicyType({ isComprehensive: false }))}
            />
          )}
          {/* <Center>
            <Divider orientation='vertical' height='65px' />
          </Center> */}
          {/* <Box
            display={
              vehicleType.toLowerCase() === 'bike' ||
              (vehicleType.toLowerCase() === 'pcv' &&
                vehicleDetails.typeOfVehicle_commercial?.code.toLowerCase() === 'school')
                ? 'none'
                : 'block'
            }
          >
            <ToggleButton
              title='Vehicle Owned By'
              firstLabel='Individual'
              secondLabel='Company'
              enabled={isIndividual}
              firstOnClick={() => dispatch(updateOwnedBy({ isIndividual: true }))}
              secondOnClick={() => dispatch(updateOwnedBy({ isIndividual: false }))}
            />
          </Box> */}
          <Box>
            <Button
              // leftIcon={<MdBuild />}
              colorScheme='secondary'
              fontSize='sm'
              marginX='2'
              variant='outline'
              onClick={() => {
                copyToClipboard(leadDetails.lead_uid ?? '')
                // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
                // ToastService.showSuccessTaost({
                //   title: 'Success',
                //   message: 'Lead Saved Successfully',
                // })
              }}
            >
              {leadDetails.lead_uid ?? '-'}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export { TopDetailBar }
