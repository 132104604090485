import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import { Select } from 'chakra-react-select'
import { ElevatedButton, ErrorText } from 'Core'
import { format } from 'date-fns'
import { updateIsOtpVerified, updateSelectedSp, updateSpConsentStatus } from 'features/Equitas'
import { setSPConsentIndentifier } from 'features/Health/cart.health.slice'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { ISaveLeadResponse } from 'Models/ResponseModels'
import { ISpData } from 'Models/ResponseModels/Equitas'
import { HealthConsentFormRequest } from 'Models/ResponseModels/Health'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  useGetSpListingQuery,
  useSpTaggingSendOtpMutation,
  useSpTaggingVerifyOtpMutation,
} from 'Services/API/authentication.api'
import { useHealthConsentFormLinkMutation, useLazyConsentCheckQuery } from 'Services/API/health.api'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import {
  useGetSpConsentFormLinkMutation,
  useLazyCheckForspConsentSubmissionStatusQuery,
} from 'Services/API/motor.api'
import { decryptData } from 'Services/general'
import { ToastService } from 'Services/toast.service'
// import { useGetSpListingQuery } from 'Services/API/equitas.api'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

const ProposalSpTaggingForm = () => {
  const {
    selectedSp: selectedSpInStore,
    isOtpVerified,
    spConsentStatus,
  } = useAppSelector((state) => state.spTagging)

  const selectedSpData = useAppSelector((state) => state.spTagging)

  const [spListing2, setSpListing2] = useState<ISpData[]>([])

  const cart_details = useAppSelector((state) => state.cart)

  const user = useAppSelector((state) => state.user)

  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)

  const { proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)
  const navigate = useNavigate()

  const { id: userId } = useAppSelector((state) => state.user.authenticatedUser)

  const { data: spListing, isLoading: isSpListingLoading } = useGetSpListingQuery({
    user_id: userId?.toString() ?? '',
  })

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  useEffect(() => {
    if (spListing) {
      setSpListing2(JSON.parse(decryptData(spListing.response_data)))
    }
  }, [spListing])

  const [saveLeads, { isLoading: saveLeadsLoading, isError: saveLeadsError }] =
    useSaveLeadsMutation()

  const dispatch = useAppDispatch()

  const [selectedSp, setSelectedSp] = useState<ISpData | undefined>(selectedSpInStore)

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const { lead_uid } = useAppSelector((state) => state.leadDetails)

  const { customerAccountStatus, ucic, selectedAccount } = useAppSelector(
    (state) => state.customerDetails,
  )

  const [getSpConsent, { isLoading: isGetSpConsentLoading }] = useGetSpConsentFormLinkMutation()

  const [isCheckSubmissionRunning, setIsCheckSubmissionRunning] = useState(false)

  const [pollingInterval, setPollingInterval] = useState(4000)
  const [generateLink] = useHealthConsentFormLinkMutation()

  const { memberDetails, isProposerIncluded, proposer, sum_insured, isDataFetchingFromLead } =
    useAppSelector((state) => state.memberDetails)

  const completeMemberDetails = useAppSelector((state) => state.memberDetails)

  const { selectedQuotes, selectedPlanType, sp_consent_identifier } = useAppSelector(
    (state) => state.cart,
  )

  const [checkForSpConsentSubmissionStatus, { data: submissionResponse }] =
    useLazyConsentCheckQuery({
      pollingInterval: pollingInterval,
    })

  useEffect(() => {
    if (submissionResponse?.is_submited === true) {
      setPollingInterval(0)
      setIsCheckSubmissionRunning(false)
      dispatch(updateSpConsentStatus('success'))
      // dispatch(updatePaymentActivated(true))
    }
  }, [submissionResponse])
  useEffect(() => {
    createLead('health-questions')
  }, [sp_consent_identifier, spConsentStatus])

  //   console.log(spData)

  useEffect(() => {
    ;(async () => {
      setIsCheckSubmissionRunning(true)

      if (sp_consent_identifier) {
        await checkForSpConsentSubmissionStatus({
          consent_identifier: sp_consent_identifier ?? '',
          consent_type: 'sp_consent',
        })
      }
    })()
    console.log('in use effect for sp consent', spConsentStatus)

    // if (!isDataFetchingFromLead) {
    //   dispatch(updateSpConsentStatus(spConsentStatus))
    // }
  }, [])

  useEffect(() => {
    if (submissionResponse?.is_submited === true) {
      setPollingInterval(0)
      setIsCheckSubmissionRunning(false)
      dispatch(updateSpConsentStatus('success'))
      // dispatch(updatePaymentActivated(true))
    }
  }, [submissionResponse])

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: completeMemberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: selectedSpData,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  // sp - validated

  const createLead = async (lead_type: string) => {
    const payload: ISaveLead = formParam(lead_type, 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  return (
    <Box className='w-[50%] md:w-96 pb-4'>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>
          SP Tagging ID <span className=' text-red-500'>*</span>
        </FormLabel>
        <Select
          placeholder='Select SP Tagging'
          isLoading={isSpListingLoading}
          isDisabled={isOtpVerified}
          value={selectedSp}
          onChange={(item) => {
            console.log(item)
            setSelectedSp(item ?? {})
            dispatch(updateSelectedSp(item ?? {}))
          }}
          options={spListing2?.map((spData: ISpData) => {
            return {
              ...spData,
              label:
                (spData?.firstname ?? '') + ' ' + (spData?.lastname ?? '') + ' (' + spData.id + ')',
              value: spData.id?.toString(),
            }
          })}
        />
        {/* {form.formState.errors.spData && form.formState.errors.spData?.type === 'required' && (
          <ErrorText text='SP Tagging ID is required' />
        )} */}
      </Flex>
      {/* {isOtpVerified.toString() + '- isOtpVerified'} */}
      {spConsentStatus === 'not-sent' && (
        <Center>
          <ElevatedButton
            isDisabled={isGetSpConsentLoading || !selectedSp}
            isLoading={isGetSpConsentLoading}
            onClick={async () => {
              const account_detail = selectedAccount

              if (account_detail) {
                const payload: HealthConsentFormRequest = {
                  consent_type: 'sp_consent',
                  lead_id: lead_uid ?? '',
                  sp_mail: selectedSp?.email,
                  sp_name: selectedSp?.firstname + ' ' + selectedSp?.lastname,
                  sp_id: selectedSp?.id ? selectedSp?.id : 0,
                  branch_name:
                    proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp?.[0].brnchNm,
                  plan_type: selectedPlanType,
                  ucic: ucic ?? '',
                  application_no: '', // TODO
                  login_date: format(new Date(), 'dd/MM/yyyy'),
                  sp_mobile: selectedSp?.phone,
                  consent_data: [
                    {
                      unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
                      // lead_id: 'niitn',
                      payment_link: '',
                      isProposerIncluded: isProposerIncluded,
                      proposer: {
                        ...proposer,
                        email_id: proposer.email,
                        mobile_no: proposer.mobile,
                      },
                      members: [
                        ...Object.keys(memberDetails)
                          .filter((key) =>
                            Object.values(selectedQuotes)[0].addedMemberTypes?.includes(
                              memberDetails[key].member_type,
                            ),
                          )
                          .map((key) => {
                            return {
                              ...memberDetails[key],
                            }
                          }),
                      ],
                      account_details: {
                        ...account_detail,
                        brnchNm: '',
                        acctTp: '',
                        acctNm: '',
                        cstId: '',
                        brnchAddr: '',
                        dbtrNm: '',
                        dbtrAcctId: '',
                        Ifsc_code: account_detail.ifsc_code,
                        customerAccountStatus: customerAccountStatus,
                        selectedAccountId: account_detail.account_id.toString(),
                        ucic: ucic ?? '',
                      },
                      policy_details: Object.values(selectedQuotes)[0],
                    },
                  ],
                }

                if (user.authenticatedUser.businessrole_name?.toLowerCase() === 'sp') {
                  delete payload.sp_id
                }
                generateLink(payload)
                  .unwrap()
                  .then(async (res) => {
                    const consent_identifier = res.split('/')[res.split('/').length - 1]
                    dispatch(setSPConsentIndentifier(consent_identifier))
                    startTimer()
                    setResendOtp(false)
                    dispatch(updateSpConsentStatus('pending'))
                    setIsCheckSubmissionRunning(true)
                    await checkForSpConsentSubmissionStatus({
                      consent_identifier: consent_identifier,
                      consent_type: 'sp_consent',
                    })
                  })
                  .catch((error) => {
                    console.log(error)
                    ToastService.showErrorToast({
                      title: 'Something Went Wrong!',
                      message: error.data.error_text,
                    })
                  })
              } else {
                ToastService.showErrorToast({
                  title: 'No Account Details Found',
                })
              }
              // dispatch(updateSpConsentStatus('pending'))
              // setIsCheckSubmissionRunning(true)
            }}
          >
            Send Consent Link
          </ElevatedButton>
        </Center>
      )}
      {spConsentStatus === 'pending' && (
        <Center>
          <ElevatedButton
            isLoading={isGetSpConsentLoading}
            isDisabled={!resendOtp}
            onClick={async () => {
              const account_detail = selectedAccount

              if (account_detail) {
                const payload: HealthConsentFormRequest = {
                  consent_type: 'sp_consent',
                  lead_id: lead_uid ?? '',
                  consent_identifier: cart_details.sp_consent_identifier,
                  sp_mail: selectedSp?.email,
                  sp_name: selectedSp?.firstname + ' ' + selectedSp?.lastname,
                  sp_id: selectedSp?.id ? selectedSp?.id : 0,
                  branch_name:
                    proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp?.[0].brnchNm,
                  plan_type: selectedPlanType,
                  ucic: ucic ?? '',
                  application_no: '', // TODO
                  login_date: format(new Date(), 'dd/MM/yyyy'),
                  sp_mobile: selectedSp?.phone,
                  consent_data: [
                    {
                      unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
                      // lead_id: 'niitn',
                      payment_link: '',
                      isProposerIncluded: isProposerIncluded,
                      proposer: {
                        ...proposer,
                        email_id: proposer.email,
                        mobile_no: proposer.mobile,
                      },
                      members: [
                        ...Object.keys(memberDetails)
                          .filter((key) =>
                            Object.values(selectedQuotes)[0].addedMemberTypes?.includes(
                              memberDetails[key].member_type,
                            ),
                          )
                          .map((key) => {
                            return {
                              ...memberDetails[key],
                            }
                          }),
                      ],
                      account_details: {
                        ...account_detail,
                        brnchNm: '',
                        acctTp: '',
                        acctNm: '',
                        cstId: '',
                        brnchAddr: '',
                        dbtrNm: '',
                        dbtrAcctId: '',
                        Ifsc_code: account_detail.ifsc_code,
                        customerAccountStatus: customerAccountStatus,
                        selectedAccountId: account_detail.account_id.toString(),
                        ucic: ucic ?? '',
                      },
                      policy_details: Object.values(selectedQuotes)[0],
                    },
                  ],
                }

                if (user.authenticatedUser.businessrole_name?.toLowerCase() === 'sp') {
                  delete payload.sp_id
                }
                generateLink(payload)
                  .unwrap()
                  .then(async (res) => {
                    ToastService.showSuccessTaost({
                      title: 'Success',
                      message: `SP Consent form link has been sent to SP's phone and email`,
                    })
                    const consent_identifier = res.split('/')[res.split('/').length - 1]
                    dispatch(setSPConsentIndentifier(consent_identifier))
                    dispatch(updateSpConsentStatus('pending'))
                    setIsCheckSubmissionRunning(true)
                    setResendOtp(false)
                    startTimer()
                    await checkForSpConsentSubmissionStatus({
                      consent_identifier: consent_identifier,
                      consent_type: 'sp_consent',
                    })
                  })
                  .catch((error) => {
                    console.log(error)
                    ToastService.showErrorToast({
                      title: 'Something Went Wrong!',
                      message: error.data.error_text,
                    })
                  })
              } else {
                ToastService.showErrorToast({
                  title: 'No Account Details Found',
                })
              }
              // dispatch(updateSpConsentStatus('pending'))
              // setIsCheckSubmissionRunning(true)
            }}
          >
            {resendOtp ? 'Resend Link' : `Resend Link in ${timeLeft}`}
          </ElevatedButton>
        </Center>
      )}
      {isCheckSubmissionRunning && spConsentStatus === 'pending' && (
        <Center paddingX='2rem' paddingY='1rem'>
          <Flex gap='1rem' fontSize='md' direction='column'>
            <Text>
              Consent form link has been sent to <b>{selectedSp?.phone}</b> and{' '}
              <b>{selectedSp?.email}</b>.
            </Text>
            <Flex gap='1rem'>
              <Text>Waiting for SP to accept the consent</Text>
              <Spinner />
            </Flex>
          </Flex>
        </Center>
      )}

      {spConsentStatus === 'success' && (
        <Center my='4'>
          <Button
            onClick={() => {
              createLead('sp-validated')
              navigate('/health/payment')
            }}
          >
            Submit
          </Button>
        </Center>
      )}
    </Box>
  )
}

export { ProposalSpTaggingForm }
