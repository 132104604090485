import { Box, Button, Container, Grid, GridItem, Flex, Text } from '@chakra-ui/react'
import { useAppSelector, useDeviceType } from 'app/hooks'
import { Header } from 'Components'
import { InformationCard } from 'Core/Cards/InformationCard'
import { useEffect } from 'react'
import { useCreateProposalMutation } from 'Services/API/health.api'
import { DataComponent } from '../DataComponent'
import { FaUserPlus } from 'react-icons/fa'
import { copyToClipboard } from 'Services/general'

export const PaymentDesktop = () => {
  const { selectedQuotes, selectedPlanType } = useAppSelector((state) => state.cart)
  const { isProposerIncluded } = useAppSelector((state) => state.memberDetails)
  const { lead_uid } = useAppSelector((state) => state.leadDetails)

  const { isDesktop } = useDeviceType()

  const getSelfSpouseKidsMemberType = (addedMembersList: any): string => {
    let diplayText = ''
    if (isProposerIncluded) {
      diplayText = 'Self '
    }
    if (addedMembersList.includes('spouse')) {
      diplayText = diplayText + ' Spouse'
    }
    if (addedMembersList.includes('son') || addedMembersList.includes('daughter')) {
      diplayText = diplayText + ' Kid'
    }

    return diplayText
  }

  const getMemberType = (type: string, memberList: any) => {
    console.log(type)
    switch (type) {
      case 'selfSpouseKids':
        return getSelfSpouseKidsMemberType(memberList)
      case 'parents':
        return 'Parents'
      case 'allMembers':
        return 'All Members'
      default:
        return 'All Members'
    }
  }

  return (
    <>
      <Header />
      <Box bgColor='#F6F6F6' height='100vh'>
        <Container maxW='1200px'>
          <Flex justifyContent={'flex-end'} my='3'>
            <Button
              variant={'outline'}
              colorScheme={'secondary'}
              rounded='full'
              size='sm'
              onClick={() => copyToClipboard(lead_uid ?? '')}
            >
              <Flex gap='1'>
                <FaUserPlus color='secondary' />
                <Text>{lead_uid}</Text>
              </Flex>
            </Button>
          </Flex>
          <Grid style={{ gridTemplateColumns: '1fr 1fr' }} gap={2}>
            {Object.keys(selectedQuotes).map((quote) => {
              return (
                <GridItem key={selectedQuotes[quote].code}>
                  <Box borderRight='1px solid #D8D8D8'>
                    <InformationCard
                      id={selectedQuotes[quote]?.code ?? ''}
                      insurerName={selectedQuotes[quote]?.insuranceName ?? ''}
                      sumAssured={selectedQuotes[quote]?.sumInsured.toString() ?? ''}
                      policyFor={getMemberType(
                        selectedQuotes[quote]?.memberType ?? '',
                        selectedQuotes[quote]?.addedMemberTypes ?? '',
                      )}
                      pincode={selectedQuotes[quote]?.pincode ?? ''}
                      policyForr=''
                      finalPremium={selectedQuotes[quote]?.premium?.toString() ?? ''}
                      image={selectedQuotes[quote].insurerLogo ?? ''}
                      loading={selectedQuotes[quote]?.loading}
                    />
                  </Box>
                </GridItem>
              )
            })}
          </Grid>
          <Box border='1px' rounded='xl' backgroundColor='white'>
            <Grid style={{ gridTemplateColumns: '1fr' }}>
              <GridItem w='100%'>
                <Box shadow='sm' paddingX='4' paddingY='6' fontWeight='bold' roundedTopRight='lg'>
                  06 Payment
                </Box>

                {Object.keys(selectedQuotes).map((key) => {
                  return (
                    <>
                      <DataComponent
                        key={selectedQuotes[key].quoteNumber}
                        member_type={'Spouse, Child 1, Child 2'}
                        insurer_logo={selectedQuotes[key].insurerLogo}
                        quoteData={selectedQuotes[key]}
                      />
                    </>
                  )
                })}
              </GridItem>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  )
}
