import { useParams } from 'react-router-dom'

export type TVehicleName = 'Car' | 'Bike' | 'Gcv' | 'Pcv' | 'Miscellaneous' | ''
export type TVehicleTypeNum = 4 | 2 | 5 | 6 | 7 | 0
export type TVehicleTypeParam =
  | 'is_fourwheeler'
  | 'is_twowheeler'
  | 'is_gcv'
  | 'is_pcv'
  | 'is_misc'
  | ''
export type TVehicleTypeText =
  | 'Private Car'
  | 'Two Wheeler'
  | 'Goods Carrying Vehicle'
  | 'Public Carrying Vehicle'
  | 'Miscellaneous Vehicle'
  | ''

interface IVehicleType {
  name: TVehicleName
  type: TVehicleTypeNum
  param: TVehicleTypeParam
  vehicleTypeText: TVehicleTypeText
}

export const useVehicleType = (): IVehicleType => {
  const params = useParams()

  switch (params.type?.toLowerCase()) {
    case 'car':
      return { name: 'Car', type: 4, param: 'is_fourwheeler', vehicleTypeText: 'Private Car' }
    case 'bike':
      return { name: 'Bike', type: 2, param: 'is_twowheeler', vehicleTypeText: 'Two Wheeler' }
    case 'gcv':
      return { name: 'Gcv', type: 5, param: 'is_gcv', vehicleTypeText: 'Goods Carrying Vehicle' }
    case 'pcv':
      return { name: 'Pcv', type: 6, param: 'is_pcv', vehicleTypeText: 'Public Carrying Vehicle' }
    case 'miscellaneous':
      return {
        name: 'Miscellaneous',
        type: 7,
        param: 'is_misc',
        vehicleTypeText: 'Miscellaneous Vehicle',
      }
    default:
      return { name: '', type: 0, param: '', vehicleTypeText: '' }
  }
}
