import {
  Box,
  Flex,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
  FormErrorMessage,
  FormControl,
  Spacer,
  Checkbox,
  CheckboxGroup,
  Spinner,
} from '@chakra-ui/react'
import { BimabossDatePicker, TextInput, ToggleSwitch } from 'Core'
import { useEffect, useState } from 'react'
import { Select as ChakraSelect } from 'chakra-react-select'
import { useAppDispatch, useAppSelector, useDeviceType, useWindowDimensions } from 'app/hooks'
import { useGetQuestionListingQuery } from 'Services/API/health.api'
import { Controller, FieldValues, useForm, UseFormSetValue } from 'react-hook-form'
import { GetQuestionsListingResponse } from 'Models/ResponseModels/Health'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent } from '../../ProposerAndInsurerDetails/Components/ButtonComponent'
import { subYears } from 'date-fns'
import { HEADER_HEIGHT } from 'Constants'
import { decryptData, getArrayFromObj } from 'Services/general'
import uuid from 'react-uuid'
import { MemberData, QuestionBody } from 'Models/Common/Health.General'
import { updateProposer, updateMember } from 'features/Health/memberDetails.health.slice'
import { ToastService } from 'Services/toast.service'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateLeadID, updateDropOff } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ISaveLeadResponse } from 'Models/ResponseModels'

interface IPreExistingDiseasesForm {
  onFormSubmit: () => void
  setShowLifeStyleQuestions: React.Dispatch<React.SetStateAction<boolean>>
  onBackPress: () => void
}

export const PreExistingDiseasesForm = (props: IPreExistingDiseasesForm) => {
  const [hasPreExistingDiseases, setHasPreExistingDiseases] = useState(true)
  const { memberDetails, isProposerIncluded, proposer } = useAppSelector(
    (state) => state.memberDetails,
  )
  const { height } = useWindowDimensions()

  const getMembersDataWithDisease = () => {
    const membersArr = []
    if (proposer.medical_questions.length !== 0) {
      membersArr.push({
        label: 'Self',
        value: 'Self',
        quoteNumber: proposer.selectedQuoteNumber!,
        code: '',
        medical_questions: proposer.medical_questions,
      })
    }
    Object.values(memberDetails).forEach((member) => {
      if (member.medical_questions.length !== 0) {
        membersArr.push({
          label: member.firstname,
          value: member.code,
          quoteNumber: member.selectedQuoteNumber,
          code: member.code,
          medical_questions: member.medical_questions,
        })
      }
    })

    return membersArr
  }

  const [selectedMembers, setSelectedMembers] = useState<
    {
      label: string
      value: string
      quoteNumber: string
      code: string
      medical_questions: GetQuestionsListingResponse[]
    }[]
  >([...getMembersDataWithDisease()])

  const [memberIndex, setMemberIndex] = useState(0)
  const navigate = useNavigate()

  const completeMemberDetails = useAppSelector((state) => state.memberDetails)
  const { cartDetails, selectedQuotes, totalAddedMembers } = useAppSelector((state) => state.cart)

  const { isDesktop } = useDeviceType()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const onNextClick = () => {
    if (selectedMembers.length === currentTabIndex + 1) {
      props.onFormSubmit()
      createLead()
    } else {
      setCurrentTabIndex((prevState) => prevState + 1)
    }
  }
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()

  const cart_details = useAppSelector((state) => state.cart)

  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: completeMemberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('health-questions', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      // console.log(e, 'error in catch')
    }
    // return response
  }

  useEffect(() => {
    createLead()
  }, [])

  const getMembersArray = () => {
    const membersArr = []
    if (isProposerIncluded) {
      membersArr.push({
        label: 'Self',
        value: 'Self',
        quoteNumber: proposer.selectedQuoteNumber!,
        code: '',
        medical_questions: proposer.medical_questions,
      })
    }
    getArrayFromObj(memberDetails)
      .filter((member) => totalAddedMembers.includes(member.member_type))
      .forEach((member: MemberData) => {
        membersArr.push({
          label: member.firstname,
          value: member.code,
          quoteNumber: member.selectedQuoteNumber,
          code: member.code,
          medical_questions: member.medical_questions,
        })
      })
    return membersArr
  }
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!hasPreExistingDiseases) {
      setSelectedMembers([])
    }
  }, [hasPreExistingDiseases])

  return (
    <Box>
      <Box p='4'>
        <Text my='2'>Does any insured member have a pre-existing disease?</Text>
        <Flex w='full' gap='2' direction={isDesktop ? 'row' : 'column'}>
          <Box w='full'>
            <ToggleSwitch enabled={hasPreExistingDiseases} setEnabled={setHasPreExistingDiseases} />
          </Box>
          {hasPreExistingDiseases && (
            <Box w='full'>
              <ChakraSelect
                maxMenuHeight={120}
                defaultValue={[...getMembersDataWithDisease()]}
                selectedOptionStyle='check'
                isMulti={true}
                placeholder='Select Members'
                onChange={(item) => {
                  if (item.length === 0) {
                    setCurrentTabIndex(0)
                  } else if (selectedMembers.length > item.length && currentTabIndex !== 0) {
                    setCurrentTabIndex((prev) => prev - 1)
                  }
                  setSelectedMembers([...item])
                }}
                options={getMembersArray()}
              />
            </Box>
          )}
        </Flex>
        {selectedMembers.length === 0 && <Spacer h='24' />}
      </Box>
      {!hasPreExistingDiseases && (
        <>
          <Flex direction={'column'}>
            <Box height={'440px'} p='4'>
              <Text textAlign={'center'}>Insured Members have no Pre Existing Diseases</Text>
            </Box>

            <Box
              w='full'
              opacity='inherit'
              roundedBottomLeft='2xl'
              boxShadow='0px -2px 6px #00000029'
            >
              <ButtonComponent
                onLeftButtonClick={props.onBackPress}
                onRightButtonClick={() => {
                  props.onFormSubmit()

                  dispatch(
                    updateProposer({
                      data: {
                        ...proposer,
                        medical_questions: [],
                      },
                      isProposerIncluded: isProposerIncluded,
                    }),
                  )

                  Object.keys(memberDetails).forEach((memberCode) => {
                    dispatch(
                      updateMember({
                        code: memberCode,
                        memberDetail: {
                          ...memberDetails[memberCode],
                          medical_questions: [],
                        },
                      }),
                    )
                  })
                  createLead()
                }}
                left='Back'
                right='Next'
              />
            </Box>
          </Flex>
        </>
      )}
      {hasPreExistingDiseases && (
        <Box w='full'>
          <Tabs
            index={currentTabIndex}
            w='full'
            variant='enclosed-colored'
            isLazy={true}
            borderTop='2px solid primary'
            size={isDesktop ? 'md' : 'sm'}
          >
            <TabList w='full'>
              <Flex overflowX='scroll' py='0.5'>
                {selectedMembers.map((member, index) => {
                  return (
                    <Tab
                      _selected={{ color: 'white', bg: 'primary.200' }}
                      fontSize='18px'
                      key={member.label}
                      onClick={() => {
                        if (index < currentTabIndex) {
                          setCurrentTabIndex(index)
                        }
                      }}
                    >
                      <Box w='48'>{member.label}</Box>
                    </Tab>
                  )
                })}
              </Flex>
            </TabList>
            <TabPanels>
              {selectedMembers.map((member) => {
                return (
                  <TabPanel p='0' key={member.value}>
                    <Box>
                      <QuestionContainer
                        onNextClick={onNextClick}
                        quoteNumber={member.quoteNumber}
                        hasPreExistingDiseases={hasPreExistingDiseases}
                        selectedMembers={member}
                        storedQuestions={member.medical_questions}
                        setShowLifeStyleQuestions={props.setShowLifeStyleQuestions}
                        onBackPress={props.onBackPress}
                      />
                    </Box>
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Box>
  )
}

const QuestionContainer = ({
  onNextClick,
  quoteNumber,
  hasPreExistingDiseases,
  selectedMembers,
  storedQuestions,
  setShowLifeStyleQuestions,
  onBackPress,
}: {
  onBackPress: () => void
  onNextClick: any
  quoteNumber: any
  setShowLifeStyleQuestions: React.Dispatch<React.SetStateAction<boolean>>
  storedQuestions: GetQuestionsListingResponse[]

  hasPreExistingDiseases: any
  selectedMembers: {
    label: string
    value: string
    quoteNumber: string
    code: string
  }
}) => {
  const { selectedQuotes } = useAppSelector((state) => state.cart)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { memberDetails, isProposerIncluded, proposer } = useAppSelector(
    (state) => state.memberDetails,
  )

  // console.log(storedQuestions, '------------storedQuestions----------------')

  const updateSubQuestionValue = (
    item: QuestionBody,
    code: string,
    values: { [key: string]: string },
  ) => {
    if (values[code]) item.value = values[code]
    item.sub_ques.forEach((subQues) => {
      updateSubQuestionValue(subQues, code + '$' + subQues.code, values)
    })
  }

  const checkforKnockoutQuestion = (sub_ques: QuestionBody[]): boolean => {
    for (let i = 0; i < sub_ques.length; i++) {
      const ques = sub_ques[i]
      if (ques.type === 'knockout' && ques.value == '1') {
        return true
      }
      if (checkforKnockoutQuestion(ques.sub_ques)) {
        return true
      }
    }

    return false
  }

  const addResponseKey = (sub_ques: QuestionBody[]) => {
    sub_ques.forEach((ques) => {
      addResponseKey(ques.sub_ques)
      if (ques.type === 'date') {
        ques.response = ques.value.split('-').reverse().join('/')
      } else {
        ques.response = ques.value
      }
    })
  }

  const onFormSubmit = (values: { [key: string]: string }) => {
    // Update Question
    if (medicalQuestionsData) {
      const updatedQuestion: GetQuestionsListingResponse[] = JSON.parse(
        JSON.stringify(medicalQuestionsData),
      )
      updatedQuestion
        .filter((question) => question.question_type === 'medical')
        ?.forEach((ques) => {
          const code = ques.code
          ques.value = values[code]
          ques.sub_ques.forEach((subQues) => {
            updateSubQuestionValue(subQues, code + '$' + subQues.code, values)
          })
        })

      let shouldProceedFurther = true
      updatedQuestion
        .filter((question) => question.question_type === 'medical')
        ?.forEach((ques) => {
          if (ques.type === 'knockout' && ques.value == '1') {
            ToastService.showErrorToast({ title: 'You cannot proceed further!' })
            shouldProceedFurther = false
            return
          }
          if (ques.sub_ques.length > 0) {
            if (checkforKnockoutQuestion(ques.sub_ques) == true) {
              ToastService.showErrorToast({
                title: 'You cannot proceed further!',
                message: 'Sorry! We’re unable to issue the policy due to adverse health',
              })
              shouldProceedFurther = false
              return
            }
          }
        })

      if (!shouldProceedFurther) {
        // navigate('/health/proposal/proposer-insurer-details')
        setShowLifeStyleQuestions(false)
        return
      }

      if (selectedMembers.value.toLowerCase() === 'self') {
        dispatch(
          updateProposer({
            data: {
              ...proposer,

              medical_questions: hasPreExistingDiseases
                ? updatedQuestion
                    .filter((question) => question.question_type === 'medical')
                    .map((ques) => {
                      addResponseKey(ques.sub_ques)
                      return {
                        ...ques,
                        response:
                          ques.type === 'date'
                            ? ques.value.split('-').reverse().join('/')
                            : ques.value,
                      }
                    })
                : [],
            },
            isProposerIncluded: isProposerIncluded,
          }),
        )
      } else {
        dispatch(
          updateMember({
            code: selectedMembers.code,
            memberDetail: {
              ...memberDetails[selectedMembers.code],
              medical_questions: hasPreExistingDiseases
                ? updatedQuestion
                    .filter((question) => question.question_type === 'medical')
                    .map((ques) => {
                      addResponseKey(ques.sub_ques)
                      return {
                        ...ques,
                        response:
                          ques.type === 'date'
                            ? ques.value.split('-').reverse().join('/')
                            : ques.value,
                      }
                    })
                : [],
            },
          }),
        )
      }
    }
    onNextClick()
  }
  const onFormError = (errors: any) => {
    console.log(errors)
  }

  const getInsurerNumber = () => {
    if (selectedQuotes[quoteNumber]) {
      return selectedQuotes[quoteNumber].insuranceNumber.toString()
    } else return ''
  }

  const { control, formState, handleSubmit, register, watch, getValues, setValue } = useForm()
  const { errors, touchedFields } = formState

  const {
    data: medicalQuestionsData,
    error: medicalQuestionError,
    isFetching: medicalQuestionFetching,
  } = useGetQuestionListingQuery({
    insurance_id: getInsurerNumber(),
  })

  const changeQuestionCode = (
    parentCode: string,
    node: QuestionBody,
    storedSubQuestion: QuestionBody | null,
  ) => {
    node.code = parentCode + '$' + node.code
    if (storedSubQuestion) {
      node.value = storedSubQuestion.response
    }
    if (node.sub_ques.length > 0) {
      node.sub_ques.forEach((subQues, index) => {
        if (storedSubQuestion) {
          changeQuestionCode(node.code, subQues, storedSubQuestion.sub_ques[index])
        } else {
          changeQuestionCode(node.code, subQues, null)
        }
      })
    }
    return
  }

  const changeQuestionFormat = (questions: GetQuestionsListingResponse[]) => {
    const tempQuestions: GetQuestionsListingResponse[] = JSON.parse(JSON.stringify(questions))
    tempQuestions
      .filter((question) => question.question_type === 'medical')
      .forEach((question, quesIndex) => {
        if (storedQuestions[quesIndex]) {
          // storedQuestions[quesIndex].response
          question.value = storedQuestions[quesIndex].response
        }
        if (question.sub_ques.length > 0) {
          question.sub_ques.forEach((subQues, index) => {
            // console.log(storedQuestions[quesIndex], 'this is a bug')
            if (storedQuestions[quesIndex] && storedQuestions[quesIndex].sub_ques[index]) {
              changeQuestionCode(question.code, subQues, storedQuestions[quesIndex].sub_ques[index])
            } else {
              changeQuestionCode(question.code, subQues, null)
            }
          })
        }
      })

    // console.log(tempQuestions, 'ddddddddd')

    return tempQuestions
  }

  return (
    <form className='h-full' onSubmit={handleSubmit(onFormSubmit, onFormError)}>
      {/* FIXME */}
      {medicalQuestionsData && (
        <>
          <Box height={'390px'} p='4' overflowY='scroll'>
            <RecursiveContainer
              questions={changeQuestionFormat(medicalQuestionsData)}
              register={register}
              errors={errors}
              touchedFields={touchedFields}
              getValues={getValues}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          </Box>
        </>
      )}
      {medicalQuestionFetching && <Spinner></Spinner>}
      {!medicalQuestionsData && medicalQuestionFetching && <Text p='10'>No Medical Questions</Text>}

      <Box roundedBottomLeft='2xl' boxShadow='0px -2px 6px #00000029'>
        <ButtonComponent onLeftButtonClick={onBackPress} left='Back' right='Next' />
      </Box>
    </form>
  )
}
const RecursiveContainer = ({
  questions,
  register,
  errors,
  touchedFields,
  getValues,
  watch,
  control,
  setValue,
}: {
  questions: GetQuestionsListingResponse[]
  register: any
  errors: any
  touchedFields: any
  getValues: any
  watch: any
  control: any
  nested?: boolean
  setValue: UseFormSetValue<FieldValues>
}) => {
  const { proposalDetails: proposalDetailsInStore } = useAppSelector((state) => state.proposalPage)
  const { clearErrors } = useForm()
  const currentDate = new Date()
  function converNumberFormator(d: number) {
    return d < 10 ? '0' + d.toString() : d.toString()
  }

  const oneYearsFromCurrentDate =
    currentDate.getFullYear() +
    1 +
    '-' +
    (converNumberFormator(currentDate.getMonth()) + 1) +
    '-' +
    converNumberFormator(currentDate.getDate())

  const builder = (question: GetQuestionsListingResponse) => {
    switch (question.type) {
      case 'text':
        return (
          <Box key={question.code}>
            <Controller
              name={question.code}
              control={control}
              rules={{ required: true }}
              defaultValue={question.value}
              render={({ field }) => (
                <FormControl isInvalid={errors[question.code] ? true : false}>
                  <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                  <TextInput type='text' {...register(question.code)} />
                  {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                </FormControl>
              )}
            />
          </Box>
        )
      case 'date':
        return (
          <Box key={question.code}>
            <Controller
              name={question.code}
              control={control}
              rules={{ required: true }}
              defaultValue={question.value}
              render={({ field }) => (
                <FormControl isInvalid={errors[question.code] ? true : false}>
                  <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                  <input
                    type='date'
                    onKeyDown={(e) => e.preventDefault()}
                    className='outline-none border text-sm px-3 bg-[#E2E8F0] w-36 py-1 rounded-sm '
                    {...register(question.code, { required: true })}
                    onChange={(e) => {
                      setValue(question.code, e.target.value)
                      clearErrors(question.code)
                    }}
                    clearErrors={() => clearErrors('dob')}
                    min={
                      question.main_question.toLowerCase().includes('expected')
                        ? new Date().toISOString().slice(0, 10)
                        : ''
                    }
                    max={
                      question.main_question.toLowerCase().includes('expected')
                        ? oneYearsFromCurrentDate
                        : new Date().toISOString().slice(0, 10)
                    }
                  />
                  {/* <TextInput {...register(question.code)} placeholder='dd/mm/yyyy' /> */}
                  {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                </FormControl>
              )}
            />
          </Box>
        )
      case 'bool':
        return (
          <Box>
            <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
            <Controller
              name={question.code}
              control={control}
              rules={{ required: true }}
              defaultValue={question.value}
              render={({ field }) => (
                <FormControl isInvalid={errors[question.code] ? true : false}>
                  <RadioGroup onChange={(value) => field.onChange(value)} value={field.value}>
                    <Stack direction='row'>
                      <Radio value='1'>Yes</Radio>
                      <Radio value='0'>No</Radio>
                    </Stack>
                  </RadioGroup>
                  {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                </FormControl>
              )}
            />
          </Box>
        )
      case 'dropdown':
        return (
          <>
            <Box key={question.code}>
              <Controller
                name={question.code}
                control={control}
                rules={{ required: true }}
                defaultValue={{
                  lable: question.value,
                  value: question.value,
                }}
                render={({ field }) => (
                  <FormControl isInvalid={errors[question.code] ? true : false}>
                    <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                    <ChakraSelect
                      {...register(question.code)}
                      options={question.multi_value_list?.map((value: any) => {
                        return {
                          label: value.toString(),
                          value: value.toString(),
                        }
                      })}
                      onChange={(item: any) => setValue(question.code, item.value)}
                    />

                    {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                  </FormControl>
                )}
              />
            </Box>
          </>
        )
      case 'multi-select':
        return (
          <>
            <Box key={question.code}>
              <Controller
                name={question.code}
                control={control}
                rules={{ required: true }}
                defaultValue={{
                  lable: question.value,
                  value: question.value,
                }}
                render={({ field }) => (
                  <FormControl isInvalid={errors[question.code] ? true : false}>
                    <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                    <ChakraSelect
                      {...register(question.code)}
                      isMulti
                      options={question.value.map((value: any) => {
                        return {
                          label: value.toString(),
                          value: value.toString(),
                        }
                      })}
                      onChange={(item: any) => setValue(question.code, item.value)}
                    />

                    {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                  </FormControl>
                )}
              />
            </Box>
          </>
        )
      case 'knockout':
        return (
          <>
            <Checkbox
              value={question.main_question}
              key={question.code}
              onChange={(e) => {
                // console.log(e.target.checked, '----------ddd-------')
                setValue(question.code, e.target.checked ? 1 : 0)
                // ToastService.showErrorToast({
                //   title: 'Sorry! We’re unable to issue the policy due to adverse health',
                // })
              }}
            >
              <Text>{question.main_question}</Text>
            </Checkbox>
          </>
        )
      default:
        return <Text>Unsupported field</Text>
    }
  }

  return (
    <>
      {questions
        .filter((question) => question.question_type === 'medical')
        .map((question, index) => {
          const watchShowAge = watch(question.code, question.value)
          // console.log(watchShowAge, '-------4444-----------------')
          return (
            <Box key={question.code} borderBottom='1px' py='2'>
              <>
                {builder(question)}
                {question.sub_ques.length > 0 && watchShowAge === '1' && (
                  <Box ml='6'>
                    <RecursiveContainer
                      questions={question.sub_ques || []}
                      register={register}
                      errors={errors}
                      touchedFields={touchedFields}
                      getValues={getValues}
                      watch={watch}
                      control={control}
                      setValue={setValue}
                    />
                  </Box>
                )}
              </>
            </Box>
          )
        })}
    </>
  )
}
