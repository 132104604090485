import { ArrowForwardIcon, CheckIcon, DownloadIcon, Icon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  List,
  ListItem,
  Text,
  Image,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'
import { BsDownload, BsFillCheckSquareFill, BsFillShareFill } from 'react-icons/bs'
import { IndianRupeeFormatFromString } from 'Services/general'
import React, { useState, useEffect } from 'react'
import { Modal, theme } from 'Core'
import { CompareModal } from 'Core/Modal/CompareModal'
import { CashlessModal } from 'Core/Modal/CashlessModal/index'
import { FilterDialog } from '../Quotation/FilterDialog'
import { RecommendedPlan } from 'Models/ResponseModels/Life'
import { useAppDispatch } from 'app/hooks'
import { updateFinalSelectedPlan } from 'features/Life/LifeQuotationDetails.slice'
import { updateQuotationsApiLoading } from 'features/Life/PlanData.slice'
import { useLifeQuotation } from '../Quotation/useLifeQuotation'

interface IQuotationCardProps extends React.ComponentPropsWithRef<'div'> {
  plan: RecommendedPlan
}

export const LifeQuotationCard = (props: IQuotationCardProps) => {
  const { plan } = props
  const [isCashlessModalOpen, setIsCashlessModalOpen] = useState(false)
  const [imageLoadFail, setImageLoadFail] = useState(false)
  const [filter, setFilter] = useState(false)
  const dispatch = useAppDispatch()

  const { onClose, isOpen, onOpen } = useDisclosure()

  const buyOnClickFunc = () => {
    onOpen()
    dispatch(updateFinalSelectedPlan(plan))
  }

  useEffect(() => {
    setFilter(false)
    dispatch(updateQuotationsApiLoading(false))
  }, [])

  const {
    isOpen: isPlanDetailModalOpen,
    onOpen: onPlanDetailModalOpen,
    onClose: onPlanDetailModalClose,
  } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <>
      <Flex w='full' direction={'column'}>
        <Box>
          <Box
            border='1px'
            // borderColor={`${selected ? '#2680EB' : ''}`}
            rounded='2xl'
            bg='white'
            shadow='lg'
            id={plan.code}
          >
            <Flex justifyContent={'flex-end'} m='3'>
              {/* <Flex justifyContent={'center'} gap='2' alignItems={'center'}>
                {isChecked ? (
                  <>
                    <Icon
                      cursor={'pointer'}
                      as={BsFillCheckSquareFill}
                      color={'blue.900'}
                      onClick={addQuoteToCompare}
                    />
                  </>
                ) : (
                  <Box   cursor={'pointer'} onClick={addQuoteToCompare} bg='white' border='1px' h='4' w='4'></Box>
                )}{' '}
                Compare
                <Checkbox onChange={() => {
                  addQuoteToCompare()

                }}>Compare</Checkbox>
              </Flex> */}
            </Flex>
            <Box padding='3' margin='3'>
              <Flex w='full' gap='2' h='12' justifyContent='space-between' alignItems='center'>
                <div className='border border-[#E0E0E0] my-auto'>
                  {/* <img src={insurerLogo} className='w-[10vh] my-4 ' /> */}
                  {imageLoadFail ? (
                    <Center>
                      <Text width='100%' height='100%' fontSize='sm' textAlign='center'>
                        {plan.name}
                      </Text>
                    </Center>
                  ) : (
                    <Image
                      w='90px'
                      h='auto'
                      src={plan.logo}
                      // border='1px solid #F1F3F4'
                      onError={() => setImageLoadFail(true)}
                    />
                  )}
                </div>
                <Text fontWeight='semibold' fontSize='xs' textAlign='left'>
                  {plan.name}
                </Text>
              </Flex>
              <Text my='2' fontSize='xs' fontWeight='bold' textAlign='left'>
                Benefits
              </Text>
              <Box h='80px' overflowY='auto'>
                {plan.plan_details?.benefits_list?.map((item, index) => (
                  <Flex key={index}>
                    <CheckIcon className='my-auto mx-1' color='primary.500' />
                    <Text className='text-xs text-[#7B7B7B]'>{item}</Text>
                  </Flex>
                ))}
              </Box>
            </Box>
            <Flex justifyContent='space-between' px='4' mb='5' color={'#1C4589'} fontSize='md'>
              <Text
                cursor={'pointer'}
                onClick={() => {
                  onPlanDetailModalOpen()
                }}
              >
                Plan Details
              </Text>
              <Text cursor='pointer' onClick={() => window.open(plan.broucher_url, '_blank')}>
                <DownloadIcon /> Brochure
              </Text>
            </Flex>
            <Box position='relative' bottom='0'>
              <Flex
                w='full'
                justifyContent={'space-between'}
                // flexBasis={'60%'}
                alignItems={'center'}
              >
                {/* <Flex alignItems='center' justifyContent='space-between' fontSize='md'>
                  <Text ml='4'>Pay: </Text>
                  <Text color='red'>₹{Math.round(plan.premium).toLocaleString('en-IN')}/yr</Text>
                </Flex> */}
                <Button
                  // width={'50%'}
                  w='full'
                  roundedTopLeft={'none'}
                  roundedTopRight={'none'}
                  //   roundedBottomLeft='14px'
                  roundedBottom='14px'
                  variant='solid'
                  colorScheme={'primary'}
                  gap={'2'}
                  onClick={() => {
                    buyOnClickFunc()
                  }}
                >
                  BUY NOW <ArrowForwardIcon />
                </Button>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Flex>
      <FilterDialog isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

      <Drawer isOpen={isPlanDetailModalOpen} placement='bottom' onClose={onPlanDetailModalClose}>
        <DrawerOverlay />
        <DrawerContent w='90%' mx='auto' borderTopRadius={'3xl'}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex gap='2'>
              <Text fontSize={'lg'}>Plan Details For : </Text>
              <Image
                className='w-[10vh]'
                src={plan.logo}
                // border='1px solid #F1F3F4'
                onError={() => setImageLoadFail(true)}
              />
              <Text fontWeight={'medium'} fontSize={'lg'}>
                {plan.name}
              </Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody px='0'>
            <Tabs isFitted colorScheme='primary' variant='unstyled'>
              <TabList>
                {plan.plan_details &&
                  plan.plan_details.plan_details?.map((item, index) => {
                    return (
                      <Tab
                        border='1px'
                        backgroundColor={'#ebebeb'}
                        borderColor={'#c4c4c4'}
                        _selected={{
                          borderTop: '4px',
                          borderColor: '#1c4589',
                          backgroundColor: 'white',
                          borderBottom: '0px',
                          borderLeft: '0px',
                          borderRight: '0px',
                        }}
                        key={index}
                      >
                        {item.title}
                      </Tab>
                    )
                  })}
              </TabList>
              <TabPanels>
                {plan.plan_details &&
                  plan.plan_details.plan_details?.map((item, index) => {
                    return (
                      <TabPanel overflow={'auto'} py='8' px='8' height={'300px'} key={index}>
                        <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
                      </TabPanel>
                    )
                  })}
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* <Modal isOpen={isCashlessModalOpen} onClose={() => setIsCashlessModalOpen(false)}>
        <CashlessModal />
      </Modal> */}
    </>
  )
}
