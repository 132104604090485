interface ICheckProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string
}

export const Check: React.FC<ICheckProps> = (props: ICheckProps) => {
  const { color } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={color}
      style={{ zIndex: 10, backgroundColor: 'white' }}
    >
      <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
    </svg>
  )
}
