import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPlanData } from 'Models/RequestModels/Life'
import { RecommendedPlan } from 'Models/ResponseModels/Life'

export interface ILifeQuotationStoreValues {
  planIdForTerm: string[]
  planIdForEndowment: string[]
  selectedRecommendedPlanType?: string
  suitabilityData: ISuitabilityData
  suitabilityData2?: Record<string, string | number | boolean | string[]>
  life_assured_id?: number
  proposer_id?: number
  finalSelectedPlan?: RecommendedPlan
  redirectionURL: string
  application_nummber: string
  consent_id?: number
  consent_status?: 'APPROVED' | 'UNAPPROVED'
  finalRecommendedProducts?: RecommendedPlan[]
  proposerData?: any
  insuredData?: any
  suitabilityStep: 'one' | 'two'
  planQuoteId: string
  relationship?: string
}

export interface ISuitabilityData {
  risk?: string
  annual_disposable_income?: string
  age_band?: string
  need?: string
  payment_preference?: string
  goal_horizon?: string
  life_stage?: string
}

const initialState: ILifeQuotationStoreValues = {
  planIdForTerm: [],
  planIdForEndowment: [],
  suitabilityData: {},
  redirectionURL: '',
  suitabilityStep: 'one',
  planQuoteId: '',
  application_nummber: '',
  consent_status: 'UNAPPROVED',
}

const lifeQuotationDetails = createSlice({
  name: 'lifeQuotationDetails',
  initialState: initialState,
  reducers: {
    savePlanIdForTerm: (state, action: PayloadAction<string[]>) => {
      state.planIdForTerm = action.payload
    },
    updateSuitabilityStep: (state, action: PayloadAction<'one' | 'two'>) => {
      state.suitabilityStep = action.payload
    },
    updateSuitabilityData2: (
      state,
      action: PayloadAction<Record<string, string | number | boolean | string[]> | undefined>,
    ) => {
      state.suitabilityData2 = action.payload
    },
    savePlanIdForEndowment: (state, action: PayloadAction<string[]>) => {
      state.planIdForEndowment = action.payload
    },
    updateRecommendedPlanType: (state, action: PayloadAction<string>) => {
      state.selectedRecommendedPlanType = action.payload
    },
    updateSuitabilityData: (state, action: PayloadAction<ISuitabilityData>) => {
      state.suitabilityData = action.payload
    },
    updateCustomerIds: (state, action: PayloadAction<any>) => {
      state.life_assured_id = action.payload.life_assured_id
      state.proposer_id = action.payload.proposer_id
    },
    updateFinalSelectedPlan: (state, action: PayloadAction<RecommendedPlan>) => {
      state.finalSelectedPlan = action.payload
    },
    resetFinalSelectedPlan: (state) => {
      state.finalSelectedPlan = undefined
    },
    updateConsentId: (state, action: PayloadAction<number>) => {
      state.consent_id = action.payload
    },
    updateConsentStatus: (state, action: PayloadAction<'APPROVED' | 'UNAPPROVED'>) => {
      state.consent_status = action.payload
    },
    updateAllRecommendedPlans: (state, action: PayloadAction<RecommendedPlan[]>) => {
      state.finalRecommendedProducts = action.payload
    },
    updateProposerData: (state, action: PayloadAction<any>) => {
      state.proposerData = action.payload
    },
    updateInsuredData: (state, action: PayloadAction<any>) => {
      state.insuredData = action.payload
    },
    resetRecommendationState: () => initialState,
    updateRedirectionURL: (state, action: PayloadAction<string>) => {
      state.redirectionURL = action.payload
    },
    updateApplicationNumber: (state, action: PayloadAction<string>) => {
      state.application_nummber = action.payload
    },
    updatePlanQuoteId: (state, action: PayloadAction<string>) => {
      state.planQuoteId = action.payload
    },
    updateRelationship: (state, action: PayloadAction<string>) => {
      state.relationship = action.payload
    },
    resetLifeQuotationDetails: (state) => {
      state.consent_id = undefined
      state.finalRecommendedProducts = []
      state.finalSelectedPlan = undefined
      state.life_assured_id = undefined
      state.planIdForEndowment = []
      state.planIdForTerm = []
      state.proposer_id = undefined
      state.planQuoteId = ''
      state.redirectionURL = ''
      state.selectedRecommendedPlanType = ''
      state.consent_status = 'UNAPPROVED'
    },
    updateAllQuotationDetailsLife: (state, action: PayloadAction<ILifeQuotationStoreValues>) => {
      const {
        planIdForEndowment,
        planIdForTerm,
        redirectionURL,
        suitabilityData,
        suitabilityStep,
        consent_id,
        finalRecommendedProducts,
        finalSelectedPlan,
        insuredData,
        life_assured_id,
        proposerData,
        proposer_id,
        selectedRecommendedPlanType,
        suitabilityData2,
        application_nummber,
        consent_status,
        relationship,
      } = action.payload
      if (consent_status) {
        state.consent_status = consent_status
      }
      if (relationship) {
        state.relationship = relationship
      }
      // if (application_nummber) {
      state.application_nummber = application_nummber
      // }
      if (planIdForEndowment) {
        state.planIdForEndowment = planIdForEndowment
      }
      if (planIdForTerm) {
        state.planIdForTerm = planIdForTerm
      }
      // if (redirectionURL) {
      state.redirectionURL = redirectionURL
      // }
      if (suitabilityData) {
        state.suitabilityData = suitabilityData
      }
      if (suitabilityStep) {
        state.suitabilityStep = suitabilityStep
      }
      if (consent_id) {
        state.consent_id = consent_id
      }
      if (finalRecommendedProducts) {
        state.finalRecommendedProducts = finalRecommendedProducts
      }
      if (finalSelectedPlan) {
        state.finalSelectedPlan = finalSelectedPlan
      }
      if (insuredData) {
        state.insuredData = insuredData
      }
      if (life_assured_id) {
        state.life_assured_id = life_assured_id
      }
      if (proposerData) {
        state.proposerData = proposerData
      }
      if (proposer_id) {
        state.proposer_id = proposer_id
      }
      if (selectedRecommendedPlanType) {
        state.selectedRecommendedPlanType = selectedRecommendedPlanType
      }
      if (suitabilityData2) {
        state.suitabilityData2 = suitabilityData2
      }
      if (relationship) {
        state.relationship = relationship
      }
    },
  },
})

const { reducer } = lifeQuotationDetails

const {
  savePlanIdForTerm,
  updateRelationship,
  updateSuitabilityStep,
  savePlanIdForEndowment,
  updateRecommendedPlanType,
  updateSuitabilityData2,
  resetRecommendationState,
  updateSuitabilityData,
  updateCustomerIds,
  updateFinalSelectedPlan,
  resetFinalSelectedPlan,
  updateRedirectionURL,
  updateAllRecommendedPlans,
  updateInsuredData,
  updateProposerData,
  updateConsentId,
  updateAllQuotationDetailsLife,
  updatePlanQuoteId,
  updateApplicationNumber,
  resetLifeQuotationDetails,
  updateConsentStatus,
} = lifeQuotationDetails.actions

export {
  reducer as lifeQuotationDetails,
  savePlanIdForTerm,
  savePlanIdForEndowment,
  updateRelationship,
  updateRecommendedPlanType,
  resetRecommendationState,
  updateSuitabilityData2,
  updateSuitabilityStep,
  updateSuitabilityData,
  updateCustomerIds,
  updateFinalSelectedPlan,
  resetFinalSelectedPlan,
  updateRedirectionURL,
  updateAllRecommendedPlans,
  updateAllQuotationDetailsLife,
  updateInsuredData,
  updateProposerData,
  updateConsentId,
  updatePlanQuoteId,
  updateApplicationNumber,
  resetLifeQuotationDetails,
  updateConsentStatus,
}
