import { Box, Center, Divider, Flex, Skeleton, Stack, Text, Image } from '@chakra-ui/react'
import { useState } from 'react'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { IndianRupeeFormatFromString } from 'Services/general'
import { IInformationCardProps } from '../interface'

export const InformationCardDesktop = (props: IInformationCardProps) => {
  const [imageLoadFail, setImageLoadFail] = useState(false)
  return (
    <>
      {!props.loading && (
        <Flex direction={'column'} paddingY='2' gap='10'>
          <Box key={props.id} paddingX={`${props.isExpanded ? '14' : ''}`}>
            <Flex
              gap={`${props.isExpanded ? '12' : '6'}`}
              paddingX={`${props.isExpanded ? '12' : ''}`}
            >
              <Box border='1px' borderColor='#E0E0E0' marginY='auto' backgroundColor='white'>
                {imageLoadFail ? (
                  <Center>
                    <Text width='100%' height='100%' fontSize='sm' textAlign='center'>
                      {props.insurerName}
                    </Text>
                  </Center>
                ) : (
                  <Image
                    src={props.image}
                    className={`${props.isExpanded ? 'w-[12vh] m-2' : 'w-[10vh] my-4'}`}
                    onError={() => setImageLoadFail(true)}
                  />
                )}
              </Box>
              <Flex direction={'column'} my='auto'>
                <Text fontSize='lg' marginY='1' fontWeight='bold'>
                  {props.insurerName}
                </Text>
                <Flex fontSize='sm' direction={'column'} gap='1'>
                  <Text>Sum Insured : ₹{IndianRupeeFormatFromString(props.sumAssured)}</Text>
                  <Text>Policy For : {props.policyFor}</Text>
                  <Text>Pincode : {props.pincode}</Text>
                </Flex>
              </Flex>
              {props.isExpanded ? (
                ''
              ) : (
                <Box w='1' h='28' my='auto'>
                  <Divider orientation='vertical' borderColor='#D8D8D8' />
                </Box>
              )}
              <Box paddingX={`${props!.isExpanded ? '12' : ''}`} marginY='auto'>
                <Flex direction={'column'}>
                  <Text fontWeight='bold' fontSize='xl'>
                    Final Premium
                  </Text>
                  <Flex gap='2'>
                    <Text fontWeight='bold' fontSize='lg'>
                      ₹{IndianRupeeFormatFromString(props!.finalPremium)}
                    </Text>
                    <Text fontSize='sm' marginY='auto'>
                      Inc. GST
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              {props!.isExpanded ? (
                <Box marginY='auto'>
                  <RiDeleteBin5Line color='appGrey' size='24' cursor='pointer' />
                </Box>
              ) : (
                ''
              )}
            </Flex>
            {props!.isExpanded ? (
              <Divider orientation='horizontal' borderColor='#D8D8D8' mt='4' />
            ) : (
              ''
            )}
          </Box>
        </Flex>
      )}
      {props.loading && (
        <Stack p='4'>
          <Skeleton height='20px' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
        </Stack>
      )}
    </>
  )
}

// <div className=' h-[120px] w-[2px] bg-[#D8D8D8] mt-12'></div>
