import { Box, Flex, Image, Text } from '@chakra-ui/react'

interface IHomeInfoItem {
  label: string
  imageSource: string
}

const HomeInfoItem = (props: IHomeInfoItem) => {
  const { imageSource, label } = props
  return (
    <Flex alignItems='center' maxW='150px'>
      <Box padding='2' flexBasis='40%'>
        <Image alt='info' src={imageSource} />
      </Box>
      <Box flexBasis='60%'>
        <Text fontSize='small'>{label}</Text>
      </Box>
    </Flex>
  )
}

export { HomeInfoItem }
