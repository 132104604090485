import { saodEnableDate } from 'Constants'
import { differenceInDays } from 'date-fns'
import { getDateFromDDMMYYYY } from 'Services/general'
import { useAppSelector } from '.'
import { useVehicleType } from './useVehicleType'

const useShowSaod = () => {
  const { registrationDate } = useAppSelector((state) => state.vehicleDetails.vehicleDetails)

  const { param } = useVehicleType()

  let showSaod = true

  if (registrationDate) {
    showSaod = differenceInDays(getDateFromDDMMYYYY(registrationDate ?? ''), saodEnableDate) > 0
  }

  if (param !== 'is_fourwheeler' && param !== 'is_twowheeler') {
    showSaod = false
  }

  return { showSaod }
}

export { useShowSaod }
