import { store } from 'app/store'
import React from 'react'

interface ISetVehicleDetailsValueInForm {
  setManufacturer: React.Dispatch<
    React.SetStateAction<{
      value: string | number
      label: string
    }>
  >
  setModel: React.Dispatch<
    React.SetStateAction<{
      value: string | number
      label: string
    }>
  >
  setVariant: React.Dispatch<
    React.SetStateAction<{
      value: string | number
      label: string
    }>
  >
  setManufacturingYear: React.Dispatch<
    React.SetStateAction<{
      label: string
      value: string | number
    }>
  >
  setRegistrationDate: React.Dispatch<React.SetStateAction<string>>
}

export const setVehicleDetailsValueInForm = (setterObjects: ISetVehicleDetailsValueInForm) => {
  const { setManufacturer, setModel, setVariant, setManufacturingYear, setRegistrationDate } =
    setterObjects

  const {
    vehicleDetails: {
      selectedManufacturer,
      selectedModel,
      selectedVariant,
      manufacturingYear,
      registrationDate,
    },
  } = store.getState().vehicleDetails

  if (selectedManufacturer) {
    setManufacturer({ label: selectedManufacturer.name, value: selectedManufacturer.code })
  }
  if (selectedModel) {
    setModel({ label: selectedModel.name, value: selectedModel.code })
  }
  if (selectedVariant) {
    setVariant({ label: selectedVariant.name, value: selectedVariant.code })
  }
  if (manufacturingYear) {
    setManufacturingYear({ label: manufacturingYear.toString(), value: manufacturingYear })
  }
  if (registrationDate) {
    setRegistrationDate(registrationDate)
  }
}

interface ISetVehicleInsuranceDetailsValueInForm {
  setSelectedPrevInsurer: React.Dispatch<React.SetStateAction<{ value: string; label: string }>>
  setPolicyExpiryDate: React.Dispatch<React.SetStateAction<string>>
}

export const setVehicleInsuranceDetailsValueInForm = (
  setterObjects: ISetVehicleInsuranceDetailsValueInForm,
) => {
  const { setPolicyExpiryDate, setSelectedPrevInsurer } = setterObjects
  const {
    vehicleInsuranceDetails: { existingPolicyExpiryDate, previousInsurer },
  } = store.getState().vehicleDetails

  if (existingPolicyExpiryDate) {
    setPolicyExpiryDate(existingPolicyExpiryDate)
  }
  if (previousInsurer) {
    setSelectedPrevInsurer({ label: previousInsurer.name, value: previousInsurer.code })
  }
}
