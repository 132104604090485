import { Box, Button, Center, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ElevatedButton } from 'Core'
import { updateSpConsentStatus } from 'features/Equitas'
import { useEffect, useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  useGetSpConsentFormLinkMutation,
  useLazyCheckForspConsentSubmissionStatusQuery,
} from 'Services/API/motor.api'
import { TMotorProposalFormType } from '../Forms/FormModel'
import { ProposalSpTaggingForm } from '../Forms/ProposalSpTaggingForm'
import { ProposalSubmitModal } from '../Forms/ProposalSubmitModal'
import { format } from 'date-fns'
import { ToastService } from 'Services/toast.service'

interface IProposalSPTaggingDesktop {
  rounded?: boolean
  form: UseFormReturn<TMotorProposalFormType, any>
}

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

const ProposalSPTaggingDesktop = (props: IProposalSPTaggingDesktop) => {
  const { form, rounded = false } = props

  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useAppDispatch()

  const { proposalStatus } = useAppSelector((state) => state.proposalPage)
  const { spConsentStatus } = useAppSelector((state) => state.spTagging)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const { ucic, proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const selectedSp = useAppSelector((state) => state.spTagging.selectedSp)
  const { isSaod } = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)

  const [
    getSpConsent,
    {
      isLoading: isGetSpConsentLoading,
      isSuccess: isGetSpConsentSuccess,
      isUninitialized: isGetSpConsentUninitialized,
    },
  ] = useGetSpConsentFormLinkMutation()

  const submitRef = useRef<HTMLButtonElement | null>(null)

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const [isCheckSubmissionRunning, setIsCheckSubmissionRunning] = useState(false)

  const [pollingInterval, setPollingInterval] = useState(4000)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  useEffect(() => {
    dispatch(updateSpConsentStatus('not-sent'))
  }, [])

  const [checkForSpConsentSubmissionStatus, { data: submissionResponse }] =
    useLazyCheckForspConsentSubmissionStatusQuery({
      pollingInterval: pollingInterval,
    })

  useEffect(() => {
    if (spConsentStatus === 'pending' && timeLeft === TIMER_LIMIT) {
      startTimer()
    }
  }, [spConsentStatus])

  return (
    <>
      <Flex
        flexBasis='40%'
        border='1px'
        borderRightRadius={rounded ? 'lg' : ''}
        maxWidth='100%'
        direction='column'
      >
        <Box padding='3' boxShadow='excludingTop'>
          <Text fontSize='md' textAlign='left' paddingX='4' paddingY='2' fontWeight='bold'>
            SP Tagging
          </Text>
        </Box>
        <div className={proposalStatus === 'success' ? 'disabled' : ''}>
          <ProposalSpTaggingForm form={form} />
          <Center paddingY='4'>
            <Button ref={submitRef} hidden type='submit'>
              hidden submit
            </Button>
            {spConsentStatus !== 'not-sent' && (
              <Flex>
                {spConsentStatus !== 'success' && (
                  <Button
                    colorScheme='primary'
                    marginX='4'
                    isDisabled={!resendOtp}
                    isLoading={isGetSpConsentLoading}
                    borderRadius='full'
                    onClick={async () => {
                      try {
                        const resp: any = await getSpConsent({
                          lead_uid: leadDetails.lead_uid ?? '',
                          resend_link: true,
                          unique_identifier:
                            quotationDetails.selectedQuote?.unique_identifier ?? '',
                          // payment_link: 'remove_this_key',
                          plan_type: isSaod
                            ? 'Stand Alone OD'
                            : quotationDetails.isComprehensive
                            ? 'Comprehensive'
                            : 'Third Party',
                          customer: {
                            owner_type: quotationDetails.isIndividual ? 'Individual' : 'Company',
                            application_no: quotationDetails.selectedQuote?.unique_identifier ?? '',
                            customer_name: quotationDetails.isIndividual
                              ? (
                                  (form.getValues('firstName') ?? '') +
                                  ' ' +
                                  (form.getValues('middleName') ?? '') +
                                  ' ' +
                                  (form.getValues('lastName') ?? '')
                                ).trim()
                              : form.getValues('companyName') ?? '',
                            dependent_name: quotationDetails.isIndividual
                              ? (
                                  (form.getValues('nomineeFirstName') ?? '') +
                                  ' ' +
                                  (form.getValues('nomineeMiddleName') ?? '') +
                                  ' ' +
                                  (form.getValues('nomineeLastName') ?? '')
                                ).trim()
                              : form.getValues('companyName') ?? '',
                            email_id: selectedSp?.email ?? '',
                            insurance_type: 'Motor',
                            login_date: format(new Date(), 'dd/MM/yyyy'),
                            method: 'Insurer Payment Gateway',
                            mode: 'Online',
                            phone: selectedSp?.phone ? '91' + selectedSp?.phone : '',
                            plan_name: quotationDetails.selectedQuote?.insurance_name ?? '',
                            policy_purchased_for: quotationDetails.isIndividual
                              ? (
                                  (form.getValues('firstName') ?? '') +
                                  ' ' +
                                  (form.getValues('middleName') ?? '') +
                                  ' ' +
                                  (form.getValues('lastName') ?? '')
                                ).trim()
                              : form.getValues('companyName') ?? '',
                            policy_term: '1 Year',
                            premium:
                              quotationDetails.selectedQuote?.premium_total?.toString() ?? '',
                            premium_payment_term: 'Annually',
                            sp_branch: selectedSp?.branch_key__branch_name ?? '',
                            sp_code: selectedSp?.id?.toString() ?? '',
                            sp_name: (
                              (selectedSp?.firstname ?? '') +
                              ' ' +
                              (selectedSp?.lastname ?? '')
                            ).trim(),
                            ucic: ucic ?? '',
                            lead_uid: leadDetails.lead_uid ?? '',
                          },
                        })
                        if (resp['data']) {
                          dispatch(updateSpConsentStatus('pending'))
                          await checkForSpConsentSubmissionStatus({
                            unique_identifier:
                              quotationDetails.selectedQuote?.unique_identifier ?? '',
                          })
                          ToastService.showSuccessTaost({
                            title: 'Success',
                            message: `SP Consent form link has been sent to SP's phone and email`,
                          })
                          setResendOtp(false)
                          startTimer()
                          // dispatch(updateSpConsentStatus('pending'))
                          setIsCheckSubmissionRunning(true)
                        }
                      } catch (e) {
                        console.log(e)
                      }
                    }}
                  >
                    {resendOtp ? 'Resend Consent' : `Resend Consent in ${timeLeft}`}
                  </Button>
                )}
                <ElevatedButton
                  colorScheme='primary'
                  // type='submit'
                  isDisabled={spConsentStatus !== 'success'}
                  onClick={async () => {
                    // console.log(form.getFieldState('nomineeDob'))
                    // if (form.watch('nomineeDob')) {
                    //   console.log('asdfg')
                    //   console.log(form.clearErrors('nomineeDob'))
                    // }
                    const isFormValid = await form.trigger()
                    if (isFormValid) onOpen()
                  }}
                  isLoading={proposalStatus === 'loading'}
                >
                  Submit
                </ElevatedButton>
              </Flex>
            )}
          </Center>
        </div>
      </Flex>
      <ProposalSubmitModal isOpen={isOpen} onClose={onClose} submitBtnRef={submitRef} />
    </>
  )
}

export { ProposalSPTaggingDesktop }
