import { Box, Flex, Text } from '@chakra-ui/react'
import { useAppDispatch, useDeviceType } from 'app/hooks'
import { Header } from 'Components'
import { HeadingText } from 'Core'
import { resetCustomerDetails } from 'features/Equitas'
import { updateAllDetails, updateVehicleNumber } from 'features/Home'
import { resetLeadDetails } from 'features/Leads'
import { useEffect } from 'react'
import { CustomerAccountDetailsDesktop } from './Desktop'
import { CustomerAccountDetailsMobile } from './Mobile'

const CustomerAccountDetails = () => {
  const { isDesktop } = useDeviceType()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateVehicleNumber(''))
    dispatch(
      updateAllDetails({ selectedSubType: 'car', selectedType: 'private', vehicleNumber: '' }),
    )
    dispatch(resetLeadDetails())
    dispatch(resetCustomerDetails())
  }, [])

  return (
    <>
      <Header />
      <Box marginX={['5', '5', '7', '10', '20', '20']} marginY='5'>
        <Flex justifyContent='center' alignItems='center'>
          {/* <HeadingText label={'Marketing Image goes here'} /> */}
        </Flex>
        {isDesktop ? <CustomerAccountDetailsDesktop /> : <CustomerAccountDetailsMobile />}
      </Box>
    </>
  )
}

export { CustomerAccountDetails }
