import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Table,
  Text,
  Th,
  Thead,
  Tooltip,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType, useVehicleType } from 'app/hooks'
import BimabossDropdown from 'BimabossDropdown'
import { Select } from 'chakra-react-select'
import { BimabossDatePicker, ErrorText, TextInput, ToggleSwitch } from 'Core'
import { addDays } from 'date-fns'
import { updateClaimsMadeInExistingPolicy, updateVehicleInsuranceForm } from 'features/Motor'
import { IInsurer } from 'Models/ResponseModels'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { getExistingNoClaimBonusArr, getExistingPolicyExpMinDate } from 'Services/general'
import { setVehicleInsuranceDetailsValueInForm } from '../VehicleDetailsUtils'
import { TVehicleInsuranceFormType } from './FormModel/VehicleInsurance'

interface IODPolicyDetailsForm {
  insurersDropdownData: IInsurer[] | undefined
  isInsurersDataLoading: boolean
  hasPreviousPolicy: boolean
  setHasPreviousPolicy: React.Dispatch<React.SetStateAction<boolean>>
  form: UseFormReturn<TVehicleInsuranceFormType, any>
}

const ncbDefaultField = {
  label: 'Select NCB',
  value: '',
}
const prevInsurerDefaultField = {
  label: 'Select Prev insurer',
  value: '',
}
const prevPolicyTypeDefaultField = {
  label: 'Select Prev. Policy Type',
  value: '',
}

const permitTypeDefaultField = {
  label: 'Select Permit Type',
  value: '',
}

const ODPolicyDetailsForm = (props: IODPolicyDetailsForm) => {
  const {
    vehicleInsuranceDetails: vehicleInsuranceDetailsInStore,
    vehicleDetails: vehicleDetailsInStore,
  } = useAppSelector((state) => state.vehicleDetails)

  const {
    insurersDropdownData,
    isInsurersDataLoading,
    hasPreviousPolicy,
    setHasPreviousPolicy,
    form: { register, formState, setValue, clearErrors, resetField, watch },
  } = props

  const [policyExpiryDate, setPolicyExpiryDate] = useState(
    vehicleInsuranceDetailsInStore.existingPolicyExpiryDate ?? '',
  )

  const [selectedPrevInsurer, setSelectedPrevInsurer] = useState<{
    value: string
    label: string
  }>(
    vehicleInsuranceDetailsInStore.previousInsurer
      ? {
          label: vehicleInsuranceDetailsInStore.previousInsurer.name,
          value: vehicleInsuranceDetailsInStore.previousInsurer.code,
        }
      : prevInsurerDefaultField,
  )
  const [selectedPrevPolicyType, setSelectedPrevPolicyType] = useState(
    vehicleInsuranceDetailsInStore.previousPolicyType
      ? {
          label: vehicleInsuranceDetailsInStore.previousPolicyType.name,
          value: vehicleInsuranceDetailsInStore.previousPolicyType.value,
        }
      : prevPolicyTypeDefaultField,
  )
  const [claimsMadeInExistingPolicy, setClaimsMadeInExistingPolicy] = useState(
    vehicleInsuranceDetailsInStore.claimsMadeInExistingPolicy,
  )
  const [selectedNcb, setSelectedNcb] = useState<{
    value: string
    label: string
  } | null>(
    vehicleInsuranceDetailsInStore.ncb
      ? {
          label: `${vehicleInsuranceDetailsInStore.ncb}%`,
          value: vehicleInsuranceDetailsInStore.ncb,
        }
      : ncbDefaultField,
  )

  const [selectedPermitType, setSelectedPermitType] = useState<{
    value: string
    label: string
  }>(
    vehicleInsuranceDetailsInStore.permitType
      ? {
          label: vehicleInsuranceDetailsInStore.permitType.name,
          value: vehicleInsuranceDetailsInStore.permitType.code,
        }
      : permitTypeDefaultField,
  )

  const dispatch = useAppDispatch()
  const { isMobile } = useDeviceType()
  const { param: vehicleType } = useVehicleType()

  useEffect(() => {
    if (claimsMadeInExistingPolicy) {
      resetField('ncb')
      setSelectedNcb(ncbDefaultField)
    }
    dispatch(updateClaimsMadeInExistingPolicy(claimsMadeInExistingPolicy))
  }, [claimsMadeInExistingPolicy])

  useEffect(() => {
    setVehicleInsuranceDetailsValueInForm({
      setSelectedPrevInsurer: setSelectedPrevInsurer,
      setPolicyExpiryDate: setPolicyExpiryDate,
    })
  }, [vehicleInsuranceDetailsInStore])

  useEffect(() => {
    dispatch(
      updateVehicleInsuranceForm({
        previous_policy_end_date: policyExpiryDate,
      }),
    )
  }, [policyExpiryDate])

  return (
    <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}>
      <GridItem className={hasPreviousPolicy ? '' : 'disabled'} marginY='2' marginX='6'>
        <FormLabel>Existing Policy Expiry Date*</FormLabel>
        <BimabossDatePicker
          inputDate={policyExpiryDate}
          setInputDate={setPolicyExpiryDate}
          clearErrors={() => clearErrors('previous_policy_end_date')}
          minDate={getExistingPolicyExpMinDate(
            vehicleDetailsInStore.registrationDate ?? '',
            vehicleInsuranceDetailsInStore.isSaod,
          )}
          maxDate={addDays(new Date(), 60)}
          {...register('previous_policy_end_date', { required: hasPreviousPolicy })}
        />
        {formState.errors.previous_policy_end_date &&
          formState.errors.previous_policy_end_date?.type === 'required' && (
            <ErrorText text='Policy exp. date is required' />
          )}
      </GridItem>
      {vehicleInsuranceDetailsInStore.isSaod === false && (
        <GridItem marginY='2' marginX='6'>
          <FormLabel>Do you have previous year&apos;s policy?*</FormLabel>
          <ToggleSwitch enabled={hasPreviousPolicy} setEnabled={setHasPreviousPolicy} />
        </GridItem>
      )}
      <GridItem className={hasPreviousPolicy ? '' : 'disabled'} marginY='2' marginX='6'>
        <FormLabel>Previous Insurer*</FormLabel>
        <Select
          selectedOptionColor='primary'
          menuPosition='fixed'
          placeholder='Select Insurer'
          isLoading={isInsurersDataLoading}
          {...register('previous_insurer', { required: hasPreviousPolicy })}
          value={selectedPrevInsurer}
          onChange={(item) => {
            setSelectedPrevInsurer({
              label: item?.label ?? '',
              value: item?.value ?? '',
            })
            setValue('previous_insurer', {
              code: item?.value ?? '',
              name: item?.label ?? '',
            })
            clearErrors('previous_insurer')
          }}
          options={insurersDropdownData?.map((insurer) => {
            return {
              label: insurer.name,
              value: insurer.code,
            }
          })}
        />
        {formState.errors.previous_insurer &&
          formState.errors.previous_insurer?.type === 'required' && (
            <ErrorText text='Previous insurer is required' />
          )}
      </GridItem>
      <GridItem className={hasPreviousPolicy ? '' : 'disabled'} marginY='2' marginX='6'>
        <FormLabel>Previous Policy Type*</FormLabel>
        {vehicleInsuranceDetailsInStore.isSaod ? (
          <TextInput isReadOnly value='Stand Alone OD'></TextInput>
        ) : (
          <Select
            selectedOptionColor='primary'
            menuPosition='fixed'
            placeholder='Select Prev. Policy Type'
            {...register('previous_policy_type', { required: hasPreviousPolicy })}
            value={selectedPrevPolicyType}
            onChange={(item) => {
              setSelectedPrevPolicyType({
                label: item?.label ?? '',
                value: item?.value ?? '',
              })
              setValue('previous_policy_type', {
                name: item?.label ?? '',
                value: item?.value ?? '',
              })
              clearErrors('previous_policy_type')
            }}
            options={[
              {
                label: 'Comprehensive',
                value: 'CO',
              },
              {
                label: 'Third Party',
                value: 'TP',
              },
            ]}
          />
        )}
        {formState.errors.previous_policy_type && (
          <ErrorText text={'Previous Policy Type is required'} />
        )}
      </GridItem>
      {hasPreviousPolicy && (
        <>
          {selectedPrevPolicyType.value !== 'TP' && (
            <GridItem marginY='2' marginX='6'>
              <Tooltip
                hasArrow
                label='Misrepresentation or wrong declaration of NCB% may lead to a claim rejection'
                bg='secondary.500'
              >
                <FormLabel>
                  Claims Made In Existing Policy* <InfoOutlineIcon />
                </FormLabel>
              </Tooltip>
              <ToggleSwitch
                enabled={claimsMadeInExistingPolicy}
                setEnabled={setClaimsMadeInExistingPolicy}
              />
            </GridItem>
          )}
          {!claimsMadeInExistingPolicy && selectedPrevPolicyType.value !== 'TP' && (
            <GridItem marginY='2' marginX='6'>
              <Tooltip
                hasArrow
                bg='secondary.500'
                label={
                  <Flex direction='column'>
                    <Text>
                      <strong>NCB</strong> or <strong>No Claim Bonus</strong> is a discount given on
                      own damage premium to the insured when renewing the policy after a claim-free
                      year. It is an incentive for driving carefully and avoiding accidents. The NCB
                      discount ranges from 20%-50%. You can easily find the no claim bonus discount
                      in your expiry/ previous year policy.
                    </Text>
                    <Text>
                      <i>Please refer to the below illustration for NCB calculation:</i>
                    </Text>
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>
                        <strong>All types of vehicles</strong>
                      </Text>
                      <Text>
                        <strong>% of Discount on OD Premium</strong>
                      </Text>
                    </Flex>
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>No Claims in the last policy year</Text>
                      <Text>20%</Text>
                    </Flex>
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>No Claims since 2 consecutive positive years.</Text>
                      <Text>25%</Text>
                    </Flex>
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>No Claims since 3 consecutive positive years.</Text>
                      <Text>35%</Text>
                    </Flex>
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>No Claims since 4 consecutive positive years.</Text>
                      <Text>45%</Text>
                    </Flex>
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>No Claims since 5 consecutive positive years.</Text>
                      <Text>50%</Text>
                    </Flex>
                  </Flex>
                }
              >
                <FormLabel>
                  Existing No Claim Bonus*
                  <InfoOutlineIcon />
                </FormLabel>
              </Tooltip>
              <Select
                selectedOptionColor='primary'
                menuPosition='fixed'
                placeholder='Select NCB'
                {...register('ncb', { required: true })}
                value={selectedNcb}
                onChange={(item) => {
                  setSelectedNcb(item)
                  setValue('ncb', item?.value ?? '')
                  clearErrors('ncb')
                }}
                options={getExistingNoClaimBonusArr().map((num) => {
                  return {
                    label: `${num}%`,
                    value: num.toString(),
                  }
                })}
              />
              {formState.errors.ncb && <ErrorText text={'NCB is required'} />}
            </GridItem>
          )}
          {vehicleType === 'is_gcv' && (
            <GridItem marginY='2' marginX='6'>
              <FormLabel>Permit Type*</FormLabel>
              <Select
                selectedOptionColor='primary'
                menuPosition='fixed'
                placeholder='Select Permit Type'
                {...register('permit_type', { required: true })}
                value={selectedPermitType}
                onChange={(item) => {
                  setSelectedPermitType({
                    label: item?.label ?? '',
                    value: item?.value ?? '',
                  })
                  setValue('permit_type', {
                    code: item?.value ?? '',
                    name: item?.label ?? '',
                  })
                  clearErrors('permit_type')
                }}
                options={[
                  // { name: 'Private Carrier', code: 'private' },
                  { name: 'Public Carrier', code: 'public' },
                ].map((item) => {
                  return {
                    label: item.name,
                    value: item.code,
                  }
                })}
              />
              {formState.errors.permit_type &&
                formState.errors.permit_type?.type === 'required' && (
                  <ErrorText text='Permit type is required' />
                )}
            </GridItem>
          )}
        </>
      )}
    </Grid>
  )
}

export { ODPolicyDetailsForm }
