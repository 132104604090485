// import {
//   Box,
//   Button,
//   Flex,
//   Input,
//   Select,
//   Spinner,
//   Table,
//   TableContainer,
//   Tbody,
//   Td,
//   Text,
//   Tfoot,
//   Th,
//   Thead,
//   Tr,
// } from '@chakra-ui/react'
// import ReactPaginate from 'react-paginate'
// import { DashboardHeading, ElevatedButton, Pagination, TextInput } from 'Core'
// import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons'
// import { Header } from 'Components'
// // import { useMotorSalesBankQuery } from 'Services/API/motor.api'
// import { IMotorSalesBankParams } from 'Models/RequestModels'
// import { ChangeEvent, useEffect, useState } from 'react'
// import { differenceInDays, format } from 'date-fns'
// import { IMotorSalesBankItem } from 'Models/ResponseModels/motor'
// import { copyToClipboard } from 'Services/general'
// import { useLifeSalesBankQuery } from 'Services/API/life.api'
// import axios from 'axios'
// import { API_ENDPOINTS, BASE_URL, EQUITAS_URL } from 'Constants'
// import { getDefaultHeaders } from 'Services/baseQuery'
// import { useAppSelector } from 'app/hooks'
// import { ToastService } from 'Services/toast.service'
// import { ILifeSalesBankParams } from 'Models/RequestModels/Life'

// /**
//  * @payment_status === 'pending ---> complete payment, copy payment link
//  * @payment_status === 'failed' ---> retry payment, copy payment link
//  * else ---> download pdf + (@allow_change for approve and reject cases)
//  */
// const getMotorSalesActionItems = (rowItem: IMotorSalesBankItem) => {
//   if (rowItem.payment_status === 'pending') {
//     if (
//       new Date().getMonth() !== new Date(rowItem.creation_date ?? '').getMonth() ||
//       new Date().getFullYear() !== new Date(rowItem.creation_date ?? '').getFullYear() ||
//       new Date().getDate() - new Date(rowItem.creation_date ?? '').getDate() >= 1
//     ) {
//       return <Text>Payment Link Expired</Text>
//     } else if (rowItem.payment_url) {
//       return (
//         <Flex direction='column'>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               window.open(rowItem.payment_url, '_blank')
//             }}
//           >
//             Complete Payment
//           </Button>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
//             }}
//           >
//             Copy Payment Link
//           </Button>
//         </Flex>
//       )
//     } else {
//       return <Text>-</Text>
//     }
//   } else if (rowItem.payment_status === 'failed') {
//     if (
//       new Date().getMonth() !== new Date(rowItem.creation_date ?? '').getMonth() ||
//       new Date().getFullYear() !== new Date(rowItem.creation_date ?? '').getFullYear() ||
//       new Date().getDate() - new Date(rowItem.creation_date ?? '').getDate() >= 1
//     ) {
//       return <Text>Payment Link Expired</Text>
//     } else if (rowItem.payment_url) {
//       return (
//         <Flex direction='column'>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               window.open(rowItem.payment_url, '_blank')
//             }}
//           >
//             Complete Payment
//           </Button>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
//             }}
//           >
//             Copy Payment Link
//           </Button>
//         </Flex>
//       )
//     } else {
//       return <Text>-</Text>
//     }
//   } else if (rowItem.policy_pdf) {
//     return (
//       <Button
//         size='xs'
//         marginY='0.5rem'
//         isDisabled={true}
//         onClick={() => {
//           window.open(rowItem.policy_pdf, '_blank')
//         }}
//         minWidth='4rem'
//       >
//         Download PDF
//       </Button>
//     )
//   }
//   return <Text>-</Text>
// }

// const LifeSalesReport = () => {
//   const { authtoken } = useAppSelector((state) => state.user.authenticatedUser)

//   const [queryParams, setQueryParams] = useState<ILifeSalesBankParams>({
//     limit: 10,
//     offset: 1,
//   })

//   const [totalPages, setTotalPages] = useState(1)

//   const { data: reportData, isFetching: isReportLoading } = useLifeSalesBankQuery(queryParams, {
//     refetchOnMountOrArgChange: true,
//   })

//   useEffect(() => {
//     const pages = Math.ceil((reportData?.count ?? 0) / parseInt(queryParams.limit.toString()))
//     setTotalPages(pages)
//   }, [reportData])

//   const filterChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const timeoutId = setTimeout(() => {
//       const allParams = {
//         ...queryParams,
//         [e.target.name]: e.target.value,
//       }
//       const allParamsArray = Object.entries(allParams)
//       const filteredParams = allParamsArray.filter(([key, value]) => Boolean(value))
//       const newParams = Object.fromEntries(filteredParams)
//       setQueryParams((prev) => ({
//         ...newParams,
//         limit: prev.limit,
//         offset: prev.offset,
//       }))
//     }, 900)
//     return () => clearTimeout(timeoutId)
//   }

//   const [salesDownloadLoading, setSalesDownloadLoading] = useState(false)

//   return (
//     <>
//       <Box position='sticky' top='0' zIndex='999'>
//         <Header />
//         <DashboardHeading title='Life Sales Report' />
//         <Flex
//           paddingX='1rem'
//           paddingY='0.5rem'
//           border='1px'
//           alignItems='center'
//           justifyContent='space-between'
//           bgColor='#fff'
//         >
//           <Flex alignItems='center'>
//             <Text fontWeight='bold' whiteSpace='nowrap' fontSize='md'>
//               Items Per Page:
//             </Text>
//             <Select
//               value={queryParams.limit}
//               onChange={(e) => {
//                 setQueryParams((prev) => ({
//                   ...prev,
//                   limit: parseInt(e.target.value),
//                   offset: 1,
//                 }))
//               }}
//               marginX='4'
//             >
//               <option value={10}>10</option>
//               <option value={20}>20</option>
//               <option value={25}>25</option>
//               <option value={50}>50</option>
//             </Select>
//           </Flex>
//           <Button
//             isDisabled={salesDownloadLoading}
//             isLoading={salesDownloadLoading}
//             onClick={async () => {
//               setSalesDownloadLoading(true)
//               try {
//                 const resp = await axios({
//                   baseURL: `${EQUITAS_URL + API_ENDPOINTS.LIFE_SALES_REPORT}`,
//                   headers: getDefaultHeaders(authtoken),
//                   params: {
//                     ...queryParams,
//                     download: true,
//                   },
//                 })
//                 const csvData = new Blob([resp.data], { type: 'text/csv' })
//                 const anchor = document.createElement('a')
//                 anchor.href = URL.createObjectURL(csvData)
//                 anchor.target = '_blank'
//                 anchor.download = 'sales_report.csv'
//                 anchor.click()
//               } catch (e) {
//                 ToastService.showErrorToast({
//                   title: 'Something went wrong!',
//                 })
//               } finally {
//                 setSalesDownloadLoading(false)
//               }
//             }}
//             leftIcon={<DownloadIcon />}
//             size='sm'
//           >
//             Download Report
//           </Button>
//         </Flex>
//       </Box>
//       <TableContainer border='1px' rounded='lg' margin='2'>
//         <Table size='md' variant='simple' colorScheme='appGrey'>
//           <Thead boxShadow='onlyBottom'>
//             <Tr>
//               <Th>UCIC ID</Th>
//               <Th>Application Number</Th>
//               <Th>Branch Code</Th>
//               <Th>Branch Name</Th>
//               <Th>Insurer</Th>
//               <Th>Plan Name</Th>
//               <Th>Proposer ID</Th>
//               <Th>Proposer Name</Th>
//               <Th>Quote ID</Th>
//               <Th>Premium</Th>
//               <Th>Sum Assured</Th>
//               <Th>Lead ID</Th>
//               <Th>SP ID</Th>
//               <Th>SP Name</Th>
//               {/* <Th>Order Id</Th> */}
//               {/* <Th>Transaction Id</Th>
//               <Th>Booking Date</Th>
//               <Th>Policy Number</Th>
//               <Th>Payment Status</Th>
//               <Th>Policy Name</Th>
//               <Th>Product Type</Th>
//               <Th>Insured Name</Th>
//               <Th>Gender</Th>
//               <Th>DOB</Th>
//               <Th isNumeric>Mobile</Th>
//               <Th>Email</Th>
//               <Th>Pincode</Th> */}
//               {/* <Th>Address</Th> */}
//               {/* <Th>Nominee Name</Th>
//                 <Th>Relationship</Th> */}
//             </Tr>
//           </Thead>
//           <Tbody>
//             <Tr>
//               <Td></Td>
//               <Td>
//                 {/* <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='vehicle_number'
//                 /> */}
//               </Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               {/* <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='transaction_id'
//                 />
//               </Td>
//               <Td></Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='policy_number'
//                 />
//               </Td>
//               <Td>
//                 <Select variant='filled' name='payment_status' onChange={filterChangeHandler}>
//                   <option value=''>Select</option>
//                   <option value='pending'>Pending</option>
//                   <option value='completed'>Completed</option>
//                   <option value='failed'>Failed</option>
//                 </Select>
//               </Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='insurance_name'
//                 />
//               </Td>
//               <Td>
//                 <Select variant='filled' name='product_type' onChange={filterChangeHandler}>
//                   <option value=''>Select</option>
//                   <option value='FOUR-WHEELER'>Four Wheeler</option>
//                   <option value='TWO-WHEELER'>Two Wheeler</option>
//                   <option value='GOODS CARRYING VEHICLE'>GCV</option>
//                   <option value='PASSENGER CARRYING VEHICLE'>PCV</option>
//                 </Select>
//               </Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td>
//               <Td></Td> */}
//               {/* <Td></Td> */}
//               {/* <Td></Td>
//                 <Td></Td> */}
//             </Tr>
//             {reportData && reportData.result?.length === 0 && (
//               <Tr margin='0 auto' width='100%' display='block'>
//                 <Text as={'td'} p='5' fontSize='4xl' color='#939393' align='center'>
//                   No Data Found
//                 </Text>
//               </Tr>
//             )}
//             {reportData && reportData?.count > 0
//               ? reportData?.result?.map((item, idx) => {
//                   return (
//                     <Tr bgColor={idx % 2 === 0 ? 'appGrey.100' : 'white'} key={item.id}>
//                       <Td>{item.ucic_id ? item.ucic_id : '-'}</Td>
//                       <Td>
//                         <Text>{item.application_number ? item.application_number : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.branch_code ? item.branch_code : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.branch_name ? item.branch_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.insurer ? item.insurer.toUpperCase() : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.plan_name ? item.plan_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.proposer_id ? item.proposer_id : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.proposer_name ? item.proposer_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.id ? item.id : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item.premium
//                             ? `₹ ${parseInt(item.premium).toLocaleString('en-IN')}`
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item.sum_assured
//                             ? `₹ ${parseInt(item.sum_assured).toLocaleString('en-IN')}`
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.lead_id ? item.lead_id : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.sp_id ? item.sp_id : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.sp_name ? item.sp_name : '-'}</Text>
//                       </Td>
//                       {/* <Td>
//                         <Text>{item.transaction_id ?? '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item?.creation_date
//                             ? format(new Date(item.creation_date), 'dd/MM/yyyy')
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.policy_number ? item.policy_number : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.payment_status ? item.payment_status : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.insurance_name ? item.insurance_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.product_type ? item.product_type : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.customer_name ? item.customer_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.gender ? item.gender : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.dob ? format(new Date(item.dob), 'dd/MM/yyyy') : '-'}</Text>
//                       </Td>
//                       <Td isNumeric>
//                         <Text>{item.mobile ? item.mobile : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.email ? item.email : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.pincode ? item.pincode : '-'}</Text>
//                       </Td> */}
//                       {/* <Td whiteSpace='break-spaces'>
//                           <Text>{item.address ? item.address : '-'}</Text>
//                         </Td> */}
//                       {/* <Td>
//                           <Text>TODO</Text>
//                         </Td>
//                         <Td>
//                           <Text>TODO</Text>
//                         </Td> */}
//                     </Tr>
//                   )
//                 })
//               : isReportLoading && (
//                   <Tr>
//                     <Spinner as='td' />
//                   </Tr>
//                 )}
//           </Tbody>
//           <Tfoot>
//             <Tr>
//               <Th>UCIC ID</Th>
//               <Th>Application Number</Th>
//               <Th>Branch Code</Th>
//               <Th>Branch Name</Th>
//               <Th>Insurer</Th>
//               <Th>Plan Name</Th>
//               <Th>Proposer ID</Th>
//               <Th>Proposer Name</Th>
//               <Th>Quote ID</Th>
//               <Th>Premium</Th>
//               <Th>Sum Assured</Th>
//               <Th>Lead ID</Th>
//               <Th>SP ID</Th>
//               <Th>SP Name</Th>
//               {/* <Th>Transaction Id</Th>
//               <Th>Booking Date</Th>
//               <Th>Policy Number</Th>
//               <Th>Payment Status</Th>
//               <Th>Policy Name</Th>
//               <Th>Product Type</Th>
//               <Th>Insured Name</Th>
//               <Th>Gender</Th>
//               <Th>DOB</Th>
//               <Th isNumeric>Mobile</Th>
//               <Th>Email</Th>
//               <Th>Pincode</Th> */}
//               {/* <Th>Address</Th> */}
//               {/* <Th>Nominee Name</Th>
//                 <Th>Relationship</Th> */}
//             </Tr>
//           </Tfoot>
//         </Table>
//       </TableContainer>
//       <Flex justifyContent='center'>
//         <ReactPaginate
//           breakLabel='...'
//           nextLabel={<ChevronRightIcon transform='scale(2)' />}
//           onPageChange={(selectedPage) => {
//             setQueryParams((prev) => ({
//               ...prev,
//               page_number: selectedPage.selected + 1,
//             }))
//           }}
//           pageCount={totalPages}
//           pageRangeDisplayed={2}
//           marginPagesDisplayed={2}
//           previousLabel={<ChevronLeftIcon transform='scale(2)' />}
//           forcePage={queryParams.offset && queryParams.offset - 1}
//           className='paginate'
//           pageClassName='page-item'
//           nextLinkClassName='paginate-button'
//           previousLinkClassName='paginate-button'
//           activeClassName='active-page'
//         />
//       </Flex>
//     </>
//   )
// }

// export { LifeSalesReport }

import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import { DashboardHeading, ElevatedButton, Pagination, TextInput } from 'Core'
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons'
import { Header } from 'Components'
// import { useMotorSalesBankQuery } from 'Services/API/motor.api'
import { IMotorSalesBankParams } from 'Models/RequestModels'
import { ChangeEvent, useEffect, useState } from 'react'
import { differenceInDays, format } from 'date-fns'
import { IMotorSalesBankItem } from 'Models/ResponseModels/motor'
import { copyToClipboard } from 'Services/general'
import { useLifeSalesBankQuery } from 'Services/API/life.api'
import axios from 'axios'
import { API_ENDPOINTS, BASE_URL, EQUITAS_URL } from 'Constants'
import { getDefaultHeaders } from 'Services/baseQuery'
import { useAppSelector } from 'app/hooks'
import { ToastService } from 'Services/toast.service'
import { ILifeSalesBankParams } from 'Models/RequestModels/Life'

/**
 * @payment_status === 'pending ---> complete payment, copy payment link
 * @payment_status === 'failed' ---> retry payment, copy payment link
 * else ---> download pdf + (@allow_change for approve and reject cases)
 */
const getMotorSalesActionItems = (rowItem: IMotorSalesBankItem) => {
  if (rowItem.payment_status === 'pending') {
    if (
      new Date().getMonth() !== new Date(rowItem.creation_date ?? '').getMonth() ||
      new Date().getFullYear() !== new Date(rowItem.creation_date ?? '').getFullYear() ||
      new Date().getDate() - new Date(rowItem.creation_date ?? '').getDate() >= 1
    ) {
      return <Text>Payment Link Expired</Text>
    } else if (rowItem.payment_url) {
      return (
        <Flex direction='column'>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              window.open(rowItem.payment_url, '_blank')
            }}
          >
            Complete Payment
          </Button>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
            }}
          >
            Copy Payment Link
          </Button>
        </Flex>
      )
    } else {
      return <Text>-</Text>
    }
  } else if (rowItem.payment_status === 'failed') {
    if (
      new Date().getMonth() !== new Date(rowItem.creation_date ?? '').getMonth() ||
      new Date().getFullYear() !== new Date(rowItem.creation_date ?? '').getFullYear() ||
      new Date().getDate() - new Date(rowItem.creation_date ?? '').getDate() >= 1
    ) {
      return <Text>Payment Link Expired</Text>
    } else if (rowItem.payment_url) {
      return (
        <Flex direction='column'>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              window.open(rowItem.payment_url, '_blank')
            }}
          >
            Complete Payment
          </Button>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
            }}
          >
            Copy Payment Link
          </Button>
        </Flex>
      )
    } else {
      return <Text>-</Text>
    }
  } else if (rowItem.policy_pdf) {
    return (
      <Button
        size='xs'
        marginY='0.5rem'
        isDisabled={true}
        onClick={() => {
          window.open(rowItem.policy_pdf, '_blank')
        }}
        minWidth='4rem'
      >
        Download PDF
      </Button>
    )
  }
  return <Text>-</Text>
}

const LifeSalesReport = () => {
  const { authtoken } = useAppSelector((state) => state.user.authenticatedUser)

  const [queryParams, setQueryParams] = useState<ILifeSalesBankParams>({
    limit: 10,
    offset: 1,
  })

  const [totalPages, setTotalPages] = useState(1)

  const { data: reportData, isFetching: isReportLoading } = useLifeSalesBankQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    const pages = Math.ceil((reportData?.count ?? 0) / parseInt(queryParams.limit.toString()))
    setTotalPages(pages)
  }, [reportData])

  const filterChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const timeoutId = setTimeout(() => {
      const allParams = {
        ...queryParams,
        [e.target.name]: e.target.value,
      }
      const allParamsArray = Object.entries(allParams)
      const filteredParams = allParamsArray.filter(([key, value]) => Boolean(value))
      const newParams = Object.fromEntries(filteredParams)
      setQueryParams((prev) => ({
        ...newParams,
        limit: prev.limit,
        offset: prev.offset,
      }))
    }, 900)
    return () => clearTimeout(timeoutId)
  }

  const [salesDownloadLoading, setSalesDownloadLoading] = useState(false)

  return (
    <>
      <Box position='sticky' top='0' zIndex='999'>
        <Header />
        <DashboardHeading title='Life Sales Report' />
        <Flex
          paddingX='1rem'
          paddingY='0.5rem'
          border='1px'
          alignItems='center'
          justifyContent='space-between'
          bgColor='#fff'
        >
          <Flex alignItems='center'>
            <Text fontWeight='bold' whiteSpace='nowrap' fontSize='md'>
              Items Per Page:
            </Text>
            <Select
              value={queryParams.limit}
              onChange={(e) => {
                setQueryParams((prev) => ({
                  ...prev,
                  limit: parseInt(e.target.value),
                  offset: 1,
                }))
              }}
              marginX='4'
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Select>
          </Flex>
          <Button
            isDisabled={salesDownloadLoading}
            isLoading={salesDownloadLoading}
            onClick={async () => {
              setSalesDownloadLoading(true)
              try {
                const resp = await axios({
                  baseURL: `${EQUITAS_URL + API_ENDPOINTS.LIFE_SALES_REPORT}`,
                  headers: getDefaultHeaders(authtoken),
                  params: {
                    ...queryParams,
                    download: true,
                  },
                })
                const csvData = new Blob([resp.data], { type: 'text/csv' })
                const anchor = document.createElement('a')
                anchor.href = URL.createObjectURL(csvData)
                anchor.target = '_blank'
                anchor.download = 'sales_report.csv'
                anchor.click()
              } catch (e) {
                ToastService.showErrorToast({
                  title: 'Something went wrong!',
                })
              } finally {
                setSalesDownloadLoading(false)
              }
            }}
            leftIcon={<DownloadIcon />}
            size='sm'
          >
            Download Report
          </Button>
        </Flex>
      </Box>
      <TableContainer border='1px' rounded='lg' margin='2'>
        <Table size='md' variant='simple' colorScheme='appGrey'>
          <Thead boxShadow='onlyBottom'>
            <Tr>
              <Th>UCIC ID</Th>
              <Th>Application Number</Th>
              <Th>Login Date</Th>
              <Th>Proposal Date</Th>
              <Th>Branch Code</Th>
              <Th>Branch Name</Th>
              <Th>Insurer</Th>
              <Th>Plan Name</Th>
              <Th>Proposer ID</Th>
              <Th>Proposer Name</Th>
              <Th>Quote ID</Th>
              <Th>Premium</Th>
              <Th>Sum Assured</Th>
              <Th>Lead ID</Th>
              <Th>SP ID</Th>
              <Th>SP Name</Th>
              {/* <Th>Order Id</Th> */}
              {/* <Th>Transaction Id</Th>
              <Th>Booking Date</Th>
              <Th>Policy Number</Th>
              <Th>Payment Status</Th>
              <Th>Policy Name</Th>
              <Th>Product Type</Th>
              <Th>Insured Name</Th>
              <Th>Gender</Th>
              <Th>DOB</Th>
              <Th isNumeric>Mobile</Th>
              <Th>Email</Th>
              <Th>Pincode</Th> */}
              {/* <Th>Address</Th> */}
              {/* <Th>Nominee Name</Th>
                <Th>Relationship</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td></Td>
              <Td>
                {/* <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='vehicle_number'
                /> */}
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              {/* <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='transaction_id'
                />
              </Td>
              <Td></Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='policy_number'
                />
              </Td>
              <Td>
                <Select variant='filled' name='payment_status' onChange={filterChangeHandler}>
                  <option value=''>Select</option>
                  <option value='pending'>Pending</option>
                  <option value='completed'>Completed</option>
                  <option value='failed'>Failed</option>
                </Select>
              </Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='insurance_name'
                />
              </Td>
              <Td>
                <Select variant='filled' name='product_type' onChange={filterChangeHandler}>
                  <option value=''>Select</option>
                  <option value='FOUR-WHEELER'>Four Wheeler</option>
                  <option value='TWO-WHEELER'>Two Wheeler</option>
                  <option value='GOODS CARRYING VEHICLE'>GCV</option>
                  <option value='PASSENGER CARRYING VEHICLE'>PCV</option>
                </Select>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td> */}
              {/* <Td></Td> */}
              {/* <Td></Td>
                <Td></Td> */}
            </Tr>
            {reportData && reportData.result?.length === 0 && (
              <Tr margin='0 auto' width='100%' display='block'>
                <Text as={'td'} p='5' fontSize='4xl' color='#939393' align='center'>
                  No Data Found
                </Text>
              </Tr>
            )}
            {reportData && reportData?.count > 0
              ? reportData?.result?.map((item, idx) => {
                  return (
                    <Tr bgColor={idx % 2 === 0 ? 'appGrey.100' : 'white'} key={item.id}>
                      <Td>{item.ucic_id ? item.ucic_id : '-'}</Td>
                      <Td>
                        <Text>{item.application_number ? item.application_number : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.created_at ? item.created_at : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.proposal_date ? item.proposal_date : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.branch_code ? item.branch_code : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.branch_name ? item.branch_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.insurer ? item.insurer.toUpperCase() : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.plan_name ? item.plan_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.proposer_id ? item.proposer_id : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.proposer_name ? item.proposer_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.id ? item.id : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>
                          {item.premium
                            ? `₹ ${parseInt(item.premium).toLocaleString('en-IN')}`
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>
                          {item.sum_assured
                            ? `₹ ${parseInt(item.sum_assured).toLocaleString('en-IN')}`
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>{item.lead_id ? item.lead_id : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.sp_id ? item.sp_id : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.sp_name ? item.sp_name : '-'}</Text>
                      </Td>
                      {/* <Td>
                        <Text>{item.transaction_id ?? '-'}</Text>
                      </Td>
                      <Td>
                        <Text>
                          {item?.creation_date
                            ? format(new Date(item.creation_date), 'dd/MM/yyyy')
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>{item.policy_number ? item.policy_number : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.payment_status ? item.payment_status : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.insurance_name ? item.insurance_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.product_type ? item.product_type : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.customer_name ? item.customer_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.gender ? item.gender : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.dob ? format(new Date(item.dob), 'dd/MM/yyyy') : '-'}</Text>
                      </Td>
                      <Td isNumeric>
                        <Text>{item.mobile ? item.mobile : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.email ? item.email : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.pincode ? item.pincode : '-'}</Text>
                      </Td> */}
                      {/* <Td whiteSpace='break-spaces'>
                          <Text>{item.address ? item.address : '-'}</Text>
                        </Td> */}
                      {/* <Td>
                          <Text>TODO</Text>
                        </Td>
                        <Td>
                          <Text>TODO</Text>
                        </Td> */}
                    </Tr>
                  )
                })
              : isReportLoading && (
                  <Tr>
                    <Spinner as='td' />
                  </Tr>
                )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>UCIC ID</Th>
              <Th>Application Number</Th>
              <Th>Login Date</Th>
              <Th>Proposal Date</Th>
              <Th>Branch Code</Th>
              <Th>Branch Name</Th>
              <Th>Insurer</Th>
              <Th>Plan Name</Th>
              <Th>Proposer ID</Th>
              <Th>Proposer Name</Th>
              <Th>Quote ID</Th>
              <Th>Premium</Th>
              <Th>Sum Assured</Th>
              <Th>Lead ID</Th>
              <Th>SP ID</Th>
              <Th>SP Name</Th>
              {/* <Th>Transaction Id</Th>
              <Th>Booking Date</Th>
              <Th>Policy Number</Th>
              <Th>Payment Status</Th>
              <Th>Policy Name</Th>
              <Th>Product Type</Th>
              <Th>Insured Name</Th>
              <Th>Gender</Th>
              <Th>DOB</Th>
              <Th isNumeric>Mobile</Th>
              <Th>Email</Th>
              <Th>Pincode</Th> */}
              {/* <Th>Address</Th> */}
              {/* <Th>Nominee Name</Th>
                <Th>Relationship</Th> */}
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <Flex justifyContent='center'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<ChevronRightIcon transform='scale(2)' />}
          onPageChange={(selectedPage) => {
            setQueryParams((prev) => ({
              ...prev,
              page_number: selectedPage.selected + 1,
            }))
          }}
          pageCount={totalPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          previousLabel={<ChevronLeftIcon transform='scale(2)' />}
          forcePage={queryParams.offset && queryParams.offset - 1}
          className='paginate'
          pageClassName='page-item'
          nextLinkClassName='paginate-button'
          previousLinkClassName='paginate-button'
          activeClassName='active-page'
        />
      </Flex>
    </>
  )
}

export { LifeSalesReport }
