import { BoxProps, FormControl } from '@chakra-ui/react'
import { BbSwitch, ElementWithLabel } from 'Core'
// import { INestedQuestionSet } from 'Models/BOB/ResponseModels/Life/QuestionSet'
import { ISwitchOption } from 'Models/Common'
import { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { getDropdownArrayFromRecord } from 'Services/general'
import RenderChild from './RenderChild'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
// import { isDisabled } from '@testing-library/user-event/dist/types/utils'

const booleanSwitchOptions: ISwitchOption[] = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
]

interface IProps extends BoxProps {
  isRoot: boolean
  parentData: { name: string; value: string | number }
  name: string
  label?: string
  isDisabled?: boolean
  childrenData?: Record<string, INestedQuestionSet[]>
  form: UseFormReturn<any, any>
  initialValues?: Record<string, string | number | boolean | string[]>
  defaultValue?: string
  options?: {
    [key: string]: string | number
  }
  disabledCodes?: string[]
}

const RenderSwitch = (props: IProps) => {
  const {
    name,
    childrenData,
    form,
    isRoot,
    options,
    isDisabled,
    parentData,
    label,
    initialValues,
    defaultValue,
    disabledCodes,
    ...rest
  } = props

  const [optionsArray, setOptionsArray] = useState(booleanSwitchOptions)

  // const [switchValue, setSwitchValue] = useState(booleanSwitchOptions[1])
  const [switchValue, setSwitchValue] = useState<ISwitchOption>()

  const handleChange = (newVal: ISwitchOption) => {
    form.setValue(name, newVal.value)
    setSwitchValue(newVal)
  }

  useEffect(() => {
    if (options && Object.keys(options).length > 0) {
      const newOptionsArray = getDropdownArrayFromRecord(options)
      setOptionsArray(newOptionsArray)
      // setSwitchValue(newOptionsArray[1])
      handleChange(newOptionsArray[1])
      return
    }
    handleChange(booleanSwitchOptions[1])
  }, [])

  useEffect(() => {
    if (initialValues && initialValues[name]) {
      const newValue: ISwitchOption | undefined = optionsArray.find(
        (item) => item.value === initialValues[name],
      )

      if (newValue) {
        handleChange(newValue)
      }
    }
  }, [initialValues, optionsArray])

  /**
   * This will set the initial value of dropdown as given in the `default` key if it exists in dropdown array
   */
  // TODO: Recheck if it is working or not
  useEffect(() => {
    if (defaultValue !== undefined) {
      const newValue: ISwitchOption | undefined = optionsArray.find(
        (item) => item.value === defaultValue.toString(),
      )
      if (newValue) {
        handleChange(newValue)
      }
    }
  }, [])

  return (
    <>
      <ElementWithLabel
        required
        marginY='0.7rem'
        height='fit-content'
        label={label || name}
        {...rest}
      >
        <BbSwitch
          isDisabled={
            isDisabled ||
            (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false)
          }
          {...form.register(name)}
          option1={optionsArray[0]}
          option2={optionsArray[1]}
          initialValue={switchValue}
          onToggle={handleChange}
        />
      </ElementWithLabel>

      {childrenData &&
        Object.keys(childrenData)?.length > 0 &&
        switchValue?.label &&
        childrenData[switchValue.label] &&
        childrenData[switchValue.label].map((item, index) => {
          return (
            <RenderChild
              key={index}
              childField={item}
              form={form}
              parentData={{
                name: name,
                value: switchValue.label,
              }}
              defaultValue={item.default}
              initialValues={initialValues}
            />
          )
        })}
    </>
  )
}

export { RenderSwitch }
