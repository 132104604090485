import {
  Box,
  Button,
  Flex,
  Text,
  Center,
  FormLabel,
  Checkbox,
  HStack,
  PinInput,
  PinInputField,
  GridItem,
  Grid,
  Heading,
  Image,
  Toast,
  Modal,
  ModalOverlay,
  ModalContent,
  Divider,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react'
import { DashboardHeading, ElevatedButton, ErrorText, TextInput } from 'Core'
import { useAppSelector, useDeviceType } from 'app/hooks'
import {
  customerConsentFormQuestionsMap,
  customerCancerConsentFormQuestionsMap,
  customerCancerConsentFormQuestionsDeclarationsMap,
} from 'Services/general'
import { useForm } from 'react-hook-form'
import {
  TCustomerConsentCancerDeclarationForm,
  TCustomerConsentCancerForm,
  TCustomerConsentForm,
  TCustomerConsentFormPMJJBY,
} from 'Pages/Common/ConsentForm/FormModels'
import { useEffect, useRef, useState } from 'react'
import { useSpTaggingSendOtpMutation } from 'Services/API/authentication.api'
import { ToastService } from 'Services/toast.service'

import {
  useConsentUploadMutation,
  useSubmitCustomerConsentFormMutation,
} from 'Services/API/motor.api'
import { exportAsImage } from 'Pages/Health/Consent/exportAsImage'
import { useParams } from 'react-router-dom'
import {
  useCallDMSAPIMutation,
  useConsentUploadSachetMutation,
  useDirectDebitMutation,
  useLazyGetConsentQuery,
  useLazyGetPaymentStatusQuery,
} from 'Services/API/sachet.api'
import { SachetPaymentSuccessDialog } from 'Core/SachetPaymentSuccess'
import { SachetPaymentFailureDialog } from 'Core/SachetPaymentFailure'
import { isObject } from 'lodash'
import { sachetsCoverageConditions, sachetsTerminationClauses } from 'Constants'
import { previousDay } from 'date-fns'
import SachetPaymentStatusModal from 'Core/Modal/SachetPaymentStatusModal'
import {
  useConsentSendSMSMutation,
  useSendEmailForOTPMutation,
  useVerifyOTPforConsentMutation,
} from 'Services/API/equitas.api'
// import { useLifeDMSDataPushMutation } from 'Services/API/life.api'

export interface directDebitResponseProps {
  amount: number
  pdf_url?: string
  close_url: string
  current_date: string
  insurer_name: string
  lead_number: string
  payment_method: string
  retry_url: string
  transaction_id: string
  coi_no?: string
}

export const formatCurr = (num: number) => {
  const formattedNumber = num.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
  })

  return formattedNumber
}
let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

export const LifeSachetForm = () => {
  const { isDesktop, isTablet } = useDeviceType()
  const { quoteId } = useParams()
  const [
    getPaymentStatus,
    {
      currentData: paymentData,
      isFetching: isFetchingPaymentStatus,
      isLoading: isLoadingPaymentStatus,
    },
  ] = useLazyGetPaymentStatusQuery()

  const [canDoPayment, SetCanDoPayment] = useState(true)
  const [coiNo, setCoiNo] = useState('')

  const { register, handleSubmit, formState, trigger } = useForm<TCustomerConsentForm>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })
  const {
    register: registerCancerDeclarations,
    handleSubmit: handleSubmitCancerDeclarations,
    formState: formStateCancerDeclarations,
    trigger: triggerCancerDeclarations,
    setValue: setValueCancerDeclarations,
    watch: watchCancerDeclarations,
  } = useForm<TCustomerConsentCancerDeclarationForm>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const {
    register: registerCancer,
    handleSubmit: handleSubmitCancer,
    formState: formStateCancer,
    trigger: triggerCancer,
    setValue: setValueCancer,
  } = useForm<TCustomerConsentCancerForm>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const {
    register: cancerRegister,
    handleSubmit: cancerHandleSubmit,
    formState: cancerFormState,
    trigger: cancerTrigger,
    setValue: cancerSetValue,
  } = useForm<TCustomerConsentForm>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })
  const [isOtpVerified, setIsOtpVerified] = useState(false)
  const customerProposerDetails = useAppSelector((state) => state.customerDetails)
  const applicantFormDetails = useAppSelector(
    (state) => state.sachetDataDetails.applicantFormDetails,
  )
  const { planQuoteId } = useAppSelector((state) => state.lifeQuotationDetails)

  const [sendOtp, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
    useConsentSendSMSMutation()

  const [verifyOtp, { isLoading: isVerifyOtpLoading, isError: isWrongOtp }] =
    useVerifyOTPforConsentMutation()

  const [sendOTPviaEmail] = useSendEmailForOTPMutation()

  const [otp, setOtp] = useState('')

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }
  const getPaymentStatusFunc = async () => {
    const resp: any = await getPaymentStatus({
      quote_id: quoteId,
    })

    // if (resp?.data?.status === 'Failed') {
    if (resp?.data?.status === 'Success') {
      setCoiNo(resp.data.coi_no)
      SetCanDoPayment(false)
    }
  }

  const [DDPaymentStatus, setDDPaymentStatus] = useState('pending')

  useEffect(() => {
    try {
      getPaymentStatusFunc()
    } catch (error) {
      ToastService.showErrorToast({
        title: 'Failed to get payment status',
        message: 'Failed to get payment status. Please try again later.',
      })
    }
  }, [])

  const [getconsent, { currentData: data, isFetching, isLoading }] = useLazyGetConsentQuery()

  const [foundConsent, setFoundConsent] = useState(false)
  const [isConsentApproved, setIsConsentApproved] = useState(false)

  const setAllCheckboxTrue = (val: boolean) => {
    setTCchecked(val)
    setStandingInstruction(true)
    setDeclarationOfGoodHealth(true)
  }
  type cancelDeclarationsType = 'q1' | 'q2' | 'q3' | 'q4' | 'q5' | 'payment_method'
  useEffect(() => {
    const getConsentfunc = async () => {
      try {
        const consentData = await getconsent({
          quote_id: quoteId!,
        }).unwrap()

        if (consentData && consentData?.status === 'APPROVED') {
          setIsConsentApproved(true)
          setDeclarationOfGoodHealth(true)
          setStandingInstruction(true)
          setStandingInstruction(true)
          setIsOtpVerified(true)
          setAllCheckboxTrue(true)
          setTCchecked(true)
        }

        // TODO:  Set the value of all checkboxes as true
        if (watchCancerDeclarations() && consentData && consentData?.status === 'APPROVED') {
          const obj = watchCancerDeclarations()
          Object.keys(obj).map((elem: any, index) => {
            if (elem) {
              setValueCancerDeclarations('q1', true)
            }
          })
        }

        setFoundConsent(true)
      } catch (error) {
        console.error('GET CONSENT DATA ERROR: ', error)
      }
    }

    getConsentfunc()
  }, [])

  useEffect(() => {
    if (data?.status === 'APPROVED') {
      setConsentSubmitted(true)
    }
  }, [data])

  const [directDebitResponse, setDirectDebitResponse] = useState<directDebitResponseProps>({
    amount: 0,
    pdf_url: '',
    close_url: '',
    current_date: '',
    insurer_name: '',
    lead_number: '',
    payment_method: '',
    retry_url: '',
    transaction_id: '',
  })
  const [gotDirectDebitResponse, setGotDirectDebitResponse] = useState(false)

  const [consentUpload] = useConsentUploadMutation()

  const exportRef = useRef<HTMLDivElement>(null)

  const [submitConsentForm] = useConsentUploadSachetMutation()
  const [directDebitFunc, { isLoading: isDirectDebitLoading }] = useDirectDebitMutation()

  const [consentSubmitted, setConsentSubmitted] = useState(false)
  const [transactionNum, setTransactionNum] = useState('')
  const [callDMSAPI, { isLoading: isDMSAPILoading }] = useCallDMSAPIMutation()

  const onSubmit: any = async (data1: any) => {
    setAcceptClicked(true)
    try {
      setConsentSubmitted(true)

      try {
        const image = await exportAsImage(exportRef.current, 'test')
        const dmsRes: any = await callDMSAPI({
          quote_id: quoteId,
          consent_type: 'sachet_consent',
          b64_image: image,
        })
      } catch (e) {
        console.log(e)
      }

      const response: any = await directDebitFunc({
        quote_id: quoteId,
        amount: data ? parseFloat(data.ui_data.selectedProductArrObjData?.premium) : 0,
        email: (data && data?.ui_data?.email) || '',
        phone_number: (data && data?.ui_data?.phoneNumber) || '',
      })

      setDirectDebitResponse(response?.data?.data)
      setDDPaymentStatus(response?.data?.message)
      setGotDirectDebitResponse(true)

      if (response?.data?.message === 'Payment already completed') {
        setPaymentStatus('Success')
        ToastService.showSuccessTaost({
          title: 'Payment already completed!',
        })
        SetCanDoPayment(false)
        return
      }

      setTransactionNum(response ? response?.data?.transaction_number : '-')

      ToastService.showSuccessTaost({
        title: 'Details Submitted!',
      })

      if (response?.data?.transaction_number?.length > 0) {
        setPaymentStatus('Success')
        ToastService.showSuccessTaost({
          title: 'Payment Successfull!',
        })
      } else if (paymentStatus !== 'PENDING....') {
        setPaymentStatus('Failed')
        ToastService.showErrorToast({
          title: 'Payment Failed!',
        })
      }
      setAcceptClicked(false)
    } catch (e: any) {
      ToastService.showErrorToast({
        title: 'Something went wrong! ------',
        message: e?.message ?? '',
      })
      setAcceptClicked(false)
    }
  }

  let today: any = new Date()
  today = today.toISOString().split('T')[0]

  const [declarationOfGoodHealth, setDeclarationOfGoodHealth] = useState(false)
  const [standingInstruction, setStandingInstruction] = useState(false)

  function getMonthName(monthNumber: number) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return months[monthNumber]
  }

  // Function to format the date
  function formatDate(date: Date) {
    const day = date.getDate()
    const monthName = getMonthName(date.getMonth())
    return `${day} ${monthName}`
  }

  // Get the current date
  const currentDate = new Date()

  // Get the date one week from now
  const nextWeekDate = new Date()
  nextWeekDate.setDate(currentDate.getDate() + 7)

  // Format the dates
  const formattedCurrentDate = formatDate(currentDate)
  const formattedNextWeekDate = formatDate(nextWeekDate)

  const customerEmail = data && data?.consent_data?.applicant?.email
  const customerPhone = data && data?.ui_data?.phoneNumber

  interface IPaymentStatus {
    id?: number
    transaction_number: string
    member_id?: string
    sp_id?: number
    insurance: string
    transaction_date: string
    amount: string
    status: string
    mode_of_payment: string
    quotation?: number
    masterPolicyNumber?: string
  }

  const [paymentStatus, setPaymentStatus] = useState('PENDING....')
  const [paymentStatusData, setPaymentStatusData] = useState<IPaymentStatus>({
    id: 0,
    transaction_number: '',
    insurance: '',
    transaction_date: new Date().toString(),
    amount: '',
    status: '',
    mode_of_payment: '',
    masterPolicyNumber: '',
  })

  const [cancerCheckBox, setCancerCheckBox] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  })

  const [TCchecked, setTCchecked] = useState(true)
  const [acceptClicked, setAcceptClicked] = useState(false)

  useEffect(() => {
    setPaymentStatusData({
      transaction_number: transactionNum || '-',
      insurance: (data && data.consent_data?.product?.name) || '',
      transaction_date: new Date().toDateString(),
      amount: (data && parseFloat(data.ui_data?.selectedProductArrObjData?.premium)) || '',
      status: 'Success',
      mode_of_payment: 'Direct Debit',
      masterPolicyNumber: (data && data.ui_data?.masterPolicyNumber) || '',
    })
  }, [paymentStatus])

  const productCode = data && data.ui_data?.selectedProductArrObjData?.code

  const extendedAddressDetails = [
    { label: 'City', value: (data && data.ui_data?.sameAddress?.city) || '-' },
    { label: 'District', value: (data && data.ui_data?.sameAddress?.district) || '-' },
    { label: 'State', value: (data && data.ui_data?.sameAddress?.state) || '-' },
    { label: 'Pincode', value: (data && data.ui_data?.sameAddress?.pincode) || '-' },
  ]

  return (
    <>
      {!canDoPayment ? (
        <DashboardHeading title='Payment Already Done!' />
      ) : (
        <Box p='4' ref={exportRef}>
          {!gotDirectDebitResponse && !foundConsent && (
            <Center>
              <Flex justifyContent='center' alignItems='center' direction='column' gap='2'>
                <Text fontSize='2.5rem' fontWeight='bold'>
                  Consent Not Found for the Given Quote ID
                </Text>
                <Text fontSize='1.5rem' textColor='gray.800'>
                  Please contact the Insurer
                </Text>
              </Flex>
            </Center>
          )}
          {/* {!directDebitResponse && foundConsent && !canDoPayment && (
          <>
            <Center my='3'>
              <Text
                fontWeight='bold'
                fontSize='1.3rem'
                textColor='green.400'
                // border='1px solid black'
                // px='7'
                // py='3'
                borderRadius='10'
              >
                Payment Already Done
              </Text>
            </Center>
          </>
        )} */}
          {!gotDirectDebitResponse && foundConsent && (
            <>
              {/* {paymentStatus === 'Success' && (
                  <Modal
                    isCentered
                    isOpen={paymentStatus === 'Success'}
                    onClose={() => console.log('On Close')}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <SachetPaymentSuccessDialog
                        paymentStatusData={paymentStatusData}
                        backHome={false}
                      />
                    </ModalContent>
                  </Modal>
                )} */}

              {data && data.ui_data.selectedProductArrObjData && (
                <>
                  <DashboardHeading title='Customer Consent Form' />
                  <Flex
                    mt='4'
                    justifyContent='center'
                    alignItems='center'
                    direction='column'
                    gap='3'
                  >
                    <Image
                      h='auto'
                      w='40'
                      borderRadius='10px'
                      src={data.ui_data.selectedProductArrObjData?.insurer_logo}
                    />
                    <Text fontWeight='bold' textDecoration='underline' p='2'>
                      {data.ui_data.selectedProductArrObjData?.name}
                    </Text>
                  </Flex>

                  <Flex
                    mt='2'
                    gridTemplateColumns={isDesktop ? '1fr 1fr' : isTablet ? '1fr 1fr' : '1fr'}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Flex w='70%' justifyContent='space-between' alignItems='center'>
                      <Flex justifyContent='center' alignItems='center' gap='2'>
                        <Text fontWeight='bold'>Premium:</Text>
                        {formatCurr(parseFloat(data.ui_data.selectedProductArrObjData?.premium))}
                      </Flex>
                      <Flex justifyContent='center' alignItems='center' gap='2'>
                        <Text fontWeight='bold'>Sum Assured:</Text>
                        {formatCurr(
                          parseFloat(data.ui_data.selectedProductArrObjData?.sum_assured),
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}

              {data && data.ui_data.selectedProductArrObjData && (
                <Center>
                  <Flex></Flex>
                </Center>
              )}

              {data && data.ui_data.customerAccountDetails && (
                <Box mt='4'>
                  <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                    Account Details
                  </Text>
                  <Grid
                    gridTemplateColumns={
                      isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                    }
                  >
                    {data.ui_data.customerAccountDetails.map((item: any) => {
                      return (
                        <GridItem key={item.label} marginY='4' marginX='6'>
                          <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                          <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                        </GridItem>
                      )
                    })}
                  </Grid>
                </Box>
              )}
              {data && data.ui_data.customerArrObjData && (
                <Box mt='4'>
                  <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                    Customer Details
                  </Text>
                  <Grid
                    gridTemplateColumns={
                      isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                    }
                  >
                    {data.ui_data.customerArrObjData.map((item: any) => {
                      if (item && item.value != '') {
                        return (
                          <GridItem key={item.label} marginY='4' marginX='6'>
                            <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                            <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                          </GridItem>
                        )
                      }
                    })}
                  </Grid>
                </Box>
              )}

              {data &&
                data.ui_data.cancerCoverType == '2A' &&
                data.ui_data.customerSpouseArrObjData && (
                  <Box mt='4'>
                    <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                      Customer Spouse Details
                    </Text>
                    <Grid
                      gridTemplateColumns={
                        isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                      }
                    >
                      {data.ui_data.customerSpouseArrObjData.map((item: any) => {
                        return (
                          <GridItem key={item.label} marginY='4' marginX='6'>
                            <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                            <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                          </GridItem>
                        )
                      })}
                    </Grid>
                  </Box>
                )}
              {data && data.ui_data.nomineeArrObjData && (
                <Box mt='4'>
                  <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                    Nominee Details
                  </Text>
                  <Grid
                    gridTemplateColumns={
                      isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                    }
                  >
                    {data.ui_data.nomineeArrObjData.map((item: any) => {
                      return (
                        <GridItem key={item.label} marginY='4' marginX='6'>
                          <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                          <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                        </GridItem>
                      )
                    })}
                    {data?.ui_data?.isSameAddressNominee &&
                      extendedAddressDetails.map((item: any) => {
                        return (
                          <GridItem key={item.label} marginY='4' marginX='6'>
                            <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                            <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                          </GridItem>
                        )
                      })}
                  </Grid>
                </Box>
              )}
              {data && data?.ui_data?.isNomineeAMinor && data.ui_data.appointeeArrObjData && (
                <Box mt='4'>
                  <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                    Appointee Details
                  </Text>
                  <Grid
                    gridTemplateColumns={
                      isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                    }
                  >
                    {data.ui_data.appointeeArrObjData.map((item: any) => {
                      return (
                        <GridItem key={item.label} marginY='4' marginX='6'>
                          <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                          <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                        </GridItem>
                      )
                    })}
                  </Grid>
                </Box>
              )}
            </>
          )}
          {!gotDirectDebitResponse && productCode === 'PMJJBY' && foundConsent && canDoPayment && (
            <>
              <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
                <Text fontWeight='bold'>Debit Authorisation</Text>
                <Checkbox
                  value='true'
                  onChange={() => {
                    setStandingInstruction((prev) => !prev)
                  }}
                  disabled={isConsentApproved}
                  defaultChecked={isConsentApproved}
                  // isChecked={isConsentApproved}
                  // checked={isConsentApproved}
                  // readOnly={isConsentApproved}
                >
                  <Text>
                    I hereby authorize Equitas Bank to debit my account with Rs.{' '}
                    {formatCurr(parseFloat(data.ui_data.selectedProductArrObjData?.premium))}
                    (applicable premium) towards premium of life insurance cover of Rs Two lakhs
                    under PMJJBY.
                  </Text>
                </Checkbox>
                <Text fontWeight='bold'>Declaration of good health</Text>
                <Checkbox
                  value='true'
                  onChange={() => {
                    setDeclarationOfGoodHealth(!declarationOfGoodHealth)
                  }}
                  defaultChecked={isConsentApproved}
                  disabled={isConsentApproved}
                >
                  <Text>
                    I,hereby declare that I am not suffering from any chronic disease as on date{' '}
                    {today} and I am in good health to enrol for this scheme.
                  </Text>
                </Checkbox>
              </Flex>
            </>
          )}

          {!gotDirectDebitResponse && productCode === 'PMSBY' && foundConsent && canDoPayment && (
            <>
              <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
                <Text fontWeight='bold'>Debit Authorisation</Text>
                <Checkbox
                  value='true'
                  onChange={() => {
                    setStandingInstruction((prev) => !prev)
                  }}
                  // isChecked={isConsentApproved}
                  defaultChecked={isConsentApproved}
                  disabled={isConsentApproved}
                >
                  <Text>
                    I hereby authorize Equitas Bank to debit my account with Rs.{' '}
                    {formatCurr(parseFloat(data.ui_data.selectedProductArrObjData?.premium))}
                    (applicable premium) towards premium of life insurance cover of Rs Two lakhs
                    under PMSBY.
                  </Text>
                </Checkbox>
                <Text fontWeight='bold'>Declaration of good health</Text>
                <Checkbox
                  value='true'
                  onChange={() => {
                    setDeclarationOfGoodHealth(!declarationOfGoodHealth)
                  }}
                  defaultChecked={isConsentApproved}
                  disabled={isConsentApproved}
                >
                  <Text>
                    I,hereby declare that I am not suffering from any chronic disease as on date{' '}
                    {today} and I am in good health to enrol for this scheme.
                  </Text>
                </Checkbox>
              </Flex>
            </>
          )}

          {!gotDirectDebitResponse && foundConsent && productCode === 'CIC' && canDoPayment && (
            <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
              <Text fontWeight='bold' fontSize='1.3rem'>
                Declaration
              </Text>
              <Text fontWeight='bold' fontSize='1.1rem'>
                Authorization for Electronic Policy Fulfilment and Service Communication
              </Text>
              <Checkbox
                {...registerCancerDeclarations('q1', { required: true })}
                value='true'
                defaultChecked={isConsentApproved}
                disabled={isConsentApproved}
                // checked={isConsentApproved}

                // readOnly={isConsentApproved}
                // isChecked={isConsentApproved}
                // readOnly={isConsentApproved}
              >
                <Text
                  dangerouslySetInnerHTML={{
                    __html: customerCancerConsentFormQuestionsDeclarationsMap.q1,
                  }}
                />
                {formStateCancerDeclarations.errors.q1 &&
                  formStateCancerDeclarations.errors.q1?.type === 'required' && (
                    <ErrorText text='This field is required' />
                  )}
              </Checkbox>
              <Text fontWeight='bold' fontSize='1.1rem'>
                Authorization for Service Calls or Any Other Communication
              </Text>
              <Checkbox
                {...registerCancerDeclarations('q2', { required: true })}
                value='true'
                defaultChecked={isConsentApproved}
                disabled={isConsentApproved} // readOnly={isConsentApproved}
                // isChecked={isConsentApproved}
                // readOnly={isConsentApproved}
              >
                <Text
                  dangerouslySetInnerHTML={{
                    __html: customerCancerConsentFormQuestionsDeclarationsMap.q2,
                  }}
                />
                {formStateCancerDeclarations.errors.q2 &&
                  formStateCancerDeclarations.errors.q2?.type === 'required' && (
                    <ErrorText text='This field is required' />
                  )}
              </Checkbox>
              <Text fontWeight='bold' fontSize='1.1rem'>
                Renewal payment sign up
              </Text>
              Payment of renewal premium of your health insurance policy can be made every year
              through continuing your existing Automated Clearing House (ACH) / Standing
              Instructions (SI) with the Company. Under this option, your policy can be renewed
              promptly, but subject to you completing all additional requirements of information and
              documentation as may be required by the Company.
              <Checkbox
                {...registerCancerDeclarations('q3', { required: true })}
                value='true'
                defaultChecked={isConsentApproved}
                disabled={isConsentApproved}
                // readOnly={isConsentApproved}
                // isChecked={isConsentApproved}
                // readOnly={isConsentApproved}
              >
                <Text
                  dangerouslySetInnerHTML={{
                    __html: customerCancerConsentFormQuestionsDeclarationsMap.q3,
                  }}
                />
                {formStateCancerDeclarations.errors.q3 &&
                  formStateCancerDeclarations.errors.q3?.type === 'required' && (
                    <ErrorText text='This field is required' />
                  )}
              </Checkbox>
              <Checkbox
                {...registerCancerDeclarations('q4', { required: true })}
                value='true'
                defaultChecked={isConsentApproved}
                disabled={isConsentApproved}
                // readOnly={isConsentApproved}
                // isChecked={isConsentApproved}
                // readOnly={isConsentApproved}
              >
                <Text
                  dangerouslySetInnerHTML={{
                    __html: customerCancerConsentFormQuestionsDeclarationsMap.q4,
                  }}
                />
                {formStateCancerDeclarations.errors.q4 &&
                  formStateCancerDeclarations.errors.q4?.type === 'required' && (
                    <ErrorText text='This field is required' />
                  )}
              </Checkbox>
              <Text fontWeight='bold' fontSize='1.1rem'>
                Good health declaration:
              </Text>
              <Checkbox
                {...registerCancerDeclarations('q5', { required: true })}
                value='true'
                defaultChecked={isConsentApproved}
                disabled={isConsentApproved}
              >
                <Text
                  dangerouslySetInnerHTML={{
                    __html: customerCancerConsentFormQuestionsDeclarationsMap.q5,
                  }}
                />
                {formStateCancerDeclarations.errors.q5 &&
                  formStateCancerDeclarations.errors.q5?.type === 'required' && (
                    <ErrorText text='This field is required' />
                  )}
              </Checkbox>
            </Flex>
          )}

          {!gotDirectDebitResponse &&
            productCode &&
            sachetsCoverageConditions[productCode] &&
            sachetsTerminationClauses[productCode] &&
            canDoPayment && (
              <>
                <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
                  <Text fontWeight='bold'>Coverage & Conditions</Text>
                  <UnorderedList>
                    {sachetsCoverageConditions[productCode].map((item: string, index: number) => {
                      return <ListItem key={index}>{item}</ListItem>
                    })}
                  </UnorderedList>
                </Flex>
                <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
                  <Text fontWeight='bold'>Termination Clauses</Text>
                  <Text fontSize='md'>
                    The Life cover for the member shall terminate on any of the following events and
                    no benefit will be payable there under:
                  </Text>
                  <UnorderedList>
                    {productCode &&
                      sachetsTerminationClauses[productCode].map((item: string, index: number) => {
                        return <ListItem key={index}>{item}</ListItem>
                      })}
                  </UnorderedList>
                </Flex>

                <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
                  <Text fontWeight='bold'>Terms and Conditions</Text>
                  <Checkbox
                    defaultChecked
                    disabled={isConsentApproved}
                    onChange={() => setTCchecked((prev) => !prev)}
                    checked={isConsentApproved}
                  >
                    {productCode === 'PMSBY' && (
                      <Text>
                        I hereby accept the{' '}
                        <Link
                          color='blue.300'
                          target='_blank'
                          href='https://www.equitasbank.com/personal-banking/insure/insurance/government-schemes/pradhan-mantri-suraksha-bima-yojana/'
                        >
                          Terms & Conditions
                        </Link>{' '}
                        for the PMSBY plan.
                      </Text>
                    )}
                    {productCode === 'PMJJBY' && (
                      <Text>
                        I hereby accept the{' '}
                        <Link
                          color='blue.300'
                          target='_blank'
                          href='https://www.equitasbank.com/personal-banking/insure/insurance/government-schemes/pradhan-mantri-jeevan-jyoti-bima-yojana/'
                        >
                          Terms & Conditions
                        </Link>{' '}
                        for the PMJJBY plan.
                      </Text>
                    )}
                    {formStateCancerDeclarations.errors.q1 &&
                      formStateCancerDeclarations.errors.q1?.type === 'required' && (
                        <ErrorText text='This field is required' />
                      )}
                  </Checkbox>
                </Flex>
              </>
            )}

          {gotDirectDebitResponse && directDebitResponse && (
            <SachetPaymentStatusModal
              setDDPaymentStatus={setDDPaymentStatus}
              quoteId={quoteId}
              data={data}
              setDirectDebitResponse={setDirectDebitResponse}
              setGotDirectDebitResponse={setGotDirectDebitResponse}
              directDebitResponse={directDebitResponse}
              DDpaymentStatus={DDPaymentStatus}
              coi_no={paymentData.coi_no}
            />
          )}

          {!gotDirectDebitResponse && productCode && productCode === 'CIC' && canDoPayment && (
            <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
              <Text fontWeight='bold' fontSize='1.3rem'>
                Statutory Warning
              </Text>
              <Text fontWeight='bold' fontSize='1.3rem'>
                Cancellation/Termination{' '}
              </Text>
              Once the Insured ceases to be a member or the policy is cancelled by the Master
              policyholder or the insurer for any reason whatsoever, the cover will automatically
              stand cancelled. However, the Insured under this policy can port to a similar approved
              retail health policy available with the company as per ‘Portability Guidelines’,
              subject to the Company’s underwriting criteria.
              <Text fontWeight='bold' fontSize='1.3rem'>
                Prohibition of Rebates
              </Text>
              No person shall allow or offer to allow, either directly or indirectly, as an
              inducement to any person (to take out or review or continue) an insurance in respect
              of any kind of risk relating to lives or property in India, any rebate of the whole or
              part of the commission payable or any rebate of the premium shown on the policy, nor
              shall any person taking out or renewing (or continuing) a policy accept any rebate,
              except such rebate as may be allowed in accordance with the published prospectus or
              tables of the insurer. Any person making default in complying with the provisions of
              this section shall be punishable with fine which may extend to Ten Lac Rupees.
              <Text fontWeight='bold' fontSize='1.3rem'>
                Caution
              </Text>
              You are obliged to make a full and frank disclosure of all material facts. This
              obligation of complete and timely disclosure does not end with the submission of this
              enrolment form. If there is any change in the information given herein or new
              information comes to light, then you must inform us of the same in writing without any
              delay.
              <Text fontWeight='bold' fontSize='1.3rem'>
                Declaration
              </Text>
              {!gotDirectDebitResponse &&
                // productCode === 'CIC' &&
                !gotDirectDebitResponse &&
                productCode === 'CIC' &&
                foundConsent &&
                canDoPayment && (
                  <>
                    <Flex gap='1rem' direction='column'>
                      <Checkbox
                        {...registerCancer('q1', { required: true })}
                        value='true'
                        // checked={isConsentApproved}
                        defaultChecked={isConsentApproved}
                        disabled={isConsentApproved}
                        // readOnly={isConsentApproved}
                        // isChecked={isConsentApproved}
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: customerCancerConsentFormQuestionsMap.q1,
                          }}
                        />
                        {formStateCancer.errors.q1 &&
                          formStateCancer.errors.q1?.type === 'required' && (
                            <ErrorText text='This field is required' />
                          )}
                      </Checkbox>
                      <Checkbox
                        {...registerCancer('q2', { required: true })}
                        value='true'
                        defaultChecked={isConsentApproved}
                        disabled={isConsentApproved} // readOnly={isConsentApproved}
                        // isChecked={isConsentApproved}
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: customerCancerConsentFormQuestionsMap.q2,
                          }}
                        />

                        {formStateCancer.errors.q2 &&
                          formStateCancer.errors.q2?.type === 'required' && (
                            <ErrorText text='This field is required' />
                          )}
                      </Checkbox>
                      <Checkbox
                        {...registerCancer('q3', { required: true })}
                        value='true'
                        defaultChecked={isConsentApproved}
                        disabled={isConsentApproved} // readOnly={isConsentApproved}
                        // isChecked={isConsentApproved}
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: customerCancerConsentFormQuestionsMap.q3,
                          }}
                        />

                        {formStateCancer.errors.q3 &&
                          formStateCancer.errors.q3?.type === 'required' && (
                            <ErrorText text='This field is required' />
                          )}
                      </Checkbox>
                      <Checkbox
                        {...registerCancer('q4', { required: true })}
                        value='true'
                        defaultChecked={isConsentApproved}
                        disabled={isConsentApproved} // readOnly={isConsentApproved}
                        // isChecked={isConsentApproved}
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: customerCancerConsentFormQuestionsMap.q4,
                          }}
                        />

                        {formStateCancer.errors.q4 &&
                          formStateCancer.errors.q4?.type === 'required' && (
                            <ErrorText text='This field is required' />
                          )}
                      </Checkbox>
                      <Checkbox
                        {...registerCancer('q5', { required: true })}
                        value='true'
                        defaultChecked={isConsentApproved}
                        disabled={isConsentApproved}
                        // checked={isConsentApproved}
                        // readOnly={isConsentApproved}
                        // isChecked={isConsentApproved}
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: customerCancerConsentFormQuestionsMap.q5,
                          }}
                        />

                        {formStateCancer.errors.q5 &&
                          formStateCancer.errors.q5?.type === 'required' && (
                            <ErrorText text='This field is required' />
                          )}
                      </Checkbox>
                    </Flex>
                  </>
                )}
            </Flex>
          )}

          {!isOtpVerified &&
            foundConsent &&
            !gotDirectDebitResponse &&
            canDoPayment &&
            !isConsentApproved && (
              <Center flexDirection='column'>
                {!isOtpSent && productCode !== 'CIC' && (
                  <ElevatedButton
                    disabled={!standingInstruction || !TCchecked || !declarationOfGoodHealth}
                    onClick={async () => {
                      const isFormValid = await trigger()
                      if (isFormValid) {
                        // await sendOtp({
                        //   email: customerEmail ?? '-',
                        // })
                        await sendOtp({
                          message_type: 'customer_otp',
                          phone_number: customerPhone ? '91' + customerPhone.slice(-10) : '',
                          //  phone_number: '919899004640',
                          unique_identifier: quoteId ?? '',
                          lead_uid: '',
                        })
                        await sendOTPviaEmail({
                          message_type: 'customer_otp',
                          reciever_mail: customerEmail ?? '-',
                          unique_identifier: quoteId ?? '',
                          lead_uid: '',
                        })
                        ToastService.showSuccessTaost({
                          title: 'Success',
                          message: `OTP sent successfully to ${customerPhone} and ${customerEmail}`,
                        })
                        startTimer()
                      }
                    }}
                    isLoading={isSendOtpLoading}
                    isDisabled={isSendOtpLoading}
                    marginY='1rem'
                  >
                    Send OTP
                  </ElevatedButton>
                )}

                {!isOtpSent && productCode === 'CIC' && (
                  <ElevatedButton
                    // disabled={standingInstruction}
                    onClick={async () => {
                      const isFormValid = await triggerCancerDeclarations()
                      const isFormValid2 = await triggerCancer()
                      if (isFormValid && isFormValid2) {
                        await sendOtp({
                          message_type: 'customer_otp',
                          phone_number: customerPhone ? '91' + customerPhone.slice(-10) : '',
                          //  phone_number: '919899004640',
                          unique_identifier: quoteId ?? '',
                          lead_uid: '',
                        })
                        await sendOTPviaEmail({
                          message_type: 'customer_otp',
                          reciever_mail: customerEmail ?? '-',
                          unique_identifier: quoteId ?? '',
                          lead_uid: '',
                        })
                        ToastService.showSuccessTaost({
                          title: 'Success',
                          message: `OTP sent successfully to ${customerPhone} and ${customerEmail}`,
                        })
                        startTimer()
                      }
                    }}
                    isLoading={isSendOtpLoading}
                    isDisabled={isSendOtpLoading}
                    marginY='1rem'
                  >
                    Send OTP
                  </ElevatedButton>
                )}

                {isOtpSent && (
                  <Flex
                    paddingY='1rem'
                    direction='column'
                    alignItems='center'
                    marginY='4'
                    marginX='6'
                  >
                    <FormLabel>Enter OTP</FormLabel>
                    <HStack>
                      <PinInput
                        onChange={(str) => {
                          setOtp(str)
                        }}
                      >
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                  </Flex>
                )}
                {isOtpSent && (
                  <Flex marginY='0.7rem'>
                    <Button
                      isDisabled={otp.length !== 6}
                      onClick={async () => {
                        try {
                          const response: any = await verifyOtp({
                            phone_no: customerPhone ? '91' + customerPhone.slice(-10) : '',
                            //  phone_no:919899004640,
                            otp: otp,
                            unique_identifier: quoteId ?? '',
                          }).unwrap()

                          const resp: any = await submitConsentForm({
                            quote_id: quoteId,
                          })
                          setIsOtpVerified(true)
                          ToastService.showSuccessTaost({
                            title: 'OTP Verified successfully',
                          })
                        } catch (e) {
                          ToastService.showErrorToast({
                            title: 'OTP Verification Failed',
                          })
                        }
                      }}
                      marginX='0.5rem'
                    >
                      Verify OTP
                    </Button>
                    <Button
                      disabled={!resendOtp}
                      onClick={async () => {
                        const res = await sendOtp({
                          message_type: 'customer_otp',
                          phone_number: customerPhone ? '91' + customerPhone.slice(-10) : '',
                          //  phone_number: '919899004640',
                          unique_identifier: quoteId ?? '',
                          lead_uid: '',
                        })
                        await sendOTPviaEmail({
                          message_type: 'customer_otp',
                          reciever_mail: customerEmail ?? '-',
                          unique_identifier: quoteId ?? '',
                          lead_uid: '',
                        })
                        setResendOtp(false)
                        ToastService.showSuccessTaost({
                          title: 'Success',
                          message: `OTP sent successfully to ${customerPhone} and ${customerEmail}`,
                        })
                        startTimer()
                      }}
                      marginX='0.5rem'
                    >
                      {resendOtp ? 'Resend OTP' : `Resend OTP in ${timeLeft}`}
                    </Button>
                  </Flex>
                )}
              </Center>
            )}
          {!gotDirectDebitResponse && isOtpVerified && canDoPayment && (
            <Center padding='1.5rem 0.5rem '>
              <ElevatedButton
                type='submit'
                onClick={onSubmit}
                isLoading={isDirectDebitLoading || isDMSAPILoading || acceptClicked}
              >
                Accept and Authenticate
                {/* Submit */}
              </ElevatedButton>
            </Center>
          )}
        </Box>
      )}
    </>
  )
}
