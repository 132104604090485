import { Header } from 'Components'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
} from '@chakra-ui/react'
import { MemberDetailsDesktop } from './Desktop'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { IMemberState, MemberForm } from './Forms/MemberForm'
import { useState } from 'react'
import {
  addMembers,
  deleteMembers,
  updateParentsPincode,
  updateProposer,
  updateProposerPincode,
} from 'features/Health/memberDetails.health.slice'
import { MemberData } from 'Models/Common/Health.General'
import { getDOBfromAge, getAgeFromDob, getMemberTypeFromAge, decryptData } from 'Services/general'
import { GenderMap } from 'Constants'
import { IHealthPincode } from 'Models/ResponseModels/Health'
import { clearCart, removeSelectedQuotes } from 'features/Health/cart.health.slice'
import { MemberPincode } from './Forms/MemberPincode'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ISaveLeadResponse } from 'Models/ResponseModels'

const MemberDetails = () => {
  const { isDesktop } = useDeviceType()
  const dispatch = useAppDispatch()
  const [showMemberPincodeForm, setShowMemberPincodeForm] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  const { proposer, memberDetails, isProposerIncluded } = useAppSelector(
    (store) => store.memberDetails,
  )
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const cart_details = useAppSelector((state) => state.cart)
  const member_details = useAppSelector((state) => state.memberDetails)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      lead_data: {
        cart_details: cart_details,
        member_details: member_details,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('prequote', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  const getGenderOfMember = (
    member_type: 'proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse',
  ) => {
    if (member_type === 'spouse')
      return proposer.gender.toLocaleLowerCase() === 'male' ? 'Female' : 'Male'
    return GenderMap[member_type]
  }

  const addMembersToStore = (members: IMemberState[]) => {
    const storeMembers: MemberData[] = []
    let proposerIncluded = false
    setCurrentIndex((prev) => prev + 1)
    members.forEach((member) => {
      console.log(members)
      if (member.member_type === 'proposer') {
        proposerIncluded = true
        dispatch(
          updateProposer({
            data: {
              ...proposer,
              age: member.age,
              // type: getMemberTypeFromAge(member.age.toString()),
              type: 'adult',
            },
            isProposerIncluded: true,
          }),
        )
      }
      member.member_type != 'proposer' &&
        storeMembers.push({
          type:
            member.member_type === 'spouse' ? 'adult' : getMemberTypeFromAge(member.age.toString()),
          salutation: { name: '', code: '' },
          member_type: member.member_type,
          age: member.age,
          dob: member.dob ?? getDOBfromAge(member.age),
          pincode: '',
          proposer: 0,
          gender: getGenderOfMember(member.member_type) ?? '',
          city_name: '',
          state_name: '',
          nationality: 'Indian',
          selected: true,
          code: member.code,
          marital_status: { name: '', code: '' },
          member_insured: '',
          firstname: '',
          middlename: '',
          lastname: '',
          relationship_with_proposer: '',
          mobile: '',
          email: '',
          occupation: { name: '', code: '' },
          bmi: '',
          height: '',
          weight: '',
          educational_qualification: { name: '', code: '' },
          annual_income: '',
          aadhar_card_number: '',
          pan_number: '',
          current_address: '',
          pincode_map: {
            pincode: '',
            area_name: '',
            area_code: '',
            city_code: '',
            city_name: '',
            state_name: '',
            state_code: '',
            is_district: null,
            area_id: null,
            extra_info: '',
            id: null,
            is_insurance_wise: null,
          },
          permanent_address: '',
          permanent_address_pincode_map: {
            pincode: '',
            area_name: '',
            area_code: '',
            city_code: '',
            city_name: '',
            state_name: '',
            state_code: '',
            is_district: null,
            area_id: null,
            extra_info: '',
            id: null,
            is_insurance_wise: null,
          },
          nominee_details: {
            salutation: { name: '', code: '' },
            firstname: '',
            middlename: '',
            lastname: '',
            dob: '',
            gender: '',
            relationship: { name: '', code: '' },
            mobile: '',
            occupation: { name: '', code: '' },
            address: '',
            marital_status: { name: '', code: '' },
            pincode_map: {
              pincode: '',
              area_name: '',
              area_code: '',
              city_code: '',
              city_name: '',
              state_name: '',
              state_code: '',
              is_district: null,
              area_id: null,
              extra_info: '',
              id: null,
              is_insurance_wise: null,
            },
          },
          medical_questions: [],
          lifestyle_questions: [],
        })

      if (!proposerIncluded) {
        dispatch(
          updateProposer({
            data: {
              ...proposer,
            },
            isProposerIncluded: false,
          }),
        )
      }
    })

    dispatch(deleteMembers())
    dispatch(addMembers(storeMembers))
    setShowMemberPincodeForm(true)
    createLead()
  }

  const addPincodesToStore = ({
    selfPincode,
    parentsPincode,
  }: {
    selfPincode: IHealthPincode
    parentsPincode: IHealthPincode
  }) => {
    // add pincodes to each member in redux
    console.log(selfPincode, parentsPincode)

    const updatedMember: MemberData[] = []

    Object.keys(memberDetails).map((memberCode) => {
      if (
        memberDetails[memberCode].member_type === 'father' ||
        memberDetails[memberCode].member_type === 'mother'
      ) {
        updatedMember.push({
          ...memberDetails[memberCode],
          city_name: parentsPincode.city_name,
          state_name: parentsPincode.state_name,
          pincode: selfPincode.pincode!.toString(),
        })
      } else {
        updatedMember.push({
          ...memberDetails[memberCode],
          city_name: selfPincode.city_name,
          state_name: selfPincode.state_name,
          pincode: selfPincode.pincode!.toString(),
        })
      }
    })
    dispatch(deleteMembers())
    dispatch(clearCart())
    dispatch(removeSelectedQuotes())
    dispatch(addMembers(updatedMember))
    if (selfPincode) {
      dispatch(updateProposerPincode(selfPincode!))
    }
    if (parentsPincode) {
      dispatch(updateParentsPincode(parentsPincode!))
    }
    dispatch(
      updateProposer({
        data: {
          ...proposer,
          city_name: selfPincode.city_name,
          state_name: selfPincode.state_name,
          pincode: selfPincode.pincode!.toString(),
        },
        isProposerIncluded: isProposerIncluded,
      }),
    )
    createLead()
  }

  return (
    <>
      <Header />
      <Box>
        <Box backgroundColor='#F6F6F6'>
          <Text
            textStyle='heading1'
            color='secondary.500'
            fontWeight='normal'
            paddingLeft={isDesktop ? '24' : '4'}
            py={isDesktop ? '8' : '4'}
          >
            Members to be Insured
          </Text>
          {isDesktop ? (
            <MemberDetailsDesktop
              showMemberPincodeForm={showMemberPincodeForm}
              onSubmit={addMembersToStore}
              pincodeFormSubmit={addPincodesToStore}
            />
          ) : (
            <Box h='full' marginY='auto' padding='2'>
              <Accordion defaultIndex={[0]} index={currentIndex}>
                <AccordionItem
                  isDisabled={currentIndex < 1}
                  rounded='2xl'
                  border='1px solid #DEDEDE'
                  backgroundColor='white'
                >
                  <h2>
                    <AccordionButton boxShadow='lg'>
                      <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                        Who would you like to insure
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel p={0}>
                    <MemberForm onSubmit={addMembersToStore} />
                  </AccordionPanel>
                </AccordionItem>
                <Box padding='2'></Box>
                <AccordionItem
                  // isDisabled={!showMemberPincodeForm}
                  rounded='2xl'
                  border='1px solid #DEDEDE'
                  backgroundColor='white'
                >
                  <h2>
                    <AccordionButton boxShadow='lg'>
                      <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                        Select Your Pincode
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <MemberPincode onSubmit={addPincodesToStore} />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default MemberDetails
