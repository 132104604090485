import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Image,
  Divider,
  Center,
  Grid,
  GridItem,
  FormLabel,
  Checkbox,
  RadioGroup,
  Radio,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
} from '@chakra-ui/react'
import { ElevatedButton, ErrorText, TextInput } from 'Core'
import { useAppSelector, useDeviceType } from 'app/hooks'
import { customerConsentFormQuestionsMap, openLinkInSpecifiedTab } from 'Services/general'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TCustomerConsentForm } from 'Pages/Common/ConsentForm/FormModels'
import { useEffect, useRef, useState } from 'react'
// import { useSpTaggingSendOtpMutation } from 'Services/API/authentication.api'
import { ToastService } from 'Services/toast.service'
import {
  useLazyGetConsentDataQuery,
  useLifeDMSDataPushMutation,
  useUpdateConsentDataMutation,
} from 'Services/API/life.api'
import {
  useConsentUploadMutation,
  useSubmitCustomerConsentFormMutation,
} from 'Services/API/motor.api'
import { exportAsImage } from 'Pages/Health/Consent/exportAsImage'
import { useParams } from 'react-router-dom'
import {
  useConsentSendSMSMutation,
  useSendEmailForOTPMutation,
  useVerifyOTPforConsentMutation,
} from 'Services/API/equitas.api'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

export const LifeConsentForm = () => {
  const { isDesktop, isTablet } = useDeviceType()
  const { consentId } = useParams()

  const { register, handleSubmit, formState, trigger } = useForm<TCustomerConsentForm>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })
  const [isOtpVerified, setIsOtpVerified] = useState(false)
  const customerProposerDetails = useAppSelector((state) => state.customerDetails)
  const { planQuoteId } = useAppSelector((state) => state.lifeQuotationDetails)

  const [sendSMS, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
    useConsentSendSMSMutation()

  const [verifyOtp, { isLoading: isVerifyOtpLoading }] = useVerifyOTPforConsentMutation()

  const [sendOTPviaEmail] = useSendEmailForOTPMutation()

  // const [sendOtp, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
  //   useSpTaggingSendOtpMutation()

  const [otp, setOtp] = useState('')

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const [callDMSAPI] = useLifeDMSDataPushMutation()

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  const [getconsent, { currentData: data, isFetching, isLoading }] = useLazyGetConsentDataQuery()

  useEffect(() => {
    getconsent({
      id: consentId!,
    })
  }, [])

  useEffect(() => {
    if (data?.status === 'APPROVED') {
      setConsentSubmitted(true)
    }
  }, [data])

  const [consentUpload] = useConsentUploadMutation()

  const exportRef = useRef<HTMLDivElement>(null)

  const [submitConsentForm] = useUpdateConsentDataMutation()

  const [consentSubmitted, setConsentSubmitted] = useState(false)

  const onSubmit: SubmitHandler<TCustomerConsentForm> = async (data1) => {
    // console.log(data)
    try {
      const resp: any = await submitConsentForm({
        consent_id: consentId!,
        status: 'APPROVED',
      })
      ToastService.showSuccessTaost({
        title: 'Approved',
      })
      setConsentSubmitted(true)
      try {
        const image = await exportAsImage(exportRef.current, 'test')
        callDMSAPI({
          quote_id: data?.quote_id,
          consent_type: 'cc_consent',
          b64_image: image,
        })
      } catch (e) {
        console.log(e)
      }

      // const paymentLink = resp.data
      // console.log(paymentLink)

      // const image = await exportAsImage(exportRef.current, 'test')

      // call consent upload api
      // await consentUpload({
      //     b64_image: image,
      //     consent_type: 'customer_consent',
      //     unique_identifier: uniqueId,
      //     email_id: customerData?.email_id ?? '',
      //     lead_uid: leaduid,
      // })

      // if (paymentLink) {
      //     window.open(paymentLink, '_self')
      // } else {
      //     console.log(resp.error.message)
      //     throw Error(resp.error.message)
      // }
    } catch (e: any) {
      ToastService.showErrorToast({
        title: 'Something went wrong!',
        message: e.message ?? '',
      })
    }
  }

  return consentSubmitted ? (
    <Box textAlign={'center'} mt='4'>
      Consent Approved
    </Box>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div ref={exportRef}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box p='4'>
            {data?.data && (
              <>
                <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                  Customer Consent Form
                </Text>
                <Center mt='4'>
                  <Image h='auto' w='60' src={data.data?.policyData?.logo} />
                </Center>
                <Grid
                  mt='2'
                  border={'1px'}
                  gridTemplateColumns={isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'}
                >
                  <GridItem p='3' borderRight={'1px'}>
                    <Box>
                      <Text>
                        <span className='font-bold'>Plan Name </span> :{' '}
                        {data.data?.policyData?.name}
                      </Text>
                      <Text>
                        <span className='font-bold'>Sum Insured</span> :{' '}
                        {data.data?.policyData?.sumInsured}
                      </Text>
                      <Text>
                        <span className='font-bold'>Policy For</span> :{' '}
                        {data.data?.policyData?.policyFor}
                      </Text>
                      <Text>
                        <span className='font-bold'>Pincode</span> :{' '}
                        {data.data?.policyData?.pincode}
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem p='3' borderRight={'1px'}>
                    <Box>
                      <Text>
                        <span className='font-bold'>Final Premium</span> :{' '}
                        {data.data?.policyData?.finalPremium}
                      </Text>
                      <Text>
                        <span className='font-bold'>Payment Mode</span> :{' '}
                        {data.data?.policyData?.paymentMode}
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem p='3' borderRight={'1px'}>
                    <Box>
                      <Text>
                        <span className='font-bold'>Premium Payment Term</span> :{' '}
                        {data.data?.policyData?.paymentTerm}{' '}
                      </Text>
                      <Text>
                        <span className='font-bold'>Policy Term</span> :{' '}
                        {data.data?.policyData?.policyTerm}
                      </Text>
                      <Text>
                        <span className='font-bold'>Proposal Date</span> :{' '}
                        {data.data?.policyData?.proposalDate}
                      </Text>
                      {/* <Text>
                        <span className='font-bold'>Plan Option</span> :{' '}
                        {data.data?.policyData?.planOption ?? '-'}
                      </Text> */}
                    </Box>
                  </GridItem>
                  {/* <GridItem p='3' borderRight={'1px'}>
                    <Box> */}
                  {/* <Text>
                        <span className='font-bold'>Payment Method</span> :{' '}
                        {data.data?.policyData?.paymentMethod}
                      </Text> */}
                  {/* <Text>
                        <span className='font-bold'>Proposal Number</span> :{' '}
                        {data.data?.policyData?.proposalNumber}
                      </Text> */}
                  {/* <Text>
                        <span className='font-bold'>Proposal Date</span> :{' '}
                        {data.data?.policyData?.proposalDate}
                      </Text> */}
                  {/* </Box>
                  </GridItem> */}
                </Grid>
                {data.data.customerAccountDetails && (
                  <Box mt='4'>
                    <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                      Customer Account Details
                    </Text>
                    <Grid
                      gridTemplateColumns={
                        isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                      }
                    >
                      {data.data.customerAccountDetails.map((item: any) => {
                        return (
                          <GridItem key={item.label} marginY='4' marginX='6'>
                            <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                            <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                          </GridItem>
                        )
                      })}
                    </Grid>
                  </Box>
                )}
                {data.data.intermediaryDetails && (
                  <Box mt='4'>
                    <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                      Intermediary Details
                    </Text>
                    <Grid
                      gridTemplateColumns={
                        isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                      }
                    >
                      {data.data.intermediaryDetails.map((item: any) => {
                        return (
                          <GridItem key={item.label} marginY='4' marginX='6'>
                            <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                            <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                          </GridItem>
                        )
                      })}
                    </Grid>
                  </Box>
                )}
                <Box mt='4'>
                  <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                    Customer Details
                  </Text>
                  {data.data.proposerDetails && (
                    <>
                      <Text mt='4' fontWeight={'bold'} fontSize={'lg'}>
                        Proposer Details
                      </Text>
                      <Grid
                        gridTemplateColumns={
                          isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                        }
                      >
                        {data.data.proposerDetails.map((item: any) => {
                          return (
                            <GridItem key={item.label} marginY='4' marginX='6'>
                              <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                              <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                            </GridItem>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                  {data.data.insuredDetails && (
                    <>
                      <Text mt='4' fontWeight={'bold'} fontSize={'lg'}>
                        Insured Details
                      </Text>
                      <Grid
                        gridTemplateColumns={
                          isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                        }
                      >
                        {data.data.insuredDetails.map((item: any) => {
                          return (
                            <GridItem key={item.label} marginY='4' marginX='6'>
                              <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                              <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                            </GridItem>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                </Box>
                {data.data.suitabilityAnalysis && (
                  <Box mt='4'>
                    <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                      Suitability Analysis
                    </Text>
                    <Grid gridTemplateColumns={'1fr'}>
                      {data.data.suitabilityAnalysis.map((item: any) => {
                        return (
                          <GridItem key={item.label} marginY='4' marginX='6'>
                            <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                            <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                          </GridItem>
                        )
                      })}
                    </Grid>
                  </Box>
                )}

                <Box mt='4'>
                  <Text fontWeight={'bold'} fontSize={'xl'}>
                    Recommended Products
                  </Text>
                  {data?.data?.recommendedProducts?.map((item: any, index: number) => {
                    return (
                      <Text key={index}>
                        {index + 1}) {item}
                      </Text>
                    )
                  })}
                </Box>

                {data.data.selectedProductName && (
                  <Box mt='4'>
                    <Text fontWeight={'bold'} fontSize={'xl'}>
                      Product Selected
                    </Text>
                    <Text>{data.data.selectedProductName}</Text>
                  </Box>
                )}

                <Center gap='2'>
                  {data?.data?.downloadBrochure && (
                    <Button
                      onClick={() => {
                        openLinkInSpecifiedTab(data?.data?.downloadBrochure, '_blank')
                      }}
                    >
                      Download Brochure
                    </Button>
                  )}
                  {data?.data?.downloadBusinessIllustration && (
                    <Button
                      onClick={() => {
                        openLinkInSpecifiedTab(data?.data?.downloadBusinessIllustration, '_blank')
                      }}
                    >
                      Download Business Illustration
                    </Button>
                  )}
                </Center>
                {data.data.productDetails && (
                  <Box mt='4'>
                    <Text textAlign='center' fontWeight={'bold'} fontSize={'xl'}>
                      Product Details
                    </Text>
                    <Grid
                      gridTemplateColumns={
                        isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                      }
                    >
                      {data.data.productDetails.map((item: any) => {
                        return (
                          <GridItem key={item.label} marginY='4' marginX='6'>
                            <FormLabel fontWeight={'bold'}>{item.label}</FormLabel>
                            <TextInput isReadOnly defaultValue={(item.value as string) ?? ''} />
                          </GridItem>
                        )
                      })}
                    </Grid>
                  </Box>
                )}
                {data.data.addRiders.length > 0 && (
                  <Box mt='4'>
                    <Text fontWeight={'bold'} fontSize={'xl'}>
                      Add-Ons/ Riders
                    </Text>
                    {data?.data?.addRiders?.map((item: any, index: number) => {
                      return (
                        <Flex wrap={'wrap'} gap='6' key={index}>
                          <Flex gap='2'>
                            <Text fontWeight={'bold'}>Rider {index + 1}</Text>
                            <Text>{item ?? ''}</Text>
                          </Flex>
                          {/* <Flex gap='2'>
                            <Text fontWeight={'bold'}>Sum Insured</Text>
                            <Text>{item.suminsured ?? ''}</Text>
                          </Flex>
                          <Flex gap='2'>
                            <Text fontWeight={'bold'}>Premium</Text>
                            <Text>{item.premium ?? ''}</Text>
                          </Flex>
                          <Flex gap='2'>
                            <Text fontWeight={'bold'}>Payment Term Option</Text>
                            <Text>{item.paymentTermOption ?? ''}</Text>
                          </Flex>
                          <Flex gap='2'>
                            <Text fontWeight={'bold'}>Rider Term</Text>
                            <Text>{item.riderTerm ?? ''}</Text>
                          </Flex>
                          <Flex gap='2'>
                            <Text fontWeight={'bold'}>Rider Payment Term</Text>
                            <Text>{item.riderPaymentTerm ?? ''}</Text>
                          </Flex> */}
                        </Flex>
                      )
                    })}
                  </Box>
                )}
                <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
                  <Checkbox {...register('q1', { required: true })} value='true'>
                    <Text
                      dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q1 }}
                    />
                    {formState.errors.q1 && formState.errors.q1?.type === 'required' && (
                      <ErrorText text='This field is required' />
                    )}
                  </Checkbox>
                  <Checkbox {...register('q2', { required: true })} value='true'>
                    <Text
                      dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q2 }}
                    />

                    {formState.errors.q2 && formState.errors.q2?.type === 'required' && (
                      <ErrorText text='This field is required' />
                    )}
                  </Checkbox>
                  <Checkbox {...register('q3', { required: true })} value='true'>
                    <Text
                      dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q3 }}
                    />

                    {formState.errors.q3 && formState.errors.q3?.type === 'required' && (
                      <ErrorText text='This field is required' />
                    )}
                  </Checkbox>
                  <Checkbox {...register('q4', { required: true })} value='true'>
                    <Text
                      dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q4 }}
                    />

                    {formState.errors.q4 && formState.errors.q4?.type === 'required' && (
                      <ErrorText text='This field is required' />
                    )}
                  </Checkbox>
                  <Checkbox {...register('q5', { required: true })} value='true'>
                    <Text
                      dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q5 }}
                    />

                    {formState.errors.q5 && formState.errors.q5?.type === 'required' && (
                      <ErrorText text='This field is required' />
                    )}
                  </Checkbox>
                  <Checkbox isChecked>
                    <Text>
                      {
                        'I/ We have gone through the list of products(s) and fund(s) recommended to me based on the risk profiling conducted by Equitas Small Finance Bank and/or the insurance partner. I/We wish to supersede the recommendations of Equitas Small Finance Bank and/or the insurance partner & view other Life Insurance product(s). I/We agree to purchase the product(s) based on my independent assessment of the risks, merits and suitability of the product(s). I/We will not hold Equitas Small Finance Bank and/or the insurance partner responsible for my acceptance of such product(s) and fund(s) as per my/our understanding.'
                      }
                    </Text>
                  </Checkbox>
                  {/* <Flex direction='column' marginY='4' marginX='6'>
                    <FormLabel>Select the Payment Method</FormLabel>
                    <RadioGroup value={'others'}>
                        <Radio marginX='0.7rem' value='equitas_account'>
                            My Equitas Account
                        </Radio>
                        <Radio marginX='0.7rem' value='others'>
                            Other payment options
                        </Radio>
                    </RadioGroup>
                </Flex> */}
                </Flex>
                {!isOtpVerified && (
                  <Center flexDirection='column'>
                    {!isOtpSent && (
                      <ElevatedButton
                        // onClick={async () => {
                        //   const isFormValid = await trigger()
                        //   if (isFormValid) {
                        //     await sendSMS({
                        //       message_type: 'customer_otp',
                        //       phone_number: data.data.proposerDetails[5].value,
                        //       //  phone_number: '919899004640',
                        //       unique_identifier: data.data.quote_id ?? '',
                        //       lead_uid: data.lead_id ?? '',
                        //     })
                        //     startTimer()
                        //   }
                        // }}
                        onClick={async () => {
                          const isFormValid = await trigger()
                          if (isFormValid) {
                            sendSMS({
                              message_type: 'customer_otp',
                              phone_number: data.data.proposerDetails[5].value,
                              //  phone_number: '919899004640',
                              unique_identifier: data.quote_id ?? '',
                              lead_uid: data.lead_id ?? '',
                            })
                              .unwrap()
                              .then((res) => {
                                console.log(res)
                                ToastService.showSuccessTaost({
                                  title: 'Success',
                                  message: `OTP sent successfully to ${data.data.proposerDetails[5].value} and ${data.data.proposerDetails[6].value}`,
                                })
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                            sendOTPviaEmail({
                              message_type: 'customer_otp',
                              reciever_mail: data.data.proposerDetails[6].value,
                              unique_identifier: data.quote_id ?? '',
                              lead_uid: data.lead_id ?? '',
                            })
                            startTimer()
                          }
                        }}
                        isLoading={isSendOtpLoading}
                        isDisabled={isSendOtpLoading}
                        marginY='1rem'
                      >
                        Send OTP
                      </ElevatedButton>
                    )}
                    {isOtpSent && (
                      <Flex
                        paddingY='1rem'
                        direction='column'
                        alignItems='center'
                        marginY='4'
                        marginX='6'
                      >
                        <FormLabel>Enter OTP</FormLabel>
                        <HStack>
                          <PinInput
                            onChange={(str) => {
                              setOtp(str)
                            }}
                          >
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                          </PinInput>
                        </HStack>
                      </Flex>
                    )}
                    {isOtpSent && (
                      <Flex marginY='0.7rem'>
                        <Button
                          isDisabled={otp.length !== 6}
                          isLoading={isVerifyOtpLoading}
                          onClick={() => {
                            try {
                              verifyOtp({
                                phone_no: data.data.proposerDetails[5].value,
                                otp: otp,
                                unique_identifier: data.quote_id ?? '',
                              })
                                .unwrap()
                                .then((res) => {
                                  console.log(res)
                                  setIsOtpVerified(true)
                                  ToastService.showSuccessTaost({
                                    title: 'OTP Verified successfully',
                                  })
                                  setIsOtpVerified(true)
                                })
                                .catch((e) =>
                                  ToastService.showErrorToast({
                                    title: 'OTP Verification Failed',
                                  }),
                                )
                            } catch (e) {
                              console.log(e)
                              ToastService.showErrorToast({
                                title: 'OTP Verification Failed',
                              })
                            }
                          }}
                          marginX='0.5rem'
                        >
                          Verify OTP
                        </Button>
                        <Button
                          disabled={!resendOtp}
                          // onClick={async () => {
                          //   await sendSMS({
                          //     message_type: 'customer_otp',
                          //     phone_number: data.data.proposerDetails[5].value,
                          //     //  phone_number: '919899004640',
                          //     unique_identifier: data.data.quote_id ?? '',
                          //     lead_uid: data.data.lead_id,
                          //   })
                          //   setResendOtp(false)
                          //   startTimer()
                          // }}
                          onClick={async () => {
                            const isFormValid = await trigger()
                            if (isFormValid) {
                              sendSMS({
                                message_type: 'customer_otp',
                                phone_number: data.data.proposerDetails[5].value,
                                //  phone_number: '919899004640',
                                unique_identifier: data.quote_id ?? '',
                                lead_uid: data.lead_id ?? '',
                              })
                                .unwrap()
                                .then((res) => {
                                  console.log(res)
                                  ToastService.showSuccessTaost({
                                    title: 'Success',
                                    message: `OTP sent successfully to ${data.data.proposerDetails[5].value} and ${data.data.proposerDetails[6].value}`,
                                  })
                                })
                                .catch((err) => {
                                  console.log(err)
                                })
                              sendOTPviaEmail({
                                message_type: 'customer_otp',
                                reciever_mail: data.data.proposerDetails[6].value,
                                unique_identifier: data.quote_id ?? '',
                                lead_uid: data.lead_id ?? '',
                              })
                              setResendOtp(false)
                              startTimer()
                            }
                          }}
                          marginX='0.5rem'
                        >
                          {resendOtp ? 'Resend OTP' : `Resend OTP in ${timeLeft}`}
                        </Button>
                      </Flex>
                    )}
                  </Center>
                )}
                {isOtpVerified && (
                  <Center padding='1.5rem 0.5rem '>
                    <ElevatedButton type='submit'>Submit</ElevatedButton>
                  </Center>
                )}
              </>
            )}
          </Box>
        )}
      </div>
    </form>
  )
}
