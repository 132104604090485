import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button,
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
  FormErrorMessage,
  FormControl,
  Grid,
  Spacer,
  Checkbox,
  Spinner,
} from '@chakra-ui/react'

import { BimabossDatePicker, TextInput, ToggleSwitch, Modal } from 'Core'
import { useEffect, useState } from 'react'
import { Select as ChakraSelect } from 'chakra-react-select'
import { useAppDispatch, useAppSelector, useDeviceType, useWindowDimensions } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { useGetQuestionListingQuery } from 'Services/API/health.api'
import { Controller, FieldValues, useForm, UseFormSetValue } from 'react-hook-form'
import { GetQuestionsListingResponse } from 'Models/ResponseModels/Health'
import { ButtonComponent } from '../../ProposerAndInsurerDetails/Components/ButtonComponent'
import { HEADER_HEIGHT } from 'Constants'
import { subYears } from 'date-fns'
import { decryptData, getArrayFromObj } from 'Services/general'
import { ProposalSpTaggingForm } from './SPTaggingForm'
import { QuestionBody } from 'Models/Common/Health.General'
import { updateProposer, updateMember } from 'features/Health/memberDetails.health.slice'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ToastService } from 'Services/toast.service'
import { ISaveLeadResponse } from 'Models/ResponseModels'
interface ILifeStyleQuestionsForm {
  onFormSubmit: () => void
  setShowLifeStyleQuestions: React.Dispatch<React.SetStateAction<boolean>>
  onBackPress: () => void
}

export const LifeStyleForm = (props: ILifeStyleQuestionsForm) => {
  const { height } = useWindowDimensions()
  const [lifeStyleQues, setlifeStyleQues] = useState(false)

  const cart_details = useAppSelector((state) => state.cart)

  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)

  const { memberDetails, isProposerIncluded, proposer } = useAppSelector(
    (state) => state.memberDetails,
  )

  const getMembersDataWithDisease = () => {
    const membersArr = []
    if (proposer.lifestyle_questions.length !== 0) {
      membersArr.push({
        label: 'Self',
        value: 'Self',
        quoteNumber: proposer.selectedQuoteNumber!,
        code: '',
        lifestyle_questions: proposer.lifestyle_questions,
      })
    }
    Object.values(memberDetails).forEach((member) => {
      if (member.lifestyle_questions.length !== 0) {
        membersArr.push({
          label: member.firstname,
          value: member.code,
          quoteNumber: member.selectedQuoteNumber,
          code: member.code,
          lifestyle_questions: member.lifestyle_questions,
        })
      }
    })

    return membersArr
  }

  const { isDesktop } = useDeviceType()
  const [selectedMembers, setSelectedMembers] = useState<
    {
      label: string
      value: string
      quoteNumber: string
      code: string
      lifestyle_questions: GetQuestionsListingResponse[]
    }[]
  >([...getMembersDataWithDisease()])
  const navigate = useNavigate()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const completeMemberDetails = useAppSelector((state) => state.memberDetails)
  const { cartDetails, selectedQuotes, totalAddedMembers } = useAppSelector((state) => state.cart)

  const [showSPTaggingModal, setshowSPTaggingModal] = useState(false)
  const { businessrole_name, role_type } = useAppSelector((state) => state.user.authenticatedUser)
  const selectedSpData = useAppSelector((state) => state.spTagging)

  const onFormSubmitHandler = () => {
    if (businessrole_name === 'NSP') setshowSPTaggingModal(true)
    else {
      navigate('/health/payment')
    }
  }

  const dispatch = useAppDispatch()
  const [saveLeads, { isLoading: saveLeadsLoading, isError: saveLeadsError }] =
    useSaveLeadsMutation()

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',

      lead_data: {
        cart_details: cart_details,
        member_details: completeMemberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: selectedSpData,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('health-questions', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  useEffect(() => {
    createLead()
  }, [])

  const onNextClick = () => {
    if (selectedMembers.length === currentTabIndex + 1) {
      props.onFormSubmit()
      // navigate('/health/payment')
      onFormSubmitHandler()
      createLead()
    } else {
      setCurrentTabIndex((prevState) => prevState + 1)
    }
  }

  const getMembersArray = () => {
    const membersArr = []
    if (isProposerIncluded) {
      membersArr.push({
        label: 'Self',
        value: 'Self',
        quoteNumber: proposer.selectedQuoteNumber!,
        code: '',
        lifestyle_questions: proposer.lifestyle_questions,
      })
    }
    getArrayFromObj(memberDetails)
      .filter((member) => totalAddedMembers.includes(member.member_type))
      .forEach((member) => {
        membersArr.push({
          label: member.firstname,
          value: member.code,
          quoteNumber: member.selectedQuoteNumber,
          code: member.code,
          lifestyle_questions: member.lifestyle_questions,
        })
      })
    return membersArr
  }

  useEffect(() => {
    if (!lifeStyleQues) {
      setSelectedMembers([])
    }
  }, [lifeStyleQues])

  return (
    <>
      <Box>
        <Box p='4'>
          <Text my='2'>Does any insured member Smoke/Consume Alcohol/Tobacco?</Text>
          <Flex w='full' gap='2' direction={isDesktop ? 'row' : 'column'}>
            <Box w='full'>
              <ToggleSwitch enabled={lifeStyleQues} setEnabled={setlifeStyleQues} />
            </Box>
            {lifeStyleQues && (
              <Box w='full'>
                <ChakraSelect
                  maxMenuHeight={120}
                  selectedOptionStyle='check'
                  isMulti={true}
                  placeholder='Select Members'
                  onChange={(item) => {
                    // setValue('members', item)
                    if (item.length === 0) {
                      setCurrentTabIndex(0)
                    } else if (selectedMembers.length > item.length && currentTabIndex !== 0) {
                      setCurrentTabIndex((prev) => prev - 1)
                    }
                    setSelectedMembers([...item])
                  }}
                  options={getMembersArray()}
                />
              </Box>
            )}
          </Flex>
          {selectedMembers.length === 0 && <Spacer h='24' />}
        </Box>
        {/* questions and tab section */}
        {lifeStyleQues && (
          <Box w='full'>
            <Tabs
              index={currentTabIndex}
              isFitted
              variant='enclosed-colored'
              isLazy={true}
              borderTop='2px solid primary'
              size={isDesktop ? 'md' : 'sm'}
            >
              <TabList w='full'>
                <Flex overflowX='scroll' py='0.5'>
                  {selectedMembers.map((member, index) => {
                    return (
                      <Tab
                        _selected={{ color: 'white', bg: 'primary.200' }}
                        fontSize='18px'
                        key={member.label}
                        onClick={() => {
                          if (index < currentTabIndex) {
                            setCurrentTabIndex(index)
                          }
                        }}
                      >
                        <Box w='48'>{member.label}</Box>
                      </Tab>
                    )
                  })}
                </Flex>
              </TabList>
              <TabPanels>
                {selectedMembers.map((member) => {
                  // console.log(member)
                  return (
                    <TabPanel p='0' key={member.value}>
                      <QuestionContainer
                        onNextClick={onNextClick}
                        quoteNumber={member.quoteNumber}
                        hasPreExistingDiseases={lifeStyleQues}
                        selectedMembers={member}
                        storedQuestions={member.lifestyle_questions}
                        setShowLifeStyleQuestions={props.setShowLifeStyleQuestions}
                      />
                    </TabPanel>
                  )
                })}
              </TabPanels>
            </Tabs>
          </Box>
        )}
        {!lifeStyleQues && (
          <>
            <Flex direction={'column'}>
              <Box height={'440px'} p='4'>
                <Text textAlign={'center'}>
                  Insured Members does not consume Smoke/Alcohol/Tobacco.
                </Text>
              </Box>

              <Box roundedBottomRight='2xl' boxShadow='0px -2px 6px #00000029' bg='white'>
                <Flex alignItems={'center'} justifyContent={'center'}>
                  <Button
                    my='4'
                    variant='solid'
                    colorScheme={'primary'}
                    w='20%'
                    mx={'auto'}
                    rounded={'full'}
                    onClick={() => {
                      onFormSubmitHandler()
                      dispatch(
                        updateProposer({
                          data: {
                            ...proposer,
                            lifestyle_questions: [],
                          },
                          isProposerIncluded: isProposerIncluded,
                        }),
                      )

                      Object.keys(memberDetails).forEach((memberCode) => {
                        dispatch(
                          updateMember({
                            code: memberCode,
                            memberDetail: {
                              ...memberDetails[memberCode],
                              lifestyle_questions: [],
                            },
                          }),
                        )
                      })
                      createLead()
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </>
        )}
        <Modal isOpen={showSPTaggingModal} onClose={() => setshowSPTaggingModal(false)}>
          {businessrole_name === 'NSP' && <ProposalSpTaggingForm />}
        </Modal>
      </Box>
    </>
  )
}

const QuestionContainer = ({
  onNextClick,
  quoteNumber,
  hasPreExistingDiseases,
  selectedMembers,
  storedQuestions,
  setShowLifeStyleQuestions,
}: {
  onNextClick: any
  quoteNumber: any
  hasPreExistingDiseases: any
  selectedMembers: {
    label: string
    value: string
    quoteNumber: string
    code: string
  }
  setShowLifeStyleQuestions: React.Dispatch<React.SetStateAction<boolean>>
  storedQuestions: GetQuestionsListingResponse[]
}) => {
  const { selectedQuotes } = useAppSelector((state) => state.cart)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { memberDetails, isProposerIncluded, proposer } = useAppSelector(
    (state) => state.memberDetails,
  )

  const updateSubQuestionValue = (
    item: QuestionBody,
    code: string,
    values: { [key: string]: string },
  ) => {
    if (values[code]) item.value = values[code]
    item.sub_ques.forEach((subQues) => {
      updateSubQuestionValue(subQues, code + '$' + subQues.code, values)
    })
  }

  const checkforKnockoutQuestion = (sub_ques: QuestionBody[]): boolean => {
    for (let i = 0; i < sub_ques.length; i++) {
      const ques = sub_ques[i]
      if (ques.type === 'knockout' && ques.value == '1') {
        return true
      }
      if (checkforKnockoutQuestion(ques.sub_ques)) {
        return true
      }
    }
    return false
  }

  const addResponseKey = (sub_ques: QuestionBody[]) => {
    sub_ques.forEach((ques) => {
      addResponseKey(ques.sub_ques)
      if (ques.type === 'date') {
        ques.response = ques.value.split('-').reverse().join('/')
      } else {
        ques.response = ques.value
      }
    })
  }

  const onFormSubmit = (values: { [key: string]: string }) => {
    console.log(values)

    if (lifestyleQuestionsData) {
      const updatedQuestion: GetQuestionsListingResponse[] = JSON.parse(
        JSON.stringify(lifestyleQuestionsData),
      )

      updatedQuestion
        .filter((question) => question.question_type === 'lifestyle')
        ?.forEach((ques) => {
          const code = ques.code
          ques.value = values[code]
          ques.sub_ques.forEach((subQues) => {
            updateSubQuestionValue(subQues, code + '$' + subQues.code, values)
          })
        })

      let shouldProceedFurther = true
      updatedQuestion
        .filter((question) => question.question_type === 'lifestyle')
        ?.forEach((ques) => {
          if (ques.type === 'knockout' && ques.value == '1') {
            ToastService.showErrorToast({ title: 'You cannot proceed further!' })
            shouldProceedFurther = false
            return
          }
          if (ques.sub_ques.length > 0) {
            if (checkforKnockoutQuestion(ques.sub_ques) == true) {
              ToastService.showErrorToast({
                title: 'You cannot proceed further!',
                message: 'Sorry! We’re unable to issue the policy due to adverse health',
              })
              shouldProceedFurther = false
              return
            }
          }
        })
      if (!shouldProceedFurther) {
        // navigate('/health/proposal/proposer-insurer-details')
        setShowLifeStyleQuestions(false)
        return
      }

      if (selectedMembers.value.toLowerCase() === 'self') {
        dispatch(
          updateProposer({
            data: {
              ...proposer,
              lifestyle_questions: hasPreExistingDiseases
                ? updatedQuestion
                    .filter((question) => question.question_type === 'lifestyle')
                    .map((ques) => {
                      addResponseKey(ques.sub_ques)
                      return {
                        ...ques,
                        response:
                          ques.type === 'date'
                            ? ques.value.split('-').reverse().join('/')
                            : ques.value,
                      }
                    })
                : [],
            },
            isProposerIncluded: isProposerIncluded,
          }),
        )
      } else {
        dispatch(
          updateMember({
            code: selectedMembers.code,
            memberDetail: {
              ...memberDetails[selectedMembers.code],
              lifestyle_questions: hasPreExistingDiseases
                ? updatedQuestion
                    .filter((question) => question.question_type === 'lifestyle')
                    .map((ques) => {
                      addResponseKey(ques.sub_ques)
                      return {
                        ...ques,
                        response:
                          ques.type === 'date'
                            ? ques.value.split('-').reverse().join('/')
                            : ques.value,
                      }
                    })
                : [],
            },
          }),
        )
      }
    }
    onNextClick()
  }
  const onFormError = (errors: any) => {
    console.log(errors)
  }

  const getInsurerNumber = () => {
    if (selectedQuotes[quoteNumber]) {
      return selectedQuotes[quoteNumber].insuranceNumber.toString()
    } else return ''
  }

  const { control, formState, handleSubmit, register, watch, getValues, setValue } = useForm()
  const { errors, touchedFields } = formState

  const {
    data: lifestyleQuestionsData,
    error: lifestyleQuestionError,
    isFetching: lifestyleQuestionFetching,
  } = useGetQuestionListingQuery({
    insurance_id: getInsurerNumber(),
  })
  // console.log(lifestyleQuestionsData, 'dddddd')
  const changeQuestionCode = (
    parentCode: string,
    node: QuestionBody,
    storedSubQuestion: QuestionBody | null,
  ) => {
    node.code = parentCode + '$' + node.code

    if (storedSubQuestion) {
      node.value = storedSubQuestion.response
    }
    if (node.sub_ques.length > 0) {
      node.sub_ques.forEach((subQues, index) => {
        if (storedSubQuestion) {
          changeQuestionCode(node.code, subQues, storedSubQuestion.sub_ques[index])
        } else {
          changeQuestionCode(node.code, subQues, null)
        }
      })
    }
    return
  }
  const changeQuestionFormat = (questions: GetQuestionsListingResponse[]) => {
    // console.log(questions, 'initial  question')

    const tempQuestions: GetQuestionsListingResponse[] = JSON.parse(JSON.stringify(questions))
    tempQuestions
      .filter((question) => question.question_type === 'lifestyle')
      .forEach((question, quesIndex) => {
        if (storedQuestions[quesIndex]) {
          storedQuestions[quesIndex].response
          question.value = storedQuestions[quesIndex].response
        }
        if (question.sub_ques.length > 0) {
          question.sub_ques.forEach((subQues, index) => {
            if (storedQuestions[quesIndex] && storedQuestions[quesIndex].sub_ques[index]) {
              changeQuestionCode(question.code, subQues, storedQuestions[quesIndex].sub_ques[index])
            } else {
              changeQuestionCode(question.code, subQues, null)
            }
          })
        }
      })
    // console.log(tempQuestions, 'ddddddddd')
    return tempQuestions
  }

  return (
    <form className='h-full' onSubmit={handleSubmit(onFormSubmit, onFormError)}>
      <Grid gap='6' templateColumns={'repeat(1, 1fr)'}>
        {/* FIXME */}
        {lifestyleQuestionsData && (
          <>
            <Box height={'390px'} p='4' overflowY='scroll'>
              <RecursiveContainer
                questions={changeQuestionFormat(lifestyleQuestionsData)}
                register={register}
                errors={errors}
                touchedFields={touchedFields}
                getValues={getValues}
                watch={watch}
                control={control}
                setValue={setValue}
              />
            </Box>
          </>
        )}
        {/* {JSON.stringify(lifestyleQuestionsData)}
        {lifestyleQuestionsData && lifestyleQuestionsData.filter(item=>item.question_type==='lifestyle')} */}
        {lifestyleQuestionFetching && <Spinner></Spinner>}
        {!lifestyleQuestionsData && <Text p='10'>No Lifestyle Questions</Text>}
      </Grid>
      <Box roundedBottomLeft='2xl' boxShadow='0px -2px 6px #00000029'>
        <ButtonComponent
          onLeftButtonClick={() => navigate('../proposer-insurer-details')}
          left='Back'
          right='Next'
        />
      </Box>
    </form>
  )
}
const RecursiveContainer = ({
  questions,
  register,
  errors,
  touchedFields,
  getValues,
  watch,
  control,
  setValue,
}: {
  questions: GetQuestionsListingResponse[]
  register: any
  errors: any
  touchedFields: any
  getValues: any
  watch: any
  control: any
  nested?: boolean
  setValue: UseFormSetValue<FieldValues>
}) => {
  const { proposalDetails: proposalDetailsInStore } = useAppSelector((state) => state.proposalPage)

  const { clearErrors } = useForm()
  const currentDate = new Date()
  function converNumberFormator(d: number) {
    return d < 10 ? '0' + d.toString() : d.toString()
  }

  const oneYearsFromCurrentDate =
    currentDate.getFullYear() +
    1 +
    '-' +
    (converNumberFormator(currentDate.getMonth()) + 1) +
    '-' +
    converNumberFormator(currentDate.getDate())
  const builder = (question: GetQuestionsListingResponse) => {
    switch (question.type) {
      case 'text':
        return (
          <Box key={question.code}>
            <Controller
              name={question.code}
              control={control}
              rules={{ required: true }}
              defaultValue={question.value}
              render={({ field }) => (
                <FormControl isInvalid={errors[question.code] ? true : false}>
                  <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                  <TextInput type='text' {...register(question.code)} />
                  {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                </FormControl>
              )}
            />
          </Box>
        )
      case 'date':
        return (
          <Box key={question.code}>
            <Controller
              name={question.code}
              control={control}
              rules={{ required: true }}
              defaultValue={question.value}
              render={({ field }) => (
                <FormControl isInvalid={errors[question.code] ? true : false}>
                  <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                  <input
                    type='date'
                    onKeyDown={(e) => e.preventDefault()}
                    className='outline-none border text-sm px-3 bg-[#E2E8F0] w-36 py-1 rounded-sm '
                    {...register(question.code, { required: true })}
                    onChange={(e) => {
                      setValue(question.code, e.target.value)
                      clearErrors(question.code)
                    }}
                    clearErrors={() => clearErrors('dob')}
                    min={
                      question.main_question.toLowerCase().includes('expected')
                        ? new Date().toISOString().slice(0, 10)
                        : ''
                    }
                    max={
                      question.main_question.toLowerCase().includes('expected')
                        ? oneYearsFromCurrentDate
                        : new Date().toISOString().slice(0, 10)
                    }
                  />
                  {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                </FormControl>
              )}
            />
          </Box>
        )
      case 'bool':
        return (
          <Box>
            <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
            <Controller
              name={question.code}
              control={control}
              rules={{ required: true }}
              defaultValue={question.value}
              render={({ field }) => (
                <FormControl isInvalid={errors[question.code] ? true : false}>
                  <RadioGroup onChange={(value) => field.onChange(value)} value={field.value}>
                    <Stack direction='row'>
                      <Radio value='1'>Yes</Radio>
                      <Radio value='0'>No</Radio>
                    </Stack>
                  </RadioGroup>
                  {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                </FormControl>
              )}
            />
          </Box>
        )
      case 'dropdown':
        return (
          <>
            <Box key={question.code}>
              <Controller
                name={question.code}
                control={control}
                rules={{ required: true }}
                defaultValue={{
                  lable: question.value,
                  value: question.value,
                }}
                render={({ field }) => (
                  <FormControl isInvalid={errors[question.code] ? true : false}>
                    <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                    <ChakraSelect
                      {...register(question.code)}
                      options={question.multi_value_list?.map((value: any) => {
                        return {
                          label: value.toString(),
                          value: value.toString(),
                        }
                      })}
                      onChange={(item: any) => setValue(question.code, item.value)}
                    />
                    {/* {console.log(question.value, 'field.value')} */}

                    {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                  </FormControl>
                )}
              />
            </Box>
          </>
        )
      case 'multi-select':
        return (
          <>
            <Box key={question.code}>
              <Controller
                name={question.code}
                control={control}
                rules={{ required: true }}
                defaultValue={{
                  lable: question.value,
                  value: question.value,
                }}
                render={({ field }) => (
                  <FormControl isInvalid={errors[question.code] ? true : false}>
                    <FormLabel htmlFor={question.code}>{question.main_question}</FormLabel>
                    <ChakraSelect
                      {...register(question.code)}
                      isMulti
                      options={question.value.map((value: any) => {
                        return {
                          label: value.toString(),
                          value: value.toString(),
                        }
                      })}
                      onChange={(item: any) => setValue(question.code, item.value)}
                    />
                    {/* {console.log(question.value, 'field.value')} */}

                    {errors[question.code] && <FormErrorMessage>Required!</FormErrorMessage>}
                  </FormControl>
                )}
              />
            </Box>
          </>
        )
      case 'knockout':
        return (
          <>
            <Checkbox
              value={question.main_question}
              key={question.code}
              onChange={(e) => {
                // console.log(e.target.checked, '----------ddd-------')
                setValue(question.code, e.target.checked ? 1 : 0)
                // ToastService.showErrorToast({
                //   title: 'Sorry! We’re unable to issue the policy due to adverse health',
                // })
              }}
            >
              <Text>{question.main_question}</Text>
            </Checkbox>
          </>
        )
      default:
        return <Text>Unsupported field</Text>
    }
  }

  return (
    <>
      {questions.filter((question) => question.question_type === 'lifestyle').length === 0 && (
        <Text>No Lifestyle Questions Found</Text>
      )}
      {questions
        .filter((question) => question.question_type === 'lifestyle')
        .map((question) => {
          const watchShowAge = watch(question.code, question.value)
          return (
            <Box key={question.code} borderBottom='1px' py='2'>
              {builder(question)}
              {question.sub_ques.length > 0 && watchShowAge === '1' && (
                <Box ml='6'>
                  <RecursiveContainer
                    questions={question.sub_ques || []}
                    register={register}
                    errors={errors}
                    touchedFields={touchedFields}
                    getValues={getValues}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                  />
                </Box>
              )}
            </Box>
          )
        })}
    </>
  )
}
