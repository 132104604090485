import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Select } from 'chakra-react-select'
import { ElementWithLabel, If } from 'Core'
import { useEffect, useState } from 'react'
import { HiOutlineFilter } from 'react-icons/hi'

import { NestedForm } from 'Core/NestedForm'
import { withFormGrid } from 'Core/withFormGrid'
import { useLifeQuotation } from './useLifeQuotation'
import { useNavigate } from 'react-router-dom'
import { updateFilter } from 'features/Life/PlanData.slice'
import { calculateAgeFromDob } from 'Services/general'

const FilterDialog = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const [initialValues, setInitialValues] = useState<Record<string, any>>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const lifeCustomerData = useAppSelector((state) => state.lifeCustomerDetails)
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)
  const dob = lifeCustomerData.lifeAssuredDetailsTyped?.dob
  const age = calculateAgeFromDob(new Date(dob ?? ''))
  const {
    planOptionQuestionList,
    form,
    onFormSubmit,
    filterFormData,
    isGetQuotationsApiLoading,
    isPlanOptionQuestionListLoading,
    authenticatedUser,
    // spUserListing,
    onAffect,
    selected_plan,
  } = useLifeQuotation()

  useEffect(() => {
    if (!isOpen) {
      dispatch(updateFilter(undefined))
    }
  }, [isOpen])

  const getFinalInitialValues = () => {
    if (selected_plan?.code === 'saral-bachat') {
      setInitialValues({
        PremiumType: 'Limited',
        PlanTerm: '15',
        PremiumPayingTerm: '7',
        PremiumFrequency: 'Yearly',
        InstallmentPremiumAmount: '20000',
        DeathBenefit: 'Lumpsum',
        IsExistingCustomer: 'False',
        ...filterFormData,
      })
      return
    }
    setInitialValues({
      ...filterFormData,
      entry_age: Math.floor(age),
      consume_tobacco: lifeCustomerData.lifeAssuredSmokerStatus === 'Smoker' ? 'Yes' : 'No',
    })
  }

  useEffect(() => {
    getFinalInitialValues()
  }, [filterFormData])

  return (
    <Box>
      <Modal closeOnOverlayClick={false} onClose={onClose} size='5xl' isOpen={isOpen}>
        <ModalOverlay backdropFilter='blur(8px)' />
        <ModalContent rounded={'xl'}>
          <ModalHeader roundedTopLeft={'xl'} roundedTopRight={'xl'} bgColor='#f7f7f7'>
            <Center gap='0.5rem'>
              <Icon as={HiOutlineFilter} />
              <Text>Filters ({lifeQuotationDetails.finalSelectedPlan?.name})</Text>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody boxShadow='inner'>
            <form onSubmit={form.handleSubmit(onFormSubmit)}>
              {isPlanOptionQuestionListLoading && (
                <Center minHeight={100}>
                  <Spinner />
                </Center>
              )}
              {planOptionQuestionList && planOptionQuestionList.length ? (
                <>
                  {withFormGrid(
                    <>
                      {NestedForm({
                        fieldsData: planOptionQuestionList,
                        form: form,
                        initialValues: initialValues,
                        // {
                        //   ...filterFormData,
                        //   entry_age: Math.floor(age),
                        //   consume_tobacco:
                        //     lifeCustomerData.lifeAssuredSmokerStatus === 'Smoker' ? 'Yes' : 'No',
                        // },
                        // initialValues: initialValues,
                        disabledCodes: ['entry_age', 'consume_tobacco'],
                        onAffect: onAffect,
                      })}
                    </>,
                  )}
                </>
              ) : (
                <>
                  {!isPlanOptionQuestionListLoading && planOptionQuestionList?.length === 0 ? (
                    <Center minHeight={100}>
                      <Text>No Question Found!</Text>
                    </Center>
                  ) : null}
                </>
              )}
              {/* {planOptionQuestionList && planOptionQuestionList.length ? ( */}
              <Center padding={'1rem'}>
                <Button
                  type='submit'
                  rounded='sm'
                  isLoading={isGetQuotationsApiLoading}
                  variant='solid'
                  flexBasis='100%'
                  // onClick={() => {
                  //   navigate('/after-quotation-page')
                  // }}
                >
                  Proceed to Quotation
                </Button>
              </Center>
              {/* ) : null} */}
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export { FilterDialog }
