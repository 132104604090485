import { CalendarIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { TextInput } from 'Core/TextInput'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import { IBimabossDatePicker } from './BimabossDatePickerProps'
import styles from './index.module.css'

// TODO: feat: allow user to type date from input
const BimabossDateRangePicker = (props: IBimabossDatePicker) => {
  const {
    inputDate,
    setInputDate,
    minDate = new Date('1950/12/10'),
    maxDate = new Date(),
    getDate,
    ...rest
  } = props

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (selectedDateRange?.from && selectedDateRange?.to) {
      setInputDate(
        format(selectedDateRange.from, 'dd/MM/yyyy') +
          ' - ' +
          format(selectedDateRange.to, 'dd/MM/yyyy'),
      )
    } else setInputDate('')
  }, [selectedDateRange])

  return (
    <>
      <InputGroup onClick={onOpen}>
        <TextInput
          value={inputDate}
          isReadOnly
          onChange={(e) => setInputDate(e.target.value)}
          placeholder='Enter date range'
          onKeyDown={(e) => {
            if (e.key === 'Enter') onOpen()
          }}
          {...rest}
        />
        <InputRightElement zIndex='0' cursor='pointer'>
          <CalendarIcon color='secondary.500' />
        </InputRightElement>
      </InputGroup>
      <Modal size='sm' isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex justifyContent='center'>
              <DayPicker
                className={styles.bimabossDatePicker}
                captionLayout='dropdown'
                mode='range'
                selected={selectedDateRange}
                onSelect={(d: DateRange | undefined) => {
                  setSelectedDateRange(d)
                }}
                fromDate={minDate}
                toDate={maxDate}
                modifiersStyles={{
                  selected: {
                    color: '#fff',
                    backgroundColor: '#4b9cd8',
                  },
                }}
                styles={{
                  caption: { color: '#4b9cd8' },
                  cell: { padding: '0.3rem' },
                }}
              />
            </Flex>
          </ModalBody>
          <ModalFooter paddingTop='0'>
            <Button
              variant='outline'
              marginX='2'
              colorScheme='red'
              onClick={() => setSelectedDateRange(undefined)}
            >
              Clear
            </Button>
            <Button marginX='2' colorScheme='blue' onClick={onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export { BimabossDateRangePicker }
