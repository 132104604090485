import { BoxProps, FormControl, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { ElementWithLabel, ErrorText } from 'Core'
// import { INestedQuestionSet } from 'Models/BOB/ResponseModels/Life/QuestionSet'
import { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import RenderChild from './RenderChild'
import { BbSwitchRadio } from 'Core/BbSwitchRadio'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'

interface IProps extends BoxProps {
  isRoot: boolean
  parentData: { name: string; value: string | number }
  name: string
  label?: string
  childrenData?: Record<string, INestedQuestionSet[]>
  form: UseFormReturn<any, any>
  initialValues?: Record<string, string | number | boolean | string[]>
  defaultValue?: string
  options: string[]
  isDisabled?: boolean
  disabledCodes?: string[]
}

const RenderRadioSwitch = (props: IProps) => {
  const {
    name,
    childrenData,
    form,
    isRoot,
    options,
    parentData,
    label,
    initialValues,
    defaultValue,
    isDisabled,
    disabledCodes,
    ...rest
  } = props

  // const [switchValue, setSwitchValue] = useState(booleanSwitchOptions[1])
  const [switchValue, setSwitchValue] = useState<string>()

  const handleChange = (newVal: string) => {
    form.setValue(name, newVal)

    setSwitchValue(newVal)
  }

  useEffect(() => {
    if (initialValues && initialValues[name]) {
      const newValue = initialValues[name] as string
      if (newValue) handleChange(newValue)
    }
  }, [initialValues])

  /**
   * This will set the initial value of dropdown as given in the `default` key if it exists in dropdown array
   */
  useEffect(() => {
    if (defaultValue !== undefined) {
      const newValue: string | undefined = options.find(
        (item) => item.toString() === defaultValue.toString(),
      )
      // console.log(newValue, 'aassdd')

      if (newValue) {
        handleChange(newValue)
      }
    }
  }, [])

  return (
    <>
      <ElementWithLabel
        required
        marginY='0.7rem'
        height='fit-content'
        label={label || name}
        {...rest}
      >
        <BbSwitchRadio
          form={form}
          name={name}
          label={label}
          option1={options[0]}
          option2={options[1]}
          onToggle={handleChange}
          isDisabled={
            isDisabled ||
            (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false)
          }
        />
      </ElementWithLabel>

      {childrenData &&
        Object.keys(childrenData)?.length > 0 &&
        switchValue &&
        childrenData[switchValue] &&
        childrenData[switchValue].map((item, index) => {
          return (
            <RenderChild
              key={index}
              childField={item}
              form={form}
              parentData={{
                name: name,
                value: switchValue,
              }}
              defaultValue={item.default}
              initialValues={initialValues}
            />
          )
        })}
    </>
  )
}

export { RenderRadioSwitch }
