import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  Spinner,
  Modal,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useAppSelector, useDeviceType, useAppDispatch } from 'app/hooks'
import { CommonImages } from 'Assets/Common'
import { TextInput } from 'Core'
import { useEffect, useState } from 'react'
import { ChakraStylesConfig, Select } from 'chakra-react-select'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  getAgeFromDob,
  getAccountTypeBasedOnIndex,
  onlyAllowTypingNumbers2,
  calculateAgeFromDob,
  getAgeObjFromDob2,
  decryptData,
  onlyAllowTypingAlphabet,
} from 'Services/general'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ToastService } from 'Services/toast.service'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  useLazyGetCustomerDetailsQuery,
  useLazySearchCustomerQuery,
} from 'Services/API/equitas.api'
import { TCustomerDetails } from 'Models/RequestModels/Equitas'
import {
  customerDetailsErrorHandler,
  customerDetailsLoadingHandler,
  customerDetailsSuccessHandler,
  customerSearchErrorHandler,
  customerSearchLoadingHandler,
  customerSearchSuccessHandler,
} from 'Pages/Motor/Equitas/CustomerAccountDetails/Forms/CbsUtils'
import {
  resetLifeCustomerDetails,
  updateAcceptedSalutation,
  updateLifeAssuredAccount,
  updateLifeAssuredDetails,
  updateLifeAssuredPan,
  updateLifeProposalDetails,
  updateUCICId,
} from 'features/Life/LifeCustomerDetails.life.slice'
import { customerLifeDetailsSuccessHandler } from 'Pages/Life/LifeUtils'
import { useLazyGetChoicesQuery } from 'Services/API/life.api'
import { ListingResponse } from 'Models/ResponseModels/Life'
import { panNumRegex } from 'Constants'
import {
  updateRelationship,
  updateSuitabilityData2,
  updateSuitabilityStep,
} from 'features/Life/LifeQuotationDetails.slice'
import { ICustomerDetails, ICustomerSearchDetails } from 'Models/ResponseModels/Equitas'
import { EditIcon } from '@chakra-ui/icons'
import { updateProposalDetailsFromCBS } from 'features/Equitas'
import { updateLifeCustomerLastName } from 'features/Equitas/CustomerDetails.equitas.slice'

const {
  HomeImages: { life },
} = CommonImages
const chakraStyles: ChakraStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '5px 0px 0px 5px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px 8px',
    fontSize: '12px',
  }),
  dropdownIndicator: (provided, state) => ({
    margin: '0px',
  }),
}

const HomeLifeForm = () => {
  // const { customerAccountStatus, accounts, selectedAccountId, customerDetailsStatus } =
  //   useAppSelector((state) => state.customerDetails)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const [relationshipListing, setRelationshipListing] = useState<ListingResponse[]>([])
  const [lastName, setLastName] = useState('')

  const { isDesktop, isMobile, isTablet } = useDeviceType()
  const { proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)

  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()

  const dispatch = useAppDispatch()

  const [
    getChoices,
    { isLoading: choicesLoading, isError: choicesError, isSuccess: choicesSuccess },
  ] = useLazyGetChoicesQuery()

  useEffect(() => {
    initializeChoices()
    dispatch(updateSuitabilityStep('one'))
    dispatch(updateSuitabilityData2(undefined))
  }, [])

  const initializeChoices = async () => {
    try {
      const response = await getChoices({ relationship: 1 }).unwrap()
      setRelationshipListing(response['relationship'])
    } catch (e) {
      setRelationshipListing([])
    }
  }

  // const getCBSProposerAge = () => {
  //   if (proposerDetailsFromCBS)
  //     if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.dob) {
  //       return Math.trunc(
  //         getAgeFromDob(new Date(proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.dob)),
  //       ).toString()
  //     }
  //   return ''
  // }

  const getCBSGender = () => {
    if (proposerDetailsFromCBS)
      if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.gndr) {
        if (proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp.gndr === 'M') return true
        else return false
      }

    return true
  }
  // const [isMale, setIsMale] = useState(getCBSGender)

  // Infer the TS type according to the zod schema.
  type LifeForm = {
    relationship: {
      name: string
      code: string
    }
    showUcic: boolean
    showPAN: boolean
    ucic: string
    pan: string
  }
  const lifeAssuredSchema = yup.object({
    relationship: yup.object({
      name: yup.string(),
      code: yup.string().required('Relationship is required!'),
    }),
    showUcic: yup.boolean(),
    showPAN: yup.boolean(),
    ucic: yup.string().when('showUcic', {
      is: true,
      then: yup.string().optional(),
      // .min(7, 'Length should be greater than 7')
      // .max(9, 'Length should be less than 9'),
    }),
    pan: yup.string().matches(panNumRegex, 'Invalid Format'),
    // .when('showPAN', {
    //   is: true,
    //   then: yup.string().required('PAN Number is required').matches(panNumRegex, 'Invalid Format'),
    // }),
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting, isSubmitted, isDirty, isValid },
  } = useForm<LifeForm>({
    resolver: yupResolver(lifeAssuredSchema),
    defaultValues: {
      relationship: { name: '', code: '' },
      ucic: '',
      showUcic: true,
      showPAN: false,
    },
  })

  const [isChecked, setIscheckbox] = useState(false)
  const [cardComp, setCardComp] = useState(false)
  const [editVisible, setEditVisible] = useState(false)

  // const isSelected = true
  const [selectedCard, setSelectedCard] = useState<number>()

  const clickHandler = () => {
    navigate('/personal-details')
  }

  useEffect(() => {
    dispatch(resetLifeCustomerDetails())
    if (
      !customerDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp![0]?.lstNm
    ) {
      onOpen()
      setEditVisible(true)
    } else {
      setEditVisible(false)
    }
  }, [])

  const onFormError = (errors: any) => {
    console.log(errors, 'eerrr')
  }

  const ucicIdInStore = useAppSelector((state) => state.lifeCustomerDetails.ucic)
  const [searchCustomer, { data: customerPersonalData, isFetching: customerSearchLoading }] =
    useLazySearchCustomerQuery()
  const [getCustomerDetails, { data: customerDetailsAPIData, isFetching: customerDetailsLoading }] =
    useLazyGetCustomerDetailsQuery()
  const customerDetails = useAppSelector((state) => state.customerDetails)
  //  console.log(getValues('relationship.name'), 'relationshiprelationship.name')

  const onFormSubmit: SubmitHandler<any> = async (data) => {
    const relation = data.relationship.name
    try {
      customerSearchLoadingHandler(dispatch)
      const params: TCustomerDetails = {}
      params['ucic'] =
        getValues('relationship.name') === 'Self' ? equitasDetails?.ucic ?? '' : data.ucic ?? ''
      if (
        (data.ucic && getValues('relationship.name') !== 'Self') ||
        getValues('relationship.name') === 'Self'
      ) {
        const { data: searchResponse } = await searchCustomer(params)
        const u: ICustomerSearchDetails = JSON.parse(decryptData(searchResponse?.data))
        if (getValues('relationship.name') !== 'Self') {
          if (u && u.accounts.length !== 0) {
            customerDetailsLoadingHandler(dispatch)
            const customerDetails = await getCustomerDetails({
              ucic: data.ucic,
            }).unwrap()
            const v: ICustomerDetails = JSON.parse(decryptData(customerDetails.data)) // cbs v3 api call
            if (v.accDtls && v.accDtls.accDtlsRp && v.accDtls.accDtlsRp.length > 0) {
              dispatch(updateLifeAssuredDetails(v)) // cbs v3 action
              // dispatch(setCustomerDetailsStatus('success'))
              // customerDetailsSuccessHandler(dispatch, customerDetails)
              // customerSearchSuccessHandler(dispatch, searchResponse, customerDetails)
              // dispatch(updateLifeAssuredDetails)
              customerLifeDetailsSuccessHandler(dispatch, v!, data.ucic!)
              dispatch(
                updateUCICId({ ucic: data.ucic, is_same: false, relationship: relation } ?? ''),
              )
              dispatch(updateRelationship(relation))
            } else {
              throw Error('Cannot fetch account details!')
            }
          } else {
            throw Error('No accounts Found!')
          }
        } else {
          // customerDetailsLoadingHandler(dispatch)
          // customerDetailsSuccessHandler(dispatch, proposerDetailsFromCBS!)
          // customerSearchSuccessHandler(dispatch, searchResponse!, proposerDetailsFromCBS!)
          dispatch(updateLifeAssuredDetails(equitasDetails.proposerDetailsFromCBS!))
          // customerLifeDetailsSuccessHandler(dispatch, proposerDetailsFromCBS!, equitasDetails.ucic!)
          dispatch(
            updateUCICId({ ucic: equitasDetails?.ucic, is_same: true, relationship: 'Self' } ?? ''),
          )
          dispatch(updateRelationship(relation))
        }
      } else if (!data.ucic && getValues('relationship.name') !== 'Self') {
        dispatch(updateUCICId({ ucic: data.ucic, is_same: false, relationship: relation } ?? ''))
        dispatch(updateRelationship(relation))
      }
      dispatch(updateLifeAssuredPan(data.pan))
      navigate('/personal-details')
    } catch (e: any) {
      customerDetailsErrorHandler(dispatch)
      customerSearchErrorHandler(dispatch)
      ToastService.showErrorToast({
        title: 'Error',
        message: e?.message ?? 'No Accounts found!',
      })
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
        <Box w={isDesktop ? '' : 'full'}>
          <Flex w={isDesktop ? '' : 'full'} pt='2px'>
            <Box bgColor={'#E9F0F5'} float={'left'} p='5' borderRadius={'0 0 0 20px'}>
              <Text fontWeight={'extrabold'}>Payor Details</Text>
              <Text mt='3' fontWeight={'semibold'} fontSize={'14px'}>
                Name:
              </Text>
              <Flex direction='row' justifyContent='space-between' alignItems='center'>
                <Text fontSize={'14px'} color={'#999999'}>
                  {`${
                    customerDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0]?.frsNm ?? ''
                  } ${
                    customerDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0]?.mdlNm ?? ''
                  } ${
                    customerDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0]?.lstNm ?? ''
                  }`}
                </Text>
                {editVisible && <EditIcon onClick={onOpen} />}
              </Flex>
              <Text mt='3' fontWeight={'semibold'} fontSize={'14px'}>
                Account Number:
              </Text>
              <Text fontSize={'14px'} color={'#999999'}>
                {/* {customerPersonalData?.accounts.toString()} */}
                {/* {customerDetails.selectedAccount} */}
                {customerDetails.proposerDetailsFromCBS?.accDtls.accDtlsRp[0]?.accNb}
              </Text>
              <Text mt='3' fontWeight={'semibold'} fontSize={'14px'}>
                Contact Number:
              </Text>
              <Text fontSize={'14px'} color={'#999999'}>
                {/* {customerDetails.fieldsFetchedFromCbsMapping.customerMobile} */}
                {
                  customerDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                    ?.IndCustDtlsRp![0]?.mblNb
                }
              </Text>
              <Text mt='3' fontWeight={'semibold'} fontSize={'14px'}>
                Email ID:
              </Text>
              <Text fontSize={'14px'} color={'#999999'}>
                {/* {customerDetails.fieldsFetchedFromCbsMapping.customerEmail} */}
                {customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                  ?.IndCustDtlsRp![0].email ?? 'NA'}
              </Text>
              <Text mt='3' fontWeight={'semibold'} fontSize={'14px'}>
                PAN Number:
              </Text>
              <Text fontSize={'14px'} color={'#999999'}>
                {/* {customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.panNb} */}
                {customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.panNb ?? '-'}
              </Text>
            </Box>
            {!cardComp &&
            getAgeObjFromDob2(
              new Date(customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob ?? ''),
            ) >= 18 ? (
              <Box>
                <Flex p='6' width={'100%'} gap='10' direction={isDesktop ? 'row' : 'column'}>
                  <Box>
                    <Text>Life Assured Details</Text>
                    <FormControl isInvalid={errors.relationship ? true : false} mt='15px'>
                      <FormLabel fontWeight={'bold'} fontSize={'14px'}>
                        Relationship with life assured
                      </FormLabel>
                      <Select
                        placeholder='Select Relationship'
                        options={relationshipListing.map((relationship) => {
                          return {
                            label: relationship.name,
                            value: relationship.code,
                            salutation: relationship.salutation,
                          }
                        })}
                        onChange={(item: any) => {
                          setValue('relationship', { name: item.label, code: item.value })
                          dispatch(
                            updateAcceptedSalutation(
                              item.salutation.length > 0 ? item.salutation.split(',') : [],
                            ),
                          )
                          clearErrors('relationship')
                          if (item.value === 'self') {
                            setValue('showUcic', false)
                            clearErrors('pan')
                            setIscheckbox(false)
                          } else {
                            setValue('showUcic', true)
                          }
                        }}
                      />
                      {errors.relationship && (
                        <FormErrorMessage>
                          {errors.relationship?.code?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    {getValues('relationship.code') !== 'self' && (
                      <Box>
                        <Box mt='5' width={'280px'}>
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) => {
                              setIscheckbox(e.target.checked)
                              setValue('showPAN', e.target.checked)
                              setValue('showUcic', !e.target.checked)
                              if (e.target.checked) {
                                setValue('ucic', '')
                              }
                            }}
                          >
                            Life assured is not an Equitas customer
                          </Checkbox>
                        </Box>
                        {isChecked ? (
                          <Box mt='2'>
                            <FormControl isInvalid={errors.pan ? true : false}>
                              <FormLabel fontWeight={'bold'} fontSize={'14px'}>
                                Enter PAN Number
                              </FormLabel>
                              <TextInput
                                width={'400px'}
                                mt='1'
                                // onChange={(e: any) => {
                                //   setValue('pan', e.target.value.trim().toUpperCase())
                                //     clearErrors('pan')
                                // }}
                                placeholder={'Enter PAN Details'}
                                {...(register('pan'),
                                {
                                  onChange: (e) => {
                                    setValue('pan', e.target.value.trim().toUpperCase())
                                    clearErrors('pan')
                                  },
                                })}
                              />
                              {errors.pan && (
                                <FormErrorMessage>
                                  {errors.pan?.message?.toString()}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Box mt={isDesktop ? '10' : '0'} fontWeight={'bold'} fontSize={'14px'}>
                      {getValues('relationship.code') !== 'self' && !isChecked && (
                        <FormControl
                          isInvalid={
                            getValues('relationship.code') !== 'self' && errors.ucic ? true : false
                          }
                        >
                          <FormLabel fontWeight={'bold'}>Life Assured UCIC ID</FormLabel>
                          <TextInput
                            placeholder={'Enter UCIC ID'}
                            {...(register('ucic'),
                            {
                              minLength: 7,
                              maxLength: 9,
                              onChange: (e) => {
                                setValue('ucic', e.target.value.trim().toUpperCase())
                              },
                            })}
                            minLength={7}
                            onKeyPress={onlyAllowTypingNumbers2}
                          />
                          {errors.ucic && getValues('relationship.code') !== 'self' && (
                            <FormErrorMessage>{errors.ucic?.message?.toString()}</FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Box>
                  </Box>
                </Flex>
                <Center my={'1rem'} width={'100%'}>
                  <Button
                    size='md'
                    variant='solid'
                    rounded='full'
                    type='submit'
                    w='44'
                    isLoading={customerSearchLoading}
                  >
                    Next
                  </Button>
                </Center>
              </Box>
            ) : (
              <Center w='full'>
                <Text>Journey not allowed as the user is below 18 years</Text>
              </Center>
            )}
            {cardComp && (
              <>
                <Box w={isDesktop ? '' : 'full'} padding={'5'} overflowY={'scroll'}>
                  <Text fontWeight={'bold'}>Select Account Details for Life Assured</Text>
                  <Text fontSize={'12px'} color={'#999999'}>
                    {customerPersonalData?.first_name} UCIC ID: {ucicIdInStore}
                  </Text>

                  <Flex direction={isDesktop ? 'row' : 'column'} overflowY={'scroll'}>
                    {customerDetailsLoading ? (
                      <Center>
                        <Spinner />
                      </Center>
                    ) : (
                      <>
                        {customerPersonalData?.accounts.map((item, idx) => (
                          <Box
                            mt='5'
                            key={idx}
                            mx='5'
                            width={'318px'}
                            color={'white'}
                            height={'201.45px'}
                            bgGradient={
                              selectedCard === idx
                                ? 'linear-gradient(112deg, #1C4589 0%, #73325C 57%, #CE202F 100%)'
                                : 'linear-gradient(112deg, #58595B 0%, #AEB0B1 57%, #D1D3D4 100%)'
                            }
                            borderRadius={'10px'}
                            onClick={() => {
                              dispatch(updateLifeAssuredAccount(item))
                              setSelectedCard(idx)
                            }}
                          >
                            <Box mx='8'>
                              {/* {JSON.stringify(customerDetailsAPIData)} */}
                              <Text float={'right'}>VISA</Text>
                              <Text mt='10'>
                                {/* {`${customerDetailsAPIData?.IndividualCustomerDetails?.IndCustDtlsRp?.[0].frsNm}` ||
                              ''}{' '}
                            {`${customerDetailsAPIData?.IndividualCustomerDetails?.IndCustDtlsRp?.[0].lstNm}` ||
                              ''} */}
                                {customerPersonalData.first_name}
                              </Text>
                              {/* <Text mt='2'>**** **** **** 1234</Text> */}
                              <Text mt='2'>{item.show_account_number}</Text>

                              <Flex gap='5' fontSize={'15px'} width={'200px'} mt='5'>
                                <Box fontSize={'12px'}>
                                  <Text>IFSC Code</Text>
                                  <Text>{item.ifsc_code}</Text>
                                </Box>
                                <Box fontSize={'12px'}>
                                  <Text>Account Type</Text>
                                  <Text>
                                    {getAccountTypeBasedOnIndex(item.show_account_number.charAt(0))}
                                  </Text>
                                </Box>
                              </Flex>
                            </Box>
                          </Box>
                        ))}
                      </>
                    )}
                  </Flex>
                  <Box mt={isDesktop ? '50px' : '0'} visibility={isDesktop ? 'visible' : 'hidden'}>
                    <Button
                      onClick={() => setCardComp(false)}
                      size='md'
                      variant='solid'
                      rounded='full'
                      w='44'
                      mr='5'
                      ml='240px'
                    >
                      Back
                    </Button>
                    <Button
                      isLoading={customerDetailsLoading || customerSearchLoading}
                      onClick={clickHandler}
                      size='md'
                      variant='solid'
                      rounded='full'
                      w='44'
                      type='submit'
                      isDisabled={selectedCard === undefined ? true : false}
                    >
                      Next
                    </Button>

                    <Flex visibility={isDesktop ? 'hidden' : 'visible'} gap='2'>
                      <Button
                        onClick={() => setCardComp(false)}
                        size='md'
                        variant='solid'
                        rounded='full'
                        w='44'
                      >
                        Back
                      </Button>
                      <Button
                        onClick={clickHandler}
                        size='md'
                        variant='solid'
                        rounded='full'
                        w='44'
                        type='submit'
                      >
                        Next
                      </Button>
                    </Flex>
                  </Box>
                </Box>
              </>
            )}
          </Flex>
        </Box>
      </form>
      <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Last Name</ModalHeader>
          <ModalBody>
            <FormLabel>Last Name</FormLabel>
            <TextInput
              onKeyDown={onlyAllowTypingAlphabet}
              placeholder={'Enter Last Name'}
              onChange={(e) => setLastName(e.target.value)}
              maxLength={30}
            />
          </ModalBody>
          <Center>
            <ModalFooter>
              <Button
                isDisabled={!lastName}
                onClick={() => {
                  if (lastName) {
                    dispatch(updateLifeCustomerLastName(lastName))
                    onClose()
                  }
                }}
              >
                Update
              </Button>
            </ModalFooter>
          </Center>
        </ModalContent>
      </Modal>
    </>
  )
}

export { HomeLifeForm }
