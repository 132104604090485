import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    primary: '#93C025', // Green in case of bimaboss
    secondary: '#1A6092', // Blue in case of bimaboss
    tertiary: '#4b9cd8', // sky blue in case of bimaboss
    focus: '#7FAC07',
    warning: '#D0342C',
    appGrey: '#8A8B8B',
    appDarkGrey: '#E8E8E8',
  },
  borders: {
    '1px': '1px solid #D8D8D8',
    '2px': '0.2rem solid',
  },
  shadows: {
    onlyBottom: 'rgba(0, 0, 0, 0.15) 0px 1.95px 2.6px;',
  },
  breakpoints: {
    xs: '300px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    '2xl': '1400px',
    '3xl': '1600px',
    '4xl': '1800px',
  },
  components: {
    Modal: {
      baseStyle: () => ({
        StyleSheet: {
          bgOpacity: '1.95px',
          bg: '#3E3E3EB7',
        },
      }),
    },
  },
})
