import { BoxProps, Code, Text } from '@chakra-ui/react'
import { PRODUCT_URL, dotNotInclude } from 'Constants'
import { ElementWithLabel, ErrorText, TextInput } from 'Core'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import RenderChild from './RenderChild'
import { objectEntries, objectKeys } from 'ts-extras'
import { store } from 'app/store'
import { IApiResponse2 } from 'Models/ResponseModels'
import { getDefaultHeaders2 } from 'Services/baseQuery'
import axios, { AxiosResponse } from 'axios'

const updateValueUsingDependentFields = ({
  type,
  questionCode,
  value,
  form,
}: {
  type: 'text' | 'date'
  questionCode: string
  value: Record<string, string | number>
  form: UseFormReturn<any, any>
}) => {
  const valueInString = objectKeys(value)[0]

  switch (type) {
    case 'text':
      form.setValue(questionCode, valueInString)
      break
    case 'date': // TODO: Add date parsing before setting value in the form on the basis of data recieved from backend
      form.setValue(questionCode, valueInString)
      break
    default:
      break
  }
}

interface IProps extends BoxProps {
  isRoot: boolean
  isRequiredField?: boolean
  isDisabled?: boolean
  parentData: {
    name: string
    value: string | number
    cover_type?: INestedQuestionSet['cover_type']
  }
  name: string
  placeholder: string
  label?: string
  min?: number | null
  max?: number | null
  regex?: string | RegExp
  input_type?: string
  initialValues?: Record<string, string | number | boolean | string[]>
  childrenData?: Record<string, INestedQuestionSet[]>
  form: UseFormReturn<any, any>
  factor?: number
  operation?: string
  value?: string
  isWithoutLabel?: boolean
  cover_type?: INestedQuestionSet['cover_type']
  defaultValue?: string
  disabledCodes?: string[]
  hidden?: boolean
  affect?: string
  call_api?: boolean
  required?: boolean
  onAffect?: (
    name: string,
    value: string | number,
    affect: string,
    factor?: number,
    operation?: string,
  ) => void
}

// TODO: Add input type - text/number restriction here
const RenderNumericText = (props: IProps) => {
  const {
    name,
    placeholder,
    childrenData,
    form,
    isRoot,
    parentData,
    isRequiredField,
    isDisabled,
    label,
    min,
    max,
    regex,
    input_type,
    initialValues,
    cover_type,
    factor,
    operation,
    value,
    isWithoutLabel,
    defaultValue,
    disabledCodes,
    affect,
    onAffect,
    hidden,
    call_api,
    required,
    ...rest
  } = props

  useEffect(() => {
    if (initialValues && initialValues[name]) {
      form.setValue(name, initialValues[name])
    }
  }, [initialValues])

  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const [regexVal, setRegexVal] = useState(regex)

  const [customError, setCustomError] = useState('')
  const [otherRequiredFieldsError, setOtherRequiredFieldsError] = useState(false)

  useEffect(() => {
    setMinVal(min)
  }, [min])

  useEffect(() => {
    setMaxVal(max)
  }, [max])

  useEffect(() => {
    setRegexVal(regex)
  }, [regex])

  useEffect(() => {
    if (isMinMaxEqual()) {
      form.setValue(name, minVal)
    }
  }, [minVal, maxVal, parentData.value])

  const getValidationData = async () => {
    if (call_api) {
      const insurer = store.getState().lifeQuotationDetails.finalSelectedPlan?.insurer
      const insurerProductCode = store.getState().lifeQuotationDetails.finalSelectedPlan?.code
      try {
        const resp: AxiosResponse<
          IApiResponse2<{
            field_validation: {
              min: string | number
              max: string | number
              regex: string
              req_fields?: string[]
              error_msg?: string
            }
            dependent_fields?: Record<
              string,
              {
                type: 'text' | 'date'
                values: Record<string, string | number>
              }
            >
            default: string
            is_disabled: boolean
          }>
        > = await axios({
          url: `${PRODUCT_URL}/product/get-choices/${insurer}/${insurerProductCode}/${name}/`,
          method: 'POST',
          data: {
            ...form.getValues(),
          },
          headers: getDefaultHeaders2({
            token: store.getState().user.authenticatedUser.authtoken ?? '',
          }),
        })

        const dependentFields = resp.data.data?.dependent_fields

        if (dependentFields) {
          const dataInEntries = objectEntries(dependentFields)
          dataInEntries.forEach((item) => {
            const questionCode = item[0]
            const questionType = item[1].type
            const newQuestionValue = item[1].values
            updateValueUsingDependentFields({
              form,
              type: questionType,
              questionCode,
              value: newQuestionValue,
            })
          })
        }

        setCustomError('')

        const fieldValidationData = resp.data.data?.field_validation

        if (fieldValidationData) {
          // If `fieldValidationData?.req_fields` has some value, then set custom validation to this field and return
          if (fieldValidationData?.req_fields && fieldValidationData?.req_fields?.length > 0) {
            form.setError(name, {
              type: 'otherRequiredFields',
              message: fieldValidationData?.error_msg ?? 'Some Other fields are required!',
            })
            setOtherRequiredFieldsError(true)
            // setCustomError(fieldValidationData?.error_msg)
            // await form.trigger(name)
            // return
          } else {
            setOtherRequiredFieldsError(false)
          }

          if (fieldValidationData?.error_msg) {
            setCustomError(fieldValidationData?.error_msg)
            // console.log('setting custom error!')

            // await form.trigger(name)
          }
          // else {
          //   // await form.trigger(name)
          //   // console.log('removing custom error!')

          //   setCustomError('')
          // }

          if (fieldValidationData.min?.toString()) {
            setMinVal(parseInt(fieldValidationData.min?.toString()))
          }

          if (fieldValidationData.max?.toString()) {
            setMaxVal(parseInt(fieldValidationData.max?.toString()))
          }

          if (fieldValidationData.regex?.toString()) {
            setRegexVal(fieldValidationData.regex?.toString())
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const [dropdownValue, setDropdownValue] = useState<{
    label: string
    value: string | number
  } | null>(null)

  const minValidationFn = (value: any) => {
    let valid = true
    if (input_type) {
      const minTemp = minVal as number
      switch (input_type.toLowerCase()) {
        case 'numeric-text':
          if (operation != null) {
            if (minTemp >= 0) {
              if (operation === 'subtract_lower_limit, subtract_upper_limit') {
                valid = Number(value) >= minVal! - parseInt(parentData.value.toString())
              } else {
                valid = Number(value) >= minTemp
              }
            }
          } else {
            valid = Number(value) >= minTemp
          }
          break
        case 'string':
          if (minTemp >= 0) {
            valid = value.length >= minTemp
          }
          break
        default:
          break
      }
    }
    return valid
  }

  const maxValidationFn = (value: any) => {
    let valid = true
    if (input_type) {
      switch (input_type.toLowerCase()) {
        case 'numeric-text':
          if (operation != null) {
            if (maxVal) {
              if (operation === 'upper_limit_less_than_and_equal_to') {
                valid = Number(value) <= Math.min(parseInt(parentData.value.toString()), maxVal)
              } else if (operation === 'subtract_upper_limit') {
                valid = Number(value) <= maxVal - parseInt(parentData.value.toString())
              } else if (operation === 'subtract_lower_limit, subtract_upper_limit') {
                valid = Number(value) <= maxVal - parseInt(parentData.value.toString())
              } else {
                valid = Number(value) <= maxVal
              }
            }
          } else {
            valid = Number(value) <= maxVal!
          }
          break
        case 'string':
          if (max) {
            valid = value.length <= maxVal!
          }
          break
        default:
          break
      }
    }
    return valid
  }

  const getIsDisabled = () => {
    return (
      isDisabled ||
      (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false) ||
      isMinMaxEqual()
    )
  }

  const customErrorFn = () => {
    let valid = true
    if (customError !== '') {
      valid = false
    }
    return valid
  }

  const otherRequiredFieldsErrorFn = () => {
    let valid = true
    if (otherRequiredFieldsError) {
      valid = true
    }
    return valid
  }

  const isMinMaxEqual = () => {
    return (
      minVal !== null &&
      maxVal !== null &&
      minVal !== undefined &&
      maxVal !== undefined &&
      minVal === maxVal &&
      minVal >= 0 &&
      maxVal >= 0 &&
      Number(defaultValue) == minVal
    )
  }

  useEffect(() => {
    if (form.getValues(name) && affect && onAffect) {
      onAffect(name, form.getValues(name), affect, factor, operation)
    }
  }, [form.getValues(name)])

  return (
    <>
      <ElementWithLabel
        marginY='0.7rem'
        required
        height='fit-content'
        label={isWithoutLabel ? '' : label || name}
        {...rest}
      >
        <TextInput
          placeholder={`Please enter ${label}.`}
          defaultValue={defaultValue}
          isDisabled={getIsDisabled()}
          onFocus={getValidationData}
          {...form.register(name, {
            required: required,
            validate: {
              min: minValidationFn,
              max: maxValidationFn,
              custom: customErrorFn,
              otherRequiredFields: otherRequiredFieldsErrorFn,
            },
            pattern: regexVal
              ? new RegExp(regexVal.toString().slice(1, -1))
              : input_type && input_type.toLowerCase() === 'numeric'
              ? new RegExp(dotNotInclude)
              : undefined,
            onBlur: () => {
              getValidationData()
            },
          })}
          // {...form.register(name, {
          //   required: true,
          //   validate: { min: minValidationFn, max: maxValidationFn },
          //   pattern: regex
          //     ? new RegExp(regex)
          //     : input_type && input_type.toLowerCase() === 'numeric-text'
          //     ? new RegExp(dotNotInclude)
          //     : undefined,
          // })}
          type={input_type && input_type.toLowerCase() === 'numeric-text' ? 'number' : 'text'}
          value={value}
        />
        {input_type && form.formState.errors[name] ? (
          <>
            {form.formState.errors[name]?.type === 'required' ? (
              <ErrorText text={`${label || name} is required!`} />
            ) : null}
            {form.formState.errors[name]?.type === 'pattern' ? (
              <ErrorText text={`${label || name} is Invalid!`} />
            ) : null}
            {input_type.toLowerCase() === 'numeric-text' ? (
              <>
                {form.formState.errors[name]?.type === 'min' ? (
                  <ErrorText text={`Minimum value: ${minVal}`} />
                ) : null}
                {form.formState.errors[name]?.type === 'max' ? (
                  operation === 'subtract_upper_limit' ? (
                    <ErrorText
                      text={`Maximum value: ${maxVal! - parseInt(parentData?.value.toString())}`}
                    />
                  ) : (
                    <ErrorText text={`Maximum value: ${maxVal}`} />
                  )
                ) : null}
              </>
            ) : null}
            {input_type.toLowerCase() === 'string' ? (
              <>
                {form.formState.errors[name]?.type === 'min' ? (
                  <ErrorText text={`Minimum ${minVal} alphabets required!`} />
                ) : null}
                {form.formState.errors[name]?.type === 'max' ? (
                  <ErrorText text={`Maximum ${maxVal} alphabets only!`} />
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </ElementWithLabel>
      {childrenData &&
        Object.keys(childrenData).length > 0 &&
        form.getValues(name) &&
        parseInt(form.getValues(name)) >= minVal! &&
        parseInt(form.getValues(name)) <= maxVal! &&
        childrenData[`${minVal}-${maxVal}`] &&
        childrenData[`${minVal}-${maxVal}`].map((item, index) => {
          return (
            <RenderChild
              key={index}
              childField={item}
              form={form}
              parentData={{
                name: name,
                value: form.getValues(name),
                cover_type: cover_type,
              }}
              defaultValue={item.default}
              initialValues={initialValues}
              factor={item.factor}
              operation={item.operation}
              affect={item.affect}
              onAffect={onAffect}
              call_api={item.call_api}
              required={item.required}
            />
          )
        })}
    </>
  )
}

export { RenderNumericText }
