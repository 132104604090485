import { Flex } from '@chakra-ui/react'
import { VehicleDetailsDesktop } from 'Pages/Motor/VehicleInsuranceDetails/Desktop/VehicleDetailsDesktop'
import { CustomerDetailsDesktop } from './CustomerDetailsDesktop'
import { CustomerLinkedAccountsDesktop } from './CustomerLinkedAccountsDesktop'

const CustomerAccountDetailsDesktop = () => {
  return (
    <Flex border='1px' borderRadius='2xl'>
      {/* <VehicleDetailsDesktop /> */}
      <CustomerDetailsDesktop />
      <CustomerLinkedAccountsDesktop />
    </Flex>
  )
}

export { CustomerAccountDetailsDesktop }
