import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IManufacturer, IModel, IVahan, IVariant } from 'Models/ResponseModels'
import {
  TVehicleInsuranceFormType,
  TVehicleInsuranceFormTypeLeads,
} from 'Pages/Motor/VehicleInsuranceDetails/Forms/FormModel/VehicleInsurance'

type TVahanFields = 'customerFirstName' | 'customerMiddleName' | 'customerLastName'
// TODO: add other fields as per requirement

export interface IVehicleDetails {
  selectedManufacturer?: IManufacturer
  selectedModel?: IModel
  selectedVariant?: IVariant
  manufacturingYear?: number | string
  registrationDate?: string
  seatsGreaterThanSix: boolean
  isCPA: boolean
  typeOfVehicle_commercial?: {
    name: string
    code: string
  }
  fieldsFetchedFromVahanMapping: {
    customerFirstName: boolean
    customerMiddleName: boolean
    customerLastName: boolean
  }
  showNameConsent: boolean
}

export interface IVehicleInsuranceDetails {
  isSaod: boolean
  existingPolicyExpiryDate?: string
  hasPreviousPolicy: boolean
  previousInsurer?: { code: string; name: string }
  previousPolicyType?: { value: string; name: string }
  claimsMadeInExistingPolicy: boolean
  ncb?: string
  TpPreviousPolicyNumber?: string
  TpPreviousInsurerName?: { code: string; name: string }
  tpPolicyStartDate?: string
  tpPolicyEndDate?: string
  showIRDAError: boolean
  permitType?: { code: string; name: string }
}

interface IState {
  vehicleDetails: IVehicleDetails
  vehicleInsuranceDetails: IVehicleInsuranceDetails
  vahanData?: IVahan
}

const initialState: IState = {
  vehicleDetails: {
    seatsGreaterThanSix: false,
    fieldsFetchedFromVahanMapping: {
      customerFirstName: false,
      customerMiddleName: false,
      customerLastName: false,
    },
    showNameConsent: false,
    isCPA: false,
  },
  vehicleInsuranceDetails: {
    isSaod: false,
    hasPreviousPolicy: true,
    claimsMadeInExistingPolicy: false,
    showIRDAError: false,
  },
}

const vehicleDetailsSlice = createSlice({
  name: 'vehicleDetails',
  initialState: initialState,
  reducers: {
    /**
     * @deprecated
     */
    updateSelectedManufacturer: (
      state,
      action: PayloadAction<{
        selectedManufacturerCode: number | string
        allManufacturers: IManufacturer[]
      }>,
    ) => {
      const { allManufacturers, selectedManufacturerCode } = action.payload
      const selectedManufacturer = allManufacturers.find(
        (manufacturer) => manufacturer.code.toString() === selectedManufacturerCode.toString(),
      )
      if (selectedManufacturer) {
        state.vehicleDetails.selectedManufacturer = selectedManufacturer
      }
    },
    /**
     * @deprecated
     */
    updateSelectedModel: (
      state,
      action: PayloadAction<{ selectedModelCode: number | string; allModels: IModel[] }>,
    ) => {
      const { allModels, selectedModelCode } = action.payload
      const selectedModel = allModels.find(
        (model) => model.code.toString() === selectedModelCode.toString(),
      )
      if (selectedModel) {
        state.vehicleDetails.selectedModel = selectedModel
      }
    },
    /**
     * @deprecated
     */
    updateSelectedVariant: (
      state,
      action: PayloadAction<{ selectedVariantCode: number | string; allVariants: IVariant[] }>,
    ) => {
      const { allVariants, selectedVariantCode } = action.payload
      const selectedVariant = allVariants.find(
        (variant) => variant.code.toString() === selectedVariantCode.toString(),
      )
      if (selectedVariant) {
        state.vehicleDetails.selectedVariant = selectedVariant
      }
    },
    updateManufacturer: (state, action: PayloadAction<IManufacturer>) => {
      const selectedManufacturer = action.payload
      state.vehicleDetails.selectedManufacturer = selectedManufacturer
    },
    updateModel: (state, action: PayloadAction<IModel>) => {
      const selectedModel = action.payload
      state.vehicleDetails.selectedModel = selectedModel
    },
    resetModel: (state) => {
      state.vehicleDetails.selectedModel = initialState.vehicleDetails.selectedModel
    },
    updateVariant: (state, action: PayloadAction<IVariant>) => {
      const selectedVariant = action.payload
      state.vehicleDetails.selectedVariant = selectedVariant
      if (selectedVariant?.seating_capacity && selectedVariant.seating_capacity > 6) {
        state.vehicleDetails.seatsGreaterThanSix = true
      }
    },
    resetVariant: (state) => {
      state.vehicleDetails.selectedVariant = initialState.vehicleDetails.selectedVariant
    },
    updateManufacturingYear: (state, action: PayloadAction<number | string>) => {
      state.vehicleDetails.manufacturingYear = action.payload
    },
    updateRegistrationDate: (state, action: PayloadAction<string>) => {
      state.vehicleDetails.registrationDate = action.payload
    },
    updateCpaCheck: (state, action: PayloadAction<boolean>) => {
      state.vehicleDetails.isCPA = action.payload
    },
    resetRegistrationDate: (state) => {
      state.vehicleDetails.registrationDate = undefined
    },
    updateTypeOfVehicle_commercial: (
      state,
      action: PayloadAction<{ name: string; code: string }>,
    ) => {
      state.vehicleDetails.typeOfVehicle_commercial = action.payload
    },
    updateIsSaod: (state, action: PayloadAction<boolean>) => {
      state.vehicleInsuranceDetails.isSaod = action.payload
    },
    updateHasPreviousPolicy: (state, action: PayloadAction<boolean>) => {
      state.vehicleInsuranceDetails.hasPreviousPolicy = action.payload
    },
    updateClaimsMadeInExistingPolicy: (state, action: PayloadAction<boolean>) => {
      state.vehicleInsuranceDetails.claimsMadeInExistingPolicy = action.payload
    },
    updateShowIrdaError: (state, action: PayloadAction<boolean>) => {
      state.vehicleInsuranceDetails.showIRDAError = action.payload
    },
    updateVehicleDetailsForm: (state, action: PayloadAction<IVehicleDetails>) => {
      const {
        selectedManufacturer,
        selectedModel,
        selectedVariant,
        manufacturingYear,
        registrationDate,
        seatsGreaterThanSix,
        typeOfVehicle_commercial,
        showNameConsent,
        isCPA,
      } = action.payload
      if (selectedManufacturer) {
        state.vehicleDetails.selectedManufacturer = selectedManufacturer
      }
      if (selectedModel) {
        state.vehicleDetails.selectedModel = selectedModel
      }
      if (selectedVariant) {
        state.vehicleDetails.selectedVariant = selectedVariant
      }
      if (manufacturingYear) {
        state.vehicleDetails.manufacturingYear = manufacturingYear
      }
      if (registrationDate) {
        state.vehicleDetails.registrationDate = registrationDate
      }
      if (seatsGreaterThanSix) {
        state.vehicleDetails.seatsGreaterThanSix = seatsGreaterThanSix
      }
      if (typeOfVehicle_commercial) {
        state.vehicleDetails.typeOfVehicle_commercial = typeOfVehicle_commercial
      }
      if (showNameConsent === true) {
        state.vehicleDetails.showNameConsent = showNameConsent
      }
      if (isCPA) {
        state.vehicleDetails.isCPA = isCPA
      }
    },
    updateVehicleInsuranceForm: (state, action: PayloadAction<TVehicleInsuranceFormType>) => {
      const {
        previous_policy_end_date,
        previous_insurer,
        previous_policy_type,
        ncb,
        permit_type,
        TpPreviousPolicyNumber,
        TpPreviousInsurerName,
        tp_policy_start_date,
        tp_policy_end_date,
      } = action.payload
      console.log(action.payload)
      if (previous_policy_end_date)
        state.vehicleInsuranceDetails.existingPolicyExpiryDate = previous_policy_end_date
      if (previous_insurer) state.vehicleInsuranceDetails.previousInsurer = previous_insurer
      if (previous_policy_type)
        state.vehicleInsuranceDetails.previousPolicyType = previous_policy_type
      if (ncb) state.vehicleInsuranceDetails.ncb = ncb
      if (permit_type) state.vehicleInsuranceDetails.permitType = permit_type
      if (TpPreviousPolicyNumber)
        state.vehicleInsuranceDetails.TpPreviousPolicyNumber = TpPreviousPolicyNumber
      if (TpPreviousInsurerName)
        state.vehicleInsuranceDetails.TpPreviousInsurerName = TpPreviousInsurerName
      if (tp_policy_start_date)
        state.vehicleInsuranceDetails.tpPolicyStartDate = tp_policy_start_date
      if (tp_policy_end_date) state.vehicleInsuranceDetails.tpPolicyEndDate = tp_policy_end_date
    },
    updateVehicleDetailsFormLeads: (
      state,
      action: PayloadAction<TVehicleInsuranceFormTypeLeads>,
    ) => {
      const {
        TpPreviousInsurerName,
        TpPreviousPolicyNumber,
        existingPolicyExpiryDate,
        ncb,
        permitType,
        previousInsurer,
        previousPolicyType,
        tpPolicyEndDate,
        tpPolicyStartDate,
      } = action.payload
      if (TpPreviousInsurerName) {
        state.vehicleInsuranceDetails.TpPreviousInsurerName = TpPreviousInsurerName
      }
      if (TpPreviousPolicyNumber) {
        state.vehicleInsuranceDetails.TpPreviousPolicyNumber
      }
      if (existingPolicyExpiryDate) {
        state.vehicleInsuranceDetails.existingPolicyExpiryDate = existingPolicyExpiryDate
      }
      if (ncb) {
        state.vehicleInsuranceDetails.ncb = ncb
      }
      if (permitType) {
        state.vehicleInsuranceDetails.permitType = permitType
      }
      if (previousInsurer) {
        state.vehicleInsuranceDetails.previousInsurer = previousInsurer
      }
      if (previousPolicyType) {
        state.vehicleInsuranceDetails.previousPolicyType = previousPolicyType
      }
      if (tpPolicyEndDate) {
        state.vehicleInsuranceDetails.tpPolicyEndDate = tpPolicyEndDate
      }
      if (tpPolicyStartDate) {
        state.vehicleInsuranceDetails.tpPolicyStartDate = tpPolicyStartDate
      }
    },
    updateFieldsFetchedFromVahanMapping: (state, action: PayloadAction<TVahanFields>) => {
      state.vehicleDetails.fieldsFetchedFromVahanMapping[action.payload] = true
    },
    updateVahanDetails: (state, action: PayloadAction<IVahan>) => {
      state.vahanData = action.payload
    },
    updateShowConsent: (state, action: PayloadAction<boolean>) => {
      state.vehicleDetails.showNameConsent = action.payload
    },
    resetVehicleDetails: () => initialState,
  },
})

const { reducer } = vehicleDetailsSlice

const {
  updateSelectedManufacturer,
  updateSelectedModel,
  updateSelectedVariant,
  updateManufacturer,
  updateModel,
  resetModel,
  updateVariant,
  resetVariant,
  updateManufacturingYear,
  updateRegistrationDate,
  resetRegistrationDate,
  updateIsSaod,
  updateHasPreviousPolicy,
  updateShowIrdaError,
  updateClaimsMadeInExistingPolicy,
  updateVehicleInsuranceForm,
  updateVehicleDetailsForm,
  updateCpaCheck,
  updateVehicleDetailsFormLeads,
  resetVehicleDetails,
  updateTypeOfVehicle_commercial,
  updateFieldsFetchedFromVahanMapping,
  updateVahanDetails,
  updateShowConsent,
} = vehicleDetailsSlice.actions

export {
  reducer as vehicleDetailsReducer,
  updateSelectedManufacturer,
  updateSelectedModel,
  updateSelectedVariant,
  updateManufacturer,
  updateModel,
  resetModel,
  updateVariant,
  resetVariant,
  updateManufacturingYear,
  updateRegistrationDate,
  resetRegistrationDate,
  updateIsSaod,
  updateHasPreviousPolicy,
  updateShowIrdaError,
  updateClaimsMadeInExistingPolicy,
  updateVehicleInsuranceForm,
  updateVehicleDetailsFormLeads,
  updateVehicleDetailsForm,
  resetVehicleDetails,
  updateCpaCheck,
  updateTypeOfVehicle_commercial,
  updateFieldsFetchedFromVahanMapping,
  updateVahanDetails,
  updateShowConsent,
}
