import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'

const BbButton = forwardRef((props: ButtonProps, ref) => {
  return (
    <Button
      textTransform={'capitalize'}
      ref={ref}
      colorScheme='primary'
      rounded='md'
      variant='solid'
      paddingX='10'
      {...props}
    ></Button>
  )
})

export { BbButton }
