import { Box, Button, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useAppSelector, useDeviceType, useVehicleType, useWindowDimensions } from 'app/hooks'
import { PlaceholderImage } from 'Core'
import { copyToClipboard, getFallbackImageHtml } from 'Services/general'
import { getInsuranceTypeText } from './ProposalUtils'

interface ITextWithLabel {
  label: string
  value: string
}

const TextWithLabel = (props: ITextWithLabel) => {
  const { label, value } = props
  return (
    <Box fontSize='xs'>
      <Text whiteSpace='nowrap' as='span' fontWeight='bold'>
        {label}:&nbsp;
      </Text>
      {value}
    </Box>
  )
}

interface IVehicleDetailsHeader {
  vehicleTypeText: string
  previousPolicyType: string
  idv: number
  registrationNumber: string
  prevPolicyExp: string
  ownedBy: string
}

const VehicleDetailsHeader = (props: IVehicleDetailsHeader) => {
  const { previousPolicyType, vehicleTypeText, idv, registrationNumber, prevPolicyExp, ownedBy } =
    props

  const { isDesktop } = useDeviceType()

  const { inspectionRequired, isComprehensive } = useAppSelector((state) => state.quotationsDetails)
  const { param: vehicleTypeParam } = useVehicleType()
  const { isSaod } = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  // const {previousPolicyType} = useAppSelector(state => state.vehicleDetails.vehicleInsuranceDetails)

  const VEHICLETYPE = <TextWithLabel label='Vehicle Type' value={vehicleTypeText} />
  const POLICYTYPE = <TextWithLabel label='Previous Policy Type' value={previousPolicyType} />
  const IDV = <TextWithLabel label='IDV' value={`₹ ${Math.round(idv).toLocaleString('en-IN')}`} />
  const REGNO = <TextWithLabel label='Registration No.' value={registrationNumber} />
  const PREVPOLICYTYPE = <TextWithLabel label='Prev. Policy Exp Date' value={prevPolicyExp} />
  const OWNEDBY = <TextWithLabel label='Vehicle Owned By' value={ownedBy} />
  const CURRINSURANCETYPE = (
    <TextWithLabel
      label='Current Insurance Type'
      value={getInsuranceTypeText({
        isBreakIn: inspectionRequired,
        isCurrentTP: !isComprehensive,
        isPreviousTP: previousPolicyType === 'Third Party',
        vehicleTypeParam: vehicleTypeParam,
      })}
    />
  )

  if (isDesktop) {
    return (
      <Flex>
        <Box flexBasis='50%' padding='5'>
          {VEHICLETYPE}
          {POLICYTYPE}
          {IDV}
          {REGNO}
        </Box>
        <Box flexBasis='50%' padding='5'>
          {PREVPOLICYTYPE}
          {OWNEDBY}
          {CURRINSURANCETYPE}
        </Box>
      </Flex>
    )
  }
  return (
    <>
      {VEHICLETYPE}
      {POLICYTYPE}
      {IDV}
      {REGNO}
      {PREVPOLICYTYPE}
      {OWNEDBY}
      {CURRINSURANCETYPE}
    </>
  )
}

const ProposalTopBar = () => {
  const { width } = useWindowDimensions()
  const { isDesktop } = useDeviceType()
  const { vehicleTypeText } = useVehicleType()
  const {
    vehicleDetails: { selectedManufacturer, selectedModel, selectedVariant },
    vehicleInsuranceDetails: { previousPolicyType, existingPolicyExpiryDate },
  } = useAppSelector((state) => state.vehicleDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)

  const { selectedQuote, isIndividual, inspectionRequired } = useAppSelector(
    (state) => state.quotationsDetails,
  )

  const registrationNumber = useAppSelector((state) => state.homePage.vehicleNumber)

  if (isDesktop) {
    return (
      <Flex marginX='8' marginY='2' paddingBottom='4' boxShadow='onlyBottom'>
        <Flex
          direction='column'
          padding='8'
          flexBasis='33%'
          justifyContent='center'
          alignItems='center'
          borderRight='1px'
        >
          <Flex alignItems='center' justifyContent='flex-end'>
            {width > 1280 ? (
              <>
                {selectedQuote?.insurer_logo ? (
                  <Image
                    margin='2'
                    border='1px'
                    className='w-[12vh] m-2'
                    src={selectedQuote?.insurer_logo}
                    onError={({ currentTarget }) => {
                      currentTarget.outerHTML = getFallbackImageHtml(
                        selectedQuote?.insurance_name ?? 'byuftdrxezwrxtcyvbjuyvtcr',
                      )
                    }}
                  />
                ) : (
                  <Box padding='2'>
                    <PlaceholderImage
                      height='50'
                      width='8rem'
                      text={selectedQuote?.insurance_name ?? 'X'}
                    />
                  </Box>
                )}
                <Flex direction='column'>
                  <Text fontWeight='bold'>
                    {selectedManufacturer?.name} {selectedModel?.name}
                  </Text>
                  <Text fontWeight='bold'>{selectedVariant?.name}</Text>
                </Flex>
              </>
            ) : (
              <>
                <Flex direction='column' alignItems='center'>
                  {selectedQuote?.insurer_logo ? (
                    <Image
                      margin='2'
                      border='1px'
                      className='w-[12vh] m-2'
                      src={selectedQuote?.insurer_logo}
                      onError={({ currentTarget }) => {
                        currentTarget.outerHTML = getFallbackImageHtml(
                          selectedQuote?.insurance_name ?? 'X',
                        )
                      }}
                    />
                  ) : (
                    <PlaceholderImage
                      height='50'
                      width='100'
                      text={selectedQuote?.insurance_name ?? 'X'}
                    />
                  )}
                  <Text textAlign='center' fontWeight='bold'>
                    {selectedManufacturer?.name} {selectedModel?.name}
                  </Text>
                  <Text textAlign='center' fontWeight='bold'>
                    {selectedVariant?.name}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Flex alignItems='center' justifyContent='center' borderRight='1px' flexBasis='33%'>
          <VehicleDetailsHeader
            vehicleTypeText={vehicleTypeText}
            previousPolicyType={previousPolicyType?.name ?? ''}
            idv={selectedQuote?.idv ?? 0}
            registrationNumber={registrationNumber}
            prevPolicyExp={existingPolicyExpiryDate ?? ''}
            ownedBy={isIndividual ? 'Individual' : 'Company'}
          />
        </Flex>
        <Flex padding='8' justifyContent='center' direction='column' flexBasis='33%'>
          <Text fontWeight='extrabold' fontSize='2xl'>
            FINAL PREMIUM
          </Text>
          <Text
            textAlign='center'
            display='flex'
            alignItems='center'
            fontWeight='extrabold'
            fontSize='2xl'
          >
            ₹{' '}
            {selectedQuote?.premium_total &&
              Math.round(selectedQuote?.premium_total).toLocaleString('en-IN')}
            <Text as='span' padding='1' fontWeight='normal' fontSize='x-small'>
              Inc. GST
            </Text>
          </Text>
          <Button
            // leftIcon={<MdBuild />}
            width='35%'
            colorScheme='secondary'
            fontSize='sm'
            marginTop='2'
            variant='outline'
            onClick={() => {
              copyToClipboard(leadDetails.lead_uid ?? '')
              // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
              // ToastService.showSuccessTaost({
              //   title: 'Success',
              //   message: 'Lead Saved Successfully',
              // })
            }}
          >
            {leadDetails.lead_uid ?? '-'}
          </Button>
        </Flex>
      </Flex>
    )
  }
  return (
    <>
      <Flex paddingY='6' paddingX='4' justifyContent='center' alignItems='center'>
        <Flex height='100%' direction='column' paddingX='2'>
          <Flex justifyContent='start'>
            {selectedQuote?.insurer_logo ? (
              <Image
                border='1px'
                className='w-[12vh] m-2'
                src={selectedQuote?.insurer_logo}
                onError={({ currentTarget }) => {
                  currentTarget.outerHTML = getFallbackImageHtml(selectedQuote.insurance_name)
                }}
              />
            ) : (
              <PlaceholderImage
                height='50'
                width='8rem'
                text={selectedQuote?.insurance_name ?? 'X'}
              />
            )}
          </Flex>

          <Text paddingY='1' fontWeight='bold' fontSize='xs'>
            FINAL PREMIUM:
          </Text>
          <Text
            textAlign='center'
            display='flex'
            alignItems='center'
            fontWeight='extrabold'
            fontSize='xs'
          >
            ₹{' '}
            {selectedQuote?.premium_total &&
              Math.round(selectedQuote?.premium_total).toLocaleString('en-IN')}
            <Text as='span' padding='1' fontWeight='normal' fontSize='x-small'>
              Inc. GST
            </Text>
          </Text>
          <Button
            // leftIcon={<MdBuild />}
            colorScheme='secondary'
            fontSize='sm'
            marginX='2'
            variant='outline'
            onClick={() => {
              copyToClipboard(leadDetails.lead_uid ?? '')
              // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
              // ToastService.showSuccessTaost({
              //   title: 'Success',
              //   message: 'Lead Saved Successfully',
              // })
            }}
          >
            {leadDetails.lead_uid ?? '-'}
          </Button>
        </Flex>
        <Divider orientation='vertical' />
        <Flex paddingX='2' direction='column'>
          <Text fontSize='xs' fontWeight='bold'>
            {selectedManufacturer?.name} {selectedModel?.name}
          </Text>
          <Text fontSize='xs' fontWeight='bold'>
            {selectedVariant?.name}
          </Text>
          <VehicleDetailsHeader
            vehicleTypeText={vehicleTypeText}
            previousPolicyType={previousPolicyType?.name ?? ''}
            idv={selectedQuote?.idv ?? 0}
            registrationNumber={registrationNumber}
            prevPolicyExp={existingPolicyExpiryDate ?? ''}
            ownedBy={isIndividual ? 'Individual' : 'Company'}
          />
        </Flex>
      </Flex>
    </>
  )
}

export { ProposalTopBar }
