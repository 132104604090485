import {
  Flex,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
} from '@chakra-ui/react'

import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { setPaymentDetails } from 'features/Sachets/sachetData.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  useCreateConsentMutation,
  useLazyGetConsentQuery,
  useLazyGetPaymentStatusQuery,
} from 'Services/API/sachet.api'
import { getAccountTypeBasedOnIndex } from 'Services/general'
import { getAccountStatus } from 'Constants'
import { ToastService } from 'Services/toast.service'
import { useSpTaggingSendOtpMutation } from 'Services/API/authentication.api'

export interface IPaymentStatus {
  id: number
  transaction_number: string
  member_id: string
  sp_id: number
  insurance: string
  transaction_date: string
  amount: string
  status: string
  mode_of_payment: string
  quotation: number
  masterPolicyNumber?: string
}

export const SachetConsentModal = ({
  selectedPlanFormData,
  isCheckboxcheckedNominee,
  isCheckboxcheckedSpouse,
  fullCustomerDetails,
  isOpen,
  onClose,
  dataStatus,
  timerLimit,
}: {
  selectedPlanFormData?: any
  isCheckboxcheckedNominee: any
  isCheckboxcheckedSpouse: any
  fullCustomerDetails: any
  onOpen: () => void
  isOpen: boolean
  onClose: () => void
  timerLimit: number
  ModalText: string
  dataStatus: any
  paymentStatus: any
}) => {
  const [sendOtp, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
    useSpTaggingSendOtpMutation()

  let selectedAccountMobileNumber: any = useAppSelector(
    (state) => state.customerDetails.customer_mobile_number,
  )
  const [resendLink, setResendLink] = useState(false)
  const [timeLeft, setTimeLeft] = useState(timerLimit)

  const TIMER_LIMIT = 60
  let tempTimeLeft = TIMER_LIMIT
  let interval: NodeJS.Timeout

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendLink(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  useEffect(() => {
    if (isOpen) {
      startTimer()
    }
  }, [isOpen])

  const dispatch = useAppDispatch()
  selectedAccountMobileNumber = selectedAccountMobileNumber.slice(2)
  const [createConsentMutationFunc, { isLoading: isCreateConsentLoading }] =
    useCreateConsentMutation()
  const applicantFormData = useAppSelector((state) => state.sachetDataDetails.applicantFormDetails)
  const selectedProductDetails = useAppSelector(
    (state) => state.sachetDataDetails.selectedProductData,
  )
  const applicantDetails = useAppSelector((state) => state.sachetDataDetails.applicantFormDetails)
  const nomineeDetailsFromStore = useAppSelector(
    (state) => state.sachetDataDetails?.nomineeFormDetails,
  )

  const customerProposerDetails = useAppSelector((state) => state.customerDetails)

  const quoteIdVal = useAppSelector((state) => state.sachetDataDetails.quoteId)

  const handleResendLink = async () => {
    try {
      const customerArrObjData = [
        { label: 'Salutation', value: applicantDetails?.salutation || '-' },

        { label: 'First Name', value: fullCustomerDetails?.first_name || '-' },
        { label: 'Last Name', value: fullCustomerDetails?.last_name || '-' },
        { label: 'Date of Birth', value: applicantDetails?.dob || '-' },
        {
          label: 'Gender',
          value:
            applicantDetails?.gender == 'm'
              ? 'Male'
              : applicantDetails?.gender == 'f'
              ? 'Female'
              : '-',
        },
        { label: 'Email', value: fullCustomerDetails?.email || applicantDetails?.email || '-' },
        {
          label: 'Address Line 1',
          value: applicantDetails?.address1 || '-',
        },
        {
          label: 'Address Line 2',
          value: applicantDetails?.address2 || '' || '-',
        },
        {
          label: 'Address Line 3',
          value: applicantDetails?.address3 || '' || '-',
        },

        { label: 'City', value: applicantDetails?.city || '-' },
        { label: 'District', value: applicantDetails?.district || '-' },
        // { label: 'District', value: applicantDetails?.district || '-' },
        { label: 'State', value: applicantDetails?.state || '-' },
        { label: 'Pincode', value: applicantDetails?.pinCode || '-' },
        { label: 'Master Policy Number', value: applicantDetails?.policyNumber || '-' },
        { label: 'Relationship', value: applicantDetails?.relationship || '-' },
      ]

      const nomineeArrObjData = [
        {
          label: 'Salutation',
          value: nomineeDetailsFromStore?.nomineeSalutation || '-',
        },
        { label: 'Name', value: nomineeDetailsFromStore?.nomineeFullName || '-' },
        { label: 'Date of Birth', value: nomineeDetailsFromStore?.nomineeDob || '-' },

        {
          label: 'Gender',
          value:
            nomineeDetailsFromStore?.nomineeGender == 'm'
              ? 'Male'
              : nomineeDetailsFromStore?.nomineeGender == 'f'
              ? 'Female'
              : '-',
        },
        { label: 'Relationship', value: nomineeDetailsFromStore?.nomineeRelationship || '-' },
        {
          label: 'Address Line 1',
          value: nomineeDetailsFromStore?.nomineeaddress1 || '-',
        },
        {
          label: 'Address Line 2',
          value: nomineeDetailsFromStore?.nomineeaddress2 || '' || '-',
        },
        {
          label: 'Address Line 3',
          value: nomineeDetailsFromStore?.nomineeaddress3 || '' || '-',
        },
      ]

      const selectedProductArrObjData = {
        name: selectedProductDetails?.name || '-',
        insurer_logo: selectedProductDetails?.insurer_logo || '-',
        insurer_name: selectedProductDetails?.insurer_name || '-',
        premium: selectedProductDetails?.premium || '-',
        sum_assured: selectedProductDetails?.sum_assured || '-',
        code: selectedProductDetails?.code || '-',
      }
      const sameAddress = {
        address_line_1: applicantDetails?.address1,
        address_line_2: applicantDetails?.address2,
        address_line_3: applicantDetails?.address3,
        city: applicantDetails?.city,
        state: applicantDetails?.state,
        pincode: applicantDetails?.pinCode,
      }
      const appointeeArrObjData = [
        { label: 'Name', value: nomineeDetailsFromStore?.appointeeFullName || '-' },

        { label: 'Relationship', value: nomineeDetailsFromStore?.appointeeRelationship || '-' },
        {
          label: 'Address',
          value: nomineeDetailsFromStore?.appointeeAddress || '-',
        },
      ]

      const createConsentResp: any = await createConsentMutationFunc({
        quote_id: quoteIdVal,
        phone_number: fullCustomerDetails?.phone.slice(2),
        email: fullCustomerDetails?.email || applicantDetails?.email,
        ucic_id: customerProposerDetails.ucic,
        ui_data: {
          customerAccountDetails: [
            {
              label: 'Account Lead',
              value: customerProposerDetails.selectedAccount?.account_lead ?? '-',
            },
            {
              label: 'Account Title',
              value: customerProposerDetails.selectedAccount?.account_title ?? '-',
            },
            {
              label: 'Source Branch',
              value: customerProposerDetails.selectedAccount?.source_branch ?? '-',
            },
            {
              label: 'Opening Branch',
              value: customerProposerDetails.selectedAccount?.opening_branch ?? '-',
            },
            {
              label: 'Account Type',
              value: getAccountTypeBasedOnIndex(
                customerProposerDetails.selectedAccount?.account_type ?? '-',
              ),
            },
            {
              label: 'Account Number',
              value: customerProposerDetails.selectedAccount?.show_account_number ?? '-',
            },
            {
              label: 'Account ID',
              value: customerProposerDetails.selectedAccount?.account_id ?? '-',
            },
            {
              label: 'Account Status',
              value: getAccountStatus(customerProposerDetails.selectedAccount?.accStsCode ?? ''),
            },
            {
              label: 'IFSC Code',
              value: customerProposerDetails.selectedAccount?.ifsc_code ?? '-',
              'UCIC ID': customerProposerDetails.ucic ?? '-',
            },
          ],
          customerArrObjData,
          isNomineeAMinor: nomineeDetailsFromStore?.isNomineeAMinorChecked,
          appointeeArrObjData,
          nomineeArrObjData,
          selectedProductArrObjData,
          masterPolicyNumber: applicantDetails ? applicantDetails?.policyNumber : '-',
          isSameAddressNominee: isCheckboxcheckedNominee,
          isSameAddressSpouse: isCheckboxcheckedSpouse,
          sameAddress,
          phoneNumber: fullCustomerDetails?.phone.slice(2),
          email: fullCustomerDetails?.email || applicantDetails?.email,
        },
      })
      setTimeLeft(timerLimit)
      setResendLink(false)
      startTimer()

      setPollingIntervalVal(2000)
      const consentData = await getconsent({
        quote_id: quoteIdVal!,
      }).unwrap()
      if (consentData && consentData?.status == 'APPROVED') {
        setPollingIntervalVal(0)
        try {
          setPaymentPollingIntervalVal(2000)
          getPaymentStatusFunc()
        } catch (error) {
          ToastService.showErrorToast({
            title: 'Failed to get payment status',
            message: 'Failed to get payment status. Please try again later.',
          })
        }
      }
    } catch (error) {
      ToastService.showErrorToast({
        title: 'Failed to Resend Link',
        message: 'Failed to Resend Link. Please try again later.',
      })
    }
  }
  const [pollingIntervalVal, setPollingIntervalVal] = useState(2000)

  const [getconsent, { currentData: data, isFetching, isLoading }] = useLazyGetConsentQuery({
    pollingInterval: pollingIntervalVal,
  })
  const [paymentPollingIntervalVal, setPaymentPollingIntervalVal] = useState(2000)
  const [paymentStatus, setPaymentStatus] = useState('pending')

  const [
    getPaymentStatus,
    {
      currentData: paymentData,
      isFetching: isFetchingPaymentStatus,
      isLoading: isLoadingPaymentStatus,
    },
  ] = useLazyGetPaymentStatusQuery({
    pollingInterval: paymentPollingIntervalVal,
  })
  const [paymentStatusData, setPaymentStatusData] = useState<IPaymentStatus>({
    id: 0,
    transaction_number: '',
    member_id: '',
    sp_id: 0,
    insurance: '',
    transaction_date: '',
    amount: '',
    status: '',
    mode_of_payment: '',
    quotation: 0,
    masterPolicyNumber: '',
  })

  useEffect(() => {
    console.log(' ')
  }, [dataStatus])

  const getPaymentStatusFunc = async () => {
    const resp: any = await getPaymentStatus({
      quote_id: quoteIdVal,
    })

    if (resp?.data?.status == 'Success' || resp?.data?.status == 'Failed') {
      setPaymentPollingIntervalVal(0)
    }

    setPaymentStatusData({
      ...resp?.data,
      insurance: selectedProductDetails?.insurer_name,
      masterPolicyNumber: applicantDetails?.policyNumber,
    })
    dispatch(setPaymentDetails(resp?.data))
  }

  useEffect(() => {
    if (paymentData?.status === 'Failed' || paymentData?.status === 'Success') {
      setPaymentPollingIntervalVal(0)
      setPaymentStatus(paymentData?.status)

      if (selectedPlanFormData) {
        setPaymentStatusData({
          ...paymentData,
          amount: selectedPlanFormData?.premiumamount,

          masterPolicyNumber: applicantDetails?.policyNumber,
        })
      } else {
        setPaymentStatusData({
          ...paymentData,
          insurance: selectedProductDetails?.insurer_name,
          masterPolicyNumber: applicantDetails?.policyNumber,
        })
      }
    }
  }, [paymentData])

  useEffect(() => {
    if (data?.status === 'APPROVED' || data?.status === 'INVALID') {
      setPollingIntervalVal(0)
      try {
        getPaymentStatusFunc()
      } catch (error) {
        ToastService.showErrorToast({
          title: 'Failed to get payment status',
          message: 'Failed to get payment status. Please try again later.',
        })
      }
    }
  }, [data])

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent w='500px'>
        <ModalHeader></ModalHeader>
        <ModalBody textAlign='center'>
          <Flex gap='1rem' fontSize='md' direction='column'>
            <Text>
              <br />
              {dataStatus === 'UNAPPROVED' ? (
                <>
                  Consent form link has been sent to <b>+91 {selectedAccountMobileNumber}</b> and{' '}
                  <b>{applicantFormData?.email}</b> :
                </>
              ) : dataStatus === 'APPROVED' ? (
                <>
                  Consent form has been accepted by the user{' '}
                  <b>+91 {selectedAccountMobileNumber}</b> and <b>{applicantFormData?.email}</b> :
                </>
              ) : (
                ''
              )}
            </Text>
            <Flex justifyContent='center' alignItems='center' gap='2'>
              <Text>
                {dataStatus === 'UNAPPROVED'
                  ? 'Waiting for customer to accept the consent'
                  : dataStatus === 'APPROVED'
                  ? 'Waiting for customer to do the payment'
                  : ''}
              </Text>
              <Spinner />
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent='center' alignItems='center' m='10'>
          <Button mr={3} onClick={handleResendLink} p='3' isDisabled={!resendLink}>
            {resendLink ? `Resend Link` : `Resend Link (00:${timeLeft})`}
            <br />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
