import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import { DashboardHeading, ElevatedButton, Pagination, TextInput } from 'Core'
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons'
import { Header } from 'Components'
// import { useMotorSalesBankQuery } from 'Services/API/motor.api'
import { IMotorSalesBankParams } from 'Models/RequestModels'
import { ChangeEvent, useEffect, useState } from 'react'
import { differenceInDays, format } from 'date-fns'
import { IMotorSalesBankItem } from 'Models/ResponseModels/motor'
import { copyToClipboard, decryptData } from 'Services/general'
import axios from 'axios'
import { API_ENDPOINTS, BASE_URL } from 'Constants'
import { getDefaultHeaders } from 'Services/baseQuery'
import { useAppSelector } from 'app/hooks'
import { ToastService } from 'Services/toast.service'
import { useSachetSalesReportQuery } from 'Services/API/sachet.api'

/**
 * @payment_status === 'pending ---> complete payment, copy payment link
 * @payment_status === 'failed' ---> retry payment, copy payment link
 * else ---> download pdf + (@allow_change for approve and reject cases)
 */
const getMotorSalesActionItems = (rowItem: IMotorSalesBankItem) => {
  if (rowItem.payment_status === 'pending') {
    if (
      new Date().getMonth() !== new Date(rowItem.creation_date ?? '').getMonth() ||
      new Date().getFullYear() !== new Date(rowItem.creation_date ?? '').getFullYear() ||
      new Date().getDate() - new Date(rowItem.creation_date ?? '').getDate() >= 1
    ) {
      return <Text>Payment Link Expired</Text>
    } else if (rowItem.payment_url) {
      return (
        <Flex direction='column'>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              window.open(rowItem.payment_url, '_blank')
            }}
          >
            Complete Payment
          </Button>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
            }}
          >
            Copy Payment Link
          </Button>
        </Flex>
      )
    } else {
      return <Text>-</Text>
    }
  } else if (rowItem.payment_status === 'failed') {
    if (
      new Date().getMonth() !== new Date(rowItem.creation_date ?? '').getMonth() ||
      new Date().getFullYear() !== new Date(rowItem.creation_date ?? '').getFullYear() ||
      new Date().getDate() - new Date(rowItem.creation_date ?? '').getDate() >= 1
    ) {
      return <Text>Payment Link Expired</Text>
    } else if (rowItem.payment_url) {
      return (
        <Flex direction='column'>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              window.open(rowItem.payment_url, '_blank')
            }}
          >
            Complete Payment
          </Button>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
            }}
          >
            Copy Payment Link
          </Button>
        </Flex>
      )
    } else {
      return <Text>-</Text>
    }
  } else if (rowItem.policy_pdf) {
    return (
      <Button
        size='xs'
        marginY='0.5rem'
        isDisabled={true}
        onClick={() => {
          window.open(rowItem.policy_pdf, '_blank')
        }}
        minWidth='4rem'
      >
        Download PDF
      </Button>
    )
  }
  return <Text>-</Text>
}

const SachetSalesReport = () => {
  const { authtoken } = useAppSelector((state) => state.user.authenticatedUser)

  const [queryParams, setQueryParams] = useState<any>({
    limit: '10',
    page: 1,
  })

  const [totalPages, setTotalPages] = useState(1)

  const { data: reportData, isFetching: isReportLoading } = useSachetSalesReportQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    const pages = Math.ceil((reportData?.data_count ?? 0) / parseInt(queryParams.limit))
    setTotalPages(pages)
  }, [reportData])

  const filterChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const timeoutId = setTimeout(() => {
      const allParams = {
        ...queryParams,
        [e.target.name]: e.target.value,
      }
      const allParamsArray = Object.entries(allParams)
      const filteredParams = allParamsArray.filter(([key, value]) => Boolean(value))
      const newParams = Object.fromEntries(filteredParams)
      setQueryParams((prev: any) => ({
        ...newParams,
        limit: prev.limit,
        page_number: prev.page_number,
      }))
    }, 900)
    return () => clearTimeout(timeoutId)
  }

  const [salesDownloadLoading, setSalesDownloadLoading] = useState(false)

  return (
    <>
      <Box position='sticky' top='0' zIndex='999'>
        <Header />
        <DashboardHeading title='Sachet Sales Report' />
        <Flex
          paddingX='1rem'
          paddingY='0.5rem'
          border='1px'
          alignItems='center'
          justifyContent='space-between'
          bgColor='#fff'
        >
          <Flex alignItems='center'>
            <Text fontWeight='bold' whiteSpace='nowrap' fontSize='md'>
              Items Per Page:
            </Text>
            <Select
              value={queryParams.limit}
              onChange={(e) => {
                setQueryParams((prev: any) => ({
                  ...prev,
                  limit: e.target.value,
                  page: 1,
                }))
              }}
              marginX='4'
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Select>
          </Flex>
          {/* <Button
              isDisabled={salesDownloadLoading}
              isLoading={salesDownloadLoading}
              onClick={async () => {
                setSalesDownloadLoading(true)
                try {
                  const resp = await axios({
                    baseURL: `${BASE_URL + API_ENDPOINTS.MOTOR_SALES_DOWNLOAD}`,
                    headers: getDefaultHeaders(authtoken),
                    params: {
                      ...queryParams,
                      download: true,
                    },
                  })
                  const csvData = new Blob([resp.data], { type: 'text/csv' })
                  const anchor = document.createElement('a')
                  anchor.href = URL.createObjectURL(csvData)
                  anchor.target = '_blank'
                  anchor.download = 'sales_report.csv'
                  anchor.click()
                } catch (e) {
                  ToastService.showErrorToast({
                    title: 'Something went wrong!',
                  })
                } finally {
                  setSalesDownloadLoading(false)
                }
              }}
              leftIcon={<DownloadIcon />}
              size='sm'
            >
              Download Report
            </Button> */}
        </Flex>
      </Box>
      <TableContainer border='1px' rounded='lg' margin='2'>
        <Table size='md' variant='simple' colorScheme='appGrey'>
          <Thead boxShadow='onlyBottom'>
            <Tr>
              <Th>Name</Th>
              <Th>Product Type</Th>
              <Th>Date of birth</Th>
              <Th>Transaction Number</Th>
              <Th>Product</Th>
              <Th>Address</Th>
              <Th>SP ID</Th>
              {/* <Th>Transaction Id</Th>
                <Th>Booking Date</Th>
                <Th>Policy Number</Th>
                <Th>Payment Status</Th>
                <Th>Policy Name</Th>
                <Th>Product Type</Th>
                <Th>Insured Name</Th>
                <Th>Gender</Th>
                <Th>DOB</Th>
                <Th isNumeric>Mobile</Th>
                <Th>Email</Th>
                <Th>Pincode</Th>
                <Th>SP ID</Th> */}
              {/* <Th>Address</Th> */}
              {/* <Th>Nominee Name</Th>
                  <Th>Relationship</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {/* <Td></Td> */}
              <Td>
                {/* <Input
                    placeholder='Search'
                    variant='filled'
                    onChange={filterChangeHandler}
                    name='name'
                  /> */}
              </Td>
              <Td>
                <Select variant='filled' name='product_code' onChange={filterChangeHandler}>
                  <option value=''>Select</option>
                  <option value='PMJJBY'>PMJJBY</option>
                  <option value='PMSBY'>PMSBY</option>
                  <option value='CIC'>Cancer Cover</option>
                </Select>
              </Td>
              <Td></Td>
              <Td>
                {/* <Input
                    placeholder='Search'
                    variant='filled'
                    onChange={filterChangeHandler}
                    name='transaction_number'
                  /> */}
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              {/* <Td></Td>
                <Td>
                  <Input
                    placeholder='Search'
                    variant='filled'
                    onChange={filterChangeHandler}
                    name='policy_number'
                  />
                </Td>
                <Td>
                  <Select variant='filled' name='payment_status' onChange={filterChangeHandler}>
                    <option value=''>Select</option>
                    <option value='pending'>Pending</option>
                    <option value='completed'>Completed</option>
                    <option value='failed'>Failed</option>
                  </Select>
                </Td>
                <Td>
                  <Input
                    placeholder='Search'
                    variant='filled'
                    onChange={filterChangeHandler}
                    name='insurance_name'
                  />
                </Td>
                <Td>
                  <Select variant='filled' name='product_type' onChange={filterChangeHandler}>
                    <option value=''>Select</option>
                    <option value='FOUR-WHEELER'>Four Wheeler</option>
                    <option value='TWO-WHEELER'>Two Wheeler</option>
                    <option value='GOODS CARRYING VEHICLE'>GCV</option>
                    <option value='PASSENGER CARRYING VEHICLE'>PCV</option>
                  </Select>
                </Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td> */}
              {/* <Td></Td> */}
              {/* <Td></Td>
                  <Td></Td> */}
            </Tr>
            {reportData && reportData.data_count === 0 && (
              <Tr margin='0 auto' width='100%' display='block'>
                <Text as={'td'} p='5' fontSize='4xl' color='#939393' align='center'>
                  No Data Found
                </Text>
              </Tr>
            )}
            {reportData && reportData?.data_count > 0
              ? reportData.results.map((item: any, idx: any) => {
                  return (
                    <Tr
                      bgColor={idx % 2 === 0 ? 'appGrey.100' : 'white'}
                      key={item.unique_identifier}
                    >
                      <Td>{item.name ? item.name : '-'}</Td>
                      <Td>{item.product_code ? item.product_code : '-'}</Td>
                      <Td>{item.date_of_birth ? item.date_of_birth : '-'}</Td>
                      <Td>
                        <Text>{item.transaction_number ? item.transaction_number : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.insurance ? item.insurance : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>
                          {item.address_line_1
                            ? item.address_line_1 ?? '' + ' ' + item.address_line_2 ?? ''
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>{item.sp_id ? item.sp_id : '-'}</Text>
                      </Td>
                      {/* <Td>
                          <Text>{item.transaction_id ?? '-'}</Text>
                        </Td>
                        <Td>
                          <Text>
                            {item?.creation_date
                              ? format(new Date(item.creation_date), 'dd/MM/yyyy')
                              : '-'}
                          </Text>
                        </Td>
                        <Td>
                          <Text>{item.policy_number ? decryptData(item.policy_number) : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.payment_status ? item.payment_status : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.insurance_name ? item.insurance_name : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.product_type ? item.product_type : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.customer_name ? decryptData(item.customer_name) : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.gender ? item.gender : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.dob ? format(new Date(item.dob), 'dd/MM/yyyy') : '-'}</Text>
                        </Td>
                        <Td isNumeric>
                          <Text>{item.mobile ? decryptData(item.mobile) : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.email ? decryptData(item.email) : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.pincode ? decryptData(item.pincode) : '-'}</Text>
                        </Td>
                        <Td>
                          <Text>{item.sp_code ? item.sp_code : '-'}</Text>
                        </Td> */}
                      {/* <Td whiteSpace='break-spaces'>
                            <Text>{item.address ? item.address : '-'}</Text>
                          </Td> */}
                      {/* <Td>
                            <Text>TODO</Text>
                          </Td>
                          <Td>
                            <Text>TODO</Text>
                          </Td> */}
                    </Tr>
                  )
                })
              : isReportLoading && (
                  <Tr>
                    <Spinner as='td' />
                  </Tr>
                )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Name</Th>
              <Th>Product Type</Th>
              <Th>Date of birth</Th>
              <Th>Transaction Number</Th>
              <Th>Product</Th>
              <Th>Address</Th>
              <Th>SP ID</Th>
              {/* <Th>Transaction Id</Th>
                <Th>Booking Date</Th>
                <Th>Policy Number</Th>
                <Th>Payment Status</Th>
                <Th>Policy Name</Th>
                <Th>Product Type</Th>
                <Th>Insured Name</Th>
                <Th>Gender</Th>
                <Th>DOB</Th>
                <Th isNumeric>Mobile</Th>
                <Th>Email</Th>
                <Th>Pincode</Th>
                <Th>SP ID</Th> */}
              {/* <Th>Address</Th> */}
              {/* <Th>Nominee Name</Th>
                  <Th>Relationship</Th> */}
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <Flex justifyContent='center'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<ChevronRightIcon transform='scale(2)' />}
          onPageChange={(selectedPage) => {
            setQueryParams((prev: any) => ({
              ...prev,
              page: selectedPage.selected + 1,
            }))
          }}
          pageCount={totalPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          previousLabel={<ChevronLeftIcon transform='scale(2)' />}
          forcePage={queryParams.page_number && queryParams.page_number - 1}
          className='paginate'
          pageClassName='page-item'
          nextLinkClassName='paginate-button'
          previousLinkClassName='paginate-button'
          activeClassName='active-page'
        />
      </Flex>
    </>
  )
}

export { SachetSalesReport }
