import { Button, Center, Flex, Image, ModalFooter, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import successImg from 'Assets/Common/Success.webp'

export const PaymentSuccessDialog = (props: {
  transactionId: string
  salesReportRoute: string
}) => {
  const navigate = useNavigate()

  return (
    <Flex paddingY='1rem' paddingX='1.5rem' gap='1rem' direction='column' alignItems='center'>
      <Image width='15rem' src={successImg} />
      <Text fontSize='3rem' fontWeight='extrabold'>
        Thank You
      </Text>
      <Text fontSize='1.2rem'>Your payment was successful!</Text>
      <Text fontSize='1rem'>Transaction ID: {props.transactionId}</Text>
      <ModalFooter>
        <Button
          onClick={() => {
            navigate('/customer-account')
          }}
          marginX='0.5rem'
        >
          Back Home
        </Button>
        <Button
          marginX='0.5rem'
          onClick={() => {
            navigate(props.salesReportRoute)
          }}
        >
          Sales Report
        </Button>
      </ModalFooter>
      {/* <Text>Confirmation e-mail sent to: abcd@test.com</Text>
      <Text>Order Number: 785883000469</Text> */}
    </Flex>
  )
}
