import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  useDisclosure,
  useMediaQuery,
  InputGroup,
  InputRightElement,
  HStack,
  PinInputField,
  PinInput,
  Button,
} from '@chakra-ui/react'
import { CommonImages } from 'Assets/Common'
import { Header } from 'Components'
import { mobileNumRegex } from 'Constants'
import { ElevatedButton, ErrorText, TextInput } from 'Core'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useCheckAuthRoleMutation,
  useLoginWithOTPMutation,
  useSendOTPMutation,
} from 'Services/API/authentication.api'
import {
  decryptData,
  encryptData,
  onlyAllowTypingNumbers,
  onlyAllowTypingNumbers2,
} from 'Services/general'
import { RegisterDistributer } from '../RegisterDistributer'
import { useForm } from 'react-hook-form'
import { ToastService } from 'Services/toast.service'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

const Login = () => {
  const { register, formState, trigger, setValue, clearErrors, watch, getValues } = useForm()

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })

  const [loginWithOTP, { isLoading, isError, isSuccess }] = useLoginWithOTPMutation()

  const [sendOTP, { isLoading: isOtpLoading, isError: isOtpError }] = useSendOTPMutation()

  const [checkRole, { isLoading: isCheckRoleLoading, isSuccess: isCheckRoleSuccess }] =
    useCheckAuthRoleMutation()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const navigate = useNavigate()

  const [mobileNum, setMobileNum] = useState('')
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [roleName, setRoleName] = useState('')
  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (roleName) {
      checkAuthRole()
    }
  }, [roleName])

  const checkAuthRole = async () => {
    try {
      await checkRole({
        role_type: roleName,
      }).unwrap()
      // navigate('/customer-account')
    } catch (e) {
      console.log(e)
    }
  }

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  return (
    <Box>
      <Header />
      <Flex height='100vh' justifyContent='space-around'>
        {isLargerThan768 && (
          <Flex
            height='100%'
            maxW='100%'
            alignItems='center'
            justifyContent='center'
            flexBasis='50%'
          >
            {/* Image Goes Here */}
            <Image padding='50' alt='logo' src={CommonImages.loginImage} />
          </Flex>
        )}
        <Flex
          direction='column'
          flexBasis={isLargerThan768 ? '50%' : '80%'}
          alignItems='center'
          justifyContent='center'
          maxW='100%'
        >
          {/* Input Fields Goes Here */}
          <form
            style={{ minWidth: '300px' }}
            onSubmit={async (e) => {
              e.preventDefault()
              try {
                if (!isOtpSent) {
                  const response = await sendOTP({ phone: mobileNum }).unwrap()
                  setIsOtpSent(true)
                  ToastService.showSuccessTaost({
                    title: 'Success',
                    message: `OTP has been sent successfully to ${mobileNum}`,
                  })
                  startTimer()
                } else {
                  let response = await loginWithOTP({
                    payload: encryptData(JSON.stringify({ phone: mobileNum, otp: otp })),
                  }).unwrap()
                  response = JSON.parse(decryptData(response.response_data ?? ''))
                  setRoleName(response.businessrole_name ?? '')
                  // navigate('/customer-account')
                }
              } catch (e: any) {
                if (!isOtpSent) {
                  console.log(e)
                  // ToastService.showErrorToast({ title: 'Error', message: e['message'] })
                }
              }
              // if (businessrole_name === 'NSP') {
              // navigate('/customer-account')
              // } else {
              //   navigate('/')
              // }
            }}
          >
            <FormControl>
              <Text fontWeight='bold' fontSize='2xl'>
                Login
              </Text>
              <Flex direction='column' marginY='4'>
                <FormLabel>Phone</FormLabel>
                <TextInput
                  maxLength={10}
                  onKeyPress={onlyAllowTypingNumbers2}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete='off'
                  // onChange={(e) =>
                  //   setFormData((prev) => {
                  //     return { ...prev, username: e.target.value }
                  //   })
                  // }
                  isInvalid={isError}
                  {...register('mobileNumber', {
                    required: true,
                    pattern: mobileNumRegex,
                    onChange: (e) => setMobileNum(e.target.value),
                  })}
                  name='mobileNumber'
                  placeholder='Enter Phone Number'
                />
                {formState.errors.mobileNumber &&
                  formState.errors.mobileNumber?.type === 'required' && (
                    <ErrorText text='Mobile no. is required' />
                  )}
                {formState.errors.mobileNumber &&
                  formState.errors.mobileNumber?.type === 'pattern' && (
                    <ErrorText text={'Invalid Mobile Number'} />
                  )}
              </Flex>
              {isOtpSent && (
                <Flex direction='column' marginY='4'>
                  <FormLabel>Enter OTP</FormLabel>
                  <HStack>
                    <PinInput
                      value={otp}
                      onChange={(str) => {
                        setOtp(str)
                      }}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                  {/* <FormLabel>Password</FormLabel>
                <InputGroup>
                  <TextInput
                    onChange={(e) =>
                      setFormData((prev) => {
                        return { ...prev, password: e.target.value }
                      })
                    }
                    isInvalid={isError}
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                  />
                  <InputRightElement
                    cursor='pointer'
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? (
                      <ViewOffIcon color='secondary.500' />
                    ) : (
                      <ViewIcon color='secondary.500' />
                    )}
                  </InputRightElement>
                </InputGroup> */}
                </Flex>
              )}
              <Center paddingY='5'>
                <Flex direction='row'>
                  {isOtpSent && (
                    <Button
                      disabled={!resendOtp}
                      isLoading={isOtpLoading}
                      onClick={async () => {
                        setOtp('')
                        try {
                          const response = await sendOTP({ phone: mobileNum }).unwrap()
                          // setIsOtpSent(true)
                          ToastService.showSuccessTaost({
                            title: 'Success',
                            message: `OTP has been sent successfully to ${mobileNum}`,
                          })
                        } catch (e: any) {
                          ToastService.showErrorToast({ title: 'Error', message: e['message'] })
                        }
                        setResendOtp(false)
                        startTimer()
                      }}
                      marginX='0.5rem'
                      borderRadius='3xl'
                    >
                      {resendOtp ? 'Resend OTP' : `Resend OTP in ${timeLeft}`}
                    </Button>
                  )}
                  <ElevatedButton
                    isLoading={(isOtpLoading && !resendOtp) || isLoading || isCheckRoleLoading}
                    isDisabled={!mobileNumRegex.test(mobileNum)}
                    type='submit'
                    borderRadius='3xl'
                    paddingX='10'
                  >
                    {!isOtpSent ? 'Send OTP' : 'Submit'}
                  </ElevatedButton>
                </Flex>
              </Center>
              {/* <Flex alignItems='center'>
                <Divider />
                <Text paddingX='4'>OR</Text>
                <Divider />
              </Flex> */}
              {/* <Flex direction='column' marginY='4'>
                <FormLabel>Mobile No.</FormLabel>
                <TextInput
                  maxLength={10}
                  onKeyDown={onlyAllowTypingNumbers}
                  placeholder='e.g. 9876543210'
                  value={mobileNum}
                  onChange={(e) => setMobileNum(e.target.value)}
                />
              </Flex> */}
              {/* <Center paddingY='5'>
                <ElevatedButton
                  isDisabled={!mobileNumRegex.test(mobileNum)}
                  variant='outline'
                  borderRadius='3xl'
                  paddingX='10'
                >
                  Send OTP
                </ElevatedButton>
              </Center> */}
              {/* <Center whiteSpace='nowrap'>
                <Text
                  onClick={onOpen}
                  cursor='pointer'
                  fontWeight='bold'
                  color='primary.600'
                  fontSize='large'
                >
                  Register here
                </Text>
                <Text fontWeight='bold'>&nbsp;to become our distributer</Text>
              </Center> */}
            </FormControl>
          </form>
        </Flex>
      </Flex>
      <Modal
        size={isLargerThan768 ? '2xl' : 'xs'}
        motionPreset='scale'
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        {/* <ModalHeader>Modal Title</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalContent>
          <RegisterDistributer />
        </ModalContent>
      </Modal>
    </Box>
  )
}

export { Login }
