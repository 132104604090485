import { Box, Flex, ListItem, ListItemProps } from '@chakra-ui/react'

interface IListTile extends ListItemProps {
  children: React.ReactNode
}

const ListTile = (props: IListTile) => {
  const { children, ...rest } = props
  return <ListItem {...rest}>{children}</ListItem>
}

export { ListTile }
