const colors = {
  primary: {
    50: '#5574A7',
    100: '#3E619B',
    200: '#335895',
    300: '#274E8F',
    400: '#1B4282',
    500: '#193E7B',
    600: '#16376E',
    700: '#153467',
    800: '#112952',
    900: '#0F264B',
  }, // Green in case of bimaboss
  secondary: {
    50: '#DA5863',
    100: '#D84D59',
    200: '#D5414E',
    300: '#D33644',
    400: '#D02B39',
    500: '#C41E2D',
    600: '#AF1B28',
    700: '#A51A26',
    800: '#901621',
    900: '#7C131C',
  }, // Blue in case of bimaboss
  tertiary: {
    50: '#dbebf7',
    100: '#c9e1f3',
    200: '#b7d7ef',
    300: '#93c3e8',
    400: '#6fafe0',
    500: '#4b9cd8',
    600: '#448cc2',
    700: '#3c7cad',
    800: '#2d5d82',
    900: '#264e6c',
  }, // sky blue bimaboss
  focus: '#7FAC07',
  warning: '#D0342C',
  appGrey: {
    50: '#FAFAFA',
    100: '#E8E8E8',
    200: '#E0E0E0',
    300: '#B3B3B3',
    400: '#DEDEDE',
    500: '#999999',
    600: '#8A8B8B',
  },
  appDarkGrey: '#E8E8E8',
} as const

export type Colors = typeof colors
export default colors
