import { Box, Flex, Text } from '@chakra-ui/react'
import { useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import { FileUpload } from 'Core'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { UseFormReturn } from 'react-hook-form'
import {
  useUploadImageRCBankMutation,
  useUploadPolicyPdfBankMutation,
} from 'Services/API/equitas.api'
import { ToastService } from 'Services/toast.service'
import { TMotorProposalFormType } from './Forms/FormModel'
import { setProposalValueInForm } from './ProposalUtils'
// import { useUploadImageRCMutation, useUploadPolicyPdfMutation } from 'Services/API/motor.api'

interface IUploadDocuments {
  form: UseFormReturn<TMotorProposalFormType, any>
  rounded?: boolean
}

const UploadDocuments = (props: IUploadDocuments) => {
  const { form: proposalForm, rounded } = props

  // const { proposalDetails: proposalDetailsInStore } = useAppSelector((state) => state.proposalPage)

  const [uploadRc, { isLoading: isRcDataLoading, isError: isRcError }] =
    useUploadImageRCBankMutation()
  const [uploadPolicyPdf, { isLoading: isPolicyDataLoading, isError: isPolicyError }] =
    useUploadPolicyPdfBankMutation()

  const [rcFile, setRcFile] = useState<File | null>(null)
  const [rcPreview, setRcPreview] = useState<string>('')

  const [policyFile, setPolicyFile] = useState<File | null>(null)

  const onRcDrop = useCallback((acceptedFiles: File[]) => {
    const sizeInMbs = acceptedFiles[0].size / (1024 * 1024)
    if (sizeInMbs > 15) {
      ToastService.showErrorToast({
        title: 'File Size Error!',
        message: 'File size has to be less than 15 MB',
      })
      return
    }
    setRcFile(acceptedFiles[0])
    setRcPreview(URL.createObjectURL(acceptedFiles[0]))
    const form = new FormData()
    form.append('file', acceptedFiles[0], acceptedFiles[0].name)
    uploadRc(form).then((data) => {
      const proposalDetailsInStore = store.getState().proposalPage.proposalDetails
      setProposalValueInForm(proposalDetailsInStore, proposalForm)
    })
  }, [])

  const rcUpload = useDropzone({
    onDrop: onRcDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
  })

  useEffect(() => {
    if (isRcError) {
      setRcFile(null)
      setRcPreview('')
    }
  }, [isRcError])

  useEffect(() => {
    if (isPolicyError) {
      setPolicyFile(null)
    }
  }, [isPolicyError])

  const onPolicyDrop = useCallback((acceptedFiles: File[]) => {
    const sizeInMbs = acceptedFiles[0].size / (1024 * 1024)
    if (sizeInMbs > 15) {
      ToastService.showErrorToast({
        title: 'File Size Error!',
        message: 'File size has to be less than 15 MB',
      })
      return
    }
    setPolicyFile(acceptedFiles[0])
    const form = new FormData()
    form.append('file', acceptedFiles[0], acceptedFiles[0].name)
    console.log(form)
    uploadPolicyPdf(form).then((data) => {
      console.log(form, 'form')
      const proposalDetailsInStore = store.getState().proposalPage.proposalDetails
      setProposalValueInForm(proposalDetailsInStore, proposalForm)
    })
  }, [])

  const prevPolicyUpload = useDropzone({
    onDrop: onPolicyDrop,
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  return (
    <Flex border='1px' borderRadius={rounded ? 'lg' : ''} direction='column' alignItems='center'>
      <Box width='100%' padding='2' boxShadow='excludingTop'>
        <Text fontSize='md' textAlign='center' fontWeight='bold'>
          Use our smart tool to pre-fill information in this form
        </Text>
      </Box>
      <Box marginY='4' marginX='3'>
        <Flex marginY='2' direction='column'>
          <Text paddingY='2' fontWeight='medium' fontSize='sm'>
            Existing RC
          </Text>
          <FileUpload
            uploadDesc='Drag and drop here or upload RC image(.jpeg/.jpg/.png, UPTO 15MB)'
            fileName={rcFile?.name}
            getInputProps={rcUpload.getInputProps}
            getRootProps={rcUpload.getRootProps}
            isLoading={isRcDataLoading}
            imagePreviewUrl={rcPreview}
            isImage
            handleDelete={() => setRcFile(null)}
          />
        </Flex>
        <Flex marginY='2' direction='column'>
          <Text paddingY='2' fontWeight='medium' fontSize='sm'>
            Existing Insurance Policy
          </Text>
          <FileUpload
            uploadDesc='Drag and drop here or upload Policy pdf(.pdf, UPTO 15MB)'
            fileName={policyFile?.name}
            getInputProps={prevPolicyUpload.getInputProps}
            getRootProps={prevPolicyUpload.getRootProps}
            isLoading={isPolicyDataLoading}
            handleDelete={() => setPolicyFile(null)}
          />
        </Flex>
      </Box>
    </Flex>
  )
}

export { UploadDocuments }
