import { Box, BoxProps, Button } from '@chakra-ui/react'
import { MultiValue, Select } from 'chakra-react-select'
import { ElementWithLabel, ErrorText } from 'Core'
// import { INestedQuestionSet } from 'Models/BOB/ResponseModels'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { getDropdownArrayFromRecord } from 'Services/general'
import RenderChild from './RenderChild'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import { store } from 'app/store'
import axios, { AxiosResponse } from 'axios'
import { IApiResponse2 } from 'Models/ResponseModels'
import { PRODUCT_URL } from 'Constants'
import { getDefaultHeaders2 } from 'Services/baseQuery'

interface IProps extends BoxProps {
  isRoot: boolean
  parentData: { name: string; value: string | number }
  name: string
  dropdownItems: Record<string, string | number>
  childrenData?: Record<string, INestedQuestionSet[]>
  form: UseFormReturn<any, any>
  // isDefaultSelected?: boolean
  value?: { label: string; value: string | number }[]
  label?: string
  initialValues?: Record<string, string | number | boolean | string[]>
  cover_type?: INestedQuestionSet['cover_type']
  factor?: number
  operation?: string
  affect?: string
  isWithoutLabel?: boolean
  isDisabled?: boolean
  defaultValue?: string
  // onAffect?: (name: string, value: string | number) => void
  onAffect?: (
    name: string,
    value: string | number,
    affect: string,
    factor?: number,
    operation?: string,
  ) => void
  disabledCodes?: string[]
  required?: boolean
  call_api?: boolean
}

const RenderMultiSelect = (props: IProps) => {
  const {
    name,
    dropdownItems,
    childrenData,
    form,
    isRoot,
    parentData,
    // isDefaultSelected = false,
    value,
    label,
    initialValues,
    cover_type,
    affect,
    onAffect,
    isWithoutLabel,
    defaultValue,
    isDisabled = false,
    disabledCodes,
    operation,
    required,
    call_api,
    ...rest
  } = props

  // const dropdownArray = getDropdownArrayFromRecord(dropdownItems)
  const [customError, setCustomError] = useState('')

  const [dropdownArray, setDropdownArray] = useState<
    {
      label: string
      value: string | number
    }[]
  >([])

  const [dropdownValue, setDropdownValue] = useState<
    | {
        label: string
        value: string | number
      }[]
    | null
  >(value ? value : null)

  useEffect(() => {
    let valid
    if (form.getValues(name) !== null && form.getValues(name) !== undefined) {
      valid = form.getValues(name).includes(dropdownArray[0])
    } else {
      valid = false
    }
    if (operation === 'always_select_first' && !valid) {
      if (dropdownValue === null) {
        form.setValue(name, [dropdownArray[0].value])
        setDropdownValue([{ label: dropdownArray[0].label, value: dropdownArray[0].value }])
      }
    }
    if (operation === 'default_select_all' && !valid) {
      const t: string[] = []
      if (dropdownValue === null) {
        dropdownArray.map((element) => t.push(element.value.toString()))
        form.setValue(name, t)
        setDropdownValue([...dropdownArray])
      }
    }
  }, [dropdownValue])

  // useEffect(() => {
  //   if (call_api) {
  //     updateListing()
  //   }
  // }, [dropdownValue])

  const updateListing = async () => {
    if (call_api) {
      const insurer = store.getState().lifeQuotationDetails.finalSelectedPlan?.insurer
      const insurerProductCode = store.getState().lifeQuotationDetails.finalSelectedPlan?.code
      try {
        const resp: AxiosResponse<
          IApiResponse2<{
            values: Record<string, string>
            default: string
            is_disabled: boolean
            field_validation: {
              min: string | number
              max: string | number
              req_fields?: string[]
              error_msg?: string
            }
          }>
        > = await axios({
          url: `${PRODUCT_URL}/product/get-choices/${insurer}/${insurerProductCode}/${name}/`,
          method: 'POST',
          data: {
            ...form.getValues(),
            is_same:
              store.getState().lifeQuotationDetails.relationship?.toLowerCase() === 'self'
                ? true
                : false,
          },
          headers: getDefaultHeaders2({
            token: store.getState().user.authenticatedUser.authtoken ?? '',
          }),
        })

        const fieldValidationData = resp.data.data?.field_validation
        if (fieldValidationData) {
          if (fieldValidationData?.error_msg) {
            setCustomError(fieldValidationData?.error_msg)
            // await form.trigger(name)
          } else {
            setCustomError('')
          }
        }

        if (resp.data.message?.toLowerCase() !== 'success') {
          throw Error('Show default values!')
        }

        const listingData = resp.data.data?.values
        if (listingData) setDropdownArray(getDropdownArrayFromRecord(listingData))
      } catch (e) {
        setDropdownArray(getDropdownArrayFromRecord(dropdownItems))
      }
    } else {
      setDropdownArray(getDropdownArrayFromRecord(dropdownItems))
    }
  }

  useEffect(() => {
    updateListing()
  }, [call_api, parentData.value])

  const handleChange = async (
    newVal: MultiValue<{
      label: string
      value: string | number
    }>,
  ) => {
    form.setValue(
      name,
      newVal.map((item) => item.value),
    )
    form.clearErrors(name)
    setDropdownValue([...newVal])
    if (call_api) {
      updateListing()
    }
  }

  useEffect(() => {
    if (initialValues && initialValues[name]) {
      const prefillValue = initialValues[name] as string[]
      const intersectingItems: {
        label: string
        value: string | number
      }[] = []

      for (let i = 0; i < (initialValues[name] as string[]).length; i++) {
        if (dropdownArray.length < 3) {
          intersectingItems.push({
            label: (initialValues[name] as string)[i],
            value: (initialValues[name] as string)[i],
          })
        }
      }
      handleChange(intersectingItems)
    }
  }, [initialValues])

  return (
    <>
      <ElementWithLabel
        required={name === 'additional_riders' ? false : true}
        marginY='0.7rem'
        height='fit-content'
        label={isWithoutLabel ? '' : label || name}
        {...rest}
      >
        <Box fontWeight={'normal'}>
          <Select
            value={dropdownValue}
            isMulti
            useBasicStyles
            focusBorderColor='primary.300'
            menuPosition='absolute'
            // onFocus={updateListing}
            onMenuOpen={updateListing}
            // onMenuClose={updateListing}
            options={dropdownArray}
            //   onChange={(newVal) => setDropdownValue(newVal)}
            {...form.register(name, {
              required: required,
              onBlur: () => {
                updateListing()
              },
            })}
            onChange={handleChange}
          />
        </Box>
        {customError !== '' ? <ErrorText text={customError} /> : null}
        {form.formState.errors[name] && <ErrorText text={`${label} is required!`} />}
      </ElementWithLabel>
      {childrenData &&
        Object.keys(childrenData).length > 0 &&
        dropdownValue &&
        dropdownValue.length > 0 &&
        dropdownValue.map((dropdownVal) => {
          return (
            Boolean(childrenData[dropdownVal.value]) &&
            childrenData[dropdownVal.value].map((item, index) => {
              return (
                <RenderChild
                  key={index}
                  childField={item}
                  form={form}
                  parentData={{
                    name: name,
                    value: dropdownVal.value,
                    cover_type: cover_type,
                  }}
                  defaultValue={item.default}
                  initialValues={initialValues}
                  factor={item.factor}
                  operation={item.operation}
                  affect={item.affect}
                  onAffect={onAffect}
                  call_api={call_api}
                  required={required}
                />
              )
            })
          )
        })}
    </>
  )
}

export { RenderMultiSelect }
