import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
} from '@chakra-ui/react'
import { ElevatedButton, ErrorText, ToggleSwitch } from 'Core'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useGetInsurersQuery } from 'Services/API/motor.api'
import { ODPolicyDetailsForm } from './ODPolicyDetailsForm'
import { TPPolicyDetailsForm } from './TPPolicyDetailsForm'
import { TVehicleInsuranceFormType } from './FormModel/VehicleInsurance'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useShowSaod, useVehicleType } from 'app/hooks'
import { updateHasPreviousPolicy, updateIsSaod, updateVehicleInsuranceForm } from 'features/Motor'

interface IVehicleInsuranceForm {
  form: UseFormReturn<TVehicleInsuranceFormType, any>
}

const VehicleInsuranceForm = (props: IVehicleInsuranceForm) => {
  const { form: vehicleInsuranceForm } = props

  const {
    vehicleDetails: vehicleDetailsInStore,
    vehicleInsuranceDetails: vehicleInsuranceDetailsInStore,
  } = useAppSelector((state) => state.vehicleDetails)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)

  const { showSaod } = useShowSaod()

  const { name: vehicleName, param: vehicleSelected } = useVehicleType()
  const [isSaod, setIsSaod] = useState(vehicleInsuranceDetailsInStore.isSaod)

  const [hasPreviousPolicy, setHasPreviousPolicy] = useState(
    vehicleInsuranceDetailsInStore.hasPreviousPolicy,
  )

  useEffect(() => {
    if (!showSaod) {
      setIsSaod(false)
    }
  }, [showSaod])

  useEffect(() => {
    dispatch(updateHasPreviousPolicy(hasPreviousPolicy))
  }, [hasPreviousPolicy])

  useEffect(() => {
    if (!isSaod) {
      vehicleInsuranceForm.resetField('TpPreviousPolicyNumber')
      vehicleInsuranceForm.resetField('TpPreviousInsurerName')
      vehicleInsuranceForm.resetField('tp_policy_start_date')
      vehicleInsuranceForm.resetField('tp_policy_end_date')
    }
  }, [isSaod])

  useEffect(() => {
    dispatch(updateIsSaod(isSaod))
  }, [isSaod])

  const { data: insurersDropdownData, isFetching: isInsurersDataLoading } = useGetInsurersQuery({
    insurer_type: 'motor',
  })

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    selectedManufacturer,
    selectedModel,
    selectedVariant,
    manufacturingYear,
    registrationDate,
    seatsGreaterThanSix,
    typeOfVehicle_commercial,
    showNameConsent,
  } = useAppSelector((state) => state.vehicleDetails.vehicleDetails)

  const { showIRDAError } = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)

  const [isFormEnabled, setIsFormEnabled] = useState(false)

  useEffect(() => {
    if (
      selectedManufacturer &&
      selectedModel &&
      selectedVariant &&
      manufacturingYear &&
      registrationDate
    ) {
      if (seatsGreaterThanSix === true && vehicleSelected === 'is_pcv') {
        if (typeOfVehicle_commercial) {
          setIsFormEnabled(true)
        } else {
          setIsFormEnabled(false)
        }
      } else {
        setIsFormEnabled(true)
      }
    } else {
      setIsFormEnabled(false)
    }
  }, [
    selectedManufacturer,
    selectedModel,
    selectedVariant,
    manufacturingYear,
    registrationDate,
    seatsGreaterThanSix,
    typeOfVehicle_commercial,
  ])

  useEffect(() => {
    const {
      existingPolicyExpiryDate,
      previousInsurer,
      previousPolicyType,
      ncb,
      permitType,
      TpPreviousPolicyNumber,
      TpPreviousInsurerName,
      tpPolicyStartDate,
      tpPolicyEndDate,
    } = vehicleInsuranceDetailsInStore
    existingPolicyExpiryDate &&
      vehicleInsuranceForm.setValue('previous_policy_end_date', existingPolicyExpiryDate)
    previousInsurer && vehicleInsuranceForm.setValue('previous_insurer', previousInsurer)
    previousPolicyType && vehicleInsuranceForm.setValue('previous_policy_type', previousPolicyType)
    ncb && vehicleInsuranceForm.setValue('ncb', ncb)
    permitType &&
      vehicleInsuranceForm.setValue('permit_type', {
        name: permitType.name,
        code: permitType.code,
      })
    TpPreviousPolicyNumber &&
      vehicleInsuranceForm.setValue('TpPreviousPolicyNumber', TpPreviousPolicyNumber)
    TpPreviousInsurerName &&
      vehicleInsuranceForm.setValue('TpPreviousInsurerName', TpPreviousInsurerName)
    tpPolicyStartDate && vehicleInsuranceForm.setValue('tp_policy_start_date', tpPolicyStartDate)
    tpPolicyEndDate && vehicleInsuranceForm.setValue('tp_policy_end_date', tpPolicyEndDate)
  }, [vehicleInsuranceDetailsInStore])

  const onSubmit: SubmitHandler<TVehicleInsuranceFormType> = (data) => {
    console.log(data)
    dispatch(updateVehicleInsuranceForm(data))
    navigate(`/motor/${vehicleName.toLowerCase()}/quotation`)
  }

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Box className={!isFormEnabled ? 'disabled' : ''} paddingY='4'>
      {showSaod && (
        <Flex
          className={hasPreviousPolicy ? '' : 'disabled'}
          direction='column'
          marginY='4'
          marginX='6'
        >
          <FormLabel>Buy Standalone Own Damage Policy</FormLabel>
          <ToggleSwitch enabled={isSaod} setEnabled={setIsSaod} />
        </Flex>
      )}
      {/* <pre>watch{JSON.stringify(vehicleInsuranceForm.watch(), null, 2)}</pre>
      <h1>___________________________________________________</h1>
      <pre>store{JSON.stringify(vehicleInsuranceDetailsInStore, null, 2)}</pre> */}
      <form onSubmit={vehicleInsuranceForm.handleSubmit(onSubmit)}>
        {isSaod ? (
          <Accordion index={tabIndex}>
            <AccordionItem boxShadow='DEFAULT' marginY='2'>
              <h2>
                <AccordionButton onClick={() => setTabIndex(0)} paddingY='4'>
                  <Box flex='1' textAlign='left'>
                    OD Policy Details
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <ODPolicyDetailsForm
                  form={vehicleInsuranceForm}
                  insurersDropdownData={insurersDropdownData}
                  hasPreviousPolicy={hasPreviousPolicy}
                  setHasPreviousPolicy={setHasPreviousPolicy}
                  isInsurersDataLoading={isInsurersDataLoading}
                />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem boxShadow='DEFAULT' marginY='2'>
              <h2>
                <AccordionButton onClick={() => setTabIndex(1)} paddingY='4'>
                  <Box flex='1' textAlign='left'>
                    TP Policy Details
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <TPPolicyDetailsForm
                  form={vehicleInsuranceForm}
                  insurersDropdownData={insurersDropdownData}
                  isInsurersDataLoading={isInsurersDataLoading}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ) : (
          <>
            {showSaod && <Divider />}
            <ODPolicyDetailsForm
              form={vehicleInsuranceForm}
              insurersDropdownData={insurersDropdownData}
              hasPreviousPolicy={hasPreviousPolicy}
              setHasPreviousPolicy={setHasPreviousPolicy}
              isInsurersDataLoading={isInsurersDataLoading}
            />
          </>
        )}
        {showNameConsent === true && quotationDetails.isIndividual && (
          <Box marginY='4' marginX='6'>
            <Checkbox {...vehicleInsuranceForm.register('nameConsent', { required: true })}>
              I have verified the name of the customer as per Equitas record vs the name of the
              customer as per Vahan. I confirm that both details belong to the same customer
            </Checkbox>
            {vehicleInsuranceForm.formState.errors.nameConsent &&
              vehicleInsuranceForm.formState.errors.nameConsent.type === 'required' && (
                <ErrorText text='Name Consent is required' />
              )}
          </Box>
        )}
        <Center paddingTop='4'>
          <ElevatedButton isDisabled={showIRDAError} type='submit' colorScheme='primary'>
            Get Quotes
          </ElevatedButton>
        </Center>
      </form>
      {/* <ElevatedButton onClick={() => console.log(vehicleInsuranceForm.formState.errors)}>
        Show errors
      </ElevatedButton> */}
    </Box>
  )
}

export { VehicleInsuranceForm }
