import { AppDispatch } from 'app/store'
import {
  DIRECT_DEBIT_ACCOUNT_RELATIONSHIP,
  DIRECT_DEBIT_MASTER,
  DIRECT_DEBIT_PRODUCT,
  DONT_SHOW_ACCT_STATUS_MASTER,
  DONT_SHOW_ACCT_STATUS_PRODUCT,
  PAYMENT_GATEWAY_PRODUCT,
} from 'Constants'
import {
  setCustomerAccountsStatus,
  setCustomerDetailsStatus,
  setSelectedAccountPaymentMode,
  updateCkycNumber,
  updateCustomerAccounts,
  updateProposalDetailsFromCBS,
  updateUcicId,
} from 'features/Equitas'
import {
  updateCustomerAge,
  updateCustomerMobileNumber,
} from 'features/Equitas/CustomerDetails.equitas.slice'
import { ICustomerDetails, ICustomerSearchDetails, TAccount } from 'Models/ResponseModels/Equitas'
import { calculateAgeFromDob, getAgeObjFromDob2, intersectionBasedOnKey } from 'Services/general'

const customerSearchLoadingHandler = (dispatch: AppDispatch) => {
  dispatch(updateCustomerAccounts([])) // clear previous accounts
  dispatch(setCustomerAccountsStatus('loading')) // reset state to not fetched
}

const customerSearchSuccessHandler = (
  dispatch: AppDispatch,
  searchResponse: ICustomerSearchDetails,
  customerDetails: ICustomerDetails,
) => {
  let validAccounts = intersectionBasedOnKey(
    searchResponse.accounts,
    'show_account_number',
    customerDetails.accDtls.accDtlsRp,
    'accNb',
  )

  const dontShowAccountArrMaster = DONT_SHOW_ACCT_STATUS_MASTER.map((item) => {
    return item.cod_acct_status.toString()
  })

  const dontShowAccountProduct = DONT_SHOW_ACCT_STATUS_PRODUCT.map((item) => item.toString())

  // console.log('----------dontShowAccountArrMaster----------')
  // console.log(dontShowAccountArrMaster)

  // console.log('----------dontShowAccountArrProduct----------')
  // console.log(dontShowAccountProduct)

  // console.log('------searchResponse.accounts-------')
  // console.log(searchResponse.accounts)

  // console.log('------customerDetails.accDtls.accDtlsRp-------')
  // console.log(customerDetails.accDtls.accDtlsRp)

  // console.log('----------valid accounts-----------')
  // console.log(validAccounts)

  validAccounts = validAccounts.filter((account) => {
    if (account.accNb?.startsWith('1') || account.accNb?.startsWith('2')) return account
  })

  const filteredAccountsFromMaster = validAccounts.filter((account) => {
    if (account.accStsCode) return !dontShowAccountArrMaster.includes(account.accStsCode.toString())

    return []
  })

  // console.log('----------filtered accounts from master----------')
  // console.log(filteredAccountsFromMaster)

  if (filteredAccountsFromMaster.length === 0) {
    throw Error('No Accounts Found!')
  }

  const filteredAccountFromProduct = filteredAccountsFromMaster.filter((account) => {
    if (account.acctProdCode)
      return !dontShowAccountProduct.includes(account.acctProdCode.toString())
  })

  // console.log('-----------filtered accounts from product-----------')
  // console.log(filteredAccountFromProduct)

  if (filteredAccountFromProduct.length === 0) {
    throw Error('No Accounts Found!')
  }

  dispatch(setCustomerAccountsStatus('success')) // customer search action
  dispatch(updateUcicId(searchResponse.ucic)) // customer search action
  dispatch(updateCustomerAccounts(filteredAccountFromProduct))
  dispatch(updateCkycNumber(searchResponse.ckyc_number ?? ''))
  dispatch(updateCustomerMobileNumber(searchResponse.phone ?? '')) // customer search action - TODO: filter this
}

const customerSearchErrorHandler = (dispatch: AppDispatch) => {
  dispatch(setCustomerAccountsStatus('error')) // customer search action
  dispatch(updateCustomerAccounts([])) // customer search action
}

const customerDetailsLoadingHandler = (dispatch: AppDispatch) => {
  dispatch(setCustomerDetailsStatus('loading')) // cbs v3 action
}

const customerDetailsSuccessHandler = (
  dispatch: AppDispatch,
  customerDetails: ICustomerDetails,
) => {
  dispatch(updateProposalDetailsFromCBS(customerDetails)) // cbs v3 action
  dispatch(setCustomerDetailsStatus('success'))
  dispatch(
    updateCustomerAge(
      getAgeObjFromDob2(new Date(customerDetails.CustmrFlds?.CustmrFldsRp.dob ?? '')),
    ),
  ) // cbs v3 action
}

const customerDetailsErrorHandler = (dispatch: AppDispatch) => {
  dispatch(setCustomerDetailsStatus('error')) // cbs v3 action
}

/**
 *
 * if all three sheet's key are of type direct debit,
 * then only make it direct debit
 */
const getPgAndDirectDebitLogicFromSelectedAccount = (account: TAccount, dispatch: AppDispatch) => {
  const directDebitMasterArr = DIRECT_DEBIT_MASTER.map((item) => item.toString())
  const directDebitProductArr = DIRECT_DEBIT_PRODUCT.map((item) => item.toString())
  const directDebitAccRelationshipArr = DIRECT_DEBIT_ACCOUNT_RELATIONSHIP.map((item) =>
    item.toString(),
  )

  let isAccountDDFromMaster = false
  let isAccountDDFromProduct = false
  let isAccountDDFromAccRelationship = false

  if (directDebitMasterArr.includes(account.accStsCode.toString())) {
    isAccountDDFromMaster = true
  } else {
    isAccountDDFromMaster = false
  }

  if (directDebitProductArr.includes(account.acctProdCode.toString())) {
    isAccountDDFromProduct = true
  } else {
    isAccountDDFromProduct = false
  }

  if (isAccountDDFromMaster === true && isAccountDDFromProduct === true) {
    if (directDebitAccRelationshipArr.includes(account.CustAcctRelation.toString())) {
      isAccountDDFromAccRelationship = true
    } else {
      isAccountDDFromAccRelationship = false
    }
  }
  // if (isAccountDDFromMaster && isAccountDDFromProduct) {
  //   console.log('isAccountDDFromAccRelationship', isAccountDDFromAccRelationship)
  // } else {
  //   console.log('isAccountDDFromAccRelationship', 'doesnt matter :P')
  // }

  if (isAccountDDFromMaster && isAccountDDFromProduct && isAccountDDFromAccRelationship) {
    // console.log('THIS IS DD CASE!')
    dispatch(setSelectedAccountPaymentMode('DirectDebit'))
    return
  }

  // console.log('THIS IS PG CASE!')
  dispatch(setSelectedAccountPaymentMode('PG'))
  return

  // // logic of product and account relation mapping
  // const paymentGatewayProductArr = PAYMENT_GATEWAY_PRODUCT.map((item) => item.toString())
  // const directDebitAccountRelationshipArr = DIRECT_DEBIT_ACCOUNT_RELATIONSHIP.map((item) =>
  //   item.toString(),
  // )

  // console.log('--------------paymentGatewayProductArr-----------')
  // console.log(paymentGatewayProductArr)

  // if (paymentGatewayProductArr.includes(account.acctProdCode.toString())) {
  //   console.log('THIS IS PG CASE!')
  //   dispatch(setSelectedAccountPaymentMode('PG'))
  //   return
  // }

  // console.log('--------------directDebitAccountRelationshipArr-----------')
  // console.log(directDebitAccountRelationshipArr)

  // if (directDebitAccountRelationshipArr.includes(account.CustAcctRelation.toString())) {
  //   console.log('THIS IS DIRECT DEBIT CASE!')
  //   dispatch(setSelectedAccountPaymentMode('DirectDebit'))
  //   return
  // }

  // console.log('THIS IS PG CASE!')
  // dispatch(setSelectedAccountPaymentMode('PG'))
  // return
}

export {
  customerSearchLoadingHandler,
  customerSearchSuccessHandler,
  customerSearchErrorHandler,
  customerDetailsLoadingHandler,
  customerDetailsSuccessHandler,
  customerDetailsErrorHandler,
  getPgAndDirectDebitLogicFromSelectedAccount,
}
