import { Box, Button, Divider, Flex, Icon, Text, Tooltip, VStack } from '@chakra-ui/react'
import { useLifeQuotation } from './useLifeQuotation'
import { RiderItem } from './RiderItem'
import { CloseIcon } from '@chakra-ui/icons'
import { useAppDispatch } from 'app/hooks'
import { NestedForm } from 'Core/NestedForm'

interface IFilterSideBar {
  visible?: boolean
  setvisible?: any
}
const FilterSideBar = (props: IFilterSideBar) => {
  const { visible, setvisible } = props
  const {
    onFilterDialogClose,
    planOptionQuestionList,
    form,
    riderForm,
    riderFormData,
    filterFormData,
    onFormSubmit,
    onRiderFormSubmit,
    ridersQuestions,
    isGetQuotationsApiLoading,
    onAffect,
    clearRider,
    toggleIsBiFormGenerated,
    // onClearRidersClick,
  } = useLifeQuotation()
  const dispatch = useAppDispatch()
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        // if (Object.values(riderForm.getValues()).filter((value) => value).length) {
        //   riderForm.handleSubmit(onRiderFormSubmit)()
        // }
        form.handleSubmit(onFormSubmit)()
      }}
      style={{ height: '100%' }}
    >
      <Box
        // display={visible ? { 'box' : 'box'}}
        display={{
          lg: 'block',
          md: visible ? 'box' : 'none',
          sm: visible ? 'box' : 'none',
          xs: visible ? 'box' : 'none',
        }}
        zIndex={'999'}
        position={'relative'}
        alignItems={'space-between'}
        overflow={'hidden'}
        height={'full'}
      >
        <VStack
          // divider={<StackDivider borderColor='gray.200' />}
          spacing={4}
          overflowY={'scroll'}
          overflowX={'hidden'}
          boxShadow={'innerBottom'}
          height={'calc(100% - 40px) '}
          paddingX='2.5rem'
          paddingY='1.5rem'
          align='stretch'
          bgColor='#fff'
        >
          {planOptionQuestionList && (
            <>
              <NestedForm
                fieldsData={planOptionQuestionList}
                form={form}
                initialValues={filterFormData}
                onAffect={onAffect}
                disabledCodes={['entry_age', 'consume_tobacco']}
              />
              {ridersQuestions && ridersQuestions.length ? (
                <Box>
                  <Divider marginY={'1rem'} />
                  <VStack
                    // divider={<StackDivider borderColor='gray.200' />}
                    paddingBottom={'3rem'}
                    spacing={4}
                    align='stretch'
                    bgColor='#fff'
                  >
                    {ridersQuestions && ridersQuestions.length && (
                      <Box>
                        <Flex alignItems='center' justifyContent='space-between'>
                          <Text fontSize={'lg'} fontWeight={'bold'} marginBottom={'1rem'}>
                            Riders
                          </Text>
                          {Object.values(riderForm.getValues()).filter((v) => v).length > 0 ? (
                            <Button
                              variant={'link'}
                              size={'sm'}
                              onClick={() => {
                                clearRider()
                              }}
                            >
                              Clear Rider
                            </Button>
                          ) : null}

                          {/* <Tooltip label='Clear Riders'>
                            <CloseIcon
                              onClick={() => onClearRidersClick()}
                              cursor='pointer'
                              boxSize={3}
                            />
                          </Tooltip> */}
                        </Flex>
                        <Flex direction={'column'} gap={'3'}>
                          <NestedForm
                            fieldsData={ridersQuestions}
                            form={riderForm}
                            initialValues={riderFormData}
                          />
                        </Flex>
                      </Box>
                    )}
                  </VStack>
                </Box>
              ) : (
                ''
              )}
            </>
          )}
        </VStack>

        <Flex marginTop='auto' position='sticky' bottom='0'>
          <Button
            type='submit'
            // onClick={() => {
            //   setvisible(false)
            //   onFilterDialogClose
            // }}
            variant='solid'
            rounded='none'
            isLoading={isGetQuotationsApiLoading}
            flexBasis='100%'
          >
            Apply
          </Button>
        </Flex>
      </Box>
    </form>
  )
}

export { FilterSideBar }
