import React from 'react'
import { ManufacturerCard } from './ManufacturerCard'

const VehicleDetails = () => {
  return (
    <div>
      <ManufacturerCard />
    </div>
  )
}

export { VehicleDetails }
