import { AppDispatch } from 'app/store'
import { resetUser, updateRoleVerified } from 'features/Authentication'
import { resetCustomerDetails, resetSpTaggingForm } from 'features/Equitas'
import { resetLeadDetails } from 'features/Leads'
import { resetProposal, resetQuotation, resetVehicleDetails } from 'features/Motor'

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function dispatchLogoutActions(dispatch: AppDispatch) {
  dispatch(resetUser())
  dispatch(resetCustomerDetails())
  dispatch(resetLeadDetails())
  dispatch(resetVehicleDetails())
  dispatch(resetQuotation())
  dispatch(resetProposal())
  dispatch(resetSpTaggingForm())
  dispatch(updateRoleVerified(false))
}

export { capitalizeFirstLetter, dispatchLogoutActions }
