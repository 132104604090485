import { Box, Flex, Skeleton, Stack, Text } from '@chakra-ui/react'
import { theme } from 'Core'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { IndianRupeeFormatFromString } from 'Services/general'
import { IInformationCardProps } from '../interface'

export const InformationCardMobile = (props: IInformationCardProps) => {
  return (
    <>
      <Flex direction='column' paddingBottom='6'>
        <Text
          noOfLines={2}
          overflow='hidden'
          fontSize='lg'
          marginY='1'
          fontWeight='bold'
          textAlign='center'
        >
          {props.insurerName}
        </Text>
        <Box
          border='1px'
          borderColor='appGrey.200'
          marginY='auto'
          marginX='auto'
          backgroundColor='white'
        >
          <img src={props.image} className='w-[9vh] m-2 h-[6vh]' />
        </Box>
        {props.loading ? (
          <Stack p='4'>
            <Skeleton height='15px' />
            <Skeleton height='15px' />
            <Skeleton height='15px' />
          </Stack>
        ) : (
          <Box>
            <Box fontSize='xs' mt='4'>
              <Text>
                <span className='font-bold'>Sum Insured :</span> ₹
                {IndianRupeeFormatFromString(props.sumAssured)}
              </Text>
              <Text>
                <span className='font-bold'>Policy For :</span> {props.policyFor}
              </Text>
              <Text>
                <span className='font-bold'>Pincode : </span>
                {props.pincode}
              </Text>
            </Box>
            <Box>
              <Flex direction={'column'}>
                <Text fontWeight='bold' fontSize='lg'>
                  Final Premium
                </Text>
                <Flex gap='2'>
                  <Text fontWeight='bold' fontSize='md'>
                    ₹{IndianRupeeFormatFromString(props!.finalPremium)}
                  </Text>
                  <Text fontSize='xs' marginY='auto'>
                    Inc. GST
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Box>
        )}
      </Flex>
    </>
  )
}
