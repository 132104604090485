import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ElevatedButton, PaymentSuccessDialog } from 'Core'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useLazyPaymentCheckQuery } from 'Services/API/motor.api'
import { copyToClipboard } from 'Services/general'
import { TMotorProposalFormType } from '../Forms/FormModel'
import { updatePaymentSuccess } from 'features/Motor'

interface IProposalPaymentDesktop {
  rounded?: boolean
  form: UseFormReturn<TMotorProposalFormType, any>
}

const ProposalPaymentDesktop = (props: IProposalPaymentDesktop) => {
  const dispatch = useAppDispatch()

  const {
    isOpen: isPaymentSuccessOpen,
    onOpen: onPaymentSuccessOpen,
    onClose: onPaymentSuccessClose,
  } = useDisclosure()

  const { rounded = false } = props
  const {
    proposalStatus,
    proposalSuccessResponse,
    proposalErrorResponse,
    paymentActivated,
    paymentSuccess,
  } = useAppSelector((state) => state.proposalPage)

  const unique_identifier = useAppSelector(
    (state) => state.quotationsDetails.selectedQuote?.unique_identifier,
  )

  const [pollingInterval, setPollingInterval] = useState(2000)

  const [paymentCheck, { data: paymentCheckResponse, isError: paymentCheckIsError }] =
    useLazyPaymentCheckQuery({
      pollingInterval: pollingInterval,
    })

  const [isPaymentCheckRunning, setIsPaymentCheckRunning] = useState(false)

  // const [paymentCheckCompleted, setPaymentCheckCompleted] = useState(false)

  useEffect(() => {
    if (paymentCheckResponse?.is_completed === true) {
      setPollingInterval(0)
      setIsPaymentCheckRunning(false)
      // setPaymentCheckCompleted(true)
      dispatch(updatePaymentSuccess(true))
      onPaymentSuccessOpen()
    }
  }, [paymentCheckResponse])

  return (
    <>
      <Flex
        flexBasis='40%'
        border='1px'
        borderRightRadius={rounded ? 'lg' : ''}
        maxWidth='100%'
        direction='column'
      >
        <Box padding='3' boxShadow='excludingTop'>
          <Text fontSize='md' textAlign='left' paddingX='4' paddingY='2' fontWeight='bold'>
            Payment
          </Text>
        </Box>
        {paymentSuccess === true ? (
          <Flex justifyContent='center' alignItems='center'>
            <Text paddingY='2rem'>Your Payment was successful!</Text>
          </Flex>
        ) : (
          <Box className={paymentActivated ? '' : 'disabled'}>
            {proposalStatus === 'error' ? (
              <Flex padding='5'>
                <Text color='red'>{proposalErrorResponse?.toString()}</Text>
              </Flex>
            ) : (
              <Flex alignItems='center' direction='column' padding='10'>
                <Flex paddingY='8'>
                  <Box fontSize='sm' paddingX='2'>
                    <Text paddingY='2'>Payment Amount:</Text>
                    <Text paddingY='2'>GST:</Text>
                    <Text paddingY='2'>You&apos;ll Pay:</Text>
                  </Box>
                  <Box fontSize='sm' paddingX='2'>
                    <Text paddingY='2'>
                      ₹{' '}
                      {proposalSuccessResponse.premium
                        ? Math.round(proposalSuccessResponse.premium).toLocaleString('en-IN')
                        : 0}
                    </Text>
                    <Text paddingY='2'>
                      ₹{' '}
                      {proposalSuccessResponse.taxes
                        ? Math.round(proposalSuccessResponse.taxes).toLocaleString('en-IN')
                        : 0}
                    </Text>
                    <Text paddingY='2'>
                      ₹{' '}
                      {proposalSuccessResponse.premium_total
                        ? Math.round(proposalSuccessResponse.premium_total).toLocaleString('en-IN')
                        : 0}
                    </Text>
                  </Box>
                </Flex>
                {proposalSuccessResponse.is_breakin_case === true ? (
                  <Text>
                    {proposalSuccessResponse.breakin_msg ?? 'This is the Break-in case. '}
                  </Text>
                ) : (
                  <Flex marginTop='10' direction='column'>
                    <ElevatedButton
                      onClick={() => copyToClipboard(proposalSuccessResponse.payment_url ?? '')}
                      colorScheme='primary'
                      margin='1'
                      variant='outline'
                      size='sm'
                    >
                      COPY PAYMENT LINK
                    </ElevatedButton>
                    <ElevatedButton
                      isLoading={isPaymentCheckRunning}
                      onClick={async () => {
                        window.open(proposalSuccessResponse.payment_url, '_blank')
                        await paymentCheck({
                          unique_identifier: unique_identifier ?? '',
                        })
                        setIsPaymentCheckRunning(true)
                      }}
                      colorScheme='primary'
                      margin='1'
                      size='sm'
                    >
                      PROCEED TO PAY
                    </ElevatedButton>
                  </Flex>
                )}
              </Flex>
            )}
          </Box>
        )}
      </Flex>
      <Modal isCentered isOpen={isPaymentSuccessOpen} onClose={onPaymentSuccessClose}>
        <ModalOverlay />
        <ModalContent>
          <PaymentSuccessDialog
            salesReportRoute='/sales/motor'
            transactionId={paymentCheckResponse?.transaction_id ?? ''}
          />
        </ModalContent>
      </Modal>
    </>
  )
}

export { ProposalPaymentDesktop }
