import {
  Text,
  Flex,
  Image,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Center,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormLabel,
  InputGroup,
  Divider,
  InputLeftAddon,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { FaShareAlt } from 'react-icons/fa'
import { emailRegex, mobileNumRegex, onlyNumbersRegex } from 'Constants'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import { useAppSelector, useAppDispatch, useVehicleType } from 'app/hooks'
import { IAdditionalCover, ITransformedAddons, Quotations } from 'Models/ResponseModels'
import { updateSelectedQuote } from 'features/Motor'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorText, TextInput } from 'Core'
import { useSendEmailMutation, useSendSmsMutation } from 'Services/API/equitas.api'
import { ToastService } from 'Services/toast.service'
import { onlyAllowTypingNumbers2 } from 'Services/general'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TCustomerDetails } from 'Models/RequestModels'

const Compare = ({
  isOpen,
  onClose,
  onOpen,
  quotationsListing,
  selectedQuotes,
  addOns,
  addOnsToDisplay,
  setAddOnsToDisplay,
  coversToDisplay,
  setCoversToDisplay,
  ownDamageCovers,
  liabilityCovers,
  subRequestId,
  selectedAddons,
  selectedCovers,
}: {
  ownDamageCovers: IAdditionalCover[]
  liabilityCovers: IAdditionalCover[]
  coversToDisplay: any[]
  setCoversToDisplay: any
  addOnsToDisplay: any[]
  setAddOnsToDisplay: any
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  quotationsListing: Quotations[]
  selectedQuotes: number[]
  addOns: ITransformedAddons[]
  subRequestId: string
  selectedAddons: any
  selectedCovers: any
}) => {
  const homePageDetails = useAppSelector((state) => state.homePage)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const customerDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isOpen: isShareOpen, onClose: onShareClose, onOpen: onShareOpen } = useDisclosure()
  const { name: vehicleType } = useVehicleType()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [imageLoadFail, setImageLoadFail] = useState(false)
  const [image, setImage] = useState(null)

  const { register, handleSubmit, formState, watch } = useForm<TCustomerDetails>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const doc = new jsPDF({
    orientation: 'landscape',
  })
  const htmlRef: any = useRef<HTMLTableElement>(null)
  //   const [addOnsToDisplay, setAddOnsToDisplay] = useState<any[]>([])

  const [sendEmail, { isLoading }] = useSendEmailMutation()
  const [sendSms, { isLoading: isSmsLoading }] = useSendSmsMutation()

  useEffect(() => {
    handleAddOns()
    handleCovers()
  }, [selectedQuotes])

  const handleCovers = () => {
    setCoversToDisplay([])
    // quotationsListing.map((quote) => {
    //   if (selectedQuotes.indexOf(quote.insurer_id) !== -1) {
    //     if (quote.cover_discount && quote.cover_discount.length !== 0) {
    //       Object.keys(quote.cover_discount).map((key: string) => {
    //         let name = ''
    //         ownDamageCovers.map((cover) => {
    //           if (cover.code === key) {
    //             name = cover.name ?? ''
    //           }
    //         })
    //         liabilityCovers.map((cover) => {
    //           if (cover.code === key) {
    //             name = cover.name ?? ''
    //           }
    //         })
    //         if (name) {
    //           const t = {
    //             name: name,
    //             code: key,
    //           }
    //           if (coversToDisplay.indexOf(t) === -1) {
    //             setCoversToDisplay((prev: any) => [...prev, t])
    //           }
    //         }
    //       })
    //     }
    //   }
    // })
    selectedCovers.forEach((value: number, key: string) => {
      if (value !== -1) {
        let name = ''
        ownDamageCovers.map((cover) => {
          if (cover.code === key) {
            name = cover.name ?? ''
          }
        })
        liabilityCovers.map((cover) => {
          if (cover.code === key) {
            name = cover.name ?? ''
          }
        })
        const t = {
          name: name,
          code: key,
        }
        setCoversToDisplay((prev: any) => [...prev, t])
      }
      // else {
      //   quotationsListing.map((quote) => {
      //     if (selectedQuotes.indexOf(quote.insurer_id) !== -1) {
      //       if (Object.keys(quote.cover_discount).length !== -1) {
      //         Object.keys(quote.cover_discount).map((value) => {
      //           let name = ''
      //           ownDamageCovers.map((cover) => {
      //             if (cover.code === value) {
      //               name = cover.name ?? ''
      //             }
      //           })
      //           liabilityCovers.map((cover) => {
      //             if (cover.code === value) {
      //               name = cover.name ?? ''
      //             }
      //           })
      //           if (name) {
      //             const t = {
      //               name: name,
      //               code: value,
      //             }
      //             if (coversToDisplay.indexOf(name) === -1) {
      //               setCoversToDisplay((prev: any) => [...prev, t])
      //             }
      //           }
      //         })
      //       }
      //     }
      //   })
      // }
    })
    // quotationsListing.map((quote) => {
    //   if (selectedQuotes.indexOf(quote.insurer_id) !== -1) {
    //     if (Object.keys(quote.cover_discount).length !== 0) {
    //       Object.keys(quote.cover_discount).map((value) => {
    //         if (quote.cover_discount[value] === -2 || quote.cover_discount[value] > 0) {
    //           ownDamageCovers.map((val) => {
    //             if (val.code === value) {
    //               let flag = false
    //               const t = {
    //                 name: val.name,
    //                 code: val.code,
    //               }
    //               coversToDisplay.forEach((item) => {
    //                 if (item.code === val.code) {
    //                   flag = true
    //                 }
    //               })
    //               if (!flag) {
    //                 let temp = []
    //                 temp = coversToDisplay
    //                 temp.push(t)
    //                 setCoversToDisplay(temp)
    //               }
    //             }
    //           })
    //           liabilityCovers.map((val) => {
    //             if (val.code === value) {
    //               let flag = false
    //               const t = {
    //                 name: val.name,
    //                 code: val.code,
    //               }
    //               coversToDisplay.forEach((item) => {
    //                 if (item.code === val.code) {
    //                   flag = true
    //                 }
    //               })
    //               if (!flag) {
    //                 let temp = []
    //                 temp = coversToDisplay
    //                 temp.push(t)
    //                 setCoversToDisplay(temp)
    //               }
    //             }
    //           })
    //         }
    //       })
    //     }
    //   }
    // })
  }

  const handleAddOns = () => {
    setAddOnsToDisplay([])
    // quotationsListing.map((quote) => {
    //   if (selectedQuotes.indexOf(quote.insurer_id) !== -1) {
    //     if (quote.add_ons && quote.add_ons.length !== 0) {
    //       quote.add_ons.forEach((key: string, value: number) => {
    //         addOns.map((addOn) => {
    //           let name = ''
    //           if (addOn.code === key) {
    //             name = addOn.name ?? ''
    //           }
    //         })
    //         const t = {
    //           name: name,
    //           code: key,
    //         }
    //         setAddOnsToDisplay((prev: any) => [...prev, t])
    //       })
    //     }
    //   }
    // })
    selectedAddons.forEach((value: boolean, key: string) => {
      if (value) {
        let name = ''
        addOns.map((addOn) => {
          if (addOn.code === key) {
            name = addOn.name ?? ''
          }
        })
        const t = {
          name: name,
          code: key,
        }
        if (addOnsToDisplay.indexOf(t) === -1) {
          setAddOnsToDisplay((prev: any) => [...prev, t])
        }
      }
    })
  }

  const OrSeprator = () => {
    return (
      <Flex alignItems='center' paddingY='1rem'>
        <Divider orientation='horizontal' />
        <Box
          padding='0.3rem'
          margin='0.5rem'
          border='1px solid'
          borderColor='gray.200'
          borderRadius='full'
        >
          <Text fontSize='sm'>OR</Text>
        </Box>
        <Divider orientation='horizontal' />
      </Flex>
    )
  }

  const onSubmit: SubmitHandler<TCustomerDetails> = async (data) => {
    console.log(data)
    if (data['email']) {
      const canvas = await html2canvas(htmlRef.current)
      try {
        const response = await sendEmail({
          unique_identifier: subRequestId,
          ucic_id: customerDetails.ucic,
          content_data: {
            insurance_type: 'Motor',
            lead_uid: leadDetails.lead_uid,
            plan_name: insuranceDetails.isSaod
              ? 'Stand Alone OD'
              : quotationDetails.isComprehensive
              ? 'Comprehensive'
              : 'Third Party',
            customer_name:
              (customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
                customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0]
                  .frsNm) ??
              '',
            current_date: new Date().toLocaleDateString(),
            salutation:
              customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr === 'M'
                ? 'Mr.'
                : customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.mrtlSts === '1'
                ? 'Mrs.'
                : 'Ms.',
          },
          message_type: 'content_message',
          reciever_mail: data['email'],
          message_body: 'Please find the comparison of quotes',
          subject_line: 'Comparison',
          file_attachment: canvas.toDataURL(),
        }).unwrap()
        ToastService.showSuccessTaost({
          title: 'Success',
          message: 'Email sent successfully',
        })
      } catch (e) {
        console.log(e)
        ToastService.showErrorToast({
          title: 'Failure',
          message: 'Could not send email',
        })
      }
      onShareClose()
    }
    if (data['phone']) {
      const canvas = await html2canvas(htmlRef.current)
      try {
        const response = await sendSms({
          unique_identifier: subRequestId,
          ucic_id: customerDetails.ucic,
          content_data: {
            insurance_type: 'Motor',
            lead_uid: leadDetails.lead_uid,
            plan_name: insuranceDetails.isSaod
              ? 'Stand Alone OD'
              : quotationDetails.isComprehensive
              ? 'Comprehensive'
              : 'Third Party',
            customer_name:
              (customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
                customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0]
                  .frsNm) ??
              '',
            current_date: new Date().toLocaleDateString(),
            salutation:
              customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr === 'M'
                ? 'Mr.'
                : customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.mrtlSts === '1'
                ? 'Mrs.'
                : 'Ms.',
          },
          message_type: 'content_message',
          phone_number: '91' + data['phone'],
          message_body: 'Please find the comparison of quotes',
          subject_line: 'Comparison',
          file_attachment: canvas.toDataURL(),
        }).unwrap()
        ToastService.showSuccessTaost({
          title: 'Success',
          message: 'Sms sent successfully',
        })
      } catch (e) {
        console.log(e)
        ToastService.showErrorToast({
          title: 'Failure',
          message: 'Could not send sms',
        })
      }
      onShareClose()
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='bottom'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader shadow='DEFAULT'>
          <Flex direction='row' justifyContent='space-between' alignItems='center' width='100%'>
            <Text>Policy Comparison</Text>
            <Button
              variant='outline'
              marginRight='6'
              marginY='auto'
              colorScheme='tertiary'
              onClick={onShareOpen}
              // onClick={async () => {
              //   const canvas = await html2canvas(htmlRef.current)
              //   const a = document.createElement('a')
              //   a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
              //   a.download = 'share.jpg'
              //   a.click()
              // }}
            >
              SHARE
            </Button>
            {/* <Box marginRight='6' cursor='pointer'>
              <FaShareAlt />
            </Box> */}
          </Flex>
        </DrawerHeader>
        <Center>
          <DrawerCloseButton />
        </Center>
        <DrawerBody>
          <TableContainer>
            <Table colorScheme='blackAlpha' ref={htmlRef}>
              <Thead>
                <Tr>
                  <Th>
                    <Flex direction='column' fontSize='12'>
                      <Text>
                        {vehicleDetails.selectedManufacturer?.name}{' '}
                        {vehicleDetails.selectedModel?.name}{' '}
                        {vehicleDetails.selectedVariant?.name.replaceAll('-', '')}
                      </Text>
                      <Text>Mfg. Year: {vehicleDetails.manufacturingYear}</Text>
                      <Text>
                        RTO: {homePageDetails.vehicleNumber.substring(0, 5).replaceAll('-', '')}
                      </Text>
                      <Text>NCB: {insuranceDetails.ncb}%</Text>
                      <Text>Policy Expiry Date: {insuranceDetails.existingPolicyExpiryDate}</Text>
                    </Flex>
                  </Th>
                  {quotationsListing.map((quote) =>
                    selectedQuotes.indexOf(quote.insurer_id) !== -1 ? (
                      <Th>
                        <Flex direction='column'>
                          <Text>{quote.insurance_name}</Text>
                          <Image
                            height='70px'
                            width='100px'
                            src={quote.insurer_logo}
                            onError={() => setImageLoadFail(true)}
                            border='1px solid #F1F3F4'
                            marginRight='4'
                            display={imageLoadFail ? 'none' : ''}
                            data-html2canvas-ignore
                          />
                        </Flex>
                      </Th>
                    ) : null,
                  )}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Premium (Inc. GST)</Td>
                  {quotationsListing.map((quote) =>
                    selectedQuotes.indexOf(quote.insurer_id) != -1 ? (
                      <Td>
                        <Text>₹ {Math.round(quote.premium_total).toLocaleString('en-IN')}</Text>
                      </Td>
                    ) : null,
                  )}
                </Tr>
                <Tr>
                  <Td>IDV</Td>
                  {quotationsListing.map((quote) =>
                    selectedQuotes.indexOf(quote.insurer_id) != -1 ? (
                      <Td>
                        <Text>₹ {Math.round(quote.idv).toLocaleString('en-IN')}</Text>
                      </Td>
                    ) : null,
                  )}
                </Tr>
                {addOnsToDisplay.length !== 0 && (
                  <Tr>
                    <Td>
                      <b>Add Ons</b>
                    </Td>
                    {selectedQuotes.map((quote) => (
                      <Td key={quote}></Td>
                    ))}
                  </Tr>
                )}
                {!!(addOnsToDisplay && addOnsToDisplay.length) &&
                  addOnsToDisplay.map((val) => (
                    <Tr key={val.code}>
                      <Td>{val.name}</Td>
                      {quotationsListing.map((quote) =>
                        selectedQuotes.indexOf(quote.insurer_id) != -1 ? (
                          <Td key={quote.insurer_id}>
                            <Text>
                              {quote.add_ons[val.code] == -2 || quote.add_ons[val.code] > 0 ? (
                                <CheckIcon color='green' />
                              ) : (
                                <CloseIcon color='red' />
                              )}
                            </Text>
                          </Td>
                        ) : null,
                      )}
                    </Tr>
                  ))}
                {coversToDisplay.length !== 0 && (
                  <Tr>
                    <Td>
                      <b>Covers</b>
                    </Td>
                    {selectedQuotes.map((quote) => (
                      <Td key={quote}></Td>
                    ))}
                  </Tr>
                )}
                {!!(coversToDisplay && coversToDisplay.length) &&
                  coversToDisplay.map((val) => (
                    <Tr key={val.code}>
                      <Td>{val.name}</Td>
                      {quotationsListing.map((quote) =>
                        selectedQuotes.indexOf(quote.insurer_id) != -1 ? (
                          <Td>
                            <Text>
                              {quote.cover_discount[val.code] == -2 ||
                              quote.cover_discount[val.code] > 0 ? (
                                <CheckIcon color='green' />
                              ) : (
                                <CloseIcon color='red' />
                              )}
                            </Text>
                          </Td>
                        ) : null,
                      )}
                    </Tr>
                  ))}
                <Tr>
                  <Td></Td>
                  {quotationsListing.map((quote) =>
                    selectedQuotes.indexOf(quote.insurer_id) != -1 ? (
                      <Td data-html2canvas-ignore>
                        <Button
                          variant='outline'
                          colorScheme='tertiary'
                          onClick={() => {
                            dispatch(updateSelectedQuote({ selectedQuote: quote }))
                            navigate(`/motor/${vehicleType.toLowerCase()}/proposal`)
                          }}
                        >
                          BUY NOW
                        </Button>
                      </Td>
                    ) : null,
                  )}
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          <Modal isOpen={isShareOpen} onClose={onShareClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Share Comparison</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* <TextInput type='email' onChange={(e) => setEmail(e.target.value)} /> */}
                  <Flex direction='column' marginY='2' marginX='6'>
                    <FormLabel>Phone Number</FormLabel>
                    <InputGroup>
                      <InputLeftAddon>+91</InputLeftAddon>
                      <TextInput
                        // onKeyDown={onlyAllowTypingNumbers}
                        onKeyPress={onlyAllowTypingNumbers2}
                        {...register('phone', {
                          required: false,
                          pattern: mobileNumRegex,
                          // maxLength: 10,
                        })}
                        maxLength={10}
                        placeholder='Enter Phone Number'
                      ></TextInput>
                    </InputGroup>
                    {formState.errors.phone && formState.errors.phone?.type === 'pattern' && (
                      <ErrorText text={'Invalid Mobile Number'} />
                    )}
                  </Flex>
                  <Flex direction='column' marginY='2' marginX='6'>
                    <OrSeprator />
                  </Flex>
                  <Flex direction='column' marginY='2' marginX='6'>
                    <FormLabel>Email ID</FormLabel>
                    <TextInput
                      {...register('email', { required: false, pattern: emailRegex })}
                      placeholder='Enter Email ID'
                    ></TextInput>
                    {formState.errors.email && formState.errors.email?.type === 'pattern' && (
                      <ErrorText text={'Invalid Email'} />
                    )}
                  </Flex>
                  <Center>
                    <Button
                      type='submit'
                      isLoading={isLoading || isSmsLoading}
                      // onClick={async () => {
                      //   const canvas = await html2canvas(htmlRef.current)
                      //   try {
                      //     const response = await sendEmail({
                      //       unique_identifier: subRequestId,
                      //       content_data: {
                      //         insurance_type: 'Motor',
                      //         plan_name: insuranceDetails.isSaod
                      //           ? 'Stand Alone OD'
                      //           : quotationDetails.isComprehensive
                      //           ? 'Comprehensive'
                      //           : 'Third Party',
                      //         customer_name:
                      //           (customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                      //             .IndCustDtlsRp &&
                      //             customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                      //               .IndCustDtlsRp[0].frsNm) ??
                      //           '',
                      //         current_date: new Date().toLocaleDateString(),
                      //         salutation:
                      //           customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      //             .gndr === 'M'
                      //             ? 'Mr.'
                      //             : customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      //                 .mrtlSts === '1'
                      //             ? 'Mrs.'
                      //             : 'Ms.',
                      //       },
                      //       message_type: 'content_message',
                      //       reciever_mail: email,
                      //       message_body: 'Please find the comparison of quotes',
                      //       subject_line: 'Comparison',
                      //       file_attachment: canvas.toDataURL(),
                      //     }).unwrap()
                      //     ToastService.showSuccessTaost({
                      //       title: 'Success',
                      //       message: 'Email sent successfully',
                      //     })
                      //   } catch (e) {
                      //     console.log(e)
                      //     ToastService.showErrorToast({
                      //       title: 'Failure',
                      //       message: 'Could not send email',
                      //     })
                      //   }
                      //   onShareClose()
                      // }}
                    >
                      Share
                    </Button>
                  </Center>
                </form>
              </ModalBody>
              {/* <Center>
                <ModalFooter>
                  <Button variant='outline' color='tertiary' marginRight='2' onClick={onShareClose}>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    isLoading={isLoading}
                    // onClick={async () => {
                    //   const canvas = await html2canvas(htmlRef.current)
                    //   try {
                    //     const response = await sendEmail({
                    //       unique_identifier: subRequestId,
                    //       content_data: {
                    //         insurance_type: 'Motor',
                    //         plan_name: insuranceDetails.isSaod
                    //           ? 'Stand Alone OD'
                    //           : quotationDetails.isComprehensive
                    //           ? 'Comprehensive'
                    //           : 'Third Party',
                    //         customer_name:
                    //           (customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                    //             .IndCustDtlsRp &&
                    //             customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                    //               .IndCustDtlsRp[0].frsNm) ??
                    //           '',
                    //         current_date: new Date().toLocaleDateString(),
                    //         salutation:
                    //           customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                    //             .gndr === 'M'
                    //             ? 'Mr.'
                    //             : customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                    //                 .mrtlSts === '1'
                    //             ? 'Mrs.'
                    //             : 'Ms.',
                    //       },
                    //       message_type: 'content_message',
                    //       reciever_mail: email,
                    //       message_body: 'Please find the comparison of quotes',
                    //       subject_line: 'Comparison',
                    //       file_attachment: canvas.toDataURL(),
                    //     }).unwrap()
                    //     ToastService.showSuccessTaost({
                    //       title: 'Success',
                    //       message: 'Email sent successfully',
                    //     })
                    //   } catch (e) {
                    //     console.log(e)
                    //     ToastService.showErrorToast({
                    //       title: 'Failure',
                    //       message: 'Could not send email',
                    //     })
                    //   }
                    //   onShareClose()
                    // }}
                  >
                    Share
                  </Button>
                </ModalFooter>
              </Center> */}
            </ModalContent>
          </Modal>
          {/* </form> */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export { Compare }
