import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
} from '@chakra-ui/react'
import { useVehicleType } from 'app/hooks'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { TVehicleInsuranceFormType } from '../Forms/FormModel/VehicleInsurance'
import { VehicleDetailsForm } from '../Forms/VehicleDetailsForm'
import { VehicleInsuranceForm } from '../Forms/VehicleInsuranceForm'

interface IVehicleInsuranceDetailsMobile {
  form: UseFormReturn<TVehicleInsuranceFormType, any>
}

const VehicleInsuranceDetailsMobile = (props: IVehicleInsuranceDetailsMobile) => {
  const { form } = props
  const { name: vehicleType } = useVehicleType()
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Accordion index={tabIndex} border='1px' borderRadius='lg'>
      <AccordionItem border='none'>
        <AccordionButton onClick={() => setTabIndex(0)} paddingY='4' bgColor='appDarkGrey'>
          <Box flex='1' textAlign='left'>
            {vehicleType} Details
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel paddingX={['0', '0', '4']}>
          <VehicleDetailsForm />
          {/* <Center>
            <ElevatedButton
              onClick={() => {
                console.log(store.getState())
                setTabIndex(1)
              }}
            >
              Log Store
            </ElevatedButton>
          </Center> */}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem borderBottom='none'>
        <AccordionButton onClick={() => setTabIndex(1)} paddingY='4' bgColor='appDarkGrey'>
          <Box flex='1' textAlign='left'>
            Previous Insurance Details
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel paddingX={['0', '0', '4']}>
          <VehicleInsuranceForm form={form} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export { VehicleInsuranceDetailsMobile }
