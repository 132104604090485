import { Box, BoxProps, FormErrorMessage, Text } from '@chakra-ui/react'
import { Select, SingleValue } from 'chakra-react-select'
import { ElementWithLabel, ErrorText, TextInput } from 'Core'
// import { INestedQuestionSet } from 'Models/BOB/ResponseModels/Life/QuestionSet'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { calculateAgeFromDob, getDropdownArrayFromRecord } from 'Services/general'
import RenderChild from './RenderChild'
import { RenderTextInput } from './RenderTextInput'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import { dotNotInclude, PRODUCT_URL } from 'Constants'
import { useAppSelector } from 'app/hooks'
import { IApiResponse2 } from 'Models/ResponseModels'
import { getDefaultHeaders2 } from 'Services/baseQuery'
import axios, { AxiosResponse } from 'axios'
import { store } from 'app/store'
import { objectEntries, objectKeys } from 'ts-extras'

const updateValueUsingDependentFields = ({
  type,
  questionCode,
  value,
  form,
}: {
  type: 'text' | 'date'
  questionCode: string
  value: Record<string, string | number>
  form: UseFormReturn<any, any>
}) => {
  const valueInString = objectKeys(value)[0]

  switch (type) {
    case 'text':
      form.setValue(questionCode, valueInString)
      break
    case 'date': // TODO: Add date parsing before setting value in the form on the basis of data recieved from backend
      form.setValue(questionCode, valueInString)
      break
    default:
      break
  }
}

interface IProps extends BoxProps {
  isRoot: boolean
  parentData: { name: string; value: string | number }
  name: string
  dropdownItems: Record<string, string | number>
  childrenData?: Record<string, INestedQuestionSet[]>
  form: UseFormReturn<any, any>
  // isDefaultSelected?: boolean
  value?: { label: string; value: string | number }
  textValue?: string
  label?: string
  initialValues?: Record<string, string | number | boolean | string[]>
  cover_type?: INestedQuestionSet['cover_type']
  factor?: number
  defaultValue?: string
  operation?: string
  input_type?: string
  isDisabled?: boolean
  min?: number | null
  max?: number | null
  disabledCodes?: string[]
  affect?: string
  regex?: string | RegExp
  call_api?: boolean
  required?: boolean
  onAffect?: (
    name: string,
    value: string | number,
    affect: string,
    factor?: number,
    operation?: string,
  ) => void
}

const RenderSiblings = (props: IProps) => {
  const {
    name,
    dropdownItems,
    childrenData,
    form,
    isRoot,
    parentData,
    // isDefaultSelected = false,
    defaultValue,
    value,
    min,
    max,
    label,
    initialValues,
    textValue,
    factor,
    cover_type,
    regex,
    isDisabled,
    input_type,
    disabledCodes,
    affect,
    onAffect,
    operation,
    call_api,
    required,
    ...rest
  } = props

  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const [regexVal, setRegexVal] = useState(regex)

  const [customError, setCustomError] = useState('')
  const [otherRequiredFieldsError, setOtherRequiredFieldsError] = useState(false)

  const dropdownArray = getDropdownArrayFromRecord(dropdownItems)

  const [dropdownValue, setDropdownValue] = useState<{
    label: string
    value: string | number
  } | null>(value ? value : null)

  const [calculatedInput, setCalculatedInput] = useState<INestedQuestionSet>()
  const [otherInputs, setOtherInputs] = useState<INestedQuestionSet[]>([])
  const lifeCustomerData = useAppSelector((state) => state.lifeCustomerDetails)
  const dob = lifeCustomerData.lifeAssuredDetailsTyped?.dob
  const age = calculateAgeFromDob(new Date(dob ?? ''))
  const handleChange = async (
    newVal: SingleValue<{
      label: string
      value: string | number
    }>,
    shouldClearChildSiblings?: boolean,
  ) => {
    if (shouldClearChildSiblings) {
      otherInputs.length &&
        otherInputs.forEach((input) => {
          form.setValue(input.code, '')
        })
      calculatedInput && form.setValue(calculatedInput.code, '')
    }
    form.setValue(name, newVal?.value)
    form.clearErrors(name)
    setDropdownValue({ label: newVal?.label ?? '', value: newVal?.value ?? '' })
    // generateChildInputs()
  }

  /**
   * This will clear the value of child dropdown if parent value changes
   */
  useEffect(() => {
    if (name !== 'entry_age') {
      form.resetField(name)
      setDropdownValue(null)
    }
  }, [parentData?.value])

  const getValidationData = async () => {
    if (call_api) {
      const insurer = store.getState().lifeQuotationDetails.finalSelectedPlan?.insurer
      const insurerProductCode = store.getState().lifeQuotationDetails.finalSelectedPlan?.code
      try {
        const resp: AxiosResponse<
          IApiResponse2<{
            field_validation: {
              min: string | number
              max: string | number
              regex: string
              req_fields?: string[]
              error_msg?: string
            }
            dependent_fields?: Record<
              string,
              {
                type: 'text' | 'date'
                values: Record<string, string | number>
              }
            >
            default: string
            is_disabled: boolean
          }>
        > = await axios({
          url: `${PRODUCT_URL}/product/get-choices/${insurer}/${insurerProductCode}/${name}/`,
          method: 'POST',
          data: {
            ...form.getValues(),
          },
          headers: getDefaultHeaders2({
            token: store.getState().user.authenticatedUser.authtoken ?? '',
          }),
        })

        const dependentFields = resp.data.data?.dependent_fields

        if (dependentFields) {
          const dataInEntries = objectEntries(dependentFields)
          dataInEntries.forEach((item) => {
            const questionCode = item[0]
            const questionType = item[1].type
            const newQuestionValue = item[1].values
            updateValueUsingDependentFields({
              form,
              type: questionType,
              questionCode,
              value: newQuestionValue,
            })
          })
        }

        setCustomError('')

        const fieldValidationData = resp.data.data?.field_validation

        if (fieldValidationData) {
          // If `fieldValidationData?.req_fields` has some value, then set custom validation to this field and return
          if (fieldValidationData?.req_fields && fieldValidationData?.req_fields?.length > 0) {
            form.setError(name, {
              type: 'otherRequiredFields',
              message: fieldValidationData?.error_msg ?? 'Some Other fields are required!',
            })
            setOtherRequiredFieldsError(true)
            // setCustomError(fieldValidationData?.error_msg)
            // await form.trigger(name)
            // return
          } else {
            setOtherRequiredFieldsError(false)
          }

          if (fieldValidationData?.error_msg) {
            setCustomError(fieldValidationData?.error_msg)
            // console.log('setting custom error!')

            // await form.trigger(name)
          }
          // else {
          //   // await form.trigger(name)
          //   // console.log('removing custom error!')

          //   setCustomError('')
          // }

          if (fieldValidationData.min?.toString()) {
            setMinVal(parseInt(fieldValidationData.min?.toString()))
          }

          if (fieldValidationData.max?.toString()) {
            setMaxVal(parseInt(fieldValidationData.max?.toString()))
          }

          if (fieldValidationData.regex?.toString()) {
            setRegexVal(fieldValidationData.regex?.toString())
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  /**
   * This will set the value of child dropdown (first index value in dropdown array) if parent value changes
   */
  // useEffect(() => {
  //   if (dropdownValue && !dropdownArray.includes(dropdownValue)) {
  //     handleChange(dropdownArray[0])
  //   }
  // }, [parentData?.value])

  /**
   * This will set the initial value of dropdown as the first index value in dropdown array
   */
  // useEffect(() => {
  //   if (isDefaultSelected) {
  //     handleChange(dropdownArray[0])
  //   }
  // }, [])

  /**
   * This will set the initial value of dropdown as given in the `default` key if it exists in dropdown array
   */
  useEffect(() => {
    if (defaultValue !== undefined) {
      const newValue:
        | SingleValue<{
            label: string
            value: string | number
          }>
        | undefined = dropdownArray.find(
        (item) => item.value?.toString() === defaultValue?.toString(),
      )

      if (newValue) {
        handleChange(newValue)
      }
    }
    if (operation === 'same_as_parent') {
      form.setValue(name, parentData.value.toString())
    }
    if (operation === 'opp__maturity_age__subtract__entry_age') {
      form.setValue(name, form.getValues('maturity_age') - Math.floor(age))
    }
    if (operation === 'subtract') {
      form.setValue(name, factor! - parseInt(parentData.value.toString()))
    }
    // if (operation === 'upper_limit_less_than_and_equal_to_half') {
    //   form.setValue(
    //     name,
    //     parseInt(parentData.value.toString()) / 2 < max!
    //       ? parseInt(parentData.value.toString()) / 2
    //       : max,
    //   )
    // }
  }, [])

  useEffect(() => {
    if (operation === 'same_as_parent') {
      form.setValue(name, parentData.value.toString())
    }
    if (operation === 'opp__maturity_age__subtract__entry_age') {
      form.setValue(name, form.getValues('maturity_age') - Math.floor(age))
    }
    if (operation === 'subtract') {
      form.setValue(name, factor! - parseInt(parentData.value.toString()))
    }
    // if (operation === 'upper_limit_less_than_and_equal_to_half') {
    //   form.setValue(
    //     name,
    //     parseInt(parentData.value.toString()) / 2 < max!
    //       ? parseInt(parentData.value.toString()) / 2
    //       : max,
    //   )
    // }
  }, [parentData.value])

  useEffect(() => {
    if (operation === 'opp__maturity_age__subtract__entry_age') {
      form.setValue(name, form.getValues('maturity_age') - Math.floor(age))
    }
  }, [form.getValues('maturity_age')])

  useEffect(() => {
    if (initialValues && initialValues[name]) {
      const temp = dropdownArray
      if (operation === 'subtract_parent_from_each_value') {
        temp.forEach((item, index) => {
          temp[index] = {
            label: (
              parseInt(item.value.toString()) - parseInt(parentData.value.toString())
            ).toString(),
            value: parseInt(item.value.toString()) - parseInt(parentData.value.toString()),
          }
        })
        const newValue: SingleValue<{ label: string; value: string | number }> | undefined =
          temp.find((item) => item.value.toString() === initialValues[name].toString())
        if (newValue) {
          handleChange(newValue)
        }
      } else if (operation === 'subtract_entry_age_from_each_value') {
        temp.forEach((item, index) => {
          temp[index] = {
            label: (
              parseInt(item.value.toString()) - parseInt(form.getValues('entry_age'))
            ).toString(),
            value: parseInt(item.value.toString()) - parseInt(form.getValues('entry_age')),
          }
        })
        const newValue: SingleValue<{ label: string; value: string | number }> | undefined =
          temp.find((item) => item.value.toString() === initialValues[name].toString())
        if (newValue) {
          handleChange(newValue)
        }
      } else if (operation === 'multiply_each_value_with_premium') {
        temp.forEach((item, index) => {
          temp[index] = {
            label: (
              parseFloat(item.value.toString()) * parseInt(form.getValues('annualPremium'))
            ).toString(),
            value: parseFloat(item.value.toString()) * parseInt(form.getValues('annualPremium')),
          }
        })
        const newValue: SingleValue<{ label: string; value: string | number }> | undefined =
          temp.find((item) => item.value.toString() === initialValues[name].toString())
        if (newValue) {
          handleChange(newValue)
        }
      } else {
        const newValue:
          | SingleValue<{
              label: string
              value: string | number
            }>
          | undefined = dropdownArray.find(
          (item) => item.value.toString() === initialValues[name].toString(),
        )
        if (newValue) {
          handleChange(newValue)
        }
      }
      // const newValue:
      //   | SingleValue<{
      //       label: string
      //       value: string | number
      //     }>
      //   | undefined = dropdownArray.find((item) =>
      //   operation === 'upper_limit_parent_subtract_factor'
      //     ? item.value.toString() === initialValues[name].toString()
      //     : operation === 'subtract_parent_from_each_value'
      //     ? (parseInt(item.value.toString()) - parseInt(parentData.value.toString())).toString() ===
      //       initialValues[name].toString()
      //     : operation === 'subtract_entry_age_from_each_value'
      //     ? item.value.toString() === initialValues[name].toString()
      //     : operation === 'upper_limit_factor_minus_entry_age'
      //     ? item.value.toString() === initialValues[name].toString()
      //     : operation === 'multiply_each_value_with_premium'
      //     ? item.value.toString() === initialValues[name].toString()
      //     : item.value.toString() === initialValues[name].toString(),
      // )
      // if (newValue) {
      //   handleChange(newValue)
      // }
    }
  }, [initialValues])

  // calculation logic
  useEffect(() => {
    generateChildInputs()
  }, [dropdownValue])

  const customErrorFn = () => {
    let valid = true
    if (customError !== '') {
      valid = false
    }
    return valid
  }

  const otherRequiredFieldsErrorFn = () => {
    let valid = true
    if (otherRequiredFieldsError) {
      valid = true
    }
    return valid
  }

  const generateChildInputs = () => {
    setOtherInputs([])
    setCalculatedInput(undefined)
    if (childrenData && dropdownValue && dropdownValue.value && childrenData[dropdownValue.value]) {
      // if (
      //   childrenData[dropdownValue.value].findIndex(
      //     ({ operation }) => operation === 'mul' || operation === 'div',
      //   ) >= 0
      // ) {
      childrenData[dropdownValue.value].forEach((child) => {
        if (child.hidden) {
          setCalculatedInput(child)
        } else {
          setOtherInputs((old) => [...old, child])
        }
      })
      // }
      // else {
      //   childrenData[dropdownValue.value].forEach((child) => {
      //     if (child.hidden && child.operation) {
      //       setCalculatedInput(child)
      //     } else {
      //       setOtherInputs((old) => [...old, child])
      //     }
      //   })
      // }
    }
  }

  const onChange = (value: number, input: INestedQuestionSet) => {
    if (!isNaN(value) && otherInputs.length && calculatedInput && input) {
      let calculateValue = Number(value)
      switch (input.operation) {
        case 'div':
          if (input.factor) {
            calculateValue = Number(Number(Number(value) / input.factor).toFixed())
            if (!calculateValue) {
              calculateValue = 0
            }
            form.setValue(calculatedInput.code, calculateValue)
          }
          break
        case 'mul':
          if (input.factor) {
            calculateValue = Number(Number(Number(value) * input.factor).toFixed())
            if (!calculateValue) {
              calculateValue = 0
            }
            form.setValue(calculatedInput.code, calculateValue)
          }
          break
        default:
          break
      }
      if (
        calculatedInput &&
        calculatedInput.operation === 'sum' &&
        calculatedInput.min !== null &&
        calculatedInput.max !== null &&
        calculatedInput.min === calculatedInput.max &&
        otherInputs &&
        otherInputs.length === 2
      ) {
        const total = Number(form.getValues(calculatedInput.code))
        const remain = (total || 0) - (calculateValue || 0)
        const remainCode = otherInputs.find(({ code }) => input.code !== code)?.code
        if (remainCode) {
          form.setValue(remainCode, remain)
        }
      }
    }
  }

  useEffect(() => {
    if (otherInputs.length && calculatedInput) {
      otherInputs.forEach((input) => {
        onChange(form.getValues(input?.code), input)
      })
    }
  }, [otherInputs])

  useEffect(() => {
    return () => {
      form.unregister(name)
      otherInputs.forEach((input) => form.unregister(input.code))
    }
  }, [])

  useEffect(() => {
    setMinVal(min)
  }, [min])

  useEffect(() => {
    setMaxVal(max)
  }, [max])

  useEffect(() => {
    setRegexVal(regex)
  }, [regex])

  useEffect(() => {
    if (isMinMaxEqual()) {
      form.setValue(name, minVal)
    }
  }, [minVal, maxVal, parentData.value])

  const getIsDisabled = () => {
    return (
      isDisabled ||
      (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false) ||
      isMinMaxEqual()
    )
  }
  const isMinMaxEqual = () => {
    return (
      minVal !== null &&
      maxVal !== null &&
      minVal !== undefined &&
      maxVal !== undefined &&
      minVal === maxVal &&
      minVal >= 0 &&
      maxVal >= 0 &&
      Number(defaultValue) == minVal
    )
  }

  const minValidationFn = (value: any) => {
    let valid = true
    if (input_type) {
      const minTemp = minVal as number
      switch (input_type.toLowerCase()) {
        case 'calculation':
          if (minTemp >= 0) {
            if (operation === 'subtract_lower_limit, subtract_upper_limit') {
              valid = Number(value) >= min! - parseInt(parentData.value.toString())
            } else if (operation === 'opp__maturity_age__subtract__entry_age') {
              valid = Number(value) === form.getValues('maturity_age') - Math.floor(age)
            } else {
              valid = Number(value) >= minTemp
            }
          }
          break
        case 'string':
          if (minTemp >= 0) {
            valid = value.length >= minTemp
          }
          break
        default:
          break
      }
    }
    return valid
  }

  const maxValidationFn = (value: any) => {
    let valid = true
    const max = maxVal as number
    if (input_type) {
      switch (input_type.toLowerCase()) {
        case 'calculation':
          if (max) {
            if (operation === 'upper_limit_less_than_and_equal_to') {
              valid = Number(value) <= Math.min(parseInt(parentData.value.toString()), max)
            } else if (operation === 'subtract_upper_limit') {
              valid = Number(value) <= max - parseInt(parentData.value.toString())
            } else if (operation === 'subtract_lower_limit, subtract_upper_limit') {
              valid = Number(value) <= max - parseInt(parentData.value.toString())
            } else if (operation === 'opp__maturity_age__subtract__entry_age') {
              valid = Number(value) === form.getValues('maturity_age') - Math.floor(age)
            } else if (operation === 'upper_limit_less_than_and_equal_to_half') {
              valid =
                Number(value) <=
                (parseInt(parentData.value.toString()) / 2 < max!
                  ? parseInt(parentData.value.toString()) / 2
                  : max)
            }
            // else if (operation === 'upper_limit_parent_subtract_factor') {
            //   console.log(operation)
            //   console.log(parentData.value)
            //   console.log(factor)
            //   valid = Number(value) <= parseInt(parentData.value.toString()) - factor!
            // }
            else {
              valid = value.length <= max
            }
          }
          if (operation === 'upper_limit_parent_subtract_factor') {
            valid = Number(value) <= parseInt(parentData.value.toString()) - factor!
          }
          break
        case 'string':
          if (max) {
            valid = value.length <= max
          }
          break
        default:
          break
      }
    }
    return valid
  }

  if (cover_type?.toLowerCase() === 'text') {
    return (
      <>
        <ElementWithLabel
          required
          marginY='0.7rem'
          height='fit-content'
          label={label || name}
          {...rest}
        >
          <TextInput
            placeholder={`Please enter ${label}.`}
            defaultValue={defaultValue}
            isDisabled={getIsDisabled()}
            onFocus={getValidationData}
            {...form.register(name, {
              required: required,
              validate: {
                min: minValidationFn,
                max: maxValidationFn,
                custom: customErrorFn,
                otherRequiredFields: otherRequiredFieldsErrorFn,
              },
              pattern: regexVal
                ? new RegExp(regexVal.toString().slice(1, -1))
                : input_type && input_type.toLowerCase() === 'numeric'
                ? new RegExp(dotNotInclude)
                : undefined,
              onBlur: () => {
                getValidationData()
              },
            })}
            // {...form.register(name, {
            //   required: true,
            //   validate: { min: minValidationFn, max: maxValidationFn },
            //   pattern: regex
            //     ? new RegExp(regex)
            //     : input_type && input_type.toLowerCase() === 'calculation'
            //     ? new RegExp(dotNotInclude)
            //     : undefined,
            // })}
            type={input_type && input_type.toLowerCase() === 'calculation' ? 'number' : 'text'}
            value={
              operation === 'subtract'
                ? factor && (factor - parseInt(parentData.value.toString())).toString()
                : operation === 'same_as_parent'
                ? parentData.value.toString()
                : operation === 'opp__maturity_age__subtract__entry_age'
                ? form.getValues('maturity_age') - Math.floor(age)
                : textValue
            }
          />
          {input_type && form.formState.errors[name] ? (
            <>
              {form.formState.errors[name]?.type === 'required' ? (
                <ErrorText text={`${label || name} is required!`} />
              ) : null}
              {form.formState.errors[name]?.type === 'pattern' ? (
                <ErrorText text={`${label || name} is Invalid!`} />
              ) : null}
              {input_type.toLowerCase() === 'calculation' ? (
                <>
                  {form.formState.errors[name]?.type === 'min' ? (
                    operation === 'subtract_lower_limit, subtract_upper_limit' ? (
                      <ErrorText
                        text={`Minimum value: ${minVal! - parseInt(parentData.value.toString())}`}
                      />
                    ) : operation === 'opp__maturity_age__subtract__entry_age' ? (
                      <ErrorText
                        text={`Value should be ${form.getValues('maturity_age') - Math.floor(age)}`}
                      />
                    ) : (
                      <ErrorText text={`Minimum value: ${minVal}`} />
                    )
                  ) : null}
                  {form.formState.errors[name]?.type === 'max' ? (
                    operation === 'upper_limit_less_than_and_equal_to' ? (
                      <ErrorText
                        text={`Maximum value: ${Math.min(
                          parseInt(parentData.value.toString()),
                          maxVal!,
                        )}`}
                      />
                    ) : operation === 'subtract_upper_limit' ? (
                      <ErrorText
                        text={`Maximum value: ${maxVal! - parseInt(parentData?.value.toString())}`}
                      />
                    ) : operation === 'subtract_lower_limit, subtract_upper_limit' ? (
                      <ErrorText
                        text={`Maximum value: ${maxVal! - parseInt(parentData.value.toString())}`}
                      />
                    ) : operation === 'upper_limit_parent_subtract_factor' ? (
                      <ErrorText
                        text={`Maximum value: ${parseInt(parentData.value.toString()) - factor!}`}
                      />
                    ) : operation === 'opp__maturity_age__subtract__entry_age' ? (
                      <ErrorText
                        text={`Value should be ${form.getValues('maturity_age') - Math.floor(age)}`}
                      />
                    ) : operation === 'upper_limit_less_than_and_equal_to_half' ? (
                      <ErrorText
                        text={`Maximum value: ${
                          parseInt(parentData.value.toString()) / 2 < maxVal!
                            ? parseInt(parentData.value.toString()) / 2
                            : max
                        }`}
                      />
                    ) : (
                      <ErrorText text={`Maximum value: ${maxVal}`} />
                    )
                  ) : null}
                </>
              ) : null}
              {input_type.toLowerCase() === 'string' ? (
                <>
                  {form.formState.errors[name]?.type === 'min' ? (
                    <ErrorText text={`Minimum ${min} alphabets required!`} />
                  ) : null}
                  {form.formState.errors[name]?.type === 'max' ? (
                    <ErrorText text={`Maximum ${max} alphabets only!`} />
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </ElementWithLabel>
      </>
    )
  }

  return (
    <>
      <ElementWithLabel
        required
        marginY='0.7rem'
        height='fit-content'
        label={label || name}
        {...rest}
      >
        <Box fontWeight={'normal'}>
          <Select
            focusBorderColor='primary.300'
            colorScheme='primary'
            useBasicStyles
            isSearchable={false}
            selectedOptionStyle='color'
            selectedOptionColor='primary'
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 20000 }),
            }}
            menuPosition='absolute'
            options={
              operation === 'upper_limit_parent_subtract_factor'
                ? dropdownArray.filter(
                    (dropdown) =>
                      parseInt(dropdown.label) <= parseInt(parentData.value.toString()) - factor!,
                  )
                : operation === 'subtract_parent_from_each_value'
                ? dropdownArray.map((dropdown) => {
                    return {
                      label: (
                        parseInt(dropdown.label) - parseInt(parentData.value.toString())
                      ).toString(),
                      value: (
                        parseInt(dropdown.label) - parseInt(parentData.value.toString())
                      ).toString(),
                    }
                  })
                : operation === 'subtract_entry_age_from_each_value'
                ? dropdownArray
                    .map((dropdown) => {
                      return {
                        label: (
                          parseInt(dropdown.label) - parseInt(form.getValues('entry_age'))
                        ).toString(),
                        value: (
                          parseInt(dropdown.label) - parseInt(form.getValues('entry_age'))
                        ).toString(),
                      }
                    })
                    .filter((item) => parseInt(item.value.toString()) <= max!)
                : operation === 'upper_limit_factor_minus_entry_age'
                ? dropdownArray
                    .map((dropdown) => {
                      return {
                        label: parseInt(dropdown.label).toString(),
                        value: parseInt(dropdown.label).toString(),
                      }
                    })
                    .filter(
                      (item) =>
                        parseInt(item.value.toString()) <= factor! - form.getValues('entry_age'),
                    )
                : operation === 'multiply_each_value_with_premium'
                ? dropdownArray.map((dropdown) => {
                    return {
                      label: (
                        parseFloat(dropdown.label) * parseInt(form.getValues('annualPremium'))
                      ).toString(),
                      value: (
                        parseFloat(dropdown.label) * parseInt(form.getValues('annualPremium'))
                      ).toString(),
                    }
                  })
                : dropdownArray
            }
            value={dropdownValue}
            {...form.register(name, { required: true })}
            onChange={(val) => handleChange(val, true)}
            isDisabled={
              isDisabled ||
              (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false)
            }
          />
        </Box>
        {form.formState.errors[name] && <ErrorText text={`${label} is required!`} />}
      </ElementWithLabel>
      {otherInputs && otherInputs.length ? (
        <>
          {otherInputs.map((input, i) => {
            return (
              <React.Fragment key={i}>
                <RenderTextInput
                  name={input.code}
                  label={input.name}
                  placeholder={input.description}
                  form={form}
                  isRoot={false}
                  parentData={parentData}
                  min={input.min}
                  max={input.max}
                  defaultValue={input.default}
                  input_type={input.input_type}
                  initialValues={initialValues}
                  onChange={(e: any) => onChange(e.target.value, input)}
                  isDisabled={input.hidden}
                  hidden={input.hidden}
                  affect={input.affect}
                  onAffect={onAffect}
                  factor={input.factor}
                  operation={input.operation}
                  call_api={input.call_api}
                  required={input.required}
                  regex={input.regex}
                />
              </React.Fragment>
            )
          })}
        </>
      ) : null}

      {calculatedInput ? (
        <RenderTextInput
          name={calculatedInput.code}
          label={calculatedInput.name}
          placeholder={calculatedInput.description}
          form={form}
          isRoot={false}
          parentData={parentData}
          min={calculatedInput.min}
          max={calculatedInput.max}
          input_type={calculatedInput.input_type}
          defaultValue={calculatedInput.default}
          initialValues={initialValues}
          isDisabled={calculatedInput.hidden}
          hidden={calculatedInput.hidden}
          affect={calculatedInput.affect}
          onAffect={onAffect}
          factor={calculatedInput.factor}
          operation={calculatedInput.operation}
          call_api={calculatedInput.call_api}
          required={calculatedInput.required}
          regex={calculatedInput.regex}
        />
      ) : null}
      {/* ) : null} */}
    </>
  )
}

export default RenderSiblings
