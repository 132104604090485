import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'Core'
import { royTheme } from 'Core/Styles/theme'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

const container = document.getElementById('root')!
const root = createRoot(container)

const persistor = persistStore(store)

root.render(
  // <React.StrictMode>
  <ChakraProvider resetCSS theme={royTheme}>
    <Provider store={store}>
      <Router>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Router>
    </Provider>
  </ChakraProvider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
