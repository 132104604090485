import { Box, Divider, Grid, GridItem } from '@chakra-ui/react'
import { useWindowDimensions } from 'app/hooks'
import { HEADER_HEIGHT } from 'Constants'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LifeStyleForm } from './LifeStyleForm'
import { PreExistingDiseasesForm } from './PreExistingDiseaseForm'

export const HealthQuestions = () => {
  const [showLifeStyleQuestions, setShowLifeStyleQuestions] = useState(false)
  const { height } = useWindowDimensions()
  const navigate = useNavigate()
  console.log(height)

  const onMedicalQuestionBackPress = () => {
    navigate('../proposer-insurer-details')
  }
  const onLifestyleQuestionBackPress = () => {
    console.log('back press')
    setShowLifeStyleQuestions(false)
  }
  return (
    <Box border='1px' rounded='xl' backgroundColor='white'>
      {/* heading grid */}
      <Grid style={{ gridTemplateColumns: '1fr 1fr' }}>
        <GridItem w='100%'>
          <Box shadow='sm' paddingX='4' paddingY='5' fontWeight='bold' border='0.5px solid #D8D8D8'>
            04 Pre Existing Disease
          </Box>
          <Divider />
        </GridItem>
        <GridItem w='100%'>
          <Box shadow='sm' paddingX='4' paddingY='5' fontWeight='bold' border='0.5px solid #D8D8D8'>
            05 Lifestyle
          </Box>
          <Divider />
        </GridItem>
      </Grid>
      {/* form grid */}
      <Grid w='full' style={{ gridTemplateColumns: '1fr 1fr' }}>
        {/* pre existing  */}
        <GridItem overflowX='scroll' w='full' borderRight='0.5px solid #D8D8D8'>
          <PreExistingDiseasesForm
            onBackPress={onMedicalQuestionBackPress}
            setShowLifeStyleQuestions={setShowLifeStyleQuestions}
            onFormSubmit={() => setShowLifeStyleQuestions(true)}
          />
        </GridItem>
        {/* lifestyle questions */}
        <GridItem overflowX='scroll' w='full' borderRight='0.5px solid #D8D8D8'>
          {showLifeStyleQuestions && (
            <LifeStyleForm
              setShowLifeStyleQuestions={setShowLifeStyleQuestions}
              onFormSubmit={() => setShowLifeStyleQuestions(true)}
              onBackPress={onLifestyleQuestionBackPress}
            />
          )}
        </GridItem>
      </Grid>
    </Box>
  )
}
