import { Box, Flex, Button, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

const SkeletonQuoteItem = () => {
  return (
    <Box
      border='1px solid #F1F3F4'
      borderRadius='10px 10px 0 0'
      width='270px'
      height='420px'
      bgColor='white'
      position='relative'
      // marginX='6'
      marginBottom='8'
    >
      <Flex direction='column' alignItems='center' justifyContent='space-around' height='100%'>
        <Flex justifyContent='space-between' width='100%' paddingX='6'>
          <SkeletonCircle size='6' />
          <SkeletonCircle size='6' />
        </Flex>
        <Box width='100%' bgColor='#F4F6FA' flexBasis='60%'>
          <Flex direction='column' justifyContent='end' height='100%'>
            <Flex
              direction='column'
              width='100%'
              marginBottom='8'
              paddingX='5'
              justifyContent='center'
            >
              <SkeletonText width='30%' noOfLines={1} marginBottom='2' />
              <SkeletonText width='100%' noOfLines={4} />
            </Flex>
            <Flex width='100%' justifyContent='space-around' marginBottom='4'>
              <Skeleton>
                <Button borderColor='#CE202F' fontSize='13px'>
                  PLAN DETAILS
                </Button>
              </Skeleton>
              <Skeleton>
                <Button borderColor='#CE202F' fontSize='13px'>
                  PLAN DETAILS
                </Button>
              </Skeleton>
            </Flex>
          </Flex>
        </Box>
        <Flex width='100%' justifyContent='space-around' paddingX='6'>
          <Flex direction='column' width='100%' alignItems='center'>
            <SkeletonText noOfLines={1} width='90%' marginBottom='2' />
            <SkeletonText noOfLines={1} width='90%' />
          </Flex>
          <Flex direction='column' width='100%' alignItems='center'>
            <SkeletonText noOfLines={1} width='90%' marginBottom='2' />
            <SkeletonText noOfLines={1} width='90%' />
          </Flex>
        </Flex>
        <Flex direction='column' justifyContent='center' position='absolute' top='9%'>
          <Skeleton height='100px' width='170px' />
        </Flex>
      </Flex>
      <Skeleton width='100%' borderRadius='0 0 10px 10px'>
        <Button>BUY</Button>
      </Skeleton>
    </Box>
  )
}

export { SkeletonQuoteItem }
