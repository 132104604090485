import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { UseFormReturn } from 'react-hook-form'
import { TVehicleInsuranceFormType } from '../Forms/FormModel/VehicleInsurance'
import { VehicleInsuranceForm } from '../Forms/VehicleInsuranceForm'

interface IVehicleInsuranceDesktop {
  form: UseFormReturn<TVehicleInsuranceFormType, any>
}

const VehicleInsuranceDesktop = (props: IVehicleInsuranceDesktop) => {
  const { form } = props
  return (
    <Box flexBasis='50%'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        paddingY='4'
        paddingX='6'
        bgColor='appDarkGrey'
        boxShadow='excludingTop'
        borderTopRightRadius='2xl'
      >
        <Text fontSize='large' fontWeight='medium'>
          Previous Insurance Details
        </Text>
        {/* <Button colorScheme='teal' variant='outline'>
          Edit
        </Button> */}
      </Flex>
      <VehicleInsuranceForm form={form} />
    </Box>
  )
}

export { VehicleInsuranceDesktop }
