import { Box, Button, Center, Flex, Image, Link, Skeleton, Text, Grid } from '@chakra-ui/react'
import { ArrowRightIcon } from '@chakra-ui/icons'
import { useLazyGetProductsQuery } from 'Services/API/sachet.api'
import { useEffect, useState } from 'react'
import { ISachetProduct } from 'Models/Sachets'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setSelectedProduct } from 'features/Sachets'
import { ToastService } from 'Services/toast.service'
import { setIsSameAddressNominee, setIsSameAddressSpouse } from 'features/Sachets/sachetData.slice'
import { getAgeObjFromDob2 } from 'Services/general'
import hospitalCashOver from 'Assets/Common/Home/hospitalCashOver.png'
import personalAccidentCover from 'Assets/Common/Home/personalAccidentCover.png'
import criticalIllnessCover from 'Assets/Common/Home/criticalllnessCover.png'
import bnwHospitalCover from 'Assets/Common/Home/bnwHospitalCover.png'
import bnwPersonalAccidentCover from 'Assets/Common/Home/bnwPersonalAccidentCover.png'
import bnwCriticalIllnessCover from 'Assets/Common/Home/bnwCriticalIllnessCover.png'

export const downloadCSVFile = async (link: string, fileName: string) => {
  const data = await fetch(link)
  const blob = await data.blob()
  const objectUrl = URL.createObjectURL(blob)
  const aTag: HTMLAnchorElement = document.createElement('a')
  aTag.href = objectUrl
  aTag.target = '_blank'
  aTag.download = fileName + '.pdf'
  aTag.click()
}

const HomeSachetForm = () => {
  const navigate = useNavigate()
  const [activeHover, setActiveHover] = useState<number>(0)

  const selectedProdState = useAppSelector((state) => state.sachetDataDetails)
  const [
    listingSachetProductData,
    {
      data,
      isLoading: isLoadingSachetProduct,
      isError: isErrorSachetProduct,
      isSuccess: isSuccessSachetProduct,
    },
  ] = useLazyGetProductsQuery()

  const customerAge = useAppSelector((state) => state.customerDetails.customerAge)
  const { selectedAccountPaymentMode } = useAppSelector((state) => state.customerDetails)

  const [loading, setLoading] = useState(true)

  const dataObj = [
    {
      id: 1,
      image: hospitalCashOver,
      blackAndWhiteImage: bnwHospitalCover,
      subHeading: 'Hospital Cash Cover',
      subText:
        'Offers a daily cash benefit to cover out-of-pocket expenses while hospitalized for an illness or injury.',

      buttonPath:
        'https://hegilead.hdfcergo.com/EqSspgApp/HEGIApp/HEGICLead?Source=TYll6xiaipA=&SubChannel=WqscUcmCNPI=&EntityName=WqscUcmCNPI=',
      knowMoreButton: 'https://foundation.theblackswan.in/media/Kz6jds6JkLfsGPA-nL5E8Q==',
      fileName: 'Hospital Cash Cover',
    },
    {
      id: 2,
      image: personalAccidentCover,
      blackAndWhiteImage: bnwPersonalAccidentCover,
      subHeading: 'Personal Accident Cover',
      subText:
        'Provides financial protection in the event of an accident resulting in injury, disability, or death. ',
      buttonPath:
        'https://hegilead.hdfcergo.com/EqSspgApp/HEGIApp/HEGICLead?Source=TYll6xiaipA=&SubChannel=WqscUcmCNPI=&EntityName=WqscUcmCNPI=',
      knowMoreButton: 'https://foundation.theblackswan.in/media/ZCgpjUoZgtu806ugEazslA==',
      fileName: 'Personal Accident Cover',
    },
    {
      id: 3,
      image: criticalIllnessCover,
      blackAndWhiteImage: bnwCriticalIllnessCover,
      subHeading: 'Critical Illness Cover',
      subText:
        'Pays a lump sum amount upon diagnosis of specified critical illnesses, providing financial support during difficult times.',
      buttonPath: 'https://sda.in.net/web/rgi/HospicashEquitas/',
      knowMoreButton: 'https://foundation.theblackswan.in/media/Wms0_aILJdg5nNBahVZkAw==',
      fileName: 'Critical Illness Cover',
    },
    // {
    //   id: 4,
    //   image: criticalIllnessCover,
    //   blackAndWhiteImage: bnwCriticalIllnessCover,
    //   subHeading: 'Cancer Care Cover',
    //   subText:
    //     'A cancer cover plan provides financial support for diagnosis and treatment, covering expenses like chemotherapy and surgery',
    //   buttonPath:
    //     'https://instasaral.nivabupa.com/Cypher/PrePop/PrePop?token=MkBnHC9K1L2s%2bwgPgyJrq%2fdJjteNSXF%2fJ%2fy86Bsg6B4%3d',
    //   knowMoreButton: 'https://foundation.theblackswan.in/media/Wms0_aILJdg5nNBahVZkAw==',
    //   fileName: 'Cancer Care Cover',
    // },
  ]

  const [sachetProductsList, setSachetProductsList] = useState<ISachetProduct[]>([])
  useEffect(() => {
    const fetchSachetProducts = async () => {
      try {
        const response = await listingSachetProductData({
          customer_age: customerAge,
        }).unwrap()

        // response.map((item:any)=>{
        //   console.log('item: ',item)
        // })
        setSachetProductsList(response)
        setLoading(false)
      } catch (error) {
        ToastService.showErrorToast({
          title: 'Failed to fetch sachet products',
          message: 'Please try again later.',
        })
        // console.error('Failed to fetch sachet products: ', error)
      }
    }
    fetchSachetProducts()
  }, [listingSachetProductData])

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setIsSameAddressNominee(false))
    dispatch(setIsSameAddressSpouse(false))
  }, [])
  const customerDetails = useAppSelector((state) => state.customerDetails)

  return (
    <>
      {getAgeObjFromDob2(
        new Date(customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob ?? ''),
      ) <= 18 ? (
        <Center gap='3' alignContent={'center'} alignItems={'center'} px='2'>
          <Center height={{ base: '190px', md: '270px', lg: '300px' }}>
            <Text fontWeight='normal' fontSize='20px'>
              Journey not allowed as the user is below 18 years
            </Text>
          </Center>
        </Center>
      ) : selectedAccountPaymentMode !== 'DirectDebit' ? (
        <Center gap='3' alignContent={'center'} alignItems={'center'} px='2'>
          <Center height={{ base: '190px', md: '270px', lg: '300px' }}>
            <Text fontWeight='normal' fontSize='20px'>
              Journey not allowed as the user account doesn{"'"}t support Direct Debit
            </Text>
          </Center>
        </Center>
      ) : (
        <Center gap='3' alignContent={'center'} alignItems={'center'} px='2'>
          {loading && (
            <>
              <Skeleton h='220px' width={'220px'} borderRadius={'20px'} my='50px'>
                <Flex
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  mt='20px'
                  textAlign='center'
                  px='2'
                  py='4'
                  gap='3'
                >
                  <Image alt='img' h={'75px'} />
                  <Flex
                    color={'#2C2C2C'}
                    fontSize='14px'
                    my='3'
                    fontWeight='bold'
                    w='full'
                    justifyContent='center'
                    alignItems='center'
                    gap='2'
                  >
                    <Text fontSize='14px' />
                    <Text fontSize='14px' style={{ cursor: 'pointer' }}>
                      <ArrowRightIcon />
                    </Text>
                  </Flex>
                </Flex>
              </Skeleton>
              <Skeleton h='220px' width={'220px'} borderRadius={'20px'} my='50px'>
                <Flex
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  mt='20px'
                  textAlign='center'
                  px='2'
                  py='4'
                  gap='3'
                >
                  <Image alt='img' h={'75px'} />
                  <Flex
                    color={'#2C2C2C'}
                    fontSize='14px'
                    my='3'
                    fontWeight='bold'
                    w='full'
                    justifyContent='center'
                    alignItems='center'
                    gap='2'
                  >
                    <Text fontSize='14px' />
                    <Text fontSize='14px' style={{ cursor: 'pointer' }}>
                      <ArrowRightIcon />
                    </Text>
                  </Flex>
                </Flex>
              </Skeleton>
              <Skeleton h='220px' width={'220px'} borderRadius={'20px'} my='50px'>
                <Flex
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  mt='20px'
                  textAlign='center'
                  px='2'
                  py='4'
                  gap='3'
                >
                  <Image alt='img' h={'75px'} />
                  <Flex
                    color={'#2C2C2C'}
                    fontSize='14px'
                    my='3'
                    fontWeight='bold'
                    w='full'
                    justifyContent='center'
                    alignItems='center'
                    gap='2'
                  >
                    <Text fontSize='14px' />
                    <Text fontSize='14px' style={{ cursor: 'pointer' }}>
                      <ArrowRightIcon />
                    </Text>
                  </Flex>
                </Flex>
              </Skeleton>
            </>
          )}

          {loading == false && sachetProductsList && sachetProductsList.length === 0 && (
            <Center height={{ base: '190px', md: '270px', lg: '300px' }}>
              <Text fontWeight='bold' fontSize='20px'>
                As per selected Customer Age, no Sachet plans are available{' '}
              </Text>
            </Center>
          )}
          <Grid gridTemplateColumns={'1fr 1fr 1fr'}>
            {loading == false &&
              sachetProductsList &&
              sachetProductsList.length > 0 &&
              sachetProductsList.map((item: any, index: number) => (
                <Box
                  height={'210px'}
                  width={'210px'}
                  borderRadius={'20px'}
                  my='50px'
                  shadow={'lg'}
                  key={index}
                  border='1px'
                  boxShadow='0px 3px 12px #00488029'
                  p='2'
                  cursor='pointer'
                  onClick={() => {
                    dispatch(setSelectedProduct(item))
                    // dispatch(setSelectedProduct({selectedProductData:item}))

                    // navigate(`sachets/${item.name}`,{state:item})

                    // navigate(`sachets/${item.name}/${item.code}`)

                    // function to
                    const newItemName = item.name.split(' ').join('_')
                    navigate(`sachets/${item.name}`)
                    // navigate(`sachets/${newItemName}`)
                  }}
                >
                  <Flex
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    mt='20px'
                    textAlign='center'
                    px='2'
                    py='4'
                    gap='3'
                  >
                    <Image src={item.icon ?? ''} alt='img' h={'75px'} />
                    <Flex
                      color={'#2C2C2C'}
                      fontSize='14px'
                      my='3'
                      fontWeight='bold'
                      w='full'
                      justifyContent='center'
                      alignItems='center'
                      gap='2'
                    >
                      <Text fontSize='14px'>{item.name}</Text>

                      <Text
                        fontSize='14px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setSelectedProduct(item))
                          // dispatch(setSelectedProduct({selectedProductData:item}))

                          //* useNavigate state drilling
                          // navigate(`sachets/${item.name}`,{state:item})

                          navigate(`sachets/${item.name}`)
                        }}
                      >
                        <ArrowRightIcon />
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              ))}
            {dataObj.map((item, index) => (
              <Box
                mx='10px'
                width={'220px'}
                borderRadius={'20px'}
                my='50px'
                shadow={'lg'}
                key={index}
                border={item.id === activeHover ? '1px solid #1C4589' : ''}
                // _hover={{ border: '1px solid #1C4589', color: '#00548C' }}
                onMouseEnter={() => setActiveHover(item.id)}
                onMouseLeave={() => setActiveHover(0)}
              >
                <Center flexDirection={'column'} mt='20px'>
                  {item.id === activeHover ? (
                    <img src={item.image} alt='an image' height={'100px'} width={'100px'} />
                  ) : (
                    <img
                      src={item.blackAndWhiteImage}
                      alt='image'
                      height={'100px'}
                      width={'100px'}
                    />
                  )}
                  <Text my='5' color={'#414141'}>
                    {item.subHeading}
                  </Text>

                  <Text color={'#949494'} fontSize={'11px'} mx='20px'>
                    {item.subText}
                    <br />
                    <span
                      style={{
                        color: '#1C4589',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '10px',
                      }}
                    >
                      <a onClick={() => downloadCSVFile(item.knowMoreButton, item.fileName)}>
                        Know More
                      </a>
                    </span>
                  </Text>
                  <a href={item.buttonPath} target='_blank' rel='noreferrer'>
                    <Button my='5'>Proceed</Button>
                  </a>
                </Center>
              </Box>
            ))}
          </Grid>
        </Center>
      )}
    </>
  )
}

export { HomeSachetForm }
