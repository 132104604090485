import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { HEADER_HEIGHT } from 'Constants'
import { useAppSelector, useWindowDimensions } from 'app/hooks'
import {
  useGetGenericBenefitsListingQuery,
  useGetGenericSumInsuredQuery,
  useGetInsurerListingPlansQuery,
} from 'Services/API/health.api'
import { ChakraStylesConfig, Select as ChakraSelect } from 'chakra-react-select'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
export const LEFT_SIDE_PANEL_WIDTH = 300

export const LeftPanel = ({
  insurersList,
  onApply,
  benefitsList,
  onReset,
}: {
  insurersList: any
  onApply: any
  benefitsList: any
  onReset: any
}) => {
  const { height } = useWindowDimensions()
  const { data: sumInsuredList, isLoading: sumInsuredLoading } = useGetGenericSumInsuredQuery()

  const { selectedPlanType } = useAppSelector((state) => state.cart)
  const { sum_insured } = useAppSelector((state) => state.memberDetails)

  type FormValues = {
    sumInsured: string
    plan_type: string
    insurer: number[]
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    reset,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      sumInsured: sum_insured,
      plan_type: selectedPlanType,
      insurer: [],
    },
  })
  const { data: InsurerData } = useGetInsurerListingPlansQuery({ is_previous_insurer: '' })

  // const { data: BenefitsData } = useGetGenericBenefitsListingQuery() // TODO pending from backend

  const onSubmit = (data: any) => {
    if (selectedPlanType === 'Individual') {
      data.plan_type = 'Individual'
    }
    onApply(data)
  }

  const onError = (errors: any) => {
    console.log(errors)
  }
  const navigate = useNavigate()

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {/* ------------ Side Pannel Content ------------  */}
        <Box
          backgroundColor={'white'}
          shadow={'md'}
          overflowY='auto'
          h={`${height - HEADER_HEIGHT - 90}px`}
        >
          <Box px={5} py={6} borderBottom='1px solid #B2B2B2'>
            <Flex justifyContent='space-between' alignContent='flex-start'>
              <Heading as='h6' fontSize={'14px'}>
                {/* Showing quotes for-self (30Years), Son 1 (2years) */}
                Showing quotes for selected members
              </Heading>
              {selectedPlanType !== 'Individual' && (
                <Box my='auto'>
                  <Button
                    colorScheme='blue'
                    variant='link'
                    size='xs'
                    mt='-4'
                    onClick={() => navigate('../health/member-details')}
                  >
                    Edit
                  </Button>
                </Box>
              )}
            </Flex>
          </Box>
          <Box px={5} py={6} borderBottom='1px solid #B2B2B2'>
            <Stack spacing={4}>
              <Heading as='h6' fontSize={'14px'}>
                Sum Insured
              </Heading>

              <ChakraSelect
                {...register('sumInsured')}
                // maxMenuHeight={120}
                isInvalid={errors.sumInsured ? true : false}
                placeholder='Select'
                useBasicStyles
                // chakraStyles={chakraStyles}

                isSearchable={false}
                defaultValue={{
                  label: sum_insured,
                  value: sum_insured,
                }}
                options={sumInsuredList?.map((item) => {
                  return {
                    label: item.toString(),
                    value: item.toString(),
                  }
                })}
                onChange={(item) => {
                  setValue('sumInsured', item!.value!)
                  clearErrors('sumInsured')
                }}
              />
            </Stack>
          </Box>
          <Box>
            {/* <Select /> */}
            <Box px={5} py={6} borderBottom='1px solid #B2B2B2'>
              <Stack spacing={4}>
                <Heading as='h6' fontSize={'14px'}>
                  Insurers
                </Heading>

                <ChakraSelect
                  {...register('insurer')}
                  maxMenuHeight={240}
                  isInvalid={errors.insurer ? true : false}
                  placeholder='Select'
                  useBasicStyles
                  isMulti={true}
                  // chakraStyles={chakraStyles}
                  isSearchable={false}
                  defaultValue={null}
                  options={InsurerData?.map((item) => {
                    return {
                      label: item.name.toString(),
                      value: item.id,
                    }
                  })}
                  onChange={(items) => {
                    console.log(items)
                    setValue(
                      'insurer',
                      items.map((item) => item!.value!),
                    )
                    clearErrors('insurer')
                  }}
                />
              </Stack>
            </Box>
          </Box>
          {selectedPlanType !== 'Individual' && (
            <Box px={5} py={6} borderBottom='1px solid #B2B2B2'>
              <Stack spacing={4}>
                <Heading as='h6' fontSize={'14px'}>
                  Type of Policy ({selectedPlanType})
                </Heading>
                <Controller
                  name={'plan_type'}
                  control={control}
                  defaultValue={selectedPlanType}
                  render={({ field }) => (
                    <FormControl isInvalid={errors.plan_type ? true : false}>
                      <RadioGroup onChange={(value) => field.onChange(value)} value={field.value}>
                        <Stack direction='column'>
                          <Radio value='Family Floater' colorScheme='primary'>
                            <Text fontSize='sm'>Family Floater</Text>
                          </Radio>

                          <Radio value='Multi Individual' colorScheme='primary'>
                            <Text fontSize='sm'>Multi Individual</Text>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      {errors.plan_type && (
                        <FormErrorMessage>{errors.plan_type?.message?.toString()}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>
            </Box>
          )}
          {/* <Box px={5} py={6}>
            <Stack spacing={4}>
              <Heading as='h6' fontSize={'14px'}>
                Benefits
              </Heading>
              <CheckboxGroup colorScheme='primary' defaultValue={['1']}>
                <Stack>
                  {BenefitsData?.map((item) => {
                    return (
                      <Checkbox value={item.name} key={item.name}>
                        <Text fontSize='sm'>{item.name}</Text>
                      </Checkbox>
                    )
                  })}
                </Stack>
              </CheckboxGroup>
            </Stack>
          </Box> */}
        </Box>
        <Box w='full'>
          <Flex w='full'>
            <Button type='submit' w='full' colorScheme='primary' variant='solid' borderRadius='0'>
              Apply
            </Button>
            {/* <Button onClick={() => {
                setValue('insurer', null)
                onReset()
                reset({
                  insurer: null
                })
              }} bg='white' w='full' colorScheme='primary' variant='outline' borderRadius='0'>
                Reset
              </Button> */}
          </Flex>
        </Box>
      </form>
    </>
  )
}
