import { Flex, FlexProps, forwardRef, Img, Text } from '@chakra-ui/react'
import { isDisabled } from '@testing-library/user-event/dist/types/utils'
import { If } from 'Core/If'
import { ISwitchOption } from 'Models/Common'
import { ReactNode, useEffect, useState } from 'react'

interface IBbSwitch extends FlexProps {
  option1: ISwitchOption
  option2: ISwitchOption
  option1Icon?: ReactNode
  option2Icon?: ReactNode
  initialValue?: ISwitchOption
  isDisabled?: boolean
  size?: 'sm' | 'md' | 'lg'
  onToggle: (value: ISwitchOption) => void
}

const BbSwitch = forwardRef(
  <T extends ISwitchOption>(props: IBbSwitch, ref: React.ForwardedRef<any>) => {
    const {
      option1,
      option2,
      option1Icon,
      option2Icon,
      initialValue,
      onToggle,
      isDisabled,
      size,
      ...rest
    } = props

    const [currentSelected, setCurrentSelected] = useState(initialValue ?? option1)

    // useEffect(() => {
    //   onToggle(currentSelected)
    // }, [currentSelected])

    useEffect(() => {
      if (initialValue) setCurrentSelected(initialValue)
    }, [initialValue])
    const onChange = (newOption: ISwitchOption) => {
      setCurrentSelected(newOption)
      onToggle(newOption)
    }

    return (
      <>
        <Flex
          // pointerEvents={'none'}
          opacity={isDisabled ? '0.4' : '1'}
          role='switch'
          width='100%'
          alignItems='center'
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          userSelect='none'
          ref={ref}
          {...rest}
        >
          <Flex
            pointerEvents={isDisabled ? 'none' : 'all'}
            bgColor={option1.value === currentSelected.value ? 'primary.50' : 'white'}
            color={option1.value === currentSelected.value ? 'primary.700' : 'gray'}
            width='50%'
            minWidth='1.5rem'
            border={
              option1.value === currentSelected.value ? '1px solid #f26422' : '1px solid grey'
            }
            borderRight={
              option1.value === currentSelected.value
                ? '1px solid #f26422'
                : '1px solid transparent'
            }
            borderRadius='md'
            borderRightRadius={option1.value === currentSelected.value ? '0' : '0'}
            onClick={() => onChange(option1)}
            fontSize={size || 'md'}
            height='40px'
            justifyContent='center'
            gap={2}
            alignItems='center'
            zIndex={option1.value === currentSelected.value ? '0' : '0'}
          >
            <Text> {option1.label === 'True' ? 'Yes' : option1.label} </Text>
            <If condition={Boolean(option1Icon)}>{option1Icon}</If>
          </Flex>
          <Flex
            pointerEvents={isDisabled ? 'none' : 'all'}
            bgColor={option1.value !== currentSelected.value ? 'primary.50' : 'white'}
            color={option1.value !== currentSelected.value ? 'primary.500' : 'gray'}
            minWidth='1.5rem'
            width='50%'
            zIndex={option1.value !== currentSelected.value ? '0' : '0'}
            border={
              option1.value !== currentSelected.value ? '1px solid #f26422' : '1px solid grey'
            }
            borderLeft={
              option1.value !== currentSelected.value ? '1px solid #f26422' : '1px solid white'
            }
            borderRadius='md'
            borderLeftRadius={option1.value !== currentSelected.value ? '0' : '0'}
            onClick={() => onChange(option2)}
            fontSize={size || 'md'}
            height='40px'
            justifyContent='center'
            alignItems='center'
            gap={2}
          >
            <Text>{option2.label === 'False' ? 'No' : option2.label} </Text>
            <If condition={Boolean(option2Icon)}>{option2Icon}</If>
          </Flex>
        </Flex>
      </>
    )
  },
)

export { BbSwitch }
