const registrationNumRegex = /^[A-Za-z]{2}-[0-9]{2}-([0-9]{4}$|[a-zA-Z]{1,3}-[0-9]{4})$/

const mobileNumRegex = /^[5-9]{1}[0-9]{9}$/

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const onlyAlphabetsRegex = /^[a-zA-Z.]+$/

const onlyAlphabetsWithSpaceRegex = /^[a-zA-Z ]*$/

const onlyNumbersRegex = /^\d+$/

const panNumRegex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/

const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/

export {
  registrationNumRegex,
  mobileNumRegex,
  emailRegex,
  panNumRegex,
  gstRegex,
  onlyAlphabetsRegex,
  onlyNumbersRegex,
  onlyAlphabetsWithSpaceRegex,
}
