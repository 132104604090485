import { ListProps, UnorderedList } from '@chakra-ui/react'

interface IListView extends ListProps {
  children: React.ReactNode
}

/**
 *
 * @param props - Children (ListTile)
 * @returns - Chakra Unorderedlist
 * @link - https://chakra-ui.com/docs/components/list/usage
 */
const ListView = (props: IListView) => {
  const { children, ...rest } = props
  return (
    <UnorderedList style={{ listStyle: 'none', marginLeft: 0 }} {...rest}>
      {children}
    </UnorderedList>
  )
}

export { ListView }
