import {
  Box,
  Button,
  Center,
  Flex,
  FlexProps,
  Icon,
  ListItem,
  Select,
  Text,
  UnorderedList,
  Image,
} from '@chakra-ui/react'
import { AiFillCheckCircle, AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { TiTick } from 'react-icons/ti'
import PrimaryTick from 'Assets/Common/primary_tick.svg'
import { ArrowDownIcon } from '@chakra-ui/icons'
import { CgArrowDownO } from 'react-icons/cg'
import { BsArrowBarDown } from 'react-icons/bs'
import { useEffect, useState } from 'react'
import { IndianRupeeFormatFromString } from 'Services/general'
// import { IRecommendedPlan } from './RecommendedPlan'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RecommendedPlan } from 'Models/ResponseModels/Life'

interface IRecommendedPlansCardProps {
  // isSelected: boolean
  // setSelected: () => void
  plan: RecommendedPlan
  // selectedAmount: any
  // onChangeSelectedAmount?: (value: string) => void
  // isDisabled?: boolean
}

const RecommendedPlansCard = (props: IRecommendedPlansCardProps) => {
  const dispatch = useAppDispatch()
  const { plan } = props
  const getFrequency = (frequency: string) => {
    switch (frequency) {
      case 'M':
        return 'monthly'
        break
      case 'Q':
        return 'quaterly'
        break
      case 'H':
        return 'half-yearly'
        break
      case 'Y':
        return 'yearly'
        break

      default:
        return ''
        break
    }
  }
  return (
    <>
      {plan.recommended && (
        <Box flexBasis={{ lg: '50%', md: 'auto', sm: 'auto', xs: 'auto' }} padding='20px'>
          <Flex
            // cursor={isDisabled ? 'not-allowed' : 'pointer'}
            bgColor={'#fff'}
            border='1px'
            borderColor={'gray.200'}
            rounded='lg'
            direction='column'
            justifyContent='space-between'
            width={{ lg: '600px', md: '500px', sm: '400px' }}
            h={'100%'}
            mx={'auto'}
            minH={'2.2rem'}
            position='relative'
            boxShadow='lg'
          >
            <Box overflow={'hidden'} position={'relative'}>
              <Box
                zIndex={2}
                position={'absolute'}
                left='0'
                top='0'
                width='170px'
                padding={'1'}
                textAlign='center'
                transform={{
                  lg: 'translate(-30px,25px) rotate(-40deg)',
                  md: 'translate(-55px,12px) rotate(-40deg) scale(.7)',
                  sm: 'translate(-55px,12px) rotate(-40deg) scale(.7)',
                }}
                color='#fff'
                fontSize={'sm'}
                bg='#1C4589'
                shadow={'md'}
                textTransform='capitalize'
              >
                {plan.plan_type} Plan
                {/* asdfghj Plan */}
              </Box>
              <Flex
                direction='row'
                alignItems='center'
                justifyContent='center'
                paddingY='1.5rem'
                background={'#E5E9F8'}
                borderTopLeftRadius='lg'
                borderTopRightRadius='lg'
              >
                <Box w='150px' h='auto'>
                  <Image src={plan.logo} />
                </Box>
                <Text fontWeight='bold' fontSize={{ lg: 'md', md: 'md', sm: 'md' }} marginLeft='8'>
                  {plan.name}
                </Text>
              </Flex>
              <Flex
                paddingY='4'
                paddingX={'2rem'}
                justifyContent={'space-between'}
                alignItems='center'
                borderBottom={'1px solid #F47321'}
                // minHeight={'72px'}
              >
                {(plan.plan_type === 'endowment' &&
                  plan.investment_amount &&
                  plan.investment_amount.length) ||
                (plan.plan_type === 'term' && plan.sum_assured && plan.sum_assured.length) ? (
                  <Text fontSize='md' fontWeight={'semibold'} color='#545862'>
                    {plan.plan_type === 'endowment' ? 'Investment Amount' : 'Sum Assured'}
                  </Text>
                ) : null}
                <Text fontSize='md' fontWeight={'semibold'} color='#545862'>
                  {plan.plan_type === 'endowment' ? 'Investment Amount' : 'Sum Assured'}
                </Text>
                {plan.plan_type === 'endowment' &&
                plan.investment_amount &&
                plan.investment_amount.length ? (
                  <Select
                    width={'fit-content'}
                    bg='#F47321'
                    color={'white'}
                    // onChange={(e) => onChangeSelectedAmount(e.target.value)}
                  >
                    {plan.investment_amount.length > 0 &&
                      plan.investment_amount.map((investment, i) => {
                        return (
                          <option key={i} value={`${investment.amount}+${investment.frequency}`}>
                            {`₹ ${IndianRupeeFormatFromString(
                              investment.amount.toString(),
                            )}/${getFrequency(investment.frequency)}`}
                          </option>
                        )
                      })}
                  </Select>
                ) : null}
                {plan.plan_type === 'term' && plan.sum_assured && plan.sum_assured.length ? (
                  <Select
                    width={'fit-content'}
                    bg='#F47321'
                    color={'white'}
                    // value={selectedAmount}
                    // onChange={(e) => onChangeSelectedAmount(e.target.value)}
                  >
                    {plan.sum_assured
                      .map((i) => parseInt(i))
                      .sort()
                      .map((sum, i) => {
                        return (
                          <option key={i} value={sum} style={{ color: '#F47321' }}>
                            ₹ {IndianRupeeFormatFromString(sum.toString())}
                          </option>
                        )
                      })}
                  </Select>
                ) : null}
              </Flex>

              <Box
                mx={'.2rem'}
                px='2rem'
                maxHeight={'180px'}
                overflow={'auto'}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    padding: '10px',
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    margin: '10px',
                    background: '#f59065',
                    borderRadius: '24px',
                    height: '10px',
                  },
                }}
              >
                <Text fontSize='lg' fontWeight={'medium'} py='2' color='#545862'>
                  Benefits
                </Text>

                {plan.plan_details?.benefits_list?.length ? (
                  <Box roundedBottom='lg' paddingY='2'>
                    <UnorderedList listStyleType={'none'} mx={0} fontSize='sm'>
                      {plan.plan_details.benefits_list.map((item, index) => {
                        return (
                          <ListItem mb='0.5' key={index}>
                            <Flex py='2px'>
                              <Box mt={'4px'} width={'15px'}>
                                <img src={PrimaryTick} />
                              </Box>
                              <Text>{item}</Text>
                            </Flex>
                          </ListItem>
                        )
                      })}
                    </UnorderedList>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  )
}

export { RecommendedPlansCard }
