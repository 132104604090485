import { Box, Flex, FlexProps, forwardRef, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { ErrorText } from 'Core/ErrorText'
import { ISwitchOption } from 'Models/Common'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface IBbSwitch extends FlexProps {
  option1: string
  option2: string
  initialValue?: ISwitchOption
  size?: 'sm' | 'md' | 'lg'
  onToggle: (value: string) => void
  name: string
  label?: string
  form: UseFormReturn<any, any>
  isDisabled?: boolean
}

const BbSwitchRadio = forwardRef(
  <T extends ISwitchOption>(props: IBbSwitch, ref: React.ForwardedRef<any>) => {
    const { option1, option2, initialValue, onToggle, size, name, label, form, isDisabled } = props

    const [currentSelected, setCurrentSelected] = useState<string>('')

    const onChange = (newOption: string) => {
      setCurrentSelected(newOption)
      onToggle(newOption)
    }

    return (
      <>
        <Flex
          opacity={isDisabled ? '0.4' : '1'}
          role='switch'
          width='100%'
          flexDirection={'column'}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          userSelect='none'
          ref={ref}
          // {...rest}
        >
          {/* <RadioGroup
            {...form.register(name, {
              required: true
            })}
            value={currentSelected}
          >
            <Box paddingBottom={1}>
              <Radio value={option1}>{option1}</Radio>
            </Box>
            <Box paddingBottom={1}>
              <Radio value={option2}>{option2}</Radio>
            </Box>
          </RadioGroup> */}
          <Flex paddingBottom={1}>
            <input
              type='radio'
              {...form.register(name, {
                required: true,
              })}
              value={option1}
              id={`${name}${option1}`}
              style={{
                accentColor: '#e75e1d',
              }}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              disabled={isDisabled}
            />
            <label
              htmlFor={`${name}${option1}`}
              style={{
                paddingLeft: 6,
                fontSize: 13,
                cursor: 'pointer',
              }}
            >
              {option1 === 'True' ? 'Yes' : option1}
            </label>
          </Flex>
          <Flex cursor={'pointer'}>
            <input
              type='radio'
              {...form.register(name, {
                required: true,
              })}
              value={option2}
              id={`${name}${option2}`}
              style={{
                accentColor: '#e75e1d',
              }}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              disabled={isDisabled}
            />
            <label
              htmlFor={`${name}${option2}`}
              style={{
                paddingLeft: 6,
                fontSize: 13,
                cursor: 'pointer',
              }}
            >
              {option2 === 'False' ? 'No' : option2}
            </label>
          </Flex>

          {/* <RadioGroup
            {...form.register(name, {
              required: true
            })}
            value={currentSelected}
          >
            <Box paddingBottom={1}>
              <Radio value={option1}>{option1}</Radio>
            </Box>
            <Box paddingBottom={1}>
              <Radio value={option2}>{option2}</Radio>
            </Box>
          </RadioGroup> */}
          {form.formState.errors[name] && <ErrorText text={`${name} is required!`} />}
        </Flex>
      </>
    )
  },
)

export { BbSwitchRadio }
