import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
  Button,
  FormControl,
} from '@chakra-ui/react'
import { useVehicleType } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { updatePaOwnerReason } from 'features/Motor'
const PODDialog = ({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const navigate = useNavigate()
  const { name: vehicleName } = useVehicleType()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <FormControl>
          <ModalHeader>
            Please select the reason for not opting compulsory PA to owner-driver cover?
          </ModalHeader>
          <ModalBody>
            <RadioGroup onChange={(val) => updatePaOwnerReason({ paOwnerReason: val })}>
              <Stack>
                <Radio value='nvdl' size='sm'>
                  You do not have a valid driving license OR
                </Radio>
                <Radio value='apa' size='sm'>
                  You have alternate personal accident policy having cover for ₹ 15,00,000
                </Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme='primary'
              onClick={() => {
                navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                onClose()
              }}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalContent>
    </Modal>
  )
}

export { PODDialog }
