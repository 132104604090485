import { Box, Button, Flex, FormLabel, Grid, Image, Select, Textarea } from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'
import axios from 'axios'
import { DashboardHeading, FileUploadV2, TextInput } from 'Core'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  useCreateSupportTicketMutation,
  useGetIssueTypeDropdownDataQuery,
} from 'Services/API/authentication.api'
import { ToastService } from 'Services/toast.service'
import { Header } from 'Components'

interface ISupportTicketForm {
  issue_type?: string
  issue_desc?: string
}

const TicketCreation = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [imageRefs, setImageRefs] = useState<string[]>([])

  const onFileDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const form = new FormData()
      form.append('file', acceptedFiles[0], acceptedFiles[0].name)
      const resp = await axios({
        url: 'https://foundation-dev.theblackswan.in/media',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Source: 'Web',
          Lang: 'EN',
          Authorization: 'Token 5401862b0791552d926cb11a7e2c9a06f42ea172',
        },
        data: form,
      })
      if (resp.data.status.toString() === '200') {
        setUploadedFiles((prev) => [...prev, acceptedFiles[0]])
        setImageRefs((prev) => [...prev, resp.data['data']['referenceid']])
      } else {
        throw Error
      }
    } catch (e) {
      ToastService.showErrorToast({
        title: 'Error',
        message: 'Something went wrong!',
      })
    }
  }, [])

  const screenshotUpload = useDropzone({
    onDrop: onFileDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
  })

  const { isDesktop, isTablet } = useDeviceType()
  const { data: dropdownData } = useGetIssueTypeDropdownDataQuery()

  const { register, handleSubmit, reset } = useForm<ISupportTicketForm>()

  const [createSupportTicket, { isLoading }] = useCreateSupportTicketMutation()

  const onSubmit: SubmitHandler<ISupportTicketForm> = async (data) => {
    console.log(data)
    try {
      await createSupportTicket({
        issue_type: data.issue_type ?? '',
        description: data.issue_desc ?? '',
        reference_key: imageRefs,
      }).unwrap()
      reset()
      setUploadedFiles([])
      setImageRefs([])
      console.log('here in try')
      ToastService.showSuccessTaost({
        title: 'Success',
      })
    } catch (e) {
      console.log('here in catch')
      ToastService.showErrorToast({
        title: 'Error',
        message: 'Something went wrong!',
      })
    }
  }

  return (
    <>
      <Header />
      <DashboardHeading title='Raise Support Ticket' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Issue Type*</FormLabel>
            <Select {...register('issue_type', { required: true })} placeholder='Select'>
              {dropdownData &&
                Object.entries(dropdownData).map((item, idx) => {
                  return (
                    <option key={idx} value={item[1]}>
                      {item[0]}
                    </option>
                  )
                })}
            </Select>
          </Flex>
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Please Provide details of the issue*</FormLabel>
            <Textarea
              {...register('issue_desc', { required: true })}
              placeholder='Describe your problem in at least 250 characters'
            />
          </Flex>
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel>Upload an image or screenshot</FormLabel>
            <Grid
              gridTemplateColumns={isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr'}
              gap='1rem'
            >
              {uploadedFiles.length > 0 &&
                uploadedFiles.map((item, idx) => (
                  <Image
                    objectFit='contain'
                    key={idx}
                    border='1px'
                    width='300px'
                    height='170px'
                    src={URL.createObjectURL(item)}
                  />
                ))}
              <FileUploadV2
                getInputProps={screenshotUpload.getInputProps}
                getRootProps={screenshotUpload.getRootProps}
              />
            </Grid>
          </Flex>
        </Box>
        <Flex justifyContent='center'>
          <Button
            marginY='0.5rem'
            size='md'
            type='submit'
            // onClick={(e) => {
            //   //   e.preventDefault()
            // }}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  )
}

export { TicketCreation }
