import { Box, BoxProps, Flex, Grid, GridItem, Radio, Text } from '@chakra-ui/react'
import { Check } from 'Assets/Common/Check'

interface IAccountDetailsCard extends BoxProps {
  isSelected: boolean
  accountNo: string
  customerName: string
  IFSCCode: string
  accountType: string
}

const AccountDetailsCard = (props: IAccountDetailsCard) => {
  const { isSelected, accountNo, customerName, IFSCCode, accountType, ...rest } = props

  return (
    <Box
      width='300px'
      paddingY='2'
      paddingX='4'
      border='1px'
      boxShadow={isSelected ? 'outline' : ''}
      rounded='2xl'
      {...rest}
    >
      <Flex justifyContent='space-between' paddingY='0.6rem'>
        <Text fontWeight='extrabold' color='primary.500'>
          {accountNo}
        </Text>
        {isSelected ? (
          <Check color='green' />
        ) : (
          <Box height='1.5rem' width='1.5rem' border='1px' rounded='full'></Box>
        )}
      </Flex>
      <Grid fontSize='sm' rowGap='1rem' templateColumns='55% 45%' paddingY='1rem'>
        <GridItem color='#999999'>
          <span>Account Holder&apos;s Name</span>
        </GridItem>
        <GridItem fontWeight='bold'>
          <span>{customerName ?? '-'}</span>
        </GridItem>
        <GridItem color='#999999'>
          <span>IFSC Code</span>
        </GridItem>
        <GridItem fontWeight='bold'>
          <span>{IFSCCode ?? '-'}</span>
        </GridItem>
        <GridItem color='#999999'>
          <span>Account Type</span>
        </GridItem>
        <GridItem fontWeight='bold'>
          <span>{accountType ?? '-'}</span>
        </GridItem>
      </Grid>
    </Box>
  )
}

export { AccountDetailsCard }
