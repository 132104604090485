import { createSlice } from '@reduxjs/toolkit'
import { IApplicantForm } from 'Pages/Common/Home/Forms/SachetsApplicantForm'
import { IPaymentStatus } from 'Pages/Common/Home/Forms/SachetsNomineeForm'

export interface ISelectedProductState {
  id: number
  name: string
  description: string
  icon: string
  premium: number
  sum_assured: number
  disclaimer: string
  code: string
  active: boolean
  get_variant: boolean
  insurer_logo: string
  insurer_name: string
}
// export interface IApplicantFormState {
//   firstName: string
//   lastName: string
//   email: string
//   pinCode: string
//   policyNumber: string
//   dob: Date | string
//   address1: string
//   city: string
//   aadhaar: string
//   gender: string
//   address2?: string
//   state: string
//   salutation: string
// }

export interface INomineeFormState {
  nomineeFullName: string
  nomineeRelationship: string
  nomineeDob: string
  nomineeaddress1: string
  nomineeaddress2?: string | undefined
  nomineeaddress3?: string | undefined
  nomineeGender: string
  nomineeSalutation: string
  appointeeFullName?: string
  appointeeAddress?: string
  appointeeRelationship?: string
  isNomineeAMinorChecked?: boolean
}

// export interface IAppointeeFormState {
//   appointeeFullName?: string
//   appointeeAddress?: string
//   appointeeRelationship?: string
//   isNomineeAMinorChecked?: boolean
// }

export interface ISelectedPlanFormState {
  name: string
  code: string
  description: string
  logo: string
  suminsured: string
  premiumamount: string
}

export interface IApplicantFormSpouse {
  firstName: string
  middleName: string
  lastName: string
  pinCode: string
  dob: Date | string
  address1: string
  address2?: string
  address3?: string
  // aadhaar: string
  gender: string
  city: string
  state: string
  salutation: string
  district: string
  relationship: string
}
interface IInitialState {
  selectedProductData: ISelectedProductState
  applicantFormDetails: IApplicantForm
  applicantFormDetailsSpouse: IApplicantFormSpouse
  nomineeFormDetails: INomineeFormState
  quoteId: string
  paymentDetails: IPaymentStatus
  fullUserData: any
  cancerCoverType: string
  isSameAddressNominee: boolean
  isSameAddressSpouse: boolean
  // appointeeDetails: IAppointeeFormState
}

const initialState: IInitialState = {
  isSameAddressNominee: false,
  isSameAddressSpouse: false,
  selectedProductData: {
    id: 0,
    name: '',
    description: '',
    icon: '',
    premium: 0,
    sum_assured: 0,
    disclaimer: '',
    code: '',
    active: false,
    get_variant: false,
    insurer_logo: '',
    insurer_name: '',
  },
  applicantFormDetails: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    pinCode: '',
    policyNumber: '',
    dob: '',
    address1: '',
    city: '',
    aadhaar: '',
    gender: '',
    address2: '',
    state: '',
    salutation: '',
    address3: '',
    // ifscCode: '',
    occupation: '',
    contactNumber: '',
    relationship: '',
    district: '',
  },
  applicantFormDetailsSpouse: {
    firstName: '',
    middleName: '',
    lastName: '',
    pinCode: '',
    dob: '',
    address1: '',
    address2: '',
    address3: '',
    // aadhaar: '',
    gender: '',
    district: '',

    city: '',
    state: '',
    salutation: '',
    relationship: '',
  },
  nomineeFormDetails: {
    nomineeFullName: '',
    nomineeRelationship: '',
    nomineeDob: '',
    nomineeaddress1: '',
    nomineeaddress2: '',
    nomineeaddress3: '',
    nomineeGender: '',
    nomineeSalutation: '',
    appointeeFullName: '',
    appointeeAddress: '',
    appointeeRelationship: '',
  },
  quoteId: '',
  paymentDetails: {
    id: 0,
    transaction_number: '',
    member_id: '',
    sp_id: 0,
    insurance: '',
    transaction_date: '',
    amount: '',
    status: '',
    mode_of_payment: '',
    quotation: 0,
  },
  fullUserData: {},
  cancerCoverType: '',
  // appointeeDetails: {
  //   appointeeFullName: '',
  //   appointeeAddress: '',
  //   appointeeRelationship: '',
  // },
}

const sachetDataSlice = createSlice({
  name: 'sachetDataReducer',
  initialState,
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProductData = action.payload
      return state
    },
    setApplicantFormDetails: (state, action) => {
      state.applicantFormDetails = action.payload
      return state
    },
    setApplicantFormDetailsSpouse: (state, action) => {
      state.applicantFormDetailsSpouse = action.payload
      return state
    },
    setNomineeFormDetails: (state, action) => {
      state.nomineeFormDetails = action.payload
      return state
    },
    setQuoteId: (state, action) => {
      state.quoteId = action.payload
      return state
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload
      return state
    },
    setFullUserData: (state, action) => {
      state.fullUserData = action.payload
      return state
    },
    setCancerCoverType: (state, action) => {
      state.cancerCoverType = action.payload
      return state
    },
    setIsSameAddressNominee: (state, action) => {
      state.isSameAddressNominee = action.payload
      return state
    },
    setIsSameAddressSpouse: (state, action) => {
      state.isSameAddressSpouse = action.payload
      return state
    },
    resetSachetData: () => initialState,
  },
})

const { reducer } = sachetDataSlice

const {
  resetSachetData,
  setSelectedProduct,
  setApplicantFormDetails,
  setApplicantFormDetailsSpouse,
  setNomineeFormDetails,
  setQuoteId,
  setPaymentDetails,
  // setAppointeeDetails,
  setFullUserData,
  setCancerCoverType,
  setIsSameAddressNominee,
  setIsSameAddressSpouse,
} = sachetDataSlice.actions

export {
  reducer as sachetDataReducer,
  resetSachetData,
  setSelectedProduct,
  setApplicantFormDetails,
  setApplicantFormDetailsSpouse,
  setNomineeFormDetails,
  setQuoteId,
  setPaymentDetails,
  // setAppointeeDetails,
  setFullUserData,
  setCancerCoverType,
  setIsSameAddressNominee,
  setIsSameAddressSpouse,
}
