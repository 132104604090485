import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Select,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Header } from 'Components'
import { BimabossDatePicker, BimabossDateRangePicker, DashboardHeading } from 'Core'
import React, { useEffect, useState } from 'react'
import { useMotorSalesSummaryBankQuery } from 'Services/API/motor.api'
import { MotorSummaryTable } from './MotorSummaryTable'

const MotorSalesSummary = () => {
  const [date, setDate] = useState('')

  const [queryParams, setQueryParams] = useState<{ start_date?: string; end_date?: string }>({})

  const { data: reportData } = useMotorSalesSummaryBankQuery(queryParams)

  useEffect(() => {
    if (date) {
      console.log(date.split(' - '))
      const dateArr = date.split(' - ')
      setQueryParams({
        start_date: dateArr[0],
        end_date: dateArr[1],
      })
    }
  }, [date])

  return (
    <>
      <Header />
      <DashboardHeading title='Sales Summary' />
      <Flex bgColor='#F5F6FA' paddingY='1rem' direction='column' alignItems='center'>
        <Text fontSize='xl'>FTD Data</Text>
        <TableContainer paddingY='0.5rem'>
          <Table border='1px' variant='unstyled'>
            <Thead>
              <Tr bgColor='primary.500' color='#fff'>
                <Th isNumeric>NOPS</Th>
                <Th isNumeric>Gross Premium</Th>
                <Th isNumeric>Net Premium</Th>
                <Th isNumeric>OD Premium</Th>
                <Th isNumeric>TP Premium</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td isNumeric>{reportData?.ftd_data.NOPS}</Td>
                <Td isNumeric>{reportData?.ftd_data.gross_premium}</Td>
                <Td isNumeric>{reportData?.ftd_data.net_premium}</Td>
                <Td isNumeric>{reportData?.ftd_data.od_premium}</Td>
                <Td isNumeric>{reportData?.ftd_data.tp_premium}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Flex bgColor='#F5F6FA' paddingY='1rem' direction='column' alignItems='center'>
        <Text fontSize='xl'>MTD Data</Text>
        <TableContainer paddingY='0.5rem'>
          <Table border='1px' variant='unstyled'>
            <Thead>
              <Tr bgColor='primary.500' color='#fff'>
                <Th isNumeric>NOPS</Th>
                <Th isNumeric>Gross Premium</Th>
                <Th isNumeric>Net Premium</Th>
                <Th isNumeric>OD Premium</Th>
                <Th isNumeric>TP Premium</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td isNumeric>{reportData?.mtd_data.NOPS}</Td>
                <Td isNumeric>{reportData?.mtd_data.gross_premium}</Td>
                <Td isNumeric>{reportData?.mtd_data.net_premium}</Td>
                <Td isNumeric>{reportData?.mtd_data.od_premium}</Td>
                <Td isNumeric>{reportData?.mtd_data.tp_premium}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Flex
        paddingX='1rem'
        paddingY='0.5rem'
        border='1px'
        alignItems='center'
        justifyContent='end'
        bgColor='#fff'
      >
        {/* <Button leftIcon={<DownloadIcon />} size='sm'>
          Download Report
        </Button> */}
        <Flex width='300px'>
          <BimabossDateRangePicker
            minDate={new Date('01/01/1950')}
            inputDate={date}
            setInputDate={setDate}
          />
        </Flex>
      </Flex>
      <Flex direction='column' alignItems='center'>
        <Tabs maxWidth='100%' isFitted variant='enclosed-colored'>
          <TabList>
            <Tab>4 Wheeler</Tab>
            <Tab>2 Wheeler</Tab>
            <Tab>GCV</Tab>
            <Tab>PCV</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MotorSummaryTable tableData={reportData?.four_wheeler} />
            </TabPanel>
            <TabPanel>
              <MotorSummaryTable tableData={reportData?.two_wheeler} />
            </TabPanel>
            <TabPanel>
              <MotorSummaryTable tableData={reportData?.gcv} />
            </TabPanel>
            <TabPanel>
              <MotorSummaryTable tableData={reportData?.pcv} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  )
}

export { MotorSalesSummary }
