import { PhoneIcon } from '@chakra-ui/icons'
import { createStandaloneToast } from '@chakra-ui/react'

const toast = createStandaloneToast()

interface IToastProps {
  title: string
  message?: string
  infinite?: boolean
}

export const ToastService = {
  showSuccessTaost: ({ title, message, infinite = false }: IToastProps) => {
    toast.toast({
      title: title,
      description: message,
      variant: 'solid',
      position: 'top-right',
      isClosable: true,
      status: 'success',
      duration: infinite ? null : 2000,
    })
  },
  showErrorToast: ({ title, message, infinite = false }: IToastProps) => {
    toast.toast({
      title: title,
      description: message,
      variant: 'solid',
      position: 'top-right',
      status: 'error',
      isClosable: true,
      duration: infinite ? null : 2000,
    })
  },
  showLoadingToast: ({ title, message }: IToastProps) => {
    toast.toast({
      title: title,
      description: message,
      duration: null,
      position: 'top-right',
      variant: 'solid',
      status: 'loading',
    })
  },
  closeAllToast: () => {
    toast.toast.closeAll()
  },
  showInfoToast: ({ title, message }: IToastProps) => {
    toast.toast({
      title: title,
      description: message,
      duration: 3000,
      position: 'top-right',
      variant: 'subtle',
      status: 'info',
    })
  },
}
