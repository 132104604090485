export default {
  fonts: {
    body: 'Roboto, sans-serif',
  },
  fontSizes: {
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
  },
}
