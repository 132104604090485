import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { IUseDeviceType, useAppDispatch } from 'app/hooks'
import { TSelectedType } from 'features/Home'
import { capitalizeFirstLetter } from 'Utils'

const getImageAspectRatio = ({ isDesktop, isTablet }: IUseDeviceType) => {
  if (isDesktop) {
    return { width: '15', height: '10' }
  } else if (isTablet) {
    return { width: '10', height: '8' }
  }
  return { width: '8', height: '7' }
}

const getLabelTextSize = ({ isDesktop, isTablet }: IUseDeviceType) => {
  if (isDesktop) {
    return 'initial'
  } else if (isTablet) {
    return 'small'
  }
  return 'x-small'
}

export interface IHomeInsuranceCardItem {
  deviceTypeObj: IUseDeviceType
  bgGradient: string
  selectedType: TSelectedType
  updateSelectedType: ActionCreatorWithPayload<TSelectedType, string>
  selectedImage: string
  unselectedImage: string
  label: TSelectedType
}

const HomeInsuranceCardItem = (props: IHomeInsuranceCardItem) => {
  const {
    deviceTypeObj,
    bgGradient,
    selectedType,
    updateSelectedType,
    selectedImage,
    unselectedImage,
    label,
    ...rest
  } = props

  const selected = selectedType === label

  const dispatch = useAppDispatch()

  return (
    <Box position='relative'>
      {selected && deviceTypeObj.isDesktop && (
        <Box
          position='absolute'
          height='1.5rem'
          width='1.5rem'
          boxShadow={selected ? 'DEFAULT' : ''}
          zIndex={-1}
          background={bgGradient}
          left='45%'
          bottom='-0.6rem'
          transform={'rotate(45deg)'}
        ></Box>
      )}
      <Flex
        rounded='full'
        background={selected ? bgGradient : ''}
        boxShadow={selected ? 'DEFAULT' : ''}
        paddingY='1rem'
        flexDirection='column'
        alignItems='center'
        gap='2'
        cursor='pointer'
        zIndex={1}
        onClick={() => dispatch(updateSelectedType(label))}
        // #TODO: Add ::after psedo element for adding triangle
        {...rest}
      >
        <Image
          {...getImageAspectRatio(deviceTypeObj)}
          alt='private car'
          src={selected ? selectedImage : unselectedImage}
        />
        <Text
          fontSize={getLabelTextSize(deviceTypeObj)}
          fontWeight='semibold'
          color={selected ? 'white' : 'black'}
        >
          {capitalizeFirstLetter(label)}
        </Text>
      </Flex>
    </Box>
  )
}

export { HomeInsuranceCardItem }
