import {
  AvatarBadge,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Header } from 'Components'
import { useEffect, useState } from 'react'
import { Modal, QuotationCard } from 'Core'
import { IoIosGitCompare } from 'react-icons/io'
import {
  useGetGenericBenefitsListingQuery,
  useGetInsurerListingPlansQuery,
  useGetPollingQuotationsQuery,
  useGetSubRequestMutation,
} from 'Services/API/health.api'

import { SkeletonQuoteItem } from 'Components/Skeleton/HealthQuoteCard'
import uuid from 'react-uuid'
import { useNavigate } from 'react-router-dom'
import { LeftPanel, LEFT_SIDE_PANEL_WIDTH } from './LeftPanel'
import { RightPanel, RIGHT_SIDE_PANEL_WIDTH } from './RightPanel'
import { useAppDispatch, useAppSelector, useDeviceType, useWindowDimensions } from 'app/hooks'
import { addToCart, updatedPlanType, updateRequestId } from 'features/Health/cart.health.slice'
import { copyToClipboard, decryptData } from 'Services/general'
import { updateSumInsured } from 'features/Health/memberDetails.health.slice'
import {
  getAdultsCount,
  getkidsCount,
  getMembers,
  getMemberTypesArray,
  getSelfSpouseKidsText,
} from '../helper'

import { CgSortAz } from 'react-icons/cg'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { HEADER_HEIGHT } from 'Constants'
import { CompareModal } from 'Core/Modal/CompareModal'
import { ToastService } from 'Services/toast.service'
import { FaUserPlus } from 'react-icons/fa'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ISaveLeadResponse } from 'Models/ResponseModels'

interface QuotesData {
  uid: string
  insurerName: string
  insuranceName: string
  premium: number
  sumInsured: number
  addedInCart: boolean
  code: string
  quoteNumber: string
  highlights: { id: number; highlight: string }[]
  benefits: { id: number; name: string }[]
  insurerLogo: string
  selected: boolean
  insuranceNumber: string
  insurerId: string
  uniqueIdentifier: string
  isAutoDebitSupported: boolean
  brochure: string
  isChecked: boolean
  isAutoPaySupported: boolean
}

const QuotationPageDesktop = () => {
  // --------------------------------------Hooks --------------------------------------

  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const { isMobile, isDesktop, isTablet } = useDeviceType()
  const dispatch = useAppDispatch()
  const { selectedPlanType, cartDetails } = useAppSelector((state) => state.cart)
  const {
    memberDetails,
    proposer,
    isProposerIncluded,
    sum_insured,
    isParentsIncluded,
    selfPincode,
    parentsPincode,
  } = useAppSelector((state) => state.memberDetails)
  const [requestQuotes] = useGetSubRequestMutation()
  const {
    isOpen: isFilterMenuOpen,
    onOpen: onFilterMenuOpen,
    onClose: onFilterMenuClose,
  } = useDisclosure()
  const { isOpen: isCartOpen, onOpen: onCartOpen, onClose: onCartClose } = useDisclosure()
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const completeMemberDetails = useAppSelector((state) => state.memberDetails)
  const cart_details = useAppSelector((state) => state.cart)

  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()
  const { selectedQuotes } = useAppSelector((state) => state.cart)

  // --------------------------------------State--------------------------------------
  const [count, setCount] = useState(4)
  const [skip, setSkip] = useState(true)
  const [subRequestId, setSubRequestId] = useState('')
  const [retryable, setRetryable] = useState(true)
  const [showLoading, setShowLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(
    getSelfSpouseKidsText(memberDetails, isProposerIncluded) == '' ? 1 : 0,
  )
  const [insurer, setInsurer] = useState<number[]>([])
  // const [benefit, setBenefit] = useState<number[]>([])

  const [showCartPanel, setShowCartPanel] = useState(
    cartDetails.allMembers || cartDetails.parents || cartDetails.selfSpouseKids ? true : false,
  )
  const [quotesData, setquotesData] = useState<QuotesData[]>([])
  const [showFilters, setShowFilters] = useState(false)

  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false) // to show compare modal

  // --------------------------------------API calls--------------------------------------

  const {
    data: getQuotes,
    isLoading: quotesLoading,
    isFetching: quotesFetching,
    refetch,
  } = useGetPollingQuotationsQuery(
    { sub_request_id: subRequestId },
    { pollingInterval: 2000, skip },
  )

  const {
    data: insuranceListingData,
    isLoading: insuranceLoading,
    isFetching: insuranceFetching,
  } = useGetInsurerListingPlansQuery({ is_previous_insurer: '' })
  const { data: BenefitsData } = useGetGenericBenefitsListingQuery()

  const [itemsToCompare, setItemsToCompare] = useState<
    {
      insuranceId: string
      insuranceName: string
      sumInsured: string
      premium: string
      insuranceLogo: string
      checked: boolean
    }[]
  >([])

  // --------------------------------------SideEffect--------------------------------------

  useEffect(() => {
    createLead()
  }, [])

  useEffect(() => {
    setShowLoading(true)
    requestQuotes({
      ucic: equitasDetails.ucic?.toString() ?? '',
      insurer_id_list: insurer,
      include_proposer: isProposerIncluded && selectedTab != 1 ? 1 : 0,
      adults: getAdultsCount(memberDetails, isProposerIncluded, getMemberType(selectedTab)),
      kids: getMemberType(selectedTab) === 'parents' ? 0 : getkidsCount(memberDetails),
      duration_years: 1,
      sum_insured: sum_insured,
      plan_type: selectedPlanType,
      members: getMembers(getMemberType(selectedTab), memberDetails, proposer).map((data) => {
        return {
          ...data,
          marital_status: 'Married',
        }
      }),
    })
      .unwrap()
      .then((response) => {
        setCount(response.count)
        setSubRequestId(response.sub_request_id)
        setRetryable(response.retryable)
        dispatch(updateRequestId(response.request_id))
        if (retryable) {
          setSkip(false)
        }
      })
      .catch((error) => {
        console.log(error, 'error in catch')
        ToastService.showErrorToast({
          title: 'Something Went Wrong!',
        })
      })
  }, [selectedPlanType, sum_insured, selectedTab, insurer])

  const checkForAutoDebit = (
    debit_types: {
      debit_type_name: string
      debit_type: number
    }[],
  ) => {
    if (debit_types.map((item) => item.debit_type).includes(1)) {
      return true
    }
    return false
  }

  const checkForAutoPay = (is_auto_pay_supported: number) => {
    if (is_auto_pay_supported) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (getQuotes?.result.length) {
      const tempData: QuotesData[] = []
      getQuotes.result.forEach((item) => {
        if (item.status === 200) {
          tempData.push({
            uid: item.data.unique_identifier,
            insurerName: item.data.insurer_name,
            insuranceName: item.data.insurance_name,
            premium: item.data.premium_total,
            sumInsured: item.data.sum_insured,
            addedInCart: false,
            code: uuid(),
            highlights: item.data.policy_highlights,
            insurerLogo: item.data.insurance_logo,
            insuranceNumber: item.data.insurance_id.toString(),
            quoteNumber: item.data.quote_number,
            selected: false,
            insurerId: item.data.insurer_id.toString(),
            uniqueIdentifier: item.data.unique_identifier,
            benefits: item.data.benifits,
            isAutoDebitSupported: checkForAutoDebit(item.data.debit_types),
            brochure: item.data.brochure,
            isChecked: false,
            isAutoPaySupported: checkForAutoPay(item.data.is_autopay_supported),
          })
        }
      })
      setquotesData((prevState) => {
        prevState.push(...tempData!)
        return [...prevState]
      })
    }

    if (getQuotes?.retryable === false) {
      setSkip(true)
      setShowLoading(false)
    }
  }, [getQuotes])

  // ------------------- Function-------------------
  const getMemberType = (index: number) => {
    switch (index) {
      case 0:
        return 'selfSpouseKids'
      case 1:
        return 'parents'
      case 2:
        return 'allMembers'
      default:
        return 'allMembers'
    }
  }
  const addToCartHandler = ({
    memberCategory,
    insurerName,
    insuranceName,
    quoteNumber,
    premium,
    sumInsured,
    code,
    insurerLogo,
    pincode,
    insuranceNumber,
    insurerId,
    uniqueIdentifier,
    isAutoDebitSupported,
    isAutoPaySupported,
  }: {
    memberCategory: 'parents' | 'selfSpouseKids' | 'allMembers'
    insurerName: string
    quoteNumber: string
    insuranceName: string
    premium: number
    sumInsured: number
    code: string
    insurerLogo: string
    pincode: string
    insuranceNumber: number
    insurerId: string
    uniqueIdentifier: string
    isAutoDebitSupported: boolean
    isAutoPaySupported: boolean
  }) => {
    setShowCartPanel(true)
    onCartOpen()
    setquotesData((prevState) => {
      prevState.forEach((quote) => {
        if (quote.code === code) quote.selected = true
        else {
          quote.selected = false
        }
      })
      return [...prevState]
    })
    const totalMembersList = Object.keys(memberDetails).map((key) => memberDetails[key].member_type)

    dispatch(
      addToCart({
        memberCategory: memberCategory,
        data: {
          insurerName,
          quoteNumber,
          insuranceName,
          premium,
          sumInsured,
          code,
          insurerLogo,
          insurerId,
          pincode,
          addons: [],
          insuranceNumber,
          addedMemberTypes: getMemberTypesArray(memberCategory).filter((member: any) =>
            totalMembersList.includes(member),
          ),
          memberType: memberCategory,
          uniqueIdentifier,
          duration: 1,
          isAutoDebitSupported: isAutoDebitSupported,
          isAutoPaySupported: isAutoPaySupported,
        },
      }),
    )
  }
  const onFilter = (data: {
    insurer: number[]
    plan_type: 'Family Floater' | 'Individual' | 'Multi Individual'
    sumInsured: string
    benefit: number[]
  }) => {
    setItemsToCompare([])
    setShowLoading(true)
    setquotesData([])
    data.plan_type && dispatch(updatedPlanType(data.plan_type))
    data.sumInsured && dispatch(updateSumInsured(data.sumInsured))

    setInsurer([...data.insurer])
    // setBenefit([...data.benefit])
  }
  const onResetFilter = () => {
    setInsurer([])
    // setBenefit([])
  }

  const { lead_uid } = useAppSelector((state) => state.leadDetails)

  const showCartButton = () => {
    if (cartDetails.allMembers || cartDetails.parents || cartDetails.selfSpouseKids) {
      return true
    }
    return false
  }

  const getTotalItemsAddedInCart = () => {
    let count = 0
    if (cartDetails.allMembers) count = count + 1

    if (cartDetails.parents) count = count + 1

    if (cartDetails.selfSpouseKids) count = count + 1
    return count
  }

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: completeMemberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('prequote', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  return (
    <>
      <Header />
      <Box backgroundColor={'#FAFAFA'}>
        {/* ------------ Left Panel ------------  */}
        {isDesktop && (
          <Box
            position='fixed'
            top={`${HEADER_HEIGHT}px`}
            left='0'
            w={`${LEFT_SIDE_PANEL_WIDTH}px`}
            my={6}
          >
            <LeftPanel
              onApply={onFilter}
              insurersList={insuranceListingData}
              benefitsList={BenefitsData}
              onReset={onResetFilter}
            />
          </Box>
        )}

        {/* {isMobile && <Box borderBottom='1px' borderColor={'appGrey.400'}>
          <Flex gap='2'>
            <Box w='50%' h='full' margin='2' onClick={() => setShowFilters}>
              <Center>
                <Flex gap='2'>
                  <Box marginY='auto'>
                    <MdSort />
                  </Box>
                  <Text>Sort</Text>
                </Flex>
              </Center>
            </Box>
            <Box w='2' h='8' my='auto'>
              <Divider orientation='vertical' borderColor={'appGrey.600'} />
            </Box>
            <Box w='50%' margin='2' onClick={onFilterMenuOpen}>
              <Center>
                <Flex gap='2'>
                  <Box marginY='auto'>
                    <CgSortAz />
                  </Box>
                  <Text>Filter</Text>
                </Flex>
              </Center>
            </Box>
          </Flex>
        </Box>} */}

        {/* ------------ Main Content------------  */}
        <Box ml={isDesktop ? `${LEFT_SIDE_PANEL_WIDTH}px` : ''}>
          {/* ------------ Header Content------------  */}

          <Box w='full'>
            <Box marginLeft='4'>
              <Flex justifyContent={'flex-end'} m='4'>
                <Button
                  variant={'outline'}
                  colorScheme={'secondary'}
                  rounded='full'
                  size='sm'
                  onClick={() => copyToClipboard(lead_uid ?? '')}
                >
                  <Flex gap='1'>
                    <FaUserPlus color='secondary' />
                    <Text>{lead_uid}</Text>
                  </Flex>
                </Button>
              </Flex>
              <Flex gap='3' justifyContent='space-between' mb='4'>
                <Text fontWeight='semibold' fontSize='large'>
                  Showing Results for Health Insurance ({quotesData?.length} Policies)
                </Text>
                <Flex>
                  {/* <Box className='m-3'>
                    <ToggleButton
                      firstLabel='Low to High'
                      secondLabel='High to Low'
                      title='Show Policies by Premium'
                    />
                  </Box> */}
                </Flex>
              </Flex>
              {/* <Text fontWeight='bold' fontSize={'14px'} marginY='1'>
                Selected: 3 Item
              </Text> */}
            </Box>
            <Tabs
              align='center'
              variant='enclosed-colored'
              isLazy={true}
              borderTop='2px solid primary'
            >
              <TabList>
                {getSelfSpouseKidsText(memberDetails, isProposerIncluded) !== '' && (
                  <Tab
                    onClick={() => {
                      setSelectedTab(0)
                      setquotesData([])
                      setItemsToCompare([])
                    }}
                    w='50'
                  >
                    {getSelfSpouseKidsText(memberDetails, isProposerIncluded)}{' '}
                  </Tab>
                )}
                {isParentsIncluded && (
                  <Tab
                    onClick={() => {
                      setSelectedTab(1)
                      setquotesData([])
                      setItemsToCompare([])
                    }}
                    w='32'
                  >
                    Parents
                  </Tab>
                )}
                {isParentsIncluded && (
                  <Tab
                    onClick={() => {
                      setSelectedTab(2)
                      setquotesData([])
                      setItemsToCompare([])
                    }}
                    w='32'
                  >
                    All Members
                  </Tab>
                )}
              </TabList>
              <TabPanels>
                {getSelfSpouseKidsText(memberDetails, isProposerIncluded) !== '' && (
                  <TabPanel px={6}>
                    <Grid
                      templateColumns={{
                        base: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        lg: 'repeat(3, 1fr)',
                        xl: 'repeat(3, 1fr)',
                        '2xl': 'repeat(4,1fr)',
                      }}
                      gap={6}
                    >
                      {quotesData.map((item, index) => {
                        return (
                          <GridItem key={item.code} w='full'>
                            <QuotationCard
                              insuranceName={item.insuranceName}
                              insurerLogo={item.insurerLogo}
                              isChecked={itemsToCompare
                                .filter((item) => item.checked)
                                .map((item) => item.insuranceId)
                                .includes(item.insuranceNumber)}
                              totalQuotesAddedToCompare={
                                quotesData.filter((item) => item.isChecked).length
                              }
                              brochure={item.brochure}
                              addQuoteToCompare={() => {
                                setItemsToCompare((prev) => {
                                  if (
                                    prev
                                      .map((item) => item.insuranceId)
                                      .includes(item.insuranceNumber)
                                  ) {
                                    prev = prev.filter(
                                      (selectedItem) =>
                                        selectedItem.insuranceId !== item.insuranceNumber,
                                    )
                                  } else if (prev.length < 3) {
                                    prev.push({
                                      insuranceId: item.insuranceNumber.toString(),
                                      insuranceName: item.insuranceName.toString(),
                                      sumInsured: item.sumInsured.toString(),
                                      premium: item.premium.toString(),
                                      insuranceLogo: item.insurerLogo.toString(),
                                      checked: prev.length < 3 ? true : false,
                                    })
                                  } else {
                                    ToastService.showInfoToast({
                                      title: ' You can select upto 3 quotes to Compare!',
                                    })
                                  }
                                  return [...prev]
                                })
                              }}
                              premium={item.premium.toString()}
                              sumInsured={item.sumInsured.toString()}
                              benefits={item.benefits.map((item) => {
                                return {
                                  id: item.id,
                                  name: item.name,
                                }
                              })}
                              highlights={item.highlights.map((item) => {
                                return {
                                  id: item.id,
                                  name: item.highlight,
                                }
                              })}
                              selected={
                                cartDetails['selfSpouseKids']?.insuranceNumber.toString() ===
                                item.insuranceNumber.toString()
                              }
                              onSelect={() => {
                                addToCartHandler({
                                  memberCategory: 'selfSpouseKids',
                                  insurerName: item.insurerName,
                                  quoteNumber: item.quoteNumber,
                                  insuranceName: item.insuranceName,
                                  premium: item.premium,
                                  sumInsured: item.sumInsured,
                                  code: item.code,
                                  insurerLogo: item.insurerLogo,
                                  pincode: selfPincode.pincode!.toString(),
                                  insuranceNumber: parseInt(item.insuranceNumber),
                                  insurerId: item.insurerId,
                                  uniqueIdentifier: item.uniqueIdentifier,
                                  isAutoDebitSupported: item.isAutoDebitSupported,
                                  isAutoPaySupported: item.isAutoPaySupported,
                                })
                              }}
                            />
                          </GridItem>
                        )
                      })}
                      {showLoading &&
                        Array(4)
                          .fill(0)
                          .map((item, index) => <SkeletonQuoteItem key={index} />)}
                    </Grid>
                  </TabPanel>
                )}
                {isParentsIncluded && (
                  <TabPanel>
                    <Grid
                      templateColumns={{
                        base: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        lg: 'repeat(3, 1fr)',
                        xl: 'repeat(3, 1fr)',
                        '2xl': 'repeat(4,1fr)',
                      }}
                      w='full'
                      gap={6}
                    >
                      {quotesData.map((item, index) => {
                        return (
                          <GridItem key={item.code} w='full'>
                            <QuotationCard
                              insuranceName={item.insuranceName}
                              insurerLogo={item.insurerLogo}
                              // insurerId={item.insurerId}
                              totalQuotesAddedToCompare={
                                quotesData.filter((item) => item.isChecked).length
                              }
                              premium={item.premium.toString()}
                              sumInsured={item.sumInsured.toString()}
                              benefits={item.benefits.map((item) => {
                                return {
                                  id: item.id,
                                  name: item.name,
                                }
                              })}
                              isChecked={itemsToCompare
                                .filter((item) => item.checked)
                                .map((item) => item.insuranceId)
                                .includes(item.insuranceNumber)}
                              brochure={item.brochure}
                              addQuoteToCompare={() => {
                                setItemsToCompare((prev) => {
                                  if (
                                    prev
                                      .map((item) => item.insuranceId)
                                      .includes(item.insuranceNumber)
                                  ) {
                                    prev = prev.filter(
                                      (selectedItem) =>
                                        selectedItem.insuranceId !== item.insuranceNumber,
                                    )
                                  } else if (prev.length < 3) {
                                    prev.push({
                                      insuranceId: item.insuranceNumber.toString(),
                                      insuranceName: item.insuranceName.toString(),
                                      sumInsured: item.sumInsured.toString(),
                                      premium: item.premium.toString(),
                                      insuranceLogo: item.insurerLogo.toString(),
                                      checked: prev.length < 3 ? true : false,
                                    })
                                  } else {
                                    ToastService.showInfoToast({
                                      title: ' You can select upto 3 quotes to Compare!',
                                    })
                                  }
                                  return [...prev]
                                })
                              }}
                              highlights={item.highlights.map((item) => {
                                return {
                                  id: item.id,
                                  name: item.highlight,
                                }
                              })}
                              // selected={item.selected}
                              selected={
                                cartDetails['parents']?.insuranceNumber.toString() ===
                                item.insuranceNumber.toString()
                              }
                              onSelect={() =>
                                addToCartHandler({
                                  memberCategory: 'parents',
                                  insurerName: item.insurerName,
                                  quoteNumber: item.quoteNumber,
                                  insuranceName: item.insuranceName,
                                  premium: item.premium,
                                  sumInsured: item.sumInsured,
                                  code: item.code,
                                  insurerLogo: item.insurerLogo,
                                  pincode: selfPincode.pincode!.toString(),
                                  insuranceNumber: parseInt(item.insuranceNumber),
                                  insurerId: item.insurerId,
                                  uniqueIdentifier: item.uniqueIdentifier,
                                  isAutoDebitSupported: item.isAutoDebitSupported,
                                  isAutoPaySupported: item.isAutoPaySupported,
                                })
                              }
                            />
                          </GridItem>
                        )
                      })}
                      {showLoading &&
                        Array(4)
                          .fill(0)
                          .map((item, index) => <SkeletonQuoteItem key={index} />)}
                    </Grid>
                  </TabPanel>
                )}
                {isParentsIncluded && (
                  <TabPanel>
                    <Grid
                      templateColumns={{
                        base: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        lg: 'repeat(3, 1fr)',
                        xl: 'repeat(3, 1fr)',
                        '2xl': 'repeat(4,1fr)',
                      }}
                      w='full'
                      gap={6}
                    >
                      {quotesData.map((item, index) => {
                        return (
                          <GridItem key={item.code} w='full'>
                            <QuotationCard
                              // insurerName={item.insurerName}
                              insuranceName={item.insuranceName}
                              totalQuotesAddedToCompare={
                                quotesData.filter((item) => item.isChecked).length
                              }
                              isChecked={itemsToCompare
                                .filter((item) => item.checked)
                                .map((item) => item.insuranceId)
                                .includes(item.insuranceNumber)}
                              brochure={item.brochure}
                              insurerLogo={item.insurerLogo}
                              premium={item.premium.toString()}
                              // insurerId={item.insurerId}
                              sumInsured={item.sumInsured.toString()}
                              benefits={item.benefits.map((item) => {
                                return {
                                  id: item.id,
                                  name: item.name,
                                }
                              })}
                              addQuoteToCompare={() => {
                                setItemsToCompare((prev) => {
                                  if (
                                    prev
                                      .map((item) => item.insuranceId)
                                      .includes(item.insuranceNumber)
                                  ) {
                                    prev = prev.filter(
                                      (selectedItem) =>
                                        selectedItem.insuranceId !== item.insuranceNumber,
                                    )
                                  } else if (prev.length < 3) {
                                    prev.push({
                                      insuranceId: item.insuranceNumber.toString(),
                                      insuranceName: item.insuranceName.toString(),
                                      sumInsured: item.sumInsured.toString(),
                                      premium: item.premium.toString(),
                                      insuranceLogo: item.insurerLogo.toString(),
                                      checked: prev.length < 3 ? true : false,
                                    })
                                  } else {
                                    ToastService.showInfoToast({
                                      title: ' You can select upto 3 quotes to Compare!',
                                    })
                                  }
                                  return [...prev]
                                })
                              }}
                              highlights={item.highlights.map((item) => {
                                return {
                                  id: item.id,
                                  name: item.highlight,
                                }
                              })}
                              // selected={item.selected}
                              selected={
                                cartDetails['allMembers']?.insuranceNumber.toString() ===
                                item.insuranceNumber.toString()
                              }
                              onSelect={() =>
                                addToCartHandler({
                                  memberCategory: 'allMembers',
                                  insurerName: item.insurerName,
                                  quoteNumber: item.quoteNumber,
                                  insuranceName: item.insuranceName,
                                  premium: item.premium,
                                  sumInsured: item.sumInsured,
                                  code: item.code,
                                  insurerLogo: item.insurerLogo,
                                  pincode: selfPincode.pincode!.toString(),
                                  insuranceNumber: parseInt(item.insuranceNumber),
                                  insurerId: item.insurerId,
                                  uniqueIdentifier: item.uniqueIdentifier,
                                  isAutoDebitSupported: item.isAutoDebitSupported,
                                  isAutoPaySupported: item.isAutoPaySupported,
                                })
                              }
                            />
                          </GridItem>
                        )
                      })}
                      {showLoading &&
                        Array(4)
                          .fill(0)
                          .map((item, index) => <SkeletonQuoteItem key={index} />)}
                    </Grid>
                  </TabPanel>
                )}
              </TabPanels>

              {!showLoading && quotesData.length === 0 && (
                <Box my='4'>
                  <Text>No Quotes To Display</Text>
                </Box>
              )}
            </Tabs>
          </Box>
        </Box>

        <Modal
          isOpen={isCompareModalOpen}
          onClose={() => setIsCompareModalOpen(false)}
          headerText='Compare'
        >
          <CompareModal compareQuotesArray={itemsToCompare.slice(0, 3)} />
        </Modal>

        {(showCartButton() || itemsToCompare.length > 1 || isMobile) && (
          <Box
            position='fixed'
            right='0'
            bottom='0'
            w={isDesktop ? width - LEFT_SIDE_PANEL_WIDTH + 'px' : 'full'}
            bgGradient='linear(to-t, blackAlpha.700, whiteAlpha.500)'
          >
            <Flex justifyContent='end' className='items-center' h='24' px='8' gap='8'>
              {isMobile && (
                <Box>
                  <Button colorScheme='primary' position={'relative'} onClick={onFilterMenuOpen}>
                    Filter
                    <Spacer w={'2'} />
                    <CgSortAz size='30' />
                  </Button>
                </Box>
              )}
              {showCartButton() && (
                <Box>
                  <Button colorScheme='primary' position={'relative'} onClick={onCartOpen}>
                    Go To Cart
                    <Spacer w={'2'} />
                    <Flex
                      justifyContent='center'
                      alignItems='center'
                      h={5}
                      w={5}
                      bg='primary.800'
                      rounded='full'
                      position='absolute'
                      top={-1}
                      left={-1}
                    >
                      <Text>{getTotalItemsAddedInCart()}</Text>
                    </Flex>
                    <AiOutlineShoppingCart size={'25'} />
                  </Button>
                </Box>
              )}
              {itemsToCompare.length > 1 && (
                <Box>
                  <Button
                    colorScheme='primary'
                    position={'relative'}
                    onClick={() => setIsCompareModalOpen(true)}
                  >
                    Compare
                    <Spacer w={'2'} />
                    <Flex
                      justifyContent='center'
                      alignItems='center'
                      h={5}
                      w={5}
                      bg='primary.800'
                      rounded='full'
                      position='absolute'
                      top={-1}
                      left={-1}
                    >
                      <Text>{itemsToCompare.slice(0, 3).length}</Text>
                    </Flex>
                    <IoIosGitCompare size={'25'} />
                  </Button>
                </Box>
              )}
            </Flex>
          </Box>
        )}

        <Spacer h='36' />

        <Drawer isOpen={isFilterMenuOpen} placement='bottom' onClose={onFilterMenuClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filters</DrawerHeader>

            <DrawerBody>
              <LeftPanel
                onApply={onFilter}
                insurersList={insuranceListingData}
                benefitsList={BenefitsData}
                onReset={onResetFilter}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Drawer isOpen={isCartOpen} placement='right' onClose={onCartClose}>
          <DrawerOverlay />
          <DrawerContent>
            <Box px={5} py={6} dropShadow='lg' borderBottom='1px solid #0000001C' shadow='md'>
              <Flex justifyContent='space-between' alignContent='flex-start'>
                <Heading as='h6' fontSize={'14px'}>
                  Review your cart
                </Heading>
                {/* <BsArrowsAngleExpand onClick={() => setIsReviewModalOpen(true)} cursor='pointer' /> */}
              </Flex>
            </Box>
            <DrawerCloseButton />

            <DrawerBody>
              <RightPanel
                onClose={onCartClose}
                policyForSelfSpouseKidsText={getSelfSpouseKidsText(
                  memberDetails,
                  isProposerIncluded,
                )}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  )
}

export { QuotationPageDesktop }

// [...fakeData.map((item: any) => {
//   return {
//     uid: item.data.unique_identifier!,
//     quoteNumber: item.data.quote_number,
//     insurerName: item.data.insurer_name,
//     insuranceName: item.data.insurance_name!,
//     premium: item.data.premium_total,
//     sumInsured: item.data.sum_insured,
//     addedInCart: false,
//     code: uuid(),
//     highlights: [],
//     benefits: [],
//     insurerLogo: item.data.insurance_logo,
//     selected: false,
//     insuranceNumber: item.data.insurance_id
//   }
// })]
