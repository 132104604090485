import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Checkbox,
  Text,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Spinner,
  Select,
  Radio,
  RadioGroup,
  Image,
  Stack,
  Box,
  Divider,
} from '@chakra-ui/react'
import { useGetRelationshipsQuery } from 'Services/API/motor.api'

import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import {
  INomineeFormState,
  ISelectedPlanFormState,
  setNomineeFormDetails,
  setPaymentDetails,
} from 'features/Sachets/sachetData.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  useAddCustomerMutation,
  useCreateConsentMutation,
  useLazyGetCriticalIllnessPlansQuery,
  useLazyGetConsentQuery,
  useLazyGetPaymentStatusQuery,
  useSaveSelectedPlanMutation,
} from 'Services/API/sachet.api'
import {
  getAccountTypeBasedOnIndex,
  onlyAllowTypingAlphabet,
  onlyAllowTypingAlphabetNumericAndSpace,
} from 'Services/general'
import { getAccountStatus } from 'Constants'
import { ToastService } from 'Services/toast.service'
import { SachetPaymentSuccessDialog } from 'Core/SachetPaymentSuccess'
import { SachetPaymentFailureDialog } from 'Core/SachetPaymentFailure'
import { useSpTaggingSendOtpMutation } from 'Services/API/authentication.api'
import { formatCurr } from 'Pages/Life/Sachet/ConsentForm'
import { RiLoaderLine } from 'react-icons/ri'
import { SachetConsentModal } from 'Core/Modal/SachetConsentModal'

const SelectedPlanSchema = yup.object().shape({
  name: yup.string().required('Nominee Name is required').max(100),
  code: yup.string().required('Nominee Name is required').max(100),
  description: yup.string().required('Nominee Name is required').max(100),
  logo: yup.string().required('Nominee Name is required').max(100),
  suminsured: yup.string().required('Nominee Name is required').max(100),
  premiumamount: yup.string().required('Nominee Name is required').max(100),
})

export interface IPaymentStatus {
  id: number
  transaction_number: string
  member_id: string
  sp_id: number
  insurance: string
  transaction_date: string
  amount: string
  status: string
  mode_of_payment: string
  quotation: number
  masterPolicyNumber?: string
}
interface ICriticalPlan {
  suminsured: string
  premiumamount: string
  plan_id: string
  name: string
}

const SachetsSelectPlanForm = ({
  setBlockEdit,
  blockEdit,
  selectedProductData,
  ApplicantAddress,
  fullCustomerDetails,
  isProceed,
}: {
  selectedProductData: any
  ApplicantAddress: { address1: string; address2: string }
  selectedAccount: any
  otherCustomerDetails: any
  fullCustomerDetails: any
  setIsProceed: any
  isProceed: any
  setBlockEdit: React.Dispatch<React.SetStateAction<boolean>>
  blockEdit: boolean
}) => {
  const { data: relationshipResponse, isLoading: isRelationshipListingFetching } =
    useGetRelationshipsQuery()

  useEffect(() => {
    if (relationshipResponse) setRelationshipListing(Object.values(relationshipResponse))
  }, [relationshipResponse])

  const cancerCoverType = useAppSelector((state) => state?.sachetDataDetails?.cancerCoverType)
  const [relationshipListing, setRelationshipListing] = useState<string[]>([])
  const [selectedPlanValue, setSelectedPlanValue] = useState('')
  const [addCustomerMutationFunc, { isLoading: isAddCustomerLoading }] = useAddCustomerMutation()
  const [createConsentMutationFunc, { isLoading: isCreateConsentLoading }] =
    useCreateConsentMutation()
  const [saveSelectedPlanMutationFunc, { isLoading: saveSelectedPlanLoading }] =
    useSaveSelectedPlanMutation()
  const [pollingIntervalVal, setPollingIntervalVal] = useState(2000)
  const [paymentPollingIntervalVal, setPaymentPollingIntervalVal] = useState(2000)

  const [getconsent, { currentData: data, isFetching, isLoading }] = useLazyGetConsentQuery({
    pollingInterval: pollingIntervalVal,
  })

  const [getCriticalIllnessPlans, { isLoading: isGetPlansLoading }] =
    useLazyGetCriticalIllnessPlansQuery()

  const [paymentStatusData, setPaymentStatusData] = useState<IPaymentStatus>({
    id: 0,
    transaction_number: '',
    member_id: '',
    sp_id: 0,
    insurance: '',
    transaction_date: '',
    amount: '',
    status: '',
    mode_of_payment: '',
    quotation: 0,
    masterPolicyNumber: '',
  })
  const [paymentStatus, setPaymentStatus] = useState('pending')

  const [
    getPaymentStatus,
    {
      currentData: paymentData,
      isFetching: isFetchingPaymentStatus,
      isLoading: isLoadingPaymentStatus,
    },
  ] = useLazyGetPaymentStatusQuery({
    pollingInterval: paymentPollingIntervalVal,
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [nomineeAddress, setNomineeAddress] = useState({
    Naddress1: '',
    Naddress2: '',
  })

  const getPaymentStatusFunc = async () => {
    const resp: any = await getPaymentStatus({
      quote_id: quoteIdVal,
    })

    if (resp?.data?.status == 'Success' || resp?.data?.status == 'Failed') {
      setPaymentPollingIntervalVal(0)
      // setPaymentStatus(resp?.data?.status)

      // setPaymentStatusData({
      //   ...resp?.data,
      //   amount: selectedPlanFormData?.premiumamount,
      //   masterPolicyNumber: applicantDetails?.policyNumber,
      // })
    }
    setPaymentStatus(resp?.data?.status)

    dispatch(setPaymentDetails(resp?.data))
  }

  useEffect(() => {
    if (paymentData?.status === 'Failed' || paymentData?.status === 'Success') {
      setPaymentPollingIntervalVal(0)
      setPaymentStatus(paymentData?.status)

      setPaymentStatusData({
        ...paymentData,
        amount: selectedPlanFormData?.premiumamount,
        masterPolicyNumber: applicantDetails?.policyNumber,
      })
    }
  }, [paymentData])

  useEffect(() => {
    if (data?.status === 'APPROVED' || data?.status === 'INVALID') {
      setPollingIntervalVal(0)
      try {
        getPaymentStatusFunc()
      } catch (error) {
        ToastService.showErrorToast({
          title: 'Failed to get payment status',
          message: 'Failed to get payment status. Please try again later.',
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (data?.status === 'APPROVED' || data?.status === 'INVALID') {
      setPollingIntervalVal(0)
    }
  }, [data])

  const [planList, setPlanList] = useState([])

  const getPlans = async () => {
    try {
      const res = await getCriticalIllnessPlans(quoteIdVal).unwrap()
      setPlanList(res)
    } catch (error) {
      ToastService.showErrorToast({
        title: 'Failed to get plans',
        message: 'Failed to get plans. Please try again later.',
      })
    }
  }

  const applicantDetails = useAppSelector((state) => state?.sachetDataDetails.applicantFormDetails)
  const applicantSpouseDetails = useAppSelector(
    (state) => state?.sachetDataDetails?.applicantFormDetailsSpouse,
  )
  const nomineeDetails = useAppSelector((state) => state.sachetDataDetails.nomineeFormDetails)
  const [getConsentLoading, setGetConsentLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ISelectedPlanFormState>({
    resolver: yupResolver(SelectedPlanSchema),
  })

  const dispatch = useAppDispatch()

  const [selectedPlanFormData, setSelectedPlanFormData] = useState({
    name: '',
    code: '',
    description: '',
    logo: '',
    suminsured: '',
    premiumamount: '',
  })

  let interval: NodeJS.Timeout

  const TIMER_LIMIT = 60

  const selectedAccountDetails = useAppSelector((state) => state.customerDetails)

  const branchName =
    selectedAccountDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp &&
    selectedAccountDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp[0]
      ?.brnchNm

  const quoteIdVal = useAppSelector((state) => state.sachetDataDetails.quoteId)
  const [smsSentStatus, setSmsSentStatus] = useState('')

  const selectedProductDetails = useAppSelector(
    (state) => state.sachetDataDetails.selectedProductData,
  )

  const nomineeDetailsFromStore = useAppSelector(
    (state) => state.sachetDataDetails?.nomineeFormDetails,
  )
  const customerProposerDetails = useAppSelector((state) => state.customerDetails)

  const sameAddress = {
    address_line_1: applicantDetails?.address1,
    address_line_2: applicantDetails?.address2,
    city: applicantDetails?.city,
    district: applicantDetails?.district,
    state: applicantDetails?.state,
    pincode: applicantDetails?.pinCode,
  }

  const [isCustomerAdded, setIsCustomerAdded] = useState(false)

  const addCustomerAndGetPlans = async () => {
    setBlockEdit(true)
    const customer = {
      first_name: applicantDetails?.firstName || ' ',
      middle_name: applicantDetails?.middleName || ' ',
      last_name: applicantDetails?.lastName || ' ',
      dob: applicantDetails?.dob || ' ',
      salutation: applicantDetails?.salutation,
      gender: applicantDetails?.gender || ' ',
      pincode: applicantDetails?.pinCode || ' ',
      email: fullCustomerDetails?.email || ' ',
      address: applicantDetails?.address1 ?? ' ',
      address_two: applicantDetails?.address2 ?? ' ',
      address_three: applicantDetails?.address3 ?? ' ',
      aadhar: applicantDetails?.aadhaar || ' ',

      contact_number: applicantDetails?.contactNumber || ' ',
      occupation: applicantDetails?.occupation || ' ',
      ifsc_code:
        (fullCustomerDetails?.accounts && fullCustomerDetails?.accounts[0]?.ifsc_code) || ' ',
      district: applicantDetails?.district || ' ',
      city: applicantDetails?.city || ' ',
      state: applicantDetails?.state || ' ',
      pan_number: applicantDetails?.pan ?? '',
      nationality: 'Indian',
    }
    const spouse_details = {
      first_name: applicantSpouseDetails?.firstName || ' ',
      middle_name: applicantSpouseDetails?.middleName || '',
      last_name: applicantSpouseDetails?.lastName || ' ',
      dob: applicantSpouseDetails?.dob || ' ',
      salutation: applicantSpouseDetails?.salutation,
      gender: applicantSpouseDetails?.gender || ' ',
      pincode: applicantSpouseDetails?.pinCode || ' ',
      address:
        applicantSpouseDetails?.address1 + ' ' + applicantSpouseDetails?.address2 ||
        ' ' + applicantSpouseDetails?.address3,
    }

    const nominee = {
      name: nomineeDetailsFromStore?.nomineeFullName || ' ',
      dob: nomineeDetailsFromStore?.nomineeDob || ' ',
      salutation: nomineeDetailsFromStore?.nomineeSalutation || ' ',

      gender: nomineeDetailsFromStore?.nomineeGender || ' ',
      relationship: nomineeDetailsFromStore?.nomineeRelationship || ' ',
      address_line_1: nomineeDetailsFromStore?.nomineeaddress1 || ' ',
      address_line_2: nomineeDetailsFromStore?.nomineeaddress2 || ' ',
      address_line_3: nomineeDetailsFromStore?.nomineeaddress3 || ' ',
      appointee_name: nomineeDetailsFromStore?.appointeeFullName || '',
      appointee_relation: nomineeDetailsFromStore?.appointeeRelationship || '',
      appointee_address: nomineeDetailsFromStore?.appointeeAddress || '',

      pincode: isCheckboxcheckedNominee == true ? applicantDetails?.pinCode : ' ',
      state: isCheckboxcheckedNominee == true ? applicantDetails?.state : ' ',
      city: isCheckboxcheckedNominee == true ? applicantDetails?.city : ' ',
      district: isCheckboxcheckedNominee == true ? applicantDetails?.district : ' ',

      // pincode:
    }

    const selectedAccount = selectedAccountDetails?.selectedAccount

    try {
      if (cancerCoverType == '1A') {
        const resp = await addCustomerMutationFunc({
          param: quoteIdVal,
          data: {
            customer,
            nominee,
            master_policy_number: applicantDetails?.policyNumber,
            account_details: {
              ...selectedAccount,
              brnchNm: branchName,
              cstId: fullCustomerDetails?.ucic,
              dbtrNm: selectedAccountDetails?.selectedAccount?.account_title,
              brnchAddr: '',
            },
            customer_details: fullCustomerDetails,
          },
        })
        await getPlans()
        setIsCustomerAdded(true)
      } else if (cancerCoverType == '2A') {
        const resp = await addCustomerMutationFunc({
          param: quoteIdVal,
          data: {
            customer,
            nominee,
            spouse_details,
            master_policy_number: applicantDetails?.policyNumber,
            account_details: {
              ...selectedAccount,
              brnchNm: branchName,
              cstId: fullCustomerDetails?.ucic,
              dbtrNm: selectedAccountDetails?.selectedAccount?.account_title,
              brnchAddr: '',
            },
            customer_details: fullCustomerDetails,
          },
        })
        await getPlans()
        setIsCustomerAdded(true)
      }
    } catch (error) {
      ToastService.showErrorToast({
        title: 'Error while adding customer or Loading the Plans',
      })
    }
  }
  const onSubmit = async () => {
    setGetConsentLoading(true)
    const selectedPlan: ISelectedPlanFormState = planList[parseInt(selectedPlanValue)]
    setSelectedPlanFormData({ ...selectedPlan, name: selectedProductDetails?.insurer_name })

    const customer = {
      first_name: applicantDetails?.firstName || ' ',
      middle_name: applicantDetails?.middleName || '',
      last_name: applicantDetails?.lastName || ' ',
      dob: applicantDetails?.dob || ' ',
      salutation: applicantDetails?.salutation,
      gender: applicantDetails?.gender || ' ',
      pincode: applicantDetails?.pinCode || ' ',
      email: fullCustomerDetails?.email || ' ',
      address: applicantDetails?.address1 ?? '',
      address_two: applicantDetails?.address2 ?? '',
      address_three: applicantDetails?.address3 ?? '',
      // aadhar: applicantDetails?.aadhaar || ' ',
      aadhar: applicantDetails?.aadhaar || ' ',

      contact_number: applicantDetails?.contactNumber || ' ',
      occupation: applicantDetails?.occupation || ' ',
      ifsc_code:
        (fullCustomerDetails?.accounts && fullCustomerDetails?.accounts[0]?.ifsc_code) || ' ',
      district: applicantDetails?.district || ' ',
      pan_number: applicantDetails?.pan || '',
      nationality: 'Indian',
    }
    const spouse_details = {
      first_name: applicantSpouseDetails?.firstName || ' ',
      middle_name: applicantSpouseDetails?.middleName || '',
      last_name: applicantSpouseDetails?.lastName || ' ',
      dob: applicantSpouseDetails?.dob || ' ',
      salutation: applicantSpouseDetails?.salutation,
      gender: applicantSpouseDetails?.gender || ' ',
      pincode: applicantSpouseDetails?.pinCode || ' ',
      address:
        applicantSpouseDetails?.address1 + ' ' + applicantSpouseDetails?.address2 ||
        ' ' + applicantSpouseDetails?.address3,
    }

    const nominee = {
      name: nomineeDetailsFromStore?.nomineeFullName || ' ',
      dob: nomineeDetailsFromStore?.nomineeDob || ' ',
      salutation: nomineeDetailsFromStore?.nomineeSalutation || ' ',

      gender: nomineeDetailsFromStore?.nomineeGender || ' ',
      relationship: nomineeDetailsFromStore?.nomineeRelationship || ' ',
      address_line_1: nomineeDetailsFromStore?.nomineeaddress1 || ' ',
      address_line_2: nomineeDetailsFromStore?.nomineeaddress2 || ' ',
      address_line_3: nomineeDetailsFromStore?.nomineeaddress3 || ' ',
      appointee_name: nomineeDetailsFromStore?.appointeeFullName || ' ',
      appointee_relation: nomineeDetailsFromStore?.appointeeRelationship || ' ',
      appointee_address: nomineeDetailsFromStore?.appointeeAddress || ' ',
      pincode: isCheckboxcheckedNominee == true ? applicantSpouseDetails?.pinCode : ' ',
    }

    const customerArrObjData = [
      { label: 'Salutation', value: applicantDetails?.salutation || '-' },

      { label: 'First Name', value: applicantDetails?.firstName || '-' },
      { label: 'Middle Name', value: applicantDetails?.middleName || '-' },

      { label: 'Last Name', value: applicantDetails?.lastName || '-' },
      { label: 'Relationship', value: applicantDetails?.relationship || '-' },

      { label: 'Date of Birth', value: applicantDetails?.dob || '-' },
      {
        label: 'Gender',
        value:
          applicantDetails?.gender == 'm'
            ? 'Male'
            : applicantDetails?.gender == 'f'
            ? 'Female'
            : '-',
      },
      { label: 'Email', value: fullCustomerDetails?.email || '-' },
      { label: 'Occupation', value: applicantDetails?.occupation || '-' },
      // {
      //   label: 'IFSC Code',
      //   value:
      //     (fullCustomerDetails?.accounts && fullCustomerDetails?.accounts[0]?.ifsc_code) || '-',
      // },
      {
        label: 'Address Line 1',
        value: applicantDetails?.address1 || '-',
      },
      {
        label: 'Address Line 2',
        value: applicantDetails?.address2 || '-',
      },
      {
        label: 'Address Line 3',
        value: applicantDetails?.address3 || '-',
      },

      { label: 'City', value: applicantDetails?.city || '-' },
      // { label: 'District', value: applicantDetails?.city || '-' },
      { label: 'District', value: applicantDetails?.district || '-' },
      { label: 'State', value: applicantDetails?.state || '-' },
      { label: 'Pincode', value: applicantDetails?.pinCode || '-' },

      // { label: 'Aadhar', value: applicantDetails?.aadhaar || '-' },
      { label: 'Master Policy Number', value: applicantDetails?.policyNumber || '-' },
      { label: 'Phone Number', value: applicantDetails?.contactNumber || '-' },
    ]
    const customerSpouseArrObjData = [
      // { label: 'Salutation', value: applicantDetails?.salutation || '-' },
      { label: 'Salutation', value: applicantSpouseDetails?.salutation || '-' },

      { label: 'First Name', value: applicantSpouseDetails?.firstName || '-' },
      { label: 'Middle Name', value: applicantSpouseDetails?.middleName || '-' },
      { label: 'Last Name', value: applicantSpouseDetails?.lastName || '-' },
      { label: 'Relationship', value: applicantSpouseDetails?.relationship || '-' },
      { label: 'Date of Birth', value: applicantSpouseDetails?.dob || '-' },

      {
        label: 'Gender',
        value:
          applicantSpouseDetails?.gender == 'm'
            ? 'Male'
            : applicantSpouseDetails?.gender == 'f'
            ? 'Female'
            : '-',
      },

      {
        label: 'Address Line 1',
        value: applicantSpouseDetails?.address1 || '-',
      },
      {
        label: 'Address Line 2',
        value: applicantSpouseDetails?.address2 || '-',
      },
      {
        label: 'Address Line 3',
        value: applicantSpouseDetails?.address3 || '-',
      },

      { label: 'City', value: applicantSpouseDetails?.city || '-' },
      { label: 'District', value: applicantSpouseDetails?.district || '-' },
      { label: 'State', value: applicantSpouseDetails?.state || '-' },
      { label: 'Pincode', value: applicantSpouseDetails?.pinCode || '-' },
    ]

    const nomineeArrObjData = [
      {
        label: 'Salutation',
        value: nomineeDetailsFromStore?.nomineeSalutation || '-',
      },
      { label: 'Name', value: nomineeDetailsFromStore?.nomineeFullName || '-' },
      { label: 'Date of Birth', value: nomineeDetailsFromStore?.nomineeDob || '-' },

      {
        label: 'Gender',
        value:
          nomineeDetailsFromStore?.nomineeGender == 'm'
            ? 'Male'
            : nomineeDetailsFromStore?.nomineeGender == 'f'
            ? 'Female'
            : '-',
      },
      { label: 'Relationship', value: nomineeDetailsFromStore?.nomineeRelationship || '-' },
      {
        label: 'Address Line 1',
        value: nomineeDetailsFromStore?.nomineeaddress1 || '-',
      },
      {
        label: 'Address Line 2',
        value: nomineeDetailsFromStore?.nomineeaddress2 || '' || '-',
      },
      {
        label: 'Address Line 3',
        value: nomineeDetailsFromStore?.nomineeaddress3 || '' || '-',
      },
    ]
    const appointeeArrObjData = [
      { label: 'Name', value: nomineeDetailsFromStore?.appointeeFullName || '-' },

      { label: 'Relationship', value: nomineeDetailsFromStore?.appointeeRelationship || '-' },
      {
        label: 'Address',
        value: nomineeDetailsFromStore?.appointeeAddress || '-',
      },
    ]

    const selectedProductArrObjData = {
      name: selectedPlan?.name || '-',
      insurer_logo: selectedProductDetails?.insurer_logo || '-',
      insurer_name: selectedProductDetails?.insurer_name || '-',
      premium: parseFloat(selectedPlan?.premiumamount) || '-',
      sum_assured: parseFloat(selectedPlan?.suminsured) || '-',
      code: selectedProductDetails?.code || '-',
    }

    const selectedAccount = selectedAccountDetails?.accounts && selectedAccountDetails?.accounts[0]

    try {
      await saveSelectedPlanMutationFunc({
        quote_id: quoteIdVal || '-',
        amount: selectedPlan?.premiumamount || '-',
        variant_code: selectedPlan?.code || '-',
      })

      const createConsentResp: any = await createConsentMutationFunc({
        ucic_id: customerProposerDetails?.ucic || '',
        quote_id: quoteIdVal,
        phone_number: fullCustomerDetails?.phone.slice(2),
        email: fullCustomerDetails?.email,
        ui_data: {
          customerAccountDetails: [
            {
              label: 'Account Lead',
              value: customerProposerDetails.selectedAccount?.account_lead ?? '-',
            },
            {
              label: 'Account Title',
              value: customerProposerDetails.selectedAccount?.account_title ?? '-',
            },
            {
              label: 'Source Branch',
              value: customerProposerDetails.selectedAccount?.source_branch ?? '-',
            },
            {
              label: 'Opening Branch',
              value: customerProposerDetails.selectedAccount?.opening_branch ?? '-',
            },
            {
              label: 'Account Type',
              value: getAccountTypeBasedOnIndex(
                customerProposerDetails.selectedAccount?.account_type ?? '-',
              ),
            },
            {
              label: 'Account Number',
              value: customerProposerDetails.selectedAccount?.show_account_number ?? '-',
            },
            {
              label: 'Account ID',
              value: customerProposerDetails.selectedAccount?.account_id ?? '-',
            },
            {
              label: 'Account Status',
              value: getAccountStatus(customerProposerDetails.selectedAccount?.accStsCode ?? '-'),
            },
            {
              label: 'UCIC ID',
              value: customerProposerDetails?.ucic ?? '-',
            },
            // {
            //   label: 'IFSC Code',
            //   value: customerProposerDetails.selectedAccount?.ifsc_code ?? '-',
            // },
          ],
          customerArrObjData,
          isNomineeAMinor: nomineeDetailsFromStore?.isNomineeAMinorChecked,

          cancerCoverType,
          customerSpouseArrObjData,
          nomineeArrObjData,
          selectedProductArrObjData,
          masterPolicyNumber: applicantDetails ? applicantDetails?.policyNumber : '-',
          isSameAddressNominee: isCheckboxcheckedNominee,
          isSameAddressspouse: isCheckboxcheckedSpouse,
          sameAddress,
          phoneNumber: fullCustomerDetails?.phone.slice(2),
          email: fullCustomerDetails?.email,
          appointeeArrObjData,
        },
      })

      if (createConsentResp?.error?.data?.status == 400) {
        setSmsSentStatus('Failed')
        setOpenConsentModal(false)

        ToastService.showErrorToast({
          title: 'Failed to create consent',
          message: createConsentResp?.error?.data?.message,
        })
      } else {
        setSmsSentStatus('Success')
      }

      setPollingIntervalVal(2000)
      const consentData = await getconsent({
        quote_id: quoteIdVal!,
      }).unwrap()

      setGetConsentLoading(false)
    } catch (error: any) {
      ToastService.showErrorToast({
        title: 'Failed to create consent or add Customer || Something went wrong',
        message: error.message,
      })
    }

    onOpen()
  }

  const calcAge18 = () => {
    // const dob = new Date(applicantDetails?.dob)
    const today = new Date()
    const yr = today.getFullYear() - 18
    const month = today.getMonth()
    const date = today.getDate()
    const finalDate = new Date(yr, month, date)

    return finalDate
  }

  const isMoreThan18 = (dob: Date) => {
    const today = new Date()
    const yr = today.getFullYear() - dob.getFullYear()
    const month = today.getMonth() - dob.getMonth()
    const day = today.getDate() - dob.getDate()

    // If age in years is less than 18, return false
    if (yr < 18) {
      return false
    }

    // If age in years is more than 18, return true
    if (yr > 18) {
      return true
    }

    // If age in years is exactly 18, we need to check months and days
    if (yr === 18) {
      // If the birth month has not yet occurred this year, return false
      if (month < 0) {
        return false
      }

      // If the birth month has occurred but not the birth day, return false
      if (month === 0 && day < 0) {
        return false
      }

      // Otherwise, return true
      return true
    }

    // Return false if none of the above conditions are met (this point should be unreachable)
    return false
  }

  const fetchedData = {
    nomineeFullName: '',
    nomineeRelationship: '',
    nomineeDob: '',
    nomineeaddress1: '',
    nomineeaddress2: '',
    nomineeGender: '',
    nomineeSalutation: '',
  }

  useEffect(() => {
    Object.keys(selectedPlanFormData).forEach((key) => {
      if (selectedPlanFormData[key as keyof ISelectedPlanFormState]) {
        setValue(
          key as keyof ISelectedPlanFormState,
          selectedPlanFormData[key as keyof ISelectedPlanFormState],
        )
      }
    })
  }, [])
  const { proposalDetails: proposalDetailsInStore } = useAppSelector((state) => state.proposalPage)

  const [selectedRelationship, setSelectedRelationship] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.nomineeRelationship
      ? {
          label: proposalDetailsInStore.nomineeRelationship,
          value: proposalDetailsInStore.nomineeRelationship,
        }
      : {
          label: 'Select Relationship',
          value: '',
        },
  )

  const PlanLogo = (selectedProductData && selectedProductData?.insurer_logo) || ''
  const isCheckboxcheckedNominee = useAppSelector(
    (state) => state?.sachetDataDetails?.isSameAddressNominee,
  )
  const isCheckboxcheckedSpouse = useAppSelector(
    (state) => state?.sachetDataDetails?.isSameAddressSpouse,
  )

  const [openConsentModal, setOpenConsentModal] = useState(true)
  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendLink, setResendLink] = useState(false)
  let tempTimeLeft = TIMER_LIMIT

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendLink(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }
  const handleResendLink = async () => {
    getPaymentStatusFunc()
    try {
      const customerArrObjData = [
        { label: 'Salutation', value: applicantDetails?.salutation || '-' },

        { label: 'First Name', value: fullCustomerDetails?.first_name || '-' },
        { label: 'Last Name', value: fullCustomerDetails?.last_name || '-' },
        { label: 'Date of Birth', value: applicantDetails?.dob || '-' },
        {
          label: 'Gender',
          value:
            applicantDetails?.gender == 'm'
              ? 'Male'
              : applicantDetails?.gender == 'f'
              ? 'Female'
              : '-',
        },
        { label: 'Email', value: fullCustomerDetails?.email || applicantDetails?.email || '-' },
        {
          label: 'Address Line 1',
          value: applicantDetails?.address1 || '-',
        },
        {
          label: 'Address Line 2',
          value: applicantDetails?.address2 || '-',
        },
        {
          label: 'Address Line 3',
          value: applicantDetails?.address3 || '-',
        },

        { label: 'City', value: applicantDetails?.city || '-' },
        // { label: 'District', value: applicantDetails?.city || '-' },
        { label: 'District', value: applicantDetails?.district || '-' },
        { label: 'State', value: applicantDetails?.state || '-' },
        { label: 'Pincode', value: applicantDetails?.pinCode || '-' },
        { label: 'Master Policy Number', value: applicantDetails?.policyNumber || '-' },
        { label: 'Relationship', value: applicantDetails?.relationship || '-' },
      ]

      const nomineeArrObjData = [
        {
          label: 'Salutation',
          value: nomineeDetailsFromStore?.nomineeSalutation || '-',
        },
        { label: 'Name', value: nomineeDetailsFromStore?.nomineeFullName || '-' },
        { label: 'Date of Birth', value: nomineeDetailsFromStore?.nomineeDob || '-' },

        {
          label: 'Gender',
          value:
            nomineeDetailsFromStore?.nomineeGender == 'm'
              ? 'Male'
              : nomineeDetailsFromStore?.nomineeGender == 'f'
              ? 'Female'
              : '-',
        },
        { label: 'Relationship', value: nomineeDetailsFromStore?.nomineeRelationship || '-' },
        {
          label: 'Address Line 1',
          value: nomineeDetailsFromStore?.nomineeaddress1 || '-',
        },
        {
          label: 'Address Line 2',
          value: nomineeDetailsFromStore?.nomineeaddress2 || '-',
        },
        {
          label: 'Address Line 3',
          value: nomineeDetailsFromStore?.nomineeaddress3 || '-',
        },
      ]

      const selectedProductArrObjData = {
        name: selectedProductDetails?.name || '-',
        insurer_logo: selectedProductDetails?.insurer_logo || '-',
        insurer_name: selectedProductDetails?.insurer_name || '-',
        premium: selectedProductDetails?.premium || '-',
        sum_assured: selectedProductDetails?.sum_assured || '-',
        code: selectedProductDetails?.code || '-',
      }
      const sameAddress = {
        address_line_1: applicantDetails?.address1,
        address_line_2: applicantDetails?.address2,
        address_line_3: applicantDetails?.address3,
        city: applicantDetails?.city,
        state: applicantDetails?.state,
        pincode: applicantDetails?.pinCode,
      }
      const appointeeArrObjData = [
        { label: 'Name', value: nomineeDetailsFromStore?.appointeeFullName || '-' },

        { label: 'Relationship', value: nomineeDetailsFromStore?.appointeeRelationship || '-' },
        {
          label: 'Address',
          value: nomineeDetailsFromStore?.appointeeAddress || '-',
        },
      ]
      const createConsentResp: any = await createConsentMutationFunc({
        ucic_id: customerProposerDetails?.ucic || '',

        quote_id: quoteIdVal,
        phone_number: fullCustomerDetails?.phone.slice(2),
        email: fullCustomerDetails?.email,
        ui_data: {
          customerAccountDetails: [
            {
              label: 'Account Lead',
              value: customerProposerDetails.selectedAccount?.account_lead ?? '-',
            },
            {
              label: 'Account Title',
              value: customerProposerDetails.selectedAccount?.account_title ?? '-',
            },
            {
              label: 'Source Branch',
              value: customerProposerDetails.selectedAccount?.source_branch ?? '-',
            },
            {
              label: 'Opening Branch',
              value: customerProposerDetails.selectedAccount?.opening_branch ?? '-',
            },
            {
              label: 'Account Type',
              value: getAccountTypeBasedOnIndex(
                customerProposerDetails.selectedAccount?.account_type ?? '-',
              ),
            },
            {
              label: 'Account Number',
              value: customerProposerDetails.selectedAccount?.show_account_number ?? '-',
            },
            {
              label: 'Account ID',
              value: customerProposerDetails.selectedAccount?.account_id ?? '-',
            },
            {
              label: 'Account Status',
              value: getAccountStatus(customerProposerDetails.selectedAccount?.accStsCode ?? ''),
            },
            {
              label: 'UCIC ID',
              value: customerProposerDetails?.ucic ?? '-',
            },
            // {
            //   label: 'IFSC Code',
            //   value: customerProposerDetails.selectedAccount?.ifsc_code ?? '-',
            // },
          ],
          customerArrObjData,
          isNomineeAMinor: nomineeDetailsFromStore?.isNomineeAMinorChecked,
          appointeeArrObjData,
          nomineeArrObjData,
          selectedProductArrObjData,
          masterPolicyNumber: applicantDetails ? applicantDetails?.policyNumber : '-',
          isSameAddressNominee: isCheckboxcheckedNominee,
          isSameAddressSpouse: isCheckboxcheckedSpouse,
          sameAddress,
          phoneNumber: fullCustomerDetails?.phone.slice(2),
          email: fullCustomerDetails?.email || applicantDetails?.email,
        },
      })
      setTimeLeft(TIMER_LIMIT)
      setResendLink(false)
      startTimer()

      setPollingIntervalVal(2000)
      const consentData = await getconsent({
        quote_id: quoteIdVal!,
      }).unwrap()

      if (consentData && consentData?.status == 'APPROVED') {
        setPollingIntervalVal(0)
        try {
          setPaymentPollingIntervalVal(2000)
          getPaymentStatusFunc()
        } catch (error) {
          ToastService.showErrorToast({
            title: 'Failed to get payment status',
            message: 'Failed to get payment status. Please try again later.',
          })
        }
      }
    } catch (error) {
      ToastService.showErrorToast({
        title: 'Failed to Resend Link',
        message: 'Failed to Resend Link. Please try again later.',
      })
    }
  }

  return (
    <>
      {openConsentModal &&
        smsSentStatus == 'Success' &&
        paymentStatus !== 'Success' &&
        paymentStatus !== 'Failed' && (
          <SachetConsentModal
            selectedPlanFormData={selectedPlanFormData}
            isCheckboxcheckedNominee={isCheckboxcheckedNominee}
            isCheckboxcheckedSpouse={isCheckboxcheckedSpouse}
            fullCustomerDetails={fullCustomerDetails}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            timerLimit={TIMER_LIMIT}
            dataStatus={data?.status}
            ModalText={
              data?.status === 'UNAPPROVED'
                ? 'Waiting for customer to accept the consent'
                : data?.status === 'APPROVED'
                ? 'Waiting for customer to do the payment'
                : ''
            }
            paymentStatus={paymentStatus}
          />
        )}

      {paymentStatus && paymentStatus?.toLowerCase() === 'success' && (
        <Modal
          isCentered
          isOpen={paymentStatus === 'Success'}
          onClose={() => console.log('On Close')}
        >
          <ModalOverlay />
          <ModalContent>
            <SachetPaymentSuccessDialog backHome={true} paymentStatusData={paymentStatusData} />
          </ModalContent>
        </Modal>
      )}
      {paymentStatus && paymentStatus?.toLowerCase() === 'failed' && (
        <Modal
          isCentered
          isOpen={paymentStatus === 'Failed'}
          onClose={() => console.log('On Close')}
        >
          <ModalOverlay />
          <ModalContent>
            <SachetPaymentFailureDialog
              backHome={true}
              paymentStatusData={paymentStatusData}
              handleResendLink={handleResendLink}
              paymentData={paymentData}
              // resendLink={resendLink}
            />
          </ModalContent>
        </Modal>
      )}

      <Flex direction='column' justifyContent='center' alignItems='center' gap='7'>
        <RadioGroup
          onChange={(e) => {
            setSelectedPlanValue(e)
          }}
          value={selectedPlanValue}
        >
          <Grid templateColumns='repeat(3, 1fr)' gap={6} w='full'>
            {planList &&
              planList.map((plan: ICriticalPlan, index: number) => {
                return (
                  <Radio value={index.toString()} key={plan?.plan_id} m='4'>
                    <Flex
                      height='220px'
                      width='220px'
                      justifyContent='center'
                      alignItems='center'
                      direction='column'
                      borderRadius='10px 10px 0px 0px'
                    >
                      <Image
                        src={PlanLogo || ''}
                        alt='Plan Logo'
                        border='1px'
                        fit='fill'
                        borderRadius='15px 15px 0px 0px'
                      />
                      <Flex
                        justifyContent='center'
                        alignItems='center'
                        direction='column'
                        w='full'
                        bgColor='white'
                        gap='1.4'
                        border='1px'
                        borderRadius='0px 0px 15px 15px'
                        px='2'
                      >
                        <Flex w='full' justifyContent='space-around' alignItems='center'>
                          <Text textColor='#7B7B7B' w='50%' textAlign='start'>
                            Plan Name:
                          </Text>
                          <Text fontSize='0.8rem' w='50%' textAlign='end'>
                            {plan?.name}
                          </Text>
                        </Flex>
                        <Divider />

                        <Flex w='full' justifyContent='space-around' alignItems='center'>
                          <Text textColor='#7B7B7B' textAlign='start' w='50%'>
                            Premium:
                          </Text>
                          <Text textColor='red' fontSize='1.2rem' w='50%' textAlign='end'>
                            {formatCurr(parseFloat(plan?.premiumamount))}
                          </Text>
                        </Flex>
                        <Divider />
                        <Flex
                          w='full'
                          justifyContent='space-around'
                          alignItems='center'
                          textAlign='right'
                        >
                          <Text textColor='#7B7B7B' w='50%' textAlign='start'>
                            Sum Insured:
                          </Text>
                          <Text fontSize='0.8rem' w='50%' textAlign='end'>
                            {' '}
                            {formatCurr(parseFloat(plan?.suminsured))}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Radio>
                )
              })}
          </Grid>
        </RadioGroup>
        {selectedPlanValue == '' && (
          <Flex textColor='red.300' fontWeight='bold' hidden={!isCustomerAdded}>
            Select a Plan before Proceeding
          </Flex>
        )}

        <Button
          onClick={addCustomerAndGetPlans}
          isLoading={isAddCustomerLoading || isGetPlansLoading}
          hidden={isCustomerAdded}
        >
          Get the Plans{' '}
        </Button>
        <Button
          onClick={() => {
            return onSubmit()
          }}
          hidden={!isCustomerAdded}
          disabled={selectedPlanValue == ''}
          type='submit'
          border='1px'
          bgColor='#1C4589'
          px='20'
          py='5'
          cursor='pointer'
          isLoading={getConsentLoading}
        >
          Get Customer Consent
        </Button>
      </Flex>
    </>
  )
}

export default SachetsSelectPlanForm
