import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { useAppSelector, useDeviceType, useWindowDimensions } from 'app/hooks'
import { Addons } from 'Pages/Health/ProposalPage/AddOns'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { IndianRupeeFormatFromString } from 'Services/general'

export const PremiumBreakUpModal = () => {
  const { selectedQuotes } = useAppSelector((state) => state.cart)
  const { isDesktop } = useDeviceType()

  return (
    <>
      {Object.keys(selectedQuotes).map((premium) => {
        return (
          <Box p='4' w={isDesktop ? '800px' : ''} key={selectedQuotes[premium].code!}>
            <Text fontSize={'20px'} fontWeight={'bold'} textAlign='center'>
              {selectedQuotes[premium].insuranceName}
            </Text>
            <Box my='1'>
              {
                <Box my='2' backgroundColor={'#EAEAEA'}>
                  <Flex fontWeight={'medium'} justifyContent={'space-between'} mx='4' py='2'>
                    <Text>Basic Premium</Text>
                    <Text>
                      ₹{IndianRupeeFormatFromString(selectedQuotes[premium].basePremium!)}
                    </Text>
                  </Flex>
                </Box>
              }
              <>
                {selectedQuotes[premium].addons.length > 0 && (
                  <Box my='2' backgroundColor={'gray.100'}>
                    <Flex fontWeight={'medium'} justifyContent={'space-between'} mx='4' py='2'>
                      <Flex gap='1'>
                        <AiOutlinePlus className='my-auto' />
                        <Text>Add Ons</Text>
                      </Flex>
                    </Flex>
                    {selectedQuotes[premium].addons.map((addon) => {
                      return (
                        <Flex
                          color='gray'
                          py='1'
                          justifyContent={'space-between'}
                          w='70%'
                          mx='6'
                          key={addon.code}
                        >
                          <Text>{addon.name}</Text>
                          {addon.amount != '-2' && (
                            <Text>₹{IndianRupeeFormatFromString(addon.amount)}/-</Text>
                          )}
                          {addon.amount == '-2' && <Text>Included</Text>}
                          {addon.amount == '-1' && <Text>NA</Text>}
                        </Flex>
                      )
                    })}
                  </Box>
                )}
                {selectedQuotes[premium].addonsTotal ? (
                  <Box my='2' backgroundColor={'#EAEAEA'}>
                    <Flex fontWeight={'medium'} mx='4' py='2' justifyContent={'space-between'}>
                      <Text>Total Addons Premium</Text>
                      <Text>
                        ₹{IndianRupeeFormatFromString(selectedQuotes[premium].addonsTotal!)}
                      </Text>
                    </Flex>
                  </Box>
                ) : (
                  <div></div>
                )}
              </>
              {/* )} */}
              {selectedQuotes[premium].discountTotal ? (
                <Box my='2' backgroundColor={'gray.100'}>
                  <Flex fontWeight={'medium'} justifyContent={'space-between'} mx='4' py='2'>
                    <Flex gap='1'>
                      <AiOutlineMinus className='my-auto' />
                      <Text>Discount</Text>
                    </Flex>
                    <Flex gap='1'>
                      <AiOutlineMinus className='my-auto' />
                      <Text>
                        ₹{IndianRupeeFormatFromString(selectedQuotes[premium].discountTotal!)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              ) : (
                ''
              )}
              {selectedQuotes[premium].loadingTotal! ? (
                <Box my='2' backgroundColor={'#EAEAEA'}>
                  <Flex fontWeight={'medium'} justifyContent={'space-between'} mx='4' py='2'>
                    <Flex gap='1'>
                      <AiOutlinePlus className='my-auto' />
                      <Text>Loading Additional Prem.</Text>
                    </Flex>
                    <Flex gap='1'>
                      <AiOutlinePlus className='my-auto' />
                      <Text>
                        ₹{IndianRupeeFormatFromString(selectedQuotes[premium].loadingTotal!)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              ) : (
                ''
              )}
              {/* {
                <Box my='2' backgroundColor={'gray.100'}>
                  <Flex fontWeight={'medium'} mx='4' py='2' justifyContent={'space-between'}>
                    <Text>Net Premium</Text>
                    <Text>
                      ₹ {IndianRupeeFormatFromString(selectedQuotes[premium].netPremium!)}
                    </Text>
                  </Flex>
                </Box>
              } */}
              {selectedQuotes[premium].GST! ? (
                <Box my='2' backgroundColor={'#EAEAEA'}>
                  <Flex fontWeight={'medium'} mx='4' py='2' justifyContent={'space-between'}>
                    <Flex>
                      <AiOutlinePlus className='my-auto' />

                      <Text>GST</Text>
                    </Flex>
                    <Flex gap='1'>
                      <AiOutlinePlus className='my-auto' />
                      <Text>₹{IndianRupeeFormatFromString(selectedQuotes[premium].GST!)}</Text>
                    </Flex>
                  </Flex>
                </Box>
              ) : (
                ''
              )}
            </Box>
            <Box my='4'>
              <Divider />
            </Box>
            <Flex justifyContent={'center'} gap='4' fontWeight={'medium'} my='4'>
              <Text my='auto' fontSize={'20px'}>
                Total Premium
              </Text>
              <Text color={'primary.500'} my='auto' fontSize={'20px'} fontWeight={'bold'}>
                ₹{IndianRupeeFormatFromString(selectedQuotes[premium].premium!.toString())}
              </Text>
            </Flex>
          </Box>
        )
      })}
    </>
  )
}
