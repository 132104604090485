import { CheckIcon, Icon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  List,
  ListItem,
  Text,
  Image,
} from '@chakra-ui/react'
import { BsDownload, BsFillCheckSquareFill, BsFillShareFill } from 'react-icons/bs'
import { IndianRupeeFormatFromString } from 'Services/general'
import { useState } from 'react'
import { Modal, theme } from 'Core'
import { CompareModal } from 'Core/Modal/CompareModal'
import { CashlessModal } from 'Core/Modal/CashlessModal/index'

interface IQuotationCardProps extends React.ComponentPropsWithRef<'div'> {
  insuranceName: string
  premium: string
  sumInsured: string
  insurerLogo: string
  benefits: {
    id: number
    name: string
  }[]
  highlights: {
    id: number
    name: string
  }[]
  selected?: boolean
  brochure?: string
  isChecked?: boolean
  onSelect?: React.Dispatch<React.SetStateAction<any>>
  addQuoteToCompare: () => void
  totalQuotesAddedToCompare: number
}

export const QuotationCard = (props: IQuotationCardProps) => {
  const {
    insuranceName,
    premium,
    sumInsured,
    insurerLogo,
    benefits,
    highlights,
    selected,
    isChecked,
    onSelect,
    addQuoteToCompare,
    brochure,
    totalQuotesAddedToCompare,
  } = props
  const [isCashlessModalOpen, setIsCashlessModalOpen] = useState(false)
  const [imageLoadFail, setImageLoadFail] = useState(false)

  return (
    <>
      <Flex direction={'column'}>
        <Box>
          <Box
            border='1px'
            borderColor={`${selected ? '#2680EB' : ''}`}
            rounded='2xl'
            bg='white'
            shadow='lg'
            id={props.insuranceName}
          >
            <Flex justifyContent={'flex-end'} m='3'>
              <Flex justifyContent={'center'} gap='2' alignItems={'center'}>
                {isChecked ? (
                  <>
                    <Icon
                      cursor={'pointer'}
                      as={BsFillCheckSquareFill}
                      color={'blue.900'}
                      onClick={addQuoteToCompare}
                    />
                  </>
                ) : (
                  <Box   cursor={'pointer'} onClick={addQuoteToCompare} bg='white' border='1px' h='4' w='4'></Box>
                )}{' '}
                Compare
                {/* <Checkbox onChange={() => {
                  addQuoteToCompare()

                }}>Compare</Checkbox> */}
              </Flex>
            </Flex>
            <Box padding='3' margin='3'>
              <Text
                fontWeight='semibold'
                fontSize='small'
                marginTop='4'
                marginBottom='2'
                textAlign='left'
              >
                {insuranceName}
              </Text>
              <Flex justifyContent='space-between' className='my-2'>
                <div className='border border-[#E0E0E0] my-auto'>
                  {/* <img src={insurerLogo} className='w-[10vh] my-4 ' /> */}
                  {imageLoadFail ? (
                    <Center>
                      <Text width='100%' height='100%' fontSize='sm' textAlign='center'>
                        {insuranceName}
                      </Text>
                    </Center>
                  ) : (
                    <Image
                      className='w-[10vh] my-2 mx-2'
                      src={insurerLogo}
                      // border='1px solid #F1F3F4'

                      onError={() => setImageLoadFail(true)}
                    />
                  )}
                </div>
                <Flex direction={'column'} className='text-[#7B7B7B] text-sm' textAlign='left'>
                  <Text>Premium:</Text>
                  <Text color='primary.500' fontSize='large' fontWeight='semibold' marginBottom='2'>
                    ₹{IndianRupeeFormatFromString(premium)}
                  </Text>
                  <Text>Sum Insured:</Text>
                  <Text>₹{IndianRupeeFormatFromString(sumInsured)}</Text>
                </Flex>
              </Flex>
              {brochure && (
                <Flex justifyContent={'flex-end'}>
                  <Flex
                    gap='1'
                    onClick={() => {
                      window.open(brochure, '_blank')
                    }}
                  >
                    {' '}
                    <BsDownload size='16' color='primary' />{' '}
                    <Text fontWeight={'bold'} fontSize={'sm'} color='primary.500'>
                      Download Brochure
                    </Text>
                  </Flex>
                </Flex>
              )}
              <Text my='2' fontSize='xs' fontWeight='bold' textAlign='left'>
                Benefits
              </Text>
              <Box h='80px' overflowY='auto'>
                {benefits.map((item) => (
                  <Flex key={item.id}>
                    <CheckIcon className='my-auto mx-1' color='primary.500' />
                    <Text className='text-xs text-[#7B7B7B]'>{item.name}</Text>
                  </Flex>
                ))}
              </Box>
              {highlights.length > 0 && (
                <>
                  <Text my='2' fontSize='xs' fontWeight='bold' textAlign='left'>
                    Highlights
                  </Text>
                  <List h='100px' overflowY='auto' styleType='disc' mx='2' textAlign='left'>
                    {highlights.map((item) => (
                      <ListItem key={item.id} className='text-xs '>
                        {item.name}
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Box>
            <Box position='relative' bottom='0'>
              <Flex>
                {selected && (
                  <Button
                    w='full'
                    roundedTopLeft={'none'}
                    roundedBottomLeft='14px'
                    colorScheme={'primary'}
                    roundedBottomRight='14px'
                    variant='outline'
                  >
                    ADDED TO CART
                  </Button>
                )}
                {!selected && (
                  <Button
                    w='full'
                    roundedTopLeft={'none'}
                    roundedTopRight={'none'}
                    roundedBottomLeft='14px'
                    roundedBottomRight='14px'
                    variant='solid'
                    colorScheme={'primary'}
                    onClick={() => onSelect && onSelect(true)}
                  >
                    ADD TO CART
                  </Button>
                )}
                {/* <Button
                  w='full'
                  colorScheme={'primary'}
                  variant='solid'
                  roundedBottomRight='14px'
                  roundedTopRight={'none'}
                  roundedLeft={'none'}
                >
                  BUY
                </Button> */}
              </Flex>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Modal isOpen={isCashlessModalOpen} onClose={() => setIsCashlessModalOpen(false)}>
        <CashlessModal />
      </Modal>
    </>
  )
}
