import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { IWheeler } from 'Models/ResponseModels/motor'

interface IMotorSummaryTable {
  tableData?: IWheeler
}

const MotorSummaryTable = (props: IMotorSummaryTable) => {
  const { tableData } = props
  return (
    <TableContainer paddingY='0.5rem'>
      <Table border='1px' variant='unstyled'>
        <Thead>
          <Tr bgColor='primary.500' color='#fff'>
            <Th isNumeric></Th>
            <Th isNumeric>Third Party Polices</Th>
            <Th isNumeric>Comprehensive Policies</Th>
            <Th isNumeric>Stand Alone OD Policies</Th>
            <Th isNumeric>Total</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td isNumeric>Number of Policies</Td>
            <Td isNumeric>{tableData?.TP.count}</Td>
            <Td isNumeric>{tableData?.CO.count}</Td>
            <Td isNumeric>{tableData?.OD.count}</Td>
            <Td isNumeric>{tableData?.total.count}</Td>
          </Tr>
          <Tr bgColor='appGrey.400'>
            <Td isNumeric>Gross Premium</Td>
            <Td isNumeric>{tableData?.TP.total_premium}</Td>
            <Td isNumeric>{tableData?.CO.total_premium}</Td>
            <Td isNumeric>{tableData?.OD.total_premium}</Td>
            <Td isNumeric>{tableData?.total.total_premium}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Net Premium</Td>
            <Td isNumeric>{tableData?.TP.net_premium}</Td>
            <Td isNumeric>{tableData?.CO.net_premium}</Td>
            <Td isNumeric>{tableData?.OD.net_premium}</Td>
            <Td isNumeric>{tableData?.total.net_premium}</Td>
          </Tr>
          <Tr bgColor='appGrey.400'>
            <Td isNumeric>TP Amount</Td>
            <Td isNumeric>{tableData?.TP.tp_premium}</Td>
            <Td isNumeric>{tableData?.CO.tp_premium}</Td>
            <Td isNumeric>{tableData?.OD.tp_premium}</Td>
            <Td isNumeric>{tableData?.total.tp_premium}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>OD Amount</Td>
            <Td isNumeric>{tableData?.TP.od_premium}</Td>
            <Td isNumeric>{tableData?.CO.od_premium}</Td>
            <Td isNumeric>{tableData?.OD.od_premium}</Td>
            <Td isNumeric>{tableData?.total.od_premium}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export { MotorSummaryTable }
