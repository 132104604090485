import { ElementWithLabel, ErrorText } from 'Core'
import { AsyncSelect } from 'chakra-react-select'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface IProps {
  name: string
  form: UseFormReturn<any, any>
  label?: string
  isDisabled?: boolean
  placeholder?: string
  initialValues?: Record<string, string | number | boolean | string[]>
  loadOptions?: (inputSearchValue: string, callback: (options: any[]) => void) => void
  onSearchableChange?: (val: any) => void
}

const RenderSearchable = (props: IProps) => {
  const {
    form,
    name,
    isDisabled,
    label,
    placeholder,
    loadOptions,
    onSearchableChange,
    initialValues,
  } = props
  useEffect(() => {
    if (initialValues && initialValues[name]) {
      form.setValue(name, initialValues[name])
      setInputValue(initialValues[name] as string)
    }
  }, [initialValues])
  const [inputValue, setInputValue] = useState('')

  return (
    <ElementWithLabel required label={label || ''}>
      <AsyncSelect
        isDisabled={isDisabled}
        isClearable
        placeholder={placeholder}
        {...form.register(name, { required: true })}
        loadOptions={loadOptions}
        inputValue={inputValue}
        onInputChange={(val) => setInputValue(val.trim().toUpperCase())}
        onChange={onSearchableChange}
      />
      {form.formState.errors[name] && form.formState.errors[name]?.type === 'required' && (
        <ErrorText text={`${name} is required`} />
      )}
    </ElementWithLabel>
  )
}

export { RenderSearchable }
