import { format, addDays, differenceInMinutes, differenceInDays } from 'date-fns'
import { IHypothecation, IPincode, IPincodeOption } from 'Models/ResponseModels'
import { IHealthPincode, IHealthPincodeOption } from 'Models/ResponseModels/Health'
import { ChangeEvent, KeyboardEvent } from 'react'
import { ToastService } from './toast.service'
import { MD5, AES, lib, mode, enc, pad } from 'crypto-js'

const IndianRupeeFormatFromString = (num: string) => {
  if (num == '' || num === null) {
    return ''
  }
  return Intl.NumberFormat('en-IN').format(parseInt(num))
}

const onlyAllowTypingNumbers = (e: KeyboardEvent<HTMLInputElement>) => {
  if (!(e.key === 'Backspace' || e.key === 'Tab' || e.key === 'ArrowDown')) {
    if (e.key.charCodeAt(0) < 48 || e.key.charCodeAt(0) > 57) {
      e.preventDefault()
    }
  }
}

const onlyAllowTypingNumbers2 = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.key.charCodeAt(0) < 48 || e.key.charCodeAt(0) > 57) {
    e.preventDefault()
  }
}

const customerCancerConsentFormQuestionsMap = {
  q1: 'I/We hereby declare on my behalf and on behalf of all persons proposed to be insured that the above statements, answers and/or particulars given by me/us are true and complete in all aspects to the best of my/our knowledge and that I/we am/are authorized to propose on behalf of these other persons.',
  q2: 'I/We understand that the information provided by me/us will form the basis of insurance policy, is subject to the board approved underwriting policy of the insurance company and that the policy will come into force only after full receipt of the premium chargeable.',
  q3: 'I/We further declare that I/we will notify any change in writing occurring in the occupation or general health of the life to be insured/proposer after the proposal has been submitted but before communication of the risk acceptance by the company.',
  q4: 'I/We declare and further consent to the Company seeking medical information from any doctor or hospital who/which at anytime has attended on the person to insured/proposer or from any past or present employer concerning anything which affects the physical or mental health of the person to be insured/proposer and seeking information from any insurer to whom an application for insurance on the person to be insured/proposer has been made for the purpose of underwriting the proposal and/or claim settlement.',
  q5: 'I/We authorize the Company to share information pertaining to my/our proposal including the medical records of the insured/proposer for the sole purpose of underwriting the proposal and/or claims settlement and with any Government and/or Regulatory Authority.',
}

const customerCancerConsentFormQuestionsDeclarationsMap = {
  q1: 'Would you like to protect the environment and help save paper by authorising the company to send all my policy and service related communication to the Email ID/SMS/What’s App as mentioned here in the application form.',
  q2: 'I hereby consent to and authorize the Company to make welcome calls, service calls or any other communication (electronic or otherwise) with respect to the proposed or existing policy of Company from time to time.',
  q3: 'I want to opt for the ACH/SI renewal option.',
  q4: 'I agree with the Terms & Conditions of Niva Bupa Health Insurance Co. Ltd.',
  q5: 'I hereby agree to enroll in the policy with the understanding, that I am not suffering from any major/ chronic health problem(s), major disease/disorder impacting vital organs (Heart, Brain, Kidneys, Lungs, Liver, Pancreas, Spleen, Intestine etc) or deformity other than minor ailment like Cold, Cough, Fever etc. I hereby also declare that I have never undergone or awaiting any major medical or surgical treatment/ procedure or follow-up. I also understand any non-disclosure in respect to any disease(s), treatment(s) and/or duration of the disease(s) may result in denial of the claim and/or cancellation of my policy. Pre-existing medical condition(s) will be covered after the waiting period, as mentioned in the policy T&C.',
}

const convertNumberToWords = (number: number) => {
  const indianFormatter = new Intl.NumberFormat('en-IN', {
    style: 'decimal',
    notation: 'compact',
    compactDisplay: 'long',
  })
  return indianFormatter.format(number)
}

const getManufacturingYearArr = (
  startYear: number,
  currentYear: number = new Date().getFullYear(),
): number[] => {
  const arr = []
  for (let year = startYear; year <= currentYear; year++) {
    arr.push(year)
  }
  return arr
}

const decryptData = (plainText: string) => {
  const key = 'OYeVQmWr0ejyOzEO'
  const encKey = enc.Utf8.parse(key)
  const decrypted = AES.decrypt(plainText, encKey, { mode: mode.ECB })
  return decrypted.toString(enc.Utf8)
  // return decryptedString.toString()
}

const encryptData = (plainText: string) => {
  const key = 'OYeVQmWr0ejyOzEO'
  const encKey = enc.Utf8.parse(key)
  const encrypted = AES.encrypt(plainText, encKey, { mode: mode.ECB })
  return encrypted.toString()
  // return decryptedString.toString()
}

const getExistingNoClaimBonusArr = (): number[] => {
  return [0, 20, 25, 35, 45, 50]
}

const validateVehicleNumber = (input: string) => {
  input = String(input).replace(/-/g, '').toUpperCase()
  let result = ''
  if (input.length >= 2) {
    const temp = input.slice(0, 2)
    if (!temp.match('[A-Z]$')) {
      return result
    }
    result += temp + '-'
    input = input.slice(2)
    if (input.length) {
      if (!input[0].match('[0-9]$')) {
        return result
      }
      result += input[0]
      input = input.slice(1)

      if (input.length) {
        if (input[0].match('[0-9]$')) {
          result += input[0]
          input = input.slice(1)
        } else if (input[0].match('[A-Z]$') && result.length === 4) {
          result = [result.slice(0, 3), '0', result.slice(3)].join('')
          // input = input.slice(1,)
        }
      }
      result += '-'
      let i = 0
      while (input && i < 3) {
        if (!input[0].match('[A-Z]$')) {
          break
        }
        result += input[0]
        input = input.slice(1)
        i += 1
      }
      if (i) {
        result += '-'
      }
      if (input) {
        const val = input.match(/^([0-9]+)/)
        if (val) {
          result += val[0]?.slice(0, 4)
        }
      }
    }
  } else {
    if (input.match('[A-Z]$')) {
      result += input
    }
  }
  result = result.match('(.+)-$') ? result.slice(0, result.length - 1) : result
  return result
}

const checkVehicleNumber = (number: string) => {
  if (number) {
    const str = number.split('-')
    if (str.length) {
      if (str[str.length - 1].length < 4) {
        return false
      }
    }
    return number.length >= 10
  }
}
const onlyAllowTypingAlphabet = (e: KeyboardEvent<HTMLInputElement>) => {
  if (
    (e.key.charCodeAt(0) > 64 && e.key.charCodeAt(0) < 91) ||
    (e.key.charCodeAt(0) > 96 && e.key.charCodeAt(0) < 123) ||
    e.key == ' '
  ) {
    console.log('')
  } else {
    e.preventDefault()
  }
}

const onlyAllowTypingAlphabetNumeric = (e: KeyboardEvent<HTMLInputElement>) => {
  if (
    (e.key.charCodeAt(0) > 64 && e.key.charCodeAt(0) < 91) ||
    (e.key.charCodeAt(0) > 96 && e.key.charCodeAt(0) < 123) ||
    (e.key.charCodeAt(0) > 47 && e.key.charCodeAt(0) < 58)
  ) {
    console.log('')
  } else {
    e.preventDefault()
  }
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
  ToastService.showSuccessTaost({ title: 'Copied to clipboard!' })
}

const getAgeFromDob = (dob: Date) => {
  const dobInNumber = +dob
  const todaysDate = +new Date()
  const ageInSeconds = todaysDate - dobInNumber
  return ageInSeconds / (24 * 3600 * 365.25 * 1000)
}

const getDOBfromAge = (age: string) => {
  const dob = new Date().getFullYear() - parseInt(age)
  return dob.toString() + '-01-01'
}
const getMemberTypeFromAge = (age: string) => {
  let type = ''
  if (parseInt(age) > 25) {
    type = 'adult'
  } else {
    type = 'child'
  }
  return type
}

const getDateFromDDMMYYYY = (dateInDDMMYYYY: string) => {
  const dateArr = dateInDDMMYYYY.split('/')
  const dateToBePassedInConstructor = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`
  return new Date(dateToBePassedInConstructor)
}

const getRegistrationDateFromManufacturingYear = (year: number | string) => {
  year = year.toString()
  if (year.length !== 4) {
    throw Error('Invalid Year')
  }
  const currentDate = new Date()
  const firstDateOfTwoYearsForwardYear = new Date(`${parseInt(year) + 2}/1/1`)
  console.log(differenceInMinutes(currentDate, firstDateOfTwoYearsForwardYear))
}

const pincodeLabelFormatter = (pincodesArray: IPincode[]) => {
  return pincodesArray.map((pincode) => {
    return {
      ...pincode,
      value: pincode.area,
      label: `${pincode.area_name}(${pincode.pincode})`,
    }
  })
}
const pincodeLabelFormatterHealth = (pincodesArray: IHealthPincode[]) => {
  return pincodesArray.map((pincode) => {
    return {
      ...pincode,
      value: pincode.pincode.toString(),
      label: `${pincode.area_name}(${pincode.pincode})`,
    }
  })
}

const bankHypothecationLabelFormatter = (banksArray: IHypothecation[]) => {
  return banksArray.map((bank) => {
    return {
      ...bank,
      value: bank.id ?? '',
      label: bank.bank_name ?? '',
    }
  })
}

const getArrayFromObj = (obj: any) => {
  if (!obj) return []
  return Object.keys(obj).map((key) => {
    return {
      ...obj[key],
      key: key,
    }
  })
}

const getExistingPolicyExpMinDate = (registrationDate: string, isSaod: boolean) => {
  const registrationDateInDateFormat = getDateFromDDMMYYYY(registrationDate)
  if (registrationDateInDateFormat) {
    if (isSaod === true) {
      if (registrationDateInDateFormat && registrationDateInDateFormat.getFullYear() > 2018) {
        return new Date(`${registrationDateInDateFormat.getFullYear()}/01/01`)
      }
      return registrationDateInDateFormat ?? new Date()
    } else {
      return new Date(`${new Date().getFullYear() - 2}/01/01`)
    }
  }
}

const getTpPolicyEndMinDate = (policyExpDate: string) => {
  console.log('policyExpDate - ', policyExpDate)
  if (policyExpDate) {
    const policyExpDateInDateFormat = getDateFromDDMMYYYY(policyExpDate)
    return addDays(policyExpDateInDateFormat, 365)
  }
}

const getFallbackImageHtml = (title: string) => {
  return `<div style="background-color: gainsboro;font-size: 0.7rem; border: solid 1px; padding: 0.6rem; display: flex; justify-content: center; align-items: center; width: fit-content;"><span>${title}</span></div>`
}

const calculateBMI = (height: number, weight: number) => {
  height = height / 100
  const bmi = weight / (height * height)
  return bmi.toFixed(2)
}

const getAccountTypeBasedOnIndex = (idx: string) => {
  switch (idx) {
    case '1':
      return 'Savings Account'
    case '2':
      return 'Current Account'
    case '3':
      return 'Term Deposit Account'
    case '4':
      return 'Recurring Deposit Account'
    default:
      return ''
  }
}

const convertNullToEmptyString = (value: any) => {
  return value === null ? '' : value
}

const customerConsentFormQuestionsMap = {
  q1: 'I/we confirm that i have received the Quote and have accepted the quote. I understand that the above proposal has been generated basis my acceptance of the quote',
  q2: 'I/we agree to the details mentioned in the above proposal form & that I have selected this product based on my requirement. I understand that Equitas Small Finance Bank Limited merely acts on a non-risk participation basis and the underlying risk shall be underwritten by the respective insurer. The purchase of this insurance products is purely voluntary, and is not linked to availing any other facility from the bank. I confirm that I have read the sales brochure and understood the risk factors. I hereby confirm that the details provided above are true to the best of my knowledge',
  q3: `I/we confirm that I/we have have read the &nbsp; <u> <a
                  href='http://www.equitasbank.com/Insurance_termsandconditions'
                  target='_blank'
                  rel='noreferrer'
                >
                  Terms & Conditions
                </a></u> &nbsp; and agree to the same`,
  q4: 'I/we understand and agree that by selecting the payment method as "My Equitas Account", I provide my consent to debit my account for the purpose of premium amount as per the application. I also understand that by selecting "Other modes of Payment", I\'d be taken to a Payment Gateway to make payment via any other preferred modes',
  q5: 'I/We hereby confirm that I/we have an active relationship with Equitas Small Finance Bank Limited with a valid customer ID. I/we confirm that the details furnished above are true to the best of my knowledge. I/we also understand that any misrepresentation of data or facts in the above request form could lead to termination of the policy or rejection of claim.',
}

const spConsentFormQuestionsMap = {
  q1: 'I confirm that I have discussed about the product with the customer and the customer has shown willingness to purchase the product',
  q2: 'I confirm that the customer is making a decision to purchase the policy based on his/her own decision and it has not been linked for the purchase of any bank related products',
  q3: 'I confirm that the information provided during the process of submitting the Application Form are true to the best of my knowledge',
}

const maxAgeCalculator = (arrayOfAges: number[]) => {
  // arrayOfAges = ['56', '60','34','12']
  let maxAge = arrayOfAges[0]
  for (let i = 0; i < arrayOfAges.length; i++) {
    if (arrayOfAges[i] > maxAge) {
      maxAge = arrayOfAges[i]
    }
  }
  return maxAge
}

function union<T>(array1: T[], array2: T[]) {
  return array1.filter((value) => array2.includes(value))
}

/**
 *
 * @param array1
 * @param key1
 * @param array2
 * @param key2
 * @desc - find intersection of two arrays such that key1's value of some item in array1 is equal to key2's value of some item in array2
 */
const intersectionBasedOnKey = <T1, T2>(
  array1: T1[],
  key1: keyof T1,
  array2: T2[],
  key2: keyof T2,
) => {
  const intersection = []
  for (let i = 0; i < array1.length; i++) {
    const key1Val: unknown = array1[i][key1]
    const secondItemWithVal = array2.find((item) => item[key2] === key1Val)
    if (secondItemWithVal) {
      intersection.push({
        ...array1[i],
        ...secondItemWithVal,
      })
    }
  }
  return intersection
}

const calculateAgeFromDob = (dob: Date) => {
  const dobInNumber = +dob
  const todaysDate = +new Date()
  const ageInSeconds = todaysDate - dobInNumber
  return ageInSeconds / (24 * 3600 * 365.25 * 1000)
}

const getDropdownArrayFromRecord = (data: Record<string, string | number>) => {
  return Object.entries(data).map((item) => {
    return {
      label: item[0],
      value: item[1],
    }
  })
}

const getAgeObjFromDob = (userinput: Date) => {
  const dob = new Date(userinput)
  let monthAge
  let dateAge

  const dobYear = dob.getFullYear()
  const dobMonth = dob.getMonth()
  const dobDate = dob.getDate()

  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()
  const currentDate = now.getDate()

  let yearAge = currentYear - dobYear

  if (currentMonth >= dobMonth) monthAge = currentMonth - dobMonth
  else {
    yearAge--
    monthAge = 12 + currentMonth - dobMonth
  }

  if (currentDate >= dobDate) dateAge = currentDate - dobDate
  else {
    monthAge--
    dateAge = 31 + currentDate - dobDate

    if (monthAge < 0) {
      monthAge = 11
      yearAge--
    }
  }
  const age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  }
  if (age.years) return age.years + ' Years'
  if (age.months) return age.months + ' Months'
  return age.days + ' Days'
}

const getAgeObjFromDob2 = (userinput: Date) => {
  const dob = new Date(userinput)
  let monthAge
  let dateAge

  const dobYear = dob.getFullYear()
  const dobMonth = dob.getMonth()
  const dobDate = dob.getDate()

  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()
  const currentDate = now.getDate()

  let yearAge = currentYear - dobYear

  if (currentMonth >= dobMonth) monthAge = currentMonth - dobMonth
  else {
    yearAge--
    monthAge = 12 + currentMonth - dobMonth
  }

  if (currentDate >= dobDate) dateAge = currentDate - dobDate
  else {
    monthAge--
    dateAge = 31 + currentDate - dobDate

    if (monthAge < 0) {
      monthAge = 11
      yearAge--
    }
  }
  const age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  }
  return age.years
}

const openLinkInSpecifiedTab = (
  link: string,
  target: '_blank' | '_self' | '_parent' | '_top' | 'framename',
) => {
  console.log(link, 'link')
  const aTag: HTMLAnchorElement = document.createElement('a')
  aTag.href = link
  aTag.target = target
  aTag.click()
}

const onlyAllowTypingAlphabetNumericAndSpace = (e: KeyboardEvent<HTMLInputElement>) => {
  if (
    (e.key.charCodeAt(0) > 64 && e.key.charCodeAt(0) < 91) ||
    (e.key.charCodeAt(0) > 96 && e.key.charCodeAt(0) < 123) ||
    (e.key.charCodeAt(0) > 47 && e.key.charCodeAt(0) < 58) ||
    e.key == ' ' ||
    e.key == '-' ||
    e.key == '_'
  ) {
    console.log('')
  } else {
    e.preventDefault()
  }
}

const onlyAllowTypingAlphabetNumericAndAt = (e: KeyboardEvent<HTMLInputElement>) => {
  if (
    (e.key.charCodeAt(0) > 64 && e.key.charCodeAt(0) < 91) ||
    (e.key.charCodeAt(0) > 96 && e.key.charCodeAt(0) < 123) ||
    (e.key.charCodeAt(0) > 47 && e.key.charCodeAt(0) < 58) ||
    e.key == '@' ||
    e.key == '.' ||
    e.key == '_' ||
    e.key == '-'
  ) {
    console.log('')
  } else {
    e.preventDefault()
  }
}

export {
  IndianRupeeFormatFromString,
  calculateAgeFromDob,
  onlyAllowTypingNumbers,
  onlyAllowTypingNumbers2,
  getAgeObjFromDob2,
  onlyAllowTypingAlphabet,
  getManufacturingYearArr,
  getExistingNoClaimBonusArr,
  validateVehicleNumber,
  checkVehicleNumber,
  copyToClipboard,
  getAgeFromDob,
  decryptData,
  getMemberTypeFromAge,
  getDOBfromAge,
  pincodeLabelFormatter,
  getDateFromDDMMYYYY,
  pincodeLabelFormatterHealth,
  getRegistrationDateFromManufacturingYear,
  getArrayFromObj,
  getExistingPolicyExpMinDate,
  getTpPolicyEndMinDate,
  calculateBMI,
  customerConsentFormQuestionsMap,
  getAccountTypeBasedOnIndex,
  spConsentFormQuestionsMap,
  getFallbackImageHtml,
  convertNullToEmptyString,
  onlyAllowTypingAlphabetNumeric,
  bankHypothecationLabelFormatter,
  union,
  intersectionBasedOnKey,
  maxAgeCalculator,
  getAgeObjFromDob,
  getDropdownArrayFromRecord,
  openLinkInSpecifiedTab,
  convertNumberToWords,
  encryptData,
  onlyAllowTypingAlphabetNumericAndSpace,
  onlyAllowTypingAlphabetNumericAndAt,
  customerCancerConsentFormQuestionsMap,
  customerCancerConsentFormQuestionsDeclarationsMap,
}
