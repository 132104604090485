import {
  GridItem,
  Box,
  Text,
  Divider,
  Flex,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Button,
  Skeleton,
  Center,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { Modal } from 'Core/Modal'
import { PremiumBreakUpModal } from 'Core/Modal/PremiumBreakUp'
import { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import uuid from 'react-uuid'
import {
  useLazyGetAddonsQuery,
  useGetInsuranceListingQuery,
  useGetPollingQuotationsQuery,
  useGetSubRequestMutation,
} from 'Services/API/health.api'
import { Select } from 'chakra-react-select'
import { IndianRupeeFormatFromString, decryptData, maxAgeCalculator } from 'Services/general'
import { getAdultsCount, getMembers } from 'Pages/Health/QuotationPage/helper'
import { cartItemData, updateSelectedQuotes } from 'features/Health/cart.health.slice'
import { ToastService } from 'Services/toast.service'
import { QuotationGetResponse } from 'Models/ResponseModels/Health'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ISaveLeadResponse } from 'Models/ResponseModels'

export const AddonComponent = ({ quoteData }: { quoteData: cartItemData }) => {
  const dispatch = useAppDispatch()
  const { isDesktop } = useDeviceType()
  const { cartDetails, requestId, selectedPlanType, selectedQuotes } = useAppSelector(
    (state) => state.cart,
  )
  const { isProposerIncluded, sum_insured, memberDetails, proposer } = useAppSelector(
    (state) => state.memberDetails,
  )

  // -----------states----------------------
  const [count, setCount] = useState(4)
  const [subRequestId, setSubRequestId] = useState('')
  const [retryable, setRetryable] = useState(true)
  const [skip, setSkip] = useState(true)
  const [selectedDuration, setSelectedDuration] = useState(quoteData.duration)
  const [viewPremiumBreakupModal, setViewPremiumBreakupModal] = useState(false)
  const [updateAddons, setUpdateAddons] = useState('')
  const [resetLoading, setResetLoading] = useState(false)
  const [applyLoading, setApplyLoading] = useState(false)
  const [durationArray, setDurationArray] = useState<number[]>([])

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const completeMemberDetails = useAppSelector((state) => state.memberDetails)
  const cart_details = useAppSelector((state) => state.cart)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: completeMemberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('addons', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  const [addonsData, setAddonsData] = useState<
    {
      selected: boolean
      disabled: boolean
      code: string
      name: string
      si_value: string
      value: string
      si_type: string
      type: 'Add-On' | 'Rider'
      is_mandatory: string
      amount: string
    }[]
  >([])

  const [quotesData, setquotesData] = useState<
    {
      basePremium?: string
      uid: string
      insurerName: string
      premium: number
      sumInsured: number
      addedInCart: boolean
      code: string
      highlights: string[]
      benefits: string[]
      duration: string
      addonsTotal?: string
      discountTotal?: string
      loadingTotal?: string
      netPremium?: string
      GST?: string
      addons: {
        name: string
        code: string
        amount: string
        si_type: string
        si_value: string
        value: string
      }[]
    }[]
  >([])

  // ------------------------------ API Calls ------------------------------------------------------------
  const [requestQuotes] = useGetSubRequestMutation()

  const { data: listingInsuranceListingData } = useGetInsuranceListingQuery({
    insurers: '',
  })
  const {
    data: getQuotes,
    isLoading: quotesLoading,
    isFetching: quotesFetching,
    refetch,
  } = useGetPollingQuotationsQuery(
    { sub_request_id: subRequestId },
    { pollingInterval: 2000, skip },
  )

  const selectedMembersAgeArray = Object.values(memberDetails)
    .filter((member) => quoteData?.addedMemberTypes?.includes(member.member_type))
    .map((member) => parseInt(member.age))
  selectedMembersAgeArray.push(parseInt(proposer.age))

  const [uid, setUid] = useState(Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '')
  const [getAddons, { data: addOnsList }] = useLazyGetAddonsQuery({})

  const [saveLeads, { isLoading: saveLeadsLoading, isError: saveLeadsError }] =
    useSaveLeadsMutation()

  // ---------------------- useEffects ---------------------------------

  useEffect(() => {
    const durationArray: number[] = []
    if (listingInsuranceListingData) {
      listingInsuranceListingData.forEach((insurance) => {
        if (insurance.insurance_id === quoteData.insuranceNumber) {
          durationArray.push(...insurance.durations.split(',').map((item) => parseInt(item)))
        }
      })
    }
    setDurationArray([...durationArray])
  }, [listingInsuranceListingData])

  useEffect(() => {
    getAddons({
      insurance_id: quoteData.insuranceNumber,
      plan_type: selectedPlanType,
      sum_insured: quoteData.sumInsured,
      age: maxAgeCalculator(selectedMembersAgeArray),
      duration: selectedDuration,
      unique_identifier: uid ?? '',
    })
      .unwrap()
      .then((response) => {
        const tempList = response.map((addon) => {
          let isAddonSelected = false
          let addonSIValue = ''

          const addedAddon = quoteData.addons.filter((stateAddon) => stateAddon.code === addon.code)

          if (addedAddon.length) {
            isAddonSelected = true
            addonSIValue = addedAddon[0].value
          }

          return {
            ...addon,
            amount: '',
            selected: addon.is_mandatory === 'Mandatory' || isAddonSelected ? true : false,
            disabled: addon.is_mandatory === 'Mandatory' ? true : false,
            value: addonSIValue,
            // si_value: addonSIValue,
          }
        })
        setUpdateAddons(uuid())
        setAddonsData([...tempList])
      })
      .catch((e) => {
        if (e.status === 404) {
          return
        } else {
          ToastService.showErrorToast({ title: e.data.error_text })
        }
      })
  }, [])

  const callAddonsAPI = () => {
    getAddons({
      insurance_id: quoteData.insuranceNumber,
      plan_type: selectedPlanType,
      sum_insured: quoteData.sumInsured,
      age: maxAgeCalculator(selectedMembersAgeArray),
      duration: selectedDuration,
      unique_identifier: uid ?? '',
    })
      .unwrap()
      .then((response) => {
        const tempList = response.map((addon) => {
          return {
            ...addon,
            amount: '',
            selected: addon.is_mandatory === 'Mandatory' ? true : false,
            disabled: addon.is_mandatory === 'Mandatory' ? true : false,
            value: '',
            // si_value: addonSIValue,
          }
        })
        setUpdateAddons(uuid())
        setAddonsData([...tempList])
      })
      .catch((e) => {
        ToastService.showErrorToast({ title: e.data.error_text })
      })
  }

  useEffect(() => {
    setquotesData([])
    dispatch(
      updateSelectedQuotes({
        key: quoteData.quoteNumber,
        data: {
          ...quoteData,
          loading: true,
        },
      }),
    )

    requestQuotes({
      ucic: equitasDetails.ucic?.toString() ?? '',
      request_id: requestId,
      include_proposer: quoteData.memberType === 'parents' ? 0 : isProposerIncluded ? 1 : 0,
      adults: getAdultsCount(memberDetails, isProposerIncluded, quoteData.memberType!),
      kids: quoteData.memberType === 'parents' ? 0 : getkidsCount(),
      duration_years: selectedDuration,
      sum_insured: sum_insured,
      plan_type: selectedPlanType,
      insurance_name: quoteData.insuranceName,
      add_ons: addonsData
        .filter((addon) => addon.selected)
        .map((addon) => {
          return {
            name: addon.name,
            code: addon.code,
            sum_insured: addon.value,
          }
        }),
      cover_discount: [],
      members: getMembers(quoteData.memberType, memberDetails, proposer).map((data) => {
        return {
          ...data,
          marital_status: 'Married',
        }
      }),
    })
      .unwrap()
      .then((response) => {
        setCount(response.count)
        setSubRequestId(response.sub_request_id)
        setRetryable(response.retryable)
        if (retryable) {
          setSkip(false)
        }
      })
      .catch((err) => {
        console.log(err, 'error in catch')
        ToastService.showErrorToast({
          title: 'Something Went Wrong!',
        })
      })
  }, [selectedDuration, updateAddons])

  useEffect(() => {
    if (getQuotes?.result.length) {
      const tempData: any = []

      setAddonsData
      getQuotes.result.forEach((item) => {
        if (item.status === 200) {
          tempData.push({
            uid: item.data.unique_identifier,
            insurerName: item.data.insurance_name,
            insuranceName: item.data.insurance_name,
            premium: item.data.premium_total,
            sumInsured: item.data.sum_insured,
            addedInCart: false,
            code: uuid(),
            highlights: [],
            benefits: [],
            insurerLogo: item.data.insurance_logo,
            insuranceNumber: item.data.insurance_id,
            duration: item.data.duration,
            addonsTotal: item.data.premium.add_on_total,
            discountTotal: item.data.discount_total,
            loadingTotal: item.data.loading_total,
            netPremium: item.data.premium_total,
            GST: item.data.premium.gst,
            basePremium: item.data.premium.base_premium,
            addons: item.data.add_ons ? item.data.add_ons : [],
          })
        } else if (item.data!.error_text == 'CHILD_25') {
          if (selectedDuration === 3) {
            ToastService.showErrorToast({
              title: 'A three-year policy is not available for children who are over the age of 24',
            })
          }
        } else {
          ToastService.showErrorToast({
            title: 'Something went wrong!',
          })
        }
      })
      setquotesData((prevState) => {
        prevState.push(...tempData!)
        return [...prevState]
      })
    }
    if (getQuotes?.retryable === false) {
      setSkip(true)
    }
  }, [getQuotes])

  useEffect(() => {
    const selectedQuote = quotesData.filter(
      (quote) => quote.duration.toString() === selectedDuration.toString(),
    )
    if (selectedQuote[0]) {
      setApplyLoading(false)
      setResetLoading(false)

      dispatch(
        updateSelectedQuotes({
          key: quoteData.quoteNumber,
          data: {
            ...quoteData,
            uniqueIdentifier: selectedQuote[0].uid,
            premium: selectedQuote[0].premium,
            addons: selectedQuote[0].addons.map((addon, index) => {
              return {
                ...addon,
                value: addonsData.filter((item) => item.selected)[index].value,
              }
            }),
            addonsTotal: selectedQuote[0].addonsTotal,
            discountTotal: selectedQuote[0].discountTotal,
            loadingTotal: selectedQuote[0].loadingTotal,
            netPremium: selectedQuote[0].netPremium,
            GST: selectedQuote[0].GST,
            loading: false,
            basePremium: selectedQuote[0].basePremium,
          },
        }),
      )
    }
  }, [quotesData])

  // ------------------------------------------------------------ Functions ---------------------------------------------
  // const getAdultsCount = () => {
  //   let count = 1
  //   // Object.keys(memberDetails).forEach((key) => {
  //   //   if (memberDetails[key].type === 'adult') {
  //   //     count = count + 1
  //   //   }
  //   // })
  //   Object.keys(memberDetails).forEach((key) => {
  //     if (getMemberTypesArray(type).includes(memberDetails[key].member_type)) {
  //       if (memberDetails[key].type === 'adult') {
  //         count = count + 1
  //       }
  //     }
  //   })
  //   // if (isProposerIncluded) {
  //   //   count = count + 1
  //   // }
  //   return count
  // }

  const getkidsCount = () => {
    let count = 0
    Object.keys(memberDetails).forEach((key) => {
      if (memberDetails[key].type === 'child') {
        count = count + 1
      }
    })
    return count
  }

  const CheckBoxComponent = ({
    price,
    code,
    label,
    selectedAddon,
    mandatoryAddon,
    fieldValue,
    fieldType,
    disabled,
    value,
    onCheckHandler,
    onValueChangeHandler,
  }: {
    price?: string
    label: string
    code: string
    selectedAddon: boolean
    mandatoryAddon: boolean
    fieldValue: string
    value: string
    fieldType: string
    disabled: boolean
    onCheckHandler: (code: string) => void
    onValueChangeHandler: (code: string, value: string) => void
  }) => {
    return (
      <>
        <Flex
          direction={isDesktop ? 'row' : 'column'}
          gap={2}
          justifyContent='space-between'
          paddingY={isDesktop ? '6' : '4'}
          paddingX={isDesktop ? '6' : '0'}
        >
          <Checkbox
            onChange={() => onCheckHandler(code)}
            disabled={disabled}
            colorScheme='primary'
            isChecked={selectedAddon}
          >
            {label}
          </Checkbox>
          {price && <Text marginY='auto'>Rs. {IndianRupeeFormatFromString(price)}</Text>}
          {fieldType === 'dropdown' && fieldValue && (
            <Box w={isDesktop ? '300px' : '250px'}>
              <Select
                placeholder=''
                // size='sm'
                // useBasicStyles
                maxMenuHeight={150}
                onChange={(item) => {
                  onValueChangeHandler(code, item!.value!)
                }}
                // defaultValue={{
                //   label: value,
                //   value: value,
                // }}
                value={{
                  label: value,
                  value: value,
                }}
                isSearchable={false}
                options={fieldValue.split(',')?.map((key: any) => {
                  return {
                    label: key,
                    value: key,
                  }
                })}
              />
            </Box>
          )}
        </Flex>
        <Divider borderColor='#D8D8D8' />
      </>
    )
  }

  return (
    <>
      <GridItem w='100%'>
        <Box
          id='add-on'
          fontWeight='normal'
          // border={isDesktop ? '1px' : ''}
          borderRight={isDesktop ? '1px' : ''}
        >
          <Tabs
            size='md'
            variant='enclosed-colored'
            isFitted
            defaultIndex={quoteData.duration - 1}
            onChange={(index) => {
              // addonsData.map((addon) => {
              //   if (addon.is_mandatory === 'Non-Mandatory') {
              //     addon.selected = false
              //   }
              //   addon.value = ''
              // })
              // setAddonsData([...addonsData])
              callAddonsAPI()
              setSelectedDuration(index + 1)
              dispatch(
                updateSelectedQuotes({
                  key: quoteData.quoteNumber,
                  data: {
                    ...quoteData,
                    duration: index + 1,
                  },
                }),
              )
            }}
          >
            <TabList>
              {durationArray.map((duration) => {
                return <Tab key={duration}>{duration} Year</Tab>
              })}
            </TabList>
            <TabPanels>
              {durationArray.map((duration) => {
                return (
                  <TabPanel key={duration}>
                    {addonsData.length === 0 && (
                      <Center>
                        <Text>No addons available for this plan</Text>
                      </Center>
                    )}
                    {addonsData.length > 0 && (
                      <Box maxH='400px' pb='24' overflowY='scroll'>
                        {addonsData &&
                          addonsData?.map((addons, index) => {
                            return (
                              <CheckBoxComponent
                                label={addons.name}
                                key={addons.code}
                                mandatoryAddon={addons.disabled}
                                selectedAddon={addons.selected}
                                fieldValue={addons.si_value}
                                fieldType={addons.si_type}
                                disabled={addons.disabled}
                                code={addons.code}
                                value={addons.value}
                                onCheckHandler={(code: string) => {
                                  addonsData.map((addon) => {
                                    if (addon.code === code) {
                                      addon.selected = !addon.selected
                                    }
                                  })
                                  setAddonsData([...addonsData])
                                }}
                                onValueChangeHandler={(code, value) => {
                                  const updatedAddons: {
                                    selected: boolean
                                    disabled: boolean
                                    code: string
                                    name: string
                                    si_value: string
                                    value: string
                                    si_type: string
                                    type: 'Add-On' | 'Rider'
                                    is_mandatory: string
                                    amount: string
                                  }[] = JSON.parse(JSON.stringify(addonsData))
                                  updatedAddons.forEach((addon) => {
                                    if (addon.code === code) {
                                      addon.value = value
                                    }
                                  })
                                  setAddonsData([...updatedAddons])
                                }}
                              />
                            )
                          })}
                      </Box>
                    )}
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs>

          <Box my='4'>
            {addonsData.length > 0 && (
              <Flex w='80%' justifyContent='center' marginY='4' marginX='auto'>
                <Button
                  paddingY='3'
                  paddingX='10'
                  cursor='pointer'
                  roundedLeft='3xl'
                  roundedRight='none'
                  colorScheme={'primary'}
                  variant='outline'
                  isLoading={resetLoading}
                  onClick={() => {
                    setResetLoading(true)
                    addonsData.map((addon) => {
                      if (addon.is_mandatory === 'Non-Mandatory') {
                        addon.selected = false
                      }
                      addon.value = ''
                    })
                    setAddonsData([...addonsData])
                    setUpdateAddons(uuid())
                  }}
                >
                  Reset
                </Button>
                <Button
                  paddingY='3'
                  paddingX='10'
                  isLoading={applyLoading}
                  cursor='pointer'
                  roundedLeft='none'
                  roundedRight='3xl'
                  onClick={() => {
                    const selectedAddons = addonsData.filter((addon) => addon.selected)
                    if (selectedAddons.length === 0) {
                      ToastService.showErrorToast({
                        title: 'Please select atleast 1 addon',
                      })
                      return
                    }

                    const selectedAddonsWithDropdownWithoutValue = selectedAddons
                      .filter((item) => item.si_type === 'dropdown')
                      .filter((item) => item.value === '')

                    if (selectedAddonsWithDropdownWithoutValue.length > 0) {
                      ToastService.showErrorToast({
                        title: 'Please select value from dropdown',
                      })
                      return
                    }

                    setUpdateAddons(uuid())
                    setApplyLoading(true)
                    createLead()
                  }}
                  colorScheme={'primary'}
                >
                  Apply
                </Button>
              </Flex>
            )}
          </Box>
          <Box>
            <Flex
              gap='1'
              color='primary.500'
              alignItems={'center'}
              justifyContent={'center'}
              mb='4'
              onClick={() => setViewPremiumBreakupModal(true)}
            >
              {!quoteData.loading ? (
                <>
                  <Text textDecoration={'underline'}>View Premium Breakup</Text>
                  <MdKeyboardArrowDown size={'22'} />
                </>
              ) : (
                <Box>
                  <Skeleton height='20px' w='250px' mx='auto' />
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
      </GridItem>
      <Modal
        isOpen={viewPremiumBreakupModal}
        onClose={() => setViewPremiumBreakupModal(false)}
        headerText='Premium Breakup'
      >
        <Flex direction={'column'}>
          <PremiumBreakUpModal />
          {/* <Flex justifyContent={'center'} my='2'>
            <Button
              variant={'solid'}
              colorScheme={'primary'}
              w='30%'
              rounded={'full'}
              onClick={() => setViewPremiumBreakupModal(false)}
              disabled={quoteData.loading}
            >
              Next
            </Button>
          </Flex> */}
        </Flex>
      </Modal>
    </>
  )
}

// const getMemberTypeFromMemberList = () => {
//   let parentsIncluded = false
//   let selfSpouseKidsIncluded = false

//   if (
//     quoteData.addedMemberTypes?.includes('mother') ||
//     quoteData.addedMemberTypes?.includes('father')
//   ) {
//     parentsIncluded = true
//     // return 'parents'
//   }
//   if (
//     isProposerIncluded ||
//     quoteData.addedMemberTypes?.includes('spouse') ||
//     quoteData.addedMemberTypes?.includes('son') ||
//     quoteData.addedMemberTypes?.includes('daughter')
//   ) {
//     selfSpouseKidsIncluded = true

//     // return 'selfSpouseKids'
//   }
//   if (parentsIncluded && !selfSpouseKidsIncluded) {
//     return 'parents'
//   }
//   if (!parentsIncluded && selfSpouseKidsIncluded) {
//     return 'selfSpouseKids'
//   }
//   if (parentsIncluded && selfSpouseKidsIncluded) {
//     return 'allMembers'
//   } else return 'allMembers'
// }
