import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdditionalCover, ITransformedAddons, Quotations } from 'Models/ResponseModels'

export interface IQuotationPageState {
  isAscending: boolean
  isComprehensive: boolean
  isIndividual: boolean
  pucCertificate: boolean
  inspectionRequired: boolean
  fuelType: string
  idv: number
  body_idv?: string
  chassis_idv?: string
  selectedQuote?: Quotations
  isBreakIn: boolean
  paOwnerReason?: string
  isPodSelected: boolean

  addOns: ITransformedAddons[]
  ownDamageCovers: IAdditionalCover[]
  liabilityCovers: IAdditionalCover[]
  discountCovers: IAdditionalCover[]
}

const initialState: IQuotationPageState = {
  isAscending: true,
  isComprehensive: true,
  isIndividual: true,
  pucCertificate: true,
  inspectionRequired: false,
  fuelType: '',
  idv: 0,
  isBreakIn: false,
  isPodSelected: false,
  addOns: [],
  ownDamageCovers: [],
  liabilityCovers: [],
  discountCovers: [],
}

const quotationPageDetails = createSlice({
  name: 'quotationDetails',
  initialState: initialState,
  reducers: {
    updateAscending: (
      state,
      action: PayloadAction<{
        isAscending: boolean
      }>,
    ) => {
      const { isAscending } = action.payload
      state.isAscending = isAscending
    },
    updatePolicyType: (
      state,
      action: PayloadAction<{
        isComprehensive: boolean
      }>,
    ) => {
      const { isComprehensive } = action.payload
      state.isComprehensive = isComprehensive
    },
    updateOwnedBy: (
      state,
      action: PayloadAction<{
        isIndividual: boolean
      }>,
    ) => {
      const { isIndividual } = action.payload
      state.isIndividual = isIndividual
    },
    updateIsPodSelected: (state, action: PayloadAction<boolean>) => {
      state.isPodSelected = action.payload
    },
    updateCertificateStatus: (
      state,
      action: PayloadAction<{
        pucCertificate: boolean
      }>,
    ) => {
      const { pucCertificate } = action.payload
      state.pucCertificate = pucCertificate
    },
    updateFuelType: (
      state,
      action: PayloadAction<{
        fuelType: string
      }>,
    ) => {
      const { fuelType } = action.payload
      state.fuelType = fuelType
    },
    updateIDV: (
      state,
      action: PayloadAction<{
        idv: number
      }>,
    ) => {
      const { idv } = action.payload
      state.idv = idv
    },
    updateBodyIDV: (
      state,
      action: PayloadAction<{
        body_idv: string
      }>,
    ) => {
      const { body_idv } = action.payload
      state.body_idv = body_idv
    },
    updateChassisIDV: (
      state,
      action: PayloadAction<{
        chassis_idv: string
      }>,
    ) => {
      const { chassis_idv } = action.payload
      state.chassis_idv = chassis_idv
    },
    updatePaOwnerReason: (
      state,
      action: PayloadAction<{
        paOwnerReason: string
      }>,
    ) => {
      const { paOwnerReason } = action.payload
      state.paOwnerReason = paOwnerReason
    },
    updateInspectionRequired: (
      state,
      action: PayloadAction<{
        inspectionRequired: boolean
      }>,
    ) => {
      const { inspectionRequired } = action.payload
      state.inspectionRequired = inspectionRequired
    },
    updateSelectedQuote: (
      state,
      action: PayloadAction<{
        selectedQuote: Quotations
      }>,
    ) => {
      const { selectedQuote } = action.payload
      state.selectedQuote = selectedQuote
    },

    updateAddons: (state, action: PayloadAction<ITransformedAddons[]>) => {
      state.addOns = action.payload
    },
    updateOwnDamageCovers: (state, action: PayloadAction<IAdditionalCover[]>) => {
      state.ownDamageCovers = action.payload
    },
    updateLiabilityCovers: (state, action: PayloadAction<IAdditionalCover[]>) => {
      state.liabilityCovers = action.payload
    },
    updateOwnDiscountCovers: (state, action: PayloadAction<IAdditionalCover[]>) => {
      state.discountCovers = action.payload
    },

    updateAllQuotationDetails: (state, action: PayloadAction<IQuotationPageState>) => {
      const {
        fuelType,
        inspectionRequired,
        isComprehensive,
        selectedQuote,
        paOwnerReason,
        isBreakIn,
        isIndividual,
        addOns,
        discountCovers,
        liabilityCovers,
        ownDamageCovers,
      } = action.payload
      if (fuelType) {
        state.fuelType = fuelType
      }
      if (inspectionRequired) {
        state.inspectionRequired = inspectionRequired
      }
      if (isComprehensive) {
        state.isComprehensive = isComprehensive
      }
      if (selectedQuote) {
        state.selectedQuote = selectedQuote
      }
      if (paOwnerReason) {
        state.paOwnerReason = paOwnerReason
      }
      if (isBreakIn) {
        state.isBreakIn = isBreakIn
      }
      if (isIndividual) {
        state.isIndividual = isIndividual
      }
      if (ownDamageCovers) {
        state.ownDamageCovers = ownDamageCovers
      }
      if (liabilityCovers) {
        state.liabilityCovers = liabilityCovers
      }
      if (addOns) {
        state.addOns = addOns
      }
      if (discountCovers) {
        state.discountCovers = discountCovers
      }
    },
    resetQuotation: () => initialState,
  },
})

const { reducer } = quotationPageDetails

const {
  updateAscending,
  updatePolicyType,
  updateOwnedBy,
  updateCertificateStatus,
  updateFuelType,
  updateIDV,
  updatePaOwnerReason,
  updateSelectedQuote,
  updateInspectionRequired,
  updateBodyIDV,
  updateChassisIDV,
  updateAllQuotationDetails,
  updateIsPodSelected,
  updateAddons,
  updateLiabilityCovers,
  updateOwnDamageCovers,
  updateOwnDiscountCovers,
  resetQuotation,
} = quotationPageDetails.actions

export {
  updateAscending,
  updateOwnedBy,
  updatePolicyType,
  updateCertificateStatus,
  updateSelectedQuote,
  updateFuelType,
  updateIDV,
  updateInspectionRequired,
  updateBodyIDV,
  updateChassisIDV,
  updatePaOwnerReason,
  updateIsPodSelected,
  updateAddons,
  updateLiabilityCovers,
  updateOwnDamageCovers,
  updateOwnDiscountCovers,
  updateAllQuotationDetails,
  resetQuotation,
  reducer as quotationsReducer,
}
