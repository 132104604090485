import {
  Box,
  Button,
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  Center,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { IndianRupeeFormatFromString, onlyAllowTypingNumbers2 } from 'Services/general'
import { useCompareListingMutation, useLazyGetCompareFieldsQuery } from 'Services/API/health.api'
import { ToastService } from 'Services/toast.service'
import { useEffect, useRef, useState } from 'react'
import { useAppSelector, useDeviceType } from 'app/hooks'
import { BsShareFill } from 'react-icons/bs'
import html2canvas from 'html2canvas'
import { useSendEmailMutation, useSendSmsMutation } from 'Services/API/equitas.api'

import { ErrorText, Modal, TextInput } from 'Core'
import uuid from 'react-uuid'
import { emailRegex, mobileNumRegex } from 'Constants'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TCustomerDetails } from 'Models/RequestModels'

interface IInfoCard extends React.ComponentPropsWithRef<'div'> {
  insuranceId: string
  insuranceName: string
  sumInsured: string
  premium: string
  insuranceLogo: string
  plan_type: string
}

export const CompareModal = ({
  compareQuotesArray,
}: {
  compareQuotesArray: {
    insuranceId: string
    insuranceName: string
    sumInsured: string
    insuranceLogo: string
    premium: string
  }[]
}) => {
  const [getCompareFields, { data }] = useLazyGetCompareFieldsQuery()
  const { selectedPlanType } = useAppSelector((state) => state.cart)
  const [shareToEmailModal, setShareToEmailModal] = useState(false)
  const [email, setEmail] = useState('')
  const [sendEmail, { isLoading }] = useSendEmailMutation()
  const [sendSms, { isLoading: isSmsLoading }] = useSendSmsMutation()
  const htmlRef: any = useRef<HTMLTableElement>(null)

  const [compareListing, { data: compareListingData }] = useCompareListingMutation()
  console.log(compareListingData, 'comparelisting')
  const { isDesktop, isMobile } = useDeviceType()
  const customerDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)

  const { register, handleSubmit, formState, watch } = useForm<TCustomerDetails>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  useEffect(() => {
    getCompareFields()
      .unwrap()
      .then((res) => {
        console.log(res, 'res')
        compareListing({
          insurance_details: [
            ...compareQuotesArray.map((compareQuotesData) => {
              return {
                insurance_id: parseInt(compareQuotesData.insuranceId),
                insurance_name: compareQuotesData.insuranceName,
                sum_insured: parseInt(compareQuotesData.sumInsured),
                plan_type: selectedPlanType,
              }
            }),
          ],
          policy_fields_data: res,
        })
          .unwrap()
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
            ToastService.showErrorToast({
              title: 'Something Went Wrong',
              message: error.data.error_text,
            })
          })
      })
      .catch((error) => {
        console.log(error)
        ToastService.showErrorToast({
          title: 'Something Went Wrong!',
          message: error.data.error_text,
        })
      })
  }, [])

  const onSubmit: SubmitHandler<TCustomerDetails> = async (data) => {
    console.log(data)
    if (data['email']) {
      const canvas = await html2canvas(htmlRef.current)
      try {
        const response = await sendEmail({
          unique_identifier: uuid(),
          ucic_id: customerDetails.ucic,
          content_data: {
            lead_uid: leadDetails.lead_uid,
            insurance_type: 'Health',
            plan_name: selectedPlanType,
            customer_name:
              (customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
                customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0]
                  .frsNm) ??
              '',
            current_date: new Date().toLocaleDateString(),
            salutation:
              customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr === 'M'
                ? 'Mr.'
                : customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.mrtlSts === '1'
                ? 'Mrs.'
                : 'Ms.',
          },
          message_type: 'content_message',
          reciever_mail: data['email'],
          message_body: 'Please find the comparison of quotes',
          subject_line: 'Comparison',
          file_attachment: canvas.toDataURL(),
        }).unwrap()
        ToastService.showSuccessTaost({
          title: 'Success',
          message: 'Email sent successfully',
        })
      } catch (e) {
        console.log(e)
        ToastService.showErrorToast({
          title: 'Failure',
          message: 'Could not send email',
        })
      }
      setShareToEmailModal(false)
    }
    if (data['phone']) {
      const canvas = await html2canvas(htmlRef.current)
      try {
        const response = await sendSms({
          unique_identifier: uuid(),
          ucic_id: customerDetails.ucic,
          content_data: {
            lead_uid: leadDetails.lead_uid,
            insurance_type: 'Health',
            plan_name: selectedPlanType,
            customer_name:
              (customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
                customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0]
                  .frsNm) ??
              '',
            current_date: new Date().toLocaleDateString(),
            salutation:
              customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr === 'M'
                ? 'Mr.'
                : customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.mrtlSts === '1'
                ? 'Mrs.'
                : 'Ms.',
          },
          message_type: 'content_message',
          phone_number: '91' + data['phone'],
          message_body: 'Please find the comparison of quotes',
          subject_line: 'Comparison',
          file_attachment: canvas.toDataURL(),
        }).unwrap()
        ToastService.showSuccessTaost({
          title: 'Success',
          message: 'Sms sent successfully',
        })
      } catch (e) {
        console.log(e)
        ToastService.showErrorToast({
          title: 'Failure',
          message: 'Could not send sms',
        })
      }
      setShareToEmailModal(false)
    }
  }

  const InfoCard = (props: IInfoCard) => {
    return (
      <>
        <Flex h='full' direction={'column'} display={'flex'} justifyContent='flex-start'>
          <Grid templateColumns='repeat(5, 1fr)' gap={1} mt='3'>
            <GridItem colSpan={isDesktop ? 2 : 5} w='100%' p='2'>
              <Image
                src={props.insuranceLogo}
                className='w-[80px] h-[40px] bg-white mx-auto'
                data-html2canvas-ignore
              />
            </GridItem>
            <GridItem colSpan={isDesktop ? 3 : 5} w='100%'>
              <Text mt='2' fontWeight='bold'>
                {props.insuranceName}
              </Text>
              <Text fontWeight='medium' className='text-[10px]'>
                Sum Insured : ₹{IndianRupeeFormatFromString(`${props.sumInsured}`)}
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Divider borderColor={'gray.300'} />
            </GridItem>
            <GridItem colSpan={5}>
              <Box margin='2'>
                <Flex gap='9' direction={isDesktop ? 'row' : 'column'}>
                  <Box textTransform='capitalize'>
                    <Text fontWeight='bold' fontSize='lg'>
                      Final Premium
                    </Text>
                    <Flex marginBottom='6' direction={isDesktop ? 'row' : 'column'}>
                      <Text fontWeight='bold' fontSize='lg'>
                        ₹{IndianRupeeFormatFromString(`${props.premium}`)}
                      </Text>
                      <Text fontSize='10px' marginLeft='2'>
                        Inc. GST
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </GridItem>
          </Grid>
        </Flex>

        {/* <Box>
          <Flex direction='column' gap='2' fontWeight='medium'>
            <Box>
              <Flex direction={isDesktop ? 'row' : 'column'} gap='2'>
                <Image
                  src={props.insuranceLogo}
                  className='w-[80px] h-[40px] my-2 bg-white'
                  data-html2canvas-ignore
                />
                <Flex direction='column' textTransform='capitalize' my='2'>
                  <Text fontWeight='bold'>{props.insuranceName}</Text>
                  <Text>Sum Insured : ₹{IndianRupeeFormatFromString(`${props.sumInsured}`)}</Text>
                </Flex>
              </Flex>
            </Box>
            <Divider borderColor={'gray.300'} />
            <Box marginTop='2'>
              <Flex gap='9' direction={isDesktop ? 'row' : 'column'}>
                <Box textTransform='capitalize'>
                  <Text fontWeight='bold' fontSize='lg'>
                    Final Premium
                  </Text>
                  <Flex marginBottom='6' direction={isDesktop ? 'row' : 'column'}>
                    <Text fontWeight='bold' fontSize='lg'>
                      ₹{IndianRupeeFormatFromString(`${props.premium}`)}
                    </Text>
                    <Text fontSize='10px' marginLeft='2'>
                      Inc. GST
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box> */}
      </>
    )
  }
  return (
    <>
      <Box className='max-h-[60vh] overflow-scroll h-[90%] relative' paddingX='8'>
        <Table size='sm' variant='striped' colorScheme='gray' ref={htmlRef} mb='6'>
          <Thead>
            <Tr>
              <Th backgroundColor='white'>
                <Button
                  variant='solid'
                  rounded='full'
                  colorScheme={'primary'}
                  onClick={() => {
                    console.log('true')
                    setShareToEmailModal(true)
                  }}
                  size={isDesktop ? 'md' : 'sm'}
                  data-html2canvas-ignore
                >
                  <Flex gap='1'>
                    <Text>Share</Text>
                    <Box my='auto' mt='0.5'>
                      <BsShareFill />
                    </Box>
                  </Flex>
                </Button>
                <Text fontWeight='bold' fontSize={isDesktop ? '15px' : '10px'} marginTop='6'>
                  Policy Features
                </Text>
                {/* </Flex> */}
              </Th>

              {compareQuotesArray.map((compareHeader) => {
                return (
                  <Th
                    backgroundColor='#F4F4F4'
                    borderRight='1px'
                    borderColor='#E0E0E0'
                    key={compareHeader.insuranceId}
                    style={{ overflow: 'wrap' }}
                    w={'300px'}
                    minW='250px'
                  >
                    <InfoCard
                      id={compareHeader.insuranceId}
                      insuranceName={compareHeader.insuranceName}
                      sumInsured={compareHeader.sumInsured}
                      premium={compareHeader.premium}
                      insuranceId={compareHeader.insuranceId}
                      insuranceLogo={compareHeader.insuranceLogo}
                      plan_type={selectedPlanType}
                    />
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Box></Box>
          <Tbody color='#6F6F6F'>
            {data?.map((feature, index) => {
              return (
                <Tr key={index}>
                  <Td color='#333333' fontWeight={'medium'}>
                    {feature.compare_field}
                  </Td>
                  {compareQuotesArray.map((item, i) => {
                    const selectedQuote = compareListingData?.filter(
                      (quote) => quote.insurance_id.toString() === item.insuranceId.toString(),
                    )
                    if (selectedQuote && selectedQuote[0]) {
                      return (
                        <Td key={selectedQuote[0].insurance_id}>
                          {selectedQuote[0].data[index]?.compare_value
                            ? selectedQuote[0].data[index]?.compare_value
                            : '-'}
                        </Td>
                      )
                    } else
                      return (
                        <Td key={i}>
                          {' '}
                          <Text textAlign={'center'}> -</Text>
                        </Td>
                      )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
      <Modal
        isOpen={shareToEmailModal}
        headerText='Share To Email'
        onClose={() => setShareToEmailModal(false)}
      >
        <Box w={isDesktop ? '400px' : ''}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <TextInput type='email' onChange={(e) => setEmail(e.target.value)} /> */}
            <Flex direction='column' marginY='2' marginX='6'>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon>+91</InputLeftAddon>
                <TextInput
                  // onKeyDown={onlyAllowTypingNumbers}
                  onKeyPress={onlyAllowTypingNumbers2}
                  {...register('phone', {
                    required: false,
                    pattern: mobileNumRegex,
                    // maxLength: 10,
                  })}
                  maxLength={10}
                  placeholder='Enter Phone Number'
                ></TextInput>
              </InputGroup>
              {formState.errors.phone && formState.errors.phone?.type === 'pattern' && (
                <ErrorText text={'Invalid Mobile Number'} />
              )}
            </Flex>
            <Flex direction='column' marginY='2' marginX='6'>
              <OrSeprator />
            </Flex>
            <Flex direction='column' marginY='2' marginX='6'>
              <FormLabel>Email ID</FormLabel>
              <TextInput
                {...register('email', { required: false, pattern: emailRegex })}
                placeholder='Enter Email ID'
              ></TextInput>
              {formState.errors.email && formState.errors.email?.type === 'pattern' && (
                <ErrorText text={'Invalid Email'} />
              )}
            </Flex>
            <Center py='2'>
              <Button type='submit' isLoading={isLoading || isSmsLoading}>
                Share
              </Button>
            </Center>
          </form>
        </Box>
      </Modal>
    </>
  )
}

const OrSeprator = () => {
  return (
    <Flex alignItems='center' paddingY='1rem'>
      <Divider orientation='horizontal' />
      <Box
        padding='0.3rem'
        margin='0.5rem'
        border='1px solid'
        borderColor='gray.200'
        borderRadius='full'
      >
        <Text fontSize='sm'>OR</Text>
      </Box>
      <Divider orientation='horizontal' />
    </Flex>
  )
}
