import { Box, Center, Grid, Image, useDisclosure, useToast } from '@chakra-ui/react'
import { CommonImages } from 'Assets/Common'
import { HomeInfoItem } from './HomeInfoItem'
import { ElevatedButton, HeadingText } from 'Core'
import { IUseDeviceType, useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { HomeInsuranceCardSelector } from './HomeInsuranceCardSelector'
import { useEffect, useLayoutEffect, useState } from 'react'
import { InsuranceFormBox } from './InsuranceFormBox'
import { useLazyValidateRtoQuery } from 'Services/API/motor.api'
import { useNavigate } from 'react-router-dom'
import {
  HomeCarForm,
  HomeCommercialForm,
  HomeHealthForm,
  HomeMiscForm,
  HomePrivateForm,
} from './Forms'
import {
  TSelectedType,
  updateSelectedSubType,
  updateSelectedType,
  updateVehicleNumber,
} from 'features/Home'
import { TSelectedSubType } from 'features/Home/HomePage'
import { Header } from 'Components'
import { store } from 'app/store'
import { resetProposal, resetQuotation, resetVehicleDetails } from 'features/Motor'
import { ToastService } from 'Services/toast.service'
import { resetCustomerDetails, resetSpTaggingForm } from 'features/Equitas'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { resetLeadDetails, updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { HomeLifeForm } from './Forms/HomeLifeForm'
import { HomeSachetForm } from './Forms/HomeSachetForm'
import { resetLifeQuotationDetails } from 'features/Life/LifeQuotationDetails.slice'
import { decryptData } from 'Services/general'
import { ISaveLeadResponse } from 'Models/ResponseModels'

const {
  HomeImages: {
    technologyDriven,
    quickPolicyIssuance,
    extensiveSupport,
    quickIncentive,
    userFriendly,
    insuranceBuffet,
    carInsurance,
    gcvInsurance,
    miscInsurance,
    healthInsurance,
  },
} = CommonImages

const homeInfoItemProps = [
  {
    label: 'Technology Driven',
    imageSource: technologyDriven,
  },
  {
    label: 'Quick policy issuance',
    imageSource: quickPolicyIssuance,
  },
  {
    label: 'Extensive Support',
    imageSource: extensiveSupport,
  },
  {
    label: 'Quick Incentives',
    imageSource: quickIncentive,
  },
  {
    label: 'User Friendly',
    imageSource: userFriendly,
  },
  {
    label: 'Insurance Buffet',
    imageSource: insuranceBuffet,
  },
]

const getHomeInfoItemLayout = ({ isDesktop, isTablet }: IUseDeviceType) => {
  if (isDesktop) {
    return 'repeat(6, 1fr)'
  } else if (isTablet) {
    return 'repeat(3, 1fr)'
  }
  return 'repeat(2, 1fr)'
}

const getSelectedImage = (selectedType: TSelectedType) => {
  console.log('get selected image')
  switch (selectedType) {
    case 'private':
      return carInsurance
    case 'commercial':
      return gcvInsurance
    case 'miscellaneous':
      return miscInsurance
    case 'health':
      return healthInsurance
    case 'life':
      return ''
    default:
      return ''
  }
}

function getSelectedForm(
  selectedType: TSelectedType,
  isDesktop: boolean,
  vehicleNumber: string,
  setVehicleNumber: React.Dispatch<React.SetStateAction<string>>,
  findPlansHandler: (vehicleNum: string, vehicleSubType: TSelectedSubType) => Promise<void>,
  findPlansLoading: boolean,
) {
  const motorProps = {
    isDesktop: isDesktop,
    vehicleNumber: vehicleNumber,
    setVehicleNumber: setVehicleNumber,
    findPlansHandler: findPlansHandler,
    findPlansLoading: findPlansLoading,
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  switch (selectedType) {
    case 'private':
      return <HomePrivateForm {...motorProps} />
    case 'commercial':
      return <HomeCommercialForm {...motorProps} />
    case 'miscellaneous':
      return <HomeMiscForm {...motorProps} />
    case 'health':
      return <HomeHealthForm />
    case 'life':
      return <HomeLifeForm />
    case 'sachet':
      return <HomeSachetForm />
    default:
      return <HomeCarForm {...motorProps} />
  }
}

const Home = () => {
  const { isDesktop, isMobile, isTablet } = useDeviceType()

  const { businessrole_name } = useAppSelector((state) => state.user.authenticatedUser)
  const { selectedAccountId } = useAppSelector((state) => state.customerDetails)
  const { isLoggedIn } = useAppSelector((state) => state.user)

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  // const [selectedType, setSelectedType] = useState<TSelectedType>('private')

  const { selectedType, selectedSubType } = useAppSelector((state) => state.homePage)

  const [vehicleNumber, setVehicleNumber] = useState('')

  const [leadID, setLeadID] = useState('')
  const [dropOff, setDropOff] = useState('')

  const [validateRtoLazyQuery] = useLazyValidateRtoQuery()

  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()

  const [findPlansLoading, setFindPlansLoading] = useState(false)

  const findPlansHandler = async (vehicleNum: string, insuranceSubType: TSelectedSubType) => {
    dispatch(updateSelectedSubType(insuranceSubType))
    setFindPlansLoading(true)
    const { data } = await validateRtoLazyQuery({
      keyword: vehicleNum.replaceAll('-', '').slice(0, 4),
    })
    console.log('data --->', data)
    setFindPlansLoading(false)
    if (vehicleNum && data) {
      const success = Boolean(Object.keys(data).length)
      dispatch(updateVehicleNumber(vehicleNum))
      if (success) {
        navigate(`/motor/${insuranceSubType}/details`)
        // createLead()
      } else {
        ToastService.showErrorToast({ title: 'Invalid RTO' })
      }
    } else {
      ToastService.showErrorToast({ title: 'Something went wrong!' })
    }
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('PreQuote', homePageDetails.selectedSubType)
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const homePageDetails = useAppSelector((state) => state.homePage)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const proposalDetails = useAppSelector((state) => state.proposalPage)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const spTaggingDetails = useAppSelector((state) => state.spTagging)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      vehicle_number: homePageDetails.vehicleNumber,
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      // insurer_name: insuranceDetails.previousInsurer?.name,
      drop_off: drop_off,
      product_name: product_name,
      lead_data: {
        homepage_details: homePageDetails,
        vehicle_details: vehicleDetails,
        insurance_details: insuranceDetails,
        quotation_details: quotationDetails,
        proposal_details: proposalDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: spTaggingDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  useLayoutEffect(() => {
    dispatch(resetLeadDetails())
    dispatch(resetVehicleDetails())
    dispatch(resetQuotation())
    dispatch(resetProposal())
    dispatch(resetSpTaggingForm())
    dispatch(resetLifeQuotationDetails())
    // dispatch(updateVehicleNumber(''))
  }, [])

  useEffect(() => {
    setVehicleNumber('')
    dispatch(updateVehicleNumber(''))
    dispatch(updateLeadID({ lead_uid: '' }))
  }, [selectedType, selectedSubType])

  useEffect(() => {
    if (selectedAccountId === -1) {
      navigate('/customer-account', { replace: true })
      if (isLoggedIn) {
        ToastService.showErrorToast({
          title: 'CBS details are required!',
        })
      }
    }
  }, [])

  useEffect(() => {
    if (homePageDetails.vehicleNumber) {
      createLead()
    }
  }, [homePageDetails, leadDetails])

  return (
    <>
      <Header />
      <Center paddingY={isDesktop ? '10' : '2'}>
        <HeadingText label="Let's Get You An Awesome Price" />
      </Center>
      {!isDesktop && (
        <Center>
          <Image
            // className='bounce'
            backgroundSize='contain'
            padding='4'
            // width={isMobile ? '70vw' : '40vw'}
            height='15rem'
            alt='insurance'
            src={getSelectedImage(selectedType)}
          />
        </Center>
      )}
      {/* <Box paddingX={isMobile ? '0.2rem' : isDesktop ? '10rem' : '4rem'}> */}
      <Box paddingX={['0.2rem', '1rem', '4rem', '6rem', '8rem', '10rem']}>
        <HomeInsuranceCardSelector
          deviceTypeObj={{ isDesktop, isMobile, isTablet }}
          selectedType={selectedType}
          updateSelectedType={updateSelectedType}
        />
        <Box paddingX='10'>
          <InsuranceFormBox>
            {getSelectedForm(
              selectedType,
              isDesktop,
              vehicleNumber,
              setVehicleNumber,
              findPlansHandler,
              findPlansLoading,
            )}
          </InsuranceFormBox>
        </Box>
      </Box>
      <Center paddingY='10'>
        <Grid
          gap='3rem'
          gridTemplateColumns={getHomeInfoItemLayout({
            isDesktop: isDesktop,
            isMobile: isMobile,
            isTablet: isTablet,
          })}
        >
          {homeInfoItemProps.map((item, index) => (
            <HomeInfoItem key={index} imageSource={item.imageSource} label={item.label} />
          ))}
        </Grid>
        {/* <ElevatedButton onClick={() => console.log(store.getState())}>Log</ElevatedButton> */}
      </Center>
    </>
  )
}

export { Home }
