import { Center, Flex, FormLabel, Image, Text, Box } from '@chakra-ui/react'
import { CommonImages } from 'Assets/Common'
import { registrationNumRegex } from 'Constants'
import { ElevatedButton, ProgressStepItem, TextInput } from 'Core'
import { validateVehicleNumber } from 'Services/general'
import { IMotorFormProps } from './MotorFormProps'
import { CompanyDialog } from 'Pages/Motor/Equitas/CompanyDialog'
import { ToggleButton } from 'Core/ToggleButton'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { updateOwnedBy } from 'features/Motor'

const {
  HomeImages: { carInsurance },
} = CommonImages

const HomeCarForm = (props: IMotorFormProps) => {
  const { isDesktop, setVehicleNumber, vehicleNumber, findPlansHandler, findPlansLoading } = props
  const { isIndividual } = useAppSelector((state) => state.quotationsDetails)
  const dispatch = useAppDispatch()

  return (
    <Flex paddingY={isDesktop ? '0' : '10'} paddingX={isDesktop ? '20' : '5'} alignItems='center'>
      <Flex
        justifyContent='space-between'
        direction='column'
        marginY='8'
        height='100%'
        flexBasis={isDesktop ? '40%' : '100%'}
      >
        <Flex width='100%' marginX='6' marginBottom='4'>
          <ToggleButton
            title=''
            firstLabel='Individual'
            secondLabel='Company'
            enabled={isIndividual}
            firstOnClick={() => dispatch(updateOwnedBy({ isIndividual: true }))}
            secondOnClick={() => dispatch(updateOwnedBy({ isIndividual: false }))}
          />
        </Flex>
        {/* TODO: change this to custom component */}
        <Flex marginX='6' gap='0.4rem'>
          <ProgressStepItem
            label='Car Number'
            isActive
            onTap={() => {
              console.log('hi')
            }}
          />
          <ProgressStepItem
            label='Car Details'
            isActive={false}
            onTap={() => {
              console.log('hi')
            }}
          />
        </Flex>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            findPlansHandler(vehicleNumber, 'car')
          }}
        >
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel fontWeight='semibold'>Car Number</FormLabel>
            <TextInput
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(validateVehicleNumber(e.target.value))}
              border='solid 2px'
              placeholder='Enter Car Number'
            />
          </Flex>
          <Center paddingTop='1rem'>
            <ElevatedButton
              isLoading={findPlansLoading}
              isDisabled={!registrationNumRegex.test(vehicleNumber)}
              type='submit'
            >
              Find Plans
            </ElevatedButton>
          </Center>
        </form>
        {/* <CompanyDialog
          findPlansHandler={findPlansHandler(vehicleNumber, 'car')}
          isOpen={isCompanyOpen}
          onClose={onCompanyClose}
          onOpen={onCompanyOpen}
        /> */}
      </Flex>
      {isDesktop && (
        <Flex justifyContent='center' flexBasis='60%'>
          <Image
            alt='car'
            backgroundSize='contain'
            paddingY={['4rem', '4rem', '2rem']}
            // height={['5rem', '10rem', '15rem', '15rem', '20rem']}
            src={carInsurance}
          />
        </Flex>
      )}
    </Flex>
  )
}

export { HomeCarForm }
