import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Spinner,
  Icon,
} from '@chakra-ui/react'
import { Header } from 'Components/Header'
import * as yup from 'yup'
import { AiOutlineUser } from 'react-icons/ai'
import { PersonalDetailsForm } from './PersonalDetailForm'
import { Requirement } from './RequirementForm'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form'
import { TSPDetails } from '../SPDetails'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import html2canvas from 'html2canvas'
import { Select } from 'chakra-react-select'
import { update } from 'lodash'
// import { updateSelectedSp } from './spDetails.life.slice'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetSpListingQuery } from 'Services/API/authentication.api'
import { TMotorProposalFormType } from 'Pages/Motor/Proposal/Forms/FormModel'
import { useGetSpConsentFormLinkMutation } from 'Services/API/motor.api'
import { ISpData } from 'Models/ResponseModels/Equitas'
import { updateSelectedSp } from 'features/Equitas'
import { useLazyGetCustomerDetailsQuery } from 'Services/API/equitas.api'
import { HiPencilAlt } from 'react-icons/hi'
import { resetLeadDetails } from 'features/Leads'
import { decryptData } from 'Services/general'

interface IModal {
  onOpen: () => void
}

interface IProposalSpTaggingForm {
  form: UseFormReturn<TMotorProposalFormType, any>
}

const PersonalDetails = () => {
  const dispatch = useAppDispatch()
  const { isDesktop } = useDeviceType()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [nextButton, setNextButton] = useState(false)
  const lifeCustomerData = useAppSelector((state) => state.lifeCustomerDetails)
  const [dob, setDob] = useState(
    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.dob
      ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.dob
      : lifeCustomerData.lifeAssuredDetailsTyped?.dob ?? '',
  )

  const [spListing2, setSpListing2] = useState<ISpData[]>([])

  const selectedSpInStore = useAppSelector((state) => state.spTagging)
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)

  useEffect(() => {
    onOpen()
    dispatch(resetLeadDetails())
  }, [])

  const [selectedSp, setSelectedSp] = useState<ISpData>()

  const onSubmitSp = (val?: any) => {
    if (val) {
      dispatch(updateSelectedSp(val))
    }
    onClose()
  }
  const lifeAssuredSchema = yup.object({
    relationship: yup.string().required('Relation with life assured is required'),
    ucic: yup.number().required('UCIC Id is required'),
  })
  const { businessrole_name } = useAppSelector((state) => state.user.authenticatedUser)

  type LifeForm = {
    relationship: string
    ucic: number
  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting, isSubmitted, isDirty, isValid },
  } = useForm<LifeForm>({
    resolver: yupResolver(lifeAssuredSchema),
    defaultValues: {
      relationship: '',
      ucic: undefined,
    },
  })
  const { id: userId } = useAppSelector((state) => state.user.authenticatedUser)

  const { data: spListing, isLoading: isSpListingLoading } = useGetSpListingQuery(
    {
      user_id: userId?.toString() ?? '',
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const proposalForm = useForm<TMotorProposalFormType>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })
  //  console.log(spListing,'sp')

  useEffect(() => {
    if (spListing) {
      setSpListing2(JSON.parse(decryptData(spListing.response_data)))
    }
  }, [spListing])

  return (
    <>
      {businessrole_name === 'NSP' && (
        <Modal size='md' closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select Specified Person</ModalHeader>
            <ModalBody pb={6}>
              <>
                <Text>Select SP</Text>
                <Select
                  placeholder='Select SP Tagging'
                  isLoading={isSpListingLoading}
                  {...proposalForm.register('spData', { required: true })}
                  value={selectedSp}
                  onChange={(item) => {
                    item && setSelectedSp(item)
                    proposalForm.setValue('spData', item ?? {})
                    proposalForm.clearErrors('spData')
                    item && dispatch(updateSelectedSp(item))
                  }}
                  options={spListing2?.map((spData) => {
                    return {
                      ...spData,
                      label:
                        (spData?.firstname ?? '') +
                        ' ' +
                        (spData?.lastname ?? '') +
                        ' (' +
                        spData.id +
                        ')',
                      value: spData.id?.toString(),
                    }
                  })}
                />
              </>
            </ModalBody>
            <ModalFooter justifyContent='center'>
              <Button
                isDisabled={!selectedSp}
                onClick={() => {
                  if (selectedSp) {
                    onSubmitSp(selectedSp)
                  }
                }}
                // isLoading={isLoading}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <Header />
      <Flex alignItems={'center'}>
        <Box mx='20' mt='8'>
          <Text fontSize={'xl'} fontWeight={'bold'}>
            Suitability Matrix
          </Text>
        </Box>
        {businessrole_name === 'NSP' && (
          <Flex mt='8' alignItems={'center'}>
            <Box>
              <AvatarGroup spacing='1rem'>
                <Avatar bg='white' icon={<AiOutlineUser fontSize='1.5rem' color='#B3B3B3' />} />
              </AvatarGroup>
            </Box>
            <Box>
              <Flex direction={'column'} fontSize={'12px'} px='2' gap='2'>
                <Box>
                  <Text fontWeight='semibold'>Specified Person</Text>
                </Box>
                <Box>
                  <Text
                    onClick={() => {
                      onOpen()
                    }}
                    fontSize={'md'}
                    color={'#1C4589'}
                  >
                    {`${selectedSpInStore?.selectedSp?.firstname} ${selectedSpInStore?.selectedSp?.lastname}`}{' '}
                    <Icon mt='-1' as={HiPencilAlt} />
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        )}
      </Flex>
      {/* ----------proposalFormS----------------- */}
      <Flex mx='20' mt='8' backgroundColor={'white'} direction={isDesktop ? 'row' : 'column'}>
        <Box
          border={nextButton ? '' : '1px solid #3E8DF0'}
          justifyContent='space-between'
          alignItems='center'
          alignContent={'center'}
          // paddingY='2'
          // paddingX='6'
          // bgColor='appDarkGrey'
          boxShadow='excludingTop'
          borderTopLeftRadius='2xl'
          flexBasis='50%'
        >
          <Text mx='10' my='7' fontWeight='semibold' fontSize='lg'>
            01. Life Assured Personal Details
          </Text>
          <hr />
          <PersonalDetailsForm setDob={setDob} setNextButton={setNextButton} />
        </Box>
        <Box
          flexBasis={'50%'}
          border={nextButton ? '1px solid #3E8DF0' : ''}
          justifyContent='space-between'
          // alignItems='center'
          // alignContent={'center'}
          // paddingY='2'
          // paddingX='6'
          // bgColor='appDarkGrey'
          boxShadow='excludingTop'
          borderTopRightRadius='2xl'
        >
          <Text mx='10' my='7' fontWeight='semibold' fontSize='lg'>
            02. Life Assured Requirements
          </Text>
          <hr />
          {lifeQuotationDetails.suitabilityStep === 'two' && <Requirement dob={dob} />}
          {/* <Requirement/> */}
        </Box>
      </Flex>
    </>
  )
}

export { PersonalDetails }
