import { Box, Divider, Grid, Button } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { decryptData, getArrayFromObj } from 'Services/general'
import { AddonComponent } from './AddonComponent'
import { ISaveLeadResponse } from 'Models/ResponseModels'

export const Addons = () => {
  const navigate = useNavigate()
  const { selectedQuotes } = useAppSelector((state) => state.cart)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()
  const dispatch = useAppDispatch()

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const memberDetails = useAppSelector((state) => state.memberDetails)
  const cart_details = useAppSelector((state) => state.cart)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: memberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('addons', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }
  useEffect(() => {
    let disabledStatus = false
    getArrayFromObj(selectedQuotes).forEach((quote) => {
      if (quote.loading) {
        disabledStatus = true
      }
    })
    setNextButtonDisabled(disabledStatus)
  }, [selectedQuotes])

  return (
    <Box border='1px' rounded='xl' backgroundColor='white'>
      <Box shadow='sm' paddingX='4' paddingY='6' fontWeight='bold'>
        01 Add on Page
      </Box>
      <Divider />
      <Grid templateColumns='repeat(2, 1fr)'>
        {Object.keys(selectedQuotes).map((quote) => {
          return (
            <AddonComponent
              quoteData={selectedQuotes[quote]}
              key={selectedQuotes[quote].quoteNumber}
            />
          )
        })}
      </Grid>
      <Grid templateColumns='repeat(1, 1fr)'>
        <Divider borderColor='#D8D8D8' />
        <Box className='flex justify-center my-12'>
          <Button
            variant='solid'
            colorScheme='primary'
            rounded='full'
            w='20%'
            onClick={() => {
              navigate('../proposer-insurer-details')
              // navigate('../health-questions')
              createLead()
            }}
            disabled={nextButtonDisabled}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Box>
  )
}
