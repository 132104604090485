import { Button, Flex } from '@chakra-ui/react'

export const ButtonComponent = ({
  left,
  onLeftButtonClick,
  onRightButtonClick,
  right,
}: {
  left: string
  onLeftButtonClick?: () => void
  onRightButtonClick?: () => void
  right: string
}) => {
  return (
    <Flex gap='4' px='4' py='4' bgColor='white' alignContent='center' justifyContent='center'>
      <Button
        onClick={onLeftButtonClick}
        size='md'
        variant='outline'
        rounded='full'
        textTransform='uppercase'
      >
        {left}
      </Button>
      <Button
        onClick={onRightButtonClick}
        type='submit'
        size='md'
        variant='solid'
        rounded='full'
        paddingX='8'
        paddingY='2'
      >
        {right}
      </Button>
    </Flex>
  )
}
