import { Center, Flex, Text } from '@chakra-ui/react'
import { ElementWithLabel, ErrorText, If } from 'Core'

import RenderDropdown from 'Pages/Life/FormComponents/RenderDropdown'
import { RenderSwitch } from 'Pages/Life/FormComponents/RenderSwitch'
import { RenderTextInput } from 'Pages/Life/FormComponents/RenderTextInput'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { objectEntries } from 'ts-extras'
// import RenderChild from '../../../src/Pages/Life/FormComponents'
// import { RendeRradio } from '../FormComponents/RendeRradio'
// import { RenderRadioSwitch } from '../FormComponents/RenderRadioSwitch'
// import { RenderDate } from '../FormComponents/RenderDate'
// import { RenderSearchable } from '../FormComponents/RenderSearchable'
// import RenderSiblings from '../FormComponents/RenderSiblings'
// import { RenderMultiSelect } from '../FormComponents/RenderMultiSelect'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import RenderChild from 'Pages/Life/FormComponents/RenderChild'
import { RenderMultiSelect } from 'Pages/Life/FormComponents/RenderMultiSelect'
import RenderSiblings from 'Pages/Life/FormComponents/RenderSiblings'
import { RenderRadioSwitch } from 'Pages/Life/FormComponents/RenderRadioSwitch'
import { RenderDate } from 'Pages/Life/FormComponents/RenderDate'
import { RenderSearchable } from 'Pages/Life/FormComponents/RenderSearchable'
import { RenderNumericText } from 'Pages/Life/FormComponents/RenderNumericText'

interface INestedForm {
  form: UseFormReturn
  fieldsData: INestedQuestionSet[]
  isDisabled?: boolean
  initialValues?: Record<string, string | number | boolean | string[]>
  onAffect?: (
    name: string,
    value: string | number,
    affect: string,
    factor?: number,
    operation?: string,
  ) => void
  disabledCodes?: string[]
  loadOptions?: (inputSearchValue: string, callback: (options: any[]) => void) => void
  onSearchableChange?: (val: any) => void
}

const NestedForm = (props: INestedForm) => {
  const {
    fieldsData,
    form,
    initialValues,
    onAffect,
    isDisabled,
    disabledCodes,
    loadOptions,
    onSearchableChange,
  } = props

  return (
    <>
      {fieldsData && fieldsData.length
        ? fieldsData.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <If condition={item.cover_type.toLowerCase() === 'text'}>
                  <>
                    <RenderTextInput
                      // isRequiredField={true}
                      form={form}
                      isRoot={true}
                      name={item.code}
                      isDisabled={isDisabled}
                      label={item.name}
                      placeholder={item.description ?? ''}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                        cover_type: 'Text',
                      }}
                      defaultValue={item.default}
                      min={item.min}
                      max={item.max}
                      regex={item.regex}
                      initialValues={initialValues}
                      input_type={item.input_type}
                      disabledCodes={disabledCodes}
                      hidden={item.hidden}
                      affect={item.affect}
                      onAffect={onAffect}
                      factor={item.factor}
                      operation={item.operation}
                      call_api={item.call_api}
                      required={item.required}
                    />
                  </>
                </If>
                <If
                  condition={
                    item.cover_type.toLowerCase() === 'dropdown' &&
                    item.input_type.toLowerCase() === 'multi-value'
                  }
                >
                  <>
                    <RenderMultiSelect
                      dropdownItems={item.dropdown ?? {}}
                      name={item.code}
                      label={item.name}
                      childrenData={item.values}
                      form={form}
                      isRoot={true}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                      }}
                      defaultValue={item.default}
                      isDisabled={isDisabled}
                      initialValues={initialValues}
                      affect={item.affect}
                      onAffect={onAffect}
                      factor={item.factor}
                      operation={item.operation}
                      disabledCodes={disabledCodes}
                      call_api={item.call_api}
                      required={item.required}
                    />
                  </>
                </If>
                <If
                  condition={
                    item.cover_type.toLowerCase() === 'dropdown' &&
                    item.input_type.toLowerCase() !== 'multi-value' &&
                    item.input_type.toLowerCase() !== 'numeric-text'
                  }
                >
                  {item.input_type.toLowerCase() === 'calculation' ? (
                    <RenderSiblings
                      name={item.code}
                      label={item.name}
                      dropdownItems={item.dropdown ?? {}}
                      childrenData={item.values}
                      form={form}
                      isRoot={true}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                      }}
                      initialValues={initialValues}
                      defaultValue={item.default}
                      cover_type={item.cover_type}
                      isDisabled={isDisabled}
                      disabledCodes={disabledCodes}
                      affect={item.affect}
                      onAffect={onAffect}
                      factor={item.factor}
                      operation={item.operation}
                    />
                  ) : (
                    <RenderDropdown
                      dropdownItems={item.dropdown ?? {}}
                      name={item.code}
                      label={item.name}
                      childrenData={item.values}
                      form={form}
                      isRoot={true}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                      }}
                      defaultValue={item.default}
                      isDisabled={isDisabled}
                      initialValues={initialValues}
                      affect={item.affect}
                      onAffect={onAffect}
                      factor={item.factor}
                      min={item.min}
                      max={item.max}
                      call_api={item.call_api}
                      required={item.required}
                      operation={item.operation}
                      disabledCodes={disabledCodes}
                    />
                  )}
                </If>
                <If condition={item.cover_type.toLowerCase() === 'multiple_dropdowns'}>
                  <>
                    <RenderDropdown
                      dropdownItems={item.dropdown ?? {}}
                      name={item.code}
                      label={item.name}
                      childrenData={item.values}
                      form={form}
                      isRoot={true}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                      }}
                      defaultValue={item.default}
                      initialValues={initialValues}
                      affect={item.affect}
                      onAffect={onAffect}
                      factor={item.factor}
                      operation={item.operation}
                      disabledCodes={disabledCodes}
                      call_api={item.call_api}
                      required={item.required}
                    />
                  </>
                </If>
                <If
                  condition={
                    item.cover_type.toLowerCase() === 'switch' &&
                    item.input_type.toLowerCase() !== 'radio'
                  }
                >
                  <>
                    <RenderSwitch
                      isDisabled={isDisabled}
                      form={form}
                      isRoot={true}
                      name={item.code}
                      label={item.name}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                      }}
                      defaultValue={item.default}
                      options={item.dropdown ?? {}}
                      childrenData={item.values}
                      initialValues={initialValues}
                      disabledCodes={disabledCodes}
                    />
                  </>
                </If>
                <If
                  condition={
                    item.cover_type.toLowerCase() === 'switch' &&
                    item.input_type.toLowerCase() === 'radio'
                  }
                >
                  <>
                    <RenderRadioSwitch
                      form={form}
                      isRoot={true}
                      name={item.code}
                      label={item.name}
                      isDisabled={isDisabled}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                      }}
                      defaultValue={item.default}
                      options={Object.keys(item.values)}
                      childrenData={item.values}
                      initialValues={initialValues}
                      disabledCodes={disabledCodes}
                    />
                  </>
                </If>
                <If
                  condition={
                    item.input_type.toLowerCase() === 'numeric-text' &&
                    item.cover_type.toLowerCase() === 'dropdown'
                  }
                >
                  <>
                    <RenderNumericText
                      // isRequiredField={true}
                      form={form}
                      isRoot={true}
                      name={item.code}
                      isDisabled={isDisabled}
                      label={item.name}
                      placeholder={item.description ?? ''}
                      parentData={{
                        name: 'BAAP',
                        value: 'form_fields',
                        cover_type: 'Text',
                      }}
                      defaultValue={item.default}
                      min={item.min}
                      max={item.max}
                      regex={item.regex}
                      initialValues={initialValues}
                      input_type={item.input_type}
                      disabledCodes={disabledCodes}
                      hidden={item.hidden}
                      affect={item.affect}
                      onAffect={onAffect}
                      factor={item.factor}
                      operation={item.operation}
                      call_api={item.call_api}
                      required={item.required}
                    />
                  </>
                </If>
                <If condition={item?.cover_type.toLowerCase() === 'joint'}>
                  <>
                    <ElementWithLabel
                      marginY='0.7rem'
                      height='fit-content'
                      label={item.name || item.code}
                      required
                    >
                      <Flex gap='0.2rem'>
                        {objectEntries(item.values).map(([_, value], i) => {
                          return (
                            <RenderChild
                              form={form}
                              initialValues={initialValues}
                              childField={value[0]}
                              parentData={{
                                name: 'BAAP',
                                value: 'form_fields',
                              }}
                              defaultValue={value[0]?.default}
                              key={i}
                              isWithoutLabel={true}
                              isDisabled={isDisabled}
                              disabledCodes={disabledCodes}
                            />
                          )
                        })}
                      </Flex>
                    </ElementWithLabel>
                  </>
                </If>
                <If condition={item.input_type.toLowerCase() === 'date'}>
                  <>
                    <RenderDate
                      form={form}
                      isRoot={true}
                      name={item.code}
                      isDisabled={isDisabled}
                      label={item.name}
                      placeholder={item.description ?? ''}
                      initialValues={initialValues}
                      input_type={item.input_type}
                      min={item.min}
                      max={item.max}
                      operation={item.operation}
                      factor={item.factor}
                      call_api={item.call_api}
                      required={item.required}
                    />
                  </>
                </If>
                <If
                  condition={
                    item.cover_type.toLowerCase() === 'searchable' && loadOptions !== undefined
                  }
                >
                  <>
                    <RenderSearchable
                      form={form}
                      name={item.code}
                      isDisabled={isDisabled}
                      label={item.name}
                      placeholder={item.description ?? ''}
                      initialValues={initialValues}
                      loadOptions={loadOptions}
                      onSearchableChange={onSearchableChange}
                    />
                  </>
                </If>
              </React.Fragment>
            )
          })
        : null}
    </>
  )
}

export { NestedForm }
