import { Flex, forwardRef, Text } from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'
import { useEffect } from 'react'

interface IToggleSwitch {
  enabled: boolean
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>
  enabledLabel?: string
  disabledLabel?: string
  changeHandler?: (enabled: boolean) => void
}

const ToggleSwitch = forwardRef((props: IToggleSwitch, ref) => {
  const {
    enabled,
    setEnabled,
    enabledLabel = 'Yes',
    disabledLabel = 'No',
    changeHandler,
    ...rest
  } = props

  useEffect(() => {
    if (changeHandler) {
      changeHandler(enabled)
    }
  }, [enabled])

  const { isDesktop } = useDeviceType()

  return (
    <Flex
      role='switch'
      width='fit-content'
      onClick={() => setEnabled(!enabled)}
      alignItems='center'
      cursor='pointer'
      userSelect='none'
      ref={ref}
      {...rest}
    >
      <Text
        bgColor={enabled ? 'secondary.600' : 'white'}
        color={enabled ? 'white' : 'black'}
        paddingY={isDesktop ? '2' : '1'}
        paddingX={isDesktop ? '6' : '4'}
        border='solid 1px gray'
        borderLeftRadius='xl'
        borderColor='gray.500'
        fontSize={isDesktop ? 'base' : 'md'}
      >
        {enabledLabel}
      </Text>
      <Text
        bgColor={!enabled ? 'secondary.600' : 'white'}
        color={!enabled ? 'white' : 'black'}
        paddingY={isDesktop ? '2' : '1'}
        paddingX={isDesktop ? '6' : '4'}
        border='solid 1px gray'
        borderRightRadius='xl'
        borderColor='gray.500'
        fontSize={isDesktop ? 'base' : 'md'}
      >
        {disabledLabel}
      </Text>
    </Flex>
  )
})

export { ToggleSwitch }
