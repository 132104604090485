import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Skeleton,
  Stack,
  Text,
  Image,
  Center,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { ElevatedButton } from 'Core'
import {
  setCustomerConsentIndentifier,
  updateSelectedQuotes,
} from 'features/Health/cart.health.slice'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import successImg from 'Assets/Common/Success.webp'
import errorImg from 'Assets/Common/error.png'

import {
  useConsentCheckQuery,
  useCreateProposalMutation,
  useHealthConsentFormLinkMutation,
  useLazyConsentCheckQuery,
  useLazyPaymentCheckQuery,
} from 'Services/API/health.api'
import { IndianRupeeFormatFromString, decryptData } from 'Services/general'
import { ToastService } from 'Services/toast.service'
import { IDataComponentProps } from '../interface'
import { HealthConsentFormRequest, ProposalRequest } from 'Models/ResponseModels/Health'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { ISaveLeadResponse } from 'Models/ResponseModels'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

export const DataComponent = (props: IDataComponentProps) => {
  const { isDesktop } = useDeviceType()

  const { memberDetails, isProposerIncluded, proposer, sum_insured } = useAppSelector(
    (state) => state.memberDetails,
  )

  const selectedSpData = useAppSelector((state) => state.spTagging)
  const cart_details = useAppSelector((state) => state.cart)
  const completeMemberDetails = useAppSelector((state) => state.memberDetails)

  const { lead_uid } = useAppSelector((state) => state.leadDetails)
  const {
    accounts,
    customerAccountStatus,
    selectedAccountId,
    selectedAccountPaymentMode,
    ucic,
    proposerDetailsFromCBS,
    ckyc_number,
  } = useAppSelector((state) => state.customerDetails)

  const { selectedQuotes, selectedPlanType, customer_consent_identifier } = useAppSelector(
    (state) => state.cart,
  )
  const { selectedSp } = useAppSelector((state) => state.spTagging)

  const [netPremium, setNetPremium] = useState(0)
  const [GST, setGST] = useState(0)
  const [pollingInterval, setPollingInterval] = useState(2000)
  const [paymentPollingInterval, setPaymentPollingInterval] = useState(2000)
  const [imageLoadFail, setImageLoadFail] = useState(false)
  const [finalPremium, setFinalPremium] = useState(0)
  const [consentUrl, setConsentUrl] = useState('')
  const [paymentUrl, setPaymentUrl] = useState('')

  const [createProposal] = useCreateProposalMutation()
  const [generateLink, { isLoading }] = useHealthConsentFormLinkMutation()

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  const [loading, setLoading] = useState(true)
  const [isCustomerConsentCheckDataLoading, setIsCustomerConsentCheckDataLoading] = useState(true)
  const [paymentDone, setPaymentDone] = useState('')
  const [proposalErrorText, setProposalErrorText] = useState('')

  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const spDetails = useAppSelector((state) => state.spTagging)

  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.user)

  // useEffect , [consentAPIdata] ....check is_submitted --->  set payment url

  const [checkForCustomerConsentSubmission, { data: customerConsentCheckData }] =
    useLazyConsentCheckQuery({ pollingInterval: pollingInterval })

  const [checkForPaymentStatus, { data: paymentCheckData }] = useLazyPaymentCheckQuery({
    pollingInterval: paymentPollingInterval,
  })

  const [saveLeads, { isLoading: saveLeadsLoading, isError: saveLeadsError }] =
    useSaveLeadsMutation()

  useEffect(() => {
    if (customerConsentCheckData) {
      if (customerConsentCheckData.is_submited) {
        setPaymentUrl(customerConsentCheckData.payment_data[0].payment_url)
        setIsCustomerConsentCheckDataLoading(false)
        setPollingInterval(0)
        checkForPaymentStatus({
          unique_identifier: props.quoteData.uniqueIdentifier!,
        })
      }
    }
  }, [customerConsentCheckData])

  useEffect(() => {
    checkForPaymentStatus({
      unique_identifier: props.quoteData.uniqueIdentifier!,
    })
  }, [])

  useEffect(() => {
    if (paymentCheckData) {
      setPaymentDone(paymentCheckData.payment_status)
      if (paymentCheckData.payment_status === 'completed') {
        setPaymentPollingInterval(0)
        setIsCustomerConsentCheckDataLoading(false)
        // setLoading(false)
        setPaymentDone('completed')
      }
    }
  }, [paymentCheckData])

  const checkForAutoDebit = (insurerFlag: boolean) => {
    if (selectedAccountPaymentMode === 'DirectDebit' && insurerFlag) {
      return true
    }
    return false
  }

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: completeMemberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: selectedSpData,
      },
      stp_trigger_by: {
        user_firstname: spDetails.selectedSp?.firstname,
        user_lastname: spDetails.selectedSp?.lastname,
        username: spDetails.selectedSp?.user__username,
        stp_trigger_user_id: spDetails.selectedSp?.id?.toString(),
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    if (!payload.stp_trigger_by) {
      delete payload.stp_trigger_by
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('sp-validated', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  useEffect(() => {
    createLead()
  }, [customer_consent_identifier])

  useEffect(() => {
    const account_details = accounts?.filter((account) => account.account_id === selectedAccountId)

    if (account_details && paymentCheckData?.payment_status === 'pending') {
      const payload: ProposalRequest = {
        account_details: {
          ...account_details[0],
          brnchNm: account_details[0]?.source_branch,
          acctTp: account_details[0]?.account_type,
          acctNm: account_details[0]?.account_title,
          cstId: ucic!,
          brnchAddr: account_details[0].source_branch,
          dbtrNm:
            proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp?.[0]?.frsNm ?? '',
          dbtrAcctId: account_details[0]?.show_account_number,
          Ifsc_code: account_details[0].ifsc_code,
          customerAccountStatus: customerAccountStatus,
          selectedAccountId: selectedAccountId.toString(),
          ucic: ucic!,
        },
        kyc_data: {
          number: ckyc_number ?? '',
        },
        include_proposer: props.quoteData.memberType === 'parents' ? 0 : isProposerIncluded ? 1 : 0,
        plan_type: selectedPlanType,
        unique_identifier: props.quoteData.uniqueIdentifier!,
        duration_years: props.quoteData.duration,
        sum_insured: props.quoteData.sumInsured,
        sp_id: selectedSp ? selectedSp.id ?? 0 : 0,
        // sp_id: 123,
        proposer: {
          ...proposer,
        },
        members: [
          ...Object.keys(memberDetails)
            .filter((key) =>
              props.quoteData.addedMemberTypes?.includes(memberDetails[key].member_type),
            )
            .map((key) => {
              return {
                ...memberDetails[key],
              }
            }),
        ],
      }
      if (user.authenticatedUser.businessrole_name?.toLowerCase() === 'sp') {
        payload.sp_id = user.authenticatedUser.id
      }

      createProposal(payload)
        .unwrap()
        .then((res) => {
          console.log(res)
          if (!res.final_premium) {
            ToastService.showErrorToast({
              title: 'Something Went Wrong!',
            })
            console.log('un expected response')
            return
          }

          if (props.quoteData.premium !== res.final_premium) {
            ToastService.showInfoToast({
              title: 'Your premium has been changed',
            })
          }
          startTimer()
          setNetPremium(res.net_premium)
          setGST(res.gst)
          setFinalPremium(res.final_premium)

          dispatch(
            updateSelectedQuotes({
              key: props.quoteData.quoteNumber,
              data: {
                ...props.quoteData,
                premium: res.final_premium,
              },
            }),
          )
          if (cart_details.customer_consent_identifier) {
            setLoading(false)
            setConsentUrl(
              '/health/consent-form/customer/consent_identifier/' +
                cart_details.customer_consent_identifier,
            )
            checkForCustomerConsentSubmission({
              consent_identifier: cart_details.customer_consent_identifier,
              consent_type: 'customer_consent',
            })
            return
          }
          generateLink({
            consent_type: 'customer_consent',
            plan_type: selectedPlanType,
            lead_id: lead_uid ?? '',
            consent_data: [
              {
                unique_identifier: props.quoteData.uniqueIdentifier!,
                isProposerIncluded: isProposerIncluded,
                payment_link: res.payment_url,
                proposer: {
                  ...proposer,
                  email_id: proposer.email,
                  mobile_no: proposer.mobile,
                },
                members: [
                  ...Object.keys(memberDetails)
                    .filter((key) =>
                      props.quoteData.addedMemberTypes?.includes(memberDetails[key].member_type),
                    )
                    .map((key) => {
                      return {
                        ...memberDetails[key],
                      }
                    }),
                ],
                account_details: {
                  ...account_details[0],
                  brnchNm: account_details[0]?.source_branch,
                  acctTp: account_details[0]?.account_type,
                  acctNm: account_details[0]?.account_title,
                  cstId: ucic!,
                  brnchAddr: account_details[0].source_branch,
                  dbtrNm:
                    proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp?.[0]?.frsNm ??
                    '',
                  dbtrAcctId: account_details[0]?.show_account_number,
                  Ifsc_code: account_details[0].ifsc_code,
                  customerAccountStatus: customerAccountStatus,
                  selectedAccountId: selectedAccountId.toString(),
                  ucic: ucic!,
                },

                // change account_type to .show_account_number.charAt(0)
                policy_details: {
                  ...props.quoteData,
                  premium: res.final_premium,
                  isAutoDebitSupported: checkForAutoDebit(props.quoteData.isAutoDebitSupported),
                },
              },
            ],

            sp_mail:
              user.authenticatedUser.businessrole_name?.toLowerCase() === 'sp'
                ? ''
                : selectedSp?.email,
          })
            .unwrap()
            .then(async (res) => {
              setLoading(false)
              setConsentUrl(res)
              // consent check api

              const consent_identifier = res.split('/')[res.split('/').length - 1]

              dispatch(setCustomerConsentIndentifier(consent_identifier))
              await checkForCustomerConsentSubmission({
                consent_identifier: consent_identifier!,
                consent_type: 'customer_consent',
              })
            })
            .catch((err) => {
              console.log(err)
              ToastService.showErrorToast({
                title: 'Something Went Wrong!',
                message: err.data.error_text,
              })
            })
        })
        .catch((err) => {
          // setLoading(false)
          console.log(err, 'err in catch')
          setProposalErrorText(err.data.error_text)

          ToastService.showErrorToast({
            title: 'Something went wrong',
            message: err.data.error_text,
          })
        })
    }
  }, [paymentCheckData])
  const proceedToPay = () => {
    window.open(paymentUrl, '_blank')
  }

  const proceedToConsent = async () => {
    const account_details = accounts?.filter((account) => account.account_id === selectedAccountId)
    if (account_details) {
      const response = await generateLink({
        consent_type: 'customer_consent',
        plan_type: selectedPlanType,
        lead_id: lead_uid ?? '',
        consent_identifier: cart_details.customer_consent_identifier,
        consent_data: [
          {
            unique_identifier: props.quoteData.uniqueIdentifier!,
            isProposerIncluded: isProposerIncluded,
            payment_link: paymentUrl,
            proposer: {
              ...proposer,
              email_id: proposer.email,
              mobile_no: proposer.mobile,
            },
            members: [
              ...Object.keys(memberDetails)
                .filter((key) =>
                  props.quoteData.addedMemberTypes?.includes(memberDetails[key].member_type),
                )
                .map((key) => {
                  return {
                    ...memberDetails[key],
                  }
                }),
            ],
            account_details: {
              ...account_details[0],
              brnchNm: account_details[0]?.source_branch,
              acctTp: account_details[0]?.account_type,
              acctNm: account_details[0]?.account_title,
              cstId: ucic!,
              brnchAddr: account_details[0].source_branch,
              dbtrNm:
                proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp?.[0]?.frsNm ?? '',
              dbtrAcctId: account_details[0]?.show_account_number,
              Ifsc_code: account_details[0].ifsc_code,
              customerAccountStatus: customerAccountStatus,
              selectedAccountId: selectedAccountId.toString(),
              ucic: ucic!,
            },

            // change account_type to .show_account_number.charAt(0)
            policy_details: {
              ...props.quoteData,
              premium: finalPremium,
              isAutoDebitSupported: checkForAutoDebit(props.quoteData.isAutoDebitSupported),
            },
          },
        ],

        sp_mail:
          user.authenticatedUser.businessrole_name?.toLowerCase() === 'sp' ? '' : selectedSp?.email,
      }).unwrap()
      ToastService.showSuccessTaost({
        title: 'Success',
        message: `Customer Consent form link has been sent to customer's phone and email`,
      })
      setResendOtp(false)
      startTimer()
    }
  }
  return (
    <>
      <Box padding={isDesktop ? '8' : ''} borderBottom={isDesktop ? '1px solid #D8D8D8' : ''}>
        <Flex gap={isDesktop ? '10' : ''} direction={isDesktop ? 'row' : 'column'}>
          <Box w={isDesktop ? '10%' : ''} marginY='auto'>
            <Box gap='4' alignItems={'center'} my='4'>
              <Text fontSize={24} textAlign='center'>
                Payment
              </Text>
            </Box>
            {!isDesktop && <Divider orientation={'horizontal'} borderColor='#D8D8D8' />}
          </Box>
          {isDesktop ? (
            <Box w='1' h={isDesktop ? '28' : ''} my='auto'>
              <Divider orientation='vertical' borderColor='#D8D8D8' />
            </Box>
          ) : (
            ''
          )}
          <Box w={isDesktop ? ' 100%' : ''}>
            <Flex
              direction={isDesktop ? 'row' : 'column'}
              gap={4}
              alignItems={'center'}
              paddingY={isDesktop ? '' : '8'}
            >
              <Box marginY='auto' marginX='auto'>
                <Box border='1px' borderColor='appGrey.200' backgroundColor='white'>
                  {/* <img src={props.insurer_logo} className='w-[140px] md:w-[180px] p-2 h-auto' /> */}
                  {imageLoadFail ? (
                    <Center>
                      <Text width='100%' height='100%' fontSize='sm' textAlign='center'>
                        {props.quoteData.insuranceName}
                      </Text>
                    </Center>
                  ) : (
                    <Image
                      className='w-[140px] md:w-[180px] mx-4'
                      src={props.insurer_logo}
                      onError={() => setImageLoadFail(true)}
                    />
                  )}
                </Box>
              </Box>
              {isDesktop && (
                <Box mx='4' w='1' h='28' my='auto'>
                  <Divider orientation='vertical' borderColor='#D8D8D8' />
                </Box>
              )}

              {loading && (
                <Stack p='6'>
                  <Skeleton height='20px' width='150px' />
                  <Skeleton height='20px' width='150px' />
                  <Skeleton height='20px' width='150px' />
                </Stack>
              )}

              {!loading && (
                <Flex padding={isDesktop ? '10' : ''} marginX='auto' gap='4'>
                  <Flex gap='4' direction={'column'}>
                    <Text>Net Premium</Text>
                    <Text>GST</Text>
                    <Text fontWeight={'bold'}>You&apos;ll Pay</Text>
                  </Flex>
                  <Flex gap='4' direction={'column'}>
                    <Text>₹{IndianRupeeFormatFromString(netPremium.toString())}</Text>
                    <Text>₹{IndianRupeeFormatFromString(GST.toString())}</Text>
                    <Text fontWeight={'bold'}>
                      ₹{IndianRupeeFormatFromString(finalPremium.toString())}
                    </Text>
                  </Flex>
                </Flex>
              )}
              <Flex direction={'column'} gap='2'>
                {isCustomerConsentCheckDataLoading && (
                  <Button
                    colorScheme={'primary'}
                    variant='solid'
                    // disabled={loading || paymentDone === 'completed'}
                    rounded='full'
                    width={isDesktop ? '200px' : '250px'}
                    isDisabled={!resendOtp}
                    isLoading={isLoading}
                    onClick={() => proceedToConsent()}
                  >
                    {resendOtp ? 'Resend Link' : `Resend Link in ${timeLeft}`}
                  </Button>
                )}
                <Button
                  isLoading={isCustomerConsentCheckDataLoading}
                  isDisabled={paymentDone === 'completed'}
                  onClick={() => proceedToPay()}
                  colorScheme='primary'
                  rounded='full'
                  width={isDesktop ? '200px' : '250px'}
                >
                  PROCEED TO PAY
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
      {proposalErrorText != '' && (
        <Flex direction={'column'} gap='3' color='red' padding='10'>
          <Text fontWeight={'bold'}>Proposal Error Message</Text>
          <Text fontWeight={'light'} wordBreak={'break-word'}>
            {proposalErrorText}
          </Text>
        </Flex>
      )}

      {!isDesktop ? <Divider orientation={'horizontal'} borderColor='#525050' /> : ''}
      {paymentDone === 'completed' && (
        <Flex alignItems={'center'} justifyContent={'center'} direction={'column'}>
          <Image width='10rem' src={successImg} my='5' />
          <Text fontSize={'xl'} fontWeight={'bold'} my='5'>
            Your Payment has been Completed!
          </Text>
        </Flex>
      )}
      {paymentDone === 'failed' && (
        <Flex alignItems={'center'} justifyContent={'center'} direction={'column'}>
          <Image width='10rem' src={errorImg} my='5' />
          <Text fontSize={'xl'} fontWeight={'bold'} my='5'>
            Your Payment has been Failed!
          </Text>
        </Flex>
      )}
    </>
  )
}
