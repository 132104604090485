import { QuotationPageDesktop } from './Desktop'

const QuotationPage = () => {
  return (
    <>
      <QuotationPageDesktop />
    </>
  )
}

export { QuotationPage }
