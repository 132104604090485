import { useDeviceType } from 'app/hooks'
import { useGetPincodeListingQuery } from 'Services/API/health.api'
import { CashlessModalDesktop } from './Desktop'
import { CashlessModalMobile } from './Mobile'

export const CashlessModal = () => {
  const { isDesktop } = useDeviceType()
  // const { data: pincodeData } = useGetPincodeListingQuery({ insurer: '20', pincode: '201301' })
  return <>{isDesktop ? <CashlessModalDesktop /> : <CashlessModalMobile />}</>
}
