const variantPrimary = () => {
  const disabled = {
    bg: 'grey',
    color: 'grey',
  }

  const loading = {
    bg: 'primary.300',
    color: 'white',
  }

  return {
    bg: 'primary',
    color: 'white',
    _hover: {
      bg: 'primary.800',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      bg: 'primary',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  }
}
const variantOutline = () => {
  const disabled = {
    bg: 'grey',
    color: 'grey',
  }

  const loading = {
    bg: 'primary',
    color: 'white',
  }

  return {
    bg: 'white',
    color: 'primary',
    _hover: {
      bg: 'primary',
      color: 'white',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      bg: 'primary',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  }
}

export const variants = {
  solid: variantPrimary,
  outline: variantOutline,
  // add more variants as needed
}
