import { AboutUs, Home, Leads, Login, RegisterDistributer } from './Pages'
import { QuotationPage } from 'Pages/Health'
import {
  CustomerAccountDetails,
  MotorProposal,
  MotorSalesReport,
  LifeSalesReport,
  VehicleDetails,
  VehicleInsuranceDetails,
} from 'Pages/Motor'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { Quotation } from 'Pages/Motor/Quotation'
import MemberDetails from 'Pages/Health/MemberDetails'
import { ProposalPage } from 'Pages/Health/ProposalPage'
import { Addons } from 'Pages/Health/ProposalPage/AddOns'
import { useAppSelector } from 'app/hooks'
import { ProposerAndInsurerDetails } from 'Pages/Health/ProposalPage/ProposerAndInsurerDetails'
import { Payment } from 'Pages/Health/ProposalPage/Payment'
import { HealthQuestions } from 'Pages/Health/ProposalPage/HealthQuestions'
import { useEffect } from 'react'
import { ToastService } from 'Services/toast.service'
import { HealthSalesReport, MotorSalesSummary, SachetSalesReport } from 'Pages/Motor/SalesReport'
import { SupportListing, TicketCreation } from 'Pages/Motor/Equitas/SupportTicket'
import { CustomerConsentForm } from 'Pages/Common/ConsentForm/CustomerConsentForm'
import { SpConsentForm } from 'Pages/Common/ConsentForm/SpConsentForm'
import { ConsentForm } from 'Pages/Health/Consent'
import Test from 'Pages/Motor/test'
import { PersonalDetails } from 'Pages/Life/PersonalDetails/PersonalDetailsPage'
import { LifeQuotationPage } from 'Pages/Life/PlanSelection/LifeQuotationPage'
import { RecommendedPlans } from 'Pages/Life/RecommendedPlans'
import { LifeQuotation } from 'Pages/Life/Quotation'
import { ConsentPendingPage } from 'Pages/Life/Consent/ConsentPendingPage'
import { LifeConsentForm } from 'Pages/Life/Consent/ConsentForm'
import { LifeSachetForm } from 'Pages/Life/Sachet/ConsentForm'
import SachetsFormContainer from 'Components/SachetFormContainer'

const PrivateRoutes = () => {
  const {
    isLoggedIn,
    isRoleVerified,
    authenticatedUser: { authtoken },
  } = useAppSelector((state) => state.user)

  const navigate = useNavigate()

  useEffect(() => {
    if (!authtoken) navigate('/login', { replace: true })
  })

  return isLoggedIn && isRoleVerified ? <Outlet /> : <Navigate to={'/login'} replace />
}

const AuthRestrictedRoutes = () => {
  const { isLoggedIn, isRoleVerified } = useAppSelector((state) => state.user)

  return isLoggedIn && isRoleVerified ? <Navigate to={'/customer-account'} replace /> : <Outlet />
}

const RoleRestrictedRoutes = () => {
  const { businessrole_name } = useAppSelector((state) => state.user.authenticatedUser)

  const allowedSales = ['POSP', 'Sub POSP', 'SP', 'NSP'].includes(businessrole_name ?? '')

  useEffect(() => {
    if (!allowedSales) {
      ToastService.showErrorToast({
        title: 'You are not allowed to sell',
      })
    }
  }, [allowedSales])

  return allowedSales ? <Outlet /> : <Navigate to={'/'} replace />
}

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path='/consent-life-form/:consentId' element={<LifeConsentForm />} />
      <Route path='/sachet-life-form/:quoteId' element={<LifeSachetForm />} />
      <Route path='' element={<Home />} />
      <Route path='/aboutus' element={<AboutUs />} />
      <Route path='consent-form/*' element={<CustomerConsentForm />} />
      <Route path='sp-consent/*' element={<SpConsentForm />} />
      <Route
        path='health/consent-form/customer/consent_identifier/:consent_identifier'
        element={<ConsentForm type={'customer_consent'} />}
      />

      <Route
        path='health/consent-form/sp/consent_identifier/:consent_identifier'
        element={<ConsentForm type={'sp_consent'} />}
      />

      <Route path='/' element={<AuthRestrictedRoutes />}>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<RegisterDistributer />} />
      </Route>
      <Route path='/' element={<PrivateRoutes />}>
        <Route path='customer-account' element={<CustomerAccountDetails />} />
        <Route path='my-leads' element={<Leads />} />
        <Route path='health/quotes' element={<QuotationPage />} />
        <Route path='vehicle-details' element={<VehicleDetails />} />
        <Route path='motor/:type/details' element={<VehicleInsuranceDetails />} />
        <Route path='motor/:type/quotation' element={<Quotation />} />
        <Route path='motor/:type/proposal' element={<MotorProposal />} />
        <Route path='sales/motor' element={<MotorSalesReport />} />
        <Route path='/sales/health' element={<HealthSalesReport />} />
        <Route path='/sales/life' element={<LifeSalesReport />} />
        <Route path='/sales/sachet' element={<SachetSalesReport />} />
        <Route path='/sales-summary/motor' element={<MotorSalesSummary />} />
        <Route path='ticket-creation' element={<TicketCreation />} />
        <Route path='support-listing' element={<SupportListing />} />
        <Route path='test' element={<Test />} />
        <Route path='sachets/:type' element={<SachetsFormContainer />} />

        <Route path='health/proposal/' element={<ProposalPage />}>
          <Route path='addons' element={<Addons />} />
          <Route path='proposer-insurer-details' element={<ProposerAndInsurerDetails />} />
          <Route path='health-questions' element={<HealthQuestions />} />
        </Route>
        <Route path='health/payment' element={<Payment />} />
        <Route path='health/quotes' element={<QuotationPage />} />
        <Route path='health/member-details' element={<MemberDetails />} />

        <Route path='/personal-details' element={<PersonalDetails />} />
        <Route path='/quotation-page' element={<LifeQuotationPage />} />
        <Route path='/after-quotation-page' element={<LifeQuotation />} />
        <Route path='/recommended-plan' element={<RecommendedPlans />} />
        <Route path='/consent-pending' element={<ConsentPendingPage />} />
      </Route>
    </Routes>
  )
}
