import logo from './logo.jpg'
import loginImage from './login.png'
import { HomeImages } from './Home'
import { Check } from './Check'

export const CommonImages = {
  logo,
  loginImage,
  HomeImages,
  Check,
}
