import { AppRouter } from './Routes'
import './App.css'
import { useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'
import { ToastService } from 'Services/toast.service'
import { useIdleTimer } from 'react-idle-timer'
import { useLogoutMutation } from 'Services/API/authentication.api'
import { useNavigate, useLocation } from 'react-router-dom'

import withClearCache from './ClearCache'

const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function MainApp() {
  const [isIdle, setIsIdle] = useState(false)
  const { isLoggedIn, authenticatedUser } = useAppSelector((state) => state.user)
  const [logout] = useLogoutMutation()
  const navigate = useNavigate()

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => {
    if (isLoggedIn) {
      setIsIdle(true)
      logout(null)
      ToastService.showErrorToast({
        title: 'You are idle!',
      })
    }
  }

  const idle = useIdleTimer({
    timeout: 7 * 60 * 1000,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  })

  // const location = useLocation()

  // useEffect(() => {
  //   console.log('-----Location changed-----')
  // }, [location])

  return <AppRouter />
}

export default App
