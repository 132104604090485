import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Textarea,
  Text,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { BimabossDatePicker, TextInput } from 'Core'
import { useEffect, useState } from 'react'
import {
  useGetMiscellaneousListingQuery,
  useLazyGetPincodeListingQuery,
  useLazyBmiCheckQuery,
} from 'Services/API/health.api'
import {
  Select as ChakraSelect,
  AsyncSelect,
  SingleValue,
  ChakraStylesConfig,
} from 'chakra-react-select'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  calculateBMI,
  decryptData,
  getArrayFromObj,
  onlyAllowTypingAlphabet,
  onlyAllowTypingNumbers,
  pincodeLabelFormatterHealth,
} from 'Services/general'
import { subDays, subMonths, subYears } from 'date-fns'
import { updateProposer } from 'features/Health/memberDetails.health.slice'
import { IHealthPincode, IHealthPincodeOption } from 'Models/ResponseModels/Health'
import { emailRegex, mobileNumRegex, onlyAlphabetsRegex, panNumRegex } from 'Constants'
import { getValue } from '@testing-library/user-event/dist/types/utils'
import { MemberData } from 'Models/Common/Health.General'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { ToastService } from 'Services/toast.service'
import { boolean, custom } from 'zod'
import { includes } from 'lodash'
import { ISaveLeadResponse } from 'Models/ResponseModels'

const SelfDataSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First Name is required!')
    .min(1, 'Min length is 1 letters')
    .test('len', 'Min length is 1 letters', (text) => {
      if (text?.trim() == '') {
        return false
      }
      if (text!.trim()!.length < 1) {
        return false
      }
      return true
    }),
  middleName: yup.string(),
  lastName: yup
    .string()
    .required('Last Name is required!')
    .min(1, 'Min length is 1 letters')
    .test('len', 'Min length is 1 letters', (text) => {
      if (text?.trim() == '') {
        return false
      }
      if (text!.trim()!.length < 1) {
        return false
      }
      return true
    }),
  dob: yup.string().required('Date of Birth is required!'),
  pincode: yup.string().required('Pincode is required!'),
  parmanent_pincode: yup.string().required('Pincode is required!'),
  mobile: yup
    .string()
    .required('Mobile Number is required!')
    .matches(mobileNumRegex, 'Invalid Format!'),
  email: yup.string().required('Email is required!').matches(emailRegex, 'Invalid format!'),

  occupation: yup.object({
    name: yup.string(),
    code: yup.string().required('Occupation is required!'),
  }),
  current_address: yup.string().required('Current Address is required!'),
  permanent_address: yup.string().required('Permanent Address is required!'),
  salutation: yup
    .object({
      name: yup.string(),
      code: yup.string(),
    })
    .required(),
  maritalStatus: yup.object({
    name: yup.string(),
    code: yup.string().required('Marital Status is required!'),
  }),
  height: yup.string().required('Height is required!'),
  weight: yup.string().required('Weight is required!'),
  educational_qualification: yup.object({
    name: yup.string(),
    code: yup.string().required('Educational Qualification is required!'),
    // .required('Educational Qualification is required!'),
  }),
  showPanNumber: yup.boolean(),

  pan_number: yup.string().when('showPanNumber', {
    is: true,
    then: yup.string().required('Pan Number is required!').matches(panNumRegex, 'Invalid Format!'),
  }),
  // .required('Pan Card is required!')
  show_aadhar_number: yup.boolean(),
  aadhar_number: yup.string().when('show_aadhar_number', {
    is: true,
    then: yup.string().required('Aadhar Card Number is required!').max(14, 'Max limit is 14'),
  }),
  annual_income: yup.string().required('Annual Income is required!').max(14, 'Max limit is 14'),

  // nominee details
  nomineeSalutation: yup.object({
    name: yup.string(),
    code: yup.string(),
    // .required('Nominee Salutation is required!'),
  }),
  // yup.string().required('Nominee Salutation is required!'),
  nomineeFullName: yup.string(),
  // .required(' Nominee Full Name is required!')
  // .min(3, 'Min length is 3 letters')
  // .test('len', 'Min length is 3 letters', (text) => {
  //   if (text?.trim() == '') {
  //     return false
  //   }
  //   if (text!.trim()!.length < 3) {
  //     return false
  //   }
  //   return true
  // })
  nomineeRelationship: yup.object({
    name: yup.string(),
    code: yup.string(),
    // .required('Nominee Relationship is required!'),
  }),
  nomineeDob: yup.string(),
  // .required('Nominee Date Of Birth is required!'),
  nomineeGender: yup.string(),
  // .required(' Nominee Gender is required!'),
  nomineeAddress: yup.string(),
  // .required(' Nominee Address is required!'),
  nomineeMobile: yup.string().test('custom', 'Invalid Format!', (text) => {
    if (text && text.trim() != '') {
      if (text.match(mobileNumRegex)) {
        return true
      } else {
        return false
      }
    }
    return true
  }),
  // matches(mobileNumRegex, 'Invalid Format!'),
  // .required(' Nominee Mobile is required!'),
  nomineeMaritalStatus: yup.object({
    name: yup.string(),
    code: yup.string(),
    // .required(' Nominee Marital Status is required!'),
  }),
  nomineeOccupation: yup.object({
    name: yup.string(),
    code: yup.string(),
    // .required(' Nominee Occupation is required!'),
  }),
})

const chakraStyles: ChakraStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    fontSize: '14px',
    paddingX: '4px',
    width: '12',
  }),
  dropdownIndicator: (provided, state) => ({
    marginX: '',
  }),
  inputContainer: (provided, state) => ({
    boxSize: '3',
  }),
}

export const SelfData = ({
  onSubmit,
  code,
  memberData,
  isSelf,
}: {
  onSubmit: (memberCode: string, memberData: MemberData, isSelf: boolean) => void
  code?: string
  memberData: MemberData
  isSelf: boolean
}) => {
  //  ------------------------------ Hooks ----------------------------------------

  const { isDesktop } = useDeviceType()
  const { selectedQuotes } = useAppSelector((state) => state.cart)

  const dispatch = useAppDispatch()
  const { isProposerIncluded } = useAppSelector((state) => state.memberDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const { memberDetails, proposer } = useAppSelector((state) => state.memberDetails)

  const cart_details = useAppSelector((state) => state.cart)
  const { proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)

  const getDobFormat = (date: string) => {
    if (date.includes('-')) {
      return date.split('-').reverse().join('/')
    }
    return date
  }

  //  ------------------------------ State ----------------------------------------

  const [dob, setDob] = useState(memberData.dob ? getDobFormat(memberData.dob) : '')
  const [isBMIAccepted, setIsBMIAccepted] = useState(false)

  const [nomineeDob, setNomineeDob] = useState(
    memberData.nominee_details.dob ? memberData.nominee_details.dob : '',
  )
  const [pincode, setPincode] = useState<IHealthPincode>(memberData.pincode_map)
  const [parmanentPincode, setParmanentPincode] = useState<IHealthPincode>(
    memberData.permanent_address_pincode_map,
  )
  const [IsPermanentAddressSameAsCurrentAddress, setIsPermanentAddressSameAsCurrentAddress] =
    useState(false)

  //  ------------------------------ API calls ----------------------------------------

  const { data: SalutationData } = useGetMiscellaneousListingQuery({
    is_salutation: '1',
    insurance_id: memberData.selectedInsuranceNumber!,
  })
  const { data: OccupationData } = useGetMiscellaneousListingQuery({
    is_occupation: '1',
    insurance_id: memberData.selectedInsuranceNumber!,
  })
  const { data: NomineeRelationshipData } = useGetMiscellaneousListingQuery({
    is_nominee_relationship: '1',
    insurance_id: memberData.selectedInsuranceNumber!,
  })
  const { data: MaritalData } = useGetMiscellaneousListingQuery({
    is_marital_status: '1',
    insurance_id: memberData.selectedInsuranceNumber!,
  })
  const { data: EducationQualificationData } = useGetMiscellaneousListingQuery({
    is_education_qualification: '1',
    insurance_id: memberData.selectedInsuranceNumber!,
  })

  const [getPincode] = useLazyGetPincodeListingQuery()
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()

  const [weight, setWeight] = useState(memberData.weight)
  const [height, setHeight] = useState(memberData.height)

  const [bmiCheck] = useLazyBmiCheckQuery()

  const [pincodeInput, setPincodeInput] = useState('')
  const [parmanentPincodeInput, setParmanentPincodeInput] = useState('')

  //  ------------------------------ Side Effects ----------------------------------------

  useEffect(() => {
    setValue('dob', dob)
  }, [dob])

  useEffect(() => {
    setValue('nomineeDob', nomineeDob)
  }, [nomineeDob])

  useEffect(() => {
    if (memberData.pincode?.toString().length === 6) {
      getPincode({
        pincode: memberData.pincode,
        insurance_id: memberData.selectedInsuranceNumber,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes && pincodes.length) {
          const selected = {
            ...pincodes[0],
            label: pincodes[0].pincode.toString(),
          }
          setPincode({
            ...selected,
          })

          setValue('pincode', selected.pincode)
          clearErrors('pincode')
        }
      })
    }
  }, [memberData.pincode])

  useEffect(() => {
    // console.log(memberData.permanentPincode, 'updated proposer pincode')
    if (memberData.permanentPincode?.toString().length === 6) {
      getPincode({
        pincode: memberData.permanentPincode,
        insurance_id: memberData.selectedInsuranceNumber,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes && pincodes.length) {
          const selected = {
            ...pincodes[0],
            label: pincodes[0].pincode.toString(),
          }
          console.log(selected, 'this should be present ')
          setParmanentPincode({
            ...selected,
          })
          setValue('parmanent_pincode', selected.pincode)
          clearErrors('parmanent_pincode')
        }
      })
    }
  }, [memberData.permanentPincode])

  useEffect(() => {
    if (height.length >= 2 && weight.length >= 1) {
      bmiCheck({
        height: parseInt(height),
        weight: parseInt(weight),
        age: parseInt(memberData.age),
        insurance_name: selectedQuotes[memberData.selectedQuoteNumber!].insuranceName,
      })
        .unwrap()
        .then((res) => {
          console.log(res, 'response for bmi check')
          if (res.bmi_acccepted) {
            setIsBMIAccepted(true)
            ToastService.showSuccessTaost({ title: 'BMI Range Accepted!', message: res.reason })
            clearErrors('height')
            clearErrors('weight')
          } else {
            setIsBMIAccepted(false)
            ToastService.showErrorToast({
              title: 'BMI Range Rejected!',
              message: res.reason,
            })
            setError('height', { type: 'custom', message: 'Invalid BMI Range' })
            setError('weight', { type: 'custom', message: 'Invalid BMI Range' })
          }
        })
        .catch((err) => {
          console.log(err, 'error in bmi check')
          ToastService.showErrorToast({
            title: 'Something went wrong',
          })
        })
    }
  }, [weight, height])
  // useEffect(()=>{
  //   if(selectedQuotes[memberData.selectedQuoteNumber!].premium &&
  //     selectedQuotes[memberData.selectedQuoteNumber!].premium > 50000) {
  //     setValue('showPanNumber',true)

  //                   }
  // },[])

  //  ------------------------------ Functions ------------------------------

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',

      lead_data: {
        cart_details: cart_details,
        member_details: memberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('proposer-insurer-details', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  const handlePincodeSelectChange = (selectedPincode: SingleValue<IHealthPincode>) => {
    if (selectedPincode?.pincode) {
      const selected = {
        ...selectedPincode,
        label: selectedPincode.pincode.toString(),
      }
      setPincode(selected)
      setValue('pincode', selected.pincode)
      clearErrors('pincode')
    }
  }

  const handlePermanentPincodeSelectChange = (selectedPincode: SingleValue<IHealthPincode>) => {
    if (selectedPincode?.pincode) {
      const selected = {
        ...selectedPincode,
        label: selectedPincode.pincode.toString(),
      }
      setParmanentPincode(selected)
      setValue('parmanent_pincode', selected.pincode)
      clearErrors('parmanent_pincode')
    }
  }

  const loadPincodeOptions = (
    inputSearchValue: string,
    callback: (options: IHealthPincodeOption[]) => void,
  ) => {
    if (inputSearchValue.length > 2 && inputSearchValue.length < 7) {
      getPincode({
        pincode: inputSearchValue,
        insurance_id: memberData.selectedInsuranceNumber,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes) callback(pincodeLabelFormatterHealth(pincodes))
      })
    }
  }

  const onFormError = (errors: any) => {
    console.log(errors)
  }

  const onFormSubmit = (formData: any) => {
    if (!isBMIAccepted) {
      setError('height', { type: 'custom', message: 'Invalid BMI Range' })
      setError('weight', { type: 'custom', message: 'Invalid BMI Range' })
      return
    }
    const nomineeName = formData.nomineeFullName.trim().split(' ')
    const nomineeFirstName = nomineeName[0]
    const nomineeLastNameWithJoin = nomineeName.slice(1).join(' ')
    const nomineeLastname = nomineeLastNameWithJoin

    if (Object.keys(errors).length) {
      return
    }

    onSubmit(
      code!,
      {
        ...memberData,
        firstname: formData.firstName,
        lastname: formData.lastName,
        middlename: formData.middleName,
        pincode: pincode?.pincode,
        permanentPincode: parmanentPincode?.pincode,
        pincode_map: pincode!,
        permanent_address: formData.permanent_address,
        permanent_address_pincode_map: parmanentPincode!,
        pan_number: formData.pan_number,
        aadhar_card_number: formData.aadhar_number,
        annual_income: formData.annual_income,

        nominee_details: {
          salutation: formData.nomineeSalutation,
          firstname: nomineeFirstName,
          middlename: '',
          lastname: nomineeLastname ? nomineeLastname : '.',
          dob: formData.nomineeDob,
          gender: formData.nomineeGender,
          relationship: formData.nomineeRelationship,
          mobile: formData.nomineeMobile,
          occupation: formData.nomineeOccupation,
          marital_status: formData.nomineeMaritalStatus,
          address: formData.nomineeAddress,
          pincode_map: pincode!,
        },
        salutation: formData.salutation,

        height: formData.height,

        educational_qualification: formData.educational_qualification,

        weight: formData.weight,
        bmi: calculateBMI(formData.height, formData.weight).toString(),
        mobile: formData.mobile,
        dob: formData.dob,
        marital_status: formData.maritalStatus,
        occupation: formData.occupation,
        current_address: formData.current_address,
        email: formData.email,
        nationality: 'Indian',
      },
      isSelf,
    )
    console.log('lead saved')

    createLead()
  }

  useEffect(() => {
    createLead()
  }, [])

  // ------------------------------   Schemas ------------------------------------------------------------
  {
    console.log(
      memberData.nominee_details.firstname + ' ' + memberData.nominee_details.lastname,
      'nominee details',
    )
  }
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(SelfDataSchema),
    defaultValues: {
      firstName: memberData.firstname ?? '',
      middleName: memberData.middlename ?? '',
      lastName: memberData.lastname ?? '',
      pincode: memberData.pincode_map.pincode,
      parmanent_pincode: memberData.permanent_address_pincode_map.pincode,
      occupation: memberData.occupation,
      current_address: memberData.current_address ?? '',
      permanent_address: memberData.permanent_address ?? '',
      salutation: memberData.salutation,
      maritalStatus: memberData.marital_status,
      height: memberData.height ?? '',
      weight: memberData.weight ?? '',
      dob: getDobFormat(memberData.dob) ?? '',
      mobile: memberData.mobile ?? '',
      email: memberData.email ?? '',
      pan_number: memberData.pan_number ?? '',
      aadhar_number: memberData.aadhar_card_number ?? '',
      annual_income: memberData.annual_income ?? '',

      educational_qualification: memberData.educational_qualification,
      // nominee_details
      nomineeFullName:
        memberData.nominee_details.firstname && memberData.nominee_details.lastname
          ? memberData.nominee_details.firstname + ' ' + memberData.nominee_details.lastname
          : '',
      nomineeRelationship: memberData.nominee_details.relationship,
      nomineeDob: memberData.nominee_details.dob ?? '',

      nomineeGender: memberData.nominee_details.gender ?? '',
      nomineeMobile: memberData.nominee_details.mobile ?? '',
      nomineeSalutation: memberData.nominee_details.salutation,
      nomineeAddress: memberData.nominee_details.address ?? '',
      nomineeMaritalStatus: memberData.nominee_details.marital_status,
      nomineeOccupation: memberData.nominee_details.occupation,

      showPanNumber:
        memberData.member_type === 'proposer' &&
        selectedQuotes[memberData.selectedQuoteNumber!].premium &&
        selectedQuotes[memberData.selectedQuoteNumber!].premium > 50000
          ? true
          : false,
      // show_aadhar_number: selectedQuotes[memberData.selectedQuoteNumber!].premium &&
      //   selectedQuotes[memberData.selectedQuoteNumber!].premium > 50000 ? true : false
      show_aadhar_number: false,
    },
  })

  const watchPincode = watch('pincode', '')
  const watchCurrentAddress = watch('current_address', '')
  return (
    <>
      <Box h={isDesktop ? '' : '500px'} overflow='scroll'>
        <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
          <Grid templateColumns={isDesktop ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'} gap={4} w='full'>
            <GridItem>
              <Box>
                <Text> Personal Details</Text>

                <Box
                  maxH={isDesktop ? ' 465px' : ''}
                  overflow={isDesktop ? 'scroll' : ''}
                  position='relative'
                >
                  <Flex>
                    <Box marginY='2' w='full'>
                      <FormControl isInvalid={errors.firstName ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          First Name <span className=' text-red-500'> *</span>
                        </FormLabel>
                        <Flex direction={isDesktop ? 'row' : 'column'} gap={isDesktop ? '0' : '2'}>
                          <Box w='50%'>
                            <ChakraSelect
                              {...register('salutation')}
                              maxMenuHeight={200}
                              isDisabled={isSelf}
                              placeholder='Select'
                              useBasicStyles
                              // chakraStyles={chakraStyles}
                              isSearchable={false}
                              options={getArrayFromObj(SalutationData?.salutations)?.map((item) => {
                                return {
                                  label: item.name.toString(),
                                  value: item.code.toString(),
                                }
                              })}
                              defaultValue={{
                                label: memberData.salutation?.name,
                                value: memberData.salutation?.code,
                              }}
                              onChange={(item: any) => {
                                setValue('salutation', { name: item!.label, code: item!.value })
                                clearErrors('salutation')
                              }}
                            />
                          </Box>
                          <TextInput
                            isDisabled={isSelf}
                            placeholder='Enter first name'
                            {...register('firstName')}
                            onKeyDown={onlyAllowTypingAlphabet}
                          />
                        </Flex>
                        {errors.firstName && (
                          <FormErrorMessage>
                            {errors.firstName?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Flex>
                  <Flex w='full' marginY='2' gap='2' direction={isDesktop ? 'row' : 'column'}>
                    <Box>
                      <FormControl>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Middle Name
                        </FormLabel>
                        <TextInput
                          isDisabled={isSelf}
                          placeholder='Enter Middle Name'
                          {...register('middleName')}
                          onKeyDown={onlyAllowTypingAlphabet}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors.lastName ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Last Name <span className=' text-red-500'> *</span>
                        </FormLabel>

                        <TextInput
                          placeholder='Enter Last Name'
                          {...register('lastName')}
                          isDisabled={isSelf}
                          onKeyDown={onlyAllowTypingAlphabet}
                        />
                        {errors.lastName && (
                          <FormErrorMessage>
                            {errors.lastName?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Flex>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.dob ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Date Of Birth <span className=' text-red-500'> *</span>
                      </FormLabel>

                      <BimabossDatePicker
                        clearErrors={() => clearErrors('dob')}
                        inputDate={dob}
                        isNonEditable={isSelf}
                        isDisabled={isSelf}
                        setInputDate={setDob}
                        minDate={subYears(new Date(), parseInt(memberData.age) + 1)}
                        maxDate={subYears(new Date(), parseInt(memberData.age))}
                        {...register('dob', { required: true })}
                        isRequired
                      />
                      {errors.dob && (
                        <FormErrorMessage>{errors.dob?.message?.toString()}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.occupation ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Occupation <span className=' text-red-500'> *</span>
                      </FormLabel>

                      <ChakraSelect
                        {...register('occupation')}
                        isDisabled={isSelf}
                        maxMenuHeight={200}
                        isInvalid={errors.occupation ? true : false}
                        placeholder='Select'
                        useBasicStyles
                        // chakraStyles={chakraStyles}
                        // isSearchable={false}
                        defaultValue={{
                          label: memberData.occupation?.name,
                          value: memberData.occupation?.code,
                        }}
                        options={getArrayFromObj(OccupationData?.occupations)?.map((item) => {
                          return {
                            label: item.name.toString(),
                            value: item.code.toString(),
                          }
                        })}
                        onChange={(item: any) => {
                          setValue('occupation', { name: item!.label, code: item!.value })
                          clearErrors('occupation')
                        }}
                      />
                      {errors.occupation && (
                        <FormErrorMessage>
                          {errors.occupation.code?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Flex direction={isDesktop ? 'row' : 'column'} w='full' marginY='2' gap='2'>
                    <Box>
                      <FormControl isInvalid={errors.height ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Height (cm.) <span className=' text-red-500'> *</span>
                        </FormLabel>
                        <TextInput
                          onKeyDown={onlyAllowTypingNumbers}
                          maxLength={3}
                          minLength={2}
                          placeholder='Enter Height in ft.'
                          {...register('height')}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const height = e.target.value
                            setHeight(height)
                            setValue('height', height)
                          }}
                        />
                        {errors.height && (
                          <FormErrorMessage>{errors.height?.message?.toString()}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors.weight ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Weight (kg.) <span className=' text-red-500'> *</span>
                        </FormLabel>

                        <TextInput
                          onKeyDown={onlyAllowTypingNumbers}
                          maxLength={3}
                          minLength={2}
                          placeholder='Enter Weight'
                          {...register('weight')}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const weight = e.target.value
                            setWeight(weight)
                            setValue('weight', weight)
                          }}
                        />
                        {errors.weight && (
                          <FormErrorMessage>{errors.weight?.message?.toString()}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Flex>
                  <Box marginY='2' w='full'>
                    <FormControl isInvalid={errors.mobile ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Mobile Number <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <TextInput
                        placeholder='+91'
                        {...register('mobile')}
                        onKeyDown={onlyAllowTypingNumbers}
                        isDisabled={isSelf}
                        maxLength={10}
                      />
                      {errors.mobile && (
                        <FormErrorMessage>{errors.mobile?.message?.toString()}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2' w='full'>
                    <FormControl isInvalid={errors.email ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Email <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <TextInput
                        placeholder='Enter email'
                        isDisabled={isSelf}
                        {...register('email')}
                      />
                      {errors.email && (
                        <FormErrorMessage>{errors.email?.message?.toString()}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2' w='full'>
                    <FormControl isInvalid={errors.maritalStatus ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Marital Status <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <Box width={'100%'}>
                        <ChakraSelect
                          {...register('maritalStatus')}
                          maxMenuHeight={400}
                          isDisabled={isSelf}
                          placeholder='Select'
                          useBasicStyles
                          // chakraStyles={chakraStyles}
                          isSearchable={false}
                          defaultValue={{
                            label: memberData.marital_status?.name,
                            value: memberData.marital_status?.code,
                          }}
                          options={getArrayFromObj(MaritalData?.marital_status)?.map((item) => {
                            return {
                              label: item.name.toString(),
                              value: item.code.toString(),
                            }
                          })}
                          onChange={(item: any) => {
                            setValue('maritalStatus', { name: item!.label, code: item!.value })
                            clearErrors('maritalStatus')
                          }}
                        />
                      </Box>
                      {errors.maritalStatus && (
                        <FormErrorMessage>
                          {errors.maritalStatus?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box>
                <Text> Residential Details </Text>
                <Box
                  maxH={isDesktop ? '  465px' : ''}
                  overflow={isDesktop ? 'scroll' : ''}
                  position='relative'
                >
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.pincode ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Pin Code <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <AsyncSelect
                        className='w-full'
                        placeholder='Select Pincode'
                        onInputChange={(input) => {
                          if (input.length < 7) setPincodeInput(input)
                        }}
                        inputValue={pincodeInput}
                        {...register('pincode')}
                        onKeyDown={onlyAllowTypingNumbers}
                        loadOptions={loadPincodeOptions}
                        onChange={handlePincodeSelectChange}
                        value={pincode}
                        defaultInputValue={pincode?.pincode.toString()}
                        isDisabled={
                          isSelf && equitasDetails.fieldsFetchedFromCbsMapping.customerPincode
                        }
                      />
                      {errors.pincode && (
                        <FormErrorMessage>{errors.pincode?.message?.toString()}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.current_address ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Current Address <span className=' text-red-500'> *</span>
                      </FormLabel>
                      <Textarea
                        placeholder='Enter Current Address'
                        {...register('current_address')}
                        isDisabled={
                          isSelf && !!proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.crntAddrLn1
                        }
                      />
                      {errors.current_address && (
                        <FormErrorMessage>
                          {errors.current_address?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>

                  <Checkbox
                    name='check'
                    checked={true}
                    isDisabled={!watchPincode || !watchCurrentAddress}
                    colorScheme='primary'
                    onChange={(e) => {
                      console.log(e.target.checked)
                      if (e.target.checked) {
                        setValue('permanent_address', getValues('current_address'))
                        setParmanentPincode(pincode)
                        setValue('parmanent_pincode', getValues('pincode'))
                        setIsPermanentAddressSameAsCurrentAddress(true)
                      } else {
                        setIsPermanentAddressSameAsCurrentAddress(false)
                        clearErrors('permanent_address')
                        clearErrors('parmanent_pincode')
                      }
                    }}
                  >
                    <Text fontSize='sm'>Is Permanent Address same as Current Address?</Text>
                  </Checkbox>
                  {!IsPermanentAddressSameAsCurrentAddress && (
                    <Box marginY='2'>
                      <FormControl isInvalid={errors.parmanent_pincode ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Permanent Address Pin Code <span className=' text-red-500'> *</span>
                        </FormLabel>
                        <AsyncSelect
                          className='w-full'
                          placeholder='Select Pincode'
                          onInputChange={(input) => {
                            if (input.length < 7) setParmanentPincodeInput(input)
                          }}
                          inputValue={parmanentPincodeInput}
                          {...register('parmanent_pincode')}
                          loadOptions={loadPincodeOptions}
                          onKeyDown={onlyAllowTypingNumbers}
                          onChange={handlePermanentPincodeSelectChange}
                          value={parmanentPincode}
                          defaultInputValue={parmanentPincode?.pincode.toString()}
                        />
                        {errors.parmanent_pincode && (
                          <FormErrorMessage>
                            {errors.parmanent_pincode?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  )}
                  {!IsPermanentAddressSameAsCurrentAddress && (
                    <Box marginY='2'>
                      <FormControl isInvalid={errors.permanent_address ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Permanent Address <span className=' text-red-500'> *</span>
                        </FormLabel>
                        <Textarea
                          placeholder='Enter Address'
                          {...register('permanent_address')}
                          isDisabled={
                            isSelf &&
                            !!proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.prmntAddrLn1
                          }
                        />
                        {errors.permanent_address && (
                          <FormErrorMessage>
                            {errors.permanent_address?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  )}
                  {memberData.member_type !== 'son' &&
                  memberData.member_type !== 'daughter' &&
                  selectedQuotes[memberData.selectedQuoteNumber!].premium &&
                  selectedQuotes[memberData.selectedQuoteNumber!].premium > 50000 ? (
                    <Box marginY='2'>
                      <FormControl isInvalid={errors.pan_number ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Pan Card{' '}
                          {getValues('showPanNumber') && <span className=' text-red-500'> *</span>}
                        </FormLabel>

                        <TextInput
                          {...register('pan_number', { required: true })}
                          placeholder='Enter Pan Card'
                          maxLength={10}
                          isDisabled={isSelf}
                        />
                        {errors.pan_number && (
                          <FormErrorMessage>
                            {errors.pan_number?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* {selectedQuotes[memberData.selectedQuoteNumber!].premium &&
                    selectedQuotes[memberData.selectedQuoteNumber!].premium > 50000 ? (
                    <Box marginY='2'>
                      <FormControl isInvalid={errors.aadhar_number ? true : false}>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Aadhar Card Number/ EID Number <span className=' text-red-500'> *</span>
                        </FormLabel>

                        <TextInput
                          {...register('aadhar_number', { required: true })}
                          placeholder='Enter Aadhar Card Number/EID Number'
                          maxLength={14}
                        />
                        {errors.aadhar_number && (
                          <FormErrorMessage>
                            {errors.aadhar_number?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )} */}

                  <Box marginY='2'>
                    <FormControl isInvalid={errors.educational_qualification ? true : false}>
                      <Box marginY='2'>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Educational Qualification<span className=' text-red-500'> *</span>
                        </FormLabel>
                        <Box width={'100%'}>
                          <ChakraSelect
                            {...register('educational_qualification')}
                            maxMenuHeight={200}
                            isInvalid={errors.educational_qualification ? true : false}
                            placeholder='Select'
                            useBasicStyles
                            // chakraStyles={chakraStyles}
                            // isSearchable={false}
                            defaultValue={{
                              label: memberData.educational_qualification?.name,
                              value: memberData.educational_qualification?.code,
                            }}
                            options={getArrayFromObj(
                              EducationQualificationData?.education_qualifications,
                            )?.map((item) => {
                              return {
                                label: item.name.toString(),
                                value: item.code.toString(),
                              }
                            })}
                            onChange={(item: any) => {
                              setValue('educational_qualification', {
                                name: item!.label,
                                code: item!.value,
                              })
                              clearErrors('educational_qualification')
                            }}
                          />
                        </Box>
                        {errors.educational_qualification && (
                          <FormErrorMessage>
                            {errors.educational_qualification?.code?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </Box>
                    </FormControl>
                  </Box>

                  <Box marginY='2'>
                    <FormControl isInvalid={errors.annual_income ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Annual Income<span className=' text-red-500'> *</span>
                      </FormLabel>

                      <TextInput
                        {...register('annual_income')}
                        placeholder='Enter Annual Income'
                        onKeyDown={onlyAllowTypingNumbers}
                        maxLength={14}
                        // minLength={5}
                        // isDisabled={isSelf && !!proposerDetailsFromCBS!.CustmrFlds.CustmrFldsRp.grossAnnlIncm!}
                      />
                      {errors.annual_income && (
                        <FormErrorMessage>
                          {errors.annual_income?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box>
                <Text> Nominee Details {!isSelf && '(Optional)'}</Text>
                <Box
                  maxH={isDesktop ? '  465px' : ''}
                  overflow={isDesktop ? 'scroll' : ''}
                  position='relative'
                >
                  <Box marginY='2'>
                    <FormLabel fontSize='sm' fontWeight='medium'>
                      Nominee Full Name
                      {/* <span className=' text-red-500'> *</span> */}
                    </FormLabel>
                    <Flex direction={isDesktop ? 'row' : 'column'} gap={isDesktop ? '0' : '2'}>
                      <Box width={isDesktop ? '40%' : '60%'}>
                        <FormControl isInvalid={errors.nomineeSalutation ? true : false}>
                          <ChakraSelect
                            {...register('nomineeSalutation')}
                            maxMenuHeight={200}
                            placeholder='Select'
                            useBasicStyles
                            isDisabled={isSelf}
                            // chakraStyles={chakraStyles}
                            isSearchable={false}
                            defaultValue={{
                              label: memberData.nominee_details.salutation?.name,
                              value: memberData.nominee_details.salutation?.code,
                            }}
                            options={getArrayFromObj(SalutationData?.salutations)?.map((item) => {
                              return {
                                label: item.name.toString(),
                                value: item.code.toString(),
                              }
                            })}
                            onChange={(item: any) => {
                              if (getValues('nomineeGender')) {
                                if (getMasterSalutaionFromInsurer(item.label) === 'OTHER') {
                                  setValue('nomineeSalutation', {
                                    name: item!.label,
                                    code: item!.value,
                                  })
                                  clearErrors('nomineeSalutation')
                                  clearErrors('nomineeGender')
                                } else if (
                                  getMasterSalutaionFromInsurer(item.label) ===
                                  getValues('nomineeGender').toUpperCase()
                                ) {
                                  setValue('nomineeSalutation', {
                                    name: item!.label,
                                    code: item!.value,
                                  })
                                  clearErrors('nomineeSalutation')
                                  clearErrors('nomineeGender')
                                } else {
                                  setValue('nomineeSalutation', {
                                    name: item!.label,
                                    code: item!.value,
                                  })
                                  setError('nomineeSalutation', {
                                    type: 'custom',
                                    message: 'Not matching with gender',
                                  })
                                }
                              } else {
                                setValue('nomineeSalutation', {
                                  name: item!.label,
                                  code: item!.value,
                                })
                                clearErrors('nomineeSalutation')
                                clearErrors('nomineeGender')
                              }
                            }}
                          />
                          {errors.nomineeSalutation && (
                            <FormErrorMessage>
                              {errors.nomineeSalutation?.message?.toString()}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <FormControl isInvalid={errors.nomineeFullName ? true : false}>
                        <TextInput
                          placeholder='Enter Nominee Name'
                          isDisabled={isSelf}
                          {...register('nomineeFullName')}
                          onKeyDown={onlyAllowTypingAlphabet}
                        />
                        {errors.nomineeFullName && (
                          <FormErrorMessage>
                            {errors.nomineeFullName?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeDob ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Date of Birth
                        {/* <span className=' text-red-500'> *</span> */}
                      </FormLabel>
                      <BimabossDatePicker
                        clearErrors={() => clearErrors('nomineeDob')}
                        isNonEditable={isSelf}
                        isDisabled={isSelf}
                        inputDate={nomineeDob}
                        setInputDate={setNomineeDob}
                        maxDate={subYears(new Date(), 18)}
                        {...register('nomineeDob', { required: true })}
                      />
                      {errors.nomineeDob && (
                        <FormErrorMessage>
                          {errors.nomineeDob?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeGender ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Gender
                        {/* <span className=' text-red-500'> *</span> */}
                      </FormLabel>
                      <ChakraSelect
                        {...register('nomineeGender')}
                        isInvalid={errors.nomineeGender ? true : false}
                        placeholder='Select'
                        isDisabled={isSelf}
                        useBasicStyles
                        chakraStyles={chakraStyles}
                        defaultValue={{
                          label: memberData.nominee_details.gender,
                          value: memberData.nominee_details.gender,
                        }}
                        isSearchable={false}
                        options={[
                          {
                            label: 'Male',
                            value: 'Male',
                          },
                          {
                            label: 'Female',
                            value: 'Female',
                          },
                        ]}
                        onChange={(item: any) => {
                          console.log(getValues('nomineeSalutation'), 'check meee ')
                          if (getValues('nomineeSalutation').code) {
                            if (
                              getMasterSalutaionFromInsurer(getValues('nomineeSalutation').name) ===
                              'OTHER'
                            ) {
                              setValue('nomineeGender', item!.value)
                              clearErrors('nomineeGender')
                              clearErrors('nomineeSalutation')
                              console.log('item for other', item)
                              return
                            }
                            if (
                              getMasterSalutaionFromInsurer(getValues('nomineeSalutation').name) ===
                              item!.value.toUpperCase()
                            ) {
                              setValue('nomineeGender', item!.value)
                              clearErrors('nomineeGender')
                              clearErrors('nomineeSalutation')
                              console.log('item', item)
                            } else {
                              setValue('nomineeGender', item!.value)
                              setError('nomineeGender', {
                                type: 'custom',
                                message: 'Not matchting with salutation',
                              })
                            }
                          } else {
                            setValue('nomineeGender', item!.value)
                            clearErrors('nomineeGender')
                            clearErrors('nomineeSalutation')
                          }
                        }}
                      />
                      {errors.nomineeGender && (
                        <FormErrorMessage>
                          {errors.nomineeGender?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeMobile ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Mobile Number
                        {/* <span className=' text-red-500'> *</span> */}
                      </FormLabel>

                      <TextInput
                        placeholder='Enter Nominee Mobile Number'
                        {...register('nomineeMobile')}
                        onKeyDown={onlyAllowTypingNumbers}
                        isDisabled={isSelf}
                        maxLength={10}
                      />
                      {errors.nomineeMobile && (
                        <FormErrorMessage>
                          {errors.nomineeMobile?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeOccupation ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Occupation
                        {/* <span className=' text-red-500'> *</span> */}
                      </FormLabel>

                      <ChakraSelect
                        {...register('nomineeOccupation')}
                        isInvalid={errors.nomineeOccupation ? true : false}
                        placeholder='Select'
                        useBasicStyles
                        // chakraStyles={chakraStyles}
                        maxMenuHeight={200}
                        // isSearchable={false}
                        isDisabled={isSelf}
                        defaultValue={{
                          label: memberData.nominee_details.occupation?.name,
                          value: memberData.nominee_details.occupation?.code,
                        }}
                        options={getArrayFromObj(OccupationData?.occupations)?.map((item) => {
                          return {
                            label: item.name.toString(),
                            value: item.code.toString(),
                          }
                        })}
                        onChange={(item: any) => {
                          setValue('nomineeOccupation', { name: item!.label, code: item!.value })
                          clearErrors('nomineeOccupation')
                        }}
                      />
                      {errors.nomineeOccupation && (
                        <FormErrorMessage>
                          {errors.nomineeOccupation.code?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeMaritalStatus ? true : false}>
                      <Box marginY='2'>
                        <FormLabel fontSize='sm' fontWeight='medium'>
                          Nominee Marital Status
                          {/* <span className=' text-red-500'> *</span> */}
                        </FormLabel>
                        <Box width={'100%'}>
                          <ChakraSelect
                            {...register('nomineeMaritalStatus')}
                            maxMenuHeight={200}
                            isDisabled={isSelf}
                            isInvalid={errors.nomineeMaritalStatus ? true : false}
                            placeholder='Select'
                            useBasicStyles
                            // chakraStyles={chakraStyles}
                            isSearchable={false}
                            defaultValue={{
                              label: memberData.nominee_details.marital_status?.name,
                              value: memberData.nominee_details.marital_status?.code,
                            }}
                            options={getArrayFromObj(MaritalData?.marital_status)?.map((item) => {
                              return {
                                label: item.name.toString(),
                                value: item.code.toString(),
                              }
                            })}
                            onChange={(item: any) => {
                              setValue('nomineeMaritalStatus', {
                                name: item!.label,
                                code: item!.value,
                              })
                              clearErrors('nomineeMaritalStatus')
                            }}
                          />
                        </Box>
                        {errors.nomineeMaritalStatus && (
                          <FormErrorMessage>
                            {errors.nomineeMaritalStatus.code?.message?.toString()}
                          </FormErrorMessage>
                        )}
                      </Box>
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeRelationship ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Relationship
                        {/* <span className=' text-red-500'> *</span> */}
                      </FormLabel>
                      <ChakraSelect
                        {...register('nomineeRelationship')}
                        isInvalid={errors.nomineeRelationship ? true : false}
                        placeholder='Select'
                        useBasicStyles
                        isDisabled={isSelf}
                        // chakraStyles={chakraStyles}
                        isSearchable={false}
                        defaultValue={{
                          label: memberData.nominee_details.relationship?.name,
                          value: memberData.nominee_details.relationship?.code,
                        }}
                        options={getArrayFromObj(
                          NomineeRelationshipData?.nominee_relationship,
                        )?.map((item) => {
                          return {
                            label: item.name.toString(),
                            value: item.code.toString(),
                          }
                        })}
                        onChange={(item: any) => {
                          setValue('nomineeRelationship', { name: item!.label, code: item!.value })
                          clearErrors('nomineeRelationship')
                        }}
                      />
                      {errors.nomineeRelationship && (
                        <FormErrorMessage>
                          {errors.nomineeRelationship.code?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box marginY='2'>
                    <FormControl isInvalid={errors.nomineeAddress ? true : false}>
                      <FormLabel fontSize='sm' fontWeight='medium'>
                        Nominee Address
                        {/* <span className=' text-red-500'> *</span> */}
                      </FormLabel>
                      <Textarea
                        placeholder='Enter Address'
                        {...register('nomineeAddress')}
                        isDisabled={isSelf}
                      />
                      {errors.nomineeAddress && (
                        <FormErrorMessage>
                          {errors.nomineeAddress?.message?.toString()}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </Grid>
          <Grid templateColumns='repeat(1, 1fr)'>
            <Box marginY='2'>
              <Flex justifyContent='center' marginY=''>
                <Button
                  variant='solid'
                  rounded='full'
                  backgroundColor='primary'
                  paddingX='14'
                  paddingY='4'
                  type='submit'
                >
                  Next
                </Button>
              </Flex>
            </Box>
          </Grid>
        </form>
      </Box>
    </>
  )
}

const getMasterSalutaionFromInsurer = (insuranceSalutation: string) => {
  console.log(insuranceSalutation)
  if (['mr', 'mr.', 'master', 'mister'].includes(insuranceSalutation.toLowerCase())) {
    return 'MALE'
  }
  if (
    ['mrs', 'ms', 'ms.', 'mrs.', 'miss', 'miss.', 'm/s'].includes(insuranceSalutation.toLowerCase())
  ) {
    return 'FEMALE'
  } else {
    return 'OTHER'
  }
}
