import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setApplicantFormDetails } from 'features/Sachets'
import { useLazyGetDistrictValQuery, useLazyGetGeoDataQuery } from 'Services/API/motor.api'
import { getMasterPolicyNumber, getStateName, onlyNumbersRegex } from 'Constants'
import {
  onlyAllowTypingAlphabet,
  onlyAllowTypingAlphabetNumeric,
  onlyAllowTypingAlphabetNumericAndAt,
  onlyAllowTypingAlphabetNumericAndSpace,
  onlyAllowTypingNumbers,
  onlyAllowTypingNumbers2,
  decryptData,
} from 'Services/general'
import { calcAge18, isMoreThan18 } from './SachetsNomineeForm'
import { ToastService } from 'Services/toast.service'
import { ISelectedProductState } from 'features/Sachets/sachetData.slice'
import SachetsApplicantFormSpouse from './SachetsApplicantFormSpouse'
import { useLazyGetPincodeListingQuery } from 'Services/API/health.api'

export interface IApplicantForm {
  firstName: string
  middleName: string
  lastName: string
  email: string
  pinCode: string
  policyNumber: string
  dob: Date | string
  address1: string
  city: string
  district: string
  aadhaar: string
  gender: string
  address2?: string
  address3?: string
  state: string
  salutation: string
  // ifscCode?: string
  occupation: string
  contactNumber: string
  relationship: string
  pan?: string
}

const occupationList = {
  '1': 'Salaried',
  '2': 'Self Employed',
  '3': 'Student',
  '4': 'HouseWife',
  '5': 'Others',
  '6': 'Retired',
}

const ApplicantSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  middleName: yup.string(),
  email: yup.string().email('Invalid email format').required('Email is required'),
  pinCode: yup
    .string()
    .required('Pin Code is required')
    .matches(/^\d{6}$/, 'Code must be exactly 6 digits'),
  policyNumber: yup.string().required('Master Policy Number is required'),
  dob: yup.date().required('Date of Birth is required'),
  address1: yup.string().required('Address Line 1 is required'),
  city: yup.string().required('City is required'),
  district: yup.string().required('District is required'),
  // aadhaar: yup
  //   .string()
  //   .required('Aadhaar number is required')
  //   .matches(/^\d{12}$/, 'Aadhar Number must be exactly 12 digits'),
  gender: yup.string().required('Gender is required'),
  address2: yup.string(),
  address3: yup.string(),
  // ifscCode: yup.string(),
  state: yup.string().required('State is required'),
  salutation: yup.string().required('Salutation is required'),
  occupation: yup.string().required('Occupation is required'),
  contactNumber: yup
    .string()
    .required('Contact Number is required')
    .matches(/^\d{10}$/, 'Contact Number must be exactly 10 digits'),
})

const SachetsApplicantForm = ({
  selectedProductData,
  setIndex,
  setApplcantAddress,
  otherCustomerDetails,
  fullCustomerDetails,
  setFormAccordianState,
  setIsProceed,
  index,
}: {
  selectedProductData: ISelectedProductState
  index: number
  setIndex: Dispatch<SetStateAction<number>>
  setApplcantAddress: Dispatch<
    SetStateAction<{ address1: string; address2: string; address3: string }>
  >
  selectedAccount: any
  otherCustomerDetails: any
  fullCustomerDetails: any
  setFormAccordianState: any
  setIsProceed: any
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IApplicantForm>({
    resolver: yupResolver(ApplicantSchema),
  })

  const fullUserDataFromSlice = useAppSelector((state) => state?.sachetDataDetails?.fullUserData)
  const { quoteId } = useAppSelector((state) => state.sachetDataDetails)
  const [getCity] = useLazyGetGeoDataQuery()
  const [getDistrict] = useLazyGetDistrictValQuery()
  const customerProposerDetails = useAppSelector((state) => state.customerDetails)
  const [cityName, setCityName] = useState('Fetching City...')
  const [districtName, setDistrictName] = useState('Fetching District...')
  const [stateName, setStateName] = useState('')

  // Getting state and city name from pincode, health API
  const [getPincode] = useLazyGetPincodeListingQuery()

  useEffect(() => {
    // console.log('first useEffect start')

    // remove the below code
    if (!customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.prmntPncd) {
      setFormData((formData) => {
        return { ...formData, city: '', state: '' }
      })

      setCityName('')
      setStateName('')
    }

    // fetching pincode and then state and city name
    if (
      customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.prmntPncd &&
      customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.prmntPncd.length ===
        6
    ) {
      const proposerPincode =
        customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.prmntPncd
      getPincode({
        pincode: proposerPincode,
      })
        .then((resp) => {
          const pincodes = resp.data?.result
          if (pincodes && pincodes.length) {
            // console.log('pincodes state_name: ', pincodes[0].state_name)
            // console.log('pincodes city_name: ', pincodes[0].city_name)

            if (pincodes[0].city_name) {
              setCityName(pincodes[0].city_name)
              setFormData((formData) => {
                return { ...formData, city: pincodes[0].city_name }
              })
            }
            if (pincodes[0].state_name) {
              setStateName(pincodes[0].state_name)
              setFormData((formData) => {
                return { ...formData, state: pincodes[0].state_name }
              })
              // setFormData((formData) => {
              //   return { ...formData, district: response.data }
              // })
            }
            // setProposerPincode({
            //   value: `${pincodes[0].area_name}(${pincodes[0].pincode})`,
            //   label: pincodes[0].pincode.toString(),
            //   ...pincodes[0],
            // })
          }
        })
        .catch((error) => {
          // console.log('error: ', error)
          setFormData((formData) => {
            return { ...formData, city: '', state: '' }
          })

          setCityName('')
          setStateName('')
        })
    } else {
      setFormData((formData) => {
        return { ...formData, city: '', state: '' }
      })

      setCityName('')
      setStateName('')
    }

    // fetching district name

    if (customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntDstrct) {
      getDistrict({
        district_id: parseInt(
          customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntDstrct,
        ),
      })
        .unwrap()
        .then((response) => {
          if (response && response.data !== '') {
            setDistrictName(response.data)
            setFormData((formData) => {
              return { ...formData, district: response.data }
            })
          } else {
            //* undo this change
            if (response && response.data == '') {
              setFormData((formData) => {
                return { ...formData, district: '' }
              })
            }

            setDistrictName('')
          }
          // if (response && response.data !== '') {
          //   console.log('response.data: ', response.data)
          //   setDistrictName(response.data)
          //   setFormData((formData) => {
          //     return { ...formData, district: response.data }
          //   })
          // } else {
          //   console.log('here: ', cityVal)

          //   setDistrictName(cityVal)
          // }
        })
        .catch((error) => {
          setDistrictName('')
        })
    } else {
      setDistrictName('')
    }

    // console.log('first useEffect end')
  }, [])

  // useEffect(() => {
  //   if (customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntCty) {
  //     getCity({
  //       city_id: parseInt(
  //         customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntCty,
  //       ),
  //     })
  //       .unwrap()
  //       .then((response) => {
  //         setCityName(response.data)
  //         // cityVal = response.data
  //         setFormData((formData) => {
  //           return { ...formData, city: response.data }
  //         })
  //       })
  //   }
  //   if (customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntSt) {
  //     const stateCode =
  //       customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntSt
  //     // console.log(
  //     //   'stateCode sachetApplicantForm: ',
  //     //   customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp,
  //     // )
  //     // console.log('state sachetApplicantForm: ', stateCode)
  //     if (getStateName[stateCode as string] as string) {
  //       setStateName(getStateName[stateCode as string] as string)
  //     } else {
  //       setStateName('')
  //       // Undo the change
  //       // setStateName('Hardcoded state value')
  //     }
  //   }
  //   if (customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntDstrct) {
  //     getDistrict({
  //       district_id: parseInt(
  //         customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntDstrct,
  //       ),
  //     })
  //       .unwrap()
  //       .then((response) => {
  //         if (response && response.data !== '') {
  //           setDistrictName(response.data)
  //           setFormData((formData) => {
  //             return { ...formData, district: response.data }
  //           })
  //         } else {
  //           //* undo this change
  //           if (response && response.data == '') {
  //             setFormData((formData) => {
  //               return { ...formData, district: '' }
  //             })
  //           }
  //           setDistrictName('')
  //         }
  //         // if (response && response.data !== '') {
  //         //   console.log('response.data: ', response.data)
  //         //   setDistrictName(response.data)
  //         //   setFormData((formData) => {
  //         //     return { ...formData, district: response.data }
  //         //   })
  //         // } else {
  //         //   console.log('here: ', cityVal)
  //         //   setDistrictName(cityVal)
  //         // }
  //       })
  //       .catch((error) => {
  //         setDistrictName('')
  //       })
  //   } else {
  //     setDistrictName('')
  //   }
  // }, [])

  const dispatch = useAppDispatch()

  const productCode: string = selectedProductData?.code
  const cancerCoverType = useAppSelector((state) => state?.sachetDataDetails?.cancerCoverType)

  // console.log('cancerCoverType sachetApplicantForm : ', cancerCoverType)

  const fetchedData = {
    firstName: fullUserDataFromSlice?.frsNm || '',
    middleName: fullUserDataFromSlice?.mdlNm || '',
    lastName: fullUserDataFromSlice?.lstNm || '',
    relationship: 'Self',
    // relationship: productCode !== 'CIC' ? 'Self' : cancerCoverType === '1A' ? 'Self' : 'Spouse',

    dob: fullCustomerDetails?.dob,
    email: fullCustomerDetails?.email,
    pinCode:
      customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.prmntPncd || '',
    policyNumber: getMasterPolicyNumber[productCode] || '',
    address1: otherCustomerDetails?.prmntAddrLn1 || '',
    address2: otherCustomerDetails.prmntAddrLn2 || '',
    address3: otherCustomerDetails?.prmntAddrLn3 || '',

    // ifscCode: (fullCustomerDetails?.accounts && fullCustomerDetails?.accounts[0]?.ifsc_code) || '-',
    city: cityName !== 'Fetching City...' ? cityName : '' || '',
    district: districtName !== 'Fetching District...' ? districtName : '' || '',
    aadhaar: fullCustomerDetails?.aadhar
      ? fullCustomerDetails?.aadhar.length == 12
        ? fullCustomerDetails?.aadhar
        : ''
      : '',
    gender: otherCustomerDetails?.gndr.toLowerCase(),
    state:
      (getStateName[
        customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntSt as string
      ] as string) || stateName,
    salutation: otherCustomerDetails && otherCustomerDetails?.gndr.toLowerCase() == 'm' ? 'Mr' : '',
    occupation: '',
    contactNumber:
      fullCustomerDetails?.phone.length == 12
        ? fullCustomerDetails?.phone.slice(2)
        : fullCustomerDetails?.phone || '',
    pan: fullCustomerDetails?.pan || '',
  }

  const onSubmit: SubmitHandler<IApplicantForm> = (_, e) => {
    e?.preventDefault()

    if (!isMoreThan18(new Date(formData.dob))) {
      return ToastService.showErrorToast({
        title: 'Nominee age should be more than or equal to 18',
      })
    }

    setFormAccordianState({
      accordian1: true,
      accordian2: false,
      accordian3: false,
    })

    setIndex(1)
  }

  const [formData, setFormData] = useState({
    firstName: fetchedData?.firstName || '',
    middleName: fetchedData?.middleName || '',
    lastName: fetchedData?.lastName || '',
    email: fetchedData?.email || '',
    pinCode: fetchedData?.pinCode || '',
    policyNumber: fetchedData?.policyNumber || '',
    dob: fetchedData?.dob || '',
    address1: fetchedData?.address1 || '',
    city: fetchedData?.city || '',
    // city: cityName !== 'Fetching City...' ? cityName : '' || '',
    district: fetchedData?.district || '',
    // district: districtName || '',
    // district:
    //   districtName !== 'Fetching District...'
    //     ? districtName
    //     : cityName !== 'Fetching City...'
    //     ? cityName
    //     : '' || '',
    aadhaar: fetchedData?.aadhaar?.length == 12 ? fetchedData?.aadhaar : '',
    gender: fetchedData?.gender || '',
    address2: fetchedData?.address2 || '',
    address3: fetchedData?.address3 || '',
    // ifscCode: fetchedData?.ifscCode || '',
    state: fetchedData?.state || '',
    salutation: fetchedData?.salutation || '',
    occupation: fetchedData?.occupation || '',
    contactNumber: fetchedData?.contactNumber || '',
    relationship: fetchedData?.relationship,
    pan: fetchedData?.pan ?? '',
  })

  useEffect(() => {
    {
      fetchedData &&
        Object.keys(fetchedData).forEach((key) => {
          if (fetchedData[key as keyof IApplicantForm]) {
            setValue(key as keyof IApplicantForm, fetchedData[key as keyof IApplicantForm])
          }
        })
    }
  }, [fetchedData])

  return (
    <Flex direction='column' justifyContent='center' alignItems='center' gap='3'>
      <Grid templateColumns='repeat(3, 1fr)' gap={6} w='full'>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>First Name</FormLabel>

            <Flex>
              <FormControl
                w='45%'
                isRequired
                isInvalid={!formData?.salutation && !!errors.salutation}
              >
                <Select
                  disabled={fetchedData?.gender == 'm' || formData?.gender == '' ? true : false}
                  fontSize='11px'
                  p='0'
                  {...register('salutation')}
                  placeholder='Select Salutation'
                  value={formData?.salutation}
                  onChange={(e) => {
                    setFormData((formData) => {
                      return {
                        ...formData,
                        salutation: formData?.gender == 'm' ? 'Mr' : e.target.value,
                      }
                    })
                  }}
                >
                  <option value='Mr' hidden={formData?.gender == 'f'}>
                    Mr
                  </option>
                  <option value='Mrs' hidden={formData?.gender == 'm'}>
                    Mrs
                  </option>
                  <option value='Ms' hidden={formData?.gender == 'm'}>
                    Ms
                  </option>
                </Select>
                {!formData?.salutation && (
                  <FormErrorMessage>{errors.salutation?.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                w='full'
                isRequired
                isInvalid={!formData?.firstName && !!errors.firstName}
              >
                <Input
                  onKeyDown={onlyAllowTypingAlphabet}
                  placeholder='Enter First name'
                  maxLength={50}
                  required
                  {...register('firstName')}
                  value={formData?.firstName}
                  disabled={fetchedData?.firstName ? true : false}
                  onChange={(e) => {
                    setFormData((formData) => {
                      return {
                        ...formData,
                        firstName: e.target.value.trim().length > 0 ? e.target.value : '',
                      }
                    })
                  }}
                />
                {!formData?.firstName && (
                  <FormErrorMessage color='red.500'>{errors.firstName?.message}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={fetchedData?.middleName ? false : !!errors?.middleName}>
            <FormLabel>Middle Name</FormLabel>
            <Input
              maxLength={50}
              onKeyDown={onlyAllowTypingAlphabet}
              {...register('middleName')}
              placeholder='Enter middle name'
              value={formData?.middleName}
              disabled={fetchedData?.middleName ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    middleName: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={formData?.lastName ? false : !!errors.lastName}>
            <FormLabel>Last Name</FormLabel>
            <Input
              maxLength={50}
              onKeyDown={onlyAllowTypingAlphabet}
              required
              {...register('lastName')}
              placeholder='Enter last name'
              value={formData?.lastName}
              disabled={fetchedData?.lastName ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    lastName: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />

            {!formData?.lastName && (
              <FormErrorMessage color='red.500'>{errors.lastName?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Relationship</FormLabel>
            <Input
              maxLength={50}
              onKeyDown={onlyAllowTypingAlphabet}
              required
              {...register('relationship')}
              placeholder='Enter last name'
              value={formData?.relationship}
              disabled={fetchedData?.relationship ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    relationship: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.relationship && (
              <FormErrorMessage color='red.500'>{errors.relationship?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.dob && !!errors.dob}>
            <FormLabel>Date of Birth</FormLabel>
            <Input
              max={calcAge18().toISOString().split('T')[0]}
              {...register('dob')}
              type='date'
              value={formData?.dob}
              disabled={fetchedData?.dob ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    dob: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />

            {!formData?.dob && (
              <FormErrorMessage color='red.500'>Date of Birth is required</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!formData?.gender && !!errors.gender}>
            <FormLabel>Gender</FormLabel>
            <Select
              {...register('gender')}
              placeholder='Select gender'
              value={formData?.gender}
              disabled={fetchedData?.gender ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    gender: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
                setValue('salutation', '')
                setFormData((formData) => {
                  return {
                    ...formData,
                    salutation: '',
                  }
                })
              }}
            >
              <option value='m'>Male</option>
              <option value='f'>Female</option>
            </Select>
            {!formData?.gender && (
              <FormErrorMessage color='red.500'>{errors.gender?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        {/* 
      <GridItem>
          <FormControl isInvalid={!formData?.ifscCode && !!errors.ifscCode}>
            <FormLabel>IFSC Code</FormLabel>
            <Input
              type='text'
              {...register('ifscCode')}
              placeholder='IFSC Code'
              onKeyDown={onlyAllowTypingAlphabetNumeric}
              value={formData?.ifscCode}
              disabled={fetchedData?.ifscCode ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    ifscCode: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.ifscCode && (
              <FormErrorMessage color='red.500'>{errors.ifscCode?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
       */}

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.occupation && !!errors.occupation}>
            <FormLabel>Occupation</FormLabel>
            <Select
              {...register('occupation')}
              placeholder='Select occupation'
              value={formData?.occupation}
              disabled={fetchedData?.occupation ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    occupation: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            >
              {Object.values(occupationList).map((elem) => {
                return (
                  <option key={elem} value={elem}>
                    {elem}
                  </option>
                )
              })}
              {/* <option value='m'>Male</option>
              <option value='f'>Female</option> */}
            </Select>
            {!formData?.occupation && (
              <FormErrorMessage>{errors.occupation?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              type='email'
              {...register('email')}
              placeholder='Email'
              onKeyDown={onlyAllowTypingAlphabetNumericAndAt}
              value={formData?.email}
              disabled={fetchedData?.email ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    email: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.email && (
              <FormErrorMessage color='red.500'>{errors.email?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!formData?.contactNumber && !!errors.contactNumber}>
            <FormLabel>Contact Number</FormLabel>
            <Input
              type='string'
              {...register('contactNumber')}
              placeholder='contactNumber'
              maxLength={10}
              minLength={10}
              onKeyDown={onlyAllowTypingNumbers}
              value={formData?.contactNumber}
              disabled={fetchedData?.contactNumber ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    contactNumber: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.contactNumber && (
              <FormErrorMessage color='red.500'>{errors.contactNumber?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!formData?.address1 && !!errors.address1}>
            <FormLabel>Address Line 1</FormLabel>
            <Input
              maxLength={100}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              {...register('address1')}
              placeholder='Address Line 1'
              value={formData?.address1}
              disabled={fetchedData?.address1 ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    address1: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            {!formData?.address1 && (
              <FormErrorMessage color='red.500'>{errors.address1?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors.address2}>
            <FormLabel>Address Line 2</FormLabel>
            <Input
              maxLength={100}
              {...register('address2')}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              placeholder='Address Line 2'
              value={formData?.address2}
              disabled={fetchedData?.address2 ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    address2: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />

            <FormErrorMessage color='red.500'>{errors.address2?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors.address2}>
            <FormLabel>Address Line 3</FormLabel>
            <Input
              maxLength={100}
              {...register('address3')}
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              placeholder='Address Line 3'
              value={formData?.address3}
              disabled={fetchedData?.address3 ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    address3: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            <FormErrorMessage color='red.500'>{errors.address3?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!!errors.city}>
            <FormLabel>City</FormLabel>
            <Input
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              type='text'
              {...register('city')}
              placeholder='Enter City'
              value={formData?.city}
              disabled={fetchedData?.city ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    city:
                      fetchedData?.city.trim().length > 0
                        ? fetchedData?.city
                        : e.target.value.trim().length > 0
                        ? e.target.value
                        : '',
                  }
                })
              }}
            />

            {cityName ? (
              ''
            ) : (
              <FormErrorMessage color='red.500'>{errors.city?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.district && !!errors.district}>
            <FormLabel>District</FormLabel>
            <Input
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              type='text'
              {...register('district')}
              placeholder='Enter district'
              value={formData?.district}
              disabled={fetchedData?.district ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    district:
                      fetchedData?.district.trim().length > 0
                        ? fetchedData?.district
                        : e.target.value.trim().length > 0
                        ? e.target.value
                        : '',
                  }
                })
              }}
            />

            {districtName ? (
              ''
            ) : (
              <FormErrorMessage color='red.500'>{errors.district?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.pinCode && !!errors.pinCode}>
            <FormLabel>Pin Code</FormLabel>
            <Input
              onKeyDown={onlyAllowTypingNumbers2}
              type='number'
              {...register('pinCode')}
              placeholder='Pin Code'
              value={formData?.pinCode}
              disabled={fetchedData?.pinCode ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    pinCode: fetchedData?.pinCode
                      ? fetchedData?.pinCode
                      : e.target.value.trim().length > 0
                      ? e.target.value
                      : '',
                  }
                })
              }}
            />
            {!formData?.pinCode && (
              <FormErrorMessage color='red.500'>{errors.pinCode?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isRequired isInvalid={!formData?.state && !!errors.state}>
            <FormLabel>State</FormLabel>
            <Input
              onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
              type='text'
              {...register('state')}
              placeholder='Enter state'
              // value={'asdasdasd'}
              value={formData?.state}
              disabled={fetchedData?.state ? true : false}
              onChange={(e) => {
                // console.log('changed: ', e.target.value)
                setFormData((formData) => {
                  return {
                    ...formData,
                    state:
                      fetchedData?.state.trim().length > 0
                        ? fetchedData?.state
                        : e.target.value.trim().length > 0
                        ? e.target.value
                        : '',
                  }
                })
              }}
            />
            {/* formdata.state : {formData?.state} */}
            {/* fetchedData.state : {fetchedData?.state} */}
            {!formData?.state && (
              <FormErrorMessage color='red.500'>{errors.state?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!formData?.policyNumber && !!errors.policyNumber}>
            <FormLabel>Master Policy Number</FormLabel>
            <Input
              onKeyDown={onlyAllowTypingAlphabetNumeric}
              {...register('policyNumber')}
              placeholder='Master Policy Number'
              maxLength={50}
              value={formData?.policyNumber}
              disabled={fetchedData?.policyNumber ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return { ...formData, policyNumber: e.target.value }
                })
                if (e.target.value.length == 0 || e.target.value == undefined) {
                  setIsProceed(false)
                }
              }}
            />
            {!formData?.policyNumber && (
              <FormErrorMessage color='red.500'>{errors.policyNumber?.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        {/* <GridItem>
          <FormControl
            isRequired
            isInvalid={!!errors.aadhaar}
            isDisabled={fetchedData?.aadhaar ? true : false}
          >
            <FormLabel>Aadhaar</FormLabel>

            <Input
              {...register('aadhaar')}
              onKeyDown={onlyAllowTypingNumbers2}
              placeholder='Aadhaar Number'
              type='text'
              maxLength={12}
              minLength={12}
              value={formData?.aadhaar}
              disabled={fetchedData?.aadhaar ? true : false}
              onChange={(e) => {
                setFormData((formData) => {
                  return {
                    ...formData,
                    aadhaar: e.target.value.trim().length > 0 ? e.target.value : '',
                  }
                })
              }}
            />
            <FormErrorMessage color='red.500'>{errors.aadhaar?.message}</FormErrorMessage>
          </FormControl>
        </GridItem> */}
      </Grid>

      <Button
        onClick={() => {
          if (
            formData.state === '' &&
            customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntSt
          ) {
            // console.log('inside if')
            const stateCode =
              customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntSt

            // console.log('stateCode: ', stateCode)
            if (getStateName[stateCode as string] as string) {
              formData.state = getStateName[stateCode as string] as string
            } else {
              formData.state = fetchedData?.state
              // console.log('fetchedData.state: ', fetchedData?.state)
              // console.log('formData.state: ', formData?.state)
            }
          }
          // console.log('formData: ', formData)
          dispatch(setApplicantFormDetails(formData))
          setIsProceed(true)

          setApplcantAddress({
            address1: formData?.address1,
            address2: formData?.address2,
            address3: formData?.address3,
          })

          const enteredDate = new Date(formData?.dob)
          const today = new Date()
          if (today.getFullYear() < enteredDate.getFullYear()) {
            ToastService.showErrorToast({
              title: 'DOB Year should be less than or equal to : ' + today.getFullYear(),
            })
            return
          }
          return handleSubmit(onSubmit)()
        }}
        disabled={
          cityName === 'Fetching City...' || districtName == 'Fetching District...' || !quoteId
        }
        cursor='pointer'
        type='submit'
        border='1px'
        bgColor='#1C4589'
        px='20'
        py='5'
      >
        Proceed
      </Button>
    </Flex>
  )
}

export default SachetsApplicantForm
