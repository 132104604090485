import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from 'Constants'
import { resetUser, updateRoleVerified, updateUserDetails } from 'features/Authentication'
import { resetCustomerDetails, resetSpTaggingForm } from 'features/Equitas'
import { resetLeadDetails } from 'features/Leads'
import { resetProposal, resetQuotation, resetVehicleDetails } from 'features/Motor'
import { IUserCredentials, IUserCredentialsOTP } from 'Models/RequestModels'
import { ISupportCreationParams, ISupportListingParams } from 'Models/RequestModels/Equitas'
import { IAuthenticatedUser } from 'Models/ResponseModels'
import { ISpData, ISupportListing } from 'Models/ResponseModels/Equitas'
import { ISp } from 'Models/ResponseModels/Equitas/SpData'
import { foundationUrlQuery } from 'Services/baseQuery'
import { decryptData } from 'Services/general'
import { ToastService } from 'Services/toast.service'
import { dispatchLogoutActions } from 'Utils'

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: foundationUrlQuery,
  tagTypes: ['Login', 'Logout'],
  endpoints: (builder) => ({
    sendOTP: builder.mutation<any, IUserCredentialsOTP>({
      query: (data: any) => ({
        url: API_ENDPOINTS.LOGIN_SEND_OTP,
        method: 'POST',
        body: data,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    loginWithOTP: builder.mutation<IAuthenticatedUser, IUserCredentialsOTP>({
      query: (formData: IUserCredentialsOTP) => ({
        url: API_ENDPOINTS.LOGIN_VERIFY_OTP,
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled
          const u: IAuthenticatedUser = JSON.parse(decryptData(user.response_data ?? ''))
          console.log(u)
          if (
            !(
              u.businessrole_name?.toLowerCase() === 'admin' ||
              u.businessrole_name?.toLowerCase() === 'operations' ||
              u.businessrole_name?.toLowerCase() === 'operation'
            )
          ) {
            dispatch(updateUserDetails(u))
            ToastService.showSuccessTaost({
              title: 'Success',
              message: 'Login Successful',
            })
          } else {
            console.log('Access not allowed')
            // ToastService.showErrorToast({
            //   title: 'Failed',
            //   message: 'Access not allowed',
            // })
          }
        } catch (err: any) {
          // TODO: add failure toast here
          // ToastService.showErrorToast({ title: 'Error', message: err['error']['message'] })
        }
      },
    }),
    checkAuthRole: builder.mutation<any, any>({
      query: (payload) => ({
        url: API_ENDPOINTS.CHECK_ROLE,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled
          dispatch(updateRoleVerified(true))
        } catch (err: any) {
          // TODO: add failure toast here
          dispatch(updateRoleVerified(false))
          dispatch(resetUser())
          ToastService.showErrorToast({ title: 'Error', message: err['error']['message'] })
        }
      },
    }),
    logout: builder.mutation<void, null>({
      query: () => ({
        url: API_ENDPOINTS.LOGOUT,
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          // dispatch(resetUser())
          // dispatch(resetCustomerDetails())
          // dispatch(resetLeadDetails())
          // dispatch(resetVehicleDetails())
          // dispatch(resetQuotation())
          // dispatch(resetProposal())
          // dispatch(resetSpTaggingForm())
          // dispatch(updateRoleVerified(false))
          dispatchLogoutActions(dispatch)
          ToastService.showSuccessTaost({
            title: 'Success',
            message: 'Logout Successful',
          })
        } catch (e: any) {
          console.log(e)
          ToastService.showErrorToast({
            title: e['error']['data']['detail'] ?? 'Something went wrong',
          })
        } finally {
          dispatch(resetUser())
        }
      },
    }),
    getSpListing: builder.query<ISp, { user_id: string; type?: string }>({
      query: (params) => ({
        url: API_ENDPOINTS.SP_LISTING,
        method: 'GET',
        params: params,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    getSupportTicketListing: builder.query<ISupportListing, ISupportListingParams>({
      query: (params) => ({
        url: API_ENDPOINTS.SUPPORT_LISTING,
        method: 'GET',
        params: params,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    spTaggingSendOtp: builder.mutation<
      string,
      {
        email: string
      }
    >({
      query: (payload) => ({
        url: API_ENDPOINTS.SP_TAGGING_SEND_OTP,
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    spTaggingVerifyOtp: builder.mutation<
      string,
      {
        otp: string
        email: string
      }
    >({
      query: (payload) => ({
        url: API_ENDPOINTS.SP_TAGGING_VERIFY_OTP,
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    getIssueTypeDropdownData: builder.query<{ [key: string]: string }, void>({
      query: () => ({
        url: API_ENDPOINTS.ISSUE_TYPE_DROPDOWN,
        method: 'GET',
      }),
    }),
    createSupportTicket: builder.mutation<void, ISupportCreationParams>({
      query: (data) => ({
        url: API_ENDPOINTS.SUPPORT_CREATION,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const {
  useSendOTPMutation,
  useLoginWithOTPMutation,
  useLogoutMutation,
  useGetSpListingQuery,
  useLazyGetSpListingQuery,
  useCheckAuthRoleMutation,
  useGetSupportTicketListingQuery,
  useSpTaggingSendOtpMutation,
  useSpTaggingVerifyOtpMutation,
  useCreateSupportTicketMutation,
  useGetIssueTypeDropdownDataQuery,
} = authenticationApi
