import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'
import { theme } from 'Core/Styles'
import { FaMapMarkerAlt } from 'react-icons/fa'

interface ICashlessHospitalData extends React.ComponentPropsWithRef<'div'> {
  name: string
  address: string
  id: string
}

export const CashlessHospitalCard = (props: ICashlessHospitalData) => {
  const { isDesktop } = useDeviceType()
  return (
    <>
      <Flex direction={'column'}>
        <Box
          w={isDesktop ? '64' : '95%'}
          border='1px'
          borderColor={'appGrey.400'}
          rounded='2xl'
          key={props.id}
          margin='2'
          bg='white'
        >
          <Box marginX={isDesktop ? '4' : '8'} marginY={isDesktop ? '2' : '4'}>
            <Text fontWeight='bold' fontSize='md' marginY='2'>
              {props.name}
            </Text>
            <Text fontSize='sm' fontWeight='medium'>
              {props.address}
            </Text>
          </Box>
          <Divider borderColor='#B0ADAD' />
          <Flex margin={2} gap='2' marginX={isDesktop ? '4' : '8'} marginY={isDesktop ? '2' : '4'}>
            <Box marginY='auto'>
              <FaMapMarkerAlt style={{ color: `${theme.colors.primary}` }} />
            </Box>
            <Text fontSize='sm'>Get Directions</Text>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
