import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react'
import { useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'
import { CustomerDetailsForm } from '../Forms/CustomerDetailsForm'
import { CustomerLinkedAccountsForm } from '../Forms/CustomerLinkedAccounts'

const CustomerAccountDetailsMobile = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const { customerAccountStatus, accounts } = useAppSelector((state) => state.customerDetails)

  useEffect(() => {
    if (accounts && accounts?.length > 0) {
      setTabIndex(1)
    }
  }, [accounts])

  return (
    <Accordion index={tabIndex} border='1px' borderRadius='lg'>
      <AccordionItem border='none'>
        <AccordionButton onClick={() => setTabIndex(0)} paddingY='4' bgColor='appDarkGrey'>
          <Box flex='1' textAlign='left'>
            Customer Account Details
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel paddingX={['0', '0', '4']}>
          <CustomerDetailsForm />
          {/* <Center>
        <ElevatedButton
          onClick={() => {
            console.log(store.getState())
            setTabIndex(1)
          }}
        >
          Log Store
        </ElevatedButton>
      </Center> */}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem borderBottom='none'>
        <AccordionButton
          onClick={() => {
            if (customerAccountStatus === 'success') setTabIndex(1)
          }}
          paddingY='4'
          bgColor='appDarkGrey'
        >
          <Box flex='1' textAlign='left'>
            Linked Accounts
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel paddingX={['0', '0', '4']}>
          <CustomerLinkedAccountsForm />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export { CustomerAccountDetailsMobile }
