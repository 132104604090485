export const FONT_WEIGHT = {
  BOLD: 'bold',
  SEMI: 600,
  NORMAL: 'normal',
}

const textStyles = {
  // Headings Font Sizes
  heading1: {
    fontSize: {
      base: '1.4rem',
      xl: '2.2rem',
    },
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '1',
  },
  heading2: {
    fontSize: {
      base: '1.05rem',
      xl: '1.625rem',
      '2xl': '1.75rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  heading3: {
    fontSize: {
      base: '1.025rem',
      xl: '1.5rem',
      '2xl': '1.625rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  heading4: {
    fontSize: {
      base: '1rem',
      xl: '1.25rem',
      '2xl': '1.375rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  heading5: {
    fontSize: {
      base: '1rem',
      xl: '1.125rem',
      '2xl': '1.25rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  heading6: {
    fontSize: {
      base: '0.875rem',
      xl: '0.94rem',
      '2xl': '1.06rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  heading7: {
    fontSize: {
      base: '0.75rem',
      xl: '0.813rem',
      '2xl': '0.938rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },

  // Para Font Sizes
  para1: {
    fontSize: {
      base: '0.875rem',
      xl: '1.25rem',
      '2xl': '1.25rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  para2: {
    fontSize: {
      base: '0.75rem',
      xl: '1.125rem',
      '2xl': '1.125rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  para3: {
    fontSize: {
      base: '0.75rem',
      xl: '1rem',
      '2xl': '1rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  para4: {
    fontSize: {
      base: '0.625rem',
      xl: '0.75rem',
      '2xl': '0.75rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
  para5: {
    fontSize: {
      base: '0.5rem',
      xl: '0.625rem',
      '2xl': '0.625rem',
    },
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1',
  },
}

export type TextStyles = typeof textStyles

export default textStyles
