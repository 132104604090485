import { FormLabel, Grid, GridItem, Box, FormControl, FormErrorMessage } from '@chakra-ui/react'

import { useDeviceType } from 'app/hooks'
import { TextInput } from 'Core'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const autoPayFormSchema = yup.object().shape({
  applicationNo: yup.string().required('Required!'),
  name: yup
    .string()
    .required('Required!')
    .min(3, 'Min length is 3 letters')
    .test('len', 'Min length is 3 letters', (text) => {
      if (text?.trim() == '') {
        return false
      }
      if (text!.trim()!.length < 3) {
        return false
      }
      return true
    }),
  planName: yup.string().required('Required!'),

  premium: yup.string().required('Required!'),
  debitDate: yup.date().required('Required!'),
  debitMonth: yup.date().required('Required!'),
  bankAccountNumber: yup.string().required('Required!'),
  accountType: yup.string().required('Required!'),

  bankName: yup.string().required('Required!'),
  frequency: yup.string().required('Required!'),
  mandateValidityTo: yup.string().required('Required!'),
  mandateValidityFrom: yup.string().required('Required!'),

  debitType: yup.string().required('Required!'),
  TypeofRegistration: yup.string().required('Required!'),
  maximumPremiumThatCanBeDebited: yup.string().required('Required!'),
})

export const AutoPayForm = ({
  applicationNo,
  name,
  planName,
  premium,
  debitDate,
  debitMonth,
  bankAccountNumber,
  accountType,
  bankName,
  frequency,
  mandateValidityFrom,
  mandateValidityTo,
  debitType,
  typeofRegistration,
  maximumPremiumThatCanBeDebited,
}: {
  applicationNo: string
  name: string
  planName: string
  premium: string
  debitDate: string
  debitMonth: string
  bankAccountNumber: string
  accountType: string
  bankName: string
  frequency: string
  mandateValidityFrom: string
  mandateValidityTo: string
  debitType: string
  typeofRegistration: string
  maximumPremiumThatCanBeDebited: string
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(autoPayFormSchema),
    defaultValues: {
      applicationNo,
      name,
      planName,
      premium,
      debitDate,
      debitMonth,
      bankAccountNumber,
      accountType,
      bankName,
      frequency,
      mandateValidityFrom,
      mandateValidityTo,
      debitType,
      typeofRegistration,
      maximumPremiumThatCanBeDebited,
    },
  })
  const onFormError = (errors: any) => {
    console.log(errors)
  }

  const onFormSubmit = (data: any) => {
    console.log(data)
  }
  const { isDesktop, isTablet } = useDeviceType()

  return (
    <Box border='1px' my='4' p='2'>
      <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
        <Grid
          gridTemplateColumns={isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr 1fr'}
        >
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.applicationNo ? true : false}>
                <FormLabel>Application Number</FormLabel>
                <TextInput
                  {...register('applicationNo')}
                  isReadOnly
                  placeholder='Enter Application Number'
                />
                {errors.applicationNo && (
                  <FormErrorMessage>{errors.applicationNo?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.name ? true : false}>
                <FormLabel>Name</FormLabel>
                <TextInput {...register('name')} isReadOnly placeholder='Enter Name' />
                {errors.name && (
                  <FormErrorMessage>{errors.name?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.planName ? true : false}>
                <FormLabel>Plan Name</FormLabel>
                <TextInput {...register('planName')} isReadOnly placeholder='Enter Plan Name' />
                {errors.planName && (
                  <FormErrorMessage>{errors.planName?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.premium ? true : false}>
                <FormLabel>Premium</FormLabel>
                <TextInput {...register('premium')} isReadOnly placeholder='Enter Premium' />
                {errors.premium && (
                  <FormErrorMessage>{errors.premium?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>{' '}
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.debitDate ? true : false}>
                <FormLabel>Debit Date</FormLabel>
                <TextInput {...register('debitDate')} isReadOnly placeholder='Enter Debit Date' />
                {errors.debitDate && (
                  <FormErrorMessage>{errors.debitDate?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.debitMonth ? true : false}>
                <FormLabel>Debit Month</FormLabel>
                <TextInput {...register('debitMonth')} isReadOnly placeholder='Enter Debit Month' />
                {errors.debitMonth && (
                  <FormErrorMessage>{errors.debitMonth?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.bankAccountNumber ? true : false}>
                <FormLabel>Bank Account Number</FormLabel>
                <TextInput
                  {...register('bankAccountNumber')}
                  isReadOnly
                  placeholder='Enter Bank Account Number'
                />
                {errors.bankAccountNumber && (
                  <FormErrorMessage>
                    {errors.bankAccountNumber?.message?.toString()}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.accountType ? true : false}>
                <FormLabel>Account Type</FormLabel>
                <TextInput
                  {...register('accountType')}
                  isReadOnly
                  placeholder='Enter Account Type'
                />
                {errors.accountType && (
                  <FormErrorMessage>{errors.accountType?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.bankName ? true : false}>
                <FormLabel>Bank Name</FormLabel>
                <TextInput {...register('bankName')} isReadOnly placeholder='Enter Bank Name' />
                {errors.bankName && (
                  <FormErrorMessage>{errors.bankName?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.frequency ? true : false}>
                <FormLabel>Frequency</FormLabel>

                <TextInput {...register('frequency')} isReadOnly placeholder='Enter Frequency' />
                {errors.frequency && (
                  <FormErrorMessage>{errors.frequency?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.mandateValidityFrom ? true : false}>
                <FormLabel>Mandate Validity (From)</FormLabel>
                <TextInput
                  {...register('mandateValidityFrom')}
                  isReadOnly
                  placeholder='Enter Mandate Validity (From) Date'
                />
                {errors.mandateValidityFrom && (
                  <FormErrorMessage>
                    {errors.mandateValidityFrom?.message?.toString()}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.mandateValidityTo ? true : false}>
                <FormLabel>Mandate Validity (To)</FormLabel>
                <TextInput
                  {...register('mandateValidityTo')}
                  isReadOnly
                  placeholder='Enter Mandate Validity (To) Date'
                />
                {errors.mandateValidityTo && (
                  <FormErrorMessage>
                    {errors.mandateValidityTo?.message?.toString()}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.debitType ? true : false}>
                <FormLabel>Debit Type</FormLabel>

                <TextInput {...register('debitType')} isReadOnly placeholder='Enter Debit Type' />
                {errors.debitType && (
                  <FormErrorMessage>{errors.debitType?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.typeofRegistration ? true : false}>
                <FormLabel>Type Of Registration</FormLabel>

                <TextInput
                  {...register('typeofRegistration')}
                  isReadOnly
                  placeholder='Enter Type Of Registration'
                />
                {errors.typeofRegistration && (
                  <FormErrorMessage>
                    {errors.typeofRegistration?.message?.toString()}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
          <GridItem marginY='2' marginX='2'>
            <Box m='1'>
              <FormControl isInvalid={errors.maximumPremiumThatCanBeDebited ? true : false}>
                <FormLabel>Maximum Premium That Can Be Debited</FormLabel>

                <TextInput
                  {...register('maximumPremiumThatCanBeDebited')}
                  isReadOnly
                  placeholder='Enter Maximum Premium That Can Be Debited'
                />
                {errors.maximumPremiumThatCanBeDebited && (
                  <FormErrorMessage>
                    {errors.maximumPremiumThatCanBeDebited?.message?.toString()}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </GridItem>
        </Grid>
      </form>
    </Box>
  )
}
