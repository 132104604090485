import { ReactElement } from 'react'
interface IProps {
  condition: boolean
  comment?: any
  fallback?: React.ReactNode
}
export function If(props: React.PropsWithChildren<IProps>): ReactElement | null {
  if (props.condition) {
    return <>{props.children}</>
  } else if (props.fallback) {
    return <>{props.fallback}</>
  }
  return null
}
