import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
  Button,
  FormControl,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'
import { useVehicleType } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { updatePaOwnerReason } from 'features/Motor'
const ZeroDepDialog = ({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const navigate = useNavigate()
  const { name: vehicleName } = useVehicleType()
  const { isOpen: isZeroDepOpen, onClose: onZeroDepClose, onOpen: onZeroDepOpen } = useDisclosure()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <FormControl>
            <ModalHeader>
              {
                'Is Zero Dep add-on included in your previous policy?. Click "Yes" to proceed, "No" otherwise.'
              }
            </ModalHeader>
            <ModalBody>
              <Flex direction='row' justifyContent='center'>
                <Button
                  colorScheme='primary'
                  marginRight='2'
                  onClick={() => {
                    navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                    onClose()
                  }}
                >
                  Yes
                </Button>
                <Button
                  colorScheme='primary'
                  onClick={() => {
                    // navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                    onClose()
                    onZeroDepOpen()
                  }}
                >
                  No
                </Button>
              </Flex>
            </ModalBody>
          </FormControl>
        </ModalContent>
      </Modal>
      <Modal isOpen={isZeroDepOpen} onClose={onZeroDepClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <FormControl>
            <ModalHeader>
              {
                'Sorry, you cannot avail Zero Dep add-on. Please de-select it to continue with purchase of policy.'
              }
            </ModalHeader>
            <ModalBody>
              <Flex direction='row' justifyContent='center'>
                {/* <Button
                  colorScheme='primary'
                  marginRight='2'
                  onClick={() => {
                    navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                    onClose()
                  }}
                >
                  Yes
                </Button> */}
                <Button
                  colorScheme='primary'
                  onClick={() => {
                    // navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                    onZeroDepClose()
                  }}
                >
                  Okay
                </Button>
              </Flex>
            </ModalBody>
          </FormControl>
        </ModalContent>
      </Modal>
    </>
  )
}

export { ZeroDepDialog }
