import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ModalFooter,
} from '@chakra-ui/react'
import { Select as Select2, SingleValue } from 'chakra-react-select'
import { useState, useEffect } from 'react'
import { useAppSelector, useVehicleType, useAppDispatch } from 'app/hooks'
import { ErrorText, TextInput } from 'Core'
import { IManufacturer, IModel, IVariant } from 'Models/ResponseModels'
import { validateVehicleNumber } from 'Services/general'
import {
  useGetManufacturersQuery,
  useGetModelsQuery,
  useGetVariantsQuery,
} from 'Services/API/motor.api'
import {
  updateSelectedManufacturer,
  updateSelectedModel,
  updateSelectedVariant,
  updateTypeOfVehicle_commercial,
} from 'features/Motor'
import { updateVehicleNumber } from 'features/Home'
import { useForm } from 'react-hook-form'

type TEditVehicleDetails = {
  vehicleNumber?: string
  manufacturer?: IManufacturer
  model?: IModel
  variant?: IVariant
}

const EditVehicleDetail = ({
  isOpen,
  onOpen,
  onClose,
  resetAll,
}: {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  resetAll: () => void
}) => {
  const { register, formState, resetField, clearErrors, setValue, trigger } =
    useForm<TEditVehicleDetails>()
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const homePageDetails = useAppSelector((state) => state.homePage)
  const [vehicleNumber, setVehicleNumber] = useState(homePageDetails.vehicleNumber)
  const [manufacturer, setManufacturer] = useState(vehicleDetails.selectedManufacturer)
  const [model, setModel] = useState(vehicleDetails.selectedModel)
  const [variant, setVariant] = useState(vehicleDetails.selectedVariant)
  const [typeOfVehicleCommercial, setTypeOfVehicleCommercial] = useState<{
    value: string
    label: string
  }>(
    vehicleDetails.typeOfVehicle_commercial
      ? {
          label: vehicleDetails.typeOfVehicle_commercial.name,
          value: vehicleDetails.typeOfVehicle_commercial.code,
        }
      : {
          label: '',
          value: '',
        },
  )
  const [callModelsApi, setCallModelsApi] = useState(true)
  const [callVariantApi, setCallVariantApi] = useState(true)

  const { type } = useVehicleType()

  const { data: manufacturersDropdownData, isFetching: isManufacturersDataLoading } =
    useGetManufacturersQuery({ type: type.toString() })
  const { data: modelDropdownData, isFetching: isModelDataLoading } = useGetModelsQuery(
    {
      manufacturer: manufacturer?.code.toString() ?? '',
    },
    {
      skip: !callModelsApi,
    },
  )
  const { data: variantDropdownData, isFetching: isVariantDataLoading } = useGetVariantsQuery(
    {
      model: model?.code.toString() ?? '',
    },
    {
      skip: !callVariantApi,
    },
  )

  useEffect(() => {
    setCallModelsApi(true)
    setCallVariantApi(true)
    setManufacturer(vehicleDetails.selectedManufacturer)
    setModel(vehicleDetails.selectedModel)
    setVariant(vehicleDetails.selectedVariant)
    setValue('vehicleNumber', vehicleNumber)
    setValue('model', model)
    setValue('variant', variant)
    clearErrors()
  }, [])

  const dispatch = useAppDispatch()
  const { name: vehicleType } = useVehicleType()

  const manufacturerChangeHandler = (
    allManufacturers: IManufacturer[],
    selectedManufacturerCode: string | number,
  ) => {
    setManufacturer(
      allManufacturers.find(
        (manufacturer) => manufacturer.code.toString() === selectedManufacturerCode.toString(),
      ),
    )
    setCallModelsApi(true)
    // setModel(undefined)
    resetField('model')
  }

  const modelChangeHandler = (allModels: IModel[], selectedModelCode: string | number) => {
    setModel(allModels.find((model) => model.code.toString() === selectedModelCode.toString()))
    setCallVariantApi(true)
    setValue('model', model, { shouldValidate: true })
    resetField('variant')
  }

  const variantChangeHandler = (allVariants: IVariant[], selectedVariantCode: string | number) => {
    setVariant(
      allVariants.find((variant) => variant.code.toString() === selectedVariantCode.toString()),
    )
    setValue('variant', variant, { shouldValidate: true })
  }

  const handleUpdate = (
    allManufacturers: IManufacturer[],
    allModels: IModel[],
    allVariants: IVariant[],
  ) => {
    if (
      homePageDetails.vehicleNumber !== vehicleNumber ||
      vehicleDetails.selectedManufacturer !== manufacturer ||
      vehicleDetails.selectedModel !== model ||
      vehicleDetails.selectedVariant !== variant ||
      vehicleDetails.typeOfVehicle_commercial?.code !== typeOfVehicleCommercial.value
    ) {
      const selectedManufacturerCode = manufacturer?.code ?? ''
      const selectedModelCode = model?.code ?? ''
      const selectedVariantCode = variant?.code ?? ''
      dispatch(updateVehicleNumber(vehicleNumber))
      dispatch(
        updateSelectedManufacturer({
          allManufacturers,
          selectedManufacturerCode,
        }),
      )
      dispatch(
        updateSelectedModel({
          allModels,
          selectedModelCode,
        }),
      )
      dispatch(
        updateSelectedVariant({
          allVariants,
          selectedVariantCode,
        }),
      )
      dispatch(
        updateTypeOfVehicle_commercial({
          name: typeOfVehicleCommercial.label,
          code: typeOfVehicleCommercial.value,
        }),
      )
      onClose()
    } else if (
      vehicleDetails.selectedManufacturer === manufacturer &&
      vehicleDetails.selectedModel === model &&
      vehicleDetails.selectedVariant === variant &&
      homePageDetails.vehicleNumber === vehicleNumber
    ) {
      onClose()
    }
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        setModel(vehicleDetails.selectedModel)
        setManufacturer(vehicleDetails.selectedManufacturer)
        setVariant(vehicleDetails.selectedVariant)
        setVehicleNumber(homePageDetails.vehicleNumber)
        resetField('vehicleNumber')
        resetField('model')
        resetField('variant')
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit {vehicleType} Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>Vehicle Number</FormLabel>
          <Input
            value={vehicleNumber}
            // onChange={(e) => setVehicleNumber(validateVehicleNumber(e.target.value))}
            {...(register('vehicleNumber', { required: true }),
            {
              onChange: (e) => {
                setVehicleNumber(validateVehicleNumber(e.target.value))
                setValue('vehicleNumber', e.target.value, { shouldValidate: true })
              },
              onFocus: (e) => {
                setVehicleNumber(e.target.value)
                setValue('vehicleNumber', e.target.value)
              },
            })}
          />
          {formState.errors.vehicleNumber && formState.errors.vehicleNumber.type === 'required' && (
            <ErrorText text='Please enter vehicle number' />
          )}
          <Flex direction='column' marginY='2'>
            <FormLabel>Manufacturer</FormLabel>
            <Select2
              menuPosition='fixed'
              selectedOptionColor='primary'
              isLoading={isManufacturersDataLoading}
              defaultValue={
                manufacturer != null
                  ? {
                      label: manufacturer.name ?? '',
                      value: manufacturer.code ?? '',
                    }
                  : {
                      label: '',
                      value: '',
                    }
              }
              onChange={(data) =>
                manufacturerChangeHandler(manufacturersDropdownData!, data!.value)
              }
              options={manufacturersDropdownData?.map((manufacturer) => {
                return {
                  label: manufacturer.name,
                  value: manufacturer.code,
                }
              })}
            />
          </Flex>
          <Flex direction='column' marginY='2'>
            <FormLabel>Model</FormLabel>
            <Select2
              menuPosition='fixed'
              selectedOptionColor='primary'
              isLoading={isModelDataLoading}
              defaultValue={
                model != null && model != undefined
                  ? {
                      label: model.name,
                      value: model.code,
                    }
                  : {
                      label: '',
                      value: '',
                    }
              }
              options={modelDropdownData?.map((model) => {
                return {
                  label: model.name,
                  value: model.code,
                }
              })}
              {...(register('model', { required: true }),
              {
                onChange: (data: SingleValue<{ label: string; value: string | number }>) =>
                  modelChangeHandler(modelDropdownData!, data!.value),
              })}
            />
            {formState.errors.model && formState.errors.model?.type === 'required' && (
              <ErrorText text='Please select model' />
            )}
          </Flex>
          <Flex direction='column' marginY='4'>
            <FormLabel>Variants</FormLabel>
            <Select2
              menuPosition='fixed'
              selectedOptionColor='primary'
              isLoading={isVariantDataLoading}
              placeholder='Select Variant'
              defaultValue={
                variant != null && variant != undefined
                  ? {
                      label: variant.name,
                      value: variant.code,
                    }
                  : {
                      label: '',
                      value: '',
                    }
              }
              options={variantDropdownData?.map((variant) => {
                return {
                  label: variant.name,
                  value: variant.code,
                }
              })}
              {...(register('variant', { required: true }),
              {
                onChange: (data: SingleValue<{ label: string; value: string | number }>) =>
                  variantChangeHandler(variantDropdownData!, data!.value),
              })}
            />
            {formState.errors.variant && formState.errors.variant?.type === 'required' && (
              <ErrorText text='Please select variant' />
            )}
          </Flex>
          {vehicleDetails.seatsGreaterThanSix === true && vehicleType.toLowerCase() === 'pcv' && (
            <Flex direction='column' marginY='4'>
              <FormLabel>Vehicle Type</FormLabel>
              <Select2
                isSearchable={false}
                selectedOptionColor='primary'
                menuPosition='fixed'
                placeholder='Select Vehicle Type'
                // onChange={manufacturingYearChangeHandler}
                value={typeOfVehicleCommercial}
                onChange={(item) => {
                  setTypeOfVehicleCommercial({
                    label: item?.label ?? '',
                    value: item?.value ?? '',
                  })
                  // dispatch(
                  //   updateTypeOfVehicle_commercial({
                  //     name: item?.label ?? '',
                  //     code: item?.value ?? '',
                  //   }),
                  // )
                }}
                options={[
                  {
                    label: 'Staff Bus',
                    value: 'Staff',
                  },
                  {
                    label: 'School Bus',
                    value: 'School',
                  },
                  {
                    label: 'Other',
                    value: 'other',
                  },
                ]}
              />
            </Flex>
          )}
          <FormLabel>Manufacturing Year</FormLabel>
          <Select disabled marginBottom='2'>
            <option>{vehicleDetails.manufacturingYear?.toString()}</option>
          </Select>
          <FormLabel>Registration Date</FormLabel>
          <TextInput value={vehicleDetails.registrationDate} disabled />
        </ModalBody>
        <Center>
          <ModalFooter>
            <Button
              variant='outline'
              colorScheme='secondary'
              marginRight='4'
              onClick={() => {
                setModel(vehicleDetails.selectedModel)
                setManufacturer(vehicleDetails.selectedManufacturer)
                setVariant(vehicleDetails.selectedVariant)
                setVehicleNumber(homePageDetails.vehicleNumber)
                resetField('vehicleNumber')
                resetField('model')
                resetField('variant')
                onClose()
              }}
            >
              Close
            </Button>
            <Button
              type='submit'
              onClick={async () => {
                const isFormValid = await trigger()
                if (
                  vehicleDetails.selectedManufacturer === manufacturer &&
                  vehicleDetails.selectedModel === model &&
                  vehicleDetails.selectedVariant === variant &&
                  homePageDetails.vehicleNumber === vehicleNumber &&
                  vehicleDetails.typeOfVehicle_commercial?.name === typeOfVehicleCommercial.label
                ) {
                  clearErrors()
                  onClose()
                }
                if (isFormValid) {
                  handleUpdate(
                    manufacturersDropdownData ?? [],
                    modelDropdownData ?? [],
                    variantDropdownData ?? [],
                  )
                }
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Center>
      </ModalContent>
    </Modal>
  )
}
export { EditVehicleDetail }
