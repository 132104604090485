import { equitasUrlQuery, foundationUrlQuery, getFileHeaders } from 'Services/baseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from 'Constants'
import { ISendEmail, TCustomerDetails } from 'Models/RequestModels'
import { ICustomerSearchDetails, ISpData } from 'Models/ResponseModels/Equitas'
import {
  setCustomerAccountsStatus,
  setCustomerDetailsStatus,
  setSelectedAccountId,
  updateCustomerAccounts,
  updateProposalDetailsFromCBS,
  updateUcicId,
} from 'features/Equitas'
import { store } from 'app/store'
import { updatePolicyDataFromOCR, updatePolicyDataFromRC } from 'features/Motor'
import { ToastService } from 'Services/toast.service'
import { IMLPolicyResponse, IMLRcResponse } from 'Models/ResponseModels'
import { ICustomerDetails } from 'Models/ResponseModels/Equitas/CustomerDetails'
import { ISendSMS } from 'Models/RequestModels/ACL'
import {
  sendSMSRequest,
  sendEmailRequest,
  verifyOTPforConsentRequest,
} from 'Models/RequestModels/ACL/SendEmail'

export const equitasApi = createApi({
  reducerPath: 'equitasApi',
  baseQuery: equitasUrlQuery,
  endpoints: (builder) => ({
    searchCustomer: builder.query<ICustomerSearchDetails, TCustomerDetails>({
      query: (searchParams) => ({
        url: API_ENDPOINTS.CUSTOMER_SEARCH,
        method: 'GET',
        params: searchParams,
        // headers: { 'Content-Type': 'multipart/form-data', Source: 'Web', Lang: 'EN' },
      }),
      // async onQueryStarted(_, { dispatch, queryFulfilled }) {
      //   dispatch(setSelectedAccountId(-1))
      //   try {
      //     const { data } = await queryFulfilled
      //     console.log(data.accounts)
      //     console.log('data')
      //     if (data && data.accounts.length !== 0) {
      //       dispatch(setCustomerAccountsStatus('success'))
      //       dispatch(updateUcicId(data.ucic))
      //       dispatch(updateCustomerAccounts(data.accounts))
      //       // dispatch(updateProposalDetailsFromCBS(data))
      //     } else {
      //       throw Error()
      //     }
      //   } catch (e) {
      //     console.log('e')
      //     console.log(e)
      //     dispatch(setCustomerAccountsStatus('error'))
      //     dispatch(updateCustomerAccounts([]))
      //     ToastService.showErrorToast({
      //       title: 'Error',
      //       message: 'No Accounts found!',
      //     })
      //   }
      // },
    }),
    getCustomerDetails: builder.query<ICustomerDetails, { ucic: string }>({
      query: (searchParams) => ({
        url: API_ENDPOINTS.CUSTOMER_DETAILS,
        method: 'GET',
        params: searchParams,
        // headers: { 'Content-Type': 'multipart/form-data', Source: 'Web', Lang: 'EN' },
      }),
      // async onQueryStarted(_, { dispatch, queryFulfilled }) {
      //   // setCustomerDetailsStatus
      //   dispatch(setCustomerDetailsStatus('loading'))
      //   try {
      //     const { data } = await queryFulfilled
      //     dispatch(updateProposalDetailsFromCBS(data))
      //     dispatch(setCustomerDetailsStatus('success'))
      //   } catch (e) {
      //     // dispatch()
      //     dispatch(setCustomerDetailsStatus('error'))
      //   }
      // },
    }),

    uploadPolicyPdfBank: builder.mutation<IMLPolicyResponse, FormData>({
      query: (filePayload: FormData) => {
        const token: string = store.getState().user.authenticatedUser.authtoken ?? ''
        return {
          url: API_ENDPOINTS.IMAGE_POLICY_OCR_BANK,
          method: 'POST',
          body: filePayload,
          headers: getFileHeaders(token),
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          const { vehicleNumber } = store.getState().homePage
          const registrationNoFilled = vehicleNumber.replaceAll('-', '')
          const registrationNoFromOcr = response.data.registration_number
          if (registrationNoFilled.toLowerCase() === registrationNoFromOcr?.toLowerCase()) {
            dispatch(updatePolicyDataFromOCR(response.data))
          } else {
            ToastService.showErrorToast({
              title: 'Registration no. entered does not match with Policy copy uploaded',
              message: 'Please check the details before proceeding further',
            })
          }
        } catch (e: any) {
          ToastService.showErrorToast({
            title: 'Error',
            // message: e['error']['message'],
            message: 'OCR failed, Please enter details manually',
          })
        }
      },
    }),
    uploadImageRCBank: builder.mutation<IMLRcResponse, FormData>({
      query: (filePayload: FormData) => {
        const token: string = store.getState().user.authenticatedUser.authtoken ?? ''
        return {
          url: API_ENDPOINTS.IMAGE_RC_OCR_BANK,
          method: 'POST',
          body: filePayload,
          headers: getFileHeaders(token),
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const resp = await queryFulfilled

          if (resp?.data && Object.keys(resp.data).length !== 0) {
            const { vehicleNumber } = store.getState().homePage
            const registrationNoFilled = vehicleNumber.replaceAll('-', '')
            const registrationNoFromOcr = resp.data.registration_number

            if (registrationNoFilled.toLowerCase() === registrationNoFromOcr?.toLowerCase()) {
              dispatch(updatePolicyDataFromRC(resp.data))
            } else {
              ToastService.showErrorToast({
                title: 'Registration no. entered does not match with RC uploaded',
                message: 'Please check the details before proceeding further',
              })
            }
          } else {
            ToastService.showErrorToast({
              title: 'Something went wrong',
            })
          }
          // updatePolicyDataFromRC
        } catch (e: any) {
          ToastService.showErrorToast({
            title: 'Error',
            // message: e['error']['message'],
            message: 'OCR failed, Please enter details manually',
          })
        }
      },
    }),
    sendEmail: builder.mutation<any, ISendEmail>({
      query: (payload: ISendEmail) => ({
        url: API_ENDPOINTS.SEND_EMAIL,
        method: 'POST',
        body: payload,
      }),
    }),
    sendEmailForOTP: builder.mutation<any, sendEmailRequest>({
      query: (payload: sendEmailRequest) => ({
        url: API_ENDPOINTS.SEND_EMAIL,
        method: 'POST',
        body: payload,
      }),
    }),

    sendSms: builder.mutation<any, ISendSMS>({
      query: (payload: ISendSMS) => ({
        url: API_ENDPOINTS.SEND_SMS,
        method: 'POST',
        body: payload,
      }),
    }),
    consentSendSMS: builder.mutation<any, sendSMSRequest>({
      query: (payload: sendSMSRequest) => ({
        url: API_ENDPOINTS.SEND_SMS,
        method: 'POST',
        body: payload,
      }),
    }),
    verifyOTPforConsent: builder.mutation<any, verifyOTPforConsentRequest>({
      query: (payload: verifyOTPforConsentRequest) => ({
        url: API_ENDPOINTS.VERIFY_OTP,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
})

export const {
  useLazySearchCustomerQuery,
  useLazyGetCustomerDetailsQuery,
  useUploadPolicyPdfBankMutation,
  useUploadImageRCBankMutation,
  useSendEmailMutation,
  useSendSmsMutation,
  useConsentSendSMSMutation,
  useSendEmailForOTPMutation,
  useVerifyOTPforConsentMutation,
} = equitasApi
