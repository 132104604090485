import { Box, Button, Flex, Grid, GridItem, Image, Text, useDisclosure } from '@chakra-ui/react'
import { Header } from 'Components/Header'
import SachetsForm from 'Pages/Common/Home/Forms/SachetsForm'
import React, { useEffect, useState } from 'react'
import homelogo from 'Assets/Icons/HomeLogo.svg'
import UserLogo from 'Assets/Icons/UserLogo.svg'
import PhoneLogo from 'Assets/Icons/PhoneLogo.svg'
import CreditCardLogo from 'Assets/Icons/CreditCardLogo.svg'
import { Link, useParams } from 'react-router-dom'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import { useGenerateQuoteMutation } from 'Services/API/sachet.api'
import {
  setApplicantFormDetails,
  setIsSameAddressNominee,
  setIsSameAddressSpouse,
  setQuoteId,
} from 'features/Sachets/sachetData.slice'
import { ToastService } from 'Services/toast.service'

const SachetsFormContainer = () => {
  const selectedAccount: any = useAppSelector((state) => state.customerDetails.selectedAccount)
  const fullCustomerDetails: any = useAppSelector(
    (state) => state.customerDetails.fullCustomerDetails,
  )
  const otherCustomerDetails: any = useAppSelector(
    (state) => state.customerDetails.otherCustomerDetails,
  )
  const selectedAccountMobileNumber: any = useAppSelector(
    (state) => state.customerDetails.customer_mobile_number,
  )

  const [generateQuoteMutationFunc] = useGenerateQuoteMutation()

  const params = useParams()

  const selectedProductData = useAppSelector(
    (state) => state?.sachetDataDetails.selectedProductData,
  )

  const customerAge = useAppSelector((state) => state.customerDetails.customerAge)

  const sachetDataDetails = useAppSelector((state) => state?.sachetDataDetails)
  const { ucic } = useAppSelector((state) => state.customerDetails)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const genQuoteFunc = async () => {
      try {
        const response = await generateQuoteMutationFunc({
          code: params?.code || selectedProductData?.code,
          customer_age: customerAge,
          ucic: fullCustomerDetails?.ucic,
        }).unwrap()
        dispatch(setQuoteId(response?.quote_id))
        if (response?.quote_id === null && response?.message) {
          ToastService.showInfoToast({
            title: 'Information',
            message: response?.message,
          })
        }
      } catch (error: any) {
        ToastService.showErrorToast({
          title: 'Error in generating quote, refresh the page to try again.',
          message: `Status: ${error?.status}`,
        })
      }
    }

    genQuoteFunc()
  }, [selectedProductData])

  useEffect(() => {
    // dispatch(resetSachetData())
    setApplicantFormDetails({})
  }, [])

  const { isOpen, onOpen, onClose } = useDisclosure()

  let interval: NodeJS.Timeout
  const TIMER_LIMIT = 30
  let tempTimeLeft = TIMER_LIMIT
  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setTimeLeft(0)

        tempTimeLeft = TIMER_LIMIT
        return
      }
    }, 1000)
  }

  const [planName, setPlanName] = useState('')
  useEffect(() => {
    if (params.type === 'Cancer Cover') {
      setPlanName(selectedProductData.insurer_name)
    } else {
      setPlanName(selectedProductData.name)
    }
  }, [])
  // useEffect(() => {
  //   dispatch(setIsSameAddressNominee(false))
  //   dispatch(setIsSameAddressSpouse(false))
  // }, [])
  return (
    <>
      <Header />
      <Flex direction='column' h='100%' w='full' gap='10px' p='20px' wrap='wrap' px='25px'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Flex justifyContent='center' alignItems='center'>
            <Link to='/'>
              <img src={homelogo} width='20px' />
            </Link>
            <ChevronRightIcon fontSize='30px' />
            Enrollment : {planName || '-'}
          </Flex>
          {/* <Flex justifyContent='center' alignItems='center'>
            <Button variant='outline'>View List</Button>
          </Flex> */}
        </Flex>
        <Flex
          w='full'
          gap='4'
          wrap={{ base: 'wrap', lg: 'nowrap' }}
          justifyContent='start'
          alignItems='start'
          direction='row'
        >
          {/* <Flex w='full' gap='4' wrap='wrap' justifyContent='start' alignItems='start' direction='row'> */}
          <Flex direction='column' gap='3' width='450px'>
            {/* <Flex direction='column' w='38%' gap='3'> */}
            <Box
              bgColor='white'
              py='4'
              borderRadius='11px'
              border='1px'
              box-shadow='0px 3px 22px #00488030'
            >
              <Flex justifyContent='center' alignItems='center' px='4'>
                <Grid templateColumns='repeat(2, 1fr)' gap={4} fontSize='15px' w='full'>
                  <GridItem>
                    <Flex justifyContent='start' alignItems='center' gap='2'>
                      <img src={UserLogo} width='17px' />
                      <Text fontSize='13px'>{selectedAccount?.account_title || '-'}</Text>
                    </Flex>
                  </GridItem>
                  <GridItem>
                    <Flex justifyContent='start' alignItems='center' gap='2'>
                      <img src={PhoneLogo} width='16px' />

                      <Text>
                        {selectedAccountMobileNumber && selectedAccountMobileNumber.length == 12
                          ? `+91 ${selectedAccountMobileNumber.slice(2)}`
                          : selectedAccountMobileNumber ?? '-'}
                      </Text>
                    </Flex>
                  </GridItem>
                  <GridItem>
                    <Flex justifyContent='start' alignItems='center' gap='2'>
                      <img src={CreditCardLogo} width='17px' />

                      <Text>{selectedAccount?.show_account_number || '-'}</Text>
                    </Flex>
                  </GridItem>
                </Grid>
              </Flex>
            </Box>
            <Box
              bgColor='white'
              px='2'
              py='4'
              borderRadius='11px'
              border='1px'
              box-shadow='0px 3px 22px #00488030'
            >
              <Flex direction='column' justifyContent='center' alignItems='startx' gap='3' px='2'>
                <Flex justifyContent='space-between' alignItems='center' w='full' gap='3'>
                  <Image alt='Logo' src={selectedProductData?.insurer_logo || ''} h='12' />
                  <Flex direction='column' justifyContent='center' alignItems='start' gap='1'>
                    <Text fontWeight='bold' fontSize='14px'>
                      {selectedProductData?.code === 'CIC'
                        ? selectedProductData?.insurer_name
                        : selectedProductData?.name}
                    </Text>
                    {selectedProductData && selectedProductData?.code !== 'CIC' && (
                      <Text color='gray.400' fontSize='13px'>
                        {selectedProductData?.description || '-'}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {selectedProductData && selectedProductData?.code !== 'CIC' && (
                  <Text fontSize='14px'>* {selectedProductData?.disclaimer || '-'}</Text>
                )}
                {selectedProductData && selectedProductData?.code !== 'CIC' && (
                  <Flex justifyContent='space-between' alignItems='center' w='full'>
                    <Text fontSize='15px'>
                      Premium{' '}
                      <Text color='gray.400' display='inline' fontSize='12px'>
                        (Incl. of GST)
                      </Text>
                    </Text>
                    <Text fontSize='17px' fontWeight='bolder'>
                      ₹{selectedProductData?.premium || '-'}/year
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Flex>
          <SachetsForm
            selectedProductData={selectedProductData}
            selectedAccount={selectedAccount}
            fullCustomerDetails={fullCustomerDetails}
            otherCustomerDetails={otherCustomerDetails}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default SachetsFormContainer
