import { Box, Center, Flex, FormLabel, Grid, Textarea, useMediaQuery } from '@chakra-ui/react'
import { ElevatedButton, TextInput } from 'Core'

const RegisterDistributer = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <Box paddingX='5'>
      <Center paddingY='4' fontSize='2xl' fontWeight='bold'>
        Register As Distributer
      </Center>
      <Grid gridTemplateColumns={isLargerThan768 ? '1fr 1fr' : '1fr'}>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>First Name*</FormLabel>
          <TextInput placeholder='Enter First Name' />
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Last Name*</FormLabel>
          <TextInput placeholder='Enter Last Name' />
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Contact Number*</FormLabel>
          <TextInput placeholder='Enter Contact No.' />
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Date of Birth*</FormLabel>
          <TextInput placeholder='Enter DOB' />
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Email*</FormLabel>
          <TextInput placeholder='Enter Email' />
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Gender*</FormLabel>
          <TextInput placeholder='Select Gender' />
        </Flex>
      </Grid>
      <Grid gridTemplateColumns={isLargerThan768 ? '1fr 1fr 1fr' : '1fr'}>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>Pincode*</FormLabel>
          <TextInput placeholder='Enter Pincode' />
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>City*</FormLabel>
          <TextInput placeholder='Enter City' />
        </Flex>
        <Flex direction='column' marginY='4' marginX='6'>
          <FormLabel>State*</FormLabel>
          <TextInput placeholder='Select State' />
        </Flex>
      </Grid>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Address*</FormLabel>
        <Textarea placeholder='Enter Address' />
      </Flex>
      <Center paddingY='6'>
        <ElevatedButton>Submit</ElevatedButton>
      </Center>
    </Box>
  )
}

export { RegisterDistributer }
