import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import logo from 'Assets/logo2.png'
import { useDeviceType } from 'app/hooks'
import { useLocation } from 'react-router-dom'

const DashboardHeading = ({ title }: { title: any }) => {
  const { isDesktop, isMobile, isTablet } = useDeviceType()

  const EXCEPTION_HEADER_TOGGLE: string[] = [
    '/my-leads',
    '/sales/motor',
    '/sales/health',
    '/sales/life',
    '/sales/sachet',
    '/sales-summary/motor',
    '/ticket-creation',
    '/support-listing',
  ]
  const location = useLocation()

  return (
    <Flex
      direction={isMobile ? 'column' : 'row'}
      backgroundColor='#fff'
      alignItems='center'
      paddingY='2'
    >
      {EXCEPTION_HEADER_TOGGLE.indexOf(location.pathname) === -1 && (
        <Flex
          alignItems='center'
          gridColumn='2'
          padding='1'
          w='120px'
          h='45px'
          position={isMobile ? 'relative' : 'absolute'}
        >
          <img src={logo} alt='logo' />
        </Flex>
      )}
      <Flex justifyContent='center' width='100%'>
        <Text fontSize='3xl'>{title}</Text>
      </Flex>
    </Flex>
  )
}

export { DashboardHeading }
