import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ModalFooter,
  Flex,
} from '@chakra-ui/react'
import { Select as Select2 } from 'chakra-react-select'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { BimabossDatePicker } from 'Core'
import { useGetInsurersQuery } from 'Services/API/motor.api'
import { useState } from 'react'
import { updateVehicleInsuranceForm, updateClaimsMadeInExistingPolicy } from 'features/Motor'
import { addDays } from 'date-fns'

const EditPolicyDetails = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}) => {
  const ncbDefaultField = {
    label: 'Select NCB',
    value: '',
  }
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const [registrationDate, setRegistrationDate] = useState(
    insuranceDetails.existingPolicyExpiryDate ?? '',
  )
  const [insurer, setInsurer] = useState(insuranceDetails.previousInsurer)
  const [policyType, setPolicyType] = useState(insuranceDetails.previousPolicyType)
  // const [ncb, setNcb] = useState(insuranceDetails.ncb)
  const [claims, setClaims] = useState(insuranceDetails.claimsMadeInExistingPolicy)
  const [ncb, setNcb] = useState<{
    value: string
    label: string
  } | null>(
    insuranceDetails.ncb
      ? {
          label: `${insuranceDetails.ncb}%`,
          value: insuranceDetails.ncb,
        }
      : ncbDefaultField,
  )

  const { data: insurersDropdownData, isFetching: isInsurersDataLoading } = useGetInsurersQuery({
    insurer_type: 'motor',
  })
  const dispatch = useAppDispatch()

  const getExistingNoClaimBonusArr = (): number[] => {
    return [0, 20, 25, 35, 45, 50]
  }

  const handleUpdate = () => {
    if (
      insuranceDetails.previousInsurer !== insurer ||
      insuranceDetails.previousPolicyType !== policyType ||
      insuranceDetails.existingPolicyExpiryDate !== registrationDate ||
      insuranceDetails.claimsMadeInExistingPolicy !== claims ||
      insuranceDetails.ncb !== ncb?.value
    ) {
      dispatch(
        updateVehicleInsuranceForm({
          previous_policy_end_date: registrationDate,
          previous_insurer: insurer ?? { code: '', name: '' },
          previous_policy_type: policyType ?? { value: '', name: '' },
          ncb: ncb?.value ?? '',
          dontRemember: insuranceDetails.hasPreviousPolicy,
          TpPreviousPolicyNumber: insuranceDetails.TpPreviousPolicyNumber ?? '',
          TpPreviousInsurerName: insuranceDetails.TpPreviousInsurerName ?? { code: '', name: '' },
          tp_policy_start_date: insuranceDetails.tpPolicyStartDate ?? '',
          tp_policy_end_date: insuranceDetails.tpPolicyEndDate ?? '',
        }),
      )
      dispatch(updateClaimsMadeInExistingPolicy(claims))
      onClose()
    } else {
      onClose()
    }
  }
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        setInsurer(insuranceDetails.previousInsurer)
        setPolicyType(insuranceDetails.previousPolicyType)
        setRegistrationDate(insuranceDetails.existingPolicyExpiryDate ?? '')
        setNcb({
          label: `${insuranceDetails.ncb}%`,
          value: insuranceDetails.ncb?.toString() ?? '',
        })
        setClaims(insuranceDetails.claimsMadeInExistingPolicy)
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Policy Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Flex direction='column' marginY='2'>
              <FormLabel>Previous Insurer</FormLabel>
              <Select2
                menuPosition='fixed'
                isLoading={isInsurersDataLoading}
                selectedOptionColor='primary'
                defaultValue={
                  insurer != null
                    ? {
                        label: insurer.name ?? '',
                        value: insurer.code ?? '',
                      }
                    : {
                        label: '',
                        value: '',
                      }
                }
                onChange={(item) => {
                  setInsurer({
                    code: item?.value ?? '',
                    name: item?.label ?? '',
                  })
                }}
                options={insurersDropdownData?.map((insurer) => {
                  return {
                    label: insurer.name,
                    value: insurer.code,
                  }
                })}
              />
            </Flex>
            <Flex direction='column' marginY='2'>
              <FormLabel>Previous Policy Type</FormLabel>
              {insuranceDetails.isSaod ? (
                <Input disabled value='StandAlone OD'></Input>
              ) : (
                <Select2
                  menuPosition='fixed'
                  selectedOptionColor='primary'
                  defaultValue={
                    insuranceDetails.previousInsurer != null
                      ? {
                          label: policyType?.name,
                          value: policyType?.value,
                        }
                      : {
                          label: '',
                          value: '',
                        }
                  }
                  onChange={(item) => {
                    setPolicyType({ value: item?.value ?? '', name: item?.label ?? '' })
                  }}
                  options={[
                    {
                      label: 'Comprehensive',
                      value: 'CO',
                    },
                    {
                      label: 'Third Party',
                      value: 'TP',
                    },
                  ]}
                />
              )}
            </Flex>
            <FormLabel>Existing Policy Expiry Date</FormLabel>
            <BimabossDatePicker
              maxDate={addDays(new Date(), 90)}
              inputDate={registrationDate}
              setInputDate={setRegistrationDate}
            />
            {policyType?.value !== 'TP' && (
              <FormLabel marginTop='2'>Claims Made In Existing Policy</FormLabel>
            )}
            {policyType?.value !== 'TP' && (
              <Select2
                menuPosition='fixed'
                selectedOptionColor='primary'
                defaultValue={
                  insuranceDetails.claimsMadeInExistingPolicy
                    ? { label: 'Yes', value: true }
                    : { label: 'No', value: false }
                }
                onChange={(item) => setClaims(item?.value ?? false)}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
              ></Select2>
            )}
            {/* <Select marginBottom='2'>
              <option>No</option>
            </Select> */}
            {claims || policyType?.value === 'TP' ? null : (
              <FormLabel marginTop='2'>Existing No Claim Bonus</FormLabel>
            )}
            {claims || policyType?.value === 'TP' ? null : (
              <Select2
                menuPosition='fixed'
                placeholder='Select NCB'
                selectedOptionColor='primary'
                value={ncb}
                onChange={(item) => {
                  setNcb(item)
                }}
                options={getExistingNoClaimBonusArr().map((num) => {
                  return {
                    label: `${num}%`,
                    value: num.toString(),
                  }
                })}
              />
            )}
          </FormControl>
        </ModalBody>
        <Center>
          <ModalFooter>
            <Button
              marginRight='4'
              onClick={() => {
                setInsurer(insuranceDetails.previousInsurer)
                setPolicyType(insuranceDetails.previousPolicyType)
                setRegistrationDate(insuranceDetails.existingPolicyExpiryDate ?? '')
                setNcb({
                  label: `${insuranceDetails.ncb}%`,
                  value: insuranceDetails.ncb?.toString() ?? '',
                })
                setClaims(insuranceDetails.claimsMadeInExistingPolicy)
                onClose()
              }}
              variant='outline'
              colorScheme='secondary'
            >
              Close
            </Button>
            <Button onClick={handleUpdate}>Update</Button>
          </ModalFooter>
        </Center>
      </ModalContent>
    </Modal>
  )
}

export { EditPolicyDetails }
