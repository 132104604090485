import { useDeviceType } from 'app/hooks'
import React from 'react'
import { InformationCardDesktop } from './Desktop'
import { IInformationCardProps } from './interface'
import { InformationCardMobile } from './Mobile'

export const InformationCard = (props: IInformationCardProps) => {
  const { isDesktop } = useDeviceType()
  return (
    <>{isDesktop ? <InformationCardDesktop {...props} /> : <InformationCardMobile {...props} />}</>
  )
}
