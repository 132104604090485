import { Flex } from '@chakra-ui/react'
import { UseFormReturn } from 'react-hook-form'
import { TVehicleInsuranceFormType } from '../Forms/FormModel/VehicleInsurance'
import { VehicleDetailsDesktop } from './VehicleDetailsDesktop'
import { VehicleInsuranceDesktop } from './VehicleInsuranceDesktop'

interface IVehicleInsuranceDetailsDesktop {
  form: UseFormReturn<TVehicleInsuranceFormType, any>
}

const VehicleInsuranceDetailsDesktop = (props: IVehicleInsuranceDetailsDesktop) => {
  const { form } = props
  return (
    <Flex border='1px' borderRadius='2xl'>
      <VehicleDetailsDesktop />
      <VehicleInsuranceDesktop form={form} />
    </Flex>
  )
}

export { VehicleInsuranceDetailsDesktop }
