import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Select } from 'chakra-react-select'
import { onlyAlphabetsRegex } from 'Constants'
import { BimabossDatePicker, ElevatedButton, ErrorText, TextInput } from 'Core'
import { differenceInYears, subYears } from 'date-fns'
import { updateSpConsentStatus } from 'features/Equitas'
import { updateProposalDetails } from 'features/Motor'

import { useEffect, useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useGetRelationshipsQuery } from 'Services/API/motor.api'
import { getDateFromDDMMYYYY } from 'Services/general'
import { TMotorProposalFormType } from './FormModel'
import { ProposalSpTaggingForm } from './ProposalSpTaggingForm'
import { ProposalSubmitModal } from './ProposalSubmitModal'

interface IProposalNomineeDetailsForm {
  form: UseFormReturn<TMotorProposalFormType, any>
  isNomineeMandatory: boolean
}

const ProposalNomineeDetailsForm = (props: IProposalNomineeDetailsForm) => {
  const {
    // form: { register, clearErrors, setValue, formState, trigger, watch },
    form,
    isNomineeMandatory,
  } = props

  const { proposalDetails: proposalDetailsInStore } = useAppSelector((state) => state.proposalPage)

  const dispatch = useAppDispatch()

  const submitRef = useRef<HTMLButtonElement | null>(null)

  const proposalStatus = useAppSelector((state) => state.proposalPage.proposalStatus)
  const { isIndividual } = useAppSelector((state) => state.quotationsDetails)

  const { data: relationshipResponse, isLoading: isRelationshipListingFetching } =
    useGetRelationshipsQuery()
  const [relationshipListing, setRelationshipListing] = useState<string[]>([])

  useEffect(() => {
    if (relationshipResponse) setRelationshipListing(Object.values(relationshipResponse))
  }, [relationshipResponse])

  const [selectedRelationship, setSelectedRelationship] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.nomineeRelationship
      ? {
          label: proposalDetailsInStore.nomineeRelationship,
          value: proposalDetailsInStore.nomineeRelationship,
        }
      : {
          label: 'Select Relationship',
          value: '',
        },
  )

  const [nomineeDob, setNomineeDob] = useState(proposalDetailsInStore.nomineeDob ?? '')
  const [nomineeAge, setNomineeAge] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { businessrole_name, role_type } = useAppSelector((state) => state.user.authenticatedUser)

  const { spConsentStatus } = useAppSelector((state) => state.spTagging)

  useEffect(() => {
    dispatch(updateSpConsentStatus('not-sent'))
  }, [])

  useEffect(() => {
    if (nomineeDob) console.log(nomineeDob, 'nomineedob')
    // dispatch(
    //   updateProposalDetails({
    //     nomineeDob: nomineeDob
    //   }),
    // )
    if (nomineeDob) {
      form.setValue('nomineeDob', nomineeDob)
      setNomineeAge(differenceInYears(new Date(), getDateFromDDMMYYYY(nomineeDob)).toString())
    }
  }, [nomineeDob])

  if (!isIndividual && businessrole_name === 'NSP') {
    return (
      <Flex
        className={proposalStatus === 'success' ? 'disabled' : ''}
        height='100%'
        // justifyContent='center'
        // alignItems='center'
        paddingY='1rem'
        direction='column'
        width='full'
      >
        <ProposalSpTaggingForm form={form} />
        <Button ref={submitRef} hidden type='submit'>
          hidden submit
        </Button>
        <Flex paddingY='4' justifyContent='center'>
          {spConsentStatus !== 'not-sent' && (
            <ElevatedButton
              colorScheme='primary'
              isDisabled={spConsentStatus !== 'success'}
              onClick={async () => {
                const isFormValid = await form.trigger()
                console.log('isFormValid - ', isFormValid)
                if (isFormValid) onOpen()
              }}
              isLoading={proposalStatus === 'loading'}
            >
              Submit
            </ElevatedButton>
          )}
        </Flex>
        <ProposalSubmitModal isOpen={isOpen} onClose={onClose} submitBtnRef={submitRef} />
      </Flex>
    )
  }

  if (!isIndividual) {
    return (
      <>
        <Flex
          className={proposalStatus === 'success' ? 'disabled' : ''}
          height='100%'
          justifyContent='center'
          alignItems='center'
          paddingY='1rem'
          direction='column'
          width='full'
        >
          {/* {businessrole_name === 'NSP' && <ProposalSpTaggingForm />} */}
          <Button ref={submitRef} hidden type='submit'>
            hidden submit
          </Button>
          <ElevatedButton
            colorScheme='primary'
            onClick={async () => {
              const isFormValid = await form.trigger()
              console.log('isFormValid - ', isFormValid)
              if (isFormValid) onOpen()
            }}
            isLoading={proposalStatus === 'loading'}
          >
            Submit
          </ElevatedButton>
        </Flex>
        <ProposalSubmitModal isOpen={isOpen} onClose={onClose} submitBtnRef={submitRef} />
      </>
    )
  }

  return (
    <Box
      className={proposalStatus === 'success' || spConsentStatus !== 'not-sent' ? 'disabled' : ''}
    >
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>First Name{isNomineeMandatory && '*'}</FormLabel>
        <TextInput
          {...form.register('nomineeFirstName', {
            required: isNomineeMandatory,
            pattern: onlyAlphabetsRegex,
          })}
          placeholder='Enter First Name'
        />
        {form.formState.errors.nomineeFirstName &&
          form.formState.errors.nomineeFirstName?.type === 'required' && (
            <ErrorText text='Nominee First Name is required' />
          )}
        {form.formState.errors.nomineeFirstName &&
          form.formState.errors.nomineeFirstName?.type === 'pattern' && (
            <ErrorText text='Nominee First name is Invalid' />
          )}
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Middle Name</FormLabel>
        <TextInput
          {...form.register('nomineeMiddleName', { pattern: onlyAlphabetsRegex })}
          placeholder='Enter Middle Name'
        />
        {form.formState.errors.nomineeMiddleName &&
          form.formState.errors.nomineeMiddleName?.type === 'pattern' && (
            <ErrorText text='Nominee Middle name is Invalid' />
          )}
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Last Name{isNomineeMandatory && '*'}</FormLabel>
        <TextInput
          {...form.register('nomineeLastName', {
            required: isNomineeMandatory,
            pattern: onlyAlphabetsRegex,
          })}
          placeholder='Enter Last Name'
        />
        {form.formState.errors.nomineeLastName &&
          form.formState.errors.nomineeLastName?.type === 'required' && (
            <ErrorText text='Nominee Last Name is required' />
          )}
        {form.formState.errors.nomineeLastName &&
          form.formState.errors.nomineeLastName?.type === 'pattern' && (
            <ErrorText text='Nominee Last name is Invalid' />
          )}
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Relationship{isNomineeMandatory && '*'}</FormLabel>
        <Select
          isSearchable={false}
          isLoading={isRelationshipListingFetching}
          {...form.register('nomineeRelationship', { required: isNomineeMandatory })}
          value={selectedRelationship}
          onChange={(item) => {
            setSelectedRelationship({
              label: item?.label ?? '',
              value: item?.value ?? '',
            })
            form.setValue('nomineeRelationship', item?.value ?? '')
            form.clearErrors('nomineeRelationship')
            dispatch(
              updateProposalDetails({
                nomineeRelationship: item?.value,
              }),
            )
          }}
          options={relationshipListing.map((relationship) => {
            return {
              label: relationship,
              value: relationship,
            }
          })}
          placeholder='Select Relationship'
        />
        {form.formState.errors.nomineeRelationship &&
          form.formState.errors.nomineeRelationship?.type === 'required' && (
            <ErrorText text='Nominee Relationship is required' />
          )}
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Date Of Birth{isNomineeMandatory && '*'}</FormLabel>
        <BimabossDatePicker
          inputDate={nomineeDob}
          setInputDate={setNomineeDob}
          clearErrors={() => form.clearErrors('nomineeDob')}
          maxDate={subYears(new Date(), 18)}
          {...form.register('nomineeDob', { required: isNomineeMandatory })}
        />
        {form.formState.errors.nomineeDob &&
          form.formState.errors.nomineeDob?.type === 'required' && (
            <ErrorText text='Nominee DOB is required' />
          )}
      </Flex>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>Nominee Age{isNomineeMandatory && '*'}</FormLabel>
        <TextInput
          {...form.register('nomineeAge', { required: false })}
          isReadOnly
          value={nomineeAge}
          placeholder='Enter Nominee Age'
        />
      </Flex>
      {businessrole_name !== 'NSP' && (
        <Center paddingY='4'>
          <Button ref={submitRef} hidden type='submit'>
            hidden submit
          </Button>
          <ElevatedButton
            colorScheme='primary'
            // type='submit'
            onClick={async () => {
              const isFormValid = await form.trigger()
              console.log(form.trigger())
              if (isFormValid) onOpen()
            }}
            isLoading={proposalStatus === 'loading'}
          >
            Submit
          </ElevatedButton>
        </Center>
      )}
      <ProposalSubmitModal isOpen={isOpen} onClose={onClose} submitBtnRef={submitRef} />
    </Box>
  )
}

export { ProposalNomineeDetailsForm }
