import {
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  ModalFooter,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import failedImg from 'Assets/Common/failed.png'
import { formatCurr } from 'Pages/Life/Sachet/ConsentForm'
import { useSpTaggingSendOtpMutation } from 'Services/API/authentication.api'
import { ToastService } from 'Services/toast.service'
import { useEffect, useState } from 'react'
import { useLazyGetPaymentStatusQuery } from 'Services/API/sachet.api'

interface IPaymentStatus {
  id?: number
  transaction_number: string
  member_id?: string
  sp_id?: number
  insurance: string
  transaction_date: string
  amount: string
  status: string
  mode_of_payment: string
  quotation?: number
  masterPolicyNumber?: string
  commission_status?: string | undefined
}

export const SachetPaymentFailureDialog = ({
  email,
  paymentStatusData,
  backHome,
  handleResendLink,
  paymentData,
}: {
  email?: any
  paymentStatusData: IPaymentStatus
  backHome: boolean
  handleResendLink: any
  paymentData: any
}) => {
  const [resendLink, setResendLink] = useState(false)
  const [sendOtp, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
    useSpTaggingSendOtpMutation()
  const TIMER_LIMIT = 60
  let tempTimeLeft = TIMER_LIMIT
  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  let interval: NodeJS.Timeout

  const resetTimer = (interval: any) => {
    clearInterval(interval)

    setResendLink(false)

    setTimeLeft(TIMER_LIMIT)
    tempTimeLeft = TIMER_LIMIT
  }

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft: number) => prevTimeLeft - 1)
      } else {
        // clearInterval(interval)
        // setResendLink(false)
        // setTimeLeft(TIMER_LIMIT)
        // tempTimeLeft = TIMER_LIMIT
        resetTimer(interval)
      }
    }, 1000)
  }
  const navigate = useNavigate()

  useEffect(() => {
    if (paymentData?.status === 'Failed' || paymentData?.status === 'Success') {
      resetTimer(interval)

      // clearInterval(interval)

      // setResendLink(false)

      // setTimeLeft(TIMER_LIMIT)
      // tempTimeLeft = TIMER_LIMIT
    }
  }, [paymentData])

  return (
    <Flex paddingY='1rem' paddingX='1.5rem' gap='1rem' direction='column' alignItems='center'>
      <Image width='4rem' src={failedImg} />
      <Text fontSize='1.4rem' fontWeight='extrabold'>
        Payment Failed!
      </Text>
      <Text fontSize='1rem'>Unfortunately your payment couldn’t go through!</Text>
      <Divider />
      <Grid templateColumns='repeat(2, 1fr)' gap={4} fontSize='0.8rem'>
        <GridItem>Transaction Reference No:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{paymentStatusData?.transaction_number}</b>
        </GridItem>
        {paymentStatusData?.masterPolicyNumber && (
          <>
            <GridItem>Master Policy Number:</GridItem>
            <GridItem textAlign='end'>
              {' '}
              <b>{paymentStatusData?.masterPolicyNumber}</b>
            </GridItem>
          </>
        )}
        <GridItem>Amount:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{formatCurr(parseFloat(paymentStatusData?.amount))}</b>
        </GridItem>
        <GridItem>Insurance:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{paymentStatusData?.insurance}</b>
        </GridItem>
        <GridItem>Mode Of Payment:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{paymentStatusData?.mode_of_payment}</b>
        </GridItem>
      </Grid>

      {backHome && (
        <>
          <Divider />
          <Center>
            <Text fontSize='0.8rem' textAlign='center'>
              Note: You can re-send paying link again by clicking below.
            </Text>
          </Center>
        </>
      )}

      {backHome && (
        <ModalFooter>
          <Button
            onClick={() => {
              navigate('/customer-account')
            }}
            marginX='0.5rem'
          >
            Back Home
          </Button>
          <Button
            onClick={() => {
              handleResendLink()
              setResendLink(true)

              startTimer()
              ToastService.showInfoToast({
                title: 'Link has been resend to the customer',
              })

              // startTimer()
            }}
            disabled={resendLink}
            marginX='0.5rem'
          >
            {!resendLink ? (
              `Resend Link`
            ) : (
              <Flex justifyContent='center' alignItems='center' gap='2'>
                Resend Link
                <Spinner />
              </Flex>
            )}

            {/* {!resendLink ? `Resend Link` : `Resend Link  (00:${timeLeft})`} */}
          </Button>
        </ModalFooter>
      )}
      {/* {backHome && (
          <ModalFooter>
            <Button
              onClick={async () => {
                try {
                  await sendOtp({
                    email: email ?? '',
                  })
  
                  ToastService.showInfoToast({
                    title: 'OTP sent successfully',
                  })
                } catch (error) {
                  ToastService.showErrorToast({
                    title: 'Failed to sent OTP',
                  })
                }
              }}
              marginX='0.5rem'
            >
              Re-Send Payment Link
            </Button>
          </ModalFooter>
        )} */}
      {/* <Text>Confirmation e-mail sent to: abcd@test.com</Text>
        <Text>Order Number: 785883000469</Text> */}
    </Flex>
  )
}
