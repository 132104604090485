import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
// import { IPremiumData } from './PremiumData'
// import { IPlanQuoteResponse } from './LifePlanQuotes'
import { ISingleQuotesResponse, RecommendedPlan } from 'Models/ResponseModels/Life'

export interface IPlanDataStoreValues {
  isViewMoreChecked: boolean
  showAllPlans: boolean
  // quoteResponse?: RecommendedQuotes
  planQuoteResponse?: ISingleQuotesResponse
  isGetQuotationsApiLoading: boolean
  // premium_data?: IPremiumData
  selected_plan?: RecommendedPlan
  selected_plan_amount: string
  isBiFormGenerated: boolean
  filter?: Record<string, string | number | boolean | string[]>
  proposal_id?: string
  lead_id?: string
  suitabilityId?: string
  annual_premium?: number
  sumAssured?: number
  ridersQuestions?: INestedQuestionSet[]
  rider?: Record<string, string | number | boolean | string[]>
  riderAffectKeys?: Record<string, string | number | boolean | string[]>
}
const initialState: IPlanDataStoreValues = {
  isViewMoreChecked: false,
  showAllPlans: false,
  selected_plan_amount: '',
  isBiFormGenerated: false,
  proposal_id: '',
  lead_id: '',
  isGetQuotationsApiLoading: false,
}
const planDataSlice = createSlice({
  name: 'planDataSlice',
  initialState: initialState,
  reducers: {
    setIsViewMoreChecked: (state, action: PayloadAction<boolean>) => {
      state.isViewMoreChecked = action.payload
    },
    setShowAllPlans: (state, action: PayloadAction<boolean>) => {
      state.showAllPlans = action.payload
    },
    // updateQuoteResponse: (state, action: PayloadAction<IRecommendedQuotes | undefined>) => {
    //   state.quoteResponse = action.payload
    // },
    updatePlanQuoteResponse: (state, action: PayloadAction<ISingleQuotesResponse | undefined>) => {
      state.planQuoteResponse = action.payload
    },
    updateQuotationsApiLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetQuotationsApiLoading = action.payload
    },
    // updateSelectedPlan: (state, action: PayloadAction<IRecommendedPlan>) => {
    //   state.selected_plan = action.payload
    // },
    // updateSelectedPlanAmount: (state, action: PayloadAction<string>) => {
    //   state.selected_plan_amount = action.payload
    // },
    updateIsBiFormGenerated: (state, action: PayloadAction<boolean>) => {
      state.isBiFormGenerated = action.payload
    },
    updateFilter: (
      state,
      action: PayloadAction<Record<string, string | number | boolean | string[]> | undefined>,
    ) => {
      state.filter = action.payload
    },
    updateProposalId: (state, action: PayloadAction<string>) => {
      state.proposal_id = action.payload
    },
    updateLeadId: (state, action: PayloadAction<string>) => {
      state.lead_id = action.payload
    },
    updateSuitabilityId: (state, action: PayloadAction<string>) => {
      state.suitabilityId = action.payload
    },
    updateRiderQuestion: (state, action: PayloadAction<INestedQuestionSet[]>) => {
      state.ridersQuestions = action.payload
    },
    updateRider: (
      state,
      action: PayloadAction<Record<string, string | number | boolean | string[]> | undefined>,
    ) => {
      state.rider = action.payload
    },
    clearRiderFromStore: (state) => {
      state.rider = undefined
    },
    updateRiderAffectKeys: (
      state,
      action: PayloadAction<Record<string, string | number | boolean | string[]> | undefined>,
    ) => {
      state.riderAffectKeys = action.payload
    },
    updateAllPlanData: (state, action: PayloadAction<IPlanDataStoreValues>) => {
      const {
        isViewMoreChecked,
        showAllPlans,
        // quoteResponse,
        planQuoteResponse,
        isGetQuotationsApiLoading,
        // premium_data,
        selected_plan,
        selected_plan_amount,
        isBiFormGenerated,
        filter,
        proposal_id,
        lead_id,
        suitabilityId,
        annual_premium,
        sumAssured,
        ridersQuestions,
        rider,
        riderAffectKeys,
      } = action.payload
      if (isViewMoreChecked !== undefined) {
        state.isViewMoreChecked = isViewMoreChecked
      }
      if (showAllPlans !== undefined) {
        state.showAllPlans = showAllPlans
      }
      // if (quoteResponse !== undefined) {
      //   state.quoteResponse = quoteResponse
      // }
      if (planQuoteResponse !== undefined) {
        state.planQuoteResponse = planQuoteResponse
      }
      if (isGetQuotationsApiLoading !== undefined) {
        state.isGetQuotationsApiLoading = isGetQuotationsApiLoading
      }
      // if (premium_data !== undefined) {
      //   state.premium_data = premium_data
      // }
      if (selected_plan !== undefined) {
        state.selected_plan = selected_plan
      }
      if (selected_plan_amount !== undefined) {
        state.selected_plan_amount = selected_plan_amount
      }
      if (isBiFormGenerated !== undefined) {
        state.isBiFormGenerated = isBiFormGenerated
      }
      if (filter !== undefined) {
        state.filter = filter
      }
      if (proposal_id !== undefined) {
        state.proposal_id = proposal_id
      }
      if (lead_id !== undefined) {
        state.lead_id = lead_id
      }
      if (suitabilityId !== undefined) {
        state.suitabilityId = suitabilityId
      }
      if (annual_premium !== undefined) {
        state.annual_premium = annual_premium
      }
      if (sumAssured !== undefined) {
        state.sumAssured = sumAssured
      }
      if (ridersQuestions !== undefined) {
        state.ridersQuestions = ridersQuestions
      }
      if (rider !== undefined) {
        state.rider = rider
      }
      if (riderAffectKeys !== undefined) {
        state.riderAffectKeys = riderAffectKeys
      }
    },
    resetAllPlanData: () => initialState,
  },
})
const { reducer } = planDataSlice
const {
  updatePlanQuoteResponse,
  // updateQuoteResponse,
  // updateSelectedPlan,
  // updateSelectedPlanAmount,
  updateIsBiFormGenerated,
  updateFilter,
  updateProposalId,
  updateLeadId,
  updateSuitabilityId,
  updateRiderQuestion,
  updateRider,
  clearRiderFromStore,
  updateQuotationsApiLoading,
  updateRiderAffectKeys,
  setIsViewMoreChecked,
  setShowAllPlans,
  updateAllPlanData,
  resetAllPlanData,
} = planDataSlice.actions
export {
  reducer as planDataReducer,
  updatePlanQuoteResponse,
  // updateQuoteResponse,
  // updateSelectedPlan,
  // updateSelectedPlanAmount,
  updateIsBiFormGenerated,
  updateFilter,
  updateProposalId,
  updateLeadId,
  updateSuitabilityId,
  updateRiderQuestion,
  updateRider,
  clearRiderFromStore,
  updateQuotationsApiLoading,
  updateRiderAffectKeys,
  setIsViewMoreChecked,
  setShowAllPlans,
  updateAllPlanData,
  resetAllPlanData,
}
