import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IState {
  lead_uid?: string
  drop_off?: string
}

const initialState: IState = {}

const leadDetails = createSlice({
  name: 'leadDetails',
  initialState: initialState,
  reducers: {
    updateLeadID: (
      state,
      action: PayloadAction<{
        lead_uid: string
      }>,
    ) => {
      const { lead_uid } = action.payload
      state.lead_uid = lead_uid
    },
    updateDropOff: (
      state,
      action: PayloadAction<{
        drop_off: string
      }>,
    ) => {
      const { drop_off } = action.payload
      state.drop_off = drop_off
    },
    resetLeadDetails: () => initialState,
  },
})

const { reducer } = leadDetails

const { updateDropOff, updateLeadID, resetLeadDetails } = leadDetails.actions

export { updateDropOff, updateLeadID, resetLeadDetails, reducer as leadDetailsReducer }
