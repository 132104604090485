import { Outlet, useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Skeleton,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { InformationCard } from 'Core/Cards/InformationCard'
import { Header } from 'Components'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { useEffect, useState } from 'react'
import { BsPencilFill } from 'react-icons/bs'
import { getArrayFromObj, IndianRupeeFormatFromString, copyToClipboard } from 'Services/general'
import { AddonComponent } from './AddOns/AddonComponent'
import { LeftComponent } from './ProposerAndInsurerDetails/Components/LeftComponent'
import { DataComponent } from './ProposerAndInsurerDetails/DataComponent'
import { PreExistingDiseasesForm } from './HealthQuestions/PreExistingDiseaseForm'
import { LifeStyleForm } from './HealthQuestions/LifeStyleForm'
import { PaymentForm } from './Payment/PaymentForm'
import { FaUserPlus } from 'react-icons/fa'
import { setCustomerConsentIndentifier } from 'features/Health/cart.health.slice'
import { getSelfSpouseKidsText } from '../QuotationPage/helper'

export const ProposalPage = () => {
  const { isDesktop, isTablet } = useDeviceType()
  const { selectedQuotes, cartDetails } = useAppSelector((state) => state.cart)
  const { isProposerIncluded, memberDetails } = useAppSelector((state) => state.memberDetails)
  const [showLifeStyleQuestions, setShowLifeStyleQuestions] = useState(false)

  const [showInsurerDetails, setShowInsurerDetails] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setCustomerConsentIndentifier(''))
  }, [])

  const [currentIndex, setCurrentIndex] = useState(0)
  const [addonsNextButtonDisabled, setAddonsNextButtonDisabled] = useState(true)
  const { lead_uid } = useAppSelector((state) => state.leadDetails)
  const navigate = useNavigate()

  useEffect(() => {
    let disabledStatus = false
    getArrayFromObj(selectedQuotes).forEach((quote) => {
      if (quote.loading) {
        disabledStatus = true
      }
    })
    setAddonsNextButtonDisabled(disabledStatus)
  }, [selectedQuotes])

  const onProposalDetailsSubmit = () => {
    setTimeout(() => {
      setShowInsurerDetails(true)
      setShowLoader(false)
    }, 1000)
    setCurrentIndex(2)
  }

  const onProposalDetailsBackPress = () => {
    setCurrentIndex(0)
  }

  const getMemberType = (type: string, memberList: any) => {
    console.log(type)
    switch (type) {
      case 'selfSpouseKids':
        return getSelfSpouseKidsText(memberDetails, isProposerIncluded)
      case 'parents':
        return 'Parents'
      case 'allMembers':
        return 'All Members'
      default:
        return 'All Members'
    }
  }

  const onMedicalQuestionBackPress = () => {
    setCurrentIndex(2)
  }
  const onLifestyleQuestionBackPress = () => {
    setCurrentIndex(4)
  }
  return (
    <>
      <Header />
      <Box bgColor='#F6F6F6' height='fit-content' pb='4'>
        <Container maxW='1400px'>
          <Flex justifyContent={'flex-end'} my='3'>
            <Button
              variant={'outline'}
              colorScheme={'secondary'}
              rounded='full'
              size='sm'
              onClick={() => copyToClipboard(lead_uid ?? '')}
            >
              <Flex gap='1'>
                <FaUserPlus color='secondary' />
                <Text>{lead_uid}</Text>
              </Flex>
            </Button>
          </Flex>
          <Grid style={{ gridTemplateColumns: '1fr 1fr' }} gap={2}>
            {Object.keys(selectedQuotes).map((quote) => {
              return (
                <GridItem key={selectedQuotes[quote].code}>
                  <Box borderRight='1px solid #D8D8D8'>
                    <InformationCard
                      id={selectedQuotes[quote].code}
                      insurerName={selectedQuotes[quote].insuranceName}
                      sumAssured={selectedQuotes[quote].sumInsured.toString()}
                      policyFor={getMemberType(
                        selectedQuotes[quote].memberType!,
                        selectedQuotes[quote].addedMemberTypes,
                      )}
                      pincode={selectedQuotes[quote].pincode}
                      policyForr=''
                      finalPremium={selectedQuotes[quote].premium.toString()}
                      image={selectedQuotes[quote].insurerLogo}
                      loading={selectedQuotes[quote].loading}
                    />
                  </Box>
                </GridItem>
              )
            })}
          </Grid>
          {isDesktop ? (
            <Outlet />
          ) : (
            <Accordion index={currentIndex}>
              <Flex direction='column' gap='8' mb='10'>
                <AccordionItem border='1px solid #DEDEDE' backgroundColor='white'>
                  <h2 onClick={() => setCurrentIndex(0)}>
                    <AccordionButton boxShadow='lg' _expanded={{ display: 'none' }}>
                      <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                        Add ons
                      </Box>

                      <BsPencilFill color='appGrey' size='18' cursor='pointer' />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {getArrayFromObj(selectedQuotes).map((quote) => {
                      return (
                        <Box key={quote.quoteNumber}>
                          <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                            01. Add on Page{' '}
                            <span className='text-[#B3B3B3] text-sm'>
                              ( {quote.insuranceName} )
                            </span>
                          </Box>
                          <AddonComponent quoteData={quote} key={quote.quoteNumber} />
                        </Box>
                      )
                    })}
                    <Box className='flex justify-center my-4'>
                      <Button
                        variant='solid'
                        colorScheme='primary'
                        rounded='full'
                        w='40%'
                        onClick={() => setCurrentIndex(1)}
                        disabled={addonsNextButtonDisabled}
                      >
                        Next
                      </Button>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border='1px solid #DEDEDE' backgroundColor='white'>
                  <h2
                    onClick={() => {
                      if (currentIndex > 1) {
                        setCurrentIndex(1)
                      }
                    }}
                  >
                    <AccordionButton boxShadow='lg'>
                      <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                        Proposer Details
                      </Box>
                      <BsPencilFill color='appGrey' size='18' cursor='pointer' />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <LeftComponent
                      onBackPress={onProposalDetailsBackPress}
                      onSubmit={onProposalDetailsSubmit}
                    />
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border='1px solid #DEDEDE' backgroundColor='white'>
                  <h2
                    onClick={() => {
                      if (currentIndex > 2) {
                        setCurrentIndex(2)
                      }
                    }}
                  >
                    <AccordionButton boxShadow='lg'>
                      <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                        Insured Details
                      </Box>
                      <BsPencilFill color='appGrey' size='18' cursor='pointer' />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {showInsurerDetails && (
                      <DataComponent onSubmit={() => setCurrentIndex((prev) => prev + 1)} />
                    )}
                    {showLoader && (
                      <Flex flexDirection='column' gap='4' p='4'>
                        <Skeleton w='full' h='8' />
                        <Spacer h='24' />
                        <Grid
                          gap='4'
                          gridTemplateColumns={
                            isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr'
                          }
                        >
                          {Array(30)
                            .fill(0)
                            .map((item, index) => (
                              <GridItem key={index}>
                                <Skeleton w='full' h='8' />
                              </GridItem>
                            ))}
                        </Grid>
                      </Flex>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border='1px solid #DEDEDE' backgroundColor='white'>
                  <h2>
                    <AccordionButton boxShadow='lg'>
                      <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                        Pre Existing Disease
                      </Box>
                      <BsPencilFill color='appGrey' size='18' cursor='pointer' />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <PreExistingDiseasesForm
                      onBackPress={onMedicalQuestionBackPress}
                      setShowLifeStyleQuestions={setShowLifeStyleQuestions}
                      onFormSubmit={() => setCurrentIndex(4)}
                    />
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border='1px solid #DEDEDE' backgroundColor='white'>
                  <h2>
                    <AccordionButton boxShadow='lg'>
                      <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                        Lifestyle Questions
                      </Box>
                      <BsPencilFill color='appGrey' size='18' cursor='pointer' />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <LifeStyleForm
                      setShowLifeStyleQuestions={setShowLifeStyleQuestions}
                      onFormSubmit={() => setCurrentIndex(5)}
                      onBackPress={onLifestyleQuestionBackPress}
                    />
                  </AccordionPanel>
                </AccordionItem>
                {/* <AccordionItem border='1px solid #DEDEDE' backgroundColor='white'>
                    <h2>
                      <AccordionButton boxShadow='lg'>
                        <Box flex='1' textAlign='left' fontWeight='bold' padding='2'>
                          Payment
                        </Box>
                        <BsPencilFill color='appGrey' size='18' cursor='pointer' />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <PaymentForm />
                    </AccordionPanel>
                  </AccordionItem> */}
              </Flex>
            </Accordion>
          )}
        </Container>
      </Box>
    </>
  )
}
