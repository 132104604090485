import { Box, Flex, Text } from '@chakra-ui/react'
import { useDeviceType, useWindowDimensions } from 'app/hooks'
import { IProgressStepItem } from './ProgressStep'

const ProgressStepItem = (props: IProgressStepItem) => {
  const { label, onTap, isActive } = props

  const { width } = useWindowDimensions()

  return (
    <Flex
      direction='column'
      onClick={isActive ? onTap : () => null}
      cursor={isActive ? 'pointer' : 'not-allowed'}
    >
      <Text userSelect='none' color='gray.500' fontSize='sm'>
        {label}
      </Text>
      <Box
        rounded='2xl'
        width={width < 500 ? '6.5rem' : '10rem'}
        height='0.3rem'
        bgColor={isActive ? 'primary.500' : 'appGrey.500'}
      ></Box>
    </Flex>
  )
}

export { ProgressStepItem }
