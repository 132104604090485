import { Box, Flex } from '@chakra-ui/react'
import { Header } from 'Components'
import { CardData } from '../CardData'
import { Form } from '../Form'

export const CashlessModalMobile = () => {
  return (
    <>
      <Header />
      <Box paddingY='4'>
        <Box backgroundColor={'appGrey'}>
          <Flex direction='column' justifyContent={'center'} alignItems='center'>
            <Box border='1px' borderColor={'appGrey.400'} roundedTop={'2xl'} w='90%'>
              <Form />
            </Box>
            <CardData />
          </Flex>
        </Box>
      </Box>
    </>
  )
}
