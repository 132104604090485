import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import nonSmoker from 'Assets/Common/nonSmoker.svg'
import { format } from 'date-fns'
import smoker from 'Assets/Common/smoker.svg'
import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Requirement } from './RequirementForm'
import { Select } from 'chakra-react-select'
import { PersonalDetails } from './PersonalDetailsPage'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from 'Core/Modal/CashlessModal/Form'
import {
  onlyAllowTypingAlphabet,
  onlyAllowTypingAlphabetNumeric,
  onlyAllowTypingNumbers,
} from 'Services/general'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { useCustomerDataRequestMutation, useLazyGetChoicesQuery } from 'Services/API/life.api'
import {
  updateLifeAssuredDetails,
  updateLifeAssuredDetailsTyped,
  updateLifeAssuredSmokerStatus,
  updateSmokerStatus,
} from 'features/Life/LifeCustomerDetails.life.slice'
import {
  updateCustomerIds,
  updateProposerData,
  updateSuitabilityData,
  updateSuitabilityStep,
} from 'features/Life/LifeQuotationDetails.slice'
import { ListingResponse } from 'Models/ResponseModels/Life'
import { mobileNumRegex, panNumRegex } from 'Constants'
import { ToastService } from 'Services/toast.service'

interface IState {
  setDob: React.Dispatch<React.SetStateAction<string>>
  setNextButton: React.Dispatch<React.SetStateAction<boolean>>
}

const PersonalDetailsForm = (props: IState) => {
  const [customerData, { data: cutomerDataFromApi, isLoading }] = useCustomerDataRequestMutation() // customerData API
  const lifeCustomerData = useAppSelector((state) => state.lifeCustomerDetails)
  const customerDetails = useAppSelector((state) => state.customerDetails)
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)

  const [salutationListing, setSalutationListing] = useState<ListingResponse[]>([])
  const [maritalStatusListing, setMaritalStatusListing] = useState<ListingResponse[]>([])
  const [genderListing, setGenderListing] = useState<ListingResponse[]>([])

  const [displayedSalutation, setDisplayedSalutation] = useState<ListingResponse>()
  const [displayedGender, setDisplayedGender] = useState<ListingResponse>()
  const [displayedMaritalStatus, setDisplayedMaritalStatus] = useState<ListingResponse>()
  // const [ageDobCal, setAgeDobCal] = useState(true)

  const [
    getChoices,
    { isLoading: choicesLoading, isError: choicesError, isSuccess: choicesSuccess },
  ] = useLazyGetChoicesQuery()

  useEffect(() => {
    initializeChoices('gender')
    initializeChoices('salutation')
    initializeChoices('marital-status')
    dispatch(updateLifeAssuredSmokerStatus('Non-Smoker'))
  }, [])

  const getTitle = () => {
    switch (lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.title) {
      case '1':
        setDisplayedSalutation(salutationListing.find((salutation) => salutation.code === 'Mr.'))
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Male'))
        return
      case '5':
        setDisplayedSalutation(salutationListing.find((salutation) => salutation.code === 'Mrs.'))
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Female'))
        return
      case '6':
        setDisplayedSalutation(salutationListing.find((salutation) => salutation.code === 'Ms.'))
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Female'))
        return
      case '22':
        setDisplayedSalutation(salutationListing.find((salutation) => salutation.code === 'Mx.'))
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Transgender'))
        return
      default:
        setDisplayedSalutation({ code: '', name: '' })
        setDisplayedGender({ code: '', name: '' })
    }
  }

  useEffect(() => {
    if (displayedGender) {
      setValue('gender', displayedGender)
    }
    if (displayedMaritalStatus) {
      setValue('marital', displayedMaritalStatus)
    }
    if (displayedSalutation) {
      setValue('title', displayedSalutation)
    }
  }, [displayedGender, displayedMaritalStatus, displayedSalutation])

  useEffect(() => {
    if (salutationListing) {
      getTitle()
    }
  }, [salutationListing])

  useEffect(() => {
    if (maritalStatusListing) {
      getMaritalStatus()
    }
  }, [maritalStatusListing])

  const getGender = (item: any) => {
    switch (item.value) {
      case 'Mr.':
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Male'))
        setValue('gender', { code: item.value, name: item.label })
        return
      case 'Mrs.':
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Female'))
        setValue('gender', { code: item.value, name: item.label })
        return
      case 'Ms.':
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Female'))
        setValue('gender', { code: item.value, name: item.label })
        return
      case 'Mx.':
        setDisplayedGender(genderListing.find((gender) => gender.code === 'Transgender'))
        setValue('gender', { code: item.value, name: item.label })
        return
      default:
        setDisplayedGender({ code: '', name: '' })
        setValue('gender', { code: '', name: '' })
    }
  }

  const getMaritalStatus = () => {
    switch (lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.mrtlSts) {
      case '1':
        setDisplayedMaritalStatus(
          maritalStatusListing.find((status) => status.code === 'Unmarried'),
        )
        return
      case '2':
        setDisplayedMaritalStatus(maritalStatusListing.find((status) => status.code === 'Married'))
        return
      case '3':
        setDisplayedMaritalStatus(maritalStatusListing.find((status) => status.code === 'Divorced'))
        return
      case '4':
        setDisplayedMaritalStatus(
          maritalStatusListing.find((status) => status.code === 'Widow(er)'),
        )
        return
      default:
        setDisplayedMaritalStatus({ code: '', name: '' })
    }
  }

  const initializeChoices = async (type: string) => {
    try {
      if (type === 'salutation') {
        const response = await getChoices({ salutation: 1 }).unwrap()
        setSalutationListing(response['salutation'])
      } else if (type === 'gender') {
        const response = await getChoices({ gender: 1 }).unwrap()
        setGenderListing(response['gender'])
      } else {
        const response = await getChoices({ marital_status: 1 }).unwrap()
        setMaritalStatusListing(response['marital_status'])
      }
    } catch (e) {
      if (type === 'salutation') {
        setSalutationListing([])
      } else if (type === 'gender') {
        setGenderListing([])
      } else {
        setMaritalStatusListing([])
      }
    }
  }

  const dispatch = useAppDispatch()
  type LifeForm = {
    title: ListingResponse
    firstName: string
    lastName: string
    dob: string
    middleName: string
    mobile: string
    landmark?: string
    pan: string
    gender: ListingResponse
    email: string
    address1: string
    address2: string
    pincode: string
    marital: ListingResponse
    dobAgeCal?: boolean
  }
  const lifeSchema = yup.object({
    title: yup.object({
      name: yup.string().required('Title is required'),
      code: yup.string().required('Title is required'),
    }),
    firstName: yup
      .string()
      .required('First Name Id is required!')
      .max(30, 'Max 30 characters allowed'),
    lastName: yup.string().required('Last name is required!').max(30, 'Max 30 characters allowed'),
    middleName: yup.string().max(30, 'Max 30 characters allowed'),
    dob: yup.string().required('Date of birth is required!'),
    mobile: yup
      .string()
      .required('Mobile Number is required!')
      .length(10, 'Mobile Number should be 10 digits')
      .matches(mobileNumRegex, 'Invalid Format'),
    ageDobCal: yup.boolean(),
    pan: yup.string().optional(),
    // .when('dobAgeCal', {
    //   is: true,
    //   then: yup.string().matches(panNumRegex, 'Invalid Format'),
    // }),
    landmark: yup.string().optional(),
    gender: yup.object({
      name: yup.string().required('Gender is required'),
      code: yup.string().required('Gender is required'),
    }),
    email: yup
      .string()
      .required('Email is required!')
      .max(50, 'Email should be less than 50 characters'),
    address1: yup.string().required('Address is required!'),
    address2: yup.string().required('Address is required!'),
    pincode: yup.string().required('Adress is required!'),
    marital: yup.object({
      name: yup.string().required('Marital Status is required'),
      code: yup.string().required('Marital Status is required'),
    }),
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting, isSubmitted, isDirty, isValid },
  } = useForm<LifeForm>({
    resolver: yupResolver(lifeSchema),
    defaultValues: {
      dobAgeCal: true,
      title: displayedSalutation,
      firstName: lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
        .frsNm
        ? lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].frsNm
        : lifeCustomerData.lifeAssuredDetailsTyped?.firstName ?? '',
      middleName: lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
        .mdlNm
        ? lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].mdlNm
        : lifeCustomerData.lifeAssuredDetailsTyped?.middleName ?? '',
      lastName: lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
        .lstNm
        ? lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].lstNm
        : lifeCustomerData.lifeAssuredDetailsTyped?.lastName ?? '',
      dob: lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.dob
        ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.dob
        : lifeCustomerData.lifeAssuredDetailsTyped?.dob ?? '',
      mobile:
        lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].mblNb
          ?.length &&
        lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].mblNb
          ?.length > 10
          ? lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].mblNb?.slice(
              2,
            ) ?? ''
          : lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
              .mblNb ?? '',

      pan: lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.panNb
        ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.panNb
        : lifeCustomerData.lifeAssuredDetailsTyped?.pan,
      gender: displayedGender,
      email: lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].email
        ? lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0].email
        : lifeCustomerData.lifeAssuredDetailsTyped?.email ?? '',
      address1:
        customerDetails.selectedAccount.EntityType === 'NRI'
          ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrAddrLn1
            ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrAddrLn1
            : lifeCustomerData.lifeAssuredDetailsTyped?.address1
          : lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn1
          ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn1
          : lifeCustomerData.lifeAssuredDetailsTyped?.address1,
      // address2: lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn2
      //   ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn2
      //   : lifeCustomerData.lifeAssuredDetailsTyped?.address2,
      address2:
        customerDetails.selectedAccount.EntityType === 'NRI'
          ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrAddrLn2
            ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrAddrLn2
            : lifeCustomerData.lifeAssuredDetailsTyped?.address2
          : lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn2
          ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn2
          : lifeCustomerData.lifeAssuredDetailsTyped?.address2,
      // pincode: lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntPncd
      //   ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntPncd
      //   : lifeCustomerData.lifeAssuredDetailsTyped?.pincode,
      pincode:
        customerDetails.selectedAccount.EntityType === 'NRI'
          ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrCty
            ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrCty
            : lifeCustomerData.lifeAssuredDetailsTyped?.pincode
          : lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntPncd
          ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntPncd
          : lifeCustomerData.lifeAssuredDetailsTyped?.pincode,
      marital: displayedMaritalStatus,
    },
  })

  const [tabIndex, setTabIndex] = useState(true)
  const { setDob, setNextButton } = props
  const navigate = useNavigate()
  const onFormSubmit = async (data: any) => {
    try {
      if (data) {
        const response = await customerData(
          lifeCustomerData.is_same
            ? {
                is_same: true,
                proposer_data: {
                  first_name: data.firstName ?? '',
                  last_name: data.lastName ?? '',
                  middle_name: data.middleName ?? '',
                  dob: data.dob ?? '',
                  marital_status: data.marital.name ?? '',
                  salutation: data.title.name ?? '',
                  gender: data.gender.name ?? '',
                  occupation: '',
                  nationality: '',
                  education: '',
                  address: data.address1 ?? '',
                  address_two: data.address2 ?? '',
                  address_three:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn3 ??
                    '',
                  landmark: data.landmark ?? '',
                  contact_number: data.mobile ?? '',
                  email: data.email ?? '',
                  pincode: data.pincode ?? '',
                  pan_number: data.pan ?? '',
                  permanent_address_line_one:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntAddrLn1 ??
                    '',
                  permanent_address_line_two:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntAddrLn2 ??
                    '',
                  permanent_address_line_three:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntAddrLn3 ??
                    '',
                  permanent_address_pincode:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntPncd ?? '',
                  overseas_address_line_one:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn1 ?? '',
                  overseas_address_line_two:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn2 ?? '',
                  overseas_address_line_three:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn3 ?? '',
                  overseas_address_pincode:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrCty ?? '',
                },
              }
            : {
                is_same: false,
                proposer_data: {
                  first_name:
                    customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0].frsNm ?? '',
                  last_name:
                    customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0].lstNm ?? '',
                  middle_name:
                    customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0].mdlNm ?? '',
                  dob: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob ?? '',
                  marital_status: data.marital.name ?? '',
                  salutation: data.title.name ?? '',
                  gender:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr ?? '',
                  occupation: '',
                  nationality: '',
                  pincode:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntPncd ?? '',
                  education: '',
                  address:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn1 ??
                    '',
                  address_two:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn2,
                  address_three:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn3,
                  contact_number: customerDetails.customer_mobile_number?.slice(-10) ?? '',
                  permanent_address_line_one:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntAddrLn1 ??
                    '',
                  permanent_address_line_two:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntAddrLn2 ??
                    '',
                  permanent_address_line_three:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntAddrLn3 ??
                    '',
                  permanent_address_pincode:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntPncd ?? '',
                  // customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.prmntMblNb ??
                  // '',
                  email:
                    customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0].email ?? '',
                  pan_number:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.panNb ?? '',
                  overseas_address_line_one:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn1 ?? '',
                  overseas_address_line_two:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn2 ?? '',
                  overseas_address_line_three:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn3 ?? '',
                  overseas_address_pincode:
                    customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrCty ?? '',
                },
                life_assured_data: {
                  first_name: data.firstName ?? '',
                  last_name: data.lastName ?? '',
                  middle_name: data.middleName ?? '',
                  dob: data.dob ?? '',
                  marital_status: data.marital.name ?? '',
                  salutation: data.title.name ?? '',
                  gender: data.gender.name ?? '',
                  occupation: '',
                  nationality: '',
                  education: '',
                  contact_number: data.mobile ?? '',
                  address: data.address1 ?? '',
                  address_two: data.address2 ?? '',
                  address_three:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn3,
                  landmark: data.landmark ?? '',
                  email: data.email ?? '',
                  pincode: data.pincode ?? '',
                  pan_number: data.pan ?? '',
                  permanent_address_line_one:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.prmntAddrLn1 ??
                    data.address1,
                  permanent_address_line_two:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.prmntAddrLn2 ??
                    data.address2,
                  permanent_address_line_three:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.prmntAddrLn3 ?? '',
                  permanent_address_pincode: lifeCustomerData.lifeAssuredDetails?.CustmrFlds
                    .CustmrFldsRp.prmntPncd
                    ? lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.prmntPncd
                    : data.pincode,
                  overseas_address_line_one:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn1 ?? '',
                  overseas_address_line_two:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn2 ?? '',
                  overseas_address_line_three:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp
                      .ovrseasCurrAddrLn3 ?? '',
                  overseas_address_pincode:
                    lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrCty ??
                    '',
                },
              },
        ).unwrap()
        dispatch(updateLifeAssuredDetailsTyped(data))
        setNextButton(true)
        dispatch(updateSuitabilityStep('two'))
        try {
          // dispatch(
          //   updateProposerData({
          //     Salutation: data.salutation.name ?? '',
          //     'First Name': data.firstName ?? '',
          //     'Middle Name': data.middleName ?? '',
          //     'Last Name': data.lastName ?? '',
          //     'Date of Birth': data.dob ?? '',
          //     Gender: data.gender.name ?? '',
          //     'Mobile Number': data.mobile ?? '',
          //     'Email ID': data.email ?? '',
          //     'Marital Status': data.marital.name,
          //   }),
          // )
        } catch (e) {
          console.log(e)
        }
        try {
          dispatch(
            updateCustomerIds({
              life_assured_id: response.life_assured_id,
              proposer_id: response.proposer_id,
            }),
          )
        } catch (e) {
          console.log(e)
        }
      }
    } catch (e: any) {
      ToastService.showErrorToast({ title: 'Error', message: e['data']['data']['errors'][0] })
    }
  }

  const onFormError = (errors: any) => {
    console.log(errors, 'eerrr')
  }
  // const params: TCustomerDetails = {
  //   // ucic: '137763064',
  // }

  return (
    <>
      {salutationListing && maritalStatusListing && genderListing ? (
        <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
          <Flex my='7'>
            {/* <Select width={'270px'} mt='7' ml='2' placeholder={'Title'} 
            <option value='option1'>Ms.</option>
            <option value='option2'>Mrs.</option>
            <option value='option3'>Mr.</option>
          /> */}
            <Box mx='2'>
              <FormControl isInvalid={errors.title ? true : false}>
                <FormLabel fontWeight={'bold'}>Title*</FormLabel>
                <Select
                  useBasicStyles
                  placeholder=''
                  options={salutationListing
                    .filter((salutationItem) => {
                      if (
                        lifeCustomerData.acceptedSalutation &&
                        lifeCustomerData.acceptedSalutation.length
                      )
                        return lifeCustomerData.acceptedSalutation.includes(salutationItem.code)
                      else {
                        return true
                      }
                    })
                    .map((salutation) => {
                      return {
                        label: salutation.name,
                        value: salutation.code,
                      }
                    })}
                  onChange={(item: any) => {
                    setDisplayedSalutation({ code: item.value, name: item.label })
                    setValue('title', { name: item.label, code: item.value })
                    getGender(item)
                    clearErrors('title')
                  }}
                  value={{ label: displayedSalutation?.name, value: displayedSalutation?.code }}
                />
                {errors.title && (
                  <FormErrorMessage>{errors.title?.code?.message?.toString()}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <FormControl mr='2' isInvalid={errors.firstName ? true : false}>
              <FormLabel fontWeight={'bold'}>First Name*</FormLabel>
              <Input
                placeholder={'Enter First Name'}
                {...register('firstName')}
                onKeyDown={onlyAllowTypingAlphabet}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
                    .frsNm
                }
              />
              {errors.firstName && (
                <FormErrorMessage>{errors.firstName?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mr='2'>
              <FormLabel fontWeight={'bold'}>Middle Name</FormLabel>
              <Input
                placeholder={'Enter Middle Name'}
                onKeyDown={onlyAllowTypingAlphabet}
                {...register('middleName')}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
                    .mdlNm
                }
              />
            </FormControl>
            <FormControl isInvalid={errors.lastName ? true : false}>
              <FormLabel fontWeight={'bold'}>Last Name*</FormLabel>
              <Input
                width={'50'}
                placeholder={'Enter Last Name'}
                {...register('lastName')}
                onKeyDown={onlyAllowTypingAlphabet}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
                    .lstNm
                }
              />
              {errors.lastName && (
                <FormErrorMessage>{errors.lastName?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <Flex mx='4' mb='7'>
            <FormControl mr='2'>
              <FormLabel fontWeight={'bold'}>Date of birth*</FormLabel>
              <Input
                type='date'
                // value={customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob}
                // value={customerDetailsAPIData?.CustmrFlds.CustmrFldsRp.dob}
                max={format(new Date(), 'yyyy-MM-dd')}
                {...register('dob')}
                onChange={(e) => {
                  setDob(e.target.value)
                  const timeDiff = Math.abs(Date.now() - new Date(e.target.value).getTime())
                  const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25)

                  setValue('dobAgeCal', false)
                }}
                isDisabled={!!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.dob}
              />
              {errors.dob && <FormErrorMessage>{errors.dob?.message?.toString()}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors.mobile ? true : false}>
              <FormLabel fontWeight={'bold'}>Mobile No.*</FormLabel>
              <Input
                type='number'
                placeholder={'Enter Mobile Number'}
                {...register('mobile')}
                onKeyDown={onlyAllowTypingNumbers}
                maxLength={10}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
                    .mblNb
                }
              />

              {errors.mobile && (
                <FormErrorMessage>{errors.mobile?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <Flex mx='4' mb='7'>
            <FormControl mr='2' isInvalid={errors.pan ? true : false}>
              <FormLabel fontWeight={'bold'}>
                {getValues('dobAgeCal') ? 'PAN Card' : 'PAN Card'}
              </FormLabel>
              <Input
                placeholder={'Enter PAN Number'}
                {...register('pan')}
                onKeyDown={onlyAllowTypingAlphabetNumeric}
                isDisabled={!!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.panNb}
              />
              {errors.pan && <FormErrorMessage>{errors.pan?.message?.toString()}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors.gender ? true : false}>
              <FormLabel fontWeight={'bold'}>Gender*</FormLabel>
              <Select
                placeholder='Select Gender'
                options={genderListing.map((gender) => {
                  return {
                    label: gender.name,
                    value: gender.code,
                  }
                })}
                onChange={(item: any) => {
                  setValue('gender', { name: item.label, code: item.value })
                  clearErrors('gender')
                }}
                // isDisabled={true}
                isDisabled={
                  getValues('title') && getValues('title').name?.toLowerCase() === 'master'
                    ? false
                    : true
                }
                value={{ label: displayedGender?.name, value: displayedGender?.code }}
              />
              {errors.gender && (
                <FormErrorMessage>{errors.gender.code?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <Flex mx='4' mb='7'>
            <FormControl mr='2' isInvalid={errors.email ? true : false}>
              <FormLabel fontWeight={'bold'}>Email Address*</FormLabel>
              <Input
                type='email'
                placeholder={'Enter email'}
                {...register('email')}
                // value={customerDetailsAPIData?.IndividualCustomerDetails.IndCustDtlsRp![0].email}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.IndividualCustomerDetails.IndCustDtlsRp![0]
                    .email
                }
              />
              {errors.email && (
                <FormErrorMessage>{errors.email?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.address1 ? true : false}>
              <FormLabel fontWeight={'bold'}>Address1*</FormLabel>
              <Input
                placeholder={'Enter Address'}
                {...register('address1')}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn1 ||
                  !!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrAddrLn1
                }
              />
              {errors.address1 && (
                <FormErrorMessage>{errors.address1?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <Flex mx='4' mb='7'>
            <FormControl mr='2' isInvalid={errors.address2 ? true : false}>
              <FormLabel fontWeight={'bold'}>Address2*</FormLabel>
              <Input
                placeholder={'Enter Address'}
                {...register('address2')}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntAddrLn2 ||
                  !!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrAddrLn2
                }
              />
              {errors.address2 && (
                <FormErrorMessage>{errors.address2?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={'bold'}>Landmark</FormLabel>
              <Input
                placeholder={'Enter Landmark'}
                {...register('landmark')}
                onKeyDown={onlyAllowTypingAlphabet}
                // value={customerDetailsAPIData?.CustmrFlds.CustmrFldsRp.crntAddrLn3}
                isDisabled={!!lifeCustomerData?.fieldsFetchedFromCbsMapping?.customerLandmark}
              />
            </FormControl>
          </Flex>

          <Flex mx='4' mb='7'>
            <FormControl mr='2' isInvalid={errors.pincode ? true : false}>
              <FormLabel fontWeight={'bold'}>Pincode*</FormLabel>
              <Input
                placeholder={'Enter Pincode'}
                {...register('pincode')}
                onKeyDown={onlyAllowTypingNumbers}
                isDisabled={
                  !!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.crntPncd ||
                  !!lifeCustomerData.lifeAssuredDetails?.CustmrFlds.CustmrFldsRp.ovrseasCurrCty
                }
              />
              {errors.pincode && (
                <FormErrorMessage>{errors.pincode?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={'bold'}>Is Life Assured a Smoker?</FormLabel>

              <Button
                mr='2'
                width={'50'}
                backgroundColor={tabIndex ? '#0678C40F' : 'white'}
                color={'#1C4589'}
                // border={'1px solid #1C4589'}
                border={tabIndex ? '1px solid #1C4589' : ''}
                _hover={{ background: '#0678C40F' }}
                onClick={() => {
                  dispatch(updateSmokerStatus({ smokerStatus: 'Non-Smoker' }))
                  dispatch(updateLifeAssuredSmokerStatus('Non-Smoker'))
                  setTabIndex(true)
                }}
                shadow={'md'}
              >
                Non Smoker
                <Box ml='2'>
                  <img src={nonSmoker} />
                </Box>
              </Button>
              <Button
                width={'140px'}
                backgroundColor={tabIndex ? 'white' : '#0678C40F'}
                color={'#1C4589'}
                border={tabIndex ? '' : '1px solid #1C4589'}
                shadow={'md'}
                _hover={{ background: '#0678C40F' }}
                onClick={() => {
                  dispatch(updateSmokerStatus({ smokerStatus: 'Smoker' }))
                  dispatch(updateLifeAssuredSmokerStatus('Smoker'))
                  setTabIndex(false)
                }}
              >
                Smoker
                <Box ml='2'>
                  <img src={smoker} />
                </Box>
              </Button>
            </FormControl>
          </Flex>

          <Flex mx='4' mb='7'>
            <FormControl mr='2' width={'330px'} isInvalid={errors.marital ? true : false}>
              <FormLabel fontWeight={'bold'}>Marital Status*</FormLabel>
              <Select
                placeholder='Select Marital Status'
                options={maritalStatusListing.map((status) => {
                  return {
                    label: status.name,
                    value: status.code,
                  }
                })}
                onChange={(item: any) => {
                  setValue('marital', { name: item.label, code: item.value })
                  setDisplayedMaritalStatus({ name: item.label, code: item.value })
                  clearErrors('marital')
                }}
                value={{
                  label: displayedMaritalStatus?.name,
                  value: displayedMaritalStatus?.code,
                }}
              />
              {errors.marital && (
                <FormErrorMessage>{errors.marital?.code?.message?.toString()}</FormErrorMessage>
              )}
            </FormControl>
            <Box mt='27px' ml='30px'>
              <Button
                mr='2'
                width={'100px'}
                shadow={'md'}
                backgroundColor={'white'}
                color={'#1C4589'}
                _hover={{ background: '#0678C40F' }}
                border={'1px solid #1C4589'}
                onClick={() => navigate('/')}
              >
                Back
              </Button>
              <Button
                width={'100px'}
                shadow={'md'}
                border={'1px solid #1C4589'}
                isLoading={isLoading}
                // onClick={() => setNextButton(true)}
                type='submit'
              >
                Next
              </Button>
            </Box>
          </Flex>
        </form>
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </>
  )
}

export { PersonalDetailsForm }
