const layerStyles = {
  transparentBorder: {
    border: '2px',
    borderRadius: 'lg',
    borderColor: 'transparent',
  },
  selectedBorder: {
    border: '2px',
    borderRadius: 'lg',
    borderColor: 'primary',
  },
}
export type LayerStyles = typeof layerStyles

export default layerStyles
