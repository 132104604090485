import {
  Box,
  Checkbox,
  Flex,
  Text,
  Button,
  RadioGroup,
  Radio,
  Stack,
  Divider,
  Tooltip,
  Spinner,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Collapse,
  useDisclosure,
  Center,
  Select,
} from '@chakra-ui/react'
import { IAdditionalCovers, ITransformedAddons, IAdditionalCover } from 'Models/ResponseModels'
import { useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector, useDeviceType, useVehicleType } from 'app/hooks'
import { updateFuelType, updateIDV } from 'features/Motor'
import { useForm } from 'react-hook-form'
import { onlyAllowTypingNumbers } from 'Services/general'

const SideBar = ({
  AddOns,
  selectedAddons,
  setSelectedAddons,
  selectedCovers,
  setSelectedCovers,
  AddOnsLoading,
  ownDamageCovers,
  liabilityCovers,
  CoversLoading,
  maxIdv,
  minIdv,
  postQuotation,
  resetAll,
  idv,
  setIdv,
  idvOption,
  setIdvOption,
  bodyIdv,
  setBodyIdv,
  setBodyIdvOption,
  chassisIdv,
  setChassisIdv,
  setChassisIdvOption,
}: {
  AddOns: ITransformedAddons[]
  selectedAddons: Map<any, any>
  setSelectedAddons: any
  selectedCovers: Map<any, any>
  setSelectedCovers: any
  AddOnsLoading: boolean
  ownDamageCovers: IAdditionalCover[]
  liabilityCovers: IAdditionalCover[]
  CoversLoading: boolean
  maxIdv: number
  minIdv: number
  postQuotation: any
  resetAll: any
  idv: number
  setIdv: any
  idvOption: string
  setIdvOption: any
  bodyIdv: number
  setBodyIdv: any
  setBodyIdvOption: any
  chassisIdv: number
  setChassisIdv: any
  setChassisIdvOption: any
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const { isOpen: isBodyOpen, onToggle: onBodyToggle } = useDisclosure()
  const { isOpen: isChassisOpen, onToggle: onChassisToggle } = useDisclosure()
  const { type: vehicleType, param: vehicleParam, name: vehicleName } = useVehicleType()
  const { register, formState, resetField, clearErrors, setValue, trigger } = useForm()
  const { isDesktop, isTablet, isMobile } = useDeviceType()

  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const dispatch = useAppDispatch()

  const renderCoverType = (cover: IAdditionalCover) => {
    if (cover.cover_type.toLowerCase() === 'dropdown') {
      return (
        <Select
          {...(register(cover.code, {
            required: parseInt(selectedCovers.get(cover.code)) <= 0,
          }),
          {
            onChange: (e) =>
              setSelectedCovers(new Map(selectedCovers.set(cover.code, e.target.value))),
          })}
        >
          <option value=''>Select Value</option>
          {Object.keys(cover.dropdown).map((item) => (
            <option value={item} key={item}>
              ₹ {parseInt(item).toLocaleString('en-IN')}
            </option>
          ))}
        </Select>
      )
    } else if (cover.cover_type.toLowerCase() === 'text') {
      return (
        <Flex direction='column'>
          <FormControl
            isInvalid={
              parseInt(selectedCovers.get(cover.code)) === 0 ||
              parseInt(selectedCovers.get(cover.code)) < cover.min! ||
              parseInt(selectedCovers.get(cover.code)) > cover.max!
            }
          >
            <InputGroup size='sm' marginTop='1'>
              <InputLeftAddon>₹</InputLeftAddon>
              <Input
                {...(register(cover.code, {
                  max: cover.max,
                  min: cover.min,
                  required:
                    parseInt(selectedCovers.get(cover.code)) === 0 &&
                    parseInt(selectedCovers.get(cover.code)) !== -1,
                }),
                {
                  onChange: (e) => {
                    setSelectedCovers(new Map(selectedCovers.set(cover.code, e.target.value)))
                    setValue(cover.code, parseInt(e.target.value))
                  },
                })}
                onKeyDown={onlyAllowTypingNumbers}
              />
            </InputGroup>
            {parseInt(selectedCovers.get(cover.code)) < cover.min! ||
            parseInt(selectedCovers.get(cover.code)) > cover.max! ? (
              <FormErrorMessage fontSize='10'>
                Kindly enter values between the range
              </FormErrorMessage>
            ) : null}
          </FormControl>
          <Flex justifyContent='space-between' fontSize='11px'>
            <Text>Min: ₹{cover.min?.toLocaleString('en-IN')}</Text>
            <Text>Max: ₹{cover.max?.toLocaleString('en-IN')}</Text>
          </Flex>
        </Flex>
      )
    } else if (cover.cover_type.toLowerCase() === 'radio') {
      return (
        <RadioGroup
          onChange={(val) => dispatch(updateFuelType({ fuelType: val }))}
          defaultValue={quotationDetails.fuelType}
        >
          <Stack>
            {cover.radio_group?.map((val) => (
              <>
                <Radio value={val.name}>{val.name}</Radio>
                {quotationDetails.fuelType === val.name && (
                  <Flex direction='column'>
                    <FormControl
                      isInvalid={
                        parseInt(selectedCovers.get(cover.code)) === 0 ||
                        parseInt(selectedCovers.get(cover.code)) < val.min! ||
                        parseInt(selectedCovers.get(cover.code)) > val.max!
                      }
                    >
                      <InputGroup size='sm' marginTop='1'>
                        <InputLeftAddon>₹</InputLeftAddon>
                        <Input
                          {...(register(cover.code, {
                            max: val.max,
                            min: val.min,
                            required:
                              parseInt(selectedCovers.get(cover.code)) === 0 &&
                              parseInt(selectedCovers.get(cover.code)) !== -1,
                          }),
                          {
                            onChange: (e) => {
                              setSelectedCovers(
                                new Map(selectedCovers.set(cover.code, e.target.value)),
                              )
                              setValue(cover.code, parseInt(e.target.value))
                            },
                          })}
                          onKeyDown={onlyAllowTypingNumbers}
                          // onChange={(e) =>
                          //   setSelectedCovers(new Map(selectedCovers.set(cover.code, e.target.value)))
                          // }
                        />
                      </InputGroup>
                      {parseInt(selectedCovers.get(cover.code)) < val.min! ||
                      parseInt(selectedCovers.get(cover.code)) > val.max! ? (
                        <FormErrorMessage fontSize='10'>
                          Kindly enter values between the range
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                    <Flex justifyContent='space-between' fontSize='11px'>
                      <Text>Min: ₹{val.min?.toLocaleString('en-IN')}</Text>
                      <Text>Max: ₹{val.max?.toLocaleString('en-IN')}</Text>
                    </Flex>
                  </Flex>
                )}
              </>
            ))}
          </Stack>
        </RadioGroup>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Flex direction='column' height='85vh' overflow='scroll' color='#333333' fontSize='13px'>
        <Box padding='4' display={quotationDetails.isComprehensive ? 'block' : 'none'}>
          <Text marginBottom='3' fontSize='14px' fontWeight='bold'>
            Insured Declared Value (IDV)
          </Text>
          <RadioGroup
            defaultValue='Recommended'
            value={idvOption}
            onChange={(val) => {
              setIdvOption(val)
              if (val === 'Recommended') {
                setIdv(0)
                setValue('idv', '')
                resetField('idv')
              }
              onToggle()
            }}
          >
            <Stack>
              <Radio value='Recommended' size='sm'>
                Recommended
              </Radio>
              <Radio value='idv' size='sm'>
                Choose your own IDV
              </Radio>
            </Stack>
          </RadioGroup>
          {/* {value == 'idv' && ( */}
          <Collapse in={isOpen} animateOpacity>
            <Flex direction='column'>
              <FormControl isInvalid={idv < minIdv || idv > maxIdv}>
                <InputGroup size='sm' marginTop='1'>
                  <InputLeftAddon>₹</InputLeftAddon>
                  <Input
                    {...(register('idv', { max: maxIdv, min: minIdv }),
                    {
                      onChange: (e) => {
                        setIdv(parseInt(e.target.value))
                        setValue('idv', parseInt(e.target.value), { shouldValidate: true })
                      },
                    })}
                    type='number'
                    value={idv.toString() === '0' ? '' : idv.toString()}
                    // onChange={(event) => {
                    //   setIdv(parseInt(event.target.value))
                    //   setValue('idv', parseInt(event.target.value), { shouldValidate: true })
                    //   // dispatch(updateIDV({ idv: parseInt(event.target.value) }))
                    // }}
                  />
                </InputGroup>
                {idv < minIdv || idv > maxIdv ? (
                  <FormErrorMessage fontSize='10'>
                    Kindly enter values between the range
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <Flex justifyContent='space-between' fontSize='11px'>
                <Text>Min: ₹{minIdv.toLocaleString('en-IN')}</Text>
                <Text>Max: ₹{maxIdv.toLocaleString('en-IN')}</Text>
              </Flex>
            </Flex>
          </Collapse>
          {/* )} */}
        </Box>
        {vehicleName.toLowerCase() === 'gcv' || vehicleName.toLowerCase() === 'pcv' ? (
          <Box padding='4' display={quotationDetails.isComprehensive ? 'block' : 'none'}>
            <Text marginBottom='3' fontSize='14px' fontWeight='bold'>
              Body Insured Declared Value
            </Text>
            <RadioGroup
              defaultValue='Recommended'
              onChange={(val) => {
                setBodyIdvOption(val)
                if (val === 'Recommended') {
                  setBodyIdv(0)
                  // dispatch(updateIDV({ idv: 0 }))
                }
                onBodyToggle()
              }}
            >
              <Stack>
                <Radio value='Recommended' size='sm'>
                  Recommended
                </Radio>
                <Radio value='idv' size='sm'>
                  Choose your own IDV
                </Radio>
              </Stack>
            </RadioGroup>
            {/* {value == 'idv' && ( */}
            <Collapse in={isBodyOpen} animateOpacity>
              <Flex direction='column'>
                {/* <FormControl
                  isInvalid={quotationDetails.idv < minIdv || quotationDetails.idv > maxIdv}
                > */}
                <InputGroup size='sm' marginTop='1'>
                  <InputLeftAddon>₹</InputLeftAddon>
                  <Input
                    onChange={(event) => {
                      setBodyIdv(parseInt(event.target.value))
                      // dispatch(updateIDV({ idv: parseInt(event.target.value) }))
                    }}
                  />
                </InputGroup>
                {/* {trailerIdv < minIdv || trailerIdv > maxIdv ? (
                    <FormErrorMessage fontSize='10'>
                      Kindly enter values between the range
                    </FormErrorMessage>
                  ) : null} */}
                {/* </FormControl> */}
                <Flex justifyContent='space-between' fontSize='11px'>
                  <Text>Min: ₹ 0</Text>
                  <Text>Max: ₹ 0</Text>
                </Flex>
              </Flex>
            </Collapse>
            {/* )} */}
          </Box>
        ) : null}
        <Divider />
        {vehicleName.toLowerCase() === 'gcv' || vehicleName.toLowerCase() === 'pcv' ? (
          <Box padding='4' display={quotationDetails.isComprehensive ? 'block' : 'none'}>
            <Text marginBottom='3' fontSize='14px' fontWeight='bold'>
              Chassis Insured Declared Value
            </Text>
            <RadioGroup
              defaultValue='Recommended'
              onChange={(val) => {
                setChassisIdvOption(val)
                if (val === 'Recommended') {
                  setChassisIdv(0)
                  // dispatch(updateIDV({ idv: 0 }))
                }
                onChassisToggle()
              }}
            >
              <Stack>
                <Radio value='Recommended' size='sm'>
                  Recommended
                </Radio>
                <Radio value='idv' size='sm'>
                  Choose your own IDV
                </Radio>
              </Stack>
            </RadioGroup>
            {/* {value == 'idv' && ( */}
            <Collapse in={isChassisOpen} animateOpacity>
              <Flex direction='column'>
                {/* <FormControl
                  isInvalid={quotationDetails.idv < minIdv || quotationDetails.idv > maxIdv}
                > */}
                <InputGroup size='sm' marginTop='1'>
                  <InputLeftAddon>₹</InputLeftAddon>
                  <Input
                    onChange={(event) => {
                      setChassisIdv(parseInt(event.target.value))
                      // dispatch(updateIDV({ idv: parseInt(event.target.value) }))
                    }}
                  />
                </InputGroup>
                {/* {trailerIdv < minIdv || trailerIdv > maxIdv ? (
                    <FormErrorMessage fontSize='10'>
                      Kindly enter values between the range
                    </FormErrorMessage>
                  ) : null} */}
                {/* </FormControl> */}
                <Flex justifyContent='space-between' fontSize='11px'>
                  <Text>Min: ₹ 0</Text>
                  <Text>Max: ₹ 0</Text>
                </Flex>
              </Flex>
            </Collapse>
            {/* )} */}
          </Box>
        ) : null}
        <Divider />
        <Box padding='4'>
          <Stack>
            <Text fontSize='14px' fontWeight='bold' marginBottom='3'>
              Select the items and click apply
            </Text>
            {(quotationDetails.isComprehensive || insuranceDetails.isSaod) && (
              <Text fontWeight='semibold' marginBottom='3'>
                Add Ons
              </Text>
            )}
            {AddOnsLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {(quotationDetails.isComprehensive || insuranceDetails.isSaod) &&
              AddOnsLoading == false &&
              AddOns.map((addOn) => (
                <Flex alignItems='center' key={addOn.code}>
                  <Checkbox
                    marginBottom='1'
                    value={addOn.code}
                    isChecked={selectedAddons.get(addOn.code)}
                    onChange={(e) => {
                      setSelectedAddons(
                        new Map(selectedAddons.set(addOn.code, !selectedAddons.get(addOn.code))),
                      )
                    }}
                  />
                  <Tooltip label={addOn.description}>
                    <label className='mb-1 ml-2'>{addOn.name}</label>
                  </Tooltip>
                </Flex>
              ))}
          </Stack>
        </Box>
        {quotationDetails.isComprehensive && <Divider />}
        <Box padding='4'>
          <Stack>
            <Text fontWeight='semibold'>Plan With</Text>
            {CoversLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {CoversLoading == false &&
              ownDamageCovers.map((additionalCover) =>
                (quotationDetails.isComprehensive && additionalCover.code == 'Fuel_tp') ||
                (!quotationDetails.isComprehensive &&
                  (additionalCover.code === 'ea' || additionalCover.code === 'nea')) ||
                (!quotationDetails.isComprehensive && additionalCover.code == 'Fuel_od') ||
                (!quotationDetails.isComprehensive && additionalCover.code === 'imt23') ||
                (insuranceDetails.isSaod &&
                  (additionalCover.code === 'pod' ||
                    additionalCover.code === 'tppd' ||
                    additionalCover.code === 'LLPD' ||
                    additionalCover.code === 'upa')) ? null : (
                  <Flex direction='column' key={additionalCover.code}>
                    <Flex alignItems='center'>
                      <Checkbox
                        marginBottom='1'
                        value={additionalCover.code}
                        isChecked={selectedCovers.get(additionalCover.code) === -1 ? false : true}
                        onChange={() => {
                          setSelectedCovers(
                            new Map(
                              selectedCovers.set(
                                additionalCover.code,
                                selectedCovers.get(additionalCover.code) !== -1 ? -1 : 0,
                              ),
                            ),
                          )
                          if (selectedCovers.get(additionalCover.code) === -1) {
                            resetField(additionalCover.code)
                          }
                        }}
                      />
                      <Tooltip label={additionalCover.description}>
                        <label className='mb-1 ml-2'>{additionalCover.name}</label>
                      </Tooltip>
                    </Flex>
                    {selectedCovers.get(additionalCover.code) !== -1 &&
                      renderCoverType(additionalCover)}
                  </Flex>
                ),
              )}
            {CoversLoading == false &&
              liabilityCovers.map((additionalCover) =>
                (quotationDetails.isComprehensive && additionalCover.code == 'Fuel_tp') ||
                (!quotationDetails.isIndividual && additionalCover.code === 'pod') ||
                (!quotationDetails.isComprehensive && additionalCover.code == 'Fuel_od') ||
                (insuranceDetails.isSaod &&
                  (additionalCover.code === 'pod' ||
                    additionalCover.code === 'tppd' ||
                    additionalCover.code === 'LLPD' ||
                    additionalCover.code === 'upa')) ? null : (
                  <Flex direction='column' key={additionalCover.code}>
                    <Flex alignItems='center'>
                      <Checkbox
                        marginBottom='1'
                        value={additionalCover.code}
                        isChecked={selectedCovers.get(additionalCover.code) == -1 ? false : true}
                        onChange={() => {
                          setSelectedCovers(
                            new Map(
                              selectedCovers.set(
                                additionalCover.code,
                                selectedCovers.get(additionalCover.code) !== -1 ? -1 : 0,
                              ),
                            ),
                          )
                          if (selectedCovers.get(additionalCover.code) === -1) {
                            resetField(additionalCover.code)
                          }
                        }}
                      />
                      <Tooltip label={additionalCover.description}>
                        <label className='mb-1 ml-2'>{additionalCover.name}</label>
                      </Tooltip>
                    </Flex>
                    {selectedCovers.get(additionalCover.code) !== -1 &&
                      renderCoverType(additionalCover)}
                  </Flex>
                ),
              )}
          </Stack>
        </Box>
      </Flex>
      <Flex width='100%' justifyContent='center' display={isDesktop ? 'flex' : 'none'}>
        <Button
          width='50%'
          variant='outline'
          borderRadius='0'
          colorScheme='secondary'
          onClick={async () => {
            const isFormValid = await trigger()
            if (isFormValid) {
              if (!(idvOption === 'idv' && idv === 0)) {
                await postQuotation()
              }
            }
          }}
        >
          Apply
        </Button>
        <Button
          width='50%'
          variant='outline'
          borderRadius='0'
          colorScheme='secondary'
          onClick={async () => {
            if (idvOption !== 'Recommended') {
              setIdvOption('Recommended')
              setIdv(0)
              onToggle()
            }
            await resetAll()
          }}
        >
          Reset
        </Button>
      </Flex>
    </>
  )
}

export { SideBar }
