import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMLPolicyResponse, IMLRcResponse, IProposal } from 'Models/ResponseModels'
import { TMotorProposalFormType } from 'Pages/Motor/Proposal/Forms/FormModel'
import { ToastService } from 'Services/toast.service'

export interface IProposalPageState {
  proposalStatus: 'success' | 'loading' | 'error' | 'not-fetched'
  proposalScreen: 'one' | 'two' | 'three'
  proposalDetails: TMotorProposalFormType
  proposalSuccessResponse: IProposal
  proposalErrorResponse: string
  consentFormLink: string
  paymentActivated: boolean
  paymentSuccess: boolean
}

const initialState: IProposalPageState = {
  proposalStatus: 'not-fetched',
  proposalScreen: 'one',
  proposalDetails: {
    isLoan: false,
    salutation: 'Mr.',
    isGenderMale: true,
    isMarried: false,
  },
  proposalSuccessResponse: {},
  proposalErrorResponse: '',
  consentFormLink: '',
  paymentActivated: false,
  paymentSuccess: false,
}

const proposalPageSlice = createSlice({
  name: 'proposalPage',
  initialState: initialState,
  reducers: {
    toggleProposalScreen: (state, action: PayloadAction<'one' | 'two' | 'three'>) => {
      state.proposalScreen = action.payload
    },
    updateProposalDetails: (state, action: PayloadAction<TMotorProposalFormType>) => {
      const {
        engineNumber,
        chassisNumber,
        previousPolicyNumber,
        isLoan,
        bankName,
        hypothecation,
        usesType,
        salutation,
        firstName,
        middleName,
        lastName,
        companyName,
        gstNumber,
        isGenderMale,
        isMarried,
        panCard,
        mobileNumber,
        email,
        pincodeArea,
        occupation,
        address1,
        address2,
        dob,
        landmark,
        nomineeFirstName,
        nomineeMiddleName,
        nomineeLastName,
        nomineeDob,
        nomineeAge,
        agreementType,
        nomineeRelationship,
      } = action.payload

      if (engineNumber) state.proposalDetails.engineNumber = engineNumber
      if (chassisNumber) state.proposalDetails.chassisNumber = chassisNumber
      if (previousPolicyNumber) state.proposalDetails.previousPolicyNumber = previousPolicyNumber
      // if (isLoan) state.proposalDetails.isLoan = isLoan
      if (agreementType) state.proposalDetails.agreementType = agreementType
      if (isLoan === true || isLoan === false) {
        state.proposalDetails.isLoan = isLoan
      }
      if (bankName) state.proposalDetails.bankName = bankName

      if (hypothecation) state.proposalDetails.hypothecation = hypothecation

      if (usesType) {
        state.proposalDetails.usesType = usesType
      }

      if (salutation) state.proposalDetails.salutation = salutation
      if (firstName) state.proposalDetails.firstName = firstName
      if (middleName) state.proposalDetails.middleName = middleName
      if (lastName) state.proposalDetails.lastName = lastName

      if (companyName) state.proposalDetails.companyName = companyName

      if (gstNumber) state.proposalDetails.gstNumber = gstNumber

      // if (isGenderMale) state.proposalDetails.isGenderMale = isGenderMale
      if (isGenderMale === true || isGenderMale === false) {
        state.proposalDetails.isGenderMale = isGenderMale
      }
      // if (isMarried) state.proposalDetails.isMarried = isMarried
      if (isMarried === true || isMarried === false) {
        state.proposalDetails.isMarried = isMarried
      }
      if (panCard) state.proposalDetails.panCard = panCard
      if (mobileNumber) state.proposalDetails.mobileNumber = mobileNumber
      if (email) state.proposalDetails.email = email
      if (pincodeArea) state.proposalDetails.pincodeArea = pincodeArea
      if (occupation) state.proposalDetails.occupation = occupation
      if (address1) state.proposalDetails.address1 = address1
      if (address2) state.proposalDetails.address2 = address2
      if (dob) state.proposalDetails.dob = dob
      if (landmark) state.proposalDetails.landmark = landmark
      if (nomineeFirstName) state.proposalDetails.nomineeFirstName = nomineeFirstName
      if (nomineeMiddleName) state.proposalDetails.nomineeMiddleName = nomineeMiddleName
      if (nomineeLastName) state.proposalDetails.nomineeLastName = nomineeLastName
      if (nomineeDob) state.proposalDetails.nomineeDob = nomineeDob
      if (nomineeAge) state.proposalDetails.nomineeAge = nomineeAge
      if (nomineeRelationship) state.proposalDetails.nomineeRelationship = nomineeRelationship
    },
    updateProposalSuccessResponse: (state, action: PayloadAction<IProposal>) => {
      state.proposalSuccessResponse = action.payload
    },
    updateProposalErrorResponse: (state, action: PayloadAction<string>) => {
      state.proposalErrorResponse = action.payload
    },
    updateProposalButtonLoadingStatus: (
      state,
      action: PayloadAction<'success' | 'loading' | 'error' | 'not-fetched'>,
    ) => {
      state.proposalStatus = action.payload
    },
    updatePolicyDataFromOCR: (state, action: PayloadAction<IMLPolicyResponse>) => {
      const {
        engine_number,
        chassis_number,
        first_name,
        middle_name,
        last_name,
        address1,
        address2,
        prev_policy_number,
        landmark,
        email,
        phone_number,
      } = action.payload
      state.proposalDetails.engineNumber = engine_number
      state.proposalDetails.chassisNumber = chassis_number
      state.proposalDetails.firstName = first_name
      state.proposalDetails.middleName = middle_name
      state.proposalDetails.lastName = last_name
      state.proposalDetails.address1 = address1
      state.proposalDetails.address2 = address2

      state.proposalDetails.previousPolicyNumber = prev_policy_number
      state.proposalDetails.landmark = landmark
      state.proposalDetails.email = email
      state.proposalDetails.mobileNumber = phone_number

      ToastService.showSuccessTaost({
        title: 'Details are fetched from the RC copy uploaded',
        message: 'Please check the details before proceeding further',
      })
    },
    updatePolicyDataFromRC: (state, action: PayloadAction<IMLRcResponse>) => {
      const { address, engine_number, chassis_number, name } = action.payload
      state.proposalDetails.address1 = address?.substring(0, 50)
      state.proposalDetails.engineNumber = engine_number
      state.proposalDetails.chassisNumber = chassis_number
      state.proposalDetails.firstName = name

      ToastService.showSuccessTaost({
        title: 'Details are fetched from the RC copy uploaded',
        message: 'Please check the details before proceeding further',
      })
    },
    resetProposalStatus: (state) => {
      state.proposalStatus = 'not-fetched'
      state.proposalSuccessResponse = {}
      state.proposalErrorResponse = ''
      state.proposalScreen = 'one'
      state.proposalDetails.pincodeArea = undefined
      state.consentFormLink = ''
      state.paymentActivated = false
      state.paymentSuccess = false
    },
    updateConsentFormLink: (state, action: PayloadAction<string>) => {
      state.consentFormLink = action.payload
    },
    updatePaymentActivated: (state, action: PayloadAction<boolean>) => {
      state.paymentActivated = action.payload
    },
    updatePaymentSuccess: (state, action: PayloadAction<boolean>) => {
      state.paymentSuccess = action.payload
    },
    resetProposal: () => initialState,
  },
})

const { reducer } = proposalPageSlice

const {
  toggleProposalScreen,
  updateProposalSuccessResponse,
  updateProposalErrorResponse,
  updateProposalDetails,
  updateProposalButtonLoadingStatus,
  updatePolicyDataFromOCR,
  updatePolicyDataFromRC,
  resetProposal,
  resetProposalStatus,
  updateConsentFormLink,
  updatePaymentActivated,
  updatePaymentSuccess,
} = proposalPageSlice.actions

export {
  reducer as proposalPageReducer,
  toggleProposalScreen,
  updateProposalSuccessResponse,
  updateProposalErrorResponse,
  updateProposalDetails,
  updateProposalButtonLoadingStatus,
  updatePolicyDataFromOCR,
  updatePolicyDataFromRC,
  resetProposal,
  resetProposalStatus,
  updateConsentFormLink,
  updatePaymentActivated,
  updatePaymentSuccess,
}
