import { Box, Flex, Text } from '@chakra-ui/react'
import { UseFormReturn } from 'react-hook-form'
import { TMotorProposalFormType } from '../Forms/FormModel'
import { ProposalCustomerDetailsForm } from '../Forms/ProposalCustomerDetailsForm'

interface IProposalCustomerDetailsDesktop {
  rounded?: boolean
  form: UseFormReturn<TMotorProposalFormType, any>
}

const ProposalCustomerDetailsDesktop = (props: IProposalCustomerDetailsDesktop) => {
  const { rounded = false, form } = props

  return (
    <Flex flexBasis='70%' border='1px' borderRightRadius={rounded ? 'lg' : ''} direction='column'>
      <Box padding='3' boxShadow='excludingTop'>
        <Text fontSize='md' textAlign='left' paddingX='4' paddingY='2' fontWeight='bold'>
          02. Customer Details
        </Text>
      </Box>
      <ProposalCustomerDetailsForm form={form} />
    </Flex>
  )
}

export { ProposalCustomerDetailsDesktop }
