import {
  Box,
  Checkbox,
  Flex,
  Text,
  Image,
  Link,
  Button,
  Center,
  Spinner,
  Spacer,
  Divider,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerCloseButton,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  useDisclosure,
} from '@chakra-ui/react'
import { FaShareAlt, FaExclamationTriangle } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { useState, useEffect } from 'react'
import { IAdditionalCover, ITransformedAddons, Quotations } from 'Models/ResponseModels'
import {
  useAppDispatch,
  useAppSelector,
  useDeviceType,
  useWindowDimensions,
  useVehicleType,
} from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { updateSelectedQuote, updateInspectionRequired } from 'features/Motor'
import { useGetPlanDetailsQuery } from 'Services/API/motor.api'
import { differenceInDays } from 'date-fns'
import { PremiumBreakup } from './PremiumBreakup'

const QuoteItem = ({
  ownDamageCovers,
  liabilityCovers,
  discountCovers,
  addOns,
  quote,
  selectedQuotes,
  setSelectedQuotes,
  buyPolicy,
  isBreakIn,
}: {
  quote: Quotations
  addOns: ITransformedAddons[]
  ownDamageCovers: IAdditionalCover[]
  liabilityCovers: IAdditionalCover[]
  discountCovers: IAdditionalCover[]
  selectedQuotes: any
  setSelectedQuotes: any
  buyPolicy: any
  isBreakIn: boolean
}) => {
  useEffect(() => {
    checkBreakIn()
    setImageLoadFail(false)
  }, [])
  const [planDetails, setPlanDetails] = useState(false)
  const [skip, setSkip] = useState(true)
  const [breakIn, setBreakIn] = useState(false)
  const [imageLoadFail, setImageLoadFail] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const homePageDetails = useAppSelector((state) => state.homePage)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isDesktop, isTablet, isMobile } = useDeviceType()
  const { width: pageWidth } = useWindowDimensions()
  const { name: vehicleName } = useVehicleType()
  const { isLoading: DetailsLoading, data: Details } = useGetPlanDetailsQuery(
    {
      insurance_code: quote.insurer_code,
      policy_type: quotationDetails.isComprehensive ? 'CO' : 'TP',
    },
    { skip },
  )

  const checkBreakIn = () => {
    let isBreakIn
    const date1 = new Date()
    const dateString = insuranceDetails.existingPolicyExpiryDate ?? ''
    const dateParts = dateString.split('/')
    const date2 = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
    if (
      vehicleName.toLowerCase() === 'car' ||
      vehicleName.toLowerCase() === 'gcv' ||
      vehicleName.toLowerCase() === 'pcv'
    ) {
      if (quotationDetails.isComprehensive === false) {
        setBreakIn(false)
        dispatch(updateInspectionRequired({ inspectionRequired: false }))
      } else if (differenceInDays(date1, date2) > 0) {
        setBreakIn(true)
        dispatch(updateInspectionRequired({ inspectionRequired: true }))
      } else {
        if (
          insuranceDetails.previousPolicyType?.value === 'TP' &&
          quotationDetails.isComprehensive
        ) {
          setBreakIn(true)
          dispatch(updateInspectionRequired({ inspectionRequired: true }))
        } else {
          setBreakIn(false)
          dispatch(updateInspectionRequired({ inspectionRequired: false }))
        }
      }
    } else if (vehicleName.toLowerCase() === 'bike') {
      setBreakIn(false)
      dispatch(updateInspectionRequired({ inspectionRequired: false }))
    }
  }
  return (
    <Box
      id={quote.insurance_name}
      border='1px solid #F1F3F4'
      borderRadius='10px'
      width={pageWidth > 625 ? '270px' : '90%'}
      marginX={isDesktop ? '2' : '5'}
      marginBottom='6'
    >
      <Flex direction='column'>
        <Flex
          direction='column'
          position='relative'
          borderRadius='10px 10px 0 0'
          boxShadow='0px 3px 6px #00000029'
          //   border='1px solid #F1F3F4'
        >
          <Box zIndex='1'>
            <Box
              flexBasis='25%'
              marginBottom='6'
              paddingBottom='8'
              bgColor='white'
              borderRadius='10px 10px 0 0'
            >
              <Flex direction='row' justifyContent='flex-end' padding='10px' alignItems='center'>
                {/* <Link>
                  <FaShareAlt />
                </Link> */}
                <Checkbox
                  onChange={(e) => {
                    e.preventDefault()
                    if (e.target.checked) {
                      setSelectedQuotes([...selectedQuotes, quote.insurer_id])
                    } else {
                      const t: number[] = []
                      selectedQuotes.map((item: number) => {
                        if (item != quote.insurer_id) {
                          t.push(item)
                        }
                      })
                      setSelectedQuotes(t)
                    }
                  }}
                >
                  Compare
                </Checkbox>
              </Flex>
            </Box>
            <Box flexBasis='50%' paddingTop='6'>
              <Flex direction='column'>
                <Center>
                  <Flex
                    direction='column'
                    height={isMobile ? '80px' : '95px'}
                    width={isMobile ? '150px' : '180px'}
                    border='1px solid #F1F3F4'
                    justifyContent='center'
                    position='absolute'
                    top={isMobile ? '11%' : '9%'}
                    bgColor={imageLoadFail ? 'gray.200' : 'white'}
                    // paddingX='2'
                  >
                    {imageLoadFail ? (
                      <Center>
                        <Text width='100%' height='100%' fontSize='sm' textAlign='center'>
                          {quote.insurance_name}
                        </Text>
                      </Center>
                    ) : (
                      <Image src={quote.insurer_logo} onError={() => setImageLoadFail(true)} />
                    )}
                  </Flex>
                </Center>
                <Box marginTop='4' fontSize='14'>
                  <Flex
                    alignItems='center'
                    justifyContent='center'
                    display={breakIn ? 'flex' : 'none'}
                  >
                    <FaExclamationTriangle color='orange' />
                    <Text color='orange' marginLeft='2'>
                      Inspection Required
                    </Text>
                  </Flex>
                  <Center>
                    <Text
                      color='green'
                      display={breakIn && quote.inspection_type ? 'block' : 'none'}
                    >
                      {quote.inspection_type} Inspection Available
                    </Text>
                  </Center>
                </Box>
                <Box paddingX='2' display={isMobile ? 'none' : 'block'}>
                  <Text fontWeight='semibold' fontSize='14px'>
                    Highlight
                  </Text>
                  {quote.policy_highlights ? (
                    <Text
                      marginTop='2'
                      fontSize='12px'
                      dangerouslySetInnerHTML={{
                        __html:
                          quote.policy_highlights
                            .replaceAll('<ul>', '<UnorderedList>')
                            .replaceAll('</ul>', '</UnorderedList>') ?? '',
                      }}
                      overflow='scroll'
                      height='90px'
                    ></Text>
                  ) : (
                    <Box height='90px' fontSize='12px'>
                      No highlights to display
                    </Box>
                  )}
                </Box>

                <Center>
                  <Flex justifyContent='space-between' marginY='2' alignItems='center'>
                    <Button
                      // color='#CE202F'
                      colorScheme='secondary'
                      variant='outline'
                      // borderColor='#CE202F'
                      fontSize='12px'
                      marginRight='2'
                      padding='2'
                      onClick={() => {
                        setSkip(false)
                        setPlanDetails(true)
                      }}
                    >
                      PLAN DETAILS
                    </Button>
                    <Spacer />
                    <Button
                      variant='outline'
                      colorScheme='secondary'
                      padding='2'
                      // color='#CE202F'
                      // borderColor='#CE202F'
                      fontSize='12px'
                      onClick={onOpen}
                      id={quote.insurance_name}
                    >
                      PREMIUM BREAKUP
                    </Button>
                    <Drawer placement='bottom' onClose={onClose} isOpen={isOpen}>
                      <DrawerOverlay />
                      <PremiumBreakup
                        insurer_logo={quote.insurer_logo}
                        idv={quote.idv}
                        ownDamageCovers={ownDamageCovers}
                        liabilityCovers={liabilityCovers}
                        discountCovers={discountCovers}
                        addOns={addOns}
                        premium={quote.premium_total}
                        premiumDict={quote.premium}
                        addOnsDict={quote.add_ons}
                        coversDict={quote.cover_discount}
                        quote={quote}
                        buyPolicy={buyPolicy}
                        homePageDetails={homePageDetails}
                        insuranceDetails={insuranceDetails}
                        quotationDetails={quotationDetails}
                        vehicleDetails={vehicleDetails}
                      />
                    </Drawer>
                  </Flex>
                </Center>
              </Flex>
            </Box>
            <Box flexBasis='25%' bgColor='white'>
              <Flex direction='column' justifyContent='space-between'>
                <Flex justifyContent='space-around' alignItems='center' marginY='2'>
                  <Flex direction='column' justifyContent='center'>
                    <Text color='primary.500' fontSize='28px' fontWeight='semibold'>
                      ₹ {Math.round(quote.premium_total).toLocaleString('en-IN')}
                    </Text>
                    <Text color='#7B7B7B' fontSize='12px'>
                      Inc. GST
                    </Text>
                  </Flex>
                  <Flex direction='column'>
                    <Text color='#7B7B7B' fontSize='15px'>
                      IDV : ₹{Math.round(quote.idv).toLocaleString('en-IN')}
                    </Text>
                    <Text color='#7B7B7B' fontSize='15px'>
                      NCB : {quote.ncb_percent == '' ? 0 : quote.ncb_percent}%
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Box
            position='absolute'
            height='100%'
            width='100%'
            padding='2'
            zIndex='1'
            bgColor='#525253'
            opacity='0.9'
            display={planDetails ? 'block' : 'none'}
            color='white'
            borderRadius='10px 10px 0 0'
            overflow='scroll'
          >
            <Flex color='white' justifyContent='space-between' alignItems='center'>
              <Text fontWeight='bold' fontSize='18px'>
                Plan Details
              </Text>
              <Icon as={AiOutlineClose} onClick={() => setPlanDetails(false)} boxSize='1.3em' />
            </Flex>
            {DetailsLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {Details?.summary && (
              <Text
                fontSize='13px'
                dangerouslySetInnerHTML={{
                  __html: Details.summary.replaceAll('<ul>', '<UnorderedList>'),
                }}
              />
            )}
          </Box>
        </Flex>
        <Button
          // colorScheme='blue'
          id={quote.insurance_name}
          borderRadius='0 0 10px 10px'
          // bgColor='#1C4589'
          disabled={!quotationDetails.pucCertificate}
          onClick={() => {
            dispatch(updateSelectedQuote({ selectedQuote: quote }))
            // navigate(`/motor/${vehicleType.toLowerCase()}/proposal`)
            buyPolicy(quote)
          }}
        >
          BUY
        </Button>
      </Flex>
    </Box>
  )
}

export { QuoteItem }
