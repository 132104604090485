import { useDeviceType } from 'app/hooks'
import { PaymentDesktop } from './Desktop'
import { PaymentMobile } from './Mobile'

export const Payment = () => {
  const { isDesktop } = useDeviceType()
  // return <>{isDesktop ? <PaymentDesktop /> : <PaymentMobile />}</>
  return (
    <>
      <PaymentDesktop />
    </>
  )
}
