import { Box, Flex, Text } from '@chakra-ui/react'
import { CustomerDetailsForm } from '../Forms/CustomerDetailsForm'
import { CustomerLinkedAccountsForm } from '../Forms/CustomerLinkedAccounts'

const CustomerLinkedAccountsDesktop = () => {
  return (
    <Box flexBasis='50%' borderLeft='1px'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        paddingY='4'
        paddingX='6'
        bgColor='appDarkGrey'
        boxShadow='excludingTop'
        borderTopRightRadius='2xl'
      >
        <Text fontSize='md' fontWeight='md'>
          Linked Accounts
        </Text>
      </Flex>
      <CustomerLinkedAccountsForm />
    </Box>
  )
}

export { CustomerLinkedAccountsDesktop }
