import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { nomineeMandatoryInsurers } from 'Constants'
import { toggleProposalScreen } from 'features/Motor'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { TMotorProposalFormType } from '../Forms/FormModel'
import { ProposalNomineeDetailsForm } from '../Forms/ProposalNomineeDetailsForm'

interface IProposalNomineeDetailsDesktop {
  rounded?: boolean
  form: UseFormReturn<TMotorProposalFormType, any>
}

const getHeading = (
  isIndividual: boolean,
  businessrole_name: string,
  isNomineeMandatory: boolean,
  role_type: string,
) => {
  if (!isIndividual && businessrole_name === 'NSP') {
    return '03. SP Tagging'
  } else if (!isIndividual) {
    return '03. Submit Details'
  } else if (isIndividual && isNomineeMandatory) {
    return '03. Nominee Details*'
  }
  return '03. Nominee Details(Optional)'
}

const ProposalNomineeDetailsDesktop = (props: IProposalNomineeDetailsDesktop) => {
  const { rounded = false, form } = props

  const { isIndividual, isPodSelected } = useAppSelector((state) => state.quotationsDetails)
  const insurerCode = useAppSelector((state) => state.quotationsDetails.selectedQuote?.insurer_code)

  const [isNomineeMandatory, setIsNomineeMandatory] = useState(false)

  const { businessrole_name, role_type } = useAppSelector((state) => state.user.authenticatedUser)

  useEffect(() => {
    if (isPodSelected === true || nomineeMandatoryInsurers.includes(insurerCode ?? '')) {
      setIsNomineeMandatory(true)
    }
  }, [])

  const dispatch = useAppDispatch()

  return (
    <Flex
      flexBasis='60%'
      border='1px'
      borderLeftRadius={rounded ? 'lg' : ''}
      maxWidth='100%'
      direction='column'
    >
      <Box padding='3' boxShadow='excludingTop'>
        <Text fontSize='md' textAlign='left' paddingX='4' paddingY='2' fontWeight='bold'>
          {/* 03.{' '}
          {isIndividual
            ? `Nominee Details${isNomineeMandatory ? '*' : ' (Optional)'}`
            : 'Submit Details'} */}
          {getHeading(isIndividual, businessrole_name ?? '', isNomineeMandatory, role_type ?? '')}
        </Text>
      </Box>
      <ProposalNomineeDetailsForm form={form} isNomineeMandatory={isNomineeMandatory} />
      {/* <Flex>
        <Button onClick={() => dispatch(toggleProposalScreen('three'))}>three</Button>
      </Flex> */}
    </Flex>
  )
}

export { ProposalNomineeDetailsDesktop }
