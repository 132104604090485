import { Box, BoxProps, ChakraComponent, Container } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface IInsuranceFormBox extends BoxProps {
  children: ReactNode
}

const InsuranceFormBox = (props: IInsuranceFormBox) => {
  const { children, ...rest } = props
  return (
    <Box roundedBottom='3xl' boxShadow='rgba(0, 0, 0, 0.16) 0px 4px 6px' {...rest}>
      {children}
    </Box>
  )
}

export { InsuranceFormBox }
