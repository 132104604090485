import { Box, Button, Divider, Flex, FormLabel, ProgressLabel, Text } from '@chakra-ui/react'
import { useVehicleType } from 'app/hooks'
import { Select } from 'chakra-react-select'
import { useParams } from 'react-router-dom'
import { VehicleDetailsForm } from '../Forms/VehicleDetailsForm'

const VehicleDetailsDesktop = () => {
  const { name: vehicleType } = useVehicleType()

  return (
    <Box flexBasis='50%' borderRight='1px'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        paddingY='4'
        paddingX='6'
        bgColor='appDarkGrey'
        boxShadow='excludingTop'
        borderTopLeftRadius='2xl'
      >
        <Text fontSize='large' fontWeight='medium'>
          {vehicleType} Details
        </Text>
        {/* <Button colorScheme='teal' variant='outline'>
          Edit
        </Button> */}
      </Flex>
      <VehicleDetailsForm />
    </Box>
  )
}

export { VehicleDetailsDesktop }
