import { CalendarIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  forwardRef,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { TextInput } from 'Core/TextInput'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { getDateFromDDMMYYYY } from 'Services/general'
import { IBimabossDatePicker } from './BimabossDatePickerProps'
import styles from './index.module.css'

// TODO: feat: allow user to type date from input
const BimabossDatePicker = forwardRef((props: IBimabossDatePicker, ref) => {
  const {
    inputDate = format(new Date(), 'dd/MM/yyyy'),
    setInputDate,
    minDate = new Date('1950'),
    maxDate = new Date(),
    clearErrors,
    isNonEditable = false,
    ...rest
  } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  // const [selectedSingleDate, setSelectedSingleDate] = useState<Date | undefined>()

  // useEffect(() => {
  //   if (selectedSingleDate) {
  // setInputDate(format(selectedSingleDate ?? new Date(), 'dd/MM/yyyy'))
  //     clearErrors && clearErrors()
  //   }
  // }, [selectedSingleDate])

  return (
    <>
      <InputGroup
        onClick={() => {
          if (!isNonEditable) {
            onOpen()
          }
        }}
      >
        <TextInput
          ref={ref}
          className={isNonEditable ? 'date-disabled': 'date-enabled'}
          value={inputDate}
          isReadOnly
          onChange={(e) => setInputDate(e.target.value)}
          placeholder='Enter date'
          onKeyDown={(e) => {
            if (e.key === 'Enter') onOpen()
          }}
          {...rest}
        />
        <InputRightElement zIndex={0} cursor='pointer'>
          <CalendarIcon color='secondary.500' />
        </InputRightElement>
      </InputGroup>
      <Modal size='sm' isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex justifyContent='center'>
              <DayPicker
                className={styles.bimabossDatePicker}
                captionLayout='dropdown'
                defaultMonth={inputDate ? getDateFromDDMMYYYY(inputDate) : new Date()}
                fromDate={minDate}
                toDate={maxDate}
                mode={'single'}
                selected={getDateFromDDMMYYYY(inputDate)}
                onSelect={(d: Date | undefined) => {
                  setInputDate(format(d ?? new Date(), 'dd/MM/yyyy'))
                  if (clearErrors) {
                    clearErrors()
                  }
                }}
                modifiersStyles={{
                  selected: {
                    color: '#fff',
                    backgroundColor: '#4b9cd8',
                  },
                }}
                styles={{
                  caption: { color: '#4b9cd8' },
                  cell: { padding: '0.3rem' },
                }}
              />
            </Flex>
          </ModalBody>
          <ModalFooter paddingTop='0'>
            <Button
              variant='outline'
              marginX='2'
              colorScheme='red'
              onClick={() => {
                // setSelectedSingleDate(undefined)
                setInputDate('')
              }}
            >
              Clear
            </Button>
            <Button
              marginX='2'
              colorScheme='blue'
              onClick={() => {
                if (inputDate && clearErrors) {
                  clearErrors()
                }
                onClose()
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
})

export { BimabossDatePicker }
