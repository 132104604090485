import { ICustomerDetails, ICustomerSearchDetails } from 'Models/ResponseModels/Equitas'
import { AppDispatch } from 'app/store'
import { setCustomerDetailsStatus } from 'features/Equitas'
import {
  updateLifeAssuredDetails,
  updateLifeProposalDetails,
  updateUCICId,
} from 'features/Life/LifeCustomerDetails.life.slice'

// const updateLifeProposalDetails = (dispatch: AppDispatch) => {

// }

const customerLifeDetailsSuccessHandler = (
  dispatch: AppDispatch,
  customerLifeDetails: ICustomerDetails,
  ucicIdInStore: string,
) => {
  dispatch(updateUCICId(ucicIdInStore))
  dispatch(updateLifeAssuredDetails(customerLifeDetails)) // cbs v3 action
  dispatch(setCustomerDetailsStatus('success')) // cbs v3 action
}

export { customerLifeDetailsSuccessHandler }
