import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
  Button,
  FormControl,
  Flex,
} from '@chakra-ui/react'
import { useVehicleType } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { updatePaOwnerReason } from 'features/Motor'
const HDFCDialog = ({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const navigate = useNavigate()
  const { name: vehicleName } = useVehicleType()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <FormControl>
          <ModalHeader>
            {
              'As per the insurer the combined values of the covers "Electrical Accessories", "Non Electrical Accessories" and "CNG/LPG Kit" should be less than half of IDV. Please reselect the values.'
            }
          </ModalHeader>
          <ModalBody>
            <Flex direction='row' justifyContent='center'>
              <Button
                colorScheme='primary'
                marginRight='2'
                onClick={() => {
                  //   navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                  onClose()
                }}
              >
                OK
              </Button>
              {/* <Button
                colorScheme='primary'
                onClick={() => {
                  // navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                  onClose()
                }}
              >
                No
              </Button> */}
            </Flex>
            {/* <RadioGroup onChange={(val) => updatePaOwnerReason({ paOwnerReason: val })}>
                <Stack>
                  <Radio value='nvdl' size='sm'>
                    You do not have a valid driving license OR
                  </Radio>
                  <Radio value='apa' size='sm'>
                    You have alternate personal accident policy having cover for ₹ 15,00,000
                  </Radio>
                </Stack>
              </RadioGroup> */}
          </ModalBody>
          {/* <ModalFooter>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                colorScheme='primary'
                onClick={() => {
                  navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                  onClose()
                }}
                ml={3}
              >
                Submit
              </Button>
            </ModalFooter> */}
        </FormControl>
      </ModalContent>
    </Modal>
  )
}

export { HDFCDialog }
