import { Flex, Box } from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'
import { CashlessHospitalCard } from 'Core/Cards/CashlessHospitalCard'

export const CardData = () => {
  const { isDesktop } = useDeviceType()
  return (
    <Flex padding='4' direction={isDesktop ? 'row' : 'column'}>
      <Box>
        <CashlessHospitalCard
          name='Indraprastha Apollo Hospital'
          address='Sarita Vihar, Delhi Mathura Road, South Delhi, Delhi-11076 Tel No: 011-78798272'
          id={'1'}
        />
        <CashlessHospitalCard
          name='Indraprastha Apollo Hospital'
          address='Sarita Vihar, Delhi Mathura Road, South Delhi, Delhi-11076 Tel No: 011-78798272'
          id={'2'}
        />
      </Box>
      <Box>
        <CashlessHospitalCard
          name='Indraprastha Apollo Hospital'
          address='Sarita Vihar, Delhi Mathura Road, South Delhi, Delhi-11076 Tel No: 011-78798272'
          id={'3'}
        />
        <CashlessHospitalCard
          name='Indraprastha Apollo Hospital'
          address='Sarita Vihar, Delhi Mathura Road, South Delhi, Delhi-11076 Tel No: 011-78798272'
          id={'4'}
        />
      </Box>
      <Box>
        <CashlessHospitalCard
          name='Indraprastha Apollo Hospital'
          address='Sarita Vihar, Delhi Mathura Road, South Delhi, Delhi-11076 Tel No: 011-78798272'
          id={'5'}
        />
        <CashlessHospitalCard
          name='Indraprastha Apollo Hospital'
          address='Sarita Vihar, Delhi Mathura Road, South Delhi, Delhi-11076 Tel No: 011-78798272'
          id={'6'}
        />
      </Box>
    </Flex>
  )
}
