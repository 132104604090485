import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { updateSelectedSubType } from 'features/Home'
import { useEffect, useState } from 'react'
import { HomeGcvForm } from './HomeGcvForm'
import { HomePcvForm } from './HomePcvForm'
import { IMotorFormProps } from './MotorFormProps'

const HomeCommercialForm = (props: IMotorFormProps) => {
  const [tabIndex, setTabIndex] = useState(0)

  const { selectedSubType } = useAppSelector((state) => state.homePage)

  useEffect(() => {
    if (selectedSubType === 'gcv') {
      setTabIndex(0)
    } else if (selectedSubType === 'pcv') {
      setTabIndex(1)
    }
  }, [])

  const dispatch = useAppDispatch()

  return (
    <Tabs index={tabIndex} paddingY='1rem' colorScheme='tertiary' isFitted>
      <TabList>
        <Tab
          onClick={() => {
            dispatch(updateSelectedSubType('gcv'))
            setTabIndex(0)
          }}
        >
          <Text fontWeight='semibold'>Goods Carrying Vehicle</Text>
        </Tab>
        <Tab
          onClick={() => {
            dispatch(updateSelectedSubType('pcv'))
            setTabIndex(1)
          }}
        >
          <Text fontWeight='semibold'>Passenger Carrying Vehicle</Text>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel padding='0'>
          <HomeGcvForm {...props} />
        </TabPanel>
        <TabPanel padding='0'>
          <HomePcvForm {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export { HomeCommercialForm }
