import technologyDriven from './technology_driven.png'
import quickPolicyIssuance from './quickpolicy.png'
import extensiveSupport from './extensivesupport.png'
import quickIncentive from './quickincentive.png'
import userFriendly from './userfriendly.png'
import insuranceBuffet from './insurancebuffet.png'
import privateCarColor from './privatecarcolor.png'
import privateCarWhite from './privatecarwhite.png'
import commercialColor from './commercialcolor.png'
import commercialWhite from './commercialwhite.png'
import miscColor from './misccolor.png'
import miscWhite from './miscwhite.png'
import healthWhite from './healthwhite.png'
import healthColor from './healthcolor.png'
import lifeColor from './lifeColor.png'
import lifeWhite from './lifeWhite.png'
import sachetWhite from './sachetWhite.png'
import sachetColor from './sachetColored.png'

import carInsurance from './car.png'
import gcvInsurance from './gcv.png'
import miscInsurance from './misc.png'
import bikeInsurance from './bike.png'
import pcvInsurance from './pcv.png'
import healthInsurance from './health.png'

export const HomeImages = {
  technologyDriven,
  quickPolicyIssuance,
  extensiveSupport,
  quickIncentive,
  userFriendly,
  insuranceBuffet,
  privateCar: {
    colored: privateCarColor,
    white: privateCarWhite,
  },
  commercial: {
    colored: commercialColor,
    white: commercialWhite,
  },
  miscellaneous: {
    colored: miscColor,
    white: miscWhite,
  },
  health: {
    colored: healthColor,
    white: healthWhite,
  },
  life: {
    colored: lifeColor,
    white: lifeWhite,
  },
  sachet: {
    colored: sachetColor,
    white: sachetWhite,
  },
  carInsurance,
  gcvInsurance,
  miscInsurance,
  bikeInsurance,
  pcvInsurance,
  healthInsurance,
}
