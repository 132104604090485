import { useEffect, useState } from 'react'
import { Box, Flex, Text, Button, Checkbox, Center, Spinner } from '@chakra-ui/react'
import { Header, ShowLoader } from 'Components'
import { RecommendedPlansCard } from './RecommendedPlansCard'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ToastService } from 'Services/toast.service'
import { store } from 'app/store'
import { useGetLifeRecommendedPlanMutation } from 'Services/API/life.api'
import { forEach } from 'lodash'
import { number } from 'zod'
import {
  resetRecommendationState,
  savePlanIdForEndowment,
  savePlanIdForTerm,
  updateRecommendedPlanType,
} from 'features/Life/LifeQuotationDetails.slice'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { copyToClipboard, decryptData } from 'Services/general'
import { ISaveLeadResponse } from 'Models/ResponseModels'
// import { getUpdateLifeLeadPayload } from 'features/BobSellJourney'

const RecommendedPlans = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const formDataFromStore = useAppSelector((state) => state.lifeCustomerDetails)
  const [selectedAmount, setSelectedAmount] = useState<any>({})
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)
  const userDetails = useAppSelector((state) => state.user.authenticatedUser)
  // const suitabilityData = useAppSelector((state) => state.lifeQuotationDetails.suitabilityData2)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const selectedSp = useAppSelector((state) => state.spTagging.selectedSp)
  const spTaggingDetails = useAppSelector((state) => state.spTagging)
  const [
    getRecommendedPlans,
    { data: quoteApiData, isLoading: isRecommendedPlansLoading, isError: isRecommendedPlansError },
  ] = useGetLifeRecommendedPlanMutation()
  const [saveLeads, { isLoading, isError, data: leadsData }] = useSaveLeadsMutation()

  const updateDataFunc = async () => {
    // await createLead()
    const response = await getRecommendedPlans({
      insurer_plans: [],
      la_customer_id: lifeQuotationDetails.life_assured_id ?? -1,
      pr_customer_id: lifeQuotationDetails.proposer_id ?? -1,
      product_category_code: 'life',
      proposer_same: formDataFromStore.relationship === 'Self' ? true : false,
      partner_id: 5,
      flow: 'insurer',
      relationship: lifeQuotationDetails?.relationship ?? '',
      lead_id: leadDetails.lead_uid ?? leadsData?.lead_uid ?? '',
      suitability_data: lifeQuotationDetails.suitabilityData,

      // ------------ DYNAMIC FUNC------
      //  suitability_data: formSuitabilityParams(),

      agent_id:
        userDetails.businessrole_name === 'SP'
          ? userDetails.id?.toString()
          : selectedSp?.id?.toString(),
      branch_code: userDetails.branch_sol_id ?? '',
      branch_name: userDetails.branch_name ?? '',
    }).unwrap()
    const term: string[] = []
    const endowment: string[] = []
    response.recommended_plans.map((item) => {
      if (item.plan_type.toLowerCase() === 'term' && item.recommended) {
        term.push(item.id)
      } else if (item.plan_type.toLowerCase() === 'endowment' && item.recommended) {
        endowment.push(item.id)
      }
    })
    dispatch(savePlanIdForEndowment(endowment))
    dispatch(savePlanIdForTerm(term))
  }

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      lead_data: {
        // cart_details: cart_details,
        // member_details: memberDetails,
        lifeCustomerDetails: formDataFromStore,
        lifeQuotationDetails: lifeQuotationDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: spTaggingDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  useEffect(() => {
    // updateDataFunc()
    if (leadDetails.lead_uid) {
      createLead()
    }
    // console.log(arrayforId(), 'arrayforId()')
  }, [leadDetails])

  useEffect(() => {
    if (!leadDetails.lead_uid) {
      createLead()
    }
  }, [])

  useEffect(() => {
    if (leadDetails.lead_uid && lifeQuotationDetails.relationship) {
      updateDataFunc()
    }
  }, [leadDetails, lifeQuotationDetails.relationship])

  const createLead = async () => {
    const payload: ISaveLead = formParam('Suitability Assessment', 'life')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  const onChangeSelectedAmount = (value: string, code: string) => {
    setSelectedAmount((old: any) => ({
      ...old,
      [code]: value,
    }))
  }

  const selectedRecommendedPlan = useAppSelector(
    (state) => state.lifeCustomerDetails.selectedRecommendedPlan,
  )

  const enablePlans = ['saral-bachat']
  const { selected_plan } = useAppSelector((state) => state.lifeCustomerDetails)
  return (
    <>
      <Header />
      <Center>
        <Text fontSize={'24px'} fontWeight={'bold'} mt='50px'>
          Recommended Plans
        </Text>
        <Box mt='50px'>
          <Button
            // leftIcon={<MdBuild />}
            colorScheme='secondary'
            fontSize='sm'
            marginX='2'
            variant='outline'
            onClick={() => {
              copyToClipboard(leadDetails.lead_uid ?? '')
              // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
              // ToastService.showSuccessTaost({
              //   title: 'Success',
              //   message: 'Lead Saved Successfully',
              // })
            }}
          >
            {leadDetails.lead_uid ?? '-'}
          </Button>
        </Box>
      </Center>
      {lifeQuotationDetails.planIdForTerm.length != 0 && (
        <Box>
          <Flex px='250px' color={'#CE202F'} alignItems='center'>
            <Text fontWeight={'bold'}>Term Life plan</Text>
            <Box borderTop={'1px solid #CE202F'} w='80%' height='1px' mx='2'></Box>
          </Flex>
          {isRecommendedPlansLoading ? (
            <Center m='10'>
              <Spinner />
            </Center>
          ) : (
            <Flex width={'100%'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
              {quoteApiData &&
                quoteApiData?.recommended_plans![0].recommended &&
                quoteApiData.recommended_plans &&
                quoteApiData.recommended_plans.length &&
                quoteApiData.recommended_plans
                  .slice()
                  .filter((item) => item.plan_type.toLowerCase() === 'term')
                  .map((plan, index) => {
                    return (
                      <RecommendedPlansCard
                        key={index}
                        plan={plan}
                        // isDisabled={!enablePlans.includes(plan.code)}
                      />
                    )
                  })}
            </Flex>
          )}
          {/* -------Term Life plan---------- */}
          <Center>
            <Button
              my='5'
              onClick={() => {
                dispatch(updateRecommendedPlanType('Term'))
                navigate('/quotation-page')
              }}
              isDisabled={isRecommendedPlansLoading || !quoteApiData}
            >
              PROCEED WITH TERM PLAN
            </Button>
          </Center>
        </Box>
      )}

      {/* ------- Endowment plan---------- */}
      {lifeQuotationDetails.planIdForEndowment.length != 0 && (
        <Box>
          <Flex px='250px' color={'#CE202F'} alignItems='center'>
            <Text fontWeight={'bold'}>Endowment plan</Text>
            <Box borderTop={'1px solid #CE202F'} w='80%' height='1px' mx='2'></Box>
          </Flex>
          {isRecommendedPlansLoading ? (
            <Center m='10'>
              <Spinner />
            </Center>
          ) : (
            <Flex width={'100%'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
              {quoteApiData &&
                quoteApiData?.recommended_plans![0].recommended &&
                quoteApiData.recommended_plans &&
                quoteApiData.recommended_plans.length &&
                quoteApiData.recommended_plans
                  .slice()
                  .filter((item) => item.plan_type.toLowerCase() !== 'term')
                  .map((plan, index) => {
                    return (
                      <RecommendedPlansCard
                        key={index}
                        plan={plan}
                        // isDisabled={!enablePlans.includes(plan.code)}
                      />
                    )
                  })}
            </Flex>
          )}
          <Center>
            <Button
              my='5'
              onClick={() => {
                dispatch(updateRecommendedPlanType('Endowment'))
                navigate('/quotation-page')
              }}
              isDisabled={isRecommendedPlansLoading}
              // isDisabled={isRecommendedPlansLoading || !quoteApiData}
            >
              PROCEED WITH ENDOWMENT PLAN
            </Button>
          </Center>
        </Box>
      )}
    </>
  )
}

export { RecommendedPlans }
