import { Box, GridItem, Grid, Divider, Skeleton, Flex, Spacer } from '@chakra-ui/react'
import { useAppSelector, useDeviceType } from 'app/hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LeftComponent } from './Components/LeftComponent'
import { DataComponent } from './DataComponent'

export const ProposerAndInsurerDetails = () => {
  const [showInsurerDetails, setShowInsurerDetails] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const { isDesktop, isTablet } = useDeviceType()
  const { proposer } = useAppSelector((state) => state.memberDetails)
  const navigate = useNavigate()

  const onProposalDetailsSubmit = () => {
    setShowInsurerDetails(false)
    setShowLoader(true)
    setTimeout(() => {
      setShowInsurerDetails(true)
      setShowLoader(false)
    }, 1000)
    console.log(proposer, 'updtated memb')
  }

  const onProposalDetailsBackPress = () => {
    navigate('../addons')
  }
  return (
    <Box border='1px' rounded='xl' backgroundColor='white' my='4'>
      <Grid style={{ gridTemplateColumns: '1fr 2fr' }}>
        <GridItem position='relative' w='100%'>
          <Box>
            <Box
              shadow='sm'
              paddingX='4'
              paddingY='6'
              fontWeight='bold'
              border='0.5px solid #D8D8D8'
              roundedTopLeft='lg'
            >
              02 Proposer Details
            </Box>
            <Divider />
            <LeftComponent
              onBackPress={onProposalDetailsBackPress}
              onSubmit={onProposalDetailsSubmit}
            />
          </Box>
        </GridItem>
        <GridItem w='100%' backgroundColor='white'>
          <Box>
            <Box
              shadow='sm'
              paddingX='4'
              paddingY='6'
              fontWeight='bold'
              border='0.5px solid #D8D8D8'
              roundedTopLeft='lg'
            >
              03 Insured Details
            </Box>
            <Divider />
            {showInsurerDetails && (
              <DataComponent
                onSubmit={() => {
                  console.log('')
                }}
              />
            )}
            {showLoader && (
              <Flex flexDirection='column' gap='4' p='4'>
                <Skeleton w='full' h='8' />
                <Spacer h='24' />
                <Grid
                  gap='4'
                  gridTemplateColumns={isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr'}
                >
                  {Array(30)
                    .fill(0)
                    .map((item, index) => (
                      <GridItem key={index}>
                        <Skeleton w='full' h='8' />
                      </GridItem>
                    ))}
                </Grid>
              </Flex>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  )
}
