import React, { useState } from 'react'
import { Box, Button, Center, Divider, Flex, Heading, Image, Spacer, Text } from '@chakra-ui/react'
import { HEADER_HEIGHT } from 'Constants'
import { BsArrowsAngleExpand } from 'react-icons/bs'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { IndianRupeeFormatFromString, decryptData } from 'Services/general'
import logo from 'Assets/adityabirlaLogo.png'
import { useAppDispatch, useAppSelector, useWindowDimensions } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { ReviewCartModal } from 'Core/Modal/ReviewCartModal'
import {
  removeFromCart,
  updateSelectedQuotes,
  removeSelectedQuotes,
  updateTotalMembers,
} from 'features/Health/cart.health.slice'
import { Modal } from 'Core/Modal'
import { useDispatch } from 'react-redux'
import {
  deleteInsurerWiseMemberDetails,
  updateMember,
  updateProposer,
} from 'features/Health/memberDetails.health.slice'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { ISaveLeadResponse } from 'Models/ResponseModels'

export const RIGHT_SIDE_PANEL_WIDTH = 240
interface ICartSummaryCard extends React.ComponentPropsWithRef<'div'> {
  insurerName: string
  finalPremium: string
  memberType: 'parents' | 'selfSpouseKids' | 'allMembers'
  insurerLogo: string
  memberList: any
  policyForSelfSpouseKidsText: string
}

const CartSummaryCard = (props: ICartSummaryCard) => {
  const dispatch = useDispatch()
  const onCartItemDelete = () => {
    dispatch(removeFromCart({ memberCategory: props.memberType }))
  }

  const getMemberType = (type: string) => {
    switch (type) {
      case 'selfSpouseKids':
        return props.policyForSelfSpouseKidsText
      case 'parents':
        return 'Parents'
      case 'allMembers':
        return 'All Members'
      default:
        return 'All Members'
    }
  }
  return (
    <>
      <Box px={1} py={6} borderBottom='1px solid #0000001C' fontSize={'12px'} w='full'>
        <Text fontWeight='bold' marginBottom='3'>
          {props.insurerName}
        </Text>
        <Flex gap='2' justifyContent={'space-between'}>
          <Box className='border border-[#E0E0E0] my-auto'>
            <Image src={props.insurerLogo} w='80px' my='2' />
          </Box>
          <Flex direction={'column'} className='my-auto' gap='1'>
            <Text fontWeight='bold'>Final Premium</Text>
            <Flex gap='1' marginRight='3'>
              <Text fontWeight='bold'>₹{IndianRupeeFormatFromString(`${props.finalPremium}`)}</Text>
              <Text fontSize='10px' marginY='2px'>
                Inc. GST
              </Text>
            </Flex>
            <Text fontWeight={'bold'}>
              Policy For :<span className=' font-light'> {getMemberType(props.memberType)}</span>{' '}
            </Text>
          </Flex>
          <Box className='my-auto'>
            <RiDeleteBin5Line size='16' cursor='pointer' onClick={onCartItemDelete} />
          </Box>
        </Flex>
      </Box>
    </>
  )
}

const PremiumBlock = ({ totalPremium, onClick }: { onClick: () => void; totalPremium: string }) => {
  const navigate = useNavigate()
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()
  const dispatch = useAppDispatch()
  const { selectedQuotes } = useAppSelector((state) => state.cart)

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const memberDetails = useAppSelector((state) => state.memberDetails)
  const cart_details = useAppSelector((state) => state.cart)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      dump_unique_identifier: Object.values(selectedQuotes)[0]?.uniqueIdentifier ?? '',
      lead_data: {
        cart_details: cart_details,
        member_details: memberDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  const createLead = async () => {
    const payload: ISaveLead = formParam('cart', 'health')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  const cartProceedHandler = () => {
    onClick()
    dispatch(deleteInsurerWiseMemberDetails())
    createLead()

    navigate('/health/proposal/addons')
  }
  return (
    <>
      <Box mx='4' mt='1' mb='5'>
        <Box>
          <Flex justifyContent='space-between'>
            <Text fontSize='15px' fontWeight='bold' className='my-auto'>
              Total Premium
            </Text>
            <Text color='primary.500' fontSize='20px'>
              ₹{IndianRupeeFormatFromString(totalPremium)}
            </Text>
          </Flex>
        </Box>
        <Center className='my-4'>
          <Button
            onClick={cartProceedHandler}
            variant='solid'
            w={'full'}
            rounded='full'
            color='white'
            backgroundColor='primary.500'
          >
            Proceed
          </Button>
        </Center>
      </Box>
    </>
  )
}

export const RightPanel = ({
  onClose,
  policyForSelfSpouseKidsText,
}: {
  onClose: () => void
  policyForSelfSpouseKidsText: string
}) => {
  const { height } = useWindowDimensions()
  // const [showCartPanel, setShowCartPanel] = useState(true)
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)
  const { cartDetails } = useAppSelector((state) => state.cart)
  const { memberDetails, proposer, isProposerIncluded } = useAppSelector(
    (state) => state.memberDetails,
  )

  const dispatch = useAppDispatch()

  const showCartPanel = () => {
    if (cartDetails.allMembers || cartDetails.parents || cartDetails.selfSpouseKids) {
      return true
    }
    return false
  }

  const getTotalPremium = (amt1: number | undefined, amt2: number | undefined) => {
    let total = 0

    if (amt1) total = total + amt1
    if (amt2) total = total + amt2
    return total.toString()
  }

  const onSubmitHandler1 = () => {
    const members: ('proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse')[] = []

    dispatch(removeSelectedQuotes())
    cartDetails.parents &&
      dispatch(
        updateSelectedQuotes({
          key: cartDetails.parents.quoteNumber,
          data: cartDetails.parents,
        }),
      )
    cartDetails.parents &&
      cartDetails.parents.addedMemberTypes &&
      members.push(...cartDetails.parents.addedMemberTypes)
    cartDetails.selfSpouseKids &&
      cartDetails.selfSpouseKids.addedMemberTypes &&
      members.push(...cartDetails.selfSpouseKids.addedMemberTypes)

    cartDetails.selfSpouseKids &&
      dispatch(
        updateSelectedQuotes({
          key: cartDetails.selfSpouseKids.quoteNumber,
          data: cartDetails.selfSpouseKids,
        }),
      )

    Object.keys(memberDetails).forEach((key) => {
      if (
        (memberDetails[key].member_type === 'father' ||
          memberDetails[key].member_type === 'mother') &&
        cartDetails.parents
      ) {
        dispatch(
          updateMember({
            code: key,
            memberDetail: {
              ...memberDetails[key],
              selectedQuoteNumber: cartDetails.parents.quoteNumber,
              selectedInsuranceNumber: cartDetails.parents.insuranceNumber,
              selectedInsurerId: cartDetails.parents.insurerId,
            },
          }),
        )
      } else if (
        memberDetails[key].member_type === 'proposer' ||
        memberDetails[key].member_type === 'son' ||
        memberDetails[key].member_type === 'daughter' ||
        memberDetails[key].member_type === 'spouse'
      ) {
        if (cartDetails.selfSpouseKids) {
          dispatch(
            updateMember({
              code: key,
              memberDetail: {
                ...memberDetails[key],
                selectedQuoteNumber: cartDetails.selfSpouseKids.quoteNumber,
                selectedInsuranceNumber: cartDetails.selfSpouseKids.insuranceNumber,
                selectedInsurerId: cartDetails.selfSpouseKids.insurerId,
              },
            }),
          )
        }
      }
    })

    if (cartDetails.selfSpouseKids) {
      dispatch(
        updateProposer({
          isProposerIncluded: isProposerIncluded,
          data: {
            ...proposer,
            selectedQuoteNumber: cartDetails.selfSpouseKids.quoteNumber,
            selectedInsuranceNumber: cartDetails.selfSpouseKids.insuranceNumber,
            selectedInsurerId: cartDetails.selfSpouseKids.insurerId,
          },
        }),
      )
    }
    if (cartDetails.parents) {
      dispatch(
        updateProposer({
          isProposerIncluded: isProposerIncluded,
          data: {
            ...proposer,
            selectedQuoteNumber: cartDetails.parents.quoteNumber,
            selectedInsuranceNumber: cartDetails.parents.insuranceNumber,
            selectedInsurerId: cartDetails.parents.insurerId,
          },
        }),
      )
    }

    dispatch(updateTotalMembers(members))
  }

  const onSubmitHandler2 = () => {
    const members: ('proposer' | 'son' | 'father' | 'mother' | 'daughter' | 'spouse')[] = []

    cartDetails.allMembers &&
      cartDetails.allMembers.addedMemberTypes &&
      members.push(...cartDetails.allMembers.addedMemberTypes)

    dispatch(removeSelectedQuotes())
    cartDetails.allMembers &&
      dispatch(
        updateSelectedQuotes({
          key: cartDetails.allMembers.quoteNumber,
          data: cartDetails.allMembers,
        }),
      )

    Object.keys(memberDetails).forEach((key) => {
      if (cartDetails.allMembers) {
        dispatch(
          updateMember({
            code: key,
            memberDetail: {
              ...memberDetails[key],
              selectedQuoteNumber: cartDetails.allMembers.quoteNumber,
              selectedInsuranceNumber: cartDetails.allMembers.insuranceNumber,
              selectedInsurerId: cartDetails.allMembers.insurerId,
            },
          }),
        )
      }
    })
    dispatch(updateTotalMembers(members))
    if (cartDetails.allMembers) {
      dispatch(
        updateProposer({
          isProposerIncluded: isProposerIncluded,
          data: {
            ...proposer,
            selectedQuoteNumber: cartDetails.allMembers.quoteNumber,
            selectedInsuranceNumber: cartDetails.allMembers.insuranceNumber,
            selectedInsurerId: cartDetails.allMembers.insurerId,
          },
        }),
      )
    }
  }

  return (
    <>
      <Box>
        {/* ------------ Right Panel Content ------------  */}
        <Box>
          {!cartDetails.selfSpouseKids && !cartDetails.allMembers && !cartDetails.parents && (
            <Center mt='12'>
              <Box>
                <Text>No Quotes Added in Cart</Text>
                <Center mt='4'>
                  <Button onClick={onClose}> Select Quotes</Button>
                </Center>
              </Box>
            </Center>
          )}
          {cartDetails.selfSpouseKids && (
            <CartSummaryCard
              insurerName={cartDetails.selfSpouseKids.insuranceName}
              finalPremium={cartDetails.selfSpouseKids.premium.toString()}
              memberType='selfSpouseKids'
              insurerLogo={cartDetails.selfSpouseKids.insurerLogo}
              memberList={cartDetails.selfSpouseKids.addedMemberTypes}
              policyForSelfSpouseKidsText={policyForSelfSpouseKidsText}
            />
          )}
          {cartDetails.parents && (
            <CartSummaryCard
              insurerName={cartDetails.parents.insuranceName}
              finalPremium={cartDetails.parents.premium.toString()}
              memberType='parents'
              insurerLogo={cartDetails.parents.insurerLogo}
              memberList={cartDetails.parents?.addedMemberTypes}
              policyForSelfSpouseKidsText={policyForSelfSpouseKidsText}
            />
          )}
          {(cartDetails.parents || cartDetails.selfSpouseKids) && (
            <PremiumBlock
              totalPremium={getTotalPremium(
                cartDetails.selfSpouseKids?.premium,
                cartDetails.parents?.premium,
              )}
              onClick={() => onSubmitHandler1()}
            />
          )}

          {(cartDetails.parents || cartDetails.selfSpouseKids) && cartDetails.allMembers && (
            <Flex align='center'>
              <Divider />
              <Text padding='2'>OR</Text>
              <Divider />
            </Flex>
          )}

          {cartDetails.allMembers && (
            <CartSummaryCard
              insurerName={cartDetails.allMembers.insuranceName}
              finalPremium={cartDetails.allMembers.premium.toString()}
              memberType='allMembers'
              insurerLogo={cartDetails.allMembers.insurerLogo}
              memberList={cartDetails.allMembers?.addedMemberTypes}
              policyForSelfSpouseKidsText={policyForSelfSpouseKidsText}
            />
          )}
          {cartDetails.allMembers && (
            <PremiumBlock
              totalPremium={cartDetails.allMembers!.premium!.toString()}
              onClick={() => onSubmitHandler2()}
            />
          )}
        </Box>
      </Box>
      <Modal isOpen={isReviewModalOpen} onClose={() => setIsReviewModalOpen(false)}>
        <ReviewCartModal />
      </Modal>
    </>
  )
}
