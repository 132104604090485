import { Box, Text } from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'
import { Header } from 'Components'

export const AboutUs = () => {
  const { isDesktop } = useDeviceType()

  return (
    <>
      <Header />
      <Box padding={isDesktop ? '20' : '6'} bg='white'>
        <Box paddingY='2'>
          <Text fontWeight='bold' fontSize='20px' paddingY='2'>
            About BimaBoss
          </Text>
          <Text>
            Insurance Regulatory and Development Authority of India, better known as IRDAI, started
            Point-of-Sales Person (PoSP) in 2015. A PoSP is an individual who can sell insurance
            products across companies and across insurance categories too, like health plans, term
            plans, life plans, and so on. Further, to ease the PoSPs with the process, ICare
            Insurance Broking Services Pvt. Ltd. has introduced BimaBoss.BimaBoss is an integrated
            platform which allows PoSP to sell policies and ensures a seamless end to end process.
            We believe in “Making Life Simple” and offer every potential opportunity to our PoSPs to
            earn with minimum investment and maximum return.
          </Text>
        </Box>
        <Box paddingY='2'>
          <Text fontWeight='bold' fontSize='20px' paddingY='2'>
            About Icare Insurance Broking
          </Text>
          <Text>
            At ICARE INSURANCE we make every effort to provide the best service for our clients. In
            order to achieve this, we focus on specialist areas where we have technical knowledge
            and experience allowing us to provide the necessary Insurance solutions for our
            clients.Our talented and dedicated team of experts, have between them a combined work
            experience of nearly a century and in our view this is one of our greatest strengths,
            allowing us to keep our finger on the pulse of the dynamic and ever-changing insurance
            landscape in order to deliver solutions that are relevant to you.
          </Text>
        </Box>
        <Box paddingY='2'>
          <Text fontWeight='bold' fontSize='20px' paddingY='2'>
            People matter
          </Text>
          <Text>
            We intend to offer the best to our PoSPs and therefore, hire the finest in industry
            people. Our teams have a mix of the brightest minds from diverse backgrounds. The
            members are from across insurance companies/ brokerage houses/ agencies, web aggregators
            and to name a few companies, Bharti Axa General Insurance, Policy Bazaar, Hero Insurance
            Group, Easy Policy are some of them. This makes for an inspiring working environment.
            People (both internal & external) form the centre of our existence.
          </Text>
        </Box>
        <Box paddingY='2'>
          <Text fontWeight='bold' fontSize='20px' paddingY='2'>
            Contact us
          </Text>
        </Box>
      </Box>
    </>
  )
}
