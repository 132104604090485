import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Header } from 'Components'
import { BimabossDatePicker, DashboardHeading, ElevatedButton, Pagination, TextInput } from 'Core'
import { format } from 'date-fns'
import { ISupportListingParams } from 'Models/RequestModels/Equitas'
import { ChangeEvent, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useGetSupportTicketListingQuery } from 'Services/API/authentication.api'

const SupportListing = () => {
  const [queryParams, setQueryParams] = useState<ISupportListingParams>({
    limit: '10',
    page_number: 1,
  })

  const [totalPages, setTotalPages] = useState(1)

  const { data: reportData } = useGetSupportTicketListingQuery(queryParams)

  useEffect(() => {
    console.log(reportData?.results[0])
  }, [reportData])

  // filters
  const [selectedDate, setSelectedDate] = useState('')

  const filterChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const timeoutId = setTimeout(() => {
      setQueryParams((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    }, 900)
    return () => clearTimeout(timeoutId)
  }

  return (
    <>
      <Header />
      <DashboardHeading title='Support Ticket Listing' />
      <Flex
        paddingX='1rem'
        paddingY='0.5rem'
        border='1px'
        alignItems='center'
        justifyContent='space-between'
        bgColor='#fff'
      >
        <Flex alignItems='center'>
          <Text fontWeight='bold' whiteSpace='nowrap' fontSize='md'>
            Items Per Page:
          </Text>
          <Select
            value={queryParams.limit}
            onChange={(e) => {
              setQueryParams((prev) => ({
                ...prev,
                limit: e.target.value,
                page_number: 1,
              }))
            }}
            marginX='4'
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Select>
        </Flex>
        <Button leftIcon={<DownloadIcon />} size='sm'>
          Download Report
        </Button>
      </Flex>
      <TableContainer border='1px' rounded='lg' margin='2'>
        <Table size='md' variant='simple' colorScheme='appGrey'>
          <Thead boxShadow='onlyBottom'>
            <Tr>
              <Th>Ticket No.</Th>
              <Th>Raised On</Th>
              <Th>Status</Th>
              <Th>Issue Type</Th>
              <Th>Resolved Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='insurance_name'
                />
              </Td>
              <Td>
                <BimabossDatePicker
                  variant='filled'
                  inputDate={selectedDate}
                  setInputDate={setSelectedDate}
                />
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
            {reportData?.results &&
              reportData.results.map((item, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{item?.ticket_no ? item?.ticket_no : '-'}</Td>
                    <Td>
                      {item?.raised_date ? format(new Date(item.raised_date), 'dd/MM/yyyy') : '-'}
                    </Td>
                    <Td>{item?.ticket_status ? item?.ticket_status : '-'}</Td>
                    <Td>{item?.issue_type ? item?.issue_type : '-'}</Td>
                    <Td>
                      {item?.resolved_date
                        ? format(new Date(item.resolved_date), 'dd/MM/yyyy')
                        : '-'}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Ticket No.</Th>
              <Th>Raised On</Th>
              <Th>Status</Th>
              <Th>Issue Type</Th>
              <Th>Resolved Date</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <Flex justifyContent='center'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<ChevronRightIcon transform='scale(2)' />}
          onPageChange={(selectedPage) => {
            setQueryParams((prev) => ({
              ...prev,
              page_number: selectedPage.selected + 1,
            }))
          }}
          pageCount={totalPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          previousLabel={<ChevronLeftIcon transform='scale(2)' />}
          forcePage={queryParams.page_number && queryParams.page_number - 1}
          className='paginate'
          pageClassName='page-item'
          nextLinkClassName='paginate-button'
          previousLinkClassName='paginate-button'
          activeClassName='active-page'
        />
      </Flex>
    </>
  )
}

export { SupportListing }
