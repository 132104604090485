import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TSelectedSubType, TSelectedType } from './HomePage'

export interface IHomePageState {
  selectedType: TSelectedType
  selectedSubType: TSelectedSubType
  vehicleNumber: string
}

const initialState: IHomePageState = {
  selectedType: 'private',
  selectedSubType: 'car',
  vehicleNumber: '',
}

const homePageSlice = createSlice({
  name: 'homePageSlice',
  initialState: initialState,
  reducers: {
    updateSelectedType: (state, action: PayloadAction<TSelectedType>) => {
      state.selectedType = action.payload
    },
    updateSelectedSubType: (state, action: PayloadAction<TSelectedSubType>) => {
      state.selectedSubType = action.payload
    },
    updateVehicleNumber: (state, action: PayloadAction<string>) => {
      state.vehicleNumber = action.payload
    },
    updateAllDetails: (state, action: PayloadAction<IHomePageState>) => {
      const { vehicleNumber, selectedSubType, selectedType } = action.payload
      if (vehicleNumber) {
        state.vehicleNumber = vehicleNumber
      }
      if (selectedSubType) {
        state.selectedSubType = selectedSubType
      }
      if (selectedType) {
        state.selectedType = selectedType
      }
    },
  },
})

const { reducer } = homePageSlice

const { updateSelectedType, updateSelectedSubType, updateVehicleNumber, updateAllDetails } =
  homePageSlice.actions

export {
  reducer as homePageReducer,
  updateSelectedType,
  updateSelectedSubType,
  updateVehicleNumber,
  updateAllDetails,
}
