import { Container } from '@chakra-ui/react'
import { useAppSelector } from 'app/hooks'
import { UseFormReturn } from 'react-hook-form'
import { ProposalCustomerConsentDesktop } from '../Desktop/ProposalCustomerConsentDesktop'
import { ProposalCustomerDetailsDesktop } from '../Desktop/ProposalCustomerDetailsDesktop'
import { ProposalNomineeDetailsDesktop } from '../Desktop/ProposalNomineeDetailsDesktop'
import { ProposalPaymentDesktop } from '../Desktop/ProposalPaymentDesktop'
import { ProposalSPTaggingDesktop } from '../Desktop/ProposalSPTaggingDesktop'
import { ProposalVehicleDetailsDesktop } from '../Desktop/ProposalVehicleDetailsDesktop'
import { TMotorProposalFormType } from '../Forms/FormModel'
import { UploadDocuments } from '../UploadDocuments'

interface IMotorProposalMobile {
  form: UseFormReturn<TMotorProposalFormType, any>
}

const MotorProposalMobile = (props: IMotorProposalMobile) => {
  const { form } = props

  const { businessrole_name, role_type } = useAppSelector((state) => state.user.authenticatedUser)
  const { isIndividual } = useAppSelector((state) => state.quotationsDetails)

  return (
    <Container marginY='4'>
      <UploadDocuments form={form} />
      <ProposalVehicleDetailsDesktop form={form} />
      <ProposalCustomerDetailsDesktop form={form} />
      <ProposalNomineeDetailsDesktop form={form} />
      {businessrole_name === 'NSP' && isIndividual === true && (
        <ProposalSPTaggingDesktop form={form} />
      )}
      <ProposalCustomerConsentDesktop />
      <ProposalPaymentDesktop form={form} />
    </Container>
  )
}

export { MotorProposalMobile }
