import {
  Box,
  Button,
  Flex,
  Grid,
  LinkBox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { BsTelephoneForward } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import logo from 'Assets/logo2.png'
import { HEADER_HEIGHT } from 'Constants'
import { ElevatedButton } from 'Core'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useLogoutMutation } from 'Services/API/authentication.api'
export const Desktop = () => {
  const { isLoggedIn, authenticatedUser, isRoleVerified } = useAppSelector((state) => state.user)
  const navigate = useNavigate()

  const [logout] = useLogoutMutation()

  return (
    <>
      <Box
        shadow='DEFAULT'
        // backgroundColor={'rgb(244, 246, 250)'}
        h={`${HEADER_HEIGHT}`}
        paddingTop='4'
        w='full'
        zIndex='20'
      >
        <Flex paddingX='8' justifyContent='space-between'>
          <Flex alignItems='center' gridColumn='2' padding='1' w='120px' h='45px'>
            <Link to='/customer-account'>
              <img src={logo} alt='logo' />
            </Link>
          </Flex>
          <Flex gap={9} alignItems='center'>
            <Link to='/customer-account'>
              <Button colorScheme='primary' variant='link'>
                Home
              </Button>
            </Link>
            {/* <Link to='/aboutus'>
              <Button colorScheme='primary' variant='link'>
                About Us
              </Button>
            </Link> */}
            {/* <Button colorScheme='primary' variant='link'>
              Contact
            </Button> */}
            {isLoggedIn && isRoleVerified ? (
              <Menu>
                <MenuButton
                  as={Button}
                  colorScheme='primary'
                  variant='link'
                  rightIcon={<ChevronDownIcon />}
                >
                  {authenticatedUser.firstname}
                </MenuButton>
                <MenuList fontSize='sm'>
                  {/* <MenuItem>Offline Policy Upload</MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      navigate('/my-leads')
                    }}
                  >
                    My Leads
                  </MenuItem>
                  <MenuDivider />
                  <MenuGroup title='Sales Report'>
                    <MenuItem onClick={() => navigate('/sales/motor')}>Motor</MenuItem>
                    <MenuItem onClick={() => navigate('/sales/health')}>Health</MenuItem>
                    <MenuItem onClick={() => navigate('/sales/life')}>Life</MenuItem>
                    <MenuItem onClick={() => navigate('/sales/sachet')}>Sachet</MenuItem>
                    {/* <MenuItem>Life</MenuItem> */}
                  </MenuGroup>
                  <MenuGroup title='Sales Summary'>
                    <MenuItem onClick={() => navigate('/sales-summary/motor')}>Motor</MenuItem>
                    {/* <MenuItem>Life</MenuItem> */}
                  </MenuGroup>
                  {/* <MenuDivider />
                  <MenuGroup title='Support'>
                    <MenuItem onClick={() => navigate('/ticket-creation')}>
                      Raise Help Request
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/support-listing')}>
                      Support Listing
                    </MenuItem>
                  </MenuGroup> */}
                  <MenuDivider />

                  {/* <MenuItem>
                    <Box paddingRight='2'>
                      <BsTelephoneForward />
                    </Box>
                    <Text color='#2C2C2C'>Helpline</Text>
                  </MenuItem> */}
                  <MenuItem
                    onClick={async () => {
                      await logout(null)
                      navigate('/')
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Button onClick={() => navigate('/login')} colorScheme='primary' variant='link'>
                Login
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
