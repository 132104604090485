import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { ProposalCustomerConsentForm } from '../Forms/ProposalCustomerConsentForm'

const ProposalCustomerConsentDesktop = () => {
  return (
    <>
      <Flex flexBasis='40%' border='1px' maxWidth='100%' direction='column'>
        <Box padding='3' boxShadow='excludingTop'>
          <Text fontSize='md' textAlign='left' paddingX='4' paddingY='2' fontWeight='bold'>
            Customer Consent Form
          </Text>
        </Box>
        <ProposalCustomerConsentForm />
      </Flex>
    </>
  )
}

export { ProposalCustomerConsentDesktop }
