import {
  AvatarBadge,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Checkbox,
} from '@chakra-ui/react'
import { Header } from 'Components'
import { useEffect, useState } from 'react'
import { QuotationCard } from 'Core'
import { IoIosGitCompare } from 'react-icons/io'

import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useDeviceType, useWindowDimensions } from 'app/hooks'
import { copyToClipboard, decryptData, getAgeFromDob } from 'Services/general'

import { CgSortAz } from 'react-icons/cg'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { LEFT_SIDE_PANEL_WIDTH, LeftPanel } from 'Pages/Health/QuotationPage/Desktop/LeftPanel'
import { RightPanel } from 'Pages/Health/QuotationPage/Desktop/RightPanel'
import { LifeLeftPanel } from '../LifeLeftPanel'
import {
  useGetLifeRecommendedPlanMutation,
  useLazyGetRiderMasterQuery,
} from 'Services/API/life.api'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { updateRider } from 'features/Life/LifeCustomerDetails.life.slice'
import {
  updateFilter,
  updateRiderAffectKeys,
  updateRiderQuestion,
} from 'features/Life/PlanData.slice'
import { cloneDeep } from 'lodash'
import { LifeQuotation } from '../Quotation'
import { de } from 'date-fns/locale'
import { FilterDialog } from '../Quotation/FilterDialog'
import { LifeQuotationCard } from './LifeQuotationCard'
import { LifeQuotationNotRecommendedCard } from './LifeQuotationNotRecommendedCards'
import {
  resetFinalSelectedPlan,
  updateAllRecommendedPlans,
} from 'features/Life/LifeQuotationDetails.slice'
import { SkeletonQuoteItemLife } from 'Components/Skeleton/LifeQuoteCard'
import { RecommendedPlan } from 'Models/ResponseModels/Life'
import { ISaveLeadResponse } from 'Models/ResponseModels'

interface QuotesData {
  uid: string
  insurerName: string
  insuranceName: string
  premium: number
  sumInsured: number
  addedInCart: boolean
  code: string
  quoteNumber: string
  highlights: { id: number; highlight: string }[]
  benefits: { id: number; name: string }[]
  insurerLogo: string
  selected: boolean
  insuranceNumber: string
  insurerId: string
  uniqueIdentifier: string
  isAutoDebitSupported: boolean
  brochure: string
  isChecked: boolean
  isAutoPaySupported: boolean
}

const LifeQuotationPage = () => {
  // --------------------------------------Hooks --------------------------------------

  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const { isMobile, isDesktop, isTablet } = useDeviceType()
  const dispatch = useAppDispatch()
  const { selectedPlanType, cartDetails } = useAppSelector((state) => state.cart)
  const {
    memberDetails,
    proposer,
    isProposerIncluded,
    sum_insured,
    isParentsIncluded,
    selfPincode,
    parentsPincode,
  } = useAppSelector((state) => state.memberDetails)

  const {
    isOpen: isFilterMenuOpen,
    onOpen: onFilterMenuOpen,
    onClose: onFilterMenuClose,
  } = useDisclosure()
  const { isOpen: isCartOpen, onOpen: onCartOpen, onClose: onCartClose } = useDisclosure()
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const completeMemberDetails = useAppSelector((state) => state.memberDetails)
  const cart_details = useAppSelector((state) => state.cart)

  // const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()
  const { selectedQuotes } = useAppSelector((state) => state.cart)

  // --------------------------------------State--------------------------------------

  // const [quotesData, setquotesData] = useState<IPlanQuotesResponse[]>([])
  const [showFilters, setShowFilters] = useState(false)

  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false) // to show compare modal

  // --------------------------------------API calls--------------------------------------

  // const {
  //   data: insuranceListingData,
  //   isLoading: insuranceLoading,
  //   isFetching: insuranceFetching,
  // } = useGetInsurerListingPlansQuery({ is_previous_insurer: '' })
  // const { data: BenefitsData } = useGetGenericBenefitsListingQuery()

  const [itemsToCompare, setItemsToCompare] = useState<
    {
      insuranceId: string
      insuranceName: string
      sumInsured: string
      premium: string
      insuranceLogo: string
      checked: boolean
    }[]
  >([])

  // --------------------------------------SideEffect--------------------------------------

  // useEffect(() => {
  //   createLead()
  // }, [])

  // useEffect(() => {
  //   setShowLoading(true)
  //   requestQuotes({

  //   })
  //     .unwrap()
  //     .catch((error) => {
  //       console.log(error, 'error in catch')
  //       ToastService.showErrorToast({
  //         title: 'Something Went Wrong!',
  //       })
  //     })
  // }, [selectedPlanType, sum_insured, selectedTab, insurer])

  const checkForAutoDebit = (
    debit_types: {
      debit_type_name: string
      debit_type: number
    }[],
  ) => {
    if (debit_types.map((item) => item.debit_type).includes(1)) {
      return true
    }
    return false
  }

  const checkForAutoPay = (is_auto_pay_supported: number) => {
    if (is_auto_pay_supported) {
      return true
    }
    return false
  }

  // ------------------- Function-------------------

  const { selected_plan, riderAffectKeys } = useAppSelector((state) => state.planData)

  // const onFilter = (data: {
  //   insurer: number[]
  //   plan_type: 'Family Floater' | 'Individual' | 'Multi Individual'
  //   sumInsured: string
  //   benefit: number[]
  // }) => {
  //   setItemsToCompare([])
  //   setShowLoading(true)
  //   setquotesData([])
  //   data.plan_type && dispatch(updatedPlanType(data.plan_type))
  //   data.sumInsured && dispatch(updateSumInsured(data.sumInsured))

  //   setInsurer([...data.insurer])
  //   // setBenefit([...data.benefit])
  // }

  const showCartButton = () => {
    if (cartDetails.allMembers || cartDetails.parents || cartDetails.selfSpouseKids) {
      return true
    }
    return false
  }

  const detailsFromStore = useAppSelector((state) => state.lifeCustomerDetails)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [buttonSelected, setButtonSelected] = useState(false)
  const [isStaff, setIsStaff] = useState(false)
  const [optForRider, setOptForRider] = useState(false)

  useEffect(() => {
    dispatch(updateFilter(undefined))
  }, [])

  const selectedRecommendedPlan = useAppSelector(
    (state) => state.lifeQuotationDetails.selectedRecommendedPlanType,
  )
  const userDetails = useAppSelector((state) => state.user.authenticatedUser)
  const selectedSp = useAppSelector((state) => state.spTagging.selectedSp)
  const spTaggingDetails = useAppSelector((state) => state.spTagging)
  const formDataFromStore = useAppSelector((state) => state.lifeCustomerDetails)
  const [
    getRecommendedPlans,
    { data: quoteApiData, isLoading: isRecommendedPlansLoading, isError: isRecommendedPlansError },
  ] = useGetLifeRecommendedPlanMutation()
  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()

  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)

  useEffect(() => {
    // dispatch(resetFinalSelectedPlan())
    if (lifeQuotationDetails.relationship) {
      getRecommendedPlans({
        insurer_plans:
          lifeQuotationDetails.selectedRecommendedPlanType === 'Term'
            ? lifeQuotationDetails.planIdForTerm
            : lifeQuotationDetails.planIdForEndowment,
        la_customer_id: lifeQuotationDetails.life_assured_id ?? -1,
        pr_customer_id: lifeQuotationDetails.proposer_id ?? -1,
        product_category_code: 'life',
        proposer_same: lifeQuotationDetails.relationship === 'Self' ? true : false,
        partner_id: 5,
        flow: 'insurer',
        relationship: lifeQuotationDetails?.relationship ?? '',
        lead_id: leadDetails.lead_uid!,
        suitability_data: lifeQuotationDetails.suitabilityData,
        agent_id:
          userDetails.businessrole_name === 'SP'
            ? userDetails.id?.toString()
            : selectedSp?.id?.toString(),
        branch_code: userDetails.branch_sol_id ?? '',
        branch_name: userDetails.branch_name ?? '',
      }).unwrap()
    }
  }, [lifeQuotationDetails.relationship])

  const createLead = async () => {
    const payload: ISaveLead = formParam('Pre-Quotation', 'life')
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  useEffect(() => {
    if (lifeQuotationDetails.selectedRecommendedPlanType && leadDetails.lead_uid) {
      createLead()
    }
  }, [lifeQuotationDetails.selectedRecommendedPlanType, leadDetails])

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      lead_data: {
        // cart_details: cart_details,
        // member_details: memberDetails,
        lifeCustomerDetails: detailsFromStore,
        lifeQuotationDetails: lifeQuotationDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: spTaggingDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  useEffect(() => {
    if (quoteApiData) {
      const t: RecommendedPlan[] = []
      quoteApiData.recommended_plans.map((plan) => {
        if (plan.recommended) {
          t.push(plan)
        }
      })
      dispatch(updateAllRecommendedPlans(t))
    }
  }, [quoteApiData])

  const updateKeyValueInTree = (
    form_fields: INestedQuestionSet[],
    code: string,
    key: string,
    newValue: number,
  ) => {
    for (let i = 0; i < form_fields.length; i++) {
      const item = form_fields[i] as any
      if (item.code === code) {
        item[key] = newValue
        // break
      } else {
        Object.values(item.values).forEach((child: any) => {
          return updateKeyValueInTree(child, code, key, newValue)
        })
      }
    }
    return form_fields
  }

  // const [planOptionQuestionList, setPlanOptionQuestionList] = useState<INestedQuestionSet[]>()

  // const [getRider] = useLazyGetRiderMasterQuery()

  // const onAffect = async (
  //   name: string,
  //   value: string | number,
  //   affect: string,
  //   factor?: number,
  //   operation?: string,
  // ) => {
  //   if (affect.toLowerCase() === 'rider') {
  //     const plan_data = Object.assign({}, riderAffectKeys)
  //     plan_data[name] = value
  //     dispatch(updateRiderAffectKeys(plan_data))
  //     // riderForm.reset()
  //     const resp2 = await getRider({
  //       plan: selected_plan?.name ?? '',
  //       plan_data: plan_data,
  //       la_pr_equal: false,
  //     }).unwrap()
  //     dispatch(updateRiderQuestion(resp2))
  //   } else if (
  //     affect.includes('-') &&
  //     operation &&
  //     affect &&
  //     factor &&
  //     value &&
  //     planOptionQuestionList
  //   ) {
  //     const code = affect.split('-')[0]
  //     const key = affect.split('-')[1]
  //     if (operation === 'mul') {
  //       const newValue = factor * Number(value)
  //       const planOptionQuestionListTemp = updateKeyValueInTree(
  //         cloneDeep(planOptionQuestionList),
  //         code,
  //         key,
  //         newValue,
  //       )
  //       setPlanOptionQuestionList(planOptionQuestionListTemp)
  //     }
  //   }
  // }

  // const form = useForm({
  //   shouldUseNativeValidation: false,
  //   mode: 'onChange',
  // })

  // const riderForm = useForm({
  //   shouldUseNativeValidation: false,
  //   mode: 'onChange',
  // })

  // const onRiderFormSubmit: SubmitHandler<FieldValues> = async (data) => {
  //   dispatch(updateRider(riderForm.getValues()))
  //   // if (filter) {
  //   //   onGetQuotation(filter, data)
  //   // }
  // }

  const [viewMore, setViewMore] = useState(false)
  const [viewMoreCheckboxChecked, setViewMoreCheckboxChecked] = useState(false)

  // console.log(isRecommendedPlansLoading, '{isRecommendedPlansLoading}')
  return (
    <>
      <Header />
      <Box backgroundColor={'#FAFAFA'}>
        {/* ------------ Main Content------------  */}
        <Box>
          {/* ------------ Header Content------------  */}

          <Box w='80%' mx='auto' px='4'>
            <Box>
              <Flex
                m='5'
                py='7'
                backgroundColor={'white'}
                borderRadius={'70'}
                alignItems={'center'}
                gap='10'
                fontSize={'14px'}
              >
                <Box borderRight={'1px solid black'} height={'30px'}>
                  <Text px='5' fontWeight={'bold'}>
                    Showing Quotes For
                  </Text>
                </Box>
                <Box px='7' borderRight={'1px solid black'}>
                  <Flex gap='5'>
                    <Flex direction={'column'}>
                      <Text color={'#999999'}>Name</Text>
                      <Text color={'#999999'}>Contact Number</Text>
                    </Flex>

                    <Flex direction={'column'}>
                      <Text
                        color={'black'}
                      >{`${detailsFromStore.lifeAssuredDetailsTyped?.firstName} ${detailsFromStore.lifeAssuredDetailsTyped?.middleName} ${detailsFromStore.lifeAssuredDetailsTyped?.lastName}`}</Text>
                      <Text color={'black'}>
                        {detailsFromStore.lifeAssuredDetailsTyped?.mobile}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>

                <Box px='7' borderRight={'1px solid black'}>
                  <Flex gap='5'>
                    <Flex direction={'column'}>
                      <Text color={'#999999'}>Age</Text>
                      <Text color={'#999999'}>Gender</Text>
                    </Flex>

                    <Flex direction={'column'}>
                      <Text color={'black'}>
                        {Math.trunc(
                          getAgeFromDob(
                            new Date(detailsFromStore.lifeAssuredDetailsTyped?.dob ?? ''),
                          ),
                        ).toString()}
                      </Text>
                      <Text color={'black'}>
                        {detailsFromStore.lifeAssuredDetailsTyped?.gender.name ?? '-'}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>

                <Box px='7'>
                  <Flex gap='5'>
                    <Flex direction={'column'}>
                      <Text color={'#999999'}>Type of Insurance</Text>
                      <Text color={'#999999'}>Smoker Status</Text>
                    </Flex>

                    <Flex direction={'column'}>
                      <Text color={'black'}>{selectedRecommendedPlan}</Text>
                      <Text color={'black'}>{detailsFromStore.lifeAssuredSmokerStatus}</Text>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
              <Flex gap='3' justifyContent='space-between' mb='4'>
                <Text fontWeight='semibold' fontSize='xl'>
                  Showing Recommended Plans
                </Text>
                <Box>
                  <Button
                    // leftIcon={<MdBuild />}
                    colorScheme='secondary'
                    fontSize='sm'
                    marginX='2'
                    variant='outline'
                    onClick={() => {
                      copyToClipboard(leadDetails.lead_uid ?? '')
                      // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
                      // ToastService.showSuccessTaost({
                      //   title: 'Success',
                      //   message: 'Lead Saved Successfully',
                      // })
                    }}
                  >
                    {leadDetails.lead_uid ?? '-'}
                  </Button>
                </Box>
              </Flex>
            </Box>
            <Grid
              templateColumns={{
                base: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)',
                xl: 'repeat(3, 1fr)',
                '2xl': 'repeat(4,1fr)',
              }}
              gap={6}
            >
              {quoteApiData?.recommended_plans
                .filter((plan) => plan.recommended || viewMore)
                .map((item, index) => {
                  return (
                    <GridItem key={item.code} w='full' ml='2'>
                      <LifeQuotationCard plan={item} />
                    </GridItem>
                  )
                })}
              {isRecommendedPlansLoading &&
                Array(3)
                  .fill(0)
                  .map((item, index) => (
                    <GridItem key={item.code} w='full' m='2'>
                      <SkeletonQuoteItemLife key={index} />
                    </GridItem>
                  ))}
            </Grid>
            {quoteApiData && (
              <Flex alignItems='center' justifyContent='space-around'>
                <Center mt='10' flexBasis='70%'>
                  <Checkbox
                    onChange={(e) => {
                      setViewMoreCheckboxChecked(e.target.checked)
                    }}
                  >
                    I/ We have gone through the list of products(s) and fund(s) recommended to me
                    based on the risk profiling conducted by Equitas Small Finance Bank and/or the
                    insurance partner. I/We wish to supersede the recommendations of Equitas Small
                    Finance Bank and/or the insurance partner & view other Life Insurance
                    product(s). I/We agree to purchase the product(s) based on my independent
                    assessment of the risks, merits and suitability of the product(s). I/We will not
                    hold Equitas Small Finance Bank and/or the insurance partner responsible for my
                    acceptance of such product(s) and fund(s) as per my/our understanding
                  </Checkbox>
                </Center>
                <Button
                  mt='8'
                  variant='outline'
                  onClick={() => {
                    setViewMore(true)
                  }}
                  isDisabled={!viewMoreCheckboxChecked}
                >
                  VIEW MORE OPTIONS
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
        {(showCartButton() || itemsToCompare.length > 1 || isMobile) && (
          <Box
            position='fixed'
            right='0'
            bottom='0'
            w={isDesktop ? width - LEFT_SIDE_PANEL_WIDTH + 'px' : 'full'}
            bgGradient='linear(to-t, blackAlpha.700, whiteAlpha.500)'
          >
            <Flex justifyContent='end' className='items-center' h='24' px='8' gap='8'>
              {isMobile && (
                <Box>
                  <Button colorScheme='primary' position={'relative'} onClick={onFilterMenuOpen}>
                    Filter
                    <Spacer w={'2'} />
                    <CgSortAz size='30' />
                  </Button>
                </Box>
              )}

              {itemsToCompare.length > 1 && (
                <Box>
                  <Button
                    colorScheme='primary'
                    position={'relative'}
                    onClick={() => setIsCompareModalOpen(true)}
                  >
                    Compare
                    <Spacer w={'2'} />
                    <Flex
                      justifyContent='center'
                      alignItems='center'
                      h={5}
                      w={5}
                      bg='primary.800'
                      rounded='full'
                      position='absolute'
                      top={-1}
                      left={-1}
                    >
                      <Text>{itemsToCompare.slice(0, 3).length}</Text>
                    </Flex>
                    <IoIosGitCompare size={'25'} />
                  </Button>
                </Box>
              )}
            </Flex>
          </Box>
        )}
        <Spacer h='36' />
      </Box>
    </>
  )
}

export { LifeQuotationPage }
