import { extendTheme, theme as defaultTheme, withDefaultColorScheme } from '@chakra-ui/react'
import components from './core'
import breakpoints from './breakpoints'
import foundations from './foundations'
import typography from './typography'

export const royTheme = extendTheme(
  {
    ...components, // customizing Chakra UI Components
    ...foundations,
    ...typography,
    breakpoints,
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
)
