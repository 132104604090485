import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Flex, Image, Text, Heading, Link } from '@chakra-ui/react'
import { directDebitResponseProps, formatCurr } from 'Pages/Life/Sachet/ConsentForm'
import { useDirectDebitMutation } from 'Services/API/sachet.api'
import { ToastService } from 'Services/toast.service'

interface PaymentStatusProps {
  directDebitResponse: directDebitResponseProps
  DDpaymentStatus: string
  setGotDirectDebitResponse: any
  setDirectDebitResponse: any
  quoteId: any
  data: any
  setDDPaymentStatus: any
  coi_no?: string
}

const SachetPaymentStatusModal = ({
  directDebitResponse,
  DDpaymentStatus,
  setGotDirectDebitResponse,
  setDirectDebitResponse,
  quoteId,
  data,
  setDDPaymentStatus,
  coi_no,
}: PaymentStatusProps) => {
  const [directDebitFunc, { isLoading: isDirectDebitLoading }] = useDirectDebitMutation()
  console.log(coi_no)
  console.log(directDebitResponse)

  const handleRetry = async () => {
    const response: any = await directDebitFunc({
      quote_id: quoteId,
      amount: data ? parseFloat(data.ui_data.selectedProductArrObjData?.premium) : 0,
      email: (data && data?.ui_data?.email) || '',
      phone_number: (data && data?.ui_data?.phoneNumber) || '',
    })

    setDirectDebitResponse(response?.data?.data)
    setDDPaymentStatus(response?.data?.message)
    setGotDirectDebitResponse(true)
    const paymentStatus = response?.data?.message

    if (paymentStatus === 'success') {
      ToastService.showSuccessTaost({
        title: 'Payment successfull.',
      })
    } else if (paymentStatus === 'failed') {
      ToastService.showErrorToast({
        title: 'Payment Failed , please try again',
      })
    }
  }

  return (
    <Flex direction='column' justifyContent='center' alignItems='center' gap='6'>
      <Heading as='h3' color='green'>
        Payment Status
      </Heading>

      <Box
        fontSize='14px'
        fontFamily='Roboto'
        textAlign='center'
        maxW='600px'
        m='auto'
        lineHeight='30px'
        border='2px solid rgb(26, 26, 213)'
        borderRadius='30px'
        p={4}
      >
        <Flex justifyContent='center' alignItems='center' mt={2} mb={2}>
          <Image
            src='https://insurance.equitasbank.com/foundation/foundation/media/22f_0xqZLl_2XM13bTVGRw=='
            boxSize='50px'
            objectFit='cover'
          />
        </Flex>

        {DDpaymentStatus === 'success' ? (
          <Text textAlign='center' mb={2} color='rgb(40, 193, 13)' fontSize='1.2rem'>
            Congratulations! Your payment is successful
            <Text>Transaction Reference No: {directDebitResponse.transaction_id}</Text>
          </Text>
        ) : (
          <Text textAlign='center' mb={2} color='rgb(241, 47, 30)' fontSize='1.2rem'>
            Sorry! Your payment is unsuccessful. But don{"'"}t worry, you could try again.
          </Text>
        )}

        <Divider borderTop='2px dashed rgb(40, 193, 13)' mb={2} />

        <Box p={6}>
          <Flex justifyContent='space-between' mb={4}>
            <Box textAlign='left' color={DDpaymentStatus === 'success' ? 'green' : 'black'}>
              <Text fontWeight='medium' fontSize='1.1rem'>
                Transaction ID: {directDebitResponse?.transaction_id || ''}
              </Text>
              <Text fontWeight='medium' fontSize='1.1rem'>
                Lead Number: {directDebitResponse?.lead_number || ''}
              </Text>
              <Text fontWeight='medium' fontSize='1.1rem'>
                Amount Paid: {formatCurr(directDebitResponse?.amount) || ''}
              </Text>
              <Text fontWeight='medium' fontSize='1.1rem'>
                Insurance: {directDebitResponse?.insurer_name || ''}
              </Text>
              <Text fontWeight='medium' fontSize='1.1rem'>
                Date: {directDebitResponse?.current_date || ''}
              </Text>
              <Text fontWeight='medium' fontSize='1.1rem'>
                Payment: {directDebitResponse?.payment_method || ''}
              </Text>
              {directDebitResponse.coi_no && (
                <Text fontWeight='medium' fontSize='1.1rem'>
                  COI No.: {directDebitResponse.coi_no || ''}
                </Text>
              )}
            </Box>
            <Image
              src='https://insurance.equitasbank.com/foundation/foundation/media/WoOPI4z3JgbM4M24EfmcTQ=='
              boxSize='120px'
              objectFit='cover'
            />
          </Flex>

          <Text fontStyle='italic' color='blue' fontSize='15px'>
            Note: Please note your policy would be underwritten by the insurer as per their
            underwriting guidelines.
          </Text>

          <Flex justifyContent='center' alignItems='center' gap={4} mt={4}>
            {DDpaymentStatus === 'success' ? (
              directDebitResponse?.pdf_url !== 'Failed to generate HTML content' ? (
                <Link href={directDebitResponse?.pdf_url} target='_blank' download>
                  <Button
                    bg='rgb(118, 8, 161)'
                    color='white'
                    borderRadius='8px'
                    p={3}
                    _hover={{ bg: 'rgb(94, 7, 128)' }}
                    fontSize='1.1rem'
                  >
                    Download Policy Doc
                  </Button>
                </Link>
              ) : (
                <></>
              )
            ) : (
              <Link>
                <Button
                  bg='rgb(118, 8, 161)'
                  color='white'
                  borderRadius='8px'
                  p={3}
                  _hover={{ bg: 'rgb(94, 7, 128)' }}
                  onClick={handleRetry}
                  fontSize='1.1rem'
                  isLoading={isDirectDebitLoading}
                >
                  Retry
                </Button>
              </Link>
            )}
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default SachetPaymentStatusModal
