import React from 'react'
import {
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'

export const Modal = ({
  children,
  isOpen = false,
  onClose,
  headerText,
}: {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  headerText?: string
}) => {
  const { isDesktop } = useDeviceType()
  return (
    <>
      <ChakraModal isOpen={isOpen} onClose={() => onClose()}>
        <ModalOverlay />
        <ModalContent
          maxWidth={isDesktop ? 'fit-content' : '90%'}
          height='fit-content'
          rounded='2xl'
        >
          <ModalHeader borderBottom={headerText ? '1px solid #ADADAD' : ''} fontWeight={'medium'}>
            {headerText}
          </ModalHeader>
          <ModalCloseButton onClick={() => onClose()} />
          {children}
        </ModalContent>
      </ChakraModal>
    </>
  )
}
