import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import { Select } from 'chakra-react-select'
import { ElevatedButton, ErrorText } from 'Core'
import { format } from 'date-fns'
import { updateIsOtpVerified, updateSelectedSp, updateSpConsentStatus } from 'features/Equitas'
import { updateProposalDetails } from 'features/Motor'
import { ISpData } from 'Models/ResponseModels/Equitas'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  useGetSpListingQuery,
  useSpTaggingSendOtpMutation,
  useSpTaggingVerifyOtpMutation,
} from 'Services/API/authentication.api'
import {
  useGetSpConsentFormLinkMutation,
  useLazyCheckForspConsentSubmissionStatusQuery,
} from 'Services/API/motor.api'
import { ToastService } from 'Services/toast.service'
// import { useGetSpListingQuery } from 'Services/API/equitas.api'
import { TMotorProposalFormType } from './FormModel'
import { decryptData } from 'Services/general'

interface IProposalSpTaggingForm {
  form: UseFormReturn<TMotorProposalFormType, any>
}

const ProposalSpTaggingForm = (props: IProposalSpTaggingForm) => {
  const { selectedSp: selectedSpInStore, spConsentStatus } = useAppSelector(
    (state) => state.spTagging,
  )

  const [spListing2, setSpListing2] = useState<ISpData[]>([])

  const leadDetails = useAppSelector((state) => state.leadDetails)

  const { id: userId } = useAppSelector((state) => state.user.authenticatedUser)

  const { data: spListing, isLoading: isSpListingLoading } = useGetSpListingQuery(
    {
      user_id: userId?.toString() ?? '',
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (spListing) {
      setSpListing2(JSON.parse(decryptData(spListing.response_data)))
    }
  }, [spListing])

  const dispatch = useAppDispatch()

  const { form } = props

  const [selectedSp, setSelectedSp] = useState<ISpData | undefined>(selectedSpInStore)

  useEffect(() => {
    if (selectedSpInStore) {
      form.setValue('spData', selectedSpInStore)
    }
  }, [])

  // const [isOtpSent, setIsOtpSent] = useState(false)
  // const [otp, setOtp] = useState('')

  // const [sendOtp, { isLoading: sendOtpLoading }] = useSpTaggingSendOtpMutation()
  // const [verifyOtp, { isLoading: verifyOtpLoading }] = useSpTaggingVerifyOtpMutation()

  const unique_identifier = useAppSelector(
    (state) => state.quotationsDetails.selectedQuote?.unique_identifier,
  )
  const { ucic, proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)

  const { selectedQuote, isComprehensive, isIndividual } = useAppSelector(
    (state) => state.quotationsDetails,
  )
  const { isSaod } = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)

  const [
    getSpConsent,
    {
      isLoading: isGetSpConsentLoading,
      isSuccess: isGetSpConsentSuccess,
      isUninitialized: isGetSpConsentUninitialized,
    },
  ] = useGetSpConsentFormLinkMutation()

  const [isCheckSubmissionRunning, setIsCheckSubmissionRunning] = useState(false)

  const [pollingInterval, setPollingInterval] = useState(4000)

  const [checkForSpConsentSubmissionStatus, { data: submissionResponse }] =
    useLazyCheckForspConsentSubmissionStatusQuery({
      pollingInterval: pollingInterval,
    })

  useEffect(() => {
    console.log('_________--------- submissin', submissionResponse)
    if (submissionResponse?.is_submited === true) {
      console.log('_________--------- submissin', submissionResponse)

      setPollingInterval(0)
      setIsCheckSubmissionRunning(false)
      dispatch(updateSpConsentStatus('success'))
      // dispatch(updatePaymentActivated(true))
    }
  }, [submissionResponse])

  return (
    <Box>
      <Flex direction='column' marginY='4' marginX='6'>
        <FormLabel>SP Tagging ID*</FormLabel>
        <Select
          placeholder='Select SP Tagging'
          isLoading={isSpListingLoading}
          {...form.register('spData', { required: true })}
          isDisabled={isGetSpConsentSuccess}
          value={selectedSp}
          onChange={(item) => {
            setSelectedSp(item ?? {})
            form.setValue('spData', item ?? {})
            form.clearErrors('spData')
            dispatch(updateSelectedSp(item ?? {}))
          }}
          options={spListing2?.map((spData: ISpData) => {
            return {
              ...spData,
              label:
                (spData?.firstname ?? '') + ' ' + (spData?.lastname ?? '') + ' (' + spData.id + ')',
              value: spData.id?.toString(),
            }
          })}
        />
        {form.formState.errors.spData && form.formState.errors.spData?.type === 'required' && (
          <ErrorText text='SP Tagging ID is required' />
        )}
      </Flex>
      {/* TODO */}
      {spConsentStatus === 'not-sent' && (
        <Center>
          <ElevatedButton
            isDisabled={isGetSpConsentLoading || !selectedSp}
            isLoading={isGetSpConsentLoading}
            onClick={async () => {
              const isFormValid = await form.trigger()
              if (isFormValid) {
                dispatch(updateProposalDetails(form.getValues()))
                try {
                  const resp: any = await getSpConsent({
                    lead_uid: leadDetails.lead_uid ?? '',
                    unique_identifier: unique_identifier ?? '',
                    // payment_link: 'remove_this_key',
                    plan_type: isSaod
                      ? 'Stand Alone OD'
                      : isComprehensive
                      ? 'Comprehensive'
                      : 'Third Party',
                    customer: {
                      owner_type: isIndividual ? 'Individual' : 'Company',
                      application_no: unique_identifier ?? '',
                      customer_name: isIndividual
                        ? (
                            (form.getValues('firstName') ?? '') +
                            ' ' +
                            (form.getValues('middleName') ?? '') +
                            ' ' +
                            (form.getValues('lastName') ?? '')
                          ).trim()
                        : form.getValues('companyName') ?? '',
                      dependent_name: isIndividual
                        ? (
                            (form.getValues('nomineeFirstName') ?? '') +
                            ' ' +
                            (form.getValues('nomineeMiddleName') ?? '') +
                            ' ' +
                            (form.getValues('nomineeLastName') ?? '')
                          ).trim()
                        : form.getValues('companyName') ?? '',
                      email_id: selectedSp?.email ?? '',
                      insurance_type: 'Motor',
                      login_date: format(new Date(), 'dd/MM/yyyy'),
                      method: 'Insurer Payment Gateway',
                      mode: 'Online',
                      phone: selectedSp?.phone ? '91' + selectedSp?.phone : '',
                      plan_name: selectedQuote?.insurance_name ?? '',
                      policy_purchased_for: isIndividual
                        ? (
                            (form.getValues('firstName') ?? '') +
                            ' ' +
                            (form.getValues('middleName') ?? '') +
                            ' ' +
                            (form.getValues('lastName') ?? '')
                          ).trim()
                        : form.getValues('companyName') ?? '',
                      policy_term: '1 Year',
                      premium: selectedQuote?.premium_total?.toString() ?? '',
                      premium_payment_term: 'Annually',
                      sp_branch: selectedSp?.branch_key__branch_name ?? '',
                      sp_code: selectedSp?.id?.toString() ?? '',
                      sp_name: (
                        (selectedSp?.firstname ?? '') +
                        ' ' +
                        (selectedSp?.lastname ?? '')
                      ).trim(),
                      ucic: ucic ?? '',
                      lead_uid: leadDetails.lead_uid ?? '',
                    },
                  })
                  if (resp['data']) {
                    dispatch(updateSpConsentStatus('pending'))
                    await checkForSpConsentSubmissionStatus({
                      unique_identifier: unique_identifier ?? '',
                    })
                    // dispatch(updateSpConsentStatus('pending'))
                    setIsCheckSubmissionRunning(true)
                  }
                  // console.log(resp['data'], 'yahan aaya')
                } catch (e) {
                  console.log(e)
                }
              }
            }}
          >
            Send Consent Link
          </ElevatedButton>
        </Center>
      )}
      {spConsentStatus === 'pending' && isCheckSubmissionRunning && (
        <Center paddingX='2rem' paddingY='1rem'>
          <Flex gap='1rem' fontSize='md' direction='column'>
            <Text>
              Consent form link has been sent to <b>{selectedSp?.phone ?? ''}</b> and{' '}
              <b>{selectedSp?.email ?? '-'}</b>.
            </Text>
            <Flex gap='1rem'>
              <Text>Waiting for SP to accept the consent</Text>
              <Spinner />
            </Flex>
          </Flex>
        </Center>
      )}
      {/* {isOtpVerified.toString() + '- isOtpVerified'} */}
      {/* {!isOtpVerified && (
        <>
          {!isOtpSent && (
            <Flex direction='column' alignItems='center' marginY='4' marginX='6'>
              <ElevatedButton
                onClick={async () => {
                  await sendOtp({
                    email: 'testing@zopper.com',
                  })
                  setIsOtpSent(true)
                }}
                isDisabled={sendOtpLoading}
                isLoading={sendOtpLoading}
                disabled={!selectedSp?.value || sendOtpLoading}
              >
                Send OTP
              </ElevatedButton>
            </Flex>
          )}
          {isOtpSent && (
            <Flex paddingY='1rem' direction='column' alignItems='center' marginY='4' marginX='6'>
              <FormLabel>Enter OTP</FormLabel>
              <HStack>
                <PinInput onChange={(str) => setOtp(str)}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>
          )}
          {isOtpSent && (
            <Flex justifyContent='center' marginY='4' marginX='6'>
              <Button
                onClick={async () => {
                  const resp = await verifyOtp({
                    email: 'testing@zopper.com',
                    otp: otp,
                  })
                  dispatch(updateIsOtpVerified(true))
                  ToastService.showSuccessTaost({
                    title: 'Otp Successfully verified',
                  })
                }}
                marginX='0.5rem'
                isLoading={verifyOtpLoading}
                disabled={verifyOtpLoading || otp.length < 6}
              >
                Verify
              </Button>
              <Button
                marginX='0.5rem'
                onClick={async () => {
                  await sendOtp({
                    email: 'testing@zopper.com',
                  })
                  setIsOtpSent(true)
                }}
                isLoading={sendOtpLoading}
                disabled={sendOtpLoading}
              >
                Resend OTP
              </Button>
            </Flex>
          )}
        </>
      )} */}
    </Box>
  )
}

export { ProposalSpTaggingForm }
