// const CancerCoverForm = ({ setFormData }: { setFormData: any }) => {
//   return (
//     <Flex w='full' justifyContent='center' alignItems='center' gap='4' direction='column'>
//       <Text fontWeight='bold' fontSize='2rem'>
//         Choose the Type of cover :
//       </Text>
//       <Flex w='full' justifyContent='center' alignItems='center' gap='4'>
//         <Button
//           variant='link'
//           onClick={() => {
//             setFormData(0)
//           }}
//         >
//           Cancer Cover 1 A
//         </Button>
//         <Button
//           variant='link'
//           onClick={() => {
//             setFormData(1)
//           }}
//         >
//           Cancer Cover 2 A
//         </Button>
//       </Flex>
//     </Flex>
//   )
// }

import { Button, Flex, FormControl, FormErrorMessage, Text } from '@chakra-ui/react'
import { useAppDispatch } from 'app/hooks'
import { Select } from 'chakra-react-select'
import { setCancerCoverType } from 'features/Sachets/sachetData.slice'
import React from 'react'

interface CancerCoverFormProps {
  setFormData: (data: number) => void
}

const CancerCoverForm: React.FC<CancerCoverFormProps> = ({ setFormData }) => {
  const dispatch = useAppDispatch()
  return (
    <Flex
      w='full'
      justifyContent='center'
      alignItems='center'
      gap='4'
      direction='column'
      p='8'
      bg='gray.50'
    >
      <Text fontWeight='bold' fontSize='2xl' mb='4'>
        Choose the Type of Cover:
      </Text>
      <Flex w='full' justifyContent='center' alignItems='center' gap='4'>
        <Button
          variant='solid'
          colorScheme='blue'
          size='lg'
          onClick={() => {
            setFormData(0)
            dispatch(setCancerCoverType('1A'))
          }}
        >
          Cancer Cover 1 A
        </Button>
        <Button
          variant='solid'
          colorScheme='blue'
          size='lg'
          onClick={() => {
            setFormData(1)
            dispatch(setCancerCoverType('2A'))
          }}
        >
          Cancer Cover 2 A
        </Button>
      </Flex>
    </Flex>
  )
}

export default CancerCoverForm
