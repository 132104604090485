import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Image,
} from '@chakra-ui/react'
import { Header } from 'Components'
import {
  useCreateConsentDataMutation,
  useGetBiQuoteMutation,
  useGetConsentDataQuery,
} from 'Services/API/life.api'
import { ToastService } from 'Services/toast.service'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import success from 'Assets/Common/success.png'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { copyToClipboard, decryptData, getAccountTypeBasedOnIndex } from 'Services/general'
import {
  updateApplicationNumber,
  updateConsentId,
  updateConsentStatus,
  updatePlanQuoteId,
  updateRedirectionURL,
} from 'features/Life/LifeQuotationDetails.slice'
import { useNavigate } from 'react-router-dom'
import { ISaveLeadResponse } from 'Models/ResponseModels'
import { getCountryName } from 'Constants'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

export const ConsentPendingPage = () => {
  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  const { redirectionURL, consent_id, suitabilityData, consent_status } = useAppSelector(
    (state) => state.lifeQuotationDetails,
  )
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)
  const { businessrole_name, id } = useAppSelector((state) => state.user.authenticatedUser)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const customerDetails = useAppSelector((state) => state.customerDetails)
  const customerDetails2 = useAppSelector((state) => state.lifeCustomerDetails)
  const spTaggingDetails = useAppSelector((state) => state.spTagging)
  const filterData = useAppSelector((state) => state.planData)
  const selectedSp = useAppSelector((state) => state.spTagging.selectedSp)
  const [pollingInterval, setPollingInterval] = useState(2000)
  const [skip, setSkip] = useState(true)
  const [button, setButton] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [saveLeads, { isError }] = useSaveLeadsMutation()
  const [callBiQuote, { isLoading: isBiLoading }] = useGetBiQuoteMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [consentApproved, setConsentApproved] = useState(false)

  const [generateConsent, { isLoading: generatingConsent }] = useCreateConsentDataMutation()

  useEffect(() => {
    if (redirectionURL) {
      createLead(true)
    }
  }, [redirectionURL])

  const createLead = async (redirect?: boolean) => {
    const payload: ISaveLead = formParam(
      redirect
        ? 'Partner Redirection'
        : data?.status.toLowerCase() === 'approved'
        ? 'BI Approved'
        : 'BI Generation',
      'life',
    )
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  const biQuoteGeneration = async () => {
    try {
      const response = await callBiQuote({
        plan_id: lifeQuotationDetails.finalSelectedPlan?.id ?? '',
        lead_id: leadDetails.lead_uid!,
        quote_id: filterData.planQuoteResponse?.quote_id ?? '',
        ucic_id: equitasDetails.ucic!,
        agent_id: businessrole_name === 'SP' ? id?.toString() : selectedSp?.id?.toString(),
        relationship: lifeQuotationDetails.relationship ?? '',
      }).unwrap()
      dispatch(updateRedirectionURL(response.insurer_data.URL))
      dispatch(updateApplicationNumber(response.insurer_data.application_number))
      dispatch(updatePlanQuoteId(response.plan_quote))
      // await createLead(true)
      if (businessrole_name === 'SP') window.open(response.insurer_data.URL, '_blank')
      // try {
      //   createConsent(response.plan_quote)
      // } catch (e: any) {
      //   ToastService.showErrorToast({ title: 'Error', message: e['data']['message'] })
      // }
    } catch (e: any) {
      ToastService.showErrorToast({ title: 'Error', message: e['data']['message'] })
    }
  }

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      product_name: product_name,
      premium: filterData.planQuoteResponse?.premium,
      insurer_name: lifeQuotationDetails.finalSelectedPlan?.insurer?.toUpperCase() ?? '-',
      lead_data: {
        // cart_details: cart_details,
        // member_details: memberDetails,
        lifeCustomerDetails: customerDetails2,
        filterData: filterData,
        lifeQuotationDetails: lifeQuotationDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: spTaggingDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  useEffect(() => {
    ToastService.closeAllToast()
    if (consent_id) {
      setSkip(false)
      createLead()
      startTimer()
    }
  }, [consent_id])

  const createConsent = async () => {
    try {
      const response = await generateConsent({
        data: generateConsentData(),
        lead_id: leadDetails.lead_uid ?? '',
        plan_id: lifeQuotationDetails.finalSelectedPlan?.id ?? '',
        quote_id: filterData.planQuoteResponse?.quote_id ?? '',
        resend: 1,
        type: 'BI-APPROVAL',
      }).unwrap()
      dispatch(updateConsentId(response.consent_id))
    } catch (e: any) {
      ToastService.closeAllToast()
      ToastService.showErrorToast({
        title: 'Error',
        message: e?.data?.message ?? 'Something went wrong',
      })
      console.log(e)
    }
  }

  const generateConsentData = () => {
    return {
      selectedProductName: filterData.planQuoteResponse?.plan_name,
      email: '',
      downloadBrochure: filterData.planQuoteResponse?.brochure_url,
      downloadBusinessIllustration: filterData.planQuoteResponse?.bi_url,
      policyData: {
        logo: filterData.planQuoteResponse?.logo,
        name: filterData.planQuoteResponse?.plan_name,
        proposalDate: new Date().toDateString(),
        finalPremium: `₹ ${parseInt(
          filterData.planQuoteResponse?.premium?.toString() ?? '',
        ).toLocaleString('en-IN')}`,
        sumInsured: `₹ ${parseInt(
          filterData.planQuoteResponse?.sum_assured?.toString() ?? '',
        ).toLocaleString('en-IN')}`,
        policyFor: customerDetails2.relationship,
        pincode: customerDetails2.lifeAssuredDetailsTyped?.pincode,
        paymentMode:
          filterData.filter && filterData.filter['premium_frequency']
            ? filterData.filter['premium_frequency']
            : '-',
        paymentTerm:
          filterData.filter && filterData.filter['premium_paying_term']
            ? filterData.filter['premium_paying_term']
            : '-',
        planOption:
          filterData.filter && filterData.filter['premium_paying_option']
            ? filterData.filter['premium_paying_option']
            : '-',
        policyTerm:
          filterData.filter && filterData.filter['plan_term']
            ? filterData.filter['plan_term']
            : '-',
      },
      customerAccountDetails: [
        {
          label: 'Account Lead',
          value: customerDetails.selectedAccount?.account_lead ?? '-',
        },
        {
          label: 'Account Title',
          value: customerDetails.selectedAccount?.account_title ?? '-',
        },
        {
          label: 'Source Branch',
          value: customerDetails.selectedAccount?.source_branch ?? '-',
        },
        {
          label: 'Opening Branch',
          value: customerDetails.selectedAccount?.opening_branch ?? '-',
        },
        {
          label: 'Account Type',
          value: getAccountTypeBasedOnIndex(customerDetails.selectedAccount?.account_type ?? '-'),
        },
        {
          label: 'Account Number',
          value: customerDetails.selectedAccount?.show_account_number ?? '-',
        },
        { label: 'Account ID', value: customerDetails.selectedAccount?.account_id ?? '-' },
        // {
        //   label: 'Account Status',
        //   value: getAccountStatus(customerDetails.selectedAccount?.accStsCode ?? ''),
        // },
        {
          label: 'IFSC Code',
          value: customerDetails.selectedAccount?.ifsc_code ?? '-',
          'UCIC ID': customerDetails.ucic ?? '-',
        },
      ],
      intermediaryCode: {},
      proposerDetails: [
        {
          label: 'First Name',
          value:
            customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp![0]
              .frsNm ?? '-',
        },
        {
          label: 'Middle Name',
          value:
            customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp![0]
              .mdlNm ?? '-',
        },
        {
          label: 'Last Name',
          value:
            customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp![0]
              .lstNm ?? '-',
        },
        {
          label: 'DOB',
          value: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.dob ?? '-',
        },
        {
          label: 'Gender',
          value: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.gndr ?? '-',
        },
        {
          label: 'Mobile Number',
          value:
            customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp![0]
              .mblNb ?? '-',
        },
        {
          label: 'Email ID',
          value:
            customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp![0]
              .email ?? '-',
        },
        {
          label: 'Address 1',
          value: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn1 ?? '-',
        },
        {
          label: 'Address 2',
          value: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn2 ?? '-',
        },
        {
          label: 'Address 3',
          value: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntAddrLn3 ?? '-',
        },
        {
          label: 'Pincode',
          value: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntPncd ?? '-',
        },
        // { label: 'City', value: cityName ?? '-' },
        {
          label: 'Nationality',
          value:
            getCountryName(
              customerDetails.proposerDetailsFromCBS?.CustmrFlds?.CustmrFldsRp?.crntCntry?.toString() ??
                '-',
            ) ?? '-',
          // countryMapping[customerProposerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.crntCntry] ??
          // '-',
        },
        // {
        //   label: 'Marital Status',
        //   value: getMaritalStatus(
        //     customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.mrtlSts ?? '-',
        //   ),
        // },
        { label: 'Relationship with insured', value: customerDetails2.relationship },
      ],
      insuredDetails: [
        { label: 'Salutation', value: customerDetails2.lifeAssuredDetailsTyped?.title.name },
        { label: 'First Name', value: customerDetails2.lifeAssuredDetailsTyped?.firstName ?? '-' },
        {
          label: 'Middle Name',
          value: customerDetails2.lifeAssuredDetailsTyped?.middleName ?? '-',
        },
        { label: 'Last Name', value: customerDetails2.lifeAssuredDetailsTyped?.lastName ?? '-' },
        { label: 'DOB', value: customerDetails2.lifeAssuredDetailsTyped?.dob },
        { label: 'Gender', value: customerDetails2.lifeAssuredDetailsTyped?.gender.name },
        { label: 'Mobile Number', value: customerDetails2.lifeAssuredDetailsTyped?.mobile },
        { label: 'Email ID', value: customerDetails2.lifeAssuredDetailsTyped?.email },
        { label: 'Address 1', value: customerDetails2.lifeAssuredDetailsTyped?.address1 },
        { label: 'Address 2', value: customerDetails2.lifeAssuredDetailsTyped?.address2 },
        { label: 'Pincode', value: customerDetails2.lifeAssuredDetailsTyped?.pincode },
        // Nationality: 'Indian'},
        { label: 'Landmark', value: customerDetails2.lifeAssuredDetailsTyped?.landmark ?? '-' },
        { label: 'Marital Status', value: customerDetails2.lifeAssuredDetailsTyped?.marital.name },
        { label: 'Relationship with insured', value: customerDetails2.relationship },
        { label: 'Is Life Insured a smoker ?', value: customerDetails2.lifeAssuredSmokerStatus },
      ],
      // nomineeDetails: {},
      suitabilityAnalysis: [
        { label: 'Customer Age', value: lifeQuotationDetails.suitabilityData.age_band },
        // {
        //   label: 'Annual Income',
        //   value: lifeQuotationDetails.suitabilityData.annual_disposable_income,
        // },
        {
          label: 'Which of the following best describes your risk taking behavior ?',
          value: lifeQuotationDetails.suitabilityData.risk,
        },
        { label: 'Insurance Purpose', value: lifeQuotationDetails.suitabilityData.need },
        { label: 'Life Stage', value: lifeQuotationDetails.suitabilityData.life_stage },
        { label: 'Goal Horizon', value: lifeQuotationDetails.suitabilityData.goal_horizon },
        {
          label: 'Payment Preference',
          value: lifeQuotationDetails.suitabilityData.payment_preference,
        },
      ],
      recommendedProducts: lifeQuotationDetails.finalRecommendedProducts?.map((plan) => plan.name),
      productDetails: null,
      addRiders:
        filterData.filter && filterData.filter['additional_riders']
          ? filterData.filter['additional_riders']
          : [],
    }
  }

  const { data, isLoading } = useGetConsentDataQuery(
    { id: consent_id?.toString() ?? '' },
    { pollingInterval: pollingInterval, skip: skip },
  )

  useEffect(() => {
    if (data?.status.toLowerCase() === 'approved') {
      createLead()
      setButton(false)
      dispatch(updateConsentStatus('APPROVED'))
      setPollingInterval(0)
      // onOpen()
    }
  }, [data])

  useEffect(() => {
    if (consent_status === 'APPROVED') {
      onOpen()
    }
  }, [consent_status])

  useEffect(() => {
    return () => {
      setPollingInterval(0)
    }
  }, [])

  useEffect(() => {
    return () => {
      setPollingInterval(0)
      if (
        lifeQuotationDetails.consent_status === 'APPROVED' &&
        location.pathname !== '/my-leads' &&
        location.pathname !== '/sales/motor' &&
        location.pathname !== '/sales/health' &&
        location.pathname !== '/sales/life' &&
        location.pathname !== '/sales-summary/motor'
      ) {
        navigate('/')
      }
    }
  }, [consent_status])

  return (
    <>
      <Header />
      <Box bg='#F5F6FA' pt='12'>
        <Box height={'100vh'} overflow={'auto'} maxW='1000px' px='4' mx='auto'>
          <Flex justifyContent='space-between' alignItems='center'>
            <Text>BI and Payment Consent Pending</Text>
            <Box>
              <Button
                // leftIcon={<MdBuild />}
                colorScheme='secondary'
                fontSize='sm'
                marginX='2'
                variant='outline'
                onClick={() => {
                  copyToClipboard(leadDetails.lead_uid ?? '')
                  // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
                  // ToastService.showSuccessTaost({
                  //   title: 'Success',
                  //   message: 'Lead Saved Successfully',
                  // })
                }}
              >
                {leadDetails.lead_uid ?? '-'}
              </Button>
            </Box>
          </Flex>
          <Box textAlign={'center'} mt='4' bg='white' rounded={'lg'} py='8'>
            <Text>
              BI and Payment Consent forms have been sent to{' '}
              {
                customerDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                  ?.IndCustDtlsRp![0]?.mblNb
              }
            </Text>
            {/* <Text mt='4' color={'#A0A0A0'}>Didn’t receive the BI authorisation link?</Text>
                        <Text mt='4'>
                            <span style={{ color: '#1C4589' }}>Resend authorisation link</span> in 30 Sec
                        </Text> */}
            <Flex w='full' justifyContent={'center'}>
              <Flex
                gap='2'
                px='1.5'
                py='1.5'
                rounded={'md'}
                bg='#DCE7F8'
                mt='8'
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Icon as={AiOutlineExclamationCircle} color={'primary.500'} />
                <Text>
                  You can proceed to next step after BI Authorisation and Health Declaration have
                  been submitted by{' '}
                  {`${
                    customerDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails
                      ?.IndCustDtlsRp![0]?.frsNm ?? ''
                  } ${
                    customerDetails2.relationship?.toLowerCase() === 'self'
                      ? customerDetails2.lifeAssuredDetailsTyped?.middleName
                      : customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                          ?.IndCustDtlsRp![0].mdlNm
                  } ${
                    customerDetails2.relationship?.toLowerCase() === 'self'
                      ? customerDetails2.lifeAssuredDetailsTyped?.lastName
                      : customerDetails.proposerDetailsFromCBS?.IndividualCustomerDetails
                          ?.IndCustDtlsRp![0].lstNm
                  }`}
                  .
                </Text>
              </Flex>
            </Flex>
            {lifeQuotationDetails.application_nummber && (
              <Flex
                gap='2'
                px='1.5'
                py='1.5'
                mt='8'
                justifyContent={'center'}
                alignItems={'center'}
              >
                Application Number:- {lifeQuotationDetails.application_nummber}
              </Flex>
            )}
            <Flex gap='4' justifyContent={'center'}>
              {consent_status !== 'APPROVED' && (
                <Button
                  colorScheme='primary'
                  mt='8'
                  marginX='4'
                  isDisabled={!resendOtp}
                  isLoading={generatingConsent}
                  onClick={async () => {
                    await createConsent()
                    ToastService.showSuccessTaost({
                      title: 'Success',
                      message: 'Consent has been re-send successfully',
                    })
                    startTimer()
                    setResendOtp(false)
                  }}
                >
                  {resendOtp ? 'Resend Consent' : `Resend Consent in ${timeLeft}`}
                </Button>
              )}
              <Button
                mt='8'
                variant='solid'
                isDisabled={button || lifeQuotationDetails.application_nummber.length > 0}
                isLoading={isBiLoading}
                onClick={async () => {
                  biQuoteGeneration()
                  // if (redirectionURL) {
                  //   await createLead(true)
                  //   window.open(redirectionURL, '_blank')
                  // }
                }}
              >
                PROCEED
              </Button>
            </Flex>
          </Box>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Consent Submitted</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction='column' alignItems='center' justifyContent='center'>
                <Image src={success} w='20' h='auto' />
                <Text my='4' fontWeight='semibold'>
                  Please click on proceed to continue
                </Text>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}
