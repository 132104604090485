const breakpoints = {
  xs: '300px',
  sm: '440px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  '2xl': '1400px',
  '3xl': '1600px',
  '4xl': '1900px',
}

export default breakpoints
