import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from 'Constants'
import { method } from 'lodash'
import {
  GetAddonsParam,
  GetPincodeListingParams,
  QuotationGetRequestParam,
  QuotationRequest,
  GetInsurerListingParams,
  GetMiscellaneousListingParams,
  GetInsuranceListingParams,
  IHealthSalesBankParams,
} from 'Models/RequestModels/Health'
import {
  GetAddonRidersListing,
  GetPincodeListingResponse,
  GetQuestionsListingResponse,
  QuotationGetResponse,
  QuotationPostResponse,
  GetInsurerListingResponse,
  GetMiscellaneousListing,
  GetInsuranceListingResponse,
  GetGenericBenefitsListingResponse,
  ProposalPostResponse,
  ProposalRequest,
  IHealthSalesReportBank,
  GetCompareFieldsResponse,
  CompareListingRequest,
  CompareListingResponse,
  HealthConsentFormPaymentLinkRequest,
  HealthConsentFormPaymentLinkResponse,
  HealthConsentFormRequest,
  ConsentCheckResponse,
  GetPaymentCheckResponse,
  DMSDataPushRequest,
  DMSDataPushResponse,
} from 'Models/ResponseModels/Health'
import { baseUrlHealthQuery } from 'Services/baseQuery'

// const getHealthHeaders = () => {
//   const tempHeaders = { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' }
//   // if (token) {
//   return {
//     ...tempHeaders,
//     Authorization: 'Token f22ea5d8f5615b76b901026a8c051f0758d1be81',
//   }
//   // }
//   // return tempHeaders
// }

export const healthApi = createApi({
  reducerPath: 'healthApi',
  baseQuery: baseUrlHealthQuery,
  endpoints: (builder) => ({
    getSubRequest: builder.mutation<QuotationPostResponse, QuotationRequest>({
      query: (body: QuotationRequest) => ({
        url: API_ENDPOINTS.HEALTH_QUOTATION,
        method: 'POST',
        body: body,
        // headers: getHealthHeaders(),
      }),
    }),
    getPollingQuotations: builder.query<QuotationGetResponse, QuotationGetRequestParam>({
      query: ({ sub_request_id }) => ({
        url: API_ENDPOINTS.HEALTH_QUOTATION,
        method: 'GET',
        params: {
          sub_request_id,
        },
        // headers: getHealthHeaders(),
      }),
    }),
    getAddons: builder.query<GetAddonRidersListing[], GetAddonsParam>({
      query: ({ insurance_id, plan_type, sum_insured, age, duration, unique_identifier }) => ({
        url: API_ENDPOINTS.HEATH_ADDONS,
        method: 'GET',
        params: {
          insurance_id,
          plan_type,
          sum_insured,
          age,
          duration,
          unique_identifier,
        },
        // headers: getHealthHeaders(),
      }),
    }),
    getPincodeListing: builder.query<GetPincodeListingResponse, GetPincodeListingParams>({
      query: ({ insurance_id, pincode }) => ({
        url: API_ENDPOINTS.HEALTH_PINCODE,
        method: 'GET',
        params: {
          insurance_id,
          pincode,
        },
        // headers: getHealthHeaders(),
      }),
    }),
    getGenericSumInsured: builder.query<string[], void>({
      query: () => ({
        url: API_ENDPOINTS.HEALTH_SUMINSURED_GENRIC,
        method: 'GET',
        // headers: getHealthHeaders(),
      }),
    }),
    getInsurerListingPlans: builder.query<GetInsurerListingResponse[], GetInsurerListingParams>({
      query: ({ is_previous_insurer }) => ({
        url: API_ENDPOINTS.HEALTH_INSURER_LISTING,
        method: 'GET',
        params: {
          is_previous_insurer,
        },
        // headers: getHealthHeaders(),
      }),
    }),
    getQuestionListing: builder.query<GetQuestionsListingResponse[], { insurance_id: string }>({
      query: ({ insurance_id }) => ({
        url: API_ENDPOINTS.HEALTH_QUES,
        method: 'GET',
        params: {
          insurance_id,
        },
        // headers: getHealthHeaders(),
      }),
    }),
    getMiscellaneousListing: builder.query<GetMiscellaneousListing, GetMiscellaneousListingParams>({
      query: (params) => ({
        url: API_ENDPOINTS.HEALTH_LIST_MISCELLANEOUS,
        method: 'GET',
        params: params,
        // headers: getHealthHeaders(),
      }),
    }),
    getInsuranceListing: builder.query<GetInsuranceListingResponse[], GetInsuranceListingParams>({
      query: (params) => ({
        url: API_ENDPOINTS.HEALTH_INSURANCE_LISTING,
        method: 'GET',
        params: params,
        // headers: getHealthHeaders(),
      }),
    }),
    getGenericBenefitsListing: builder.query<GetGenericBenefitsListingResponse[], void>({
      query: (params) => ({
        url: API_ENDPOINTS.HEALTH_BENEFITS,
        method: 'GET',
        params: params,
        // headers: getHealthHeaders(),
      }),
    }),
    createProposal: builder.mutation<ProposalPostResponse, ProposalRequest>({
      query: (body: ProposalRequest) => ({
        url: API_ENDPOINTS.HEALTH_PROPOSAL,
        method: 'POST',
        body: body,
        // headers: getHealthHeaders(),
      }),
    }),
    healthSalesBank: builder.query<IHealthSalesReportBank, IHealthSalesBankParams>({
      query: (queryParams) => ({
        url: API_ENDPOINTS.HEALTH_SALES_BANK,
        method: 'GET',
        params: queryParams,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    getCompareFields: builder.query<GetCompareFieldsResponse[], void>({
      query: (params) => ({
        url: API_ENDPOINTS.HEALTH_COMPARE_FIELDS,
        method: 'GET',
        params: params,
      }),
    }),

    compareListing: builder.mutation<CompareListingResponse[], CompareListingRequest>({
      query: (body: CompareListingRequest) => ({
        url: API_ENDPOINTS.HEALTH_COMPARE_LISTING,
        method: 'POST',
        body: body,
      }),
    }),
    healthConsentFormLink: builder.mutation<string, HealthConsentFormRequest>({
      query: (body: HealthConsentFormRequest) => ({
        url: API_ENDPOINTS.HEALTH_CONSENT,
        method: 'POST',
        body: body,
        // headers: getHealthHeaders(),
      }),
    }),
    healthConsentSubmitFormPaymentLink: builder.mutation<
      HealthConsentFormPaymentLinkResponse[],
      HealthConsentFormPaymentLinkRequest
    >({
      query: (body: HealthConsentFormPaymentLinkRequest) => ({
        url: API_ENDPOINTS.HEALTH_CONSENT_SUBMIT_FORM,
        method: 'POST',
        body: body,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    DMSDataPush: builder.mutation<DMSDataPushResponse, DMSDataPushRequest>({
      query: (body: DMSDataPushRequest) => ({
        url: API_ENDPOINTS.HEALTH_DMS_DATA_PUSH,
        method: 'POST',
        body: body,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    getConsentData: builder.query<HealthConsentFormRequest, string>({
      query: (consent_identifier) => ({
        url: API_ENDPOINTS.HEALTH_CONSENT,
        method: 'GET',
        params: {
          consent_identifier: consent_identifier,
        },
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    consentCheck: builder.query<
      ConsentCheckResponse,
      { consent_identifier: string; consent_type: string }
    >({
      query: ({ consent_identifier, consent_type }) => ({
        url: API_ENDPOINTS.CONSENT_CHECK,
        method: 'GET',
        params: {
          consent_identifier: consent_identifier,
          consent_type: consent_type,
        },
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    paymentCheck: builder.query<
      GetPaymentCheckResponse,
      {
        unique_identifier: string
      }
    >({
      query: (params) => ({
        url: API_ENDPOINTS.HEALTH_PAYMENT_CHECK,
        method: 'GET',
        params: params,
      }),
    }),

    bmiCheck: builder.query<
      any,
      {
        height: number
        weight: number
        age: number
        insurance_name: string
      }
    >({
      query: (params) => ({
        url: API_ENDPOINTS.BMI_CHECK,
        method: 'GET',
        params: params,
      }),
    }),
  }),
})

export const {
  useGetSubRequestMutation,
  useGetPollingQuotationsQuery,
  useLazyGetAddonsQuery,
  useGetPincodeListingQuery,
  useGetGenericSumInsuredQuery,
  useGetInsurerListingPlansQuery,
  useGetQuestionListingQuery,
  useGetMiscellaneousListingQuery,
  useLazyGetPincodeListingQuery,
  useGetInsuranceListingQuery,
  useGetGenericBenefitsListingQuery,
  useCreateProposalMutation,
  useHealthSalesBankQuery,
  useLazyGetCompareFieldsQuery,
  useCompareListingMutation,
  useHealthConsentFormLinkMutation,
  useHealthConsentSubmitFormPaymentLinkMutation,
  useLazyGetConsentDataQuery,
  useConsentCheckQuery,
  useLazyConsentCheckQuery,
  usePaymentCheckQuery,
  useLazyPaymentCheckQuery,
  useLazyBmiCheckQuery,
  useDMSDataPushMutation,
} = healthApi
