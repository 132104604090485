import { ComponentStyleConfig } from '@chakra-ui/react'
import { sizes } from './sizes'
import { variants } from './variants'

const baseStyle = {
  borderRadius: 'lg',
  borderColor: 'transparent',
  boxShadow: 'none',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  _disabled: {
    cursor: 'not-allowed',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
  _focus: {
    outline: 0,
  },
}

const defaultProps = {
  variant: 'outline',
  size: 'md',
}

const Button: ComponentStyleConfig = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}

export default Button
