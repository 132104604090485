// import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { TAccount, ICustomerDetails } from 'Models/ResponseModels/Equitas'

// type TCbsFields =
//   | 'customerFirstName'
//   | 'customerMiddleName'
//   | 'customerLastName'
//   | 'customerEmail'
//   | 'customerMobile'
//   | 'customerDob'
//   | 'customerAddress1'
//   | 'customerAddress2'
//   | 'customerPincode'
//   | 'customerPermanentPincode'
// type TPaymentMode = 'PG' | 'DirectDebit'

// interface IState {
//   accounts?: TAccount[]
//   ucic?: string
//   proposerDetailsFromCBS?: ICustomerDetails
//   customerAccountStatus: 'not-fetched' | 'loading' | 'success' | 'error'
//   customerDetailsStatus: 'not-fetched' | 'loading' | 'success' | 'error'
//   customerAge: number
//   selectedAccountId: number
//   selectedAccountPaymentMode?: TPaymentMode
//   selectedAccount?: TAccount
//   ckyc_number?: string
//   customer_mobile_number?: string
//   fieldsFetchedFromCbsMapping: {
//     customerFirstName: boolean
//     customerMiddleName: boolean
//     customerLastName: boolean
//     customerEmail: boolean
//     customerMobile: boolean
//     customerDob: boolean
//     customerAddress1: boolean
//     customerAddress2: boolean
//     customerPincode: boolean
//     customerPermanentPincode: boolean
//   }
// }

// const initialState: IState = {
//   accounts: [],
//   customerAccountStatus: 'not-fetched',
//   customerDetailsStatus: 'not-fetched',
//   customerAge: 0,
//   selectedAccountId: -1,
//   fieldsFetchedFromCbsMapping: {
//     customerFirstName: false,
//     customerMiddleName: false,
//     customerLastName: false,
//     customerEmail: false,
//     customerMobile: false,
//     customerDob: false,
//     customerAddress1: false,
//     customerAddress2: false,
//     customerPincode: false,
//     customerPermanentPincode: false,
//   },
// }

// const customerDetailsSlice = createSlice({
//   name: 'customerDetailsSlice',
//   initialState: initialState,
//   reducers: {
//     updateCustomerAccounts: (state, action: PayloadAction<TAccount[]>) => {
//       const accounts = action.payload
//       state.accounts = accounts
//     },
//     updateLifeCustomerLastName: (state, action: PayloadAction<string>) => {
//       state.proposerDetailsFromCBS!.IndividualCustomerDetails.IndCustDtlsRp![0].lstNm =
//         action.payload
//     },
//     updateCkycNumber: (state, action: PayloadAction<string>) => {
//       const ckyc_number = action.payload
//       state.ckyc_number = ckyc_number
//     },
//     updateCustomerAge: (state, action: PayloadAction<number>) => {
//       const age = action.payload
//       state.customerAge = age
//     },
//     updateCustomerMobileNumber: (state, action: PayloadAction<string>) => {
//       const mobile_number = action.payload
//       state.customer_mobile_number = mobile_number
//     },
//     updateUcicId: (state, action: PayloadAction<string>) => {
//       state.ucic = action.payload
//     },
//     setCustomerAccountsStatus: (
//       state,
//       action: PayloadAction<'not-fetched' | 'loading' | 'success' | 'error'>,
//     ) => {
//       state.customerAccountStatus = action.payload
//     },
//     setSelectedAccountId: (state, action: PayloadAction<number>) => {
//       state.selectedAccountId = action.payload
//     },
//     setSelectedAccount: (state, action: PayloadAction<TAccount>) => {
//       state.selectedAccount = action.payload
//     },
//     setSelectedAccountPaymentMode: (state, action: PayloadAction<TPaymentMode>) => {
//       state.selectedAccountPaymentMode = action.payload
//     },
//     setCustomerDetailsStatus: (
//       state,
//       action: PayloadAction<'not-fetched' | 'loading' | 'success' | 'error'>,
//     ) => {
//       state.customerDetailsStatus = action.payload
//     },
//     updateProposalDetailsFromCBS: (state, action: PayloadAction<ICustomerDetails>) => {
//       state.proposerDetailsFromCBS = action.payload
//     },
//     updateFieldsFetchedFromCbsMapping: (state, action: PayloadAction<TCbsFields>) => {
//       state.fieldsFetchedFromCbsMapping[action.payload] = true
//     },
//     resetCustomerDetails: () => initialState,
//   },
// })

// const { reducer } = customerDetailsSlice

// const {
//   updateCustomerAccounts,
//   updateCkycNumber,
//   updateUcicId,
//   setCustomerAccountsStatus,
//   setSelectedAccountId,
//   setSelectedAccount,
//   resetCustomerDetails,
//   updateProposalDetailsFromCBS,
//   updateFieldsFetchedFromCbsMapping,
//   setCustomerDetailsStatus,
//   setSelectedAccountPaymentMode,
//   updateCustomerMobileNumber,
//   updateCustomerAge,
//   updateLifeCustomerLastName,
// } = customerDetailsSlice.actions

// export {
//   reducer as customerDetailsReducer,
//   updateCustomerAccounts,
//   updateCkycNumber,
//   setCustomerAccountsStatus,
//   setSelectedAccountId,
//   setSelectedAccount,
//   resetCustomerDetails,
//   updateProposalDetailsFromCBS,
//   updateUcicId,
//   updateFieldsFetchedFromCbsMapping,
//   setCustomerDetailsStatus,
//   setSelectedAccountPaymentMode,
//   updateCustomerMobileNumber,
//   updateCustomerAge,
//   updateLifeCustomerLastName,
// }

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TAccount, ICustomerDetails } from 'Models/ResponseModels/Equitas'

type TCbsFields =
  | 'customerFirstName'
  | 'customerMiddleName'
  | 'customerLastName'
  | 'customerEmail'
  | 'customerMobile'
  | 'customerDob'
  | 'customerAddress1'
  | 'customerAddress2'
  | 'customerPincode'
  | 'customerPermanentPincode'
type TPaymentMode = 'PG' | 'DirectDebit'

interface Account {
  account_id: number
  account_lead: string
  account_title: string
  account_type: string
  ifsc_code: string
  opening_branch: string
  show_account_number: string
  source_branch: string
  status: string
}

interface IFullCustomerDetails {
  aadhar: string
  accounts: Account[]
  ckyc_number: string
  dob: string
  driving_licence: string | null
  email: string
  first_name: string
  gender: string | null
  home_branch: string | null
  id: number
  last_name: string | null
  middle_name: string | null
  modified_by: string | null
  pan: string
  passport: string | null
  phone: string
  status: number
  ucic: string
  unique_id: string
  voter_id: string | null
}
interface IOtherCustomerDetails {
  crnSt: string
  crntAddrLn1: string
  crntCntry: string
  crntCty: string
  crntDstrct: string | null
  crntPncd: string
  dcmtId: string | null
  dcmtVrfDt: string | null
  dob: string
  fathrNm: string
  gndr: string
  grossAnnlIncm: string
  mrtlSts: string
  occptn: string
  panNb: string
  prmntAddrLn1: string
  prmntAddrLn2: string | null
  prmntAddrLn3: string | null
  prmntCntry: string
  prmntCty: string
  prmntDstrct: string | null
  prmntMblNb: string
  prmntPncd: string
  prmntSt: string
  ucic: string
}

interface IState {
  accounts?: TAccount[]
  ucic?: string
  proposerDetailsFromCBS?: ICustomerDetails
  customerAccountStatus: 'not-fetched' | 'loading' | 'success' | 'error'
  customerDetailsStatus: 'not-fetched' | 'loading' | 'success' | 'error'
  customerAge: number
  selectedAccountId: number
  selectedAccountPaymentMode?: TPaymentMode
  selectedAccount?: TAccount
  ckyc_number?: string
  customer_mobile_number?: string
  fieldsFetchedFromCbsMapping: {
    customerFirstName: boolean
    customerMiddleName: boolean
    customerLastName: boolean
    customerEmail: boolean
    customerMobile: boolean
    customerDob: boolean
    customerAddress1: boolean
    customerAddress2: boolean
    customerPincode: boolean
    customerPermanentPincode: boolean
  }
  fullCustomerDetails: IFullCustomerDetails
  otherCustomerDetails: IOtherCustomerDetails
}

const initialState: IState = {
  accounts: [],
  customerAccountStatus: 'not-fetched',
  customerDetailsStatus: 'not-fetched',
  customerAge: 0,
  selectedAccountId: -1,
  fieldsFetchedFromCbsMapping: {
    customerFirstName: false,
    customerMiddleName: false,
    customerLastName: false,
    customerEmail: false,
    customerMobile: false,
    customerDob: false,
    customerAddress1: false,
    customerAddress2: false,
    customerPincode: false,
    customerPermanentPincode: false,
  },
  selectedAccount: {},
  customer_mobile_number: '',
  fullCustomerDetails: {
    aadhar: '',
    accounts: [],
    ckyc_number: '',
    dob: '',
    driving_licence: '' || null,
    email: '',
    first_name: '',
    gender: '' || null,
    home_branch: '' || null,
    id: 0,
    last_name: '' || null,
    middle_name: '' || null,
    modified_by: '' || null,
    pan: '',
    passport: '' || null,
    phone: '',
    status: 0,
    ucic: '',
    unique_id: '',
    voter_id: '' || null,
  },
  otherCustomerDetails: {
    crnSt: '',
    crntAddrLn1: '',
    crntCntry: '',
    crntCty: '',
    crntDstrct: '' || null,
    crntPncd: '',
    dcmtId: '' || null,
    dcmtVrfDt: '' || null,
    dob: '',
    fathrNm: '',
    gndr: '',
    grossAnnlIncm: '',
    mrtlSts: '',
    occptn: '',
    panNb: '',
    prmntAddrLn1: '',
    prmntAddrLn2: '' || null,
    prmntAddrLn3: '' || null,
    prmntCntry: '',
    prmntCty: '',
    prmntDstrct: '' || null,
    prmntMblNb: '',
    prmntPncd: '',
    prmntSt: '',
    ucic: '',
  },
}

const customerDetailsSlice = createSlice({
  name: 'customerDetailsSlice',
  initialState: initialState,
  reducers: {
    setCustomerDetails: (state, action) => {
      state.fullCustomerDetails = action.payload.u
      state.otherCustomerDetails = action.payload.v

      // console.log('u from SLICE fullCustomerDetails: ', state.fullCustomerDetails)
      // console.log('v from SLICE otherCustomerDetails: ', state.otherCustomerDetails)
    },
    updateCustomerAccounts: (state, action: PayloadAction<TAccount[]>) => {
      const accounts = action.payload
      state.accounts = accounts
    },
    updateLifeCustomerLastName: (state, action: PayloadAction<string>) => {
      state.proposerDetailsFromCBS!.IndividualCustomerDetails.IndCustDtlsRp![0].lstNm =
        action.payload
    },
    updateCkycNumber: (state, action: PayloadAction<string>) => {
      const ckyc_number = action.payload
      state.ckyc_number = ckyc_number
    },
    updateCustomerAge: (state, action: PayloadAction<number>) => {
      const age = action.payload
      state.customerAge = age
    },
    updateCustomerMobileNumber: (state, action: PayloadAction<string>) => {
      const mobile_number = action.payload
      state.customer_mobile_number = mobile_number
    },
    updateUcicId: (state, action: PayloadAction<string>) => {
      state.ucic = action.payload
    },
    setCustomerAccountsStatus: (
      state,
      action: PayloadAction<'not-fetched' | 'loading' | 'success' | 'error'>,
    ) => {
      state.customerAccountStatus = action.payload
    },
    setSelectedAccountId: (state, action: PayloadAction<number>) => {
      state.selectedAccountId = action.payload
    },
    setSelectedAccount: (state, action: PayloadAction<TAccount>) => {
      state.selectedAccount = action.payload
    },
    setSelectedAccountPaymentMode: (state, action: PayloadAction<TPaymentMode>) => {
      state.selectedAccountPaymentMode = action.payload
    },
    setCustomerDetailsStatus: (
      state,
      action: PayloadAction<'not-fetched' | 'loading' | 'success' | 'error'>,
    ) => {
      state.customerDetailsStatus = action.payload
    },
    updateProposalDetailsFromCBS: (state, action: PayloadAction<ICustomerDetails>) => {
      state.proposerDetailsFromCBS = action.payload
    },
    updateFieldsFetchedFromCbsMapping: (state, action: PayloadAction<TCbsFields>) => {
      state.fieldsFetchedFromCbsMapping[action.payload] = true
    },
    resetCustomerDetails: () => initialState,
  },
})

const { reducer } = customerDetailsSlice

const {
  updateCustomerAccounts,
  updateCkycNumber,
  updateUcicId,
  setCustomerAccountsStatus,
  setSelectedAccountId,
  setSelectedAccount,
  resetCustomerDetails,
  updateProposalDetailsFromCBS,
  updateFieldsFetchedFromCbsMapping,
  setCustomerDetailsStatus,
  setSelectedAccountPaymentMode,
  updateCustomerMobileNumber,
  updateCustomerAge,
  updateLifeCustomerLastName,
  setCustomerDetails,
} = customerDetailsSlice.actions

export {
  reducer as customerDetailsReducer,
  updateCustomerAccounts,
  updateCkycNumber,
  setCustomerAccountsStatus,
  setSelectedAccountId,
  setSelectedAccount,
  resetCustomerDetails,
  updateProposalDetailsFromCBS,
  updateUcicId,
  updateFieldsFetchedFromCbsMapping,
  setCustomerDetailsStatus,
  setSelectedAccountPaymentMode,
  updateCustomerMobileNumber,
  updateCustomerAge,
  updateLifeCustomerLastName,
  setCustomerDetails,
}
