import { Box, BoxProps, Flex, FormLabel, Text } from '@chakra-ui/react'
import React from 'react'

interface IElementWithLabelProps extends BoxProps {
  children: React.ReactNode
  label: string
  required?: boolean
  isOptional?: boolean
}

const ElementWithLabel = (props: IElementWithLabelProps) => {
  const { color, fontWeight, children, label, required, isOptional, ...rest } = props
  return (
    <Box
      height='full'
      display='flex'
      flexDirection='column'
      marginTop={'auto'}
      my={'0.3rem'}
      position={'relative'}
      {...rest}
    >
      {label ? (
        <FormLabel
          fontWeight={fontWeight ? fontWeight : 'bold'}
          fontSize='md'
          color={color ? color : '#666666'}
        >
          <Flex alignItems={'center'}>
            <p>
              {label}
              {isOptional ? (
                <small
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    marginLeft: 2,
                  }}
                >
                  (Optional)
                </small>
              ) : null}
              {required ? <span style={{ color: 'red' }}>{' *'}</span> : ''}
            </p>
          </Flex>
        </FormLabel>
      ) : null}

      {children}
    </Box>
  )
}

export { ElementWithLabel }
