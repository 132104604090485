import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'
import { IQuotationRequest } from 'Models/RequestModels'
import {
  IAdditionalCover,
  ISaveLeadResponse,
  ITransformedAddons,
  Quotations,
} from 'Models/ResponseModels'
import { useEffect, useState } from 'react'
import {
  usePostPollingQuotationMutation,
  useGetPollingQuotationQuery,
  useGetAdditionalCoversQuery,
  useGetAddonsQuery,
  useCheckForCpaMutation,
} from 'Services/API/motor.api'
import { useSaveLeadsMutation } from 'Services/API/leads.api'
import { QuoteItem } from './QuoteItem'
import { SideBar } from './SideBar'
import { SkeletonQuoteItem } from './SkeletonQuoteItem'
import { TopDetailBar } from './TopDetailBar'
import { Compare } from './Compare'
import { PODDialog, MobileAddonsDialog, CPADialog, HDFCDialog, ZeroDepDialog } from './DialogBoxes'
import { ToggleButton } from 'Core/ToggleButton'

import {
  useAppDispatch,
  useAppSelector,
  useVehicleType,
  useWindowDimensions,
  useDeviceType,
} from 'app/hooks'
import {
  updateAddons,
  updateFuelType,
  updateIDV,
  updateInspectionRequired,
  updateIsPodSelected,
  updateLiabilityCovers,
  updateOwnDamageCovers,
  updateOwnDiscountCovers,
  updateOwnedBy,
  updatePolicyType,
} from 'features/Motor'
import { Header } from 'Components'
import { allInsurers } from 'Constants'
import { useNavigate } from 'react-router-dom'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { decryptData } from 'Services/general'

const Quotation = () => {
  const [addOns, setAddons] = useState<ITransformedAddons[]>([])
  const [ownDamageCovers, setOwnDamageCovers] = useState<IAdditionalCover[]>([])
  const [liabilityCovers, setLiabilityCovers] = useState<IAdditionalCover[]>([])
  const [discountCovers, setDiscountCovers] = useState<IAdditionalCover[]>([])
  const [quotationsListing, setQuotationsListing] = useState<Quotations[]>([])
  const [reverseQuotationsListing, setReverseQuotationsListing] = useState<Quotations[]>([])
  const [pollingInterval, setPollingInterval] = useState(2000)
  const [skip, setSkip] = useState(true)
  const [selectedAddons, setSelectedAddons] = useState(new Map())
  const [selectedCovers, setSelectedCovers] = useState(new Map())
  const [selectedQuotes, setSelectedQuotes] = useState([])
  const [addOnsToDisplay, setAddOnsToDisplay] = useState<any[]>([])
  const [coversToDisplay, setCoversToDisplay] = useState<any[]>([])

  const [count, setCount] = useState(4)
  const [subRequestId, setSubRequestId] = useState('')
  const [retryable, setRetryable] = useState(true)
  const [requestId, setRequestId] = useState('')
  const [idv, setIdv] = useState(0)
  const [idvOption, setIdvOption] = useState('Recommended')
  const [bodyIdv, setBodyIdv] = useState(0)
  const [chassisIdv, setChassisIdv] = useState(0)
  const [bodyIdvOption, setBodyIdvOption] = useState('Recommended')
  const [chassisIdvOption, setChassisIdvOption] = useState('Recommended')
  const [maxIdv, setMaxIdv] = useState(0)
  const [minIdv, setMinIdv] = useState(0)
  const [isBreakIn, setIsBreakIn] = useState(false)

  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const homePageDetails = useAppSelector((state) => state.homePage)
  const { type: vehicleType, param: vehicleParam, name: vehicleName } = useVehicleType()
  const dispatch = useAppDispatch()
  const { width: pageWidth } = useWindowDimensions()
  const { isDesktop, isTablet, isMobile } = useDeviceType()
  const navigate = useNavigate()

  const ascending = quotationDetails.isAscending
  const isComprehensive = quotationDetails.isComprehensive
  const isIndividual = quotationDetails.isIndividual

  const { isOpen: isSidebarOpen, onOpen: onSidebarOpen, onClose: onSidebarClose } = useDisclosure()
  const { isOpen: isCompareOpen, onOpen: onCompareOpen, onClose: onCompareClose } = useDisclosure()
  const {
    isOpen: isPODDialogOpen,
    onOpen: onPODDialogOpen,
    onClose: onPODDialogClose,
  } = useDisclosure()
  const {
    isOpen: isZeroDepDialogOpen,
    onOpen: onZeroDepDialogOpen,
    onClose: onZeroDepDialogClose,
  } = useDisclosure()
  const {
    isOpen: isCPADialogOpen,
    onOpen: onCPADialogOpen,
    onClose: onCPADialogClose,
  } = useDisclosure()
  const {
    isOpen: isHDFCDialogOpen,
    onOpen: onHDFCDialogOpen,
    onClose: onHDFCDialogClose,
  } = useDisclosure()

  useEffect(() => {
    if (selectedCovers.get('pod') === 0) {
      dispatch(updateIsPodSelected(true))
    } else if (selectedCovers.get('pod') === -1) {
      dispatch(updateIsPodSelected(false))
    }
  }, [selectedCovers])

  const Sidebar = () => {
    return (
      <Modal isCentered isOpen={isSidebarOpen} onClose={onSidebarClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Select and Click Apply</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <SideBar
              AddOns={addOns}
              selectedAddons={selectedAddons}
              setSelectedAddons={setSelectedAddons}
              selectedCovers={selectedCovers}
              setSelectedCovers={setSelectedCovers}
              ownDamageCovers={ownDamageCovers}
              liabilityCovers={liabilityCovers}
              AddOnsLoading={AddonsLoading}
              CoversLoading={CoversLoading}
              maxIdv={maxIdv}
              minIdv={minIdv}
              postQuotation={postQuotation}
              resetAll={handleReset}
              idv={idv}
              setIdv={setIdv}
              idvOption={idvOption}
              setIdvOption={setIdvOption}
              setBodyIdv={setBodyIdv}
              bodyIdv={bodyIdv}
              setBodyIdvOption={setBodyIdvOption}
              setChassisIdv={setChassisIdv}
              chassisIdv={chassisIdv}
              setChassisIdvOption={setChassisIdvOption}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  //  APIs begin here
  const { isLoading: AddonsLoading, data: AddonsData } = useGetAddonsQuery({
    variable_type: vehicleParam,
  })
  const { isLoading: CoversLoading, data: CoversData } = useGetAdditionalCoversQuery({
    variable_type: vehicleParam,
  })

  const [checkCpa, { isLoading: isCpaCheckLoading, isError: isCpaCheckError }] =
    useCheckForCpaMutation()
  const [
    postPollingQuotation,
    { isLoading: PostQuotesLoading, isError: QuotesError, isSuccess: PostQuotesSuccess },
  ] = usePostPollingQuotationMutation()
  const { data: QuoteData, isLoading: QuotesLoading } = useGetPollingQuotationQuery(
    { sub_request_id: subRequestId },
    { pollingInterval: pollingInterval, skip },
  )

  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()
  //  APIs end here

  const postQuotation = async (reset?: boolean) => {
    const payload: IQuotationRequest = formQuoteParam(reset)
    setPollingInterval(0)
    setCount(4)
    setQuotationsListing([])
    setReverseQuotationsListing([])
    try {
      const response = await postPollingQuotation(payload).unwrap()
      setCount(response.count)
      setRequestId(response.request_id)
      setSubRequestId(response.sub_request_id)
      setRetryable(response.retryable)
      setPollingInterval(2000)
    } catch (error) {
      setCount(0)
      setQuotationsListing([])
      setPollingInterval(0)
      setReverseQuotationsListing([])
    }
  }

  useEffect(() => {
    checkCpa({ rto: homePageDetails.vehicleNumber.substring(0, 5).replaceAll('-', '') })
  }, [homePageDetails])

  useEffect(() => {
    setCount(4)
    setQuotationsListing([])
    setReverseQuotationsListing([])
    setAddOnsToDisplay([])
    setCoversToDisplay([])
    handleReset().then(() => {
      if (retryable) {
        setSkip(false)
        setPollingInterval(2000)
      }
    })
  }, [isComprehensive, isIndividual, homePageDetails, vehicleDetails, insuranceDetails])

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const proposalDetails = useAppSelector((state) => state.proposalPage)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const spTaggingDetails = useAppSelector((state) => state.spTagging)

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      vehicle_number: homePageDetails.vehicleNumber,
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: drop_off,
      // insurer_name: insuranceDetails.previousInsurer?.name,
      product_name: product_name,
      lead_data: {
        homepage_details: homePageDetails,
        vehicle_details: vehicleDetails,
        insurance_details: insuranceDetails,
        quotation_details: quotationDetails,
        proposal_details: proposalDetails,
        lead_details: leadDetails,
        equitas_details: equitasDetails,
        spTagging_details: spTaggingDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  useEffect(() => {
    if (leadDetails.lead_uid) {
      createLead()
    }
  }, [leadDetails, vehicleDetails, insuranceDetails])

  const createLead = async () => {
    const payload: ISaveLead = formParam('Quotes', homePageDetails.selectedSubType)
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  useEffect(() => {
    if (QuoteData) {
      if (QuoteData.max_idv > maxIdv) {
        setMaxIdv(QuoteData.max_idv)
      }
      if (QuoteData.min_idv < minIdv || minIdv == 0) {
        setMinIdv(QuoteData['min_idv'])
      }
      if (QuoteData.result.length != 0) {
        const temp = [...quotationsListing, ...QuoteData.result]
        const reverseTemp = [...quotationsListing, ...QuoteData.result]
        temp.sort((a, b) => {
          if (a.premium_total > b.premium_total) {
            return 1
          } else if (a.premium_total < b.premium_total) {
            return -1
          } else {
            return 0
          }
        })
        reverseTemp.sort((a, b) => {
          if (a.premium_total > b.premium_total) {
            return -1
          } else if (a.premium_total < b.premium_total) {
            return 1
          } else {
            return 0
          }
        })
        setQuotationsListing(temp)
        setReverseQuotationsListing(reverseTemp)
      }
      if (QuoteData.retryable == false) {
        setCount(0)
        setPollingInterval(0)
      }
    }
  }, [QuoteData])

  useEffect(() => {
    if (AddonsData) {
      setSelectedAddons(new Map())
      const temp: ITransformedAddons[] = []
      Object.keys(AddonsData).map((t) => {
        setSelectedAddons(selectedAddons.set(t, false))
        temp.push(AddonsData[t])
        temp[temp.length - 1] = {
          ...temp[temp.length - 1],
          isSelected: false,
          code: t,
        }
      })
      temp.sort((a, b) => {
        if (a.priority < b.priority) {
          return -1
        } else if (a.priority > b.priority) {
          return 1
        } else {
          return 0
        }
      })
      setAddons(temp)
      dispatch(updateAddons(temp))
    }
  }, [AddonsLoading])

  useEffect(() => {
    if (CoversData) {
      setSelectedCovers(new Map())
      const tempOD: IAdditionalCover[] = []
      const tempLB: IAdditionalCover[] = []
      const tempDIS: IAdditionalCover[] = []
      Object.keys(CoversData).map((covers) => {
        CoversData[covers].map((t) => {
          if (covers == 'OD') {
            setSelectedCovers(selectedCovers.set(t.code, -1))
            tempOD.push(t)
          } else if (covers == 'LB') {
            setSelectedCovers(selectedCovers.set(t.code, -1))
            tempLB.push(t)
          } else {
            tempDIS.push(t)
          }
        })
      })
      setOwnDamageCovers(tempOD)
      setLiabilityCovers(tempLB)
      setDiscountCovers(tempDIS)
      dispatch(updateOwnDamageCovers(tempOD))
      dispatch(updateLiabilityCovers(tempLB))
      dispatch(updateOwnDiscountCovers(tempDIS))
    }
  }, [CoversLoading])

  const checkBreakIn = () => {
    let isBreakIn
    console.log('Inside checkbreakin')
    const date1 = new Date()
    const date2 = new Date(insuranceDetails.existingPolicyExpiryDate ?? '')
    if (
      vehicleName.toLowerCase() === 'car' ||
      vehicleName.toLowerCase() === 'gcv' ||
      vehicleName.toLowerCase() === 'pcv'
    ) {
      if (isComprehensive === false) {
        setIsBreakIn(false)
        dispatch(updateInspectionRequired({ inspectionRequired: false }))
      } else if (date1.getTime() - date2.getTime() > 0) {
        setIsBreakIn(true)
        dispatch(updateInspectionRequired({ inspectionRequired: true }))
      } else {
        if (insuranceDetails.previousPolicyType?.value === 'TP' && isComprehensive) {
          setIsBreakIn(true)
          dispatch(updateInspectionRequired({ inspectionRequired: true }))
        } else {
          setIsBreakIn(false)
          dispatch(updateInspectionRequired({ inspectionRequired: false }))
        }
      }
    } else if (vehicleName.toLowerCase() === 'bike') {
      setIsBreakIn(false)
      dispatch(updateInspectionRequired({ inspectionRequired: false }))
    }
  }

  const formQuoteParam = (reset?: boolean) => {
    // console.log(idv)
    // console.log(idvOption)
    const payload: IQuotationRequest = {
      add_ons: checkAddOns(),
      additional_cover: checkAdditionalCovers(),
      category: vehicleType,
      idv: reset === true ? 0 : isComprehensive ? (idvOption === 'Recommended' ? 0 : idv) : 0,
      is_vehicle_new: 0,
      loan: 0,
      make: vehicleDetails.selectedManufacturer?.code.toString() ?? '',
      manufacturing_year: vehicleDetails.manufacturingYear?.toString() ?? '',
      model: vehicleDetails.selectedModel?.code.toString() ?? '',
      ncb: insuranceDetails.claimsMadeInExistingPolicy
        ? '0'
        : insuranceDetails.previousPolicyType?.value === 'TP'
        ? '0'
        : insuranceDetails.ncb ?? '',
      owner_type: isIndividual ? 'Individual' : 'Company',
      policy_type: isComprehensive ? 'CO' : 'TP',
      previous_insurer: insuranceDetails.previousInsurer?.name ?? '',
      previous_policy_claim: insuranceDetails.claimsMadeInExistingPolicy ? 'Y' : 'N',
      previous_policy_end_date: insuranceDetails.existingPolicyExpiryDate ?? '',
      previous_policy_type: insuranceDetails.previousPolicyType?.value ?? '',
      registration_date: vehicleDetails.registrationDate ?? '',
      registration_no: homePageDetails.vehicleNumber,
      rto: homePageDetails.vehicleNumber.substring(0, 5).replaceAll('-', ''),
      variant: vehicleDetails.selectedVariant?.code.toString() ?? '',
      ucic_id: equitasDetails.ucic,
      lead_uid: leadDetails.lead_uid,
      ckyc_number: equitasDetails.ckyc_number ?? '',
      pan: equitasDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.panNb ?? '',
    }
    if (insuranceDetails.isSaod) {
      payload.TpPreviousInsurerName = insuranceDetails.TpPreviousInsurerName?.name
      payload.TpPreviousPolicyNumber = insuranceDetails.TpPreviousPolicyNumber
      payload.tp_policy_start_date = insuranceDetails.tpPolicyStartDate
      payload.tp_policy_end_date = insuranceDetails.tpPolicyEndDate
      payload.previous_policy_type = 'OD'
      payload.policy_type = 'OD'
    } else {
      delete payload.TpPreviousInsurerName
      delete payload.TpPreviousPolicyNumber
      delete payload.tp_policy_end_date
      delete payload.tp_policy_start_date
    }
    if (!insuranceDetails.hasPreviousPolicy) {
      payload.ncb = '0'
      payload.previous_policy_claim = 'Y'
      payload.previous_policy_type = 'CO'
      payload.previous_policy_end_date = '31/07/2022'
      payload.previous_insurer = 'Acko General Insurance'
    }
    if (requestId) {
      payload.request_id = requestId
    } else {
      delete payload.request_id
    }
    if (quotationDetails.fuelType) {
      payload.fuel_type = quotationDetails.fuelType
    } else {
      delete payload.fuel_type
    }
    if (vehicleName.toLowerCase() === 'gcv' || vehicleName.toLowerCase() === 'pcv') {
      payload.body_idv = isComprehensive ? bodyIdv.toString() : '0'
      payload.chassis_idv = isComprehensive ? chassisIdv.toString() : '0'
    } else {
      delete payload.body_idv
      delete payload.chassis_idv
    }
    if (vehicleName.toLowerCase() === 'gcv') {
      payload.permit_type = insuranceDetails.permitType?.code
    } else {
      delete payload.permit_type
    }
    if (vehicleName.toLowerCase() === 'pcv') {
      payload.vehicle_type = vehicleDetails.typeOfVehicle_commercial?.code.toLowerCase()
    } else {
      delete payload.vehicle_type
    }
    return payload
  }

  const checkAddOns = () => {
    const t: string[] = []
    selectedAddons.forEach((value, key) => {
      if (value) {
        t.push(key)
      }
    })
    return t
  }

  const checkAdditionalCovers = () => {
    let temp = {}
    selectedCovers.forEach((value, key) => {
      if (value !== -1) {
        temp = { ...temp, [key]: parseInt(value) }
        if (key === 'Fuel_od' || key === 'Fuel_tp') {
          temp = { ...temp, 'CNG/LPG Kitcheckbox': 0 }
        }
      }
    })
    return temp
  }

  const handleReset = async () => {
    // setIdv(0)
    // setIdvOption('Recommended')
    addOns.map((addOn) => {
      setSelectedAddons(new Map(selectedAddons.set(addOn.code, false)))
    })
    ownDamageCovers.map((cover) => {
      setSelectedCovers(new Map(selectedCovers.set(cover.code, -1)))
    })
    liabilityCovers.map((cover) => {
      setSelectedCovers(new Map(selectedCovers.set(cover.code, -1)))
    })
    dispatch(updateFuelType({ fuelType: '' }))
    setSelectedQuotes([])
    await postQuotation(true)
  }

  const buyPolicy = (quote: Quotations) => {
    if (
      quote.insurance_name === allInsurers.hdfc_4w ||
      quote.insurance_name === allInsurers.hdfc_2w ||
      quote.insurance_name === allInsurers.HDGCV ||
      quote.insurance_name === allInsurers.HDPCV
    ) {
      if (
        (quote.insurance_name === allInsurers.hdfc_4w ||
          quote.insurance_name === allInsurers.hdfc_2w) &&
        (insuranceDetails.previousPolicyType?.value === 'CO' ||
          quote.add_ons['zp'] === -2 ||
          quote.add_ons['zp'] > 0 ||
          parseInt(selectedCovers.get('nea')) +
            parseInt(selectedCovers.get('ea')) +
            parseInt(selectedCovers.get('Fuel_od')) >
            quote.idv / 2)
      ) {
        if (insuranceDetails.previousPolicyType?.value === 'CO') {
          onCPADialogOpen()
        }
        if (quote.add_ons['zp'] === -2 || quote.add_ons['zp'] > 0) {
          onZeroDepDialogOpen()
        }
        if (
          parseInt(selectedCovers.get('nea')) +
            parseInt(selectedCovers.get('ea')) +
            parseInt(selectedCovers.get('Fuel_od')) >
          quote.idv / 2
        ) {
          onHDFCDialogOpen()
        }
      } else {
        navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
      }
    } else {
      if (quote.insurance_name === allInsurers.hdfc_2w && vehicleName.toLowerCase() === 'bike') {
        navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
      } else if (
        (quote.insurance_name === allInsurers.BJ2W ||
          quote.insurance_name === allInsurers.BJ4W ||
          quote.insurance_name === allInsurers.BJGCV ||
          quote.insurance_name === allInsurers.SBIFW ||
          quote.insurance_name === allInsurers.SBITW ||
          quote.insurance_name === allInsurers.SBIGCV) &&
        quotationDetails.isIndividual &&
        !insuranceDetails.isSaod &&
        selectedCovers.get('pod') === -1
      ) {
        onPODDialogOpen()
      } else {
        navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
      }
      // else {
      //   if (vehicleName.toLowerCase() === 'car' || vehicleName.toLowerCase() === 'bike') {
      //     if (
      //       (quote.insurance_name === allInsurers.raheja_4w ||
      //         quote.insurance_name === allInsurers.hdfc_4w ||
      //         quote.insurance_name === allInsurers.navi_4w ||
      //         quote.insurance_name === allInsurers.tata_aig_4w ||
      //         quote.insurance_name === allInsurers.tata_aig_2w) &&
      //       (selectedAddons.get('zp') === -2 || selectedAddons.get('zp') >= 0)
      //     ) {
      //       navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
      //     } else {
      //       navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
      //     }
      //   } else {
      //     navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
      //   }
      // }
    }
  }

  return (
    <Flex height='100vh' direction='column' bgColor='#F5F6FA'>
      <Header />
      <Flex alignItems='start' justifyContent='start' height='90%'>
        <Box
          // borderTop='1px solid #707070'
          // borderRight='1px solid #707070'
          // borderBottom='1px solid #707070'
          boxShadow='0px 3px 6px #00000029'
          // marginTop='10px'
          bgColor='white'
          width='19%'
          display={isDesktop ? 'block' : 'none'}
        >
          <SideBar
            AddOns={addOns}
            selectedAddons={selectedAddons}
            setSelectedAddons={setSelectedAddons}
            selectedCovers={selectedCovers}
            setSelectedCovers={setSelectedCovers}
            ownDamageCovers={ownDamageCovers}
            liabilityCovers={liabilityCovers}
            AddOnsLoading={AddonsLoading}
            CoversLoading={CoversLoading}
            maxIdv={maxIdv}
            minIdv={minIdv}
            postQuotation={postQuotation}
            resetAll={handleReset}
            idv={idv}
            setIdv={setIdv}
            idvOption={idvOption}
            setIdvOption={setIdvOption}
            setBodyIdv={setBodyIdv}
            bodyIdv={bodyIdv}
            setBodyIdvOption={setBodyIdvOption}
            setChassisIdv={setChassisIdv}
            chassisIdv={chassisIdv}
            setChassisIdvOption={setChassisIdvOption}
          />
        </Box>
        <Flex
          flexBasis={isDesktop ? '90%' : '100%'}
          direction='column'
          height='100%'
          width='100%'
          justifyContent='space-between'
          alignItems='center'
          marginLeft='2'
        >
          <TopDetailBar length={quotationsListing.length} resetAll={handleReset} />
          <Flex
            bgColor='white'
            width='100%'
            alignItems='center'
            // paddingY='6'
            justifyContent='space-around'
            display={isDesktop ? 'none' : 'flex'}
          >
            <Popover>
              <PopoverTrigger>
                <Flex direction='column' alignItems='center' flexBasis='34%'>
                  <Text cursor='pointer' color='#00548C' _hover={{ textDecoration: 'underline' }}>
                    Package
                  </Text>
                  <Text>{quotationDetails.isComprehensive ? 'Comprehensive' : 'Third Party'}</Text>
                </Flex>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Choose Package Type</PopoverHeader>
                <PopoverBody>
                  <ToggleButton
                    title='Choose Package Type'
                    firstLabel='Comprehensive'
                    secondLabel='Third Party'
                    enabled={quotationDetails.isComprehensive}
                    firstOnClick={() => dispatch(updatePolicyType({ isComprehensive: true }))}
                    secondOnClick={() => dispatch(updatePolicyType({ isComprehensive: false }))}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Divider orientation='vertical' height='80px' />
            <Popover>
              <PopoverTrigger>
                <Flex direction='column' alignItems='center' flexBasis='32%'>
                  <Text cursor='pointer' color='#00548C' _hover={{ textDecoration: 'underline' }}>
                    Owned By
                  </Text>
                  <Text>{quotationDetails.isIndividual ? 'Individual' : 'Company'}</Text>
                </Flex>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Owned By</PopoverHeader>
                <PopoverBody>
                  <ToggleButton
                    title='Vehicle Owned By'
                    firstLabel='Individual'
                    secondLabel='Company'
                    enabled={quotationDetails.isIndividual}
                    firstOnClick={() => dispatch(updateOwnedBy({ isIndividual: true }))}
                    secondOnClick={() => dispatch(updateOwnedBy({ isIndividual: false }))}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Divider orientation='vertical' height='80px' />
            <Center flexBasis='33%'>
              <Text
                cursor='pointer'
                color='#00548C'
                _hover={{ textDecoration: 'underline' }}
                onClick={onSidebarOpen}
              >
                Add Ons
              </Text>
            </Center>
            <MobileAddonsDialog
              isOpen={isSidebarOpen}
              onClose={onSidebarClose}
              onOpen={onSidebarOpen}
              AddOns={addOns}
              selectedAddons={selectedAddons}
              setSelectedAddons={setSelectedAddons}
              selectedCovers={selectedCovers}
              setSelectedCovers={setSelectedCovers}
              ownDamageCovers={ownDamageCovers}
              liabilityCovers={liabilityCovers}
              AddOnsLoading={AddonsLoading}
              CoversLoading={CoversLoading}
              maxIdv={maxIdv}
              minIdv={minIdv}
              postQuotation={postQuotation}
              resetAll={handleReset}
              idv={idv}
              setIdv={setIdv}
              idvOption={idvOption}
              setIdvOption={setIdvOption}
              setBodyIdv={setBodyIdv}
              bodyIdv={bodyIdv}
              setBodyIdvOption={setBodyIdvOption}
              setChassisIdv={setChassisIdv}
              chassisIdv={chassisIdv}
              setChassisIdvOption={setChassisIdvOption}
            />
            {/* <Sidebar /> */}
          </Flex>
          <Box
            width='100%'
            bgColor='#D1ECF1'
            padding='1'
            textAlign='center'
            marginY='2'
            fontSize='12'
            display={
              selectedCovers.get('pod') === 0 || !quotationDetails.isIndividual ? 'none' : 'block'
            }
          >
            <Text color='#0D5460'>
              Please opt for &lsquo;PA to owner driver cover&rsquo; if the owner do not have a
              Personal Accident Cover of 15 Lakhs
            </Text>
          </Box>
          <Flex
            flexBasis='80%'
            overflow='scroll'
            width='100%'
            height='100%'
            justifyContent={
              count == 0 && quotationsListing.length == 0
                ? 'center'
                : isDesktop
                ? 'start'
                : 'center'
            }
          >
            <SimpleGrid
              // width='100%'
              columns={
                pageWidth > 1700
                  ? 5
                  : pageWidth > 1310
                  ? 4
                  : pageWidth > 820
                  ? 3
                  : pageWidth > 570
                  ? 2
                  : 1
              }
              // spacing='2'
              height='100%'
              width='100%'
              display={count == 0 && quotationsListing.length === 0 ? 'none' : 'grid'}
              // spacing='4'
              // flexBasis='67%'
              // overflow='scroll'
              // height='100%'
            >
              {quotationDetails.isAscending == true
                ? quotationsListing.map((quote) => (
                    <QuoteItem
                      key={quote.insurer_id}
                      quote={quote}
                      ownDamageCovers={ownDamageCovers}
                      liabilityCovers={liabilityCovers}
                      discountCovers={discountCovers}
                      addOns={addOns}
                      selectedQuotes={selectedQuotes}
                      setSelectedQuotes={setSelectedQuotes}
                      buyPolicy={buyPolicy}
                      isBreakIn={isBreakIn}
                    />
                  ))
                : reverseQuotationsListing.map((quote) => (
                    <QuoteItem
                      key={quote.insurer_id}
                      quote={quote}
                      ownDamageCovers={ownDamageCovers}
                      liabilityCovers={liabilityCovers}
                      discountCovers={discountCovers}
                      addOns={addOns}
                      selectedQuotes={selectedQuotes}
                      setSelectedQuotes={setSelectedQuotes}
                      buyPolicy={buyPolicy}
                      isBreakIn={isBreakIn}
                    />
                  ))}
              {count != 0 &&
                [...Array(count - quotationsListing.length)].map((n) => (
                  <SkeletonQuoteItem key={n} />
                ))}
            </SimpleGrid>
            {count == 0 && quotationsListing.length == 0 && (
              <Center flexBasis='100%' width='100%'>
                <Text fontSize='20px' color='tertiary.800' fontWeight='bold' textAlign='center'>
                  Sorry! There is no quote for the chosen vehicle or policy details. Kindly try
                  another variant or policy.
                </Text>
              </Center>
            )}
          </Flex>
        </Flex>
        <PODDialog isOpen={isPODDialogOpen} onClose={onPODDialogClose} onOpen={onPODDialogOpen} />
        <CPADialog isOpen={isCPADialogOpen} onClose={onCPADialogClose} onOpen={onCPADialogOpen} />
        <HDFCDialog
          isOpen={isHDFCDialogOpen}
          onClose={onHDFCDialogClose}
          onOpen={onHDFCDialogOpen}
        />
        <ZeroDepDialog
          isOpen={isZeroDepDialogOpen}
          onClose={onZeroDepDialogClose}
          onOpen={onZeroDepDialogOpen}
        />
      </Flex>
      {selectedQuotes.length >= 2 && (
        // <Box position='absolute' >
        <>
          <Button
            position='absolute'
            bottom='5%'
            right='5%'
            padding='4'
            zIndex='10'
            // colorScheme='blue'
            borderRadius='20px'
            boxShadow='0px 3px 6px #00000029'
            // bgColor='#1C4589'
            // disabled={!quotationDetails.pucCertificate}
            onClick={onCompareOpen}
          >
            Compare
          </Button>
          <Compare
            isOpen={isCompareOpen}
            onClose={onCompareClose}
            onOpen={onCompareOpen}
            quotationsListing={quotationsListing}
            selectedQuotes={selectedQuotes}
            addOns={addOns}
            ownDamageCovers={ownDamageCovers}
            liabilityCovers={liabilityCovers}
            addOnsToDisplay={addOnsToDisplay}
            setAddOnsToDisplay={setAddOnsToDisplay}
            coversToDisplay={coversToDisplay}
            setCoversToDisplay={setCoversToDisplay}
            subRequestId={subRequestId}
            selectedAddons={selectedAddons}
            selectedCovers={selectedCovers}
          />
        </>
      )}
    </Flex>
  )
}

export { Quotation }
