import { Flex, Radio, RadioGroup, Stack, Switch, Text } from '@chakra-ui/react'
import { useState } from 'react'

interface IToggleButtonProps extends React.ComponentPropsWithRef<'div'> {
  title: string
  firstLabel: string
  secondLabel: string
  enabled?: boolean
  firstOnClick?: any
  secondOnClick?: any
}

export const ToggleButton = (props: IToggleButtonProps) => {
  return (
    <Flex alignItems='center' justifyContent='space-evenly'>
      <Text flexBasis='30%' display={props.title ? 'flex' : 'none'}>
        {props.title}
      </Text>
      <Flex border='1px solid #B2B2B2' borderRadius='17px' fontSize='12px'>
        <Text
          bgColor={props.enabled ? 'secondary.500' : '#F5F6FA'}
          color={props.enabled ? 'white' : '#B2B2B2'}
          borderRadius='17px'
          paddingX='3'
          paddingY='1'
          cursor={props.enabled ? 'default' : 'pointer'}
          onClick={props.firstOnClick}
          // onClick={() => dispatch(updateAscending({ isAscending: true }))}
        >
          {props.firstLabel}
        </Text>
        <Text
          bgColor={!props.enabled ? 'secondary.500' : '#F5F6FA'}
          color={!props.enabled ? 'white' : '#B2B2B2'}
          borderRadius='17px'
          paddingX='3'
          paddingY='1'
          cursor={!props.enabled ? 'default' : 'pointer'}
          onClick={props.secondOnClick}
          // onClick={() => dispatch(updateAscending({ isAscending: false }))}
        >
          {props.secondLabel}
        </Text>
      </Flex>
    </Flex>
    // <>
    //   <Flex className='space-x-2 mt-1'>
    //     <Text fontSize='sm'>{props.title}</Text>
    //     <Flex>
    //       <RadioGroup defaultValue='1'>
    //         <Stack>
    //           <Flex className='space-x-1'>
    //             <Radio color='secondary' value='1'>
    //               <Text fontSize='sm'>{props.firstLabel}</Text>
    //             </Radio>
    //             <Radio color='secondary' value='2' fontSize='sm'>
    //               <Text fontSize='sm'>{props.secondLabel}</Text>
    //             </Radio>
    //           </Flex>
    //         </Stack>
    //       </RadioGroup>
    //     </Flex>
    //   </Flex>
    // </>
  )
}
