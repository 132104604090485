import {
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  ModalFooter,
  Text,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import successImg from 'Assets/Common/Tick.png'
import { formatCurr } from 'Pages/Life/Sachet/ConsentForm'

interface IPaymentStatus {
  id?: number
  transaction_number: string
  member_id?: string
  sp_id?: number
  insurance: string
  transaction_date: string
  amount: string
  status: string
  mode_of_payment: string
  quotation?: number
  masterPolicyNumber?: string
  coi_no?: string
}

export const SachetPaymentSuccessDialog = ({
  paymentStatusData,
  backHome,
}: {
  paymentStatusData: IPaymentStatus
  backHome: boolean
}) => {
  const navigate = useNavigate()

  return (
    <Flex paddingY='1rem' paddingX='1.5rem' gap='1rem' direction='column' alignItems='center'>
      <Image width='4rem' src={successImg} />
      <Text fontSize='1.4rem' fontWeight='extrabold'>
        Congratulations!
      </Text>
      <Text fontSize='1rem'>Payment is successful!</Text>

      <Text>
        Transaction Reference No: <b>{paymentStatusData?.transaction_number}</b>
      </Text>
      <Divider />
      <Grid templateColumns='repeat(2, 1fr)' gap={4} fontSize='0.8rem'>
        {/* {paymentStatusData?.masterPolicyNumber && (
            <>
              <GridItem>Master Policy Number:</GridItem>
              <GridItem textAlign='end'>
                {' '}
                <b>{paymentStatusData?.masterPolicyNumber}</b>
              </GridItem>
            </>
          )} */}
        {paymentStatusData?.coi_no && (
          <>
            <GridItem>COI Number:</GridItem>
            <GridItem textAlign='end'>
              {' '}
              <b>{paymentStatusData?.coi_no}</b>
            </GridItem>
          </>
        )}
        <GridItem>Amount:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{formatCurr(parseFloat(paymentStatusData?.amount))}</b>
        </GridItem>
        <GridItem>Insurance:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{paymentStatusData?.insurance}</b>
        </GridItem>
        <GridItem>Mode Of Payment:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{paymentStatusData?.mode_of_payment}</b>
        </GridItem>
        <GridItem>Date of enrollment:</GridItem>
        <GridItem textAlign='end'>
          {' '}
          <b>{new Date().toLocaleDateString()}</b>
        </GridItem>
      </Grid>
      <Divider />
      <Center>
        <Text fontSize='0.8rem' textAlign='center'>
          Note: Please note the policy would be underwritten by the insurer as per their
          underwriting guidelines{' '}
        </Text>
      </Center>

      {backHome && (
        <ModalFooter>
          <Button
            onClick={() => {
              navigate('/customer-account')
            }}
            marginX='0.5rem'
          >
            Back Home
          </Button>
        </ModalFooter>
      )}
      {/* {!backHome && (
          <ModalFooter>
            <Button
              onClick={() => {
                // window.close(``, `_parent`, ``)
              }}
              marginX='0.5rem'
              variant='outline'
            >
              You may close the tab
            </Button>
          </ModalFooter>
        )} */}
      {/* <Text>Confirmation e-mail sent to: abcd@test.com</Text>
        <Text>Order Number: 785883000469</Text> */}
    </Flex>
  )
}
