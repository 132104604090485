import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { TextInput } from 'Core'
import { ManufacturerList } from './ManufacturerList'

const popular = ['Honda', 'Maruti Suzuki', 'Mahindra', 'TATA', 'Nissan', 'Hyudai']

const ManufacturerCard = () => {
  return (
    <Box>
      <Box paddingX='5' paddingY='1'>
        <Text fontSize='lg' fontWeight='bold'>
          Manufacturer*
        </Text>
      </Box>
      <Box paddingX='5' paddingY='2'>
        <TextInput placeholder='Search Manufacturer' />
      </Box>
      <Tabs variant='unstyled'>
        <TabList boxShadow='excludingTop'>
          <Tab
            _selected={{
              color: 'tertiary',
              borderBlockEndColor: 'tertiary',
              borderBlockEnd: '4px',
            }}
            marginX='3'
          >
            Popular
          </Tab>
          <Tab
            _selected={{
              color: 'tertiary',
              borderBlockEndColor: 'tertiary',
              borderBlockEnd: '4px',
            }}
            marginX='3'
          >
            All Manufacturers ({popular.length})
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel paddingX='0'>
            <ManufacturerList />
          </TabPanel>
          <TabPanel paddingX='0'>
            <ManufacturerList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export { ManufacturerCard }
