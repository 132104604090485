import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  Flex,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { ToggleButton } from 'Core/ToggleButton'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { updatePolicyType } from 'features/Motor'
import { IAdditionalCover, ITransformedAddons } from 'Models/ResponseModels'
import { SideBar } from '../SideBar'

const MobileAddonsDialog = ({
  isOpen,
  onOpen,
  onClose,
  AddOns,
  selectedAddons,
  setSelectedAddons,
  selectedCovers,
  setSelectedCovers,
  AddOnsLoading,
  ownDamageCovers,
  liabilityCovers,
  CoversLoading,
  maxIdv,
  minIdv,
  postQuotation,
  resetAll,
  idv,
  setIdv,
  idvOption,
  setIdvOption,
  bodyIdv,
  setBodyIdv,
  setBodyIdvOption,
  chassisIdv,
  setChassisIdv,
  setChassisIdvOption,
}: {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  AddOns: ITransformedAddons[]
  selectedAddons: Map<any, any>
  setSelectedAddons: any
  selectedCovers: Map<any, any>
  setSelectedCovers: any
  AddOnsLoading: boolean
  ownDamageCovers: IAdditionalCover[]
  liabilityCovers: IAdditionalCover[]
  CoversLoading: boolean
  maxIdv: number
  minIdv: number
  postQuotation: any
  resetAll: any
  idv: number
  setIdv: any
  idvOption: string
  setIdvOption: any
  bodyIdv: number
  setBodyIdv: any
  setBodyIdvOption: any
  chassisIdv: number
  setChassisIdv: any
  setChassisIdvOption: any
}) => {
  const dispatch = useAppDispatch()
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Choose Package Type</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody>
          <SideBar
            AddOns={AddOns}
            selectedAddons={selectedAddons}
            setSelectedAddons={setSelectedAddons}
            selectedCovers={selectedCovers}
            setSelectedCovers={setSelectedCovers}
            ownDamageCovers={ownDamageCovers}
            liabilityCovers={liabilityCovers}
            AddOnsLoading={AddOnsLoading}
            CoversLoading={CoversLoading}
            maxIdv={maxIdv}
            minIdv={minIdv}
            postQuotation={postQuotation}
            resetAll={resetAll}
            idv={idv}
            setIdv={setIdv}
            idvOption={idvOption}
            setIdvOption={setIdvOption}
            setBodyIdv={setBodyIdv}
            bodyIdv={bodyIdv}
            setBodyIdvOption={setBodyIdvOption}
            setChassisIdv={setChassisIdv}
            chassisIdv={chassisIdv}
            setChassisIdvOption={setChassisIdvOption}
          />
        </ModalBody>
        <ModalFooter>
          <Flex width='100%' justifyContent='center'>
            <Button
              width='50%'
              variant='outline'
              borderRadius='0'
              colorScheme='tertiary'
              // color='#CE202F'
              // borderColor='#CE202F'
              onClick={async () => {
                await postQuotation()
                onClose()
              }}
            >
              Apply
            </Button>
            <Button
              width='50%'
              variant='outline'
              borderRadius='0'
              colorScheme='tertiary'
              // color='#CE202F'
              // borderColor='#CE202F'
              onClick={() => {
                resetAll()
                onClose()
              }}
            >
              Reset
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { MobileAddonsDialog }
