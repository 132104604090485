import { Grid } from '@chakra-ui/react'
import { ReactNode } from 'react'

const withFormGrid = (children: ReactNode) => {
  return (
    <Grid
      color={'#000'}
      mx={{ lg: '1rem', md: '1rem', sm: '1rem', xs: '.5rem' }}
      my={{ lg: '2rem', md: '2rem', sm: '1rem', xs: '1rem' }}
      paddingX='1rem'
      gap='2rem'
      gridTemplateColumns={{
        lg: '1fr 1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr',
        xs: '1fr',
      }}
      alignItems='end'
    >
      {children}
    </Grid>
  )
}

export { withFormGrid }
