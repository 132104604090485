import {
  Box,
  Divider,
  Flex,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAppDispatch } from 'app/hooks'
import { store } from 'app/store'
import { emailRegex, mobileNumRegex, onlyNumbersRegex } from 'Constants'
import { ElevatedButton, ErrorText, TextInput } from 'Core'
import {
  setCustomerAccountsStatus,
  setCustomerDetailsStatus,
  updateCustomerAccounts,
  updateProposalDetailsFromCBS,
  updateUcicId,
} from 'features/Equitas'
import { TCustomerDetails } from 'Models/RequestModels'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  useLazyGetCustomerDetailsQuery,
  useLazySearchCustomerQuery,
} from 'Services/API/equitas.api'
import { decryptData, onlyAllowTypingNumbers, onlyAllowTypingNumbers2 } from 'Services/general'
import { ToastService } from 'Services/toast.service'
import {
  customerDetailsErrorHandler,
  customerDetailsLoadingHandler,
  customerDetailsSuccessHandler,
  customerSearchErrorHandler,
  customerSearchLoadingHandler,
  customerSearchSuccessHandler,
} from './CbsUtils'
import { JourneyBlockDialog } from '../../JourneyBlockDialog'
import { ICustomerDetails, ICustomerSearchDetails } from 'Models/ResponseModels/Equitas'
import { setFullUserData } from 'features/Sachets/sachetData.slice'
import { setCustomerDetails } from 'features/Equitas/CustomerDetails.equitas.slice'

const OrSeprator = () => {
  return (
    <Flex alignItems='center' paddingY='1rem'>
      <Divider orientation='horizontal' />
      <Box
        padding='0.3rem'
        margin='0.5rem'
        border='1px solid'
        borderColor='gray.200'
        borderRadius='full'
      >
        <Text fontSize='sm'>OR</Text>
      </Box>
      <Divider orientation='horizontal' />
    </Flex>
  )
}

const CustomerDetailsForm = () => {
  const dispatch = useAppDispatch()

  const { register, handleSubmit, formState, watch } = useForm<TCustomerDetails>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const {
    isOpen: isBlockJourneyOpen,
    onOpen: onBlockJourneyOpen,
    onClose: onBlockJourneyClose,
  } = useDisclosure()

  const [searchCustomer, { isFetching: customerSearchLoading }] = useLazySearchCustomerQuery()

  const [getCustomerDetails, { isFetching: customerDetailsLoading }] =
    useLazyGetCustomerDetailsQuery()

  const onSubmit: SubmitHandler<TCustomerDetails> = async (data) => {
    try {
      customerSearchLoadingHandler(dispatch)
      const params: TCustomerDetails = {}
      if (data['ucic']) {
        params['ucic'] = data['ucic']
      } else if (data['phone']) {
        params['phone'] = '91' + data['phone']
      } else if (data['email']) {
        params['email'] = data['email']
      } else {
        ToastService.showErrorToast({
          title: 'UCIC id/Phone no/Email is required',
        })
        return
      }

      const { data: searchResponse } = await searchCustomer(params)
      const u: ICustomerSearchDetails = JSON.parse(decryptData(searchResponse?.data))
      if (u?.pan && u.phone) {
        if (u && u.accounts.length !== 0) {
          customerDetailsLoadingHandler(dispatch)
          const customerDetails = await getCustomerDetails({ ucic: u?.ucic }).unwrap() // cbs v3 api call
          const v: ICustomerDetails = JSON.parse(decryptData(customerDetails.data))
          if (v?.IndividualCustomerDetails?.IndCustDtlsRp) {
            // saving the applicant details in the store
            dispatch(setFullUserData(v?.IndividualCustomerDetails?.IndCustDtlsRp[0]))
            // console.log('setFullUserData: ', v?.IndividualCustomerDetails?.IndCustDtlsRp[0])
          }
          dispatch(
            setCustomerDetails({
              u,
              v: v?.CustmrFlds?.CustmrFldsRp,
            }),
          )
          if (v.accDtls && v.accDtls.accDtlsRp && v.accDtls.accDtlsRp.length > 0) {
            customerDetailsSuccessHandler(dispatch, v)
            customerSearchSuccessHandler(dispatch, u, v)
          } else {
            throw Error('Cannot fetch account details!')
          }
        } else {
          throw Error('No accounts Found!')
        }
      } else {
        onBlockJourneyOpen()
      }
    } catch (e: any) {
      console.log(decryptData(e))
      console.log('e')
      console.log(e)

      customerDetailsErrorHandler(dispatch)

      customerSearchErrorHandler(dispatch)
      // ToastService.showErrorToast({
      //   title: 'Error',
      //   message: e?.message ?? 'No Accounts found!',
      // })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box paddingY='4'>
        <Flex direction='column' marginY='2' marginX='6'>
          <FormLabel>UCIC ID</FormLabel>
          <TextInput
            autoComplete='off'
            onPaste={(e) => e.preventDefault()}
            {...register('ucic', {
              required: false,
              minLength: 7,
              maxLength: 9,
              pattern: onlyNumbersRegex,
            })}
            maxLength={9}
            placeholder='Enter UCIC ID'
            onKeyPress={onlyAllowTypingNumbers2}
            // onKeyDown={onlyAllowTypingNumbers}
          ></TextInput>
          {formState.errors.ucic &&
            (formState.errors.ucic?.type === 'minLength' ||
              formState.errors.ucic?.type === 'maxLength' ||
              formState.errors.ucic.type === 'pattern') && (
              <ErrorText text={'Length has to be between 7 to 9 of type number'} />
            )}
        </Flex>
        <Flex direction='column' marginY='2' marginX='6'>
          <OrSeprator />
        </Flex>
        <Flex direction='column' marginY='2' marginX='6'>
          <FormLabel>Phone Number</FormLabel>
          <InputGroup>
            <InputLeftAddon>+91</InputLeftAddon>
            <TextInput
              // onKeyDown={onlyAllowTypingNumbers}
              autoComplete='off'
              onPaste={(e) => e.preventDefault()}
              onKeyPress={onlyAllowTypingNumbers2}
              {...register('phone', { required: false, pattern: mobileNumRegex })}
              maxLength={10}
              placeholder='Enter Phone Number'
            ></TextInput>
          </InputGroup>
          {formState.errors.phone && formState.errors.phone?.type === 'pattern' && (
            <ErrorText text={'Invalid Mobile Number'} />
          )}
        </Flex>
        <Flex direction='column' marginY='2' marginX='6'>
          <OrSeprator />
        </Flex>
        <Flex direction='column' marginY='2' marginX='6'>
          <FormLabel>Email ID</FormLabel>
          <TextInput
            {...register('email', { required: false, pattern: emailRegex })}
            placeholder='Enter Email ID'
            autoComplete='off'
            maxLength={50}
          ></TextInput>
          {formState.errors.email && formState.errors.email?.type === 'pattern' && (
            <ErrorText text={'Invalid Email'} />
          )}
        </Flex>
        <Flex justifyContent='center' paddingY='5'>
          {/* <ElevatedButton
            onClick={() => console.log(store.getState())}
            rounded='lg'
            marginX='2'
            variant='outline'
          >
            Back
          </ElevatedButton> */}
          <ElevatedButton
            isLoading={customerDetailsLoading || customerSearchLoading}
            type='submit'
            rounded='lg'
            marginX='2'
            variant='solid'
          >
            Next
          </ElevatedButton>
          <Modal isCentered isOpen={isBlockJourneyOpen} onClose={onBlockJourneyClose}>
            <ModalOverlay />
            <ModalContent>
              <JourneyBlockDialog>
                <Flex direction='column' alignItems='center'>
                  <Text>Please update customers PAN/ Mobile number with the bank</Text>
                  {/* <Text>
                Name Fetched From Vahan -{' '}
                {`${vahanName.firstName ?? ''} ${vahanName.middleName ?? ''} ${
                  vahanName.lastName ?? ''
                }`}
              </Text>
              <Text>
                Name Fetched From CBS -{' '}
                {`${cbsName.firstName ?? ''} ${cbsName.middleName ?? ''} ${cbsName.lastName ?? ''}`}
              </Text> */}
                </Flex>
              </JourneyBlockDialog>
            </ModalContent>
          </Modal>
        </Flex>
      </Box>
    </form>
  )
}

export { CustomerDetailsForm }
