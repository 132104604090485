import React from 'react'

import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  Grid,
  GridItem,
  Input,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { IMemberState, MemberForm } from '../Forms/MemberForm'
import { MemberPincode } from '../Forms/MemberPincode'
import { IHealthPincode } from 'Models/ResponseModels/Health'

export const MemberDetailsDesktop = ({
  onSubmit,
  showMemberPincodeForm,
  pincodeFormSubmit,
}: {
  onSubmit: (members: IMemberState[]) => void
  showMemberPincodeForm: boolean
  pincodeFormSubmit: ({
    selfPincode,
    parentsPincode,
  }: {
    selfPincode: IHealthPincode
    parentsPincode: IHealthPincode
  }) => void
}) => {
  return (
    <>
      <Container maxW='1300px'>
        <Box border='1px' rounded='lg' backgroundColor='white'>
          <Grid templateColumns='repeat(2, 1fr)'>
            <GridItem borderRight='1px' w='100%'>
              <Box borderBottom='1px' px='8' py='4'>
                Who would you like to insure?
              </Box>
              <MemberForm onSubmit={onSubmit} />
            </GridItem>
            <GridItem backgroundColor={!showMemberPincodeForm ? 'appGrey.50' : ''} w='100%'>
              <Box px='8' py='4' borderBottom='1px'>
                Select Your Pincode
              </Box>
              {showMemberPincodeForm && <MemberPincode onSubmit={pincodeFormSubmit} />}
            </GridItem>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
