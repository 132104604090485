import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberData } from 'Models/Common/Health.General'
import { IHealthPincode } from 'Models/ResponseModels/Health'
import uuid from 'react-uuid'

interface IState {
  memberDetails: { [key: string]: MemberData }
  isParentsIncluded: boolean
  proposer: MemberData
  selfPincode: IHealthPincode
  parentsPincode: IHealthPincode
  isProposerIncluded: boolean
  sum_insured: string
  isDataFetchingFromLead?: boolean
}

const initialState: IState = {
  isDataFetchingFromLead: false,
  memberDetails: {},
  isParentsIncluded: false,
  isProposerIncluded: false,
  selfPincode: {
    pincode: '',
    area_name: '',
    area_code: '',
    city_code: '',
    city_name: '',
    state_name: '',
    state_code: '',
    is_district: null,
    area_id: null,
    extra_info: {},
    id: null,
    is_insurance_wise: null,
  },
  parentsPincode: {
    pincode: '',
    area_name: '',
    area_code: '',
    city_code: '',
    city_name: '',
    state_name: '',
    state_code: '',
    is_district: null,
    area_id: null,
    extra_info: {},
    id: null,
    is_insurance_wise: null,
  },
  proposer: {
    type: 'adult',
    member_type: 'proposer',
    salutation: { name: '', code: '' },
    age: '',
    dob: '',
    pincode: '',
    proposer: 1,
    gender: '',
    city_name: '',
    state_name: '',
    nationality: '',
    selected: false,
    code: '',
    marital_status: { name: '', code: '' },
    member_insured: '',
    firstname: '',
    middlename: '',
    lastname: '',
    relationship_with_proposer: '',
    mobile: '',
    email: '',
    occupation: { name: '', code: '' },
    bmi: '',
    height: '',
    weight: '',
    educational_qualification: { name: '', code: '' },
    annual_income: '',
    aadhar_card_number: '',
    pan_number: '',
    current_address: '',
    pincode_map: {
      pincode: '',
      area_name: '',
      area_code: '',
      city_code: '',
      city_name: '',
      state_name: '',
      state_code: '',
      is_district: null,
      area_id: null,
      extra_info: {},
      id: null,
      is_insurance_wise: null,
    },
    permanent_address: '',
    permanent_address_pincode_map: {
      pincode: '',
      area_name: '',
      area_code: '',
      city_code: '',
      city_name: '',
      state_name: '',
      state_code: '',
      is_district: null,
      area_id: null,
      extra_info: {},
      id: null,
      is_insurance_wise: null,
    },
    nominee_details: {
      salutation: { name: '', code: '' },
      firstname: '',
      middlename: '',
      lastname: '',
      dob: '',
      gender: '',
      relationship: { name: '', code: '' },
      mobile: '',
      occupation: { name: '', code: '' },
      address: '',
      marital_status: { name: '', code: '' },
      pincode_map: {
        pincode: '',
        area_name: '',
        area_code: '',
        city_code: '',
        city_name: '',
        state_name: '',
        state_code: '',
        is_district: null,
        area_id: null,
        extra_info: {},
        id: null,
        is_insurance_wise: null,
      },
    },
    medical_questions: [],
    lifestyle_questions: [],
    selectedQuoteNumber: '',
  },
  sum_insured: '',
}

const checkforParents = (state: IState) => {
  console.log(state.memberDetails, 'heloooo')
  let isParentsIncluded = false
  Object.keys(state.memberDetails).forEach((member) => {
    if (
      state.memberDetails[member].member_type === 'father' ||
      state.memberDetails[member].member_type === 'mother'
    ) {
      isParentsIncluded = true
    }
  })
  return isParentsIncluded
}

const memberDetailsSlice = createSlice({
  name: 'memberDetails',
  initialState: initialState,
  reducers: {
    resetMemberDetails: () => initialState,
    updateIsDataFetchingFromLeadStatus: (state, action: PayloadAction<boolean>) => {
      state.isDataFetchingFromLead = action.payload
    },
    updateMember: (
      state,
      action: PayloadAction<{
        code: string
        memberDetail: MemberData
      }>,
    ) => {
      if (state.memberDetails[action.payload.code]) {
        state.memberDetails[action.payload.code] = action.payload.memberDetail
      } else {
        state.memberDetails[uuid()] = action.payload.memberDetail
      }
    },

    deleteInsurerWiseMemberDetails: (state) => {
      Object.values(state.memberDetails).forEach((member) => {
        state.memberDetails[member.code!] = {
          ...state.memberDetails[member.code!],
          occupation: { name: '', code: '' },
          salutation: { name: '', code: '' },
          marital_status: { name: '', code: '' },
          educational_qualification: { name: '', code: '' },
          pincode_map: {
            pincode: '',
            area_name: '',
            area_code: '',
            city_code: '',
            city_name: '',
            state_name: '',
            state_code: '',
            is_district: null,
            area_id: null,
            extra_info: {},
            id: null,
            is_insurance_wise: null,
          },
          permanent_address_pincode_map: {
            pincode: '',
            area_name: '',
            area_code: '',
            city_code: '',
            city_name: '',
            state_name: '',
            state_code: '',
            is_district: null,
            area_id: null,
            extra_info: {},
            id: null,
            is_insurance_wise: null,
          },
          nominee_details: {
            ...state.memberDetails[member.code!].nominee_details,
            occupation: { name: '', code: '' },
            salutation: { name: '', code: '' },
            marital_status: { name: '', code: '' },
            relationship: { name: '', code: '' },
          },
          lifestyle_questions: [],
          medical_questions: [],
        }
      })
      state.proposer = {
        ...state.proposer,
        occupation: { name: '', code: '' },
        salutation: { name: '', code: '' },
        marital_status: { name: '', code: '' },
        educational_qualification: { name: '', code: '' },
        pincode_map: {
          pincode: '',
          area_name: '',
          area_code: '',
          city_code: '',
          city_name: '',
          state_name: '',
          state_code: '',
          is_district: null,
          area_id: null,
          extra_info: {},
          id: null,
          is_insurance_wise: null,
        },
        permanent_address_pincode_map: {
          pincode: '',
          area_name: '',
          area_code: '',
          city_code: '',
          city_name: '',
          state_name: '',
          state_code: '',
          is_district: null,
          area_id: null,
          extra_info: {},
          id: null,
          is_insurance_wise: null,
        },
        nominee_details: {
          ...state.proposer.nominee_details,
          occupation: { name: '', code: '' },
          salutation: { name: '', code: '' },
          marital_status: { name: '', code: '' },
          relationship: { name: '', code: '' },
        },
        lifestyle_questions: [],
        medical_questions: [],
      }
    },
    addMembers: (state, action: PayloadAction<MemberData[]>) => {
      console.log(action.payload)
      action.payload.forEach((member) => {
        console.log(state.memberDetails[member.code!], member.code)
        const code = uuid()
        if (state.memberDetails[member.code!]) {
          state.memberDetails[member.code!] = member
        } else {
          member.code = code
          state.memberDetails[code] = member
        }
      })
      state.isParentsIncluded = checkforParents(state)
    },
    deleteMember: (
      state,
      action: PayloadAction<{
        key: string
      }>,
    ) => {
      delete state.memberDetails[action.payload.key]
    },
    updateProposer: (
      state,
      action: PayloadAction<{
        data: MemberData
        isProposerIncluded: boolean
      }>,
    ) => {
      // console.log(action.payload.data, 'this is final data ')
      state.proposer = {
        ...state.proposer,
        ...action.payload.data,
      }
      state.isProposerIncluded = action.payload.isProposerIncluded
    },
    updateSumInsured: (state, action: PayloadAction<string>) => {
      state.sum_insured = action.payload
    },
    deleteMembers: (state) => {
      state.memberDetails = {}
      state.isParentsIncluded = false
    },
    updateProposerPincode: (state, action: PayloadAction<IHealthPincode>) => {
      state.selfPincode = action.payload
    },
    updateParentsPincode: (state, action: PayloadAction<IHealthPincode>) => {
      state.parentsPincode = action.payload
    },
    updateCompleteMemberDetails: (state, action: PayloadAction<IState>) => {
      state.memberDetails = action.payload.memberDetails
      state.isParentsIncluded = action.payload.isParentsIncluded
      state.proposer = action.payload.proposer
      state.selfPincode = action.payload.selfPincode
      state.parentsPincode = action.payload.parentsPincode
      state.isProposerIncluded = action.payload.isProposerIncluded
      state.sum_insured = action.payload.sum_insured
    },
  },
})

const { reducer } = memberDetailsSlice

export const {
  addMembers,
  updateProposer,
  updateSumInsured,
  deleteMembers,
  updateMember,
  updateProposerPincode,
  updateParentsPincode,
  deleteInsurerWiseMemberDetails,
  updateCompleteMemberDetails,
  updateIsDataFetchingFromLeadStatus,
  resetMemberDetails,
} = memberDetailsSlice.actions

export { reducer as memberDetailsReducer }
