import { Center, Flex, FormLabel, Image, Text } from '@chakra-ui/react'
import { CommonImages } from 'Assets/Common'
import { registrationNumRegex } from 'Constants'
import { ElevatedButton, ProgressStepItem, TextInput } from 'Core'
import { useState } from 'react'
import { checkVehicleNumber, validateVehicleNumber } from 'Services/general'
import { IMotorFormProps } from './MotorFormProps'

const {
  HomeImages: { miscInsurance },
} = CommonImages

const HomeMiscForm = (props: IMotorFormProps) => {
  const { isDesktop, setVehicleNumber, vehicleNumber, findPlansHandler, findPlansLoading } = props

  return (
    <Flex paddingY={isDesktop ? '0' : '10'} paddingX={isDesktop ? '20' : '5'} alignItems='center'>
      <Flex
        justifyContent='space-between'
        direction='column'
        height='100%'
        marginY='8'
        flexBasis={isDesktop ? '40%' : '100%'}
      >
        {/* TODO: change this to custom component */}
        <Flex marginX='6' gap='0.4rem'>
          <ProgressStepItem
            label='Miscellaneous Number'
            isActive
            onTap={() => {
              console.log('hi')
            }}
          />
          <ProgressStepItem
            label='Miscellaneous Details'
            isActive={false}
            onTap={() => {
              console.log('hi')
            }}
          />
        </Flex>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            findPlansHandler(vehicleNumber, 'miscellaneous')
          }}
        >
          <Flex direction='column' marginY='4' marginX='6'>
            <FormLabel fontWeight='semibold'>Miscellaneous Number</FormLabel>
            <TextInput
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(validateVehicleNumber(e.target.value))}
              border='solid 2px'
              placeholder='Enter Miscellaneous Number'
            />
          </Flex>
          <Center paddingTop='1rem'>
            <ElevatedButton
              isLoading={findPlansLoading}
              isDisabled={!registrationNumRegex.test(vehicleNumber)}
              type='submit'
            >
              Find Plans
            </ElevatedButton>
          </Center>
        </form>
      </Flex>
      {isDesktop && (
        <Flex justifyContent='center' flexBasis='60%'>
          <Image
            paddingY={['4rem', '4rem', '2rem']}
            backgroundSize='contain'
            // height={['5rem', '10rem', '15rem', '15rem', '20rem']}
            alt='car'
            src={miscInsurance}
          />
        </Flex>
      )}
    </Flex>
  )
}

export { HomeMiscForm }
