import React, { useEffect, useRef, useState } from 'react'
import { BimabossDatePicker, DashboardHeading, ElevatedButton, ErrorText, TextInput } from 'Core'
import { useDeviceType, useAppSelector } from 'app/hooks'
import {
  Button,
  Center,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TSpConsentForm } from './FormModels'
import { spConsentFormQuestionsMap } from 'Services/general'
import { useParams } from 'react-router-dom'
import { ISpConsentGenerationPayload } from 'Models/RequestModels/Equitas'
import {
  useConsentUploadMutation,
  useLazyCheckForspConsentSubmissionStatusQuery,
  useSubmitSpConsentFormMutation,
} from 'Services/API/motor.api'
import { ToastService } from 'Services/toast.service'
import { ISpConsentCheckBody } from 'Models/ResponseModels/Equitas'
import { exportAsImage } from 'Pages/Health/Consent/exportAsImage'
import {
  useConsentSendSMSMutation,
  useSendEmailForOTPMutation,
  useVerifyOTPforConsentMutation,
} from 'Services/API/equitas.api'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

const SpConsentForm = () => {
  const exportRef = useRef<HTMLDivElement>(null)

  const { isDesktop, isTablet } = useDeviceType()
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)

  const params = useParams()

  const [uniqueId, setUniqueId] = useState('')
  const [spData, setSpData] = useState<ISpConsentCheckBody>()

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const { register, handleSubmit, formState, trigger } = useForm<TSpConsentForm>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  useEffect(() => {
    const base64Data = Object.values(params)[0]

    const jsonStringData = atob(base64Data ?? '')

    const uniqueIdentifier = JSON.parse(jsonStringData).unique_identifier

    setUniqueId(uniqueIdentifier)
  }, [])

  const [submitSpConsentForm, { isLoading: isSpTaggingFormSubmitLoading }] =
    useSubmitSpConsentFormMutation()

  const [checkForSpConsentSubmissionStatus, { data: submissionResponse }] =
    useLazyCheckForspConsentSubmissionStatusQuery()

  const [sendSMS, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
    useConsentSendSMSMutation()

  const [verifyOtp, { isLoading: isVerifyOtpLoading }] = useVerifyOTPforConsentMutation()

  const [sendOTPviaEmail] = useSendEmailForOTPMutation()

  const [consentUpload] = useConsentUploadMutation()

  const onSubmit: SubmitHandler<TSpConsentForm> = async (data) => {
    try {
      console.log('hi')
      await submitSpConsentForm({
        unique_identifier: uniqueId,
        consent_data: {
          [spConsentFormQuestionsMap['q1']]: 'true',
          [spConsentFormQuestionsMap['q2']]: 'true',
          [spConsentFormQuestionsMap['q3']]: 'true',
          payment_method: 'others',
        },
        customer: spData?.customer,
      }).unwrap()

      const image = await exportAsImage(exportRef.current, 'test')

      // call consent upload api
      await consentUpload({
        b64_image: image,
        consent_type: 'sp_consent',
        unique_identifier: uniqueId,
        email_id: spData?.customer?.email_id ?? '',
        lead_uid: spData?.customer.lead_uid ?? '',
      })

      setTimeout(() => {
        window.close()
      }, 1000)
    } catch (e) {
      ToastService.showErrorToast({
        title: 'Something went wrong',
      })
    }
  }

  useEffect(() => {
    if (uniqueId) {
      checkForSpConsentSubmissionStatus({
        unique_identifier: uniqueId ?? '',
      }).then((data) => {
        console.log(data.data, 'data')
        if (data?.data?.is_submited === false) {
          setSpData(data.data.payload)
          console.log(data.data.payload, 'payload recieved')
        } else {
          ToastService.showErrorToast({
            title: 'Error',
            message: 'Form Already Submitted!',
            infinite: true,
          })
          setIsFormSubmitted(true)
        }
      })
    }
  }, [uniqueId])

  // const [sendOtp, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
  //   useSpTaggingSendOtpMutation()

  const [otp, setOtp] = useState('')

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const [isOtpVerified, setIsOtpVerified] = useState(false)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  return (
    <>
      {isFormSubmitted ? (
        <DashboardHeading title='Form Already Submitted!' />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div ref={exportRef}>
            <DashboardHeading title='SP Validation Form' />
            <Grid
              paddingX={isDesktop ? '2rem' : '0'}
              gridTemplateColumns={isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr'}
            >
              {spData?.customer.sp_name && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>SP Name</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.sp_name ?? ''}
                    placeholder='Enter SP Name'
                  />
                </GridItem>
              )}
              {spData?.customer.sp_code && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>SP Code</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.sp_code ?? ''}
                    placeholder='Enter SP Code'
                  />
                </GridItem>
              )}
              {spData?.customer.sp_branch && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>SP Branch</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.sp_branch ?? ''}
                    placeholder='Enter SP Branch'
                  />
                </GridItem>
              )}
              {spData?.customer.application_no && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Application No</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.application_no ?? ''}
                    placeholder='Enter Application No'
                  />
                </GridItem>
              )}
              {spData?.customer.login_date && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Login Date</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.login_date ?? ''}
                    placeholder='Enter Login Date'
                  />
                </GridItem>
              )}
              {spData?.customer.customer_name && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>
                    {spData?.customer.owner_type === 'Individual'
                      ? 'Customer Name'
                      : 'Company Name'}
                  </FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.customer_name ?? ''}
                    placeholder='Enter Customer Name'
                  />
                </GridItem>
              )}
              {spData?.customer.insurance_type && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Insurance Type</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.insurance_type ?? ''}
                    placeholder='Enter Insurance Type'
                  />
                </GridItem>
              )}
              {spData?.customer.plan_name && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Plan Name</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.plan_name ?? ''}
                    placeholder='Enter Plan Name'
                  />
                </GridItem>
              )}
              {spData?.customer.premium && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Premium</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.premium ?? ''}
                    placeholder='Enter Premium'
                  />
                </GridItem>
              )}
              {spData?.customer.mode && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Mode</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.mode ?? ''}
                    placeholder='Enter Mode'
                  />
                </GridItem>
              )}
              {spData?.customer.method && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Method</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.method ?? ''}
                    placeholder='Enter Method'
                  />
                </GridItem>
              )}
              {spData?.customer.policy_term && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Policy Term</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.policy_term ?? ''}
                    placeholder='Enter Policy Term'
                  />
                </GridItem>
              )}
              {spData?.customer.premium_payment_term && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Premium Payment Term</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.premium_payment_term ?? ''}
                    placeholder='Enter Premium Payment Term'
                  />
                </GridItem>
              )}
              {spData?.customer.policy_purchased_for && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Policy Purchased for</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.policy_purchased_for ?? ''}
                    placeholder='Enter Policy Purchased for'
                  />
                </GridItem>
              )}
              {spData?.customer.dependent_name && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Dependent Name</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.dependent_name ?? ''}
                    placeholder='Enter Dependent Name'
                  />
                </GridItem>
              )}
              {spData?.customer.ucic && (
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>UCIC</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={spData?.customer.ucic ?? ''}
                    placeholder='Enter UCIC'
                  />
                </GridItem>
              )}
            </Grid>
            <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
              <Checkbox {...register('q1', { required: true })} value='true'>
                {spConsentFormQuestionsMap.q1}
                {formState.errors.q1 && formState.errors.q1?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q2', { required: true })} value='true'>
                {spConsentFormQuestionsMap.q2}
                {formState.errors.q2 && formState.errors.q2?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q3', { required: true })} value='true'>
                {spConsentFormQuestionsMap.q3}
                {formState.errors.q3 && formState.errors.q3?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
            </Flex>
          </div>
          {!isOtpVerified && (
            <Center flexDirection='column'>
              {!isOtpSent && (
                <ElevatedButton
                  onClick={async () => {
                    const isFormValid = await trigger()
                    if (isFormValid) {
                      sendSMS({
                        message_type: 'sp_otp',
                        phone_number: spData?.customer.phone,
                        //  phone_number: '919899004640',
                        unique_identifier: uniqueId ?? '',
                        lead_uid: spData?.customer.lead_uid ?? '',
                      })
                        .unwrap()
                        .then((res) => {
                          console.log(res)
                          ToastService.showSuccessTaost({
                            title: 'Success',
                            message: `OTP sent successfully to ${spData?.customer.phone} and ${spData?.customer.email_id}`,
                          })
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                      sendOTPviaEmail({
                        message_type: 'sp_otp',
                        reciever_mail: spData?.customer.email_id,
                        unique_identifier: uniqueId ?? '',
                        lead_uid: spData?.customer.lead_uid ?? '',
                      })
                      startTimer()
                    }
                  }}
                  isLoading={isSendOtpLoading}
                  isDisabled={isSendOtpLoading}
                  marginY='1rem'
                >
                  Send OTP
                </ElevatedButton>
              )}
              {isOtpSent && (
                <Flex
                  paddingY='1rem'
                  direction='column'
                  alignItems='center'
                  marginY='4'
                  marginX='6'
                >
                  <FormLabel>Enter OTP</FormLabel>
                  <HStack>
                    <PinInput
                      onChange={(str) => {
                        setOtp(str)
                      }}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </Flex>
              )}
              {isOtpSent && (
                <Flex marginY='0.7rem'>
                  <Button
                    isDisabled={otp.length !== 6}
                    isLoading={isVerifyOtpLoading}
                    onClick={() => {
                      try {
                        verifyOtp({
                          phone_no: spData?.customer.phone
                            ? '91' + spData?.customer.phone.slice(-10)
                            : '',
                          //  phone_no:919899004640,
                          otp: otp,
                          unique_identifier: uniqueId,
                        })
                          .unwrap()
                          .then((res) => {
                            console.log(res)
                            setIsOtpVerified(true)
                            ToastService.showSuccessTaost({
                              title: 'OTP Verified successfully',
                            })
                            setIsOtpVerified(true)
                          })
                          .catch((e) =>
                            ToastService.showErrorToast({
                              title: 'OTP Verification Failed',
                            }),
                          )
                      } catch (e) {
                        console.log(e)
                        ToastService.showErrorToast({
                          title: 'OTP Verification Failed',
                        })
                      }
                    }}
                    marginX='0.5rem'
                  >
                    Verify OTP
                  </Button>
                  <Button
                    disabled={!resendOtp}
                    onClick={async () => {
                      sendSMS({
                        message_type: 'sp_otp',
                        phone_number: spData?.customer.phone
                          ? '91' + spData?.customer.phone.slice(-10)
                          : '',
                        //  phone_number: '919899004640',
                        unique_identifier: uniqueId ?? '',
                        lead_uid: spData?.customer.lead_uid ?? '',
                      })
                        .unwrap()
                        .then((res) => {
                          console.log(res)
                          ToastService.showSuccessTaost({
                            title: 'Success',
                            message: `OTP sent successfully to ${spData?.customer.phone} and ${spData?.customer.email_id}`,
                          })
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                      sendOTPviaEmail({
                        message_type: 'sp_otp',
                        reciever_mail: spData?.customer.email_id,
                        unique_identifier: uniqueId ?? '',
                        lead_uid: spData?.customer.lead_uid ?? '',
                      })
                      setResendOtp(false)
                      startTimer()
                    }}
                    marginX='0.5rem'
                  >
                    {resendOtp ? 'Resend OTP' : `Resend OTP in ${timeLeft}`}
                  </Button>
                </Flex>
              )}
            </Center>
          )}
          {isOtpVerified && (
            <Center padding='1.5rem 0.5rem '>
              <ElevatedButton isLoading={isSpTaggingFormSubmitLoading} type='submit'>
                Submit
              </ElevatedButton>
            </Center>
          )}
        </form>
      )}
    </>
  )
}

export { SpConsentForm }
