import { Box, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import { useOnClickOutside } from 'app/hooks/useClickOutside'
import { TextInput } from 'Core'
import React, { useEffect, useRef, useState } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'

interface IBimabossDropdown<T> extends InputProps {
  items: T[]
  renderItem: (item: T) => React.ReactNode
  keyGenerator: (item: T) => string | number
  selectedDropdownItem: T | null
  setSelectedDropdownItem: React.Dispatch<React.SetStateAction<T | null>>
}

const BimabossDropdown = <T extends { value: string | number; label: string }>(
  props: IBimabossDropdown<T>,
) => {
  const {
    items,
    keyGenerator,
    renderItem,
    selectedDropdownItem,
    setSelectedDropdownItem,
    ...rest
  } = props

  // useEffect(() => {
  //   console.log(selectedDropdownItem)
  // }, [selectedDropdownItem])

  const parentRef = useRef<HTMLDivElement>(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [inputVal, setInputVal] = useState(selectedDropdownItem?.label ?? '')

  useOnClickOutside(parentRef, () => setIsDropdownOpen(false))

  return (
    <Box ref={parentRef} position='relative'>
      <InputGroup onClick={() => setIsDropdownOpen((prev) => !prev)}>
        <TextInput value={inputVal} isReadOnly {...rest} />
        <InputRightElement>
          <RiArrowDownSLine color='gray.500' />
        </InputRightElement>
      </InputGroup>
      {isDropdownOpen && (
        <Box
          zIndex='9999'
          position='absolute'
          top='50px'
          width='100%'
          border='solid 1px'
          borderColor='gray.200'
          overflowY='scroll'
        >
          {items.map((item) => {
            return (
              <Box
                cursor='pointer'
                padding='10px'
                _hover={
                  selectedDropdownItem?.value === item.value
                    ? {}
                    : {
                        backgroundColor: '#e2e8f0',
                      }
                }
                color={selectedDropdownItem?.value === item.value ? 'white' : 'black'}
                backgroundColor={
                  selectedDropdownItem
                    ? selectedDropdownItem.value === item.value
                      ? 'primary.500'
                      : 'white'
                    : 'white'
                }
                boxShadow='md'
                onClick={() => {
                  setSelectedDropdownItem(item)
                  setInputVal(item.label)
                  setIsDropdownOpen(false)
                }}
                key={keyGenerator(item)}
              >
                {renderItem(item)}
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default BimabossDropdown
