import { useDisclosure } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { AxiosError } from 'axios'

import { IApiResponse } from 'Models/ResponseModels'
import { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ToastService } from 'Services/toast.service'

import { store } from 'app/store'

import cloneDeep from 'lodash/cloneDeep'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import { updateRider } from 'features/Life/LifeCustomerDetails.life.slice'
import {
  updateFilter,
  updateIsBiFormGenerated,
  updatePlanQuoteResponse,
  updateProposalId,
  updateQuotationsApiLoading,
  updateRiderAffectKeys,
  updateRiderQuestion,
} from 'features/Life/PlanData.slice'
import {
  useGetLifeSingleQuotesMutation,
  useLazyGetPlanQuestionsMasterQuery,
  useLazyGetRiderMasterQuery,
} from 'Services/API/life.api'
import { updateApplicationNumber } from 'features/Life/LifeQuotationDetails.slice'

export interface IQuoteDetails {
  planName: string
  planDescription: string
  quoteId: string
  biUrl: string
  sumAssured: number
  annualPremium: string
  filters: any
  startDate: string
  logo: string
  toggleIsBiFormGenerated: (state: boolean) => void
}

const useLifeQuotation = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { authenticatedUser } = useAppSelector((state) => state.user)

  const [getSingleQuote, { isLoading, isError }] = useGetLifeSingleQuotesMutation()

  const [getRider] = useLazyGetRiderMasterQuery()

  const [riderData, setRiderData] = useState<FieldValues>()

  const filterFormData = useAppSelector((state) => state.planData.filter)
  const riderFormData = useAppSelector((state) => state.planData.rider)
  const lifeQuotationDetails = useAppSelector((state) => state.lifeQuotationDetails)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const formDataFromStore = useAppSelector((state) => state.lifeCustomerDetails)
  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const selectedSp = useAppSelector((state) => state.spTagging.selectedSp)
  // const { assuredDetails, customerDetails, relationship } = useAppSelector((state) => state.cbsData)
  // #TODO: change this to diff slice
  const {
    lead_id,
    isBiFormGenerated,
    planQuoteResponse,
    isGetQuotationsApiLoading,
    selected_plan,
    selected_plan_amount,
    ridersQuestions,
    filter,
    riderAffectKeys,
  } = useAppSelector((state) => state.planData)

  const {
    isOpen: isFilterDialogOpen,
    onOpen: onFilterDialogOpen,
    onClose: onFilterDialogClose,
  } = useDisclosure()

  useEffect(() => {
    if (filterFormData) {
      Object.entries(filterFormData).map(([key, value]) => form.setValue(key, value))
    }
  }, [])

  const form = useForm({
    shouldUseNativeValidation: false,
    mode: 'onChange',
  })
  const riderForm = useForm({
    shouldUseNativeValidation: false,
    mode: 'onChange',
  })
  const onRiderFormSubmit: SubmitHandler<FieldValues> = async (data) => {
    dispatch(updateRider(riderForm.getValues()))
    // if (filter) {
    //   onGetQuotation(filter, data)
    // }
  }

  // const onClearRidersClick = () => { // TODO
  //   riderForm.setValue('IsRiderOpted', '')
  //   dispatch(clearRiderFromStore())
  // }

  const onFormSubmit: SubmitHandler<FieldValues> = async (data) => {
    dispatch(updateFilter(data))
    getFinalQuote(data)
  }

  // useEffect(() => {
  //   // console.log(filter)
  //   if (filter) getFinalQuote()
  // }, [filter])

  const getFinalQuote = async (data: any) => {
    dispatch(updatePlanQuoteResponse(undefined))
    dispatch(updateQuotationsApiLoading(true))
    try {
      const response = await getSingleQuote({
        branch_code: authenticatedUser.branch_sol_id ?? '',
        branch_name: authenticatedUser.branch_name ?? '',
        la_customer_id: lifeQuotationDetails.life_assured_id ?? -1,
        pr_customer_id: lifeQuotationDetails.proposer_id ?? -1,
        plan_data: data,
        plan_id: lifeQuotationDetails.finalSelectedPlan?.id ?? '',
        relationship: formDataFromStore.relationship,
        lead_id: leadDetails.lead_uid,
        ucic_id: equitasDetails.ucic!,
        sp_id:
          authenticatedUser.businessrole_name === 'SP'
            ? authenticatedUser.id?.toString()
            : selectedSp?.id?.toString(),
        suitability_data: lifeQuotationDetails.suitabilityData,
        sp_name:
          authenticatedUser.businessrole_name === 'SP'
            ? authenticatedUser.firstname?.toString()
            : selectedSp?.firstname?.toString(),
      }).unwrap()
      dispatch(updateQuotationsApiLoading(false))
      dispatch(updatePlanQuoteResponse(response))
      dispatch(updateApplicationNumber(''))
      toggleIsBiFormGenerated(false)
      if (window.location.pathname === '/quotation-page') navigate('/after-quotation-page')
    } catch (e: any) {
      dispatch(updateQuotationsApiLoading(false))
      // dispatch(updatePlanQuoteResponse(undefined))
      ToastService.showErrorToast({ title: 'Error', message: e['data']['message'] })
    }
  }

  // const onGetQuotation = async (filter?: Record<string, any>, rider?: Record<string, any>) => {
  //   onFilterDialogClose()
  //   try {
  //     dispatch(updatePlanQuoteResponse(undefined))
  //     dispatch(updateQuotationsApiLoading(true))
  //     console.log('Get Quotations')
  //     getFinalQuote()
  //   } catch (e: unknown) {
  //     // ToastService.showErrorToast({
  //     //   title: 'Something went wrong!',
  //     //   // message: error.message,
  //     // })
  //   } finally {
  //     dispatch(updateQuotationsApiLoading(false))
  //   }
  // }
  // const onProceedToProposal = async () => {
  //   dispatch(updateIsQuickBuy(false))
  //   dispatch(updateJourneyPurchaseType('End To End'))
  //   getCreateProposalStatus({
  //     planquote_id: planQuoteResponse?.plan_quote ?? '',
  //     lead_id: lead_id ?? '',
  //   })
  //     .unwrap()
  //     .then(async (resp) => {
  //       dispatch(updateProposalId(resp.proposal_id ?? ''))
  //       dispatch(updateProposalStep(0))
  //       navigate('/life/proposal')
  //       try {
  //         await updateLead(
  //           getUpdateLifeLeadPayload({ stage: 'Quotation', insurerName: 'IndiaFirst' }),
  //         ).unwrap()
  //         navigate('/life/proposal')
  //       } catch (e) {
  //         ToastService.showErrorToast({
  //           title: 'Unable to update lead!',
  //         })
  //       }
  //     })
  // }
  const [planOptionQuestionList, setPlanOptionQuestionList] = useState<INestedQuestionSet[]>()
  const [getPlanQuestionsFromApi, { isFetching: isPlanOptionQuestionListLoading }] =
    useLazyGetPlanQuestionsMasterQuery()

  useEffect(() => {
    if (lifeQuotationDetails.finalSelectedPlan && isFilterDialogOpen) {
      getPlanQuestions()
    }
  }, [lifeQuotationDetails.finalSelectedPlan, isFilterDialogOpen])

  const getPlanQuestions = async () => {
    setPlanOptionQuestionList(undefined)
    const resp = await getPlanQuestionsFromApi({
      plan: lifeQuotationDetails.finalSelectedPlan?.name ?? '',
      question_type: lifeQuotationDetails.finalSelectedPlan?.question_type,
    }).unwrap()
    setPlanOptionQuestionList(
      (resp as any)[lifeQuotationDetails.finalSelectedPlan?.question_type ?? ''],
    )
  }

  useEffect(() => {
    onFilterDialogOpen()
  }, [filterFormData])

  const onAffect = async (
    name: string,
    value: string | number,
    affect: string,
    factor?: number,
    operation?: string,
  ) => {
    if (affect.toLowerCase() === 'rider') {
      const plan_data = Object.assign({}, riderAffectKeys)
      plan_data[name] = value
      dispatch(updateRiderAffectKeys(plan_data))
      // riderForm.reset()
      const resp2 = await getRider({
        plan: selected_plan?.name ?? '',
        plan_data: plan_data,
        la_pr_equal: false,
      }).unwrap()
      dispatch(updateRiderQuestion(resp2))
    } else if (
      affect.includes('-') &&
      operation &&
      affect &&
      factor &&
      value &&
      planOptionQuestionList
    ) {
      const code = affect.split('-')[0]
      const key = affect.split('-')[1]
      if (operation === 'mul') {
        const newValue = factor * Number(value)
        const planOptionQuestionListTemp = updateKeyValueInTree(
          cloneDeep(planOptionQuestionList),
          code,
          key,
          newValue,
        )
        setPlanOptionQuestionList(planOptionQuestionListTemp)
      }
    }
  }
  const updateKeyValueInTree = (
    form_fields: INestedQuestionSet[],
    code: string,
    key: string,
    newValue: number,
  ) => {
    for (let i = 0; i < form_fields.length; i++) {
      const item = form_fields[i] as any
      if (item.code === code) {
        item[key] = newValue
        // break
      } else {
        Object.values(item.values).forEach((child: any) => {
          return updateKeyValueInTree(child, code, key, newValue)
        })
      }
    }
    return form_fields
  }

  const clearRider = async () => {
    dispatch(updateRiderQuestion([]))
    riderForm.reset()
    riderForm.handleSubmit(onRiderFormSubmit)()
    form.handleSubmit(onFormSubmit)()
  }

  // const onQuickBuy = async () => {
  //   dispatch(updateIsQuickBuy(true))
  //   dispatch(updateJourneyPurchaseType('Partial'))
  //   const resp = await getCreateProposalStatus({
  //     planquote_id: planQuoteResponse?.plan_quote ?? '',
  //     lead_id: lead_id ?? '',
  //   }).unwrap()
  //   dispatch(updateProposalId(resp.proposal_id ?? ''))
  //   await proposalSubmissionToInsurer({
  //     proposal_id: resp.proposal_id ?? '',
  //     is_partial_journey: true,
  //   }).unwrap()
  //   await getSendConsentLink({
  //     proposal_id: resp.proposal_id ?? '',
  //     stage: 'Proposal-Approval',
  //     agent_id: authenticatedUser.id?.toString() ?? '',
  //   }).unwrap()
  //   dispatch(updateProposalStep(0))
  //   navigate('/life/proposal')
  //   await updateLead(getUpdateLifeLeadPayload({ stage: 'Quotation', insurerName: 'IndiaFirst' }))
  //     .unwrap()
  //     .catch((e) => {
  //       ToastService.showErrorToast({
  //         title: 'Unable to update lead!',
  //       })
  //     })
  // }

  const toggleIsBiFormGenerated = (state: boolean) => {
    dispatch(updateIsBiFormGenerated(state))
  }

  return {
    isFilterDialogOpen,
    onFilterDialogClose,
    planOptionQuestionList,
    form,
    onFormSubmit,
    filterFormData,
    isGetQuotationsApiLoading,
    selected_plan,
    // onProceedToProposal,
    isPlanOptionQuestionListLoading,
    authenticatedUser,
    // spUserListing,
    planQuoteResponse,
    ridersQuestions,
    riderForm,
    riderFormData,
    onRiderFormSubmit,
    onAffect,
    clearRider,
    onFilterDialogOpen,
    // onQuickBuy,
    // relationship,
    // isProposalSubmissionLoading,
    // isCreateProposalLoading,
    // isGetSendConsentLoading,
    // isQuickBuy,
    isBiFormGenerated,
    toggleIsBiFormGenerated,
    // onClearRidersClick,
  }
}

export { useLifeQuotation }
