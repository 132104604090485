import { useEffect } from 'react'
import { Button } from 'react-day-picker'
import { UseFormReturn } from 'react-hook-form'
import RenderDropdown from './RenderDropdown'
import { RenderSwitch } from './RenderSwitch'
import { RenderTextInput } from './RenderTextInput'
import RenderSiblings from './RenderSiblings'
import { RenderRadioSwitch } from './RenderRadioSwitch'
import { RenderDate } from './RenderDate'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import { RenderNumericText } from './RenderNumericText'
import { RenderMultiSelect } from './RenderMultiSelect'

interface IProps {
  form: UseFormReturn<any, any>
  childField: INestedQuestionSet
  parentData: {
    name: string
    value: string | number
    cover_type?: INestedQuestionSet['cover_type']
  }
  // isDefaultSelected?: boolean
  defaultValue?: string
  initialValues?: Record<string, string | number | boolean | string[]>
  factor?: number
  operation?: string
  affect?: string
  onAffect?: (name: string, value: string | number, affect: string, factor?: number) => void
  isWithoutLabel?: boolean
  isDisabled?: boolean
  disabledCodes?: string[]
  required?: boolean
  call_api?: boolean
}

const RenderChild = (props: IProps) => {
  const {
    childField,
    form,
    parentData,
    // isDefaultSelected = false,
    defaultValue,
    initialValues,
    affect,
    onAffect,
    isWithoutLabel,
    isDisabled,
    disabledCodes,
    operation,
    required,
    call_api,
  } = props

  useEffect(() => {
    return () => {
      form.unregister(childField.code)
    }
  }, [])

  switch (childField.cover_type.toLowerCase()) {
    case 'text':
      if (childField.input_type.toLowerCase() === 'date') {
        return (
          <RenderDate
            form={form}
            isRoot={true}
            name={childField.code}
            label={childField.name}
            initialValues={initialValues}
            input_type={childField.input_type}
            isDisabled={isDisabled}
            disabledCodes={disabledCodes}
            min={childField.min}
            max={childField.max}
            operation={childField.operation}
            factor={childField.factor}
            required={childField.required}
            call_api={childField.call_api}
          />
        )
      }
      if (childField.input_type.toLowerCase() === 'numeric-text') {
        return (
          <RenderNumericText
            // isRequiredField={true}
            name={childField.code}
            label={childField.name}
            placeholder={childField.description}
            form={form}
            isRoot={false}
            parentData={parentData}
            childrenData={childField.values}
            defaultValue={defaultValue}
            min={childField.min}
            max={childField.max}
            regex={childField.regex}
            input_type={childField.input_type}
            initialValues={initialValues}
            isWithoutLabel={isWithoutLabel}
            isDisabled={isDisabled}
            disabledCodes={disabledCodes}
            hidden={childField.hidden}
            affect={childField.affect}
            onAffect={onAffect}
            operation={childField.operation}
            call_api={childField.call_api}
            required={childField.required}
          />
        )
      }
      if (childField.input_type.toLowerCase() === 'calculation') {
        return (
          <RenderSiblings
            name={childField.code}
            label={childField.name}
            dropdownItems={childField.dropdown ?? {}}
            childrenData={childField.values}
            input_type={childField.input_type}
            factor={childField.factor}
            min={childField.min}
            max={childField.max}
            form={form}
            isRoot={false}
            parentData={parentData}
            initialValues={initialValues}
            defaultValue={defaultValue}
            cover_type={childField.cover_type}
            isDisabled={isDisabled}
            disabledCodes={disabledCodes}
            affect={childField.affect}
            onAffect={onAffect}
            operation={childField.operation}
            call_api={childField.call_api}
            required={childField.required}
          />
        )
      }
      return (
        <RenderTextInput
          name={childField.code}
          label={childField.name}
          placeholder={childField.description}
          form={form}
          isRoot={false}
          parentData={parentData}
          defaultValue={defaultValue}
          min={childField.min}
          max={childField.max}
          regex={childField.regex}
          input_type={childField.input_type}
          initialValues={initialValues}
          isWithoutLabel={isWithoutLabel}
          isDisabled={isDisabled}
          disabledCodes={disabledCodes}
          hidden={childField.hidden}
          affect={childField.affect}
          onAffect={onAffect}
          call_api={childField.call_api}
          required={childField.required}
          operation={childField.operation}
        />
      )
    case 'dropdown':
      if (childField.input_type.toLowerCase() === 'calculation') {
        return (
          <RenderSiblings
            name={childField.code}
            factor={childField.factor}
            label={childField.name}
            dropdownItems={childField.dropdown ?? {}}
            childrenData={childField.values}
            form={form}
            isRoot={false}
            parentData={parentData}
            initialValues={initialValues}
            max={childField.max}
            defaultValue={defaultValue}
            cover_type={childField.cover_type}
            isDisabled={isDisabled}
            disabledCodes={disabledCodes}
            affect={childField.affect}
            onAffect={onAffect}
            operation={childField.operation}
          />
        )
      }
      if (childField.input_type.toLowerCase() === 'numeric-text') {
        return (
          <RenderNumericText
            // isRequiredField={true}
            name={childField.code}
            label={childField.name}
            placeholder={childField.description}
            form={form}
            isRoot={false}
            parentData={parentData}
            childrenData={childField.values}
            defaultValue={defaultValue}
            min={childField.min}
            max={childField.max}
            regex={childField.regex}
            input_type={childField.input_type}
            initialValues={initialValues}
            isWithoutLabel={isWithoutLabel}
            isDisabled={isDisabled}
            disabledCodes={disabledCodes}
            hidden={childField.hidden}
            affect={childField.affect}
            onAffect={onAffect}
            operation={childField.operation}
            call_api={childField.call_api}
            required={childField.required}
          />
        )
      }
      if (childField.input_type.toLowerCase() === 'multi-value') {
        return (
          <RenderMultiSelect
            dropdownItems={childField.dropdown ?? {}}
            name={childField.code}
            label={childField.name}
            childrenData={childField.values}
            form={form}
            isRoot={true}
            parentData={{
              name: 'BAAP',
              value: 'form_fields',
            }}
            defaultValue={childField.default}
            isDisabled={isDisabled}
            initialValues={initialValues}
            affect={childField.affect}
            onAffect={onAffect}
            factor={childField.factor}
            operation={childField.operation}
            disabledCodes={disabledCodes}
            required={childField.required}
            call_api={childField.call_api}
          />
        )
      }
      return (
        <RenderDropdown
          name={childField.code}
          label={childField.name}
          dropdownItems={childField.dropdown ?? {}}
          childrenData={childField.values}
          form={form}
          isRoot={false}
          parentData={parentData}
          initialValues={initialValues}
          // isDefaultSelected={isDefaultSelected}
          defaultValue={defaultValue}
          affect={childField.affect}
          onAffect={onAffect}
          operation={childField.operation}
          isWithoutLabel={isWithoutLabel}
          isDisabled={isDisabled}
          disabledCodes={disabledCodes}
          required={childField.required}
          call_api={childField.call_api}
        />
      )
    case 'switch':
      if (childField.input_type.toLowerCase() === 'radio') {
        return (
          <RenderRadioSwitch
            name={childField.code}
            label={childField.name}
            childrenData={childField.values}
            form={form}
            isRoot={false}
            parentData={parentData}
            options={Object.keys(childField.values)}
            defaultValue={defaultValue}
            initialValues={initialValues}
            isDisabled={isDisabled}
            disabledCodes={disabledCodes}
          />
        )
      }
      return (
        <RenderSwitch
          name={childField.code}
          label={childField.name}
          childrenData={childField.values}
          form={form}
          isRoot={false}
          parentData={parentData}
          defaultValue={defaultValue}
          initialValues={initialValues}
          options={childField.dropdown ?? {}}
          isDisabled={isDisabled}
          disabledCodes={disabledCodes}
        />
      )

    default:
      return <>Invalid Cover Type!</>
  }
}

export default RenderChild
