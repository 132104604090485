import {
  Center,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Radio,
  Text,
  RadioGroup,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  PinInput,
  HStack,
  Button,
  PinInputField,
  Image,
  FormControl,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react'
import successImg from 'Assets/Common/Success.webp'
import { Select as ChakraSelect } from 'chakra-react-select'
import ReactDOMServer from 'react-dom/server'

import { useAppSelector, useDeviceType } from 'app/hooks'
import { DashboardHeading, ElevatedButton, ErrorText, TextInput } from 'Core'
import { GetQuestionsListingResponse, HealthConsentFormRequest } from 'Models/ResponseModels/Health'
import { useEffect, useRef, useState } from 'react'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  useConsentCheckQuery,
  useDMSDataPushMutation,
  useHealthConsentSubmitFormPaymentLinkMutation,
  useLazyGetConsentDataQuery,
} from 'Services/API/health.api'
import { ToastService } from 'Services/toast.service'
import {
  copyToClipboard,
  getAccountTypeBasedOnIndex,
  IndianRupeeFormatFromString,
  spConsentFormQuestionsMap,
} from 'Services/general'
import { InformationCard } from 'Core/Cards/InformationCard'
import { AutoPayForm } from './AutoPayForm'
import { exportAsImage } from './exportAsImage'
import {
  useConsentSendSMSMutation,
  useSendEmailForOTPMutation,
  useSendSmsMutation,
  useVerifyOTPforConsentMutation,
} from 'Services/API/equitas.api'
import { MemberData } from 'Models/Common/Health.General'
import uuid from 'react-uuid'
import { getSelfSpouseKidsText } from '../QuotationPage/helper'

export const ConsentForm = ({ type }: { type: 'sp_consent' | 'customer_consent' }) => {
  const { isDesktop, isTablet } = useDeviceType()
  const { register, handleSubmit, formState, trigger } = useForm()
  const { consent_identifier } = useParams()

  const exportRef = useRef<HTMLDivElement>(null)

  const [callDMSAPI] = useDMSDataPushMutation()

  const [getConsentData, { currentData }] = useLazyGetConsentDataQuery()
  const { selectedSp } = useAppSelector((state) => state.spTagging)

  const [submitForm, { isLoading: submitLoading }] = useHealthConsentSubmitFormPaymentLinkMutation()
  let interval: NodeJS.Timeout
  const TIMER_LIMIT = 60
  let tempTimeLeft = TIMER_LIMIT

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  const { data: formStatusData } = useConsentCheckQuery(
    {
      consent_identifier: consent_identifier!,
      consent_type: type,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const [isLoading, setIsLoading] = useState(true)

  const [consentStage, setConsentStage] = useState(0)
  const [consentFormData, setconsentFormData] = useState<HealthConsentFormRequest>()
  const [paymentLink, setPaymentLink] = useState('')

  const [paymentMode, setPaymentMode] = useState(0) // 0 manual 1 auto debit
  const [autoPayMode, setAutoPayMode] = useState(0) // 0 manual 1 auto debit

  const [isOtpVerified, setIsOtpVerified] = useState(false)
  const [otp, setOtp] = useState('')
  // const { isProposerIncluded } = useAppSelector((state) => state.memberDetails)
  // const [proposerEmail, setProposerEmail] = useState('')

  const [sendSMS, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
    useConsentSendSMSMutation()

  const [verifyOtp] = useVerifyOTPforConsentMutation()

  const [sendOTPviaEmail] = useSendEmailForOTPMutation()

  useEffect(() => {
    if (formStatusData) {
      console.log(formStatusData, 'formStatusData')
      if (formStatusData.is_submited) {
        console.log('inside formStatus data')
        if (type === 'sp_consent') {
          console.log('consent stage is 2')
          setConsentStage(2)
          setIsLoading(false)
        } else {
          setConsentStage(1)
          setPaymentLink(formStatusData.payment_data[0].payment_url)
          setIsLoading(false)
        }
      } else {
        getConsentData(consent_identifier!)
          .unwrap()
          .then((res) => {
            setconsentFormData(res)
            // setProposerEmail(res.consent_data[0].proposer.email)
            setIsLoading(false)
          })
      }
    }
  }, [formStatusData])

  const onSubmit = async (data: any) => {
    let ifscCode = ''
    if (consentFormData?.consent_data[0].account_details?.Ifsc_code) {
      ifscCode = consentFormData?.consent_data[0].account_details?.Ifsc_code?.toString()
    }
    submitForm({
      consent_type: type,
      sp_mail: selectedSp?.email,
      sp_mobile: type === 'sp_consent' ? selectedSp?.phone : '',

      consent_identifier: consent_identifier!,
      consent_data: [
        {
          unique_identifier: consentFormData!.consent_data[0].unique_identifier!,
          consent_questions: [
            {
              question:
                'I/we confirm that ive received the Quote and have accepted the quote. I understand that the above proposal has been generated basis my acceptance of the quote',
              response: 1,
            },
            {
              question:
                'I/we confirm that ive received the Quote and have accepted the quote. I understand that the above proposal has been generated basis my acceptance of the quote',
              response: 1,
            },
            {
              question:
                'I/we confirm that I/we ve have read the Terms & Conditions and agree to the same',
              response: 1,
            },
            {
              question:
                'I/we understand and agree that by selecting the payment method as My Equitas Account, I provide my consent to debit my account for the purpose of premium amount as per the application. I also understand that by selecting Other modes of Payment, I d be taken to a Payment Gateway to make payment via any other preferred modes',
              response: 1,
            },
          ],
          payment_mode: paymentMode,
          is_auto_pay: autoPayMode,
          auto_pay_details: {
            bankName: 'Equitas Small Finance Bank',
            accountNumber:
              consentFormData?.consent_data[0].account_details.show_account_number ?? '',
            IFSCCode: ifscCode,
            MICRCode: '',
            nameOfAccountHolder:
              consentFormData?.consent_data[0].account_details.account_title ?? '',
            typeOfAccount: consentFormData?.consent_data[0].account_details.account_type ?? '',
            debitType: 'MA',
            debitPeriodFrom: new Date().toISOString().slice(0, 10), // aaj ki date
            debitPeriodTo: '',
            untilCancelled: 'Y',
          },
        },
      ],
    })
      .unwrap()
      .then(async (res) => {
        if (type === 'sp_consent') {
          console.log('consent stage is 2 in on submit')
          setConsentStage(2)
        } else {
          setPaymentLink(res[0].payment_url)
          setConsentStage(1)
        }

        const image = await exportAsImage(exportRef.current, 'test')

        callDMSAPI({
          consent_type: type,
          pdf_bytes: image,
          data: consentFormData!.consent_data.map((item) => {
            return {
              unique_identifier: item.unique_identifier,
            }
          }),
        })

        // call DMS API to save Image
      })
      .catch((error) => {
        console.log(error, 'error in catch')
        ToastService.showErrorToast({ title: 'Something Went Wrong!' })
      })
  }
  // const getSelfSpouseKidsMemberType = (addedMembersList: any): string => {
  //   let diplayText = ''
  //   if (isProposerIncluded) {
  //     diplayText = 'Self '
  //   }
  //   if (addedMembersList.includes('spouse')) {
  //     diplayText = diplayText + ' Spouse'
  //   }
  //   if (addedMembersList.includes('son') || addedMembersList.includes('daughter')) {
  //     diplayText = diplayText + ' Kid'
  //   }

  //   return diplayText
  // }
  const getMemberType = (type: string, memberList: MemberData[], isProposerIncluded: boolean) => {
    console.log(type)
    switch (type) {
      case 'selfSpouseKids':
        return getSelfSpouseKidsText(getMemberObjFromList(memberList), isProposerIncluded)
      case 'parents':
        return 'Parents'
      case 'allMembers':
        return 'All Members'
      default:
        return 'All Members'
    }
  }

  const getMemberObjFromList = (memberList: MemberData[]) => {
    const memberObj: {
      [key: string]: MemberData
    } = {}
    memberList.forEach((member) => {
      memberObj[member.code ?? uuid()] = { ...member }
    })
    return memberObj
  }

  const checkForAutoDebit = () => {
    let shouldAllowAutoDebit = true
    consentFormData?.consent_data.forEach((consentData) => {
      if (consentData.policy_details.isAutoDebitSupported === false) {
        shouldAllowAutoDebit = false
      }
    })
    return shouldAllowAutoDebit
  }

  const checkForAutoPay = () => {
    let allowAutoPay = true
    consentFormData?.consent_data.forEach((consentData) => {
      if (consentData.policy_details.isAutoPaySupported === false) {
        allowAutoPay = false
      }
    })
    return allowAutoPay
  }

  return (
    <div ref={exportRef}>
      {/* <button onClick={() => exportAsImage(exportRef.current, 'test')}>Capture Image</button> */}
      {isLoading ? (
        <Text>Loading</Text>
      ) : consentStage === 0 ? (
        <form onSubmit={handleSubmit(onSubmit)} className=' w-[90%] sm:w-[80%] mx-auto'>
          {type === 'customer_consent' ? (
            <DashboardHeading title='Insurance Application Form' />
          ) : (
            <DashboardHeading title='SP Validation Form' />
          )}

          {type === 'sp_consent' && (
            <>
              <Text mt='4' textAlign={'center'} fontWeight='bold' fontSize={'18px'}>
                SP Details
              </Text>
              <Grid
                paddingX={isDesktop ? '2rem' : '0'}
                gridTemplateColumns={isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr'}
              >
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>SP Name</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={consentFormData?.sp_name ?? ''}
                    placeholder='Enter SP Name'
                  />
                </GridItem>
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>SP ID</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={consentFormData?.sp_id ?? ''}
                    placeholder='Enter SP ID'
                  />
                </GridItem>
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>SP Branch</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={consentFormData?.branch_name ?? ''}
                    placeholder='Enter SP Branch'
                  />
                </GridItem>
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Application No</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={consentFormData?.lead_id ?? ''}
                    placeholder='Enter Application No'
                  />
                </GridItem>
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>Login Date</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={consentFormData?.login_date ?? ''}
                    placeholder='Enter Login Date'
                  />
                </GridItem>
                <GridItem marginY='4' marginX='6'>
                  <FormLabel>UCIC</FormLabel>
                  <TextInput
                    isReadOnly
                    defaultValue={consentFormData?.ucic ?? ''}
                    placeholder='Enter UCIC'
                  />
                </GridItem>
              </Grid>
            </>
          )}
          <Grid gridTemplateColumns={'1fr'}>
            {consentFormData?.consent_data.map((data, index) => {
              return (
                <GridItem key={index}>
                  <Box mt='8' key={data.unique_identifier}>
                    <InformationCard
                      id={data.policy_details.code}
                      insurerName={data.policy_details.insuranceName}
                      sumAssured={data.policy_details.sumInsured.toString()}
                      policyFor={getMemberType(
                        data.policy_details.memberType!,
                        data.members,
                        data.isProposerIncluded,
                      )}
                      pincode={data.policy_details.pincode}
                      policyForr=''
                      finalPremium={data.policy_details.premium.toString()}
                      image={data.policy_details.insurerLogo}
                      loading={false}
                    />

                    {type === 'customer_consent' && (
                      <>
                        <Text textAlign={'center'} fontWeight='bold' fontSize={'18px'} my='1'>
                          Selected Account Details
                        </Text>
                        <Grid
                          gridTemplateColumns={
                            isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr 1fr'
                          }
                          border='1px'
                          my='4'
                          p='2'
                        >
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Account Lead</FormLabel>
                              <TextInput value={data.account_details.account_lead} isReadOnly />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Account Title</FormLabel>
                              <TextInput value={data.account_details.account_title} isReadOnly />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Source Branch</FormLabel>
                              <TextInput value={data.account_details.source_branch} isReadOnly />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Opening Branch</FormLabel>
                              <TextInput value={data.account_details.opening_branch} isReadOnly />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Account Type</FormLabel>
                              <TextInput
                                value={getAccountTypeBasedOnIndex(
                                  data.account_details.account_type,
                                )}
                                isReadOnly
                              />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Account Number</FormLabel>
                              <TextInput
                                value={data.account_details.show_account_number}
                                isReadOnly
                              />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Account ID</FormLabel>
                              <TextInput value={data.account_details.account_id} isReadOnly />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>Status</FormLabel>
                              <TextInput value={data.account_details.status} isReadOnly />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>IFSC Code</FormLabel>
                              <TextInput value={data.account_details.Ifsc_code} isReadOnly />
                            </Box>
                          </GridItem>
                          <GridItem marginY='2' marginX='2'>
                            <Box m='1'>
                              <FormLabel>UCIC</FormLabel>
                              <TextInput value={data.account_details.ucic} isReadOnly />
                            </Box>
                          </GridItem>
                        </Grid>
                      </>
                    )}
                    {/* Addons Block */}
                    {
                      <Text fontWeight={'bold'} my='2'>
                        Addons
                      </Text>
                    }
                    {data.policy_details.addons.length === 0 && (
                      <Text my='6'>No Addons Selected</Text>
                    )}
                    {data.policy_details.addons &&
                      data.policy_details.addons.map((addon) => {
                        return (
                          <CheckBoxComponent
                            label={addon.name}
                            price={addon.amount}
                            value={addon.value}
                            key={addon.code}
                          />
                        )
                      })}
                    <>
                      <Text textAlign={'center'} fontWeight='bold' fontSize={'18px'} my='4'>
                        Customer Details
                      </Text>
                      <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem border='1px solid #DEDEDE'>
                          <h2>
                            <AccordionButton boxShadow='lg' textAlign='left'>
                              <Box flex='1' my='2' fontWeight={'medium'}>
                                {data.proposer.firstname +
                                  ' ' +
                                  data.proposer.lastname +
                                  ' ' +
                                  'Details'}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>

                          <AccordionPanel pb={4}>
                            <Grid
                              gridTemplateColumns={
                                isDesktop ? '1fr 1fr 1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr'
                              }
                            >
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Pan Number</FormLabel>
                                  <TextInput
                                    value={data.proposer.pan_number}
                                    isReadOnly
                                    placeholder='Enter Pan Number'
                                  />
                                </Box>
                              </GridItem>

                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Salutation</FormLabel>
                                  <TextInput
                                    value={data.proposer.salutation?.name}
                                    isReadOnly
                                    placeholder='Enter Salutation'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>First Name</FormLabel>
                                  <TextInput
                                    value={data.proposer.firstname}
                                    isReadOnly
                                    placeholder='Enter First Name'
                                  />
                                </Box>
                              </GridItem>

                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Middle Name</FormLabel>
                                  <TextInput
                                    value={data.proposer.middlename}
                                    isReadOnly
                                    placeholder='Enter Middle Name'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Last Name</FormLabel>
                                  <TextInput
                                    value={data.proposer.lastname}
                                    isReadOnly
                                    placeholder='Enter Last Name'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Email</FormLabel>
                                  <TextInput
                                    value={data.proposer.email}
                                    isReadOnly
                                    placeholder='Enter Email'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Mobile</FormLabel>
                                  <TextInput
                                    value={data.proposer.mobile}
                                    isReadOnly
                                    placeholder='Enter Mobile'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Gender</FormLabel>
                                  <TextInput
                                    value={data.proposer.gender}
                                    isReadOnly
                                    placeholder='Enter Gender'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Annual Income</FormLabel>
                                  <TextInput
                                    value={data.proposer.annual_income}
                                    isReadOnly
                                    placeholder='Enter Annual Income'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Occupation</FormLabel>
                                  <TextInput
                                    value={data.proposer.occupation?.name}
                                    isReadOnly
                                    placeholder='Enter Occupation'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Age</FormLabel>
                                  <TextInput
                                    value={data.proposer.age}
                                    isReadOnly
                                    placeholder='Enter Age'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Date Of Birth</FormLabel>
                                  <TextInput
                                    value={data.proposer.dob}
                                    isReadOnly
                                    placeholder='Enter DOB'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>BMI</FormLabel>
                                  <TextInput
                                    value={data.proposer.bmi}
                                    isReadOnly
                                    placeholder='Enter BMI'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Pincode</FormLabel>
                                  <TextInput
                                    value={data.proposer.pincode}
                                    isReadOnly
                                    placeholder='Enter Pincode'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Current Address</FormLabel>
                                  <TextInput
                                    value={data.proposer.current_address}
                                    isReadOnly
                                    placeholder='Enter Current Address'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Permanent Pincode</FormLabel>
                                  <TextInput
                                    value={data.proposer.permanent_address_pincode_map.pincode}
                                    isReadOnly
                                    placeholder='Enter Permanent Pincode'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Permanent Address</FormLabel>
                                  <TextInput
                                    value={data.proposer.permanent_address}
                                    isReadOnly
                                    placeholder='Enter Permanent Address'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Marital Status</FormLabel>
                                  <TextInput
                                    value={data.proposer.marital_status?.name}
                                    isReadOnly
                                    placeholder='Enter Marital Status'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Educational Qualification</FormLabel>
                                  <TextInput
                                    value={data.proposer.educational_qualification?.name}
                                    isReadOnly
                                    placeholder='Enter Educational Qualification'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Gender</FormLabel>
                                  <TextInput
                                    value={data.proposer.gender}
                                    isReadOnly
                                    placeholder='Enter Gender'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Height (cm.)</FormLabel>
                                  <TextInput
                                    value={data.proposer.height}
                                    isReadOnly
                                    placeholder='Enter height'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Weight (kg.) </FormLabel>
                                  <TextInput
                                    value={data.proposer.weight}
                                    isReadOnly
                                    placeholder='Enter weight'
                                  />
                                </Box>
                              </GridItem>
                              {data.proposer.aadhar_card_number && (
                                <GridItem marginY='2' marginX='2'>
                                  <Box m='1'>
                                    <FormLabel>Aadhar Card Number/ EID Number </FormLabel>
                                    <TextInput
                                      value={data.proposer.aadhar_card_number}
                                      isReadOnly
                                      placeholder='Enter Aadhar Card Number/ EID Number '
                                    />
                                  </Box>
                                </GridItem>
                              )}
                              <GridItem colSpan={isDesktop ? 4 : isTablet ? 3 : 1} my='4'>
                                <Text textAlign='center' fontWeight='bold'>
                                  Nominee Details
                                </Text>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Salutation</FormLabel>
                                  <TextInput
                                    value={data.proposer?.nominee_details?.salutation.name}
                                    isReadOnly
                                    placeholder='Enter Nominee Salutation'
                                  />
                                </Box>
                              </GridItem>

                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee First Name</FormLabel>
                                  <TextInput
                                    value={data.proposer?.nominee_details?.firstname}
                                    isReadOnly
                                    placeholder='Enter First Name'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Last Name</FormLabel>
                                  <TextInput
                                    value={data.proposer?.nominee_details?.lastname}
                                    isReadOnly
                                    placeholder='Enter Last Name'
                                  />
                                </Box>
                              </GridItem>

                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Gender</FormLabel>
                                  <TextInput
                                    value={data.proposer.nominee_details?.gender}
                                    isReadOnly
                                    placeholder='Enter Gender'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Relationship</FormLabel>
                                  <TextInput
                                    value={data.proposer.nominee_details?.relationship?.name}
                                    isReadOnly
                                    placeholder='Enter Relationship'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Occupation</FormLabel>
                                  <TextInput
                                    value={data.proposer.nominee_details?.occupation?.name}
                                    isReadOnly
                                    placeholder='Enter Occupation'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Mobile</FormLabel>
                                  <TextInput
                                    value={data.proposer.nominee_details?.mobile}
                                    isReadOnly
                                    placeholder='Enter Mobile'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Address</FormLabel>
                                  <TextInput
                                    value={data.proposer.nominee_details?.address}
                                    isReadOnly
                                    placeholder='Enter Address'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Dob</FormLabel>
                                  <TextInput
                                    value={data.proposer.nominee_details?.dob}
                                    isReadOnly
                                    placeholder='Enter Dob'
                                  />
                                </Box>
                              </GridItem>
                              <GridItem marginY='2' marginX='2'>
                                <Box m='1'>
                                  <FormLabel>Nominee Marital Status</FormLabel>
                                  <TextInput
                                    value={data.proposer.nominee_details?.marital_status?.name}
                                    isReadOnly
                                    placeholder='Enter Marital Status'
                                  />
                                </Box>
                              </GridItem>
                            </Grid>
                            <Box p='4'>
                              {
                                <Text fontWeight={'bold'} textAlign='center' my='3'>
                                  Medical Questions
                                </Text>
                              }
                              <>
                                <Text my='2'>
                                  Does any insured member have a pre-existing disease?
                                </Text>
                                {data.proposer.medical_questions.length === 0 ? 'No' : 'Yes'}
                              </>
                              <RecursiveContainer questions={data.proposer.medical_questions} />
                              {
                                <Text fontWeight={'bold'} textAlign='center' my='3'>
                                  Lifestyle Questions
                                </Text>
                              }

                              <Text my='2'>
                                Does any insured member Smoke/Consume Alcohol/Tobacco?
                              </Text>
                              {data.proposer.lifestyle_questions.length === 0 ? 'No' : 'Yes'}
                              <RecursiveContainer questions={data.proposer.lifestyle_questions} />
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>

                        {data.members.map((customer, index) => {
                          return (
                            <>
                              <AccordionItem
                                key={index}
                                my='4'
                                border='1px solid #DEDEDE'
                                backgroundColor={'white'}
                              >
                                <h2>
                                  <AccordionButton boxShadow='lg' textAlign='left'>
                                    <Box flex='1' my='2' fontWeight={'medium'}>
                                      {customer.salutation.name +
                                        ' ' +
                                        customer.firstname +
                                        ' ' +
                                        customer.middlename +
                                        ' ' +
                                        customer.lastname +
                                        ' ' +
                                        'Details'}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>

                                <AccordionPanel pb={4}>
                                  <Grid
                                    gridTemplateColumns={
                                      isDesktop
                                        ? '1fr 1fr 1fr 1fr'
                                        : isTablet
                                        ? '1fr 1fr 1fr'
                                        : '1fr'
                                    }
                                  >
                                    {customer.pan_number && (
                                      <GridItem marginY='2' marginX='2'>
                                        <Box m='1'>
                                          <FormLabel>Pan Number</FormLabel>
                                          <TextInput
                                            value={customer.pan_number}
                                            isReadOnly
                                            placeholder='Enter Pan Number'
                                          />
                                        </Box>
                                      </GridItem>
                                    )}
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Salutation</FormLabel>
                                        <TextInput
                                          value={customer.salutation.name}
                                          isReadOnly
                                          placeholder='Enter Salutation'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>First Name</FormLabel>
                                        <TextInput
                                          value={customer.firstname}
                                          isReadOnly
                                          placeholder='Enter First Name'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Middle Name</FormLabel>
                                        <TextInput
                                          value={customer.middlename}
                                          isReadOnly
                                          placeholder='Enter Middle Name'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Last Name</FormLabel>
                                        <TextInput
                                          value={customer.lastname}
                                          isReadOnly
                                          placeholder='Enter Last Name'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Email</FormLabel>
                                        <TextInput
                                          value={customer.email}
                                          isReadOnly
                                          placeholder='Enter Email'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Mobile</FormLabel>
                                        <TextInput
                                          value={customer.mobile}
                                          isReadOnly
                                          placeholder='Enter Mobile'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Gender</FormLabel>
                                        <TextInput
                                          value={customer.gender}
                                          isReadOnly
                                          placeholder='Enter Gender'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Annual Income</FormLabel>
                                        <TextInput
                                          value={customer.annual_income}
                                          isReadOnly
                                          placeholder='Enter Annual Income'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Occupation</FormLabel>
                                        <TextInput
                                          value={customer.occupation.name}
                                          isReadOnly
                                          placeholder='Enter Occupation'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Age</FormLabel>
                                        <TextInput
                                          value={customer.age}
                                          isReadOnly
                                          placeholder='Enter Age'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>BMI</FormLabel>
                                        <TextInput
                                          value={customer.bmi}
                                          isReadOnly
                                          placeholder='Enter BMI'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Pincode</FormLabel>
                                        <TextInput
                                          value={customer.pincode}
                                          isReadOnly
                                          placeholder='Enter Pincode'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Current Address</FormLabel>
                                        <TextInput
                                          value={customer.current_address}
                                          isReadOnly
                                          placeholder='Enter Current Address'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Permanent Address</FormLabel>
                                        <TextInput
                                          value={customer.permanent_address}
                                          isReadOnly
                                          placeholder='Enter Permanent Address'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Permanent Pincode</FormLabel>
                                        <TextInput
                                          value={customer.permanent_address_pincode_map.pincode}
                                          isReadOnly
                                          placeholder='Enter Permanent Pincode'
                                        />
                                      </Box>
                                    </GridItem>

                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Marital Status</FormLabel>
                                        <TextInput
                                          value={customer.marital_status.name}
                                          isReadOnly
                                          placeholder='Enter Marital Status'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Educational Qualification</FormLabel>
                                        <TextInput
                                          value={customer.educational_qualification.name}
                                          isReadOnly
                                          placeholder='Enter Educational Qualification'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Gender</FormLabel>
                                        <TextInput
                                          value={customer.gender}
                                          isReadOnly
                                          placeholder='Enter Gender'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Height (cm.)</FormLabel>
                                        <TextInput
                                          value={customer.height}
                                          isReadOnly
                                          placeholder='Enter height'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Weight (kg.) </FormLabel>
                                        <TextInput
                                          value={customer.weight}
                                          isReadOnly
                                          placeholder='Enter weight'
                                        />
                                      </Box>
                                    </GridItem>
                                    {customer.aadhar_card_number && (
                                      <GridItem marginY='2' marginX='2'>
                                        <Box m='1'>
                                          <FormLabel>Aadhar Card Number/ EID Number </FormLabel>
                                          <TextInput
                                            value={customer.aadhar_card_number}
                                            isReadOnly
                                            placeholder='Enter Aadhar Card Number/ EID Number '
                                          />
                                        </Box>
                                      </GridItem>
                                    )}
                                    <GridItem colSpan={isDesktop ? 4 : isTablet ? 3 : 1} my='4'>
                                      <Text textAlign='center' fontWeight='bold'>
                                        Nominee Details
                                      </Text>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Salutation</FormLabel>
                                        <TextInput
                                          value={customer?.nominee_details?.salutation.name}
                                          isReadOnly
                                          placeholder='Enter Nominee Salutation'
                                        />
                                      </Box>
                                    </GridItem>

                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee First Name</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.firstname}
                                          isReadOnly
                                          placeholder='Enter First Name'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Last Name</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.lastname}
                                          isReadOnly
                                          placeholder='Enter Last Name'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Middle Name</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.middlename}
                                          isReadOnly
                                          placeholder='Enter Middle Name'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Gender</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.gender}
                                          isReadOnly
                                          placeholder='Enter Gender'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Relationship</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.relationship.name}
                                          isReadOnly
                                          placeholder='Enter Relationship'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Occupation</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.occupation.name}
                                          isReadOnly
                                          placeholder='Enter Occupation'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Mobile</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.mobile}
                                          isReadOnly
                                          placeholder='Enter Mobile'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Address</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.address}
                                          isReadOnly
                                          placeholder='Enter Address'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Dob</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.dob}
                                          isReadOnly
                                          placeholder='Enter Dob'
                                        />
                                      </Box>
                                    </GridItem>
                                    <GridItem marginY='2' marginX='2'>
                                      <Box m='1'>
                                        <FormLabel>Nominee Marital Status</FormLabel>
                                        <TextInput
                                          value={customer.nominee_details.marital_status.name}
                                          isReadOnly
                                          placeholder='Enter Marital Status'
                                        />
                                      </Box>
                                    </GridItem>
                                  </Grid>
                                  <Box p='4'>
                                    {
                                      <Text fontWeight={'bold'} textAlign='center' my='3'>
                                        Medical Questions
                                      </Text>
                                    }
                                    <>
                                      <Text my='2'>
                                        Does any insured member have a pre-existing disease?
                                      </Text>
                                      {customer.medical_questions.length === 0 ? 'No' : 'Yes'}
                                    </>
                                    <RecursiveContainer questions={customer.medical_questions} />
                                    {
                                      <Text fontWeight={'bold'} textAlign='center' my='3'>
                                        Lifestyle Questions
                                      </Text>
                                    }

                                    <>
                                      <Text my='2'>
                                        Does any insured member Smoke/Consume Alcohol/Tobacco?
                                      </Text>
                                      {customer.lifestyle_questions.length === 0 ? 'No' : 'Yes'}
                                    </>

                                    <RecursiveContainer questions={customer.lifestyle_questions} />
                                  </Box>
                                </AccordionPanel>
                              </AccordionItem>
                            </>
                          )
                        })}
                      </Accordion>
                    </>
                  </Box>
                </GridItem>
              )
            })}
          </Grid>

          {type === 'customer_consent' ? (
            <Flex gap='1rem' direction='column' marginY='2' marginX='2'>
              <Checkbox {...register('q1', { required: true })} value='true'>
                I/we confirm that i&apos;ve received the Quote and have accepted the quote. I
                understand that the above proposal has been generated basis my acceptance of the
                quote
                {formState.errors.q1 && formState.errors.q1?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q2', { required: true })} value='true'>
                I/we agree to the details mentioned in the above proposal form & that I&apos;ve
                selected this product based on my requirement. I understand that Equitas Small
                Finance Bank Limited merely acts on a non-risk participation basis and the
                underlying risk shall be underwritten by the respective insurer. The purchase of
                this insurance products is purely voluntary, and is not linked to availing any other
                facility from the bank. I confirm that I&apos;ve read the sales brochure and
                understood the risk factors. I hereby confirm that the details provided above are
                true to the best of my knowledge
                {formState.errors.q2 && formState.errors.q2?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q3', { required: true })} value='true'>
                I/we confirm that I/we&apos;ve have read the{' '}
                <a
                  href='http://www.equitasbank.com/Insurance_termsandconditions'
                  target='_blank'
                  rel='noreferrer'
                  className=' underline'
                >
                  Terms & Conditions
                </a>{' '}
                and agree to the same
                {formState.errors.q3 && formState.errors.q3?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q4', { required: true })} value='true'>
                I/we understand and agree that by selecting the payment method as &quot;My Equitas
                Account&quot;, I provide my consent to debit my account for the purpose of premium
                amount as per the application. I also understand that by selecting &quot;Other modes
                of Payment&quot;, I&apos;d be taken to a Payment Gateway to make payment via any
                other preferred modes
                {formState.errors.q4 && formState.errors.q4?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q5', { required: true })} value='true'>
                I/We hereby confirm that I/we have an active relationship with Equitas Small Finance
                Bank Limited with a valid customer ID. I/we confirm that the details furnished above
                are true to the best of my knowledge. I/we also understand that any
                misrepresentation of data or facts in the above request form could lead to
                termination of the policy or rejection of claim.
                {formState.errors.q5 && formState.errors.q5?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>

              {type === 'customer_consent' && checkForAutoDebit() && (
                <>
                  <Flex direction='column' marginY='2'>
                    <Text fontWeight={'bold'} fontSize={'md'}>
                      Select the Payment Method
                    </Text>
                    <RadioGroup
                      onChange={(selectedItem) => {
                        console.log(selectedItem)
                        setPaymentMode(parseInt(selectedItem))
                      }}
                      value={paymentMode.toString()}
                    >
                      <Radio value='1' marginX='0.7rem'>
                        My Equitas Account
                      </Radio>
                      <Radio value='0' marginX='0.7rem'>
                        Other payment options
                      </Radio>
                    </RadioGroup>
                  </Flex>
                </>
              )}
            </Flex>
          ) : (
            <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
              <Checkbox {...register('q1', { required: true })} value='true'>
                {spConsentFormQuestionsMap.q1}
                {formState.errors.q1 && formState.errors.q1?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q2', { required: true })} value='true'>
                {spConsentFormQuestionsMap.q2}
                {formState.errors.q2 && formState.errors.q2?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q3', { required: true })} value='true'>
                {spConsentFormQuestionsMap.q3}
                {formState.errors.q3 && formState.errors.q3?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
            </Flex>
          )}
          {/* condition to be applied for auto pay flag  */}
          {type === 'customer_consent' && checkForAutoPay() && checkForAutoDebit() && (
            <Flex direction='column' marginY='2' marginX='2' my='4'>
              <Text fontWeight={'bold'} fontSize={'md'}>
                Select the Autopay Mode
              </Text>
              <RadioGroup
                onChange={(selectedItem) => {
                  console.log(selectedItem, 'selectedItem')
                  setAutoPayMode(parseInt(selectedItem))
                }}
                value={autoPayMode.toString()}
              >
                <Radio value='1' marginX='0.7rem'>
                  My Equitas Account
                </Radio>
                <Radio value='0' marginX='0.7rem'>
                  I don&apos;t want to register for Autopay
                </Radio>
              </RadioGroup>
            </Flex>
          )}

          {type === 'customer_consent' && autoPayMode === 1 && (
            <>
              <AutoPayForm
                applicationNo={consent_identifier ?? ''}
                name={consentFormData?.consent_data[0].account_details.account_title ?? ''}
                planName={consentFormData?.consent_data[0].policy_details.insuranceName ?? ''}
                premium={consentFormData?.consent_data[0].policy_details.premium.toString() ?? ''}
                debitDate={new Date().toISOString().slice(0, 10)}
                debitMonth={(new Date().getMonth() + 1).toString()}
                bankAccountNumber={
                  consentFormData?.consent_data[0].account_details.show_account_number ?? ''
                }
                accountType={consentFormData?.consent_data[0].account_details.account_type ?? ''}
                bankName={'Equitas Small Finance Bank'}
                frequency={'Annual'}
                mandateValidityFrom={new Date().toISOString().slice(0, 10)}
                mandateValidityTo={'Until Cancelled'}
                debitType={'MA'}
                typeofRegistration={'CREATE'}
                maximumPremiumThatCanBeDebited={(
                  (consentFormData?.consent_data[0]?.policy_details?.premium ?? 0) * 1.2
                ).toString()}
              />
            </>
          )}
          {type === 'customer_consent' && autoPayMode === 1 && (
            <Flex gap='1rem' direction='column' marginY='2' marginX='2'>
              <Checkbox {...register('q5', { required: true })} value='true'>
                I hereby authorize Equitas Small Finance Bank/(
                {consentFormData?.consent_data
                  .map((data) => {
                    return data.policy_details.insurerName
                  })
                  .join(',')}
                ) to debit my account as mentioned above for all future premiums for the said
                application.
                {formState.errors.q5 && formState.errors.q5?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q6', { required: true })} value='true'>
                I provide my consent to debit premium from my account as per my policy
                {formState.errors.q6 && formState.errors.q6?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
              <Checkbox {...register('q7', { required: true })} value='true'>
                I understand that in case of any tax change by the governing authorities and a
                subsequent revision in premium as an outcome of the same, the revised premium could
                be debited subject to a maximum of the amount mentioned in the field &quot;Maximum
                Premium that can be debited&quot;. No additional consent is required if the revised
                premium is within this limit.
                {formState.errors.q7 && formState.errors.q7?.type === 'required' && (
                  <ErrorText text='This field is required' />
                )}
              </Checkbox>
            </Flex>
          )}

          {!isOtpVerified && (
            <Center flexDirection='column'>
              {!isOtpSent && (
                <ElevatedButton
                  onClick={async () => {
                    const isFormValid = await trigger()
                    if (isFormValid) {
                      sendSMS({
                        message_type: type === 'sp_consent' ? 'sp_otp' : 'customer_otp',
                        lead_uid: consentFormData?.lead_id ?? '',
                        phone_number:
                          type === 'sp_consent'
                            ? consentFormData?.sp_mobile
                              ? '91' + consentFormData?.sp_mobile.slice(-10)
                              : ''
                            : consentFormData?.consent_data[0].proposer.mobile
                            ? '91' + consentFormData?.consent_data[0].proposer.mobile.slice(-10)
                            : '',
                        //  phone_number: '919899004640',
                        unique_identifier: consentFormData!.consent_data[0].unique_identifier!,
                      })
                        .unwrap()
                        .then((res) => {
                          console.log(res, 'res')
                          ToastService.showSuccessTaost({
                            title: 'Success',
                            message:res.data,
                          })
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                      sendOTPviaEmail({
                        message_type: type === 'sp_consent' ? 'sp_otp' : 'customer_otp',
                        reciever_mail:
                          type === 'sp_consent' ? consentFormData?.sp_mail : consentFormData?.consent_data[0].proposer.email,
                        unique_identifier: consentFormData!.consent_data[0].unique_identifier!,
                        lead_uid: consentFormData?.lead_id ?? '',
                      })
                      .unwrap()
                        .then((res) => {
                          console.log(res, 'res')
                          ToastService.showSuccessTaost({
                            title: 'Success',
                            message:res.data,
                          })
                        })
                        .catch((err) => {
                          console.log(err)
                        })

                      startTimer()
                    }
                  }}
                  isLoading={isSendOtpLoading}
                  isDisabled={isSendOtpLoading}
                  marginY='1rem'
                >
                  Send OTP
                </ElevatedButton>
              )}
              {isOtpSent && (
                <Flex
                  paddingY='1rem'
                  direction='column'
                  alignItems='center'
                  marginY='4'
                  marginX='6'
                >
                  <FormLabel>Enter OTP</FormLabel>
                  <HStack>
                    <PinInput
                      onChange={(str) => {
                        setOtp(str)
                      }}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </Flex>
              )}
              {isOtpSent && (
                <Flex marginY='0.7rem'>
                  <Button
                    isDisabled={otp.length !== 6}
                    data-html2canvas-ignore
                    onClick={() => {
                      verifyOtp({
                        phone_no:
                          type === 'sp_consent'
                            ? consentFormData?.sp_mobile
                              ? '91' + consentFormData?.sp_mobile.slice(-10)
                              : ''
                            : consentFormData?.consent_data[0].proposer.mobile
                            ? '91' + consentFormData?.consent_data[0].proposer.mobile.slice(-10)
                            : '',
                        //  phone_no:919899004640,
                        otp: otp,
                        unique_identifier: consentFormData!.consent_data[0].unique_identifier!,
                      })
                        .unwrap()
                        .then((res) => {
                          console.log(res)
                          setIsOtpVerified(true)
                          ToastService.showSuccessTaost({
                            title: 'OTP Verified successfully',
                          })
                        })
                        .catch((e) => {
                          ToastService.showErrorToast({
                            title: e['data']['data']['error_text'],
                          })
                        })
                    }}
                    marginX='0.5rem'
                  >
                    Verify OTP
                  </Button>
                  <Button
                    disabled={!resendOtp}
                    data-html2canvas-ignore
                    // onClick={async () => {
                    //   await sendOtp({
                    //     email: 'rohan.gupta@zopper.com',
                    //   })
                    // }}
                    onClick={() => {
                      sendSMS({
                        message_type: type === 'sp_consent' ? 'sp_otp' : 'customer_otp',
                        phone_number: '91' + consentFormData?.sp_mobile,
                        //  phone_number:'919899004640',
                        unique_identifier: consentFormData!.consent_data[0].unique_identifier!,
                        lead_uid: consentFormData?.lead_id ?? '',
                      })
                        .unwrap()
                        .then((res) => {
                          console.log(res)
                          ToastService.showSuccessTaost({
                            title: 'Success',
                            message: `OTP sent successfully to ${consentFormData?.sp_mobile} and ${consentFormData?.sp_mail}`,
                          })
                        })
                        .catch((error) => {
                          console.log(error)
                          ToastService.showErrorToast({ title: 'Something went Wrong!' })
                        })
                      sendOTPviaEmail({
                        message_type: type === 'sp_consent' ? 'sp_otp' : 'customer_otp',
                        reciever_mail: consentFormData?.sp_mail,
                        unique_identifier: consentFormData!.consent_data[0].unique_identifier!,
                        lead_uid: consentFormData?.lead_id ?? '',
                      })

                      setResendOtp(false)
                      startTimer()
                    }}
                    marginX='0.5rem'
                  >
                    {resendOtp ? 'Resend OTP' : `Resend OTP in ${timeLeft}`}
                  </Button>
                </Flex>
              )}
            </Center>
          )}
          {isOtpVerified && (
            <Center padding='1.5rem 0.5rem '>
              <ElevatedButton isLoading={submitLoading} type='submit' data-html2canvas-ignore>
                Submit
              </ElevatedButton>
            </Center>
          )}
        </form>
      ) : consentStage === 1 ? (
        <>
          <DashboardHeading title='Customer Consent for Policy Payment' />
          <Text textAlign={'center'} m='4'>
            Consent form has been successfully submitted and payment link has been generated! You
            can proceed with the payment.
          </Text>
          <Flex alignItems={'center'} justifyContent={'center'}>
            <ElevatedButton
              onClick={() => {
                copyToClipboard(paymentLink)
              }}
              size='sm'
              marginX='0.5rem'
              marginY='0.8rem'
            >
              Copy Form Link
            </ElevatedButton>
            <ElevatedButton
              onClick={() => {
                window.open(paymentLink)
              }}
              size='sm'
              marginX='0.5rem'
              marginY='0.8rem'
            >
              Proceed To Pay
            </ElevatedButton>
          </Flex>
        </>
      ) : (
        <Box>
          <Flex alignItems={'center'} justifyContent={'center'} direction={'column'}>
            <Image width='15rem' src={successImg} my='5' />
            <Text
              fontSize={'2xl'}
              fontWeight={'bold'}
              my='5'
              textAlign={'center'}
              paddingX={isDesktop ? '' : '2px'}
            >
              Thank You for filling the Consent Form
            </Text>
          </Flex>
        </Box>
      )}
    </div>
  )
}

const RecursiveContainer = ({ questions }: { questions: GetQuestionsListingResponse[] }) => {
  const builder = (question: GetQuestionsListingResponse) => {
    switch (question.type) {
      case 'text':
        return (
          <>
            <Text>{question.main_question}</Text>
            <TextInput value={question.response} isReadOnly />
          </>
        )
      case 'date':
        return (
          <>
            <Text>{question.main_question}</Text>

            <TextInput value={question.response} isReadOnly />
          </>
        )
      case 'bool':
        return (
          <>
            <Text>{question.main_question}</Text>

            <RadioGroup value={question.value}>
              <Stack direction='row'>
                <Radio value='1'>Yes</Radio>
                <Radio value='0'>No</Radio>
              </Stack>
            </RadioGroup>
          </>
        )
      case 'dropdown':
        return (
          <>
            <Text>{question.main_question}</Text>
            <TextInput value={question.response} isReadOnly />
          </>
        )
      case 'knockout':
        return (
          <>
            <Checkbox value={question.response} key={question.code}>
              <Text fontSize='sm'>{question.main_question}</Text>
            </Checkbox>
          </>
        )
      // case 'multi-select':
      //   return (
      //     <>
      //       <Text>{question.main_question}</Text>
      //       <ChakraSelect
      //         isMulti
      //         options={question.value.map((value: any) => {
      //           return {
      //             label: value.toString(),
      //             value: value.toString(),
      //           }
      //         })}
      //       />
      //     </>
      //   )
      default:
        return <Text>Unsupported field</Text>
    }
  }

  return (
    <>
      {questions.map((question, index) => {
        return (
          <Box key={question.code} py='2'>
            <>
              {builder(question)}

              {question.sub_ques.length > 0 && question.response === '1' && (
                <Box ml='6' key={index}>
                  <RecursiveContainer questions={question.sub_ques || []} />
                </Box>
              )}
            </>
          </Box>
        )
      })}
    </>
  )
}

const CheckBoxComponent = ({
  label,
  price,
  value,
}: {
  label: string
  price: string
  value: string
}) => (
  <>
    <Flex justifyContent={'space-between'} py='6' w='100%'>
      <Checkbox isChecked={true} colorScheme='primary'>
        <span className=' w-[200px]'>{label}</span>
      </Checkbox>
      <Flex gap='2'>
        {value && (
          <Text border={'1px'} pr='2'>
            {value}
          </Text>
        )}
        {price != '-2' && <Text my='auto'>₹{IndianRupeeFormatFromString(price)}/-</Text>}
        {price == '-2' && <Text my='auto'>Included</Text>}
        {price == '-1' && <Text my='auto'>NA</Text>}
      </Flex>
    </Flex>
  </>
)
