import { Flex, Box, Divider } from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'
import { CardData } from '../CardData'
import { Form } from '../Form'

export const CashlessModalDesktop = () => {
  const { isDesktop } = useDeviceType()
  return (
    <>
      <Flex fontSize='16px' gap='2'>
        <Form />

        <Box height='lg'>
          <Divider orientation='vertical' borderColor='#D8D8D8' />
        </Box>
        <Box>
          <Box
            fontSize='md'
            fontWeight='bold'
            paddingY='4'
            boxShadow={isDesktop ? '' : 'lg'}
            borderBottom='1px'
          >
            Cashless Hospitals
          </Box>
          <CardData />
        </Box>
      </Flex>
    </>
  )
}
