import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useAppSelector, useDeviceType } from 'app/hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetPincodeListingQuery, useLazyGetPincodeListingQuery } from 'Services/API/health.api'
import { AsyncSelect, Select, SingleValue } from 'chakra-react-select'
import { IHealthPincode, IHealthPincodeOption } from 'Models/ResponseModels/Health'
import { onlyAllowTypingNumbers, pincodeLabelFormatterHealth } from 'Services/general'
import { ToastService } from 'Services/toast.service'

export const MemberPincode = ({
  onSubmit,
}: {
  onSubmit: ({
    selfPincode,
    parentsPincode,
  }: {
    selfPincode: IHealthPincode
    parentsPincode: IHealthPincode
  }) => void
}) => {
  const {
    isParentsIncluded,
    selfPincode,
    parentsPincode: parentsPincodeState,
    proposer,
  } = useAppSelector((state) => state.memberDetails)
  const { isDesktop } = useDeviceType()

  const [proposerPincode, setProposerPincode] = useState<IHealthPincode | undefined>(selfPincode)

  const [parentsPincode, setParentsPincode] = useState<IHealthPincode | undefined>(
    parentsPincodeState,
  )
  const equitasDetails = useAppSelector((state) => state.customerDetails)

  console.log(parentsPincodeState, selfPincode, 'check this')

  const [invalidPincodeSelf, setInvalidPincodeSelf] = useState(false)
  const [invalidPincodeParent, setInvalidPincodeParent] = useState(false)

  const [getPincode] = useLazyGetPincodeListingQuery()
  const navigate = useNavigate()

  useEffect(() => {
    console.log(proposer.pincode, 'proposer.pincode------------')
    if (proposer.pincode.length === 6) {
      getPincode({
        pincode: proposer.pincode,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes && pincodes.length) {
          const selected = {
            ...pincodes[0],
            label: pincodes[0].pincode.toString(),
          }
          setProposerPincode({
            ...selected,
          })
        }
      })
    }
  }, [proposer.pincode])

  const SubmitHandler = () => {
    if (proposerPincode?.pincode.toString() === '') {
      ToastService.showErrorToast({
        title: 'Please select your pincode',
      })
      return
    }
    if (isParentsIncluded) {
      if (parentsPincode?.pincode.toString() === '') {
        ToastService.showErrorToast({
          title: 'Please select your parents pincode',
        })
        return
      }
    }
    onSubmit({
      parentsPincode: parentsPincode!,
      selfPincode: proposerPincode!,
    })
    navigate('/health/quotes')
  }

  return (
    <>
      <Box>
        <Box p='8' textAlign={`${isDesktop ? 'left' : 'center'}`}>
          <Text fontSize='sm'>
            Health insurance premium might differ based on your location of residence
          </Text>
          <Box mt='4'>
            {isDesktop ? (
              <Stack gap={6}>
                <NumberInput
                  type='self'
                  isInvalid={invalidPincodeSelf}
                  value={proposerPincode}
                  onChange={setProposerPincode}
                  labelText='Your Pincode'
                  disablePincode={equitasDetails.fieldsFetchedFromCbsMapping.customerPincode}
                />
                {isParentsIncluded && (
                  <NumberInput
                    type='parents'
                    isInvalid={invalidPincodeParent}
                    value={parentsPincode}
                    onChange={setParentsPincode}
                    labelText='Your Parents Pincode'
                    disablePincode={false}
                  />
                )}
              </Stack>
            ) : (
              <Center>
                <Flex direction='column' w='full' gap={6}>
                  <NumberInput
                    type='self'
                    isInvalid={invalidPincodeSelf}
                    value={proposerPincode}
                    onChange={setProposerPincode}
                    labelText='Your Pincode'
                    disablePincode={equitasDetails.fieldsFetchedFromCbsMapping.customerPincode}
                  />
                  {isParentsIncluded && (
                    <NumberInput
                      type='parents'
                      isInvalid={invalidPincodeParent}
                      value={parentsPincode}
                      onChange={setParentsPincode}
                      labelText='Your Parents Pincode'
                      disablePincode={false}
                    />
                  )}
                </Flex>
              </Center>
            )}
            <Center mt='6'>
              <Button
                onClick={() => SubmitHandler()}
                size='lg'
                variant='solid'
                rounded='full'
                paddingX='14'
                paddingY='2'
              >
                Next
              </Button>
            </Center>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const NumberInput = ({
  labelText,
  value,
  onChange,
  isInvalid,
  type,
  disablePincode,
}: {
  labelText: string
  value: IHealthPincode | undefined
  type: 'self' | 'parents'
  onChange: React.Dispatch<React.SetStateAction<IHealthPincode | undefined>>
  isInvalid: boolean
  disablePincode: boolean
}) => {
  const { isDesktop } = useDeviceType()
  const [pincodeInput, setPincodeInput] = useState('')
  const [getPincode] = useLazyGetPincodeListingQuery()

  const loadPincodeOptions = (
    inputSearchValue: string,
    callback: (options: IHealthPincodeOption[]) => void,
  ) => {
    if (inputSearchValue.length > 2 && inputSearchValue.length < 7) {
      getPincode({
        pincode: inputSearchValue,
      }).then((resp) => {
        const pincodes = resp.data?.result
        if (pincodes) callback(pincodeLabelFormatterHealth(pincodes))
      })
    }
  }

  const handlePincodeSelectChange = (selectedPincode: SingleValue<IHealthPincode>) => {
    if (selectedPincode?.pincode) {
      const selected = {
        ...selectedPincode,
        label: selectedPincode.pincode.toString(),
      }
      onChange(selected)
      // setValue('pincodeArea', selected)
      // clearErrors('pincodeArea')
      // dispatch(
      //   updateProposalDetails({
      //     pincodeArea: selected,
      //   }),
      // )
    }
  }

  return (
    <>
      <Box>
        <Text fontWeight='medium' fontSize='base'>
          {labelText}
        </Text>
        <Box mt='2'>
          <AsyncSelect
            // {...register('pincodeArea', { required: true })}
            className={isDesktop ? 'w-1/2' : 'w-full'}
            placeholder='Select Pincode'
            isDisabled={disablePincode}
            loadOptions={loadPincodeOptions}
            onInputChange={(input) => {
              if (input.length < 7) setPincodeInput(input)
            }}
            inputValue={pincodeInput}
            onChange={handlePincodeSelectChange}
            onKeyDown={onlyAllowTypingNumbers}
            value={value}
            defaultInputValue={value?.pincode.toString()}
            // inputValue={pincodeValue?.pincode.toString() ?? 'Select Pincode'}
          />
        </Box>
      </Box>
    </>
  )
}
