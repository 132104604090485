import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Checkbox,
  Text,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Spinner,
  Select,
  Box,
  Container,
  Divider,
  Center,
} from '@chakra-ui/react'
import { useGetRelationshipsQuery } from 'Services/API/motor.api'

import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import {
  INomineeFormState,
  setIsSameAddressNominee,
  setNomineeFormDetails,
  setPaymentDetails,
} from 'features/Sachets/sachetData.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  useAddCustomerMutation,
  useCreateConsentMutation,
  useLazyGetConsentQuery,
  useLazyGetPaymentStatusQuery,
} from 'Services/API/sachet.api'
import {
  getAccountTypeBasedOnIndex,
  onlyAllowTypingAlphabet,
  onlyAllowTypingAlphabetNumericAndSpace,
} from 'Services/general'
import { getAccountStatus } from 'Constants'
import { ToastService } from 'Services/toast.service'
import { SachetPaymentSuccessDialog } from 'Core/SachetPaymentSuccess'
import { SachetPaymentFailureDialog } from 'Core/SachetPaymentFailure'
import { useSpTaggingSendOtpMutation } from 'Services/API/authentication.api'
import { SachetConsentModal } from 'Core/Modal/SachetConsentModal'

export const isMoreThan18 = (dob: Date) => {
  const today = new Date()
  const yr = today.getFullYear() - dob.getFullYear()
  const month = today.getMonth() - dob.getMonth()
  const day = today.getDate() - dob.getDate()

  if (yr < 18) {
    return false
  }

  if (yr > 18) {
    return true
  }

  if (yr === 18) {
    if (month < 0) {
      return false
    }

    if (month === 0 && day < 0) {
      return false
    }

    return true
  }

  return false
}

export const calcAge18 = () => {
  const today = new Date()
  const yr = today.getFullYear() - 18
  const month = today.getMonth()
  const date = today.getDate()
  const finalDate = new Date(yr, month, date)

  return finalDate
}

const NomineeSchema = yup.object().shape({
  nomineeFullName: yup.string().required("Nominee's Name is required").max(100),
  nomineeRelationship: yup.string().required("Nominee's relationship is required"),
  nomineeDob: yup.date().required("Nominee's Date of Birth is required"),
  nomineeaddress1: yup.string().required("Nominee's Address Line 1 is required").max(100),
  nomineeaddress2: yup.string().max(100),
  nomineeGender: yup.string().required("Nominee's Gender is required"),
  nomineeSalutation: yup.string().required("Nominee's Salutation is required"),
  isNomineeAMinorChecked: yup.boolean(),
  appointeeFullName: yup.string().when('isNomineeAMinorChecked', {
    is: true,
    then: yup.string().required('Appointee name is required'),
  }),
  appointeeAddress: yup.string().when('isNomineeAMinorChecked', {
    is: true,
    then: yup.string().required('Appointee address is required'),
  }),
  appointeeRelationship: yup.string().when('isNomineeAMinorChecked', {
    is: true,
    then: yup.string().required('Appointee relationship is required'),
  }),
})

// const AppointeeSchema = yup.object().shape({
//   isNomineeAMinorChecked: yup.boolean(),
//   appointeeFullName: yup
//     .string()
//     .optional()
//     .when('isNomineeAMinorchecked', {
//       is: true,
//       then: yup.string().required('Appointee name is required'),
//     }),
//   appointeeAddress: yup
//     .string()
//     .optional()
//     .when('isNomineeAMinorchecked', {
//       is: true,
//       then: yup.string().required('Appointee address is required'),
//     }),
//   appointeeRelationship: yup
//     .string()
//     .optional()
//     .when('isNomineeAMinorchecked', {
//       is: true,
//       then: yup.string().required('Appointee relationship is required'),
//     }),
// })

export interface IPaymentStatus {
  id: number
  transaction_number: string
  member_id: string
  sp_id: number
  insurance: string
  transaction_date: string
  amount: string
  status: string
  mode_of_payment: string
  quotation: number
  masterPolicyNumber?: string
  commission_status?: string | undefined
}

const SachetsNomineeForm = ({
  cancerCoverType,
  setIndex,
  getVariant,
  ApplicantAddress,
  fullCustomerDetails,
  isProceed,
  setFormAccordianState,
}: {
  cancerCoverType?: string
  setIndex: any
  getVariant: boolean
  ApplicantAddress: { address1: string; address2: string; address3: string }
  selectedAccount: any
  otherCustomerDetails: any
  fullCustomerDetails: any
  setIsProceed: any
  isProceed: any
  setFormAccordianState: any
}) => {
  const [ShowAppointeeForm, setShowAppointeeForm] = useState(false)

  const { data: relationshipResponse, isLoading: isRelationshipListingFetching } =
    useGetRelationshipsQuery()

  const [relationshipListing, setRelationshipListing] = useState<string[]>([])
  const appointeeRelationshipListing = ['Brother', 'Sister', 'Son', 'Daughter']
  // const [appointeeRelationshipListing, setAppointeeRelationshipListing] = useState<string[]>([])

  useEffect(() => {
    if (relationshipResponse) {
      // const newRelationshipList = relationshipResponse.map(
      //   (relationship: string) => relationship != 'Other',
      // )
      setRelationshipListing(Object.values(relationshipResponse))
    }
  }, [relationshipResponse])

  const [addCustomerMutationFunc, { isLoading: isAddCustomerLoading }] = useAddCustomerMutation()
  const [createConsentMutationFunc, { isLoading: isCreateConsentLoading }] =
    useCreateConsentMutation()

  const [pollingIntervalVal, setPollingIntervalVal] = useState(2000)
  const [paymentPollingIntervalVal, setPaymentPollingIntervalVal] = useState(2000)

  const [getconsent, { currentData: data, isFetching, isLoading }] = useLazyGetConsentQuery({
    pollingInterval: pollingIntervalVal,
  })

  const [paymentStatusData, setPaymentStatusData] = useState<IPaymentStatus>({
    id: 0,
    transaction_number: '',
    member_id: '',
    sp_id: 0,
    insurance: '',
    transaction_date: '',
    amount: '',
    status: '',
    mode_of_payment: '',
    quotation: 0,
    masterPolicyNumber: '',
  })
  const [paymentStatus, setPaymentStatus] = useState('pending')

  const [
    getPaymentStatus,
    {
      currentData: paymentData,
      isFetching: isFetchingPaymentStatus,
      isLoading: isLoadingPaymentStatus,
    },
  ] = useLazyGetPaymentStatusQuery({
    pollingInterval: paymentPollingIntervalVal,
  })

  const { isOpen, onOpen, onClose } = useDisclosure()
  const customerProposerDetails = useAppSelector((state) => state.customerDetails)

  const [nomineeAddress, setNomineeAddress] = useState({
    Naddress1: '',
    Naddress2: '',
    Naddress3: '',
  })
  const getPaymentStatusFunc = async () => {
    const resp: any = await getPaymentStatus({
      quote_id: quoteIdVal,
    })

    if (resp?.data?.status == 'Success' || resp?.data?.status == 'Failed') {
      setPaymentPollingIntervalVal(0)
      // setPaymentStatus(resp?.data?.status)

      // setPaymentStatusData({
      //   ...resp?.data,
      //   insurance: selectedProductDetails?.insurer_name,
      //   masterPolicyNumber: applicantDetails?.policyNumber,
      // })
      // setPaymentStatus(resp?.data?.status.toLowerCase())
    }
    setPaymentStatus(resp?.data?.status)

    dispatch(setPaymentDetails(resp?.data))
  }

  const [getConsentLoading, setGetConsentLoading] = useState(false)
  useEffect(() => {
    if (data?.status === 'APPROVED' || data?.status === 'INVALID') {
      setPollingIntervalVal(0)
      try {
        getPaymentStatusFunc()
      } catch (error) {
        ToastService.showErrorToast({
          title: 'Failed to get payment status',
          message: 'Failed to get payment status. Please try again later.',
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (paymentData?.status === 'Failed' || paymentData?.status === 'Success') {
      setPaymentPollingIntervalVal(0)
      setPaymentStatus(paymentData?.status)
      // setPaymentStatusData(paymentData)

      setPaymentStatusData({
        ...paymentData,
        insurance: selectedProductDetails?.insurer_name,
        masterPolicyNumber: applicantDetails?.policyNumber,
      })
    }
  }, [paymentData])

  useEffect(() => {
    if (data?.status === 'APPROVED' || data?.status === 'INVALID') {
      setPollingIntervalVal(0)
    }
  }, [data])

  const applicantDetails = useAppSelector((state) => state.sachetDataDetails.applicantFormDetails)
  const nomineeDetails = useAppSelector((state) => state.sachetDataDetails.nomineeFormDetails)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<INomineeFormState>({
    resolver: yupResolver(NomineeSchema),
  })

  const dispatch = useAppDispatch()

  const [formData, setFormData] = useState({
    nomineeFullName: '',
    nomineeRelationship: '',
    nomineeDob: '',
    nomineeaddress1: '',
    nomineeaddress2: '',
    nomineeaddress3: '',
    nomineeGender: '',
    nomineeSalutation: '',
    appointeeFullName: '',
    appointeeRelationship: '',
    appointeeAddress: '',
    isNomineeAMinorChecked: false,
  })

  let interval: NodeJS.Timeout

  const TIMER_LIMIT = 60
  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)

  const [openConsentModal, setOpenConsentModal] = useState(true)

  const quoteIdVal = useAppSelector((state) => state.sachetDataDetails.quoteId)
  const [smsSentStatus, setSmsSentStatus] = useState('')

  const selectedProductDetails = useAppSelector(
    (state) => state.sachetDataDetails.selectedProductData,
  )

  const onProceed = () => {
    dispatch(setIsSameAddressNominee(isCheckboxchecked))
    if (cancerCoverType == '2') {
      setIndex(3)
    } else {
      setIndex(2)
    }
  }

  const selectedAccountDetails = useAppSelector((state) => state.customerDetails)

  const branchName =
    selectedAccountDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp &&
    selectedAccountDetails?.proposerDetailsFromCBS?.IndividualCustomerDetails?.IndCustDtlsRp[0]
      ?.brnchNm

  const onSubmit: SubmitHandler<any> = async (data) => {
    setGetConsentLoading(true)
    if (isProceed == false) {
      return ToastService.showErrorToast({
        title: 'Please Fill the Applicant Form before Proceeding!',
      })
    }

    const customer = {
      first_name: applicantDetails?.firstName || '-',
      middle_name: applicantDetails?.middleName || '',
      last_name: applicantDetails?.lastName || '-',
      dob: applicantDetails?.dob || '-',
      salutation: applicantDetails?.salutation,
      gender: applicantDetails?.gender || '-',
      pincode: applicantDetails?.pinCode || '-',
      email: fullCustomerDetails?.email || applicantDetails?.email || '-',
      address: applicantDetails?.address1 || '-',
      address_two: applicantDetails?.address2 || '',
      address_three: applicantDetails?.address3 || '-',
      // marital_status:applicantDetails?.mari
      pan_number: applicantDetails?.pan || '',

      aadhar: applicantDetails?.aadhaar || '-',
      // aadhar: (fullCustomerDetails && fullCustomerDetails?.aadhaar) || '-',
      contact_number: applicantDetails?.contactNumber || '-',
      occupation: applicantDetails?.occupation || '-',
      ifsc_code:
        (fullCustomerDetails?.accounts && fullCustomerDetails?.accounts[0]?.ifsc_code) || '-',
      district: applicantDetails?.district || '-',
      city: applicantDetails?.city || '-',
      state: applicantDetails?.state || '-',
      nationality: 'Indian',
    }

    const nominee = {
      name: formData?.nomineeFullName || '-',
      dob: formData?.nomineeDob || '-',
      salutation: formData?.nomineeSalutation || '-',
      gender: formData?.nomineeGender || '-',
      relationship: formData?.nomineeRelationship || '-',
      address_line_1: formData?.nomineeaddress1 || '-',
      address_line_2: formData?.nomineeaddress2 || '-',
      address_line_3: formData?.nomineeaddress3 || '-',
      // appointee_name: formData?.appointeeFullName || '',
      // appointee_relation: formData?.appointeeRelationship || '',
      // appointee_address: formData?.appointeeAddress || '',
      appointee_name: formData?.appointeeFullName || '',
      appointee_relation: formData?.appointeeRelationship || '',
      appointee_address: formData?.appointeeAddress || '',

      pincode: isCheckboxchecked === true ? applicantDetails?.pinCode : '',
      state: isCheckboxchecked == true ? applicantDetails?.state : '-',
      city: isCheckboxchecked == true ? applicantDetails?.city : '-',
      district: isCheckboxchecked == true ? applicantDetails?.district : '-',
    }

    const customerArrObjData = [
      { label: 'Salutation', value: applicantDetails?.salutation || '-' },

      { label: 'First Name', value: applicantDetails?.firstName || '-' },
      { label: 'Middle Name', value: applicantDetails?.middleName || '' },
      { label: 'Last Name', value: applicantDetails?.lastName || '-' },
      { label: 'Relationship', value: applicantDetails?.relationship || '-' },
      { label: 'Date of Birth', value: applicantDetails?.dob || '-' },
      {
        label: 'Gender',
        value:
          applicantDetails?.gender == 'm'
            ? 'Male'
            : applicantDetails?.gender == 'f'
            ? 'Female'
            : '-',
      },
      { label: 'Email', value: fullCustomerDetails?.email || applicantDetails?.email || '-' },
      { label: 'Occupation', value: applicantDetails?.occupation || '-' },
      // {
      //   label: 'IFSC Code',
      //   value:
      //     (fullCustomerDetails?.accounts && fullCustomerDetails?.accounts[0]?.ifsc_code) || '-',
      // },
      // { label: 'IFSC Code', value: applicantDetails?.ifscCode || '-' },

      {
        label: 'Address Line 1',
        value: applicantDetails?.address1 || '-',
      },
      {
        label: 'Address Line 2',
        value: applicantDetails?.address2 || '' || '-',
      },
      {
        label: 'Address Line 3',
        value: applicantDetails?.address3 || '' || '-',
      },

      { label: 'City', value: applicantDetails?.city || '-' },
      { label: 'District', value: applicantDetails?.district || '-' },
      // { label: 'District', value: applicantDetails?.district || '-' },
      { label: 'State', value: applicantDetails?.state || '-' },
      { label: 'Pincode', value: applicantDetails?.pinCode || '-' },
      // { label: 'Aadhar', value: applicantDetails?.aadhaar || '-' },
      { label: 'Master Policy Number', value: applicantDetails?.policyNumber || '-' },
      { label: 'Phone Number', value: applicantDetails?.contactNumber || '-' },
    ]

    const nomineeArrObjData = [
      {
        label: 'Salutation',
        value: formData?.nomineeSalutation || '-',
      },
      { label: 'Name', value: formData?.nomineeFullName || '-' },
      { label: 'Date of Birth', value: formData?.nomineeDob || '-' },

      {
        label: 'Gender',
        value:
          formData?.nomineeGender == 'm' ? 'Male' : formData?.nomineeGender == 'f' ? 'Female' : '-',
      },
      { label: 'Relationship', value: formData?.nomineeRelationship || '-' },
      {
        label: 'Address Line 1',
        value: formData?.nomineeaddress1 || '-',
      },
      {
        label: 'Address Line 2',
        value: formData?.nomineeaddress2 || '' || '-',
      },
      {
        label: 'Address Line 3',
        value: formData?.nomineeaddress3 || '' || '-',
      },
    ]
    const appointeeArrObjData = [
      { label: 'Name', value: formData?.appointeeFullName || '-' },

      { label: 'Relationship', value: formData?.appointeeRelationship || '-' },
      {
        label: 'Address',
        value: formData?.appointeeAddress || '-',
      },
    ]

    const selectedProductArrObjData = {
      name: selectedProductDetails?.name || '-',
      insurer_logo: selectedProductDetails?.insurer_logo || '-',
      insurer_name: selectedProductDetails?.insurer_name || '-',
      premium: selectedProductDetails?.premium || '-',
      sum_assured: selectedProductDetails?.sum_assured || '-',
      code: selectedProductDetails?.code || '-',
    }

    const selectedAccount = selectedAccountDetails?.selectedAccount
    try {
      const res: any = await addCustomerMutationFunc({
        param: quoteIdVal,
        data: {
          customer,
          nominee,
          master_policy_number: applicantDetails?.policyNumber,
          account_details: {
            ...selectedAccount,
            brnchNm: branchName,
            cstId: fullCustomerDetails?.ucic,
            dbtrNm: selectedAccountDetails?.selectedAccount?.account_title,
            brnchAddr: '',
          },
        },
      })

      // if (res?.message == 'Customer is already linked to this quotation.') {
      //   ToastService.showInfoToast({
      //     title: 'Customer Already Linked',
      //   })
      //   setPollingIntervalVal(0)
      // }

      const sameAddress = {
        address_line_1: applicantDetails?.address1,
        address_line_2: applicantDetails?.address2,
        address_line_3: applicantDetails?.address3,
        city: applicantDetails?.city,
        state: applicantDetails?.state,
        pincode: applicantDetails?.pinCode,
        district: applicantDetails?.district,
      }
      dispatch(setIsSameAddressNominee(isCheckboxchecked))
      const createConsentResp: any = await createConsentMutationFunc({
        ucic_id: customerProposerDetails?.ucic || '',
        quote_id: quoteIdVal,
        // phone_number: '7982931119',
        phone_number: fullCustomerDetails?.phone.slice(2),
        // email: 'dhruv.varshney@zopper.com',
        email: fullCustomerDetails?.email || applicantDetails?.email,
        ui_data: {
          customerAccountDetails: [
            {
              label: 'Account Lead',
              value: customerProposerDetails.selectedAccount?.account_lead ?? '-',
            },
            {
              label: 'Account Title',
              value: customerProposerDetails.selectedAccount?.account_title ?? '-',
            },
            {
              label: 'Source Branch',
              value: customerProposerDetails.selectedAccount?.source_branch ?? '-',
            },
            {
              label: 'Opening Branch',
              value: customerProposerDetails.selectedAccount?.opening_branch ?? '-',
            },
            {
              label: 'Account Type',
              value: getAccountTypeBasedOnIndex(
                customerProposerDetails.selectedAccount?.account_type ?? '-',
              ),
            },
            {
              label: 'Account Number',
              value: customerProposerDetails.selectedAccount?.show_account_number ?? '-',
            },
            {
              label: 'Account ID',
              value: customerProposerDetails.selectedAccount?.account_id ?? '-',
            },
            {
              label: 'Account Status',
              value: getAccountStatus(customerProposerDetails.selectedAccount?.accStsCode ?? ''),
            },
            {
              label: 'UCIC ID',
              value: customerProposerDetails?.ucic ?? '-',
            },
            // {
            //   label: 'IFSC Code',
            //   value: customerProposerDetails.selectedAccount?.ifsc_code ?? '-',
            // },
          ],
          customerArrObjData,
          isNomineeAMinor: formData.isNomineeAMinorChecked,
          appointeeArrObjData,
          nomineeArrObjData,
          selectedProductArrObjData,
          masterPolicyNumber: applicantDetails ? applicantDetails?.policyNumber : '-',
          isSameAddressNominee: isCheckboxchecked,
          isSameAddressSpouse: false,
          sameAddress,
          phoneNumber: fullCustomerDetails?.phone.slice(2),
          email: fullCustomerDetails?.email || applicantDetails?.email,
        },
      })

      if (createConsentResp?.error?.data?.status == 400) {
        setSmsSentStatus('Failed')
        setOpenConsentModal(false)
        ToastService.showErrorToast({
          title: 'Failed to create consent',
          message: createConsentResp?.error?.data?.message,
        })
      } else {
        setSmsSentStatus('Success')
        setPollingIntervalVal(2000)
      }

      const consentData = await getconsent({
        quote_id: quoteIdVal!,
      }).unwrap()
      if (consentData?.status == 404) {
        setOpenConsentModal(false)
        ToastService.showErrorToast({
          title: consentData?.message,
        })
      }

      setGetConsentLoading(false)
    } catch (error: any) {
      setOpenConsentModal(false)
      ToastService.showErrorToast({
        title: 'Failed to create consent or add Customer',
        message: error.message,
      })
    }

    onOpen()
  }

  const fetchedData = {
    nomineeFullName: '',
    nomineeRelationship: '',
    nomineeDob: '',
    nomineeaddress1: '',
    nomineeaddress2: '',
    nomineeaddress3: '',
    nomineeGender: '',
    nomineeSalutation: '',
    appointeeFullName: '',
    appointeeAddress: '',
    appointeeRelationship: '',
    isNomineeAMinorChecked: false,
  }

  useEffect(() => {
    Object.keys(fetchedData).forEach((key) => {
      if (fetchedData[key as keyof INomineeFormState]) {
        setValue(key as keyof INomineeFormState, fetchedData[key as keyof INomineeFormState])
      }
    })
  }, [])
  const { proposalDetails: proposalDetailsInStore } = useAppSelector((state) => state.proposalPage)

  const [selectedRelationship, setSelectedRelationship] = useState<
    | {
        label: string
        value: string
      }
    | undefined
  >(
    proposalDetailsInStore.nomineeRelationship
      ? {
          label: proposalDetailsInStore.nomineeRelationship,
          value: proposalDetailsInStore.nomineeRelationship,
        }
      : {
          label: 'Select Relationship',
          value: '',
        },
  )

  useEffect(() => {
    if (timeLeft <= 0) {
      setTimeLeft(0)
      return
    }
  }, [timeLeft])

  const maxNomineeAge = () => {
    const currentYear = new Date().getFullYear() - 99
    const newDate = new Date().getDate()
    const newMonth = new Date().getMonth()

    const updatedDate = new Date(currentYear, newMonth, newDate)

    return updatedDate
  }

  const [isCheckboxchecked, setIsCheckboxchecked] = useState(false)
  const [resendLink, setResendLink] = useState(false)

  let tempTimeLeft = TIMER_LIMIT
  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendLink(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  const handleResendLink = async () => {
    getPaymentStatusFunc()

    try {
      const customerArrObjData = [
        { label: 'Salutation', value: applicantDetails?.salutation || '-' },

        { label: 'First Name', value: applicantDetails?.firstName || '-' },
        { label: 'Middle Name', value: applicantDetails?.middleName || '' },
        { label: 'Last Name', value: applicantDetails?.lastName || '-' },
        { label: 'Date of Birth', value: applicantDetails?.dob || '-' },
        {
          label: 'Gender',
          value:
            applicantDetails?.gender == 'm'
              ? 'Male'
              : applicantDetails?.gender == 'f'
              ? 'Female'
              : '-',
        },

        { label: 'Email', value: fullCustomerDetails?.email || applicantDetails?.email || '-' },
        {
          label: 'Address Line 1',
          value: applicantDetails?.address1 || '-',
        },
        {
          label: 'Address Line 2',
          value: applicantDetails?.address2 || '' || '-',
        },
        {
          label: 'Address Line 3',
          value: applicantDetails?.address3 || '' || '-',
        },
        { label: 'City', value: applicantDetails?.city || '-' },
        { label: 'District', value: applicantDetails?.district || '-' },
        // { label: 'District', value: applicantDetails?.district || '-' },
        { label: 'State', value: applicantDetails?.state || '-' },
        { label: 'Pincode', value: applicantDetails?.pinCode || '-' },
        // { label: 'Aadhar', value: applicantDetails?.aadhaar || '-' },
        { label: 'Master Policy Number', value: applicantDetails?.policyNumber || '-' },
        { label: 'Relationship', value: applicantDetails?.relationship || '-' },
      ]

      const nomineeArrObjData = [
        {
          label: 'Salutation',
          value: formData?.nomineeSalutation || '-',
        },
        { label: 'Name', value: formData?.nomineeFullName || '-' },
        { label: 'Date of Birth', value: formData?.nomineeDob || '-' },

        {
          label: 'Gender',
          value:
            formData?.nomineeGender == 'm'
              ? 'Male'
              : formData?.nomineeGender == 'f'
              ? 'Female'
              : '-',
        },
        { label: 'Relationship', value: formData?.nomineeRelationship || '-' },
        {
          label: 'Address Line 1',
          value: formData?.nomineeaddress1 || '-',
        },
        {
          label: 'Address Line 2',
          value: formData?.nomineeaddress2 || '' || '-',
        },
        {
          label: 'Address Line 3',
          value: formData?.nomineeaddress3 || '' || '-',
        },
      ]

      const selectedProductArrObjData = {
        name: selectedProductDetails?.name || '-',
        insurer_logo: selectedProductDetails?.insurer_logo || '-',
        insurer_name: selectedProductDetails?.insurer_name || '-',
        premium: selectedProductDetails?.premium || '-',
        sum_assured: selectedProductDetails?.sum_assured || '-',
        code: selectedProductDetails?.code || '-',
      }
      const sameAddress = {
        address_line_1: applicantDetails?.address1,
        address_line_2: applicantDetails?.address2,
        address_line_3: applicantDetails?.address3,
        city: applicantDetails?.city,
        district: applicantDetails?.district,
        state: applicantDetails?.state,
        pincode: applicantDetails?.pinCode,
      }

      dispatch(setIsSameAddressNominee(isCheckboxchecked))

      const createConsentResp: any = await createConsentMutationFunc({
        ucic_id: customerProposerDetails?.ucic || '',

        quote_id: quoteIdVal,
        // phone_number: '7982931119',
        phone_number: fullCustomerDetails?.phone.slice(2),
        // email: 'dhruv.varshney@zopper.com',
        email: fullCustomerDetails?.email || applicantDetails?.email,
        ui_data: {
          customerAccountDetails: [
            {
              label: 'Account Lead',
              value: customerProposerDetails.selectedAccount?.account_lead ?? '-',
            },
            {
              label: 'Account Title',
              value: customerProposerDetails.selectedAccount?.account_title ?? '-',
            },
            {
              label: 'Source Branch',
              value: customerProposerDetails.selectedAccount?.source_branch ?? '-',
            },
            {
              label: 'Opening Branch',
              value: customerProposerDetails.selectedAccount?.opening_branch ?? '-',
            },
            {
              label: 'Account Type',
              value: getAccountTypeBasedOnIndex(
                customerProposerDetails.selectedAccount?.account_type ?? '-',
              ),
            },
            {
              label: 'Account Number',
              value: customerProposerDetails.selectedAccount?.show_account_number ?? '-',
            },
            {
              label: 'Account ID',
              value: customerProposerDetails.selectedAccount?.account_id ?? '-',
            },
            {
              label: 'Account Status',
              value: getAccountStatus(customerProposerDetails.selectedAccount?.accStsCode ?? ''),
            },
            {
              label: 'UCIC ID',
              value: customerProposerDetails?.ucic ?? '-',
            },
            // {
            //   label: 'IFSC Code',
            //   value: customerProposerDetails.selectedAccount?.ifsc_code ?? '-',
            // },
          ],
          customerArrObjData,
          isNomineeAMinor: formData.isNomineeAMinorChecked,
          nomineeArrObjData,
          selectedProductArrObjData,
          masterPolicyNumber: applicantDetails ? applicantDetails?.policyNumber : '-',
          isSameAddressNominee: isCheckboxchecked,
          isSameAddressSpouse: false,
          sameAddress,
          phoneNumber: fullCustomerDetails?.phone.slice(2),
          email: fullCustomerDetails?.email || applicantDetails?.email,
        },
      })
      setTimeLeft(TIMER_LIMIT)
      startTimer()
      setResendLink(false)

      setPollingIntervalVal(2000)

      const consentData = await getconsent({
        quote_id: quoteIdVal!,
      }).unwrap()

      if (consentData && consentData?.status == 'APPROVED') {
        setPollingIntervalVal(0)
        try {
          setPaymentPollingIntervalVal(2000)
          getPaymentStatusFunc()
        } catch (error) {
          ToastService.showErrorToast({
            title: 'Failed to get payment status',
            message: 'Failed to get payment status. Please try again later.',
          })
        }
      }
    } catch (error) {
      ToastService.showErrorToast({
        title: 'Failed to Resend Link',
        message: 'Failed to Resend Link. Please try again later.',
      })
    }
  }

  return (
    <>
      {openConsentModal &&
        smsSentStatus == 'Success' &&
        paymentStatus !== 'Success' &&
        paymentStatus !== 'Failed' && (
          <SachetConsentModal
            isCheckboxcheckedNominee={isCheckboxchecked}
            isCheckboxcheckedSpouse={false}
            fullCustomerDetails={fullCustomerDetails}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            timerLimit={TIMER_LIMIT}
            dataStatus={data?.status}
            ModalText={
              data?.status === 'UNAPPROVED'
                ? 'Waiting for customer to accept the consent'
                : data?.status === 'APPROVED'
                ? 'Waiting for customer to do the payment'
                : ''
            }
            paymentStatus={paymentStatus}
          />
        )}

      {paymentStatus === 'Success' && (
        <Modal
          isCentered
          isOpen={paymentStatus === 'Success'}
          onClose={() => console.log('On Close')}
        >
          <ModalOverlay />
          <ModalContent>
            <SachetPaymentSuccessDialog backHome={true} paymentStatusData={paymentStatusData} />
          </ModalContent>
        </Modal>
      )}

      {paymentStatus === 'Failed' && (
        <Modal
          isCentered
          isOpen={paymentStatus === 'Failed'}
          onClose={() => console.log('On Close')}
        >
          <ModalOverlay />
          <ModalContent>
            <SachetPaymentFailureDialog
              backHome={true}
              paymentStatusData={paymentStatusData}
              email={applicantDetails?.email || ''}
              handleResendLink={handleResendLink}
              paymentData={paymentData}
            />
          </ModalContent>
        </Modal>
      )}

      <Flex direction='column' justifyContent='center' alignItems='center' gap='7'>
        <Grid templateColumns='repeat(3, 1fr)' gap={6} w='full'>
          <GridItem>
            <FormControl isRequired>
              <FormLabel>Nominee Full Name</FormLabel>

              <Flex>
                <FormControl
                  w='45%'
                  isRequired
                  isInvalid={!formData?.nomineeSalutation && !!errors.nomineeSalutation}
                >
                  <Select
                    disabled={formData?.nomineeGender == '' ? true : false}
                    p='0'
                    {...register('nomineeSalutation')}
                    placeholder='Select Salutation'
                    value={formData?.nomineeSalutation}
                    onChange={(e) => {
                      setFormData((formData) => {
                        return {
                          ...formData,
                          nomineeSalutation: formData?.nomineeGender == 'm' ? 'Mr' : e.target.value,
                        }
                      })

                      setValue('nomineeSalutation', e.target.value)
                    }}
                  >
                    <option value='Mr' hidden={formData?.nomineeGender == 'f'}>
                      Mr
                    </option>
                    <option value='Mrs' hidden={formData?.nomineeGender == 'm'}>
                      Mrs
                    </option>
                    <option value='Ms' hidden={formData?.nomineeGender == 'm'}>
                      Ms
                    </option>
                  </Select>
                  {!formData?.nomineeSalutation && (
                    <FormErrorMessage>{errors.nomineeSalutation?.message}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  w='full'
                  isRequired
                  isInvalid={!formData?.nomineeFullName && !!errors.nomineeFullName}
                >
                  <Input
                    maxLength={100}
                    onKeyDown={onlyAllowTypingAlphabet}
                    placeholder="Enter nominee's full name"
                    {...register('nomineeFullName')}
                    value={formData?.nomineeFullName}
                    onChange={(e) => {
                      setFormData((formData) => {
                        return {
                          ...formData,
                          nomineeFullName: e.target.value.trim().length > 0 ? e.target.value : '',
                        }
                      })
                      setValue(
                        'nomineeFullName',
                        e.target.value.trim().length > 0 ? e.target.value : '',
                      )
                    }}
                  />
                  {!formData?.nomineeFullName && (
                    <FormErrorMessage color='red.500'>
                      {errors.nomineeFullName?.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isRequired isInvalid={!formData?.nomineeGender && !!errors.nomineeGender}>
              <FormLabel>Gender</FormLabel>
              <Select
                {...register('nomineeGender')}
                placeholder='Select gender'
                value={formData?.nomineeGender}
                onChange={(e) => {
                  setFormData((formData) => {
                    return { ...formData, nomineeGender: e.target.value }
                  })

                  setValue('nomineeSalutation', '')
                  setFormData((formData) => {
                    return {
                      ...formData,
                      nomineeSalutation: '',
                    }
                  })
                }}
              >
                <option value='m'>Male</option>
                <option value='f'>Female</option>
              </Select>
              {!formData?.nomineeGender && (
                <FormErrorMessage color='red.500'>{errors.nomineeGender?.message}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl
              isRequired
              isInvalid={!formData?.nomineeaddress1 && !!errors.nomineeaddress1}
            >
              <FormLabel>Address Line 1</FormLabel>
              <Input
                disabled={isCheckboxchecked}
                maxLength={99}
                placeholder="Enter nominee's address"
                onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
                {...register('nomineeaddress1')}
                onChange={(e) => {
                  {
                    isCheckboxchecked == true
                      ? setNomineeAddress((prev) => {
                          return { ...prev, Naddress1: ApplicantAddress.address1 }
                        })
                      : setNomineeAddress((prev) => {
                          return {
                            ...prev,
                            Naddress1: e.target.value.trim().length > 0 ? e.target.value : '',
                          }
                        })
                  }
                  {
                    isCheckboxchecked == true
                      ? setFormData((formData) => {
                          return { ...formData, nomineeaddress1: ApplicantAddress.address1 }
                        })
                      : setFormData((formData) => {
                          return {
                            ...formData,
                            nomineeaddress1: e.target.value.trim().length > 0 ? e.target.value : '',
                          }
                        })
                  }
                }}
                value={
                  isCheckboxchecked == true
                    ? ApplicantAddress?.address1
                    : nomineeAddress?.Naddress1
                    ? nomineeAddress?.Naddress1
                    : formData?.nomineeaddress1 || ''
                }
              />
              {!formData?.nomineeaddress1 && (
                <FormErrorMessage>{errors.nomineeaddress1?.message}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!formData?.nomineeaddress2 && !!errors.nomineeaddress2}>
              <FormLabel>Address Line 2</FormLabel>
              <Input
                disabled={isCheckboxchecked}
                maxLength={100}
                placeholder="Enter nominee's address"
                onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
                {...register('nomineeaddress2')}
                onChange={(e) => {
                  setNomineeAddress((prev) => {
                    return {
                      ...prev,
                      Naddress2: e.target.value.trim().length > 0 ? e.target.value : '',
                    }
                  })

                  setFormData((formData) => {
                    return {
                      ...formData,
                      nomineeaddress2: e.target.value.trim().length > 0 ? e.target.value : '',
                    }
                  })
                }}
                value={
                  isCheckboxchecked == true ? ApplicantAddress?.address2 : nomineeAddress?.Naddress2
                }
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!formData?.nomineeaddress3 && !!errors.nomineeaddress3}>
              <FormLabel>Address Line 3</FormLabel>
              <Input
                disabled={isCheckboxchecked}
                maxLength={100}
                placeholder="Enter nominee's address"
                onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
                {...register('nomineeaddress3')}
                onChange={(e) => {
                  setNomineeAddress((prev) => {
                    return {
                      ...prev,
                      Naddress3: e.target.value.trim().length > 0 ? e.target.value : '',
                    }
                  })

                  setFormData((formData) => {
                    return {
                      ...formData,
                      nomineeaddress3: e.target.value.trim().length > 0 ? e.target.value : '',
                    }
                  })
                }}
                value={
                  isCheckboxchecked == true ? ApplicantAddress?.address3 : nomineeAddress?.Naddress3
                }
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isRequired isInvalid={!formData?.nomineeDob && !!errors.nomineeDob}>
              <FormLabel>Date of Birth</FormLabel>
              <Input
                // max={calcAge18().toISOString().split('T')[0]}
                max={new Date().toISOString().split('T')[0]}
                min={maxNomineeAge().toISOString().split('T')[0]}
                placeholder="Select nominee's date of birth"
                type='date'
                {...register('nomineeDob')}
                onChange={(e) => {
                  setFormData((formData: any) => {
                    if (e.target.value)
                      return {
                        ...formData,
                        nomineeDob: e.target.value,
                      }
                  })
                  if (!isMoreThan18(new Date(e.target.value))) {
                    setFormData((formData: any) => {
                      return { ...formData, isNomineeAMinorChecked: true }
                    })
                    setValue('isNomineeAMinorChecked', true)
                    setShowAppointeeForm(true)
                  } else {
                    // const enteredDate = new Date(e.target.value)
                    // const today = new Date()
                    // if (today.getFullYear() < enteredDate.getFullYear()) {
                    //   ToastService.showErrorToast({
                    //     title:
                    //       'Nominee DOB Year should be less than or equal to : ' +
                    //       today.getFullYear(),
                    //   })
                    // }
                    setFormData((formData: any) => {
                      return { ...formData, isNomineeAMinorChecked: false, nomineeRelationship: '' }
                    })
                    setValue('isNomineeAMinorChecked', false)
                    setValue('nomineeRelationship', '')
                    setShowAppointeeForm(false)
                  }
                }}
              />
              {!formData?.nomineeDob && (
                <FormErrorMessage color='red.500'>Date of Birth is required</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl
              isRequired
              isInvalid={!formData?.nomineeRelationship && !!errors.nomineeRelationship}
            >
              <FormLabel>Relationship</FormLabel>

              <Select
                {...register('nomineeRelationship')}
                onChange={(e) => {
                  setFormData((formData) => {
                    return { ...formData, nomineeRelationship: e.target.value }
                  })
                }}
                value={formData?.nomineeRelationship}
                placeholder='Select Relationship'
              >
                {formData?.isNomineeAMinorChecked
                  ? appointeeRelationshipListing.map((elem) => {
                      if (elem != 'Other') {
                        return <option key={elem}>{elem}</option>
                      }
                    })
                  : relationshipListing.map((elem) => {
                      if (elem != 'Other') {
                        return <option key={elem}>{elem}</option>
                      }
                    })}
              </Select>
              {!formData?.nomineeRelationship && (
                <FormErrorMessage>{errors.nomineeRelationship?.message}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          {formData.isNomineeAMinorChecked && (
            <GridItem>
              <FormControl
                isRequired
                isInvalid={!formData?.appointeeFullName && !!errors.appointeeFullName}
              >
                <FormLabel>Appointee Name</FormLabel>
                <Input
                  isInvalid={!formData?.appointeeFullName && !!errors.appointeeFullName}
                  maxLength={50}
                  onKeyDown={onlyAllowTypingAlphabet}
                  placeholder="Enter appointee's full name"
                  {...register('appointeeFullName')}
                  value={formData?.appointeeFullName}
                  onChange={(e) => {
                    setFormData((appointeeFormData) => {
                      return {
                        ...appointeeFormData,
                        appointeeFullName: e.target.value.trim().length > 0 ? e.target.value : '',
                      }
                    })
                  }}
                />
                {!formData?.appointeeFullName && (
                  <FormErrorMessage color='red.500'>
                    {errors.appointeeFullName?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
          )}
          {formData.isNomineeAMinorChecked && (
            <GridItem>
              <FormControl
                isRequired
                isInvalid={!formData?.appointeeAddress && !!errors.appointeeAddress}
              >
                <FormLabel>Appointee Address</FormLabel>
                <Input
                  isInvalid={!formData?.appointeeAddress && !!errors.appointeeAddress}
                  maxLength={50}
                  onKeyDown={onlyAllowTypingAlphabetNumericAndSpace}
                  placeholder="Enter appointee's full address"
                  {...register('appointeeAddress')}
                  value={formData?.appointeeAddress}
                  onChange={(e) => {
                    setFormData((appointeeFormData) => {
                      return {
                        ...appointeeFormData,
                        appointeeAddress: e.target.value.trim().length > 0 ? e.target.value : '',
                      }
                    })
                  }}
                />
                {!formData?.appointeeAddress && (
                  <FormErrorMessage color='red.500'>
                    {errors.appointeeAddress?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
          )}
          {formData.isNomineeAMinorChecked && (
            <GridItem>
              <FormControl
                isRequired
                isInvalid={!formData?.appointeeRelationship && !!errors.appointeeRelationship}
              >
                <FormLabel>Appointee Relationship</FormLabel>
                <Select
                  {...register('appointeeRelationship')}
                  onChange={(e) => {
                    setFormData((appointeeFormData) => {
                      return { ...appointeeFormData, appointeeRelationship: e.target.value }
                    })
                  }}
                  value={formData?.appointeeRelationship}
                  placeholder='Select Relationship'
                >
                  {relationshipListing.map((elem) => {
                    if (elem != 'Other') {
                      return <option key={elem}>{elem}</option>
                    }
                  })}
                </Select>
                {!formData?.appointeeRelationship && (
                  <FormErrorMessage color='red.500'>
                    {errors.appointeeRelationship?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
          )}
        </Grid>

        <Flex w='full' justifyContent='space-between' alignItems='start'>
          <Checkbox
            onChange={(e) => {
              setIsCheckboxchecked(e.target.checked)
              if (e.target.checked) {
                setValue('nomineeaddress1', ApplicantAddress.address1)
                setValue('nomineeaddress2', ApplicantAddress.address2)
                setValue('nomineeaddress3', ApplicantAddress.address3)

                setFormData((formData) => {
                  return {
                    ...formData,
                    nomineeaddress1: ApplicantAddress.address1,
                    nomineeaddress2: ApplicantAddress.address2,
                    nomineeaddress3: ApplicantAddress.address3,
                  }
                })
              } else {
                setValue('nomineeaddress1', '')
                setValue('nomineeaddress2', '')
                setValue('nomineeaddress3', '')

                setFormData((formData) => {
                  return {
                    ...formData,
                    nomineeaddress1: '',
                    nomineeaddress2: '',
                    nomineeaddress3: '',
                  }
                })
              }
            }}
          >
            Same as Applicant Address
          </Checkbox>
          <Checkbox disabled isChecked={ShowAppointeeForm}>
            Is Nominee a minor{' '}
          </Checkbox>
        </Flex>
        {getVariant && (
          <Button
            onClick={() => {
              if (isCheckboxchecked == true) {
                setFormData((formData) => {
                  return {
                    ...formData,
                    nomineeaddress1: ApplicantAddress.address1,
                    nomineeaddress2: ApplicantAddress.address2,
                    nomineeaddress3: ApplicantAddress.address3,
                  }
                })
              } else {
                setFormData((formData) => {
                  return {
                    ...formData,
                    nomineeaddress1: nomineeAddress.Naddress1,
                    nomineeaddress2: nomineeAddress.Naddress2,
                    nomineeaddress3: nomineeAddress.Naddress3,
                  }
                })
              }
              dispatch(setNomineeFormDetails(formData))

              const enteredDate = new Date(formData?.nomineeDob)
              const today = new Date()
              if (today.getFullYear() < enteredDate.getFullYear()) {
                ToastService.showErrorToast({
                  title:
                    'Nominee DOB Year should be less than or equal to : ' + today.getFullYear(),
                })
                return
              }
              return handleSubmit(onProceed)()
            }}
            type='submit'
            border='1px'
            bgColor='#1C4589'
            px='20'
            py='5'
            cursor='pointer'
          >
            Proceed
          </Button>
        )}
        {!getVariant && (
          <Button
            onClick={async () => {
              if (isCheckboxchecked == true) {
                setFormData((formData) => {
                  return {
                    ...formData,
                    nomineeaddress1: ApplicantAddress.address1,
                    nomineeaddress2: ApplicantAddress.address2,
                    nomineeaddress3: ApplicantAddress.address3,
                  }
                })
              } else {
                setFormData((formData) => {
                  return {
                    ...formData,
                    nomineeaddress1: nomineeAddress.Naddress1,
                    nomineeaddress2: nomineeAddress.Naddress2,
                    nomineeaddress3: nomineeAddress.Naddress3,
                  }
                })
              }

              const enteredDate = new Date(formData?.nomineeDob)
              const today = new Date()

              if (today.getFullYear() < enteredDate.getFullYear()) {
                ToastService.showErrorToast({
                  title:
                    'Nominee DOB Year should be less than or equal to : ' + today.getFullYear(),
                })
                return
              }
              return handleSubmit(onSubmit)()
            }}
            type='submit'
            border='1px'
            bgColor='#1C4589'
            px='20'
            py='5'
            cursor='pointer'
            isLoading={getConsentLoading}
          >
            Get Customer Consent
          </Button>
        )}
      </Flex>
    </>
  )
}

export default SachetsNomineeForm
