import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

const ProposalSubmitModal = ({
  submitBtnRef,
  isOpen,
  onClose,
}: {
  submitBtnRef: React.MutableRefObject<HTMLButtonElement | null>
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader boxShadow='excludingTop' fontSize='md'>
          Are you sure you want to submit the proposal form?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex paddingY='3' justifyContent='center'>
            <Text fontWeight='semibold'>You will not be allowed to change once submitted</Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button variant='outline' colorScheme='primary' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme='primary'
            onClick={() => {
              onClose()
              submitBtnRef.current?.click()
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { ProposalSubmitModal }
