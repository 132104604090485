import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

interface IPlaceholderProps {
  text: string
  height: string
  width: string
}

const PlaceholderImage = (props: IPlaceholderProps) => {
  const { height, width, text } = props

  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      height={height}
      width={width}
      fontSize='sm'
      bgColor='gray.300'
    >
      <Text>{text}</Text>
    </Flex>
  )
}

export { PlaceholderImage }
