import { Input } from '@chakra-ui/react'
import { BimabossDatePicker, ElementWithLabel, ErrorText } from 'Core'
import { useAppSelector } from 'app/hooks'
import { subYears } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { store } from 'app/store'
import axios, { AxiosResponse } from 'axios'
import { IApiResponse2 } from 'Models/ResponseModels'
import { PRODUCT_URL } from 'Constants'
import { getDefaultHeaders2 } from 'Services/baseQuery'

interface Iprops {
  isRoot: boolean
  isDisabled?: boolean
  placeholder?: string
  name: string
  label?: string
  form: UseFormReturn<any, any>
  initialValues?: Record<string, string | number | boolean | string[]>
  isWithoutLabel?: boolean
  input_type?: string
  disabledCodes?: string[]
  min?: number | null
  max?: number | null
  operation?: string
  factor?: number
  required?: boolean
  call_api?: boolean
}

export const RenderDate = (props: Iprops) => {
  const {
    form,
    isDisabled,
    placeholder,
    label,
    isWithoutLabel,
    name,
    disabledCodes,
    min,
    max,
    operation,
    factor,
    required,
    call_api,
    ...rest
  } = props
  // console.log(max)

  const planDetails = useAppSelector((state) => state.planData)

  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')

  const getValidationData = async () => {
    if (call_api) {
      const insurer = store.getState().lifeQuotationDetails.finalSelectedPlan?.insurer
      const insurerProductCode = store.getState().lifeQuotationDetails.finalSelectedPlan?.code
      try {
        const resp: AxiosResponse<
          IApiResponse2<{
            field_validation: {
              min: string | number
              max: string | number
              regex: string
              req_fields?: string[]
              error_msg?: string
            }
            dependent_fields?: Record<
              string,
              {
                type: 'text' | 'date'
                values: Record<string, string | number>
              }
            >
            default: string
            is_disabled: boolean
          }>
        > = await axios({
          url: `${PRODUCT_URL}/product/get-choices/${insurer}/${insurerProductCode}/${name}/`,
          method: 'POST',
          data: {
            ...form.getValues(),
          },
          headers: getDefaultHeaders2({
            token: store.getState().user.authenticatedUser.authtoken ?? '',
          }),
        })

        const fieldValidationData = resp.data.data?.field_validation

        if (fieldValidationData) {
          if (fieldValidationData.min?.toString()) {
            setMinDate(fieldValidationData.min?.toString())
          }

          if (fieldValidationData.max?.toString()) {
            setMaxDate(fieldValidationData.max?.toString())
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getMin = () => {
    if (minDate !== '') {
      return minDate
    }
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth() + 1
    const currentDate = now.getDate()
    let dateString
    let monthString
    if (currentDate + (factor && factor !== 30 ? factor : 0) <= 9) {
      dateString = '0' + (currentDate + (factor && factor !== 30 ? factor : 0)).toString()
    }
    // if (currentMonth <= 9) {
    //   monthString = '0' + currentDate.toString()
    // }
    if (operation === 'date_greater_than_one_month_one_year_range') {
      return `${currentYear + parseInt(form.getValues('DefermentPeriod') ?? 0)}-${
        currentMonth < 9 ? '0' + (currentMonth + 1).toString() : currentMonth + 1
      }-${currentDate <= 9 ? dateString : currentDate}`
    } else if (operation === 'date_greater_than_pt_one_year_range') {
      return `${
        currentYear +
        (form.getValues('guaranteed_income_frequency').toLowerCase() === 'yearly' ? 1 : 0) +
        parseInt(form.getValues('DefermentPeriod') ?? 0) +
        parseInt(form.getValues('plan_term'))
      }-${
        currentMonth +
          (factor && factor === 30 ? 1 : 0) +
          (form.getValues('guaranteed_income_frequency').toLowerCase() === 'monthly' ? 1 : 0) <
        9
          ? '0' +
            (
              currentMonth +
              (factor && factor === 30 ? 1 : 0) +
              (form.getValues('guaranteed_income_frequency').toLowerCase() === 'monthly' ? 1 : 0)
            ).toString()
          : currentMonth +
            (factor && factor === 30 ? 1 : 0) +
            (form.getValues('guaranteed_income_frequency').toLowerCase() === 'monthly' ? 1 : 0)
      }-${
        currentDate + (factor && factor !== 30 ? factor : 0) <= 9
          ? dateString
          : currentDate + (factor && factor !== 30 ? factor : 0)
      }`
    } else if (operation === 'date_greater_than_pt_range') {
      return `${
        currentYear +
        parseInt(form.getValues('DefermentPeriod') ?? 0) +
        parseInt(form.getValues('plan_term'))
      }-${currentMonth < 9 ? '0' + (currentMonth + 1).toString() : currentMonth + 1}-${
        currentDate <= 9 ? dateString : currentDate
      }`
    } else if (operation === 'date_greater_than_ppt_one_year_range') {
      return `${
        currentYear +
        1 +
        parseInt(form.getValues('DefermentPeriod') ?? 0) +
        parseInt(form.getValues('premium_paying_term'))
      }-${currentMonth < 9 ? '0' + (currentMonth + 1).toString() : currentMonth + 1}-${
        currentDate <= 9 ? dateString : currentDate
      }`
    }
    return undefined
  }

  const getMax = () => {
    if (maxDate !== '') {
      return maxDate
    }
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth() + 1
    const currentDate = now.getDate()
    let dateString
    let monthString
    if (
      operation === 'date_greater_than_pt_one_year_range' ||
      operation === 'date_greater_than_ppt_one_year_range'
    ) {
      if (currentDate <= 10) {
        dateString = '0' + (currentDate - 1).toString()
      }
    } else {
      if (currentDate <= 9) {
        dateString = '0' + currentDate.toString()
      }
    }
    // if (currentMonth <= 9) {
    //   monthString = '0' + currentDate.toString()
    // }
    if (operation === 'date_greater_than_one_month_one_year_range') {
      return `${currentYear + 1 + parseInt(form.getValues('DefermentPeriod') ?? 0)}-${
        currentMonth <= 9 ? '0' + currentMonth.toString() : currentMonth
      }-${
        currentDate <= 9 ? '0' + (parseInt(dateString?.substring(1) ?? '') - 1) : currentDate - 1
      }`
    } else if (operation === 'date_greater_than_pt_one_year_range') {
      return `${
        currentYear +
        1 +
        (form.getValues('guaranteed_income_frequency').toLowerCase() === 'yearly' ? 1 : 0) +
        parseInt(form.getValues('DefermentPeriod') ?? 0) +
        parseInt(form.getValues('plan_term'))
      }-${currentMonth <= 9 ? '0' + currentMonth.toString() : currentMonth}-${
        currentDate <= 9 ? dateString : currentDate - 1
      }`
    } else if (operation === 'date_greater_than_pt_range') {
      return `${
        currentYear +
        1 +
        parseInt(form.getValues('DefermentPeriod') ?? 0) +
        parseInt(form.getValues('plan_term'))
      }-${currentMonth <= 9 ? '0' + currentMonth.toString() : currentMonth}-${
        currentDate <= 9 ? dateString : currentDate - 1
      }`
    } else if (operation === 'date_greater_than_ppt_one_year_range') {
      return `${
        currentYear +
        2 +
        parseInt(form.getValues('DefermentPeriod') ?? 0) +
        parseInt(form.getValues('premium_paying_term'))
      }-${currentMonth <= 9 ? '0' + currentMonth.toString() : currentMonth}-${
        currentDate <= 9 ? dateString : currentDate - 1
      }`
    }
    return undefined
  }

  useEffect(() => {
    console.log(
      `${
        parseInt((new Date().getFullYear() + 1).toString()) +
        parseInt(form.getValues('DefermentPeriod') ?? 0)
      }-${
        new Date().getMonth() + 1 <= 9
          ? '0' + (new Date().getMonth() + 1).toString()
          : new Date().getMonth() + 1
      }-${
        new Date().getDate() <= 9 ? '0' + new Date().getDate().toString() : new Date().getDate()
      }`,
    )
    if (operation === 'pre_fill_date_current_date_plus_pt_years') {
      form.setValue(
        name,
        `${new Date().getFullYear() + parseInt(form.getValues('plan_term'))}-${
          new Date().getMonth() + 1 <= 9
            ? '0' + (new Date().getMonth() + 1).toString()
            : new Date().getMonth() + 1
        }-${
          new Date().getDate() <= 9 ? '0' + new Date().getDate().toString() : new Date().getDate()
        }`,
      )
    } else if (operation === 'pre_fill_date_same_as_current_date') {
      form.setValue(
        name,
        `${new Date().getFullYear()}-${
          new Date().getMonth() + 1 < 9
            ? '0' + (new Date().getMonth() + 1).toString()
            : new Date().getMonth() + 1
        }-${
          new Date().getDate() <= 9 ? '0' + new Date().getDate().toString() : new Date().getDate()
        }`,
      )
    } else if (operation === 'pre_fill_date_same_as_date_options') {
      form.setValue(name, form.getValues('date_options'))
    } else if (operation === 'pre_fill_current_date_plus_one_month') {
      form.setValue(
        name,
        `${
          parseInt(new Date().getFullYear().toString()) +
          parseInt(form.getValues('DefermentPeriod') ?? 0)
        }-${
          new Date().getMonth() + 1 < 9
            ? '0' + (new Date().getMonth() + 2).toString()
            : new Date().getMonth() + 2
        }-${
          new Date().getDate() <= 9 ? '0' + new Date().getDate().toString() : new Date().getDate()
        }`,
      )
    } else if (operation === 'pre_fill_current_date_plus_one_year') {
      form.setValue(
        name,
        `${
          parseInt((new Date().getFullYear() + 1).toString()) +
          parseInt(form.getValues('DefermentPeriod') ?? 0)
        }-${
          new Date().getMonth() + 1 <= 9
            ? '0' + (new Date().getMonth() + 1).toString()
            : new Date().getMonth() + 1
        }-${
          new Date().getDate() <= 9 ? '0' + new Date().getDate().toString() : new Date().getDate()
        }`,
      )
    }
  }, [])

  useEffect(() => {
    if (operation === 'pre_fill_date_same_as_date_options') {
      form.setValue(name, form.getValues('date_options'))
    }
  }, [form.getValues('date_options')])

  useEffect(() => {
    if (operation === 'pre_fill_current_date_plus_one_month') {
      form.setValue(
        name,
        `${
          parseInt(new Date().getFullYear().toString()) +
          parseInt(form.getValues('DefermentPeriod') ?? 0)
        }-${
          new Date().getMonth() + 1 < 9
            ? '0' + (new Date().getMonth() + 2).toString()
            : new Date().getMonth() + 2
        }-${
          new Date().getDate() <= 9 ? '0' + new Date().getDate().toString() : new Date().getDate()
        }`,
      )
    } else if (operation === 'pre_fill_current_date_plus_one_year') {
      form.setValue(
        name,
        `${
          parseInt((new Date().getFullYear() + 1).toString()) +
          parseInt(form.getValues('DefermentPeriod') ?? 0)
        }-${
          new Date().getMonth() + 1 <= 9
            ? '0' + (new Date().getMonth() + 1).toString()
            : new Date().getMonth() + 1
        }-${
          new Date().getDate() <= 9 ? '0' + new Date().getDate().toString() : new Date().getDate()
        }`,
      )
    }
  }, [form.getValues('DefermentPeriod')])

  return (
    <ElementWithLabel
      marginY='0.7rem'
      required
      height='fit-content'
      label={isWithoutLabel ? '' : label || name}
      {...rest}
    >
      <Input
        {...form.register(name, {
          required: true,
        })}
        name={name}
        onFocus={getValidationData}
        type='date'
        // onChange={onChange}
        placeholder={placeholder}
        value={
          operation === 'pre_fill_date_current_date_plus_pt_years'
            ? `${new Date().getFullYear() + parseInt(form.getValues('plan_term'))}-${
                new Date().getMonth() + 1 <= 9
                  ? '0' + (new Date().getMonth() + 1).toString()
                  : new Date().getMonth() + 1
              }-${
                new Date().getDate() <= 9
                  ? '0' + new Date().getDate().toString()
                  : new Date().getDate()
              }`
            : operation === 'pre_fill_date_same_as_current_date'
            ? `${new Date().getFullYear()}-${
                new Date().getMonth() + 1 < 9
                  ? '0' + (new Date().getMonth() + 1).toString()
                  : new Date().getMonth() + 1
              }-${
                new Date().getDate() <= 9
                  ? '0' + new Date().getDate().toString()
                  : new Date().getDate()
              }`
            : operation === 'pre_fill_date_same_as_date_options'
            ? form.getValues('date_options')
            : operation === 'pre_fill_current_date_plus_one_month'
            ? `${
                parseInt(new Date().getFullYear().toString()) +
                parseInt(form.getValues('DefermentPeriod') ?? 0)
              }-${
                new Date().getMonth() + 1 < 9
                  ? '0' + (new Date().getMonth() + 2).toString()
                  : new Date().getMonth() + 2
              }-${
                new Date().getDate() <= 9
                  ? '0' + new Date().getDate().toString()
                  : new Date().getDate()
              }`
            : operation === 'pre_fill_current_date_plus_one_year'
            ? `${
                parseInt((new Date().getFullYear() + 1).toString()) +
                parseInt(form.getValues('DefermentPeriod') ?? 0)
              }-${
                new Date().getMonth() + 1 <= 9
                  ? '0' + (new Date().getMonth() + 1).toString()
                  : new Date().getMonth() + 1
              }-${
                new Date().getDate() <= 9
                  ? '0' + new Date().getDate().toString()
                  : new Date().getDate()
              }`
            : undefined
        }
        disabled={
          operation === 'pre_fill_date_current_date_plus_pt_years' ||
          operation === 'pre_fill_date_same_as_current_date' ||
          operation === 'pre_fill_date_same_as_date_options' ||
          operation === 'pre_fill_current_date_plus_one_month' ||
          operation === 'pre_fill_current_date_plus_one_year'
            ? true
            : false
        }
        // value={customerObj.dob}
        variant={'outline'}
        isDisabled={
          isDisabled ||
          (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false)
        }
        // min={min ? min : undefined}
        // max={max ? new Date(max).toISOString().split('T')[0] : undefined}
        min={getMin()}
        max={getMax()}
      />
      {form.formState.errors[name] && <ErrorText text={`${label} is required!`} />}
    </ElementWithLabel>
  )
}
