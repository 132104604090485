import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'

interface IFileUploadV2Props {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
}

const FileUploadV2 = (props: IFileUploadV2Props) => {
  const { getInputProps, getRootProps } = props

  return (
    <Flex
      padding='1rem'
      border='1px dashed'
      borderColor='gray.300'
      gap='0.5rem'
      alignItems='center'
      direction='column'
      {...getRootProps()}
      width='300px'
      height='170px'
    >
      <Box padding='1rem' border='1px' borderColor='primary.500' rounded='full'>
        <AddIcon height='2rem' width='2rem' color='primary.500' />
      </Box>
      <Text color='gray.300' fontWeight='bold'>
        Drag & Drop File
      </Text>
      <Button size='sm' variant='link'>
        Browse my files
      </Button>
    </Flex>
  )
}

export { FileUploadV2 }
