import { Flex, Image, ListItem, Spacer, Text, UnorderedList } from '@chakra-ui/react'
import { ListTile, ListView } from 'Core'

const ManufacturerList = () => {
  return (
    <ListView>
      {['Maruti Suzuki', 'Toyota', 'Tata', 'Mahindra', 'Hyundai'].map((item, index) => {
        return (
          <ListTile
            paddingX='4'
            paddingY='2'
            bgColor={index % 2 === 0 ? 'appGrey' : ''}
            key={index}
          >
            <Flex alignItems='center'>
              <Image
                maxW='40'
                maxH='45'
                src='https://foundation.theblackswan.in/media/oxzR7S74WtyaWPsEycBmKg=='
              />
              <Text paddingX='4' fontWeight='medium'>
                {item}
              </Text>
            </Flex>
          </ListTile>
        )
      })}
    </ListView>
  )
}

export { ManufacturerList }
