import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { IndianRupeeFormatFromString } from 'Services/general'
import { IQuoteDetails } from './useLifeQuotation'
import { useEffect, useState } from 'react'
// import indiaFirstLogo from 'Assets/indiafirst-color.png'
import { IoIosArrowDropupCircle } from 'react-icons/io'
import { ToastService } from 'Services/toast.service'
import { BbButton } from 'Core/BbButton'

const PremiumStrip = ({
  label,
  amount,
  varient,
  subLabel,
}: {
  label: string
  varient?: string
  amount: string
  subLabel?: string
}) => {
  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      // bg={varient === 'plain' ? '' : 'secondary.50'}
      // opacity={label === 'Sum Assured' ? '0.07' : ''}
      px='.5rem'
      gap={'2'}
      my={'0.2rem'}
      py='2'
    >
      <Text fontWeight='bold' fontSize='sm'>
        {label}
        <Text fontSize='10px'>{subLabel}</Text>
      </Text>
      <Text color='secondary.500' fontSize='large' fontWeight='bold'>
        ₹ {IndianRupeeFormatFromString(amount.toString())}
      </Text>
    </Flex>
  )
}

const QuoteItemCard = (props: IQuoteDetails) => {
  const {
    planName,
    planDescription,
    quoteId,
    biUrl,
    annualPremium,
    sumAssured,
    filters,
    toggleIsBiFormGenerated,
    startDate,
    logo,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      bg={'#fff'}
      // border='1px solid'
      borderColor='primary.500'
      boxShadow='cardShadow'
      rounded='md'
      p='4'
      mb='6'
    >
      <Grid gridTemplateColumns='.5fr 2fr 1fr'>
        <Flex></Flex>
        <Text fontSize='xl' textAlign={'center'} fontWeight='bold'>
          {planName}
          {/* IndiaFirst Life Saral Bachat Plan */}
        </Text>
      </Grid>
      <Flex pt='4' w={'100%'} direction={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }}>
        <Flex
          width={{ lg: '45%', md: '45%', sm: '100%', xs: '100%' }}
          direction='column'
          alignItems='center'
          p='4'
          borderRight={{ lg: '1px ', md: '1px ', sm: '0px ', xs: '0px' }}
          borderColor='appGrey.100'
        >
          <img width='120px' src={logo} alt='' />

          <Text
            pt='4'
            my={'2rem'}
            color='appGrey.500'
            fontSize='md'
            textAlign={'justify'}
            position={'relative'}
            style={{
              display: `${isOpen ? '' : '-webkit-box'}`,
              WebkitLineClamp: 6,
              WebkitBoxOrient: 'vertical',
              overflow: `${isOpen ? '' : 'hidden'}`,
              textOverflow: `${isOpen ? '' : 'ellipsis'}`,
            }}
          >
            {planDescription}
            <Box
              cursor={'pointer'}
              bg={'#fff'}
              px={'.5rem'}
              position={'absolute'}
              bottom={'0px'}
              right={0}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              <Text color={'primary.500'}>
                {isOpen ? <IoIosArrowDropupCircle size={'20px'} /> : 'Show more'}
              </Text>
            </Box>
          </Text>
          <Flex direction='row' alignItems='center' w='full' fontSize='md' fontWeight='semibold'>
            <Text>Proposal Start Date:</Text>
            <Text mx='2'>{startDate}</Text>
          </Flex>
        </Flex>
        <Flex
          paddingY='1rem'
          width={{ lg: '55%', md: '55%', sm: '100%', xs: '100%' }}
          direction='column'
          alignItems='center'
          px={{ lg: '2rem', md: '1rem', sm: '4rem', xs: '1rem' }}
          justifyContent='center'
        >
          <Flex paddingY='0.5rem'>
            <Text mr='1'>Quote ID:</Text>
            <Text fontWeight='bold'>{quoteId}</Text>
          </Flex>
          <Box w={{ lg: '65%', md: '100%', sm: '100%', xs: '100%' }}>
            <PremiumStrip
              label={
                filters.filter &&
                filters.filter['calculate'] &&
                filters.filter['calculate']?.toLowerCase() === 'guaranteed income'
                  ? 'Annual Guaranteed Income'
                  : 'Installment Premium'
              }
              // label='Annual Guaranteed Income'
              subLabel='(Incl. of taxes + Rider amount)'
              varient='plain'
              amount={annualPremium}
            />
            <PremiumStrip label='Sum Assured' amount={sumAssured.toString()} />
          </Box>

          <Flex my='4' width={{ lg: '65%', md: '100%', sm: '100%', xs: '100%' }}>
            <BbButton
              variant='outline'
              borderRadius='sm'
              width='100%'
              onClick={() => {
                // biUrl ? window.open(biUrl, '_blank') : null
                if (biUrl) {
                  window.open(biUrl, '_blank')
                  toggleIsBiFormGenerated(true)
                } else {
                  ToastService.showErrorToast({
                    title: 'BI Url not found!',
                  })
                }
              }}
            >
              View BI
            </BbButton>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export { QuoteItemCard }
