export const sizes = {
  lg: {
    // apply: 'textStyles.body3Semi',
    fontSize: {
      base: '0.813rem',
      xl: '1.063rem',
      '3xl': '1.125rem',
    },
    px: 6,
    minW: '5rem',
    h: '2.8rem',
  },
  md: {
    apply: 'textStyles.body3Semi',
    px: 5,
    minW: '4rem',
    h: '2.5rem',
  },
  sm: {
    apply: 'textStyles.body4Semi',
    px: 4,
    minW: '3rem',
    h: '1.5rem',
  },
}
