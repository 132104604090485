import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useVehicleType } from 'app/hooks'
import { ElevatedButton } from 'Core'
import { updatePaymentActivated } from 'features/Motor'
import { useEffect, useState } from 'react'
import {
  useGetCustomerConsentFormLinkMutation,
  useLazyCheckForCustomerConsentSubmissionStatusQuery,
} from 'Services/API/motor.api'
import { copyToClipboard } from 'Services/general'
import { getConsentFormPayload } from '../ProposalUtils'
import { ToastService } from 'Services/toast.service'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

const ProposalCustomerConsentForm = () => {
  const dispatch = useAppDispatch()

  const { consentFormLink, paymentActivated } = useAppSelector((state) => state.proposalPage)
  const unique_identifier = useAppSelector(
    (state) => state.quotationsDetails.selectedQuote?.unique_identifier,
  )

  const { param: vehicleType, name: vehicleName } = useVehicleType()

  const [isCheckSubmissionRunning, setIsCheckSubmissionRunning] = useState(true)

  const [pollingInterval, setPollingInterval] = useState(4000)

  const [checkForCustomerConsentSubmissionStatus, { data: submissionResponse }] =
    useLazyCheckForCustomerConsentSubmissionStatusQuery({
      pollingInterval: pollingInterval,
    })

  useEffect(() => {
    if (submissionResponse?.is_submited === true) {
      setPollingInterval(0)
      setIsCheckSubmissionRunning(false)
      dispatch(updatePaymentActivated(true))
    }
  }, [submissionResponse])

  useEffect(() => {
    checkForCustomerConsentSubmissionStatus({
      unique_identifier: unique_identifier ?? '',
    })
    startTimer()
  }, [])

  const homePageDetails = useAppSelector((state) => state.homePage)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const proposalDetails = useAppSelector((state) => state.proposalPage)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const spDetails = useAppSelector((state) => state.spTagging)

  const [getCustomerConsentForm, { isLoading }] = useGetCustomerConsentFormLinkMutation()

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  if (paymentActivated) {
    return (
      <Flex marginY='10' marginX='6' direction='column' alignItems='center'>
        Consent form has been successfully submitted and payment link has been generated! You can
        proceed with the payment.
      </Flex>
    )
  }

  return (
    <Flex marginY='10' marginX='6' direction='column' alignItems='center'>
      {consentFormLink && consentFormLink !== '' && (
        <>
          <Text fontSize='md'>Customer Consent form link has been generated!</Text>
          <Flex>
            {!submissionResponse?.is_submited && (
              <Button
                borderRadius='full'
                isLoading={isLoading}
                onClick={async () => {
                  const resp = await getCustomerConsentForm({
                    customer: {
                      premium_breakup_details: {
                        addOns: quotationDetails.addOns,
                        discountCovers: quotationDetails.discountCovers,
                        liabilityCovers: quotationDetails.liabilityCovers,
                        ownDamageCovers: quotationDetails.ownDamageCovers,
                        selectedQuote: quotationDetails.selectedQuote,
                        homePageDetails: homePageDetails,
                        vehicleDetails: vehicleDetails,
                        insuranceDetails: insuranceDetails,
                        quotationDetails: quotationDetails,
                      },
                      customer_data: getConsentFormPayload(vehicleType, vehicleName),
                      email_id: proposalDetails.proposalDetails.email ?? '',
                      phone: proposalDetails.proposalDetails.mobileNumber
                        ? '91' + proposalDetails.proposalDetails.mobileNumber
                        : '',
                      lead_uid: leadDetails.lead_uid ?? '',
                    },
                    lead_uid: leadDetails.lead_uid ?? '',
                    payment_link: proposalDetails.proposalSuccessResponse.payment_url ?? '',
                    unique_identifier: quotationDetails.selectedQuote?.unique_identifier ?? '',
                    resend_link: true,
                  }).unwrap()
                  if (resp) {
                    setResendOtp(false)
                    startTimer()
                    ToastService.showSuccessTaost({
                      title: 'Success',
                      message: `Customer Consent form link has been sent to customer's phone and email`,
                    })
                    // await checkForSpConsentSubmissionStatus({
                    //   unique_identifier: unique_identifier ?? '',
                    // })
                    // // dispatch(updateSpConsentStatus('pending'))
                    // setIsCheckSubmissionRunning(true)
                  }
                }}
                isDisabled={!resendOtp}
                size='sm'
                marginX='0.5rem'
                marginY='0.8rem'
              >
                {resendOtp ? 'Resend Link' : `Resend Link in ${timeLeft}`}
              </Button>
            )}
            <ElevatedButton
              onClick={() => {
                copyToClipboard(consentFormLink)
              }}
              size='sm'
              marginX='0.5rem'
              marginY='0.8rem'
            >
              Copy Form Link
            </ElevatedButton>
            {/* <ElevatedButton
              isLoading={isCheckSubmissionRunning}
              onClick={async () => {
                window.open(consentFormLink)
                await checkForCustomerConsentSubmissionStatus({
                  unique_identifier: unique_identifier ?? '',
                })
                setIsCheckSubmissionRunning(true)
              }}
              size='sm'
              marginX='0.5rem'
              marginY='0.8rem'
            >
              Open Form
            </ElevatedButton> */}
          </Flex>
        </>
      )}
    </Flex>
  )
}

export { ProposalCustomerConsentForm }
