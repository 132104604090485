import { CalendarIcon } from '@chakra-ui/icons'
import {
  Flex,
  FormLabel,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType, useVehicleType } from 'app/hooks'
import { Select } from 'chakra-react-select'
import { BimabossDatePicker, ErrorText, TextInput } from 'Core'
import { addYears, differenceInDays, format, subMonths } from 'date-fns'
import { subDays } from 'date-fns/esm'
import { updateShowIrdaError } from 'features/Motor'
import { IInsurer } from 'Models/ResponseModels'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { getDateFromDDMMYYYY, getTpPolicyEndMinDate } from 'Services/general'
import { TVehicleInsuranceFormType } from './FormModel/VehicleInsurance'

interface ITPPolicyDetailsForm {
  insurersDropdownData: IInsurer[] | undefined
  isInsurersDataLoading: boolean
  form: UseFormReturn<TVehicleInsuranceFormType, any>
}

const tpInsurerNameDefaultValue = {
  label: 'Select Insurer',
  value: '',
}

const TPPolicyDetailsForm = (props: ITPPolicyDetailsForm) => {
  const { isMobile } = useDeviceType()
  const { param } = useVehicleType()
  const dispatch = useAppDispatch()
  const {
    insurersDropdownData,
    isInsurersDataLoading,
    form: { register, formState, setValue, clearErrors },
  } = props

  const {
    vehicleInsuranceDetails: vehicleInsuranceDetailsInStore,
    vehicleDetails: vehicleDetailsInStore,
  } = useAppSelector((state) => state.vehicleDetails)

  const [selectedTpInsurerName, setSelectedTpInsurerName] = useState<{
    value: string
    label: string
  }>(
    vehicleInsuranceDetailsInStore.TpPreviousInsurerName
      ? {
          label: vehicleInsuranceDetailsInStore.TpPreviousInsurerName.name,
          value: vehicleInsuranceDetailsInStore.TpPreviousInsurerName.code,
        }
      : tpInsurerNameDefaultValue,
  )

  const [tpPolicyStartDate, setTpPolicyStartDate] = useState(
    vehicleInsuranceDetailsInStore.tpPolicyStartDate ?? '',
  )
  const [tpPolicyEndDate, setTpPolicyEndDate] = useState(
    vehicleInsuranceDetailsInStore.tpPolicyEndDate ?? '',
  )

  useEffect(() => {
    if (tpPolicyStartDate) {
      setValue('tp_policy_start_date', tpPolicyStartDate)
      if (param === 'is_fourwheeler') {
        setTpPolicyEndDate(
          format(subDays(addYears(getDateFromDDMMYYYY(tpPolicyStartDate), 3), 1), 'dd/MM/yyyy'),
        )
        setValue(
          'tp_policy_end_date',
          format(subDays(addYears(getDateFromDDMMYYYY(tpPolicyStartDate), 3), 1), 'dd/MM/yyyy'),
        )
      } else if (param === 'is_twowheeler') {
        setTpPolicyEndDate(
          format(subDays(addYears(getDateFromDDMMYYYY(tpPolicyStartDate), 5), 1), 'dd/MM/yyyy'),
        )
        setValue(
          'tp_policy_end_date',
          format(subDays(addYears(getDateFromDDMMYYYY(tpPolicyStartDate), 5), 1), 'dd/MM/yyyy'),
        )
      }
    }
  }, [tpPolicyStartDate])

  // const [showIRDAError, setShowIRDAError] = useState(false)

  useEffect(() => {
    const tpMinDate = getTpPolicyEndMinDate(
      vehicleInsuranceDetailsInStore.existingPolicyExpiryDate ?? '',
    )

    console.log('tpMinDate - ', tpMinDate)
    console.log('tpPolicyEndDate - ', tpPolicyEndDate)

    if (tpMinDate && tpPolicyEndDate) {
      dispatch(
        updateShowIrdaError(differenceInDays(tpMinDate, getDateFromDDMMYYYY(tpPolicyEndDate)) > 0),
      )
      // setShowIRDAError(differenceInDays(tpMinDate, getDateFromDDMMYYYY(tpPolicyEndDate)) > 0)
    }
  }, [vehicleInsuranceDetailsInStore.existingPolicyExpiryDate, tpPolicyEndDate])

  return (
    <>
      <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}>
        <GridItem marginY='2' marginX='6'>
          <FormLabel>TP previous Policy Number*</FormLabel>
          <TextInput
            {...register('TpPreviousPolicyNumber', { required: true })}
            placeholder='Enter TP Previous Policy No.'
          />
          {formState.errors.TpPreviousPolicyNumber &&
            formState.errors.TpPreviousPolicyNumber?.type === 'required' && (
              <ErrorText text='TP Prev. policy no. is required' />
            )}
        </GridItem>
        <GridItem marginY='2' marginX='6'>
          <FormLabel>TP Insurer Name*</FormLabel>
          <Select
            selectedOptionColor='primary'
            menuPosition='fixed'
            placeholder='Select Insurer'
            isLoading={isInsurersDataLoading}
            {...register('TpPreviousInsurerName', { required: true })}
            value={selectedTpInsurerName}
            onChange={(item) => {
              setSelectedTpInsurerName({
                label: item?.label ?? '',
                value: item?.value ?? '',
              })
              setValue('TpPreviousInsurerName', {
                code: item?.value ?? '',
                name: item?.label ?? '',
              })
              clearErrors('TpPreviousInsurerName')
            }}
            options={insurersDropdownData?.map((insurer) => {
              return {
                label: insurer.name,
                value: insurer.code,
              }
            })}
          />
          {formState.errors.TpPreviousInsurerName && (
            <ErrorText text={'TP prev. insurer is required'} />
          )}
        </GridItem>
        <GridItem marginY='2' marginX='6'>
          <FormLabel>TP Policy Start Date*</FormLabel>
          <BimabossDatePicker
            {...register('tp_policy_start_date', { required: true })}
            inputDate={tpPolicyStartDate}
            setInputDate={setTpPolicyStartDate}
            clearErrors={() => clearErrors('tp_policy_start_date')}
            maxDate={new Date()}
            minDate={
              vehicleDetailsInStore.registrationDate
                ? subMonths(getDateFromDDMMYYYY(vehicleDetailsInStore.registrationDate), 6)
                : new Date()
            }
          />
          {formState.errors.tp_policy_start_date &&
            formState.errors.tp_policy_start_date?.type === 'required' && (
              <ErrorText text='TP policy start date is required' />
            )}
        </GridItem>
        <GridItem marginY='2' marginX='6'>
          <FormLabel>TP Policy End Date*</FormLabel>
          <InputGroup>
            <TextInput
              {...register('tp_policy_end_date')}
              value={tpPolicyEndDate}
              isReadOnly
              placeholder='Enter date'
            />
            <InputRightElement zIndex='0' cursor='pointer'>
              <CalendarIcon color='secondary.500' />
            </InputRightElement>
          </InputGroup>
          {formState.errors.tp_policy_end_date &&
            formState.errors.tp_policy_end_date?.type === 'required' && (
              <ErrorText text='TP policy end date is required' />
            )}
        </GridItem>
      </Grid>
      {vehicleInsuranceDetailsInStore.showIRDAError && (
        <Flex justifyContent='center'>
          <Text fontSize='sm' color='red'>
            As per the IRDA guidelines, it is mandatory to have active TP policy for the period of
            OD policy
          </Text>
        </Flex>
      )}
    </>
  )
}

export { TPPolicyDetailsForm }
