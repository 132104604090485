import { Box, Flex, Text } from '@chakra-ui/react'
import { useVehicleType } from 'app/hooks'
import { UseFormReturn } from 'react-hook-form'
import { TMotorProposalFormType } from '../Forms/FormModel'
import { ProposalVehicleDetailsForm } from '../Forms/ProposalVehicleDetailsForm'

interface IProposalVehicleDetailsDesktop {
  rounded?: boolean
  form: UseFormReturn<TMotorProposalFormType, any>
}

const ProposalVehicleDetailsDesktop = (props: IProposalVehicleDetailsDesktop) => {
  const { name: vehicleName } = useVehicleType()
  const { rounded, form } = props

  return (
    <Flex
      flexBasis='30%'
      border='1px'
      borderLeftRadius={rounded ? 'lg' : ''}
      maxWidth='100%'
      direction='column'
    >
      <Box padding='3' boxShadow='excludingTop'>
        <Text fontSize='md' textAlign='left' paddingX='4' paddingY='2' fontWeight='bold'>
          01. {vehicleName} Details
        </Text>
      </Box>
      <ProposalVehicleDetailsForm form={form} />
    </Flex>
  )
}

export { ProposalVehicleDetailsDesktop }
