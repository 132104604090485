import { MemberData } from 'Models/Common/Health.General'
import { getDOBfromAge, getMemberTypeFromAge } from 'Services/general'

export const getMembers = (
  type: 'parents' | 'selfSpouseKids' | 'allMembers' | undefined,
  memberDetails: {
    [key: string]: MemberData
  },
  proposer: MemberData,
) => {
  const allMembers: MemberData[] = []
  Object.keys(memberDetails).forEach((key) => {
    allMembers.push({
      type: memberDetails[key].type,
      salutation: {
        name: '',
        code: '',
      },
      member_type: memberDetails[key].member_type,
      age: memberDetails[key].age,
      dob: memberDetails[key].dob ?? getDOBfromAge(memberDetails[key].age),
      pincode: memberDetails[key].pincode,
      // pincode: '201301',
      proposer: memberDetails[key].proposer,
      gender: memberDetails[key].gender,
      city_name: memberDetails[key].city_name,
      state_name: memberDetails[key].state_name,
      nationality: memberDetails[key].nationality,
      marital_status: memberDetails[key].marital_status,
      member_insured: '',
      firstname: '',
      middlename: '',
      lastname: '',
      relationship_with_proposer: '',
      mobile: '',
      email: '',
      occupation: {
        name: '',
        code: '',
      },
      bmi: '',
      height: '',
      weight: '',
      educational_qualification: {
        name: '',
        code: '',
      },
      annual_income: '',
      aadhar_card_number: '',
      pan_number: '',
      current_address: '',
      pincode_map: {
        area_code: '',
        area_id: null,
        area_name: '',
        city_code: '',
        city_name: '',
        id: null,
        is_district: null,
        is_insurance_wise: null,
        pincode: '',
        state_code: '',
        state_name: '',
        extra_info: '',
      },
      permanent_address: '',
      permanent_address_pincode_map: {
        area_code: '',
        area_id: null,
        area_name: '',
        city_code: '',
        city_name: '',
        id: null,
        is_district: null,
        is_insurance_wise: null,
        pincode: '',
        state_code: '',
        state_name: '',
        extra_info: '',
      },
      nominee_details: {
        salutation: {
          name: '',
          code: '',
        },
        firstname: '',
        middlename: '',
        lastname: '',
        dob: '',
        gender: '',
        relationship: {
          name: '',
          code: '',
        },
        mobile: '',
        occupation: {
          name: '',
          code: '',
        },
        address: '',
        marital_status: {
          name: '',
          code: '',
        },
        pincode_map: {
          area_code: '',
          area_id: null,
          area_name: '',
          city_code: '',
          city_name: '',
          id: null,
          is_district: null,
          is_insurance_wise: null,
          pincode: '',
          state_code: '',
          state_name: '',
          extra_info: '',
        },
      },
      medical_questions: [],
      lifestyle_questions: [],
    })
  })
  allMembers.push({
    // type: getMemberTypeFromAge(proposer.age),
    type: 'adult',
    salutation: {
      name: '',
      code: '',
    },
    member_type: proposer.member_type,
    age: proposer.age,
    dob: getDOBfromAge(proposer.age),
    // pincode: proposer.pincode,
    pincode: proposer.pincode,
    proposer: proposer.proposer,
    gender: proposer.gender,
    city_name: proposer.city_name,
    state_name: proposer.state_name,
    nationality: proposer.nationality,
    marital_status: proposer.marital_status,
    member_insured: '',
    firstname: '',
    middlename: '',
    lastname: '',
    relationship_with_proposer: '',
    mobile: '',
    email: '',
    occupation: {
      name: '',
      code: '',
    },
    bmi: '',
    height: '',
    weight: '',
    educational_qualification: {
      name: '',
      code: '',
    },
    annual_income: '',
    aadhar_card_number: '',
    pan_number: '',
    current_address: '',
    pincode_map: {
      area_code: '',
      area_id: null,
      area_name: '',
      city_code: '',
      city_name: '',
      id: null,
      is_district: null,
      is_insurance_wise: null,
      pincode: '',
      state_code: '',
      state_name: '',
      extra_info: '',
    },
    permanent_address: '',
    permanent_address_pincode_map: {
      area_code: '',
      area_id: null,
      area_name: '',
      city_code: '',
      city_name: '',
      id: null,
      is_district: null,
      is_insurance_wise: null,
      pincode: '',
      state_code: '',
      state_name: '',
      extra_info: '',
    },
    nominee_details: {
      salutation: {
        name: '',
        code: '',
      },
      firstname: '',
      middlename: '',
      lastname: '',
      dob: '',
      gender: '',
      relationship: {
        name: '',
        code: '',
      },
      mobile: '',
      occupation: {
        name: '',
        code: '',
      },
      address: '',
      marital_status: {
        name: '',
        code: '',
      },
      pincode_map: {
        area_code: '',
        area_id: null,
        area_name: '',
        city_code: '',
        city_name: '',
        id: null,
        is_district: null,
        is_insurance_wise: null,
        pincode: '',
        state_code: '',
        state_name: '',
        extra_info: '',
      },
    },
    medical_questions: [],
    lifestyle_questions: [],
  })

  // console.log(allMembers, 'members ')

  const filteredSelfSpouseKids = allMembers.filter(
    (member) =>
      member.member_type == 'proposer' ||
      member.member_type == 'spouse' ||
      member.member_type == 'son' ||
      member.member_type == 'daughter',
  )

  // console.log(filteredSelfSpouseKids, 'members proporse rsjpu;d be there in that funxtion')

  const filteredParents = allMembers.filter(
    (member) =>
      member.member_type == 'father' ||
      member.member_type == 'mother' ||
      member.member_type == 'proposer',
  )

  // console.log(type, filteredParents, 'check data')

  switch (type) {
    case 'parents':
      return filteredParents
    case 'allMembers':
      return allMembers
    case 'selfSpouseKids':
      return filteredSelfSpouseKids
    default:
      return allMembers
  }
}

export const getSelfSpouseKidsText = (
  memberDetails: {
    [key: string]: MemberData
  },
  isProposerIncluded: boolean,
) => {
  let selfIncluded = false
  let spouseIncluded = false
  let kidsIncluded = false

  let displayText = ''

  Object.keys(memberDetails).forEach((key) => {
    if (memberDetails[key].member_type === 'spouse') spouseIncluded = true
    if (memberDetails[key].member_type === 'son' || memberDetails[key].member_type === 'daughter')
      kidsIncluded = true
  })

  if (isProposerIncluded) selfIncluded = true

  if (selfIncluded) displayText = displayText + 'Self'

  if (spouseIncluded && selfIncluded) displayText = displayText + ' + Spouse'
  else if (spouseIncluded) displayText = displayText + 'Spouse'

  if (kidsIncluded) {
    const kidText = getkidsCount(memberDetails) < 2 ? 'Kid' : 'Kids'
    if (selfIncluded || spouseIncluded) {
      displayText = displayText + ' + ' + getkidsCount(memberDetails) + kidText
    } else {
      displayText = displayText + getkidsCount(memberDetails) + ' ' + kidText
    }
  }

  return displayText

  // let displayText = '
}

export const getMemberTypesArray = (type: string): any => {
  // console.log(type)
  switch (type) {
    case 'selfSpouseKids':
      return ['proposer', 'son', 'daughter', 'spouse']
    case 'parents':
      return ['father', 'mother']
    case 'allMembers':
      return ['proposer', 'son', 'father', 'mother', 'daughter', 'spouse']
    default:
      return ['proposer', 'son', 'father', 'mother', 'daughter', 'spouse']
  }
}

export const getAdultsCount = (
  memberDetails: {
    [key: string]: MemberData
  },
  isProposerIncluded: boolean,
  type: string,
) => {
  let count = 1
  Object.keys(memberDetails).forEach((key) => {
    if (getMemberTypesArray(type).includes(memberDetails[key].member_type)) {
      if (memberDetails[key].type === 'adult') {
        count = count + 1
      }
    }
  })
  // if (isProposerIncluded) {
  //     count = count + 1
  // }
  return count
}

export const getkidsCount = (memberDetails: { [key: string]: MemberData }) => {
  let count = 0
  Object.keys(memberDetails).forEach((key) => {
    if (memberDetails[key].type === 'child') {
      count = count + 1
    }
  })
  return count
}
