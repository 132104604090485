import { Box, Center, Flex, Grid, Text } from '@chakra-ui/react'
import { ProposalCustomerDetailsDesktop } from './ProposalCustomerDetailsDesktop'
import { ProposalVehicleDetailsDesktop } from './ProposalVehicleDetailsDesktop'
import { UploadDocuments } from '../UploadDocuments'
import { ProposalNomineeDetailsDesktop } from './ProposalNomineeDetailsDesktop'
import { ProposalPaymentDesktop } from './ProposalPaymentDesktop'
import { ElevatedButton } from 'Core'
import { MdArrowLeft } from 'react-icons/md'
import { UseFormReturn } from 'react-hook-form'
import { TMotorProposalFormType } from '../Forms/FormModel'
import { useAppDispatch, useAppSelector, useVehicleType } from 'app/hooks'
import { toggleProposalScreen } from 'features/Motor'
import { ProposalSPTaggingDesktop } from './ProposalSPTaggingDesktop'
import { ProposalCustomerConsentForm } from '../Forms/ProposalCustomerConsentForm'
import { ProposalCustomerConsentDesktop } from './ProposalCustomerConsentDesktop'

interface IMotorProposalDesktop {
  form: UseFormReturn<TMotorProposalFormType, any>
}

const MotorProposalDesktop = (props: IMotorProposalDesktop) => {
  const dispatch = useAppDispatch()

  const { businessrole_name, role_type } = useAppSelector((state) => state.user.authenticatedUser)
  const { spConsentStatus } = useAppSelector((state) => state.spTagging)

  const { proposalScreen, proposalStatus } = useAppSelector((state) => state.proposalPage)
  const { form } = props
  const proposalDetailsInStore = useAppSelector((state) => state.proposalPage.proposalDetails)

  const { isIndividual } = useAppSelector((state) => state.quotationsDetails)

  const { param: vehicleType } = useVehicleType()
  return (
    <Box>
      <Grid gap='5' marginY='10' marginX='10' gridTemplateColumns='1fr 5fr'>
        {proposalScreen === 'one' ? (
          vehicleType === 'is_gcv' || vehicleType === 'is_pcv' ? (
            <Text></Text>
          ) : (
            <UploadDocuments rounded form={form} />
          )
        ) : (
          <Center
            className={
              proposalStatus === 'success' || spConsentStatus !== 'not-sent' ? 'disabled' : ''
            }
          >
            <ElevatedButton
              variant='outline'
              onClick={() => {
                // setShowScreenOne((prev) => !prev)
                if (proposalScreen === 'two') {
                  dispatch(toggleProposalScreen('one'))
                } else if (proposalScreen === 'three') {
                  dispatch(toggleProposalScreen('two'))
                }
                // setProposalValueInForm(proposalDetailsInStore, form)
              }}
              leftIcon={<MdArrowLeft />}
              colorScheme='primary'
            >
              Edit Previous Details
            </ElevatedButton>
          </Center>
        )}
        <Flex>
          {proposalScreen === 'one' ? (
            <>
              <ProposalVehicleDetailsDesktop rounded form={form} />
              <ProposalCustomerDetailsDesktop
                rounded
                // setShowScreenOne={setShowScreenOne}
                form={form}
              />
            </>
          ) : proposalScreen === 'two' ? (
            <>
              <ProposalNomineeDetailsDesktop rounded form={form} />
              {businessrole_name === 'NSP' && isIndividual === true && (
                <ProposalSPTaggingDesktop form={form} />
              )}
              {/* <ProposalCustomerConsentDesktop /> */}
              {/* <ProposalPaymentDesktop rounded form={form} /> */}
            </>
          ) : (
            <>
              <ProposalCustomerConsentDesktop />
              <ProposalPaymentDesktop rounded form={form} />
            </>
          )}
        </Flex>
      </Grid>
    </Box>
  )
}

export { MotorProposalDesktop }
