import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Select,
  Skeleton,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Divider,
} from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import { DashboardHeading, TextInput } from 'Core'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DownloadIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons'
import { BiEditAlt } from 'react-icons/bi'
import { Header } from 'Components'
import { differenceInDays, format, parse } from 'date-fns'
import { IMotorSalesBankParams } from 'Models/RequestModels'
import {
  useLazyLeadsListingQuery,
  useCloseLeadMutation,
  useReassignLeadMutation,
} from 'Services/API/leads.api'
import { ChangeEvent, useState, useEffect, useRef, useId } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useDeviceType } from 'app/hooks'
import { updateAllDetails } from 'features/Home'
import {
  updateVehicleDetailsForm,
  updateVehicleInsuranceForm,
  updateVehicleDetailsFormLeads,
  updateAllQuotationDetails,
  updateProposalDetails,
  toggleProposalScreen,
  updateConsentFormLink,
  updateProposalSuccessResponse,
  updatePaymentActivated,
  resetProposalStatus,
} from 'features/Motor'
import { updateLeadID } from 'features/Leads'
import { ToastService } from 'Services/toast.service'
import {
  resetSpTaggingForm,
  setCustomerAccountsStatus,
  setSelectedAccount,
  setSelectedAccountId,
  updateAllDetailsSpTagging,
  updateCkycNumber,
  updateCustomerAccounts,
  updateProposalDetailsFromCBS,
  updateUcicId,
} from 'features/Equitas'
import { ISpData } from 'Models/ResponseModels/Equitas'
import { AsyncSelect, SingleValue } from 'chakra-react-select'
import { useLazyGetSpListingQuery } from 'Services/API/authentication.api'
import { getDefaultHeaders } from 'Services/baseQuery'
import { API_ENDPOINTS, LEADS_URL } from 'Constants'
import axios from 'axios'
import {
  updateCompleteMemberDetails,
  updateIsDataFetchingFromLeadStatus,
} from 'features/Health/memberDetails.health.slice'
import {
  setCustomerConsentIndentifier,
  setSPConsentIndentifier,
  updateCompleteCartData,
} from 'features/Health/cart.health.slice'
import {
  resetRecommendationState,
  updateAllQuotationDetailsLife,
  updateRelationship,
  updateSuitabilityData2,
} from 'features/Life/LifeQuotationDetails.slice'
import { updateAllPlanData, updateFilter } from 'features/Life/PlanData.slice'
import { updateLifeAssuredDetailsTyped } from 'features/Life/LifeCustomerDetails.life.slice'
import { decryptData } from 'Services/general'

interface ICloseLeadValues {
  lead_close_reason: string
  lead_uid: string
}

const Leads = () => {
  const { authtoken } = useAppSelector((state) => state.user.authenticatedUser)
  const { isOpen: isModalOpen, onToggle: onModalToggle, onClose: onModalClose } = useDisclosure()
  const [selectedLead, setSelectedLead] = useState('')

  const getDateInFormat = (date: string) => {
    // const dateArray = new Date(date).toISOString().split('T')[0].split('-')
    // return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    const t = `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`
    return t
  }

  const { isDesktop, isTablet, isMobile } = useDeviceType()

  const DataCell = ({ leadData, index }: { leadData: any; index: number }) => {
    const { isOpen, onToggle } = useDisclosure()

    return (
      <Box marginY='2' backgroundColor='white' fontSize='sm'>
        <Flex direction='column' alignItems='center' width='100%'>
          <Flex
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            shadow='DEFAULT'
            width='100%'
            paddingY='2'
            zIndex='10'
            borderLeft={isOpen ? '3px solid #1C4589' : '3px solid white'}
          >
            {leadData.sub_leads.length !== 0 ? (
              <Box width='100px' borderRight='1px solid #707070' marginRight='2'>
                {isOpen ? (
                  <Center>
                    <ChevronUpIcon onClick={onToggle} />
                  </Center>
                ) : (
                  <Center>
                    <ChevronDownIcon onClick={onToggle} />
                  </Center>
                )}
              </Box>
            ) : (
              <Box width='100px' marginRight='2'></Box>
            )}
            {queryParams.lead_state === 'closed' ? null : (
              <Box width='100px' marginRight='2'>
                <Text
                  onClick={
                    userDetails.authenticatedUser.firstname?.toLowerCase() !==
                      leadData.lead_owner.user_firstname?.toLowerCase() ||
                    userDetails.authenticatedUser.lastname?.toLowerCase() !==
                      leadData.lead_owner.user_lastname?.toLowerCase()
                      ? () => {
                          ToastService.showErrorToast({
                            title: 'Error',
                            message: 'Cannot close the lead',
                          })
                        }
                      : () => handleCloseLead(leadData.lead_uid)
                  }
                  cursor='pointer'
                  color='red'
                  fontSize='xs'
                >
                  Close x
                </Text>
              </Box>
            )}
            <Box width='200px'>
              <Text
                color='primary.400'
                cursor='pointer'
                textAlign='center'
                onClick={
                  queryParams.lead_state === 'closed' ||
                  userDetails.authenticatedUser.firstname?.toLowerCase() !==
                    leadData.lead_owner.user_firstname?.toLowerCase() ||
                  userDetails.authenticatedUser.lastname?.toLowerCase() !==
                    leadData.lead_owner.user_lastname?.toLowerCase()
                    ? () => {
                        ToastService.showErrorToast({
                          title: 'Error',
                          message: 'Cannot continue lead',
                        })
                      }
                    : () => {
                        if (leadData.product_name == 'health') {
                          if (leadData.lead_data?.cart_details.customer_consent_identifier) {
                            dispatch(
                              setCustomerAccountsStatus(
                                leadData.lead_data?.cart_details.customer_consent_identifier,
                              ),
                            )
                          }
                          if (leadData.lead_data?.cart_details.sp_consent_identifier) {
                            dispatch(
                              setSPConsentIndentifier(
                                leadData.lead_data?.cart_details.sp_consent_identifier,
                              ),
                            )
                          }
                          if (leadData.lead_data?.spTagging_details)
                            dispatch(
                              updateAllDetailsSpTagging(leadData.lead_data?.spTagging_details),
                            )

                          dispatch(
                            updateLeadID({
                              lead_uid: leadData.lead_uid,
                            }),
                          )
                          dispatch(updateIsDataFetchingFromLeadStatus(true))
                          dispatch(
                            updateCompleteMemberDetails({
                              memberDetails: leadData.lead_data.member_details.memberDetails,
                              isParentsIncluded:
                                leadData.lead_data.member_details.isParentsIncluded,
                              proposer: leadData.lead_data.member_details.proposer,
                              selfPincode: leadData.lead_data.member_details.selfPincode,
                              parentsPincode: leadData.lead_data.member_details.parentsPincode,
                              isProposerIncluded:
                                leadData.lead_data.member_details.isProposerIncluded,
                              sum_insured: leadData.lead_data.member_details.sum_insured,
                            }),
                          )
                          dispatch(
                            updateCompleteCartData({
                              cartDetails: leadData.lead_data.cart_details.cartDetails,
                              selectedQuotes: leadData.lead_data.cart_details.selectedQuotes,
                              selectedPlanType: leadData.lead_data.cart_details.selectedPlanType,
                              requestId: leadData.lead_data.cart_details.requestId,
                              totalAddedMembers: leadData.lead_data.cart_details.totalAddedMembers,
                            }),
                          )
                          dispatch(updateUcicId(leadData.lead_data.equitas_details.ucic))
                          dispatch(
                            updateCustomerAccounts(leadData.lead_data.equitas_details.accounts),
                          )
                          dispatch(updateCkycNumber(leadData.lead_data.equitas_details.ckyc_number))

                          if (leadData.lead_data.equitas_details.selectedAccount) {
                            dispatch(
                              setSelectedAccount(
                                leadData.lead_data.equitas_details.selectedAccount,
                              ),
                            )
                          }

                          dispatch(
                            setSelectedAccountId(
                              leadData.lead_data.equitas_details.selectedAccountId,
                            ),
                          )

                          dispatch(
                            setCustomerAccountsStatus(
                              leadData.lead_data.equitas_details.customerAccountStatus,
                            ),
                          )
                          dispatch(
                            updateProposalDetailsFromCBS(
                              leadData.lead_data.equitas_details.proposerDetailsFromCBS,
                            ),
                          )

                          if (
                            (new Date().getMonth() !==
                              new Date(leadData.created_at ?? '').getMonth() ||
                              new Date().getFullYear() !==
                                new Date(leadData.created_at ?? '').getFullYear() ||
                              new Date().getDate() -
                                new Date(leadData.created_at ?? '').getDate() >=
                                1) &&
                            (leadData.drop_off.toLowerCase() === 'proposer-insurer-details' ||
                              leadData.drop_off.toLowerCase() === 'health-questions' ||
                              leadData.drop_off.toLowerCase() === 'sp-validated')
                          ) {
                            // console.log(leadData.creation_date)
                            // console.log(leadData.created_at)
                            // console.log(new Date(leadData.creation_date ?? ''))
                            navigate('/health/quotes')
                            return
                          }

                          if (
                            leadData.drop_off.toLowerCase() === 'prequote' &&
                            Object.keys(leadData.lead_data.member_details.memberDetails).length ===
                              0
                          )
                            navigate('/health/quotes')
                          if (
                            leadData.drop_off.toLowerCase() === 'prequote' &&
                            Object.keys(leadData.lead_data.member_details.memberDetails).length > 0
                          )
                            navigate('/health/member-details')
                          if (leadData.drop_off.toLowerCase() === 'proposer-insurer-details') {
                            navigate('/health/proposal/proposer-insurer-details')
                            return
                          }
                          if (leadData.drop_off.toLowerCase() === 'addons') {
                            navigate('/health/proposal/addons')
                            return
                          }
                          if (leadData.drop_off.toLowerCase() === 'health-questions') {
                            navigate('/health/proposal/health-questions')
                            return
                          }
                          if (leadData.drop_off.toLowerCase() === 'sp-validated') {
                            navigate('/health/payment')
                            return
                          }
                          if (leadData.drop_off.toLowerCase() === 'cart') navigate('/health/quotes')
                          return
                        } else if (leadData.product_name == 'life') {
                          if (leadData.lead_data?.spTagging_details) {
                            dispatch(
                              updateAllDetailsSpTagging(leadData.lead_data?.spTagging_details),
                            )
                          }
                          if (leadData.drop_off.toLowerCase() === 'partner redirection') {
                            if (leadData.lead_owner.role === 'SP') {
                              window.open(
                                leadData.lead_data.lifeQuotationDetails['redirectionURL'],
                                '_target',
                              )
                            }
                            return
                          }
                          dispatch(
                            updateLeadID({
                              lead_uid: leadData.lead_data.lead_details.lead_uid,
                            }),
                          )
                          dispatch(updateUcicId(leadData.lead_data.equitas_details.ucic))
                          // dispatch(
                          //   updateRelationship(leadData.lead_data.lifeCustomerDetails.relationship),
                          // )
                          dispatch(
                            updateCustomerAccounts(leadData.lead_data.equitas_details.accounts),
                          )
                          dispatch(
                            setSelectedAccountId(
                              leadData.lead_data.equitas_details.selectedAccountId,
                            ),
                          )
                          dispatch(
                            setCustomerAccountsStatus(
                              leadData.lead_data.equitas_details.customerAccountStatus,
                            ),
                          )
                          dispatch(
                            updateAllQuotationDetailsLife(leadData.lead_data.lifeQuotationDetails),
                          )
                          dispatch(
                            updateLifeAssuredDetailsTyped(
                              leadData.lead_data.lifeCustomerDetails.lifeAssuredDetailsTyped,
                            ),
                          )
                          if (
                            (new Date().getMonth() !==
                              new Date(leadData.created_at ?? '').getMonth() ||
                              new Date().getFullYear() !==
                                new Date(leadData.created_at ?? '').getFullYear() ||
                              new Date().getDate() -
                                new Date(leadData.created_at ?? '').getDate() >=
                                1) &&
                            (leadData.drop_off.toLowerCase() === 'pre-quotation' ||
                              leadData.drop_off.toLowerCase() === 'quotation' ||
                              leadData.drop_off.toLowerCase() === 'bi generation' ||
                              leadData.drop_off.toLowerCase() === 'bi approved')
                          ) {
                            navigate('/recommended-plan')
                            return
                          }
                          if (leadData.drop_off.toLowerCase() === 'suitability assessment') {
                            navigate('/recommended-plan')
                            return
                          } else if (leadData.drop_off.toLowerCase() === 'pre-quotation') {
                            navigate('/quotation-page')
                            return
                          } else if (leadData.drop_off.toLowerCase() === 'quotation') {
                            dispatch(updateFilter(leadData.lead_data.filterData.filter))
                            navigate('/after-quotation-page')
                            return
                          } else if (
                            leadData.drop_off.toLowerCase() === 'bi generation' ||
                            leadData.drop_off.toLowerCase() === 'bi approved'
                          ) {
                            dispatch(
                              updateProposalDetailsFromCBS(
                                leadData.lead_data.equitas_details.proposerDetailsFromCBS,
                              ),
                            )
                            dispatch(updateAllPlanData(leadData.lead_data.filterData))
                            // dispatch(updateFilter(leadData.lead_data.filterData.filter.filter))
                            navigate('/consent-pending')
                            return
                          }
                        }

                        dispatch(
                          updateLeadID({
                            lead_uid: leadData.lead_data.lead_details.lead_uid,
                          }),
                        )

                        dispatch(updateAllDetails(leadData.lead_data.homepage_details))
                        dispatch(updateVehicleDetailsForm(leadData.lead_data.vehicle_details))
                        dispatch(
                          updateVehicleDetailsFormLeads(leadData.lead_data.insurance_details),
                        )
                        // dispatch(updateProposalDetailsFromCBS({}))
                        dispatch(updateUcicId(leadData.lead_data.equitas_details.ucic))
                        dispatch(
                          updateCustomerAccounts(leadData.lead_data.equitas_details.accounts),
                        )
                        dispatch(
                          setSelectedAccountId(
                            leadData.lead_data.equitas_details.selectedAccountId,
                          ),
                        )
                        dispatch(
                          setCustomerAccountsStatus(
                            leadData.lead_data.equitas_details.customerAccountStatus,
                          ),
                        )
                        dispatch(updateAllQuotationDetails(leadData.lead_data.quotation_details))
                        // Proposal Action Dispatching
                        dispatch(
                          updateProposalDetails(
                            leadData.lead_data.proposal_details.proposalDetails,
                          ),
                        )
                        dispatch(
                          toggleProposalScreen(leadData.lead_data.proposal_details.proposalScreen),
                        )
                        dispatch(updateAllDetailsSpTagging(leadData.lead_data?.spTagging_details))
                        dispatch(
                          updateConsentFormLink(
                            leadData.lead_data.proposal_details?.consentFormLink,
                          ),
                        )
                        dispatch(
                          updateProposalSuccessResponse(
                            leadData.lead_data.proposal_details?.proposalSuccessResponse,
                          ),
                        )
                        dispatch(
                          updatePaymentActivated(
                            leadData.lead_data.proposal_details?.paymentActivated,
                          ),
                        )
                        // Proposal Action Dispatching
                        if (
                          leadData.drop_off.toLowerCase() === 'quotes' ||
                          ((leadData.drop_off.toLowerCase() === 'proposal' ||
                            leadData.drop_off.toLowerCase() === 'sp validation' ||
                            leadData.drop_off.toLowerCase() === 'sp validation complete' ||
                            leadData.drop_off.toLowerCase() === 'customer consent' ||
                            leadData.drop_off.toLowerCase() === 'payment pending') &&
                            (new Date().getMonth() !==
                              new Date(leadData.created_at ?? '').getMonth() ||
                              new Date().getFullYear() !==
                                new Date(leadData.created_at ?? '').getFullYear() ||
                              new Date().getDate() -
                                new Date(leadData.created_at ?? '').getDate() >=
                                1))
                        ) {
                          navigate(`/motor/${leadData.product_name.toLowerCase()}/quotation`)
                          dispatch(resetProposalStatus())
                          dispatch(resetSpTaggingForm())
                        } else if (leadData.drop_off.toLowerCase() === 'prequote') {
                          navigate(`/motor/${leadData.product_name.toLowerCase()}/details`)
                        } else if (
                          leadData.drop_off.toLowerCase() === 'proposal' ||
                          leadData.drop_off.toLowerCase() === 'sp validation' ||
                          leadData.drop_off.toLowerCase() === 'sp validation complete' ||
                          leadData.drop_off.toLowerCase() === 'customer consent' ||
                          leadData.drop_off.toLowerCase() === 'payment pending'
                        ) {
                          navigate(`/motor/${leadData.product_name.toLowerCase()}/proposal`)
                        }
                      }
                }
              >
                {leadData.lead_uid}
              </Text>
            </Box>
            <Box width='150px'>
              <Text textAlign='center'>
                {/* {new Date(leadData.created_at).toLocaleDateString() ?? '-'}
                {new Date(leadData.created_at).toLocaleTimeString() ?? '-'} */}
                {getDateInFormat(leadData.created_at) ?? '-'}
              </Text>
            </Box>
            <Box width='150px'>
              <Text textAlign='center'>
                {/* {format(new Date(leadData.modified_at), 'dd/MM/yyyy') ?? '-'} */}
                {getDateInFormat(leadData.modified_at) ?? '-'}
              </Text>
            </Box>
            <Box width='200px'>
              <Text textAlign='center'>
                {`${leadData.lead_generator.user_firstname || ''} ${
                  leadData.lead_generator.user_lastname || ''
                }` ?? '-'}
              </Text>
            </Box>
            <Box width='200px'>
              <Flex direction='row' alignItems='center' justifyContent='center'>
                <Text marginRight='2'>
                  {`${leadData.lead_owner.user_firstname || ''} ${
                    leadData.lead_owner.user_lastname || ''
                  }` ?? '-'}
                </Text>
                {/* <Text marginRight='2'>{leadData.lead_owner.user_firstname}</Text> */}
                {queryParams.lead_state === 'open' ? (
                  <BiEditAlt
                    size={20}
                    onClick={() => {
                      onModalToggle()
                      setSelectedLead(leadData.lead_uid)
                    }}
                  />
                ) : null}
              </Flex>
            </Box>
            <Box width='150px'>
              <Text textAlign='center'>{leadData.customer_id.ucic}</Text>
            </Box>
            <Box width='150px'>
              <Text textAlign='center'>{leadData.vehicle_number ?? '-'}</Text>
            </Box>
            <Box width='200px'>
              <Text textAlign='center'>{leadData.customer_id.cx_firstname}</Text>
            </Box>
            <Box width='150px'>
              {leadData?.product_name?.toLowerCase() === 'health' ? (
                <Text textAlign='center' whiteSpace='initial'>
                  {leadData.drop_off} (Health)
                </Text>
              ) : leadData?.product_name?.toLowerCase() === 'life' ? (
                <Text textAlign='center' whiteSpace='initial'>
                  {leadData.drop_off} (Life)
                </Text>
              ) : (
                <Text textAlign='center' whiteSpace='initial'>
                  {leadData.drop_off} (Motor)
                </Text>
              )}
            </Box>
            <Box width='200px'>
              <Text textAlign='center'>{leadData?.product_name?.toUpperCase()}</Text>
            </Box>
            <Box width='200px'>
              <Text textAlign='center' whiteSpace='initial'>
                {leadData.insurer_name ?? '-'}
              </Text>
            </Box>
            <Box width='150px' height='100%'>
              <Text textAlign='center'>
                {leadData.premium ? `₹ ${parseInt(leadData.premium).toLocaleString('en-IN')}` : '-'}
                {/* {leadData.lead_data.quotation_details?.selectedQuote
                  ? `₹ ${leadData.lead_data.quotation_details?.selectedQuote?.premium_total.toLocaleString(
                      'en-IN',
                    )}`
                  : '-'} */}
              </Text>
            </Box>
          </Flex>
          <Box backgroundColor='#F1F1F1' width='100%'>
            <Collapse in={isOpen} animateOpacity>
              <Flex direction='column' alignItems='center' width='100%'>
                {leadData.sub_leads.length ? (
                  leadData.sub_leads.map((subLead: any) => (
                    <Flex
                      direction='row'
                      justifyContent='space-between'
                      width='100%'
                      alignItems='center'
                      paddingY='2'
                      borderBottom='1px solid #B6B6C1'
                      key={subLead.id}
                    >
                      <Box width='100px' marginRight='2px'></Box>
                      <Box width='100px' marginRight='2px'></Box>
                      <Box width='200px' textAlign='center'>
                        <Text>{subLead.lead_uid}</Text>
                      </Box>
                      <Box width='150px'>
                        <Text textAlign='center'>
                          {/* {format(new Date(subLead.created_at), 'dd/MM/yyyy') ?? '-'} */}
                          {getDateInFormat(subLead.created_at) ?? '-'}
                        </Text>
                      </Box>
                      <Box width='150px'>
                        <Text textAlign='center'>
                          {/* {format(new Date(subLead.modified_at), 'dd/MM/yyyy') ?? '-'} */}
                          {getDateInFormat(subLead.modified_at) ?? '-'}
                        </Text>
                      </Box>
                      <Box width='200px'>
                        <Text textAlign='center'>
                          {`${subLead.lead_generator.user_firstname || ''} ${
                            subLead.lead_generator.user_lastname || ''
                          }` ?? '-'}
                        </Text>
                      </Box>
                      <Box width='200px'>
                        <Flex direction='row' alignItems='center' justifyContent='center'>
                          <Text marginRight='2' textAlign='center'>
                            {`${subLead.lead_owner.user_firstname || ''} ${
                              subLead.lead_owner.user_lastname || ''
                            }` ?? '-'}
                          </Text>
                          {/* <BiEditAlt size={20} display={!isAdmin() ? '' : 'none'} /> */}
                        </Flex>
                      </Box>
                      <Box width='150px'>
                        <Text textAlign='center'>{subLead.customer_id.ucic}</Text>
                      </Box>
                      <Box width='150px'>
                        <Text textAlign='center'>{subLead.vehicle_number ?? '-'}</Text>
                      </Box>
                      <Box width='200px'>
                        <Text textAlign='center'>{subLead.customer_id.cx_firstname}</Text>
                      </Box>
                      <Box width='150px'>
                        <Text textAlign='center'>{subLead.drop_off}</Text>
                      </Box>
                      <Box width='200px'>
                        <Text textAlign='center'>{subLead.product_name.toUpperCase()}</Text>
                      </Box>
                      <Box width='200px'>
                        <Text textAlign='center'>{subLead.insurer_name ?? '-'}</Text>
                      </Box>
                      <Box width='150px'>
                        <Text textAlign='center'>
                          {subLead.premium
                            ? `₹ ${parseInt(subLead.premium).toLocaleString('en-IN')}`
                            : '-'}
                          {/* {subLead.lead_data.quotation_details?.selectedQuote
                            ? `₹ ${subLead.lead_data.quotation_details.selectedQuote?.premium_total.toLocaleString(
                                'en-IN',
                              )}`
                            : '-'} */}
                        </Text>
                      </Box>
                    </Flex>
                  ))
                ) : (
                  <Text paddingY='2' width='100%'>
                    No History
                  </Text>
                )}
              </Flex>
            </Collapse>
          </Box>
        </Flex>
      </Box>
    )
  }

  const [data, setData] = useState([])
  const [reportDownloading, setReportDownloading] = useState(false)
  const [perPage, setPerPage] = useState(20)
  const userDetails = useAppSelector((state) => state.user)
  const [leadsListing, { isError }] = useLazyLeadsListingQuery()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [closeLeadValues, setCloseLeadValues] = useState<ICloseLeadValues>({
    lead_close_reason: '',
    lead_uid: '',
  })
  const cancelRef: any = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [closeLead, { isLoading: isCloseLeadLoading, isError: isCloseLeadError }] =
    useCloseLeadMutation()
  const [reassignLead, { isLoading: leadReassignLoading, isSuccess: leadReassignSuccess }] =
    useReassignLeadMutation()
  const [getSpListing, { isLoading: spListLoading, isSuccess: spListSuccess }] =
    useLazyGetSpListingQuery()
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    page_size: 20,
    lead_state: 'open',
  })

  const resetState = {
    created_at: '',
    drop_off: '',
    insurer_name: '',
    lead_state: queryParams.lead_state,
    lead_uid: '',
    modified_at: '',
    page: 1,
    page_size: 20,
    product_name: '',
    vehicle_number: '',
  }

  console.log(queryParams)

  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(null)
  const [selectedSp, setSelectedSp] = useState<ISpData>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const getLeadsListing = async () => {
    setIsLoading(true)
    setTotalCount(null)
    try {
      const response = await leadsListing(queryParams).unwrap()
      if (response?.results?.length) {
        setData(response?.results)
        setTotalCount(response?.total_count)
      } else {
        setTotalPages(0)
        setIsLoading(false)
        setData([])
        setTotalCount(null)
      }
      setTotalPages(Math.ceil(response?.total_count / perPage) || 1)
      setIsLoading(false)
    } catch (e) {
      setTotalPages(0)
      setIsLoading(false)
      setTotalCount(null)
      setData([])
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => getLeadsListing(), 900)
    return () => clearTimeout(timeoutId)
  }, [queryParams])

  const handleSubmit = async () => {
    try {
      const response = await reassignLead({
        user_firstname: selectedSp?.firstname,
        lead_uid: selectedLead,
        // email: selectedSp?.email,
        username: selectedSp?.user__username,
        user_lastname: selectedSp?.lastname,
        reassign_to_user_id: selectedSp?.id?.toString(),
      }).unwrap()
      ToastService.showSuccessTaost({ title: 'Success', message: 'Lead Reassigned Successfully' })
      setSelectedSp(undefined)
      onModalClose()
      getLeadsListing()
    } catch (e: any) {
      ToastService.showErrorToast({ title: 'Error', message: e['message'] })
      setSelectedSp(undefined)
      onModalClose()
    }
  }

  const handleCloseLead = (uid: string) => {
    onOpen()
    setCloseLeadValues({ ...closeLeadValues, lead_uid: uid })
  }

  const columns2 = [
    { label: '', width: '100px' },
    { label: '', width: queryParams.lead_state === 'open' ? '100px' : '0px' },
    { label: 'Lead ID', width: '200px' },
    {
      label: 'Creation Date',
      width: '150px',
      format: (value: any) => value,
      value: 'created_at',
      filter: (
        <input
          onChange={(e) => filterChangeHandler(e, 'created_at')}
          max={format(new Date(), 'yyyy-MM-dd')}
          style={{ border: '1px solid', borderRadius: '2px', color: 'gray', padding: '2px' }}
          type='date'
        />
      ),
    },
    {
      label: 'Last Updated',
      width: '150px',
      format: (value: any) => format(new Date(value), 'dd/MM/yyyy'),
    },
    { label: 'Lead Generator', width: '200px' },
    { label: 'Lead Converter', width: '200px' },
    { label: 'UCIC ID', width: '150px' },
    { label: 'Vehicle Number', width: '180px' },
    { label: 'Customer Name', width: '200px' },
    {
      label: 'Drop Off',
      width: '150px',
      value: 'drop_off',
      filter: (
        <select
          onChange={(e) => filterChangeHandler(e, 'drop_off')}
          style={{ width: '80%', border: '1px solid', padding: '2px' }}
          value={queryParams.drop_off}
        >
          <option defaultChecked value=''>
            Select
          </option>
          <option value={'Proposal'}>Motor-Proposal</option>
          <option value={'Quotes'}>Motor-Quotes</option>
          <option value={'PreQuote'}>Motor-PreQuote</option>
          <option value={'proposer-insurer-details'}>Health-proposer-insurer-details</option>
          <option value={'cart'}>Health-cart</option>
          <option value={'prequote'}>Health-PreQuote</option>
        </select>
      ),
    },
    {
      label: 'Product Name',
      width: '200px',
      value: 'product_name',
      filter: (
        <select
          onChange={(e) => filterChangeHandler(e, 'product_name')}
          style={{ width: '80%', border: '1px solid', padding: '2px' }}
          value={queryParams.product_name}
        >
          <option defaultChecked value=''>
            Select
          </option>
          <option>CAR</option>
          <option>BIKE</option>
          <option>GCV</option>
          <option>PCV</option>
          <option>HEALTH</option>
        </select>
      ),
      format: (value: string) => value.toUpperCase(),
    },
    { label: 'Insurer Name', width: '200px' },
    { label: 'Premium Amount', width: '150px' },
  ]

  const columns = [
    {
      label: 'Creation Date',
      value: 'created_at',
      format: (value: any) => format(new Date(value), 'dd/MM/yyyy'),
      filter: (
        <input
          onChange={(e) => filterChangeHandler(e, 'created_at')}
          max={format(new Date(), 'yyyy-MM-dd')}
          style={{ border: '1px solid', borderRadius: '2px', color: 'gray', padding: '2px' }}
          type='date'
        />
      ),
    },
    {
      label: 'STP Triggering ID',
      value: 'stp_trigger_by',
      format: (value: any) => (value ? value.user_firstname : '-'),
      filter: (
        <input
          onChange={(e) => filterChangeHandler(e, 'stp')}
          style={{ border: '1px solid', borderRadius: '2px', color: 'gray', padding: '2px' }}
          type='text'
        />
      ),
    },
    {
      label: 'SUB-Distribution ID',
      value: 'subDistribution',
      filter: (
        <input
          onChange={(e) => filterChangeHandler(e, 'subDistribution')}
          style={{ border: '1px solid', borderRadius: '2px', color: 'gray', padding: '2px' }}
          type='text'
        />
      ),
    },
    {
      label: 'Business Name',
      value: 'businessName',
      // filter: (
      //   <select
      //     onChange={(e) => filterChangeHandler(e, 'businessName')}
      //     style={{ width: '80%', border: '1px solid', padding: '2px' }}
      //     value={queryParams.businessName}
      //   >
      //     <option value={10}>10</option>
      //   </select>
      // ),
    },
    {
      label: 'Vehicle Number',
      value: 'vehicle_number',
      filter: (
        <input
          onChange={(e) => filterChangeHandler(e, 'vehicle_number')}
          style={{ border: '1px solid', borderRadius: '2px', color: 'gray', padding: '2px' }}
          type='text'
        />
      ),
    },
    {
      label: 'Product Name',
      value: 'product_name',
      filter: (
        <select
          onChange={(e) => filterChangeHandler(e, 'product_name')}
          style={{ width: '80%', border: '1px solid', padding: '2px' }}
          value={queryParams.product_name}
        >
          <option defaultChecked value=''>
            Select
          </option>
          <option>CAR</option>
          <option>BIKE</option>
          <option>GCV</option>
          <option>PCV</option>
          <option>HEALTH</option>
        </select>
      ),
      format: (value: string) => value?.toUpperCase() ?? '-',
    },
    {
      label: 'Journey Status',
      value: 'drop_off',
      filter: (
        <select
          onChange={(e) => filterChangeHandler(e, 'drop_off')}
          style={{ width: '80%', border: '1px solid', padding: '2px' }}
          value={queryParams.drop_off}
        >
          <option defaultChecked value=''>
            Select
          </option>
          <option value={'Proposal'}>Proposal</option>
          <option value={'Quotes'}>Quotes</option>
          <option value={'PreQuote'}>PreQuote</option>
        </select>
      ),
    },
  ]

  const filterChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    stateName: string,
  ) => {
    console.log(e.target.value, stateName, 'sdsdsds')
    setQueryParams((prev: any) => ({
      ...prev,
      [stateName]:
        stateName === 'created_at' || stateName === 'modified_at'
          ? e.target.value
            ? format(new Date(e.target.value.toLocaleString()), 'dd/MM/yyyy')
            : ''
          : e.target.value,
    }))
  }

  const handleRemoveSubmit = async () => {
    onClose()
    try {
      const response = await closeLead(closeLeadValues).unwrap()
      ToastService.showSuccessTaost({
        title: 'Success',
        message: 'Lead Closed Successfully',
      })
      setCloseLeadValues({
        lead_close_reason: '',
        lead_uid: '',
      })
      getLeadsListing()
    } catch (e) {
      ToastService.showErrorToast({
        title: 'Error',
        message: 'Cannot close the lead',
      })
      // console.log(e)
    }
  }

  const handleChange = (sp: SingleValue<ISpData>) => {
    if (sp) {
      setSelectedSp(sp)
    }
  }

  const spFormatter = (spArray: ISpData[]) => {
    return spArray.map((spData) => {
      return {
        ...spData,
        value: spData.user__username,
        label: `${spData.firstname ?? ''} ${spData.lastname ?? ''} (${
          spData.user__username ?? ''
        })`,
      }
    })
  }

  const loadSpDataOptions = (inputSearchValue: string, callback: (options: ISpData[]) => void) => {
    if (inputSearchValue.length >= 2) {
      getSpListing({
        user_id: userDetails.authenticatedUser.id?.toString() ?? '',
        type: 'all',
      }).then((resp) => {
        const spData = JSON.parse(decryptData(resp.data.response_data))
        if (spData) callback(spFormatter(spData))
      })
    }
  }

  const handleTab = (value: string) => {
    // setQueryParams({
    //   page: 1,
    //   page_size: 20,
    //   lead_state: value,
    // })
    setData([])
    setQueryParams((prev: any) => ({
      ...prev,
      lead_state: value,
    }))
    setQueryParams({ ...resetState, lead_state: value })
  }

  const downloadReport = async () => {
    setReportDownloading(true)
    try {
      const resp = await axios({
        baseURL: `${LEADS_URL + API_ENDPOINTS.DOWNLOAD_REPORT}`,
        headers: getDefaultHeaders(authtoken),
        params: queryParams,
      })
      const csvData = new Blob([resp.data], { type: 'text/csv' })
      const anchor = document.createElement('a')
      anchor.href = URL.createObjectURL(csvData)
      anchor.target = '_blank'
      anchor.download = 'leads_report.csv'
      anchor.click()
      setReportDownloading(false)
    } catch (e) {
      setReportDownloading(false)
      ToastService.showErrorToast({ title: 'Failure', message: 'Could not download report' })
      console.log(e)
    }
  }

  return (
    <>
      <Box position='sticky' top='0' zIndex='999'>
        <Header />
        <Flex
          paddingY='0.5rem'
          direction='column'
          alignItems='center'
          width='100%'
          justifyContent='start'
        >
          <Text color='primary.500' fontSize='xl' marginTop='2' fontWeight='bold'>
            My Leads
          </Text>
        </Flex>
        <DashboardHeading
          title={
            <>
              <Text
                cursor='pointer'
                onClick={() => handleTab('open')}
                borderBottom={queryParams.lead_state === 'open' ? '1px solid' : ''}
                fontSize='lg'
                fontWeight='bold'
                color='primary.500'
                as='a'
              >
                Open Lead
              </Text>{' '}
              <Text
                onClick={() => handleTab('closed')}
                borderBottom={queryParams.lead_state !== 'open' ? '1px solid' : ''}
                fontSize='lg'
                cursor='pointer'
                fontWeight='bold'
                color='primary.500'
                as='a'
              >
                Closed Lead
              </Text>
            </>
          }
        />
        <Box mx='20px'>
          <Flex
            paddingX='1rem'
            paddingY='0.5rem'
            border='1px'
            alignItems='center'
            justifyContent='space-between'
            bgColor='#fff'
            direction={isMobile ? 'column' : 'row'}
          >
            <Flex direction={isMobile ? 'column' : 'row'} alignItems='center' fontSize='md'>
              <Text whiteSpace='nowrap'>
                Showing <b>{perPage}</b> of <b>{totalCount ?? ''}</b> items
              </Text>
              <Divider
                display={isMobile ? 'none' : ''}
                orientation='vertical'
                height='50px'
                marginX='4'
              />
              <Text fontWeight='bold' whiteSpace='nowrap'>
                Items Per Page:
              </Text>
              <Select
                value={perPage}
                onChange={(e) => {
                  setQueryParams((prev: any) => ({
                    ...prev,
                    page_size: e.target.value,
                    page: 1,
                  }))
                  setPerPage(parseInt(e.target.value))
                }}
                marginX='4'
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </Select>
            </Flex>
            <Flex direction='row' marginY={isMobile ? '2' : ''}>
              <Button size='sm' marginRight='2' onClick={() => setQueryParams(resetState)}>
                Clear Filter
              </Button>
              <Button
                leftIcon={<DownloadIcon />}
                size='sm'
                onClick={downloadReport}
                isLoading={reportDownloading}
              >
                Download Report
              </Button>
            </Flex>
          </Flex>
          <TableContainer>
            <Table
              overflow='scroll'
              minW='1200px'
              bgcolor='#fff'
              border='1px'
              borderTop='none'
              variant='simple'
              colorScheme='gray'
            >
              <Box width='100%'>
                <Flex
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  marginY='2'
                >
                  {columns2.map((column) => (
                    <Flex
                      direction='row'
                      key={column.value}
                      alignItems='center'
                      width={column.width}
                      justifyContent='center'
                    >
                      <Text color='#BCBBC5'>{column.label}</Text>
                      <Flex
                        mt='1px'
                        ml='5px'
                        direction='column'
                        display={column.label ? 'flex' : 'none'}
                      >
                        <TriangleUpIcon color={'#BCBBC5'} h='6px' />
                        <TriangleDownIcon color={'#BCBBC5'} h='6px' />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
                <Flex
                  direction='row'
                  alignItems='center'
                  marginY='1'
                  backgroundColor='white'
                  justifyContent='space-between'
                  shadow='DEFAULT'
                  paddingY='2'
                  width={queryParams.lead_state === 'open' ? '2330px' : '2230px'}
                >
                  <Box width='100px' marginX='2'></Box>
                  <Box
                    width={queryParams.lead_state === 'open' ? '100px' : '0px'}
                    marginX='2'
                  ></Box>
                  <Box width='200px' marginX='2'>
                    <TextInput
                      value={queryParams.lead_uid}
                      onChange={(e) => filterChangeHandler(e, 'lead_uid')}
                    />
                  </Box>
                  <Box width='150px' marginX='2'>
                    <Input
                      type='date'
                      max={format(new Date(), 'yyyy-MM-dd')}
                      value={
                        queryParams.created_at
                          ? format(
                              parse(queryParams.created_at, 'dd/MM/yyyy', new Date()),
                              'yyyy-MM-dd',
                            )
                          : ''
                      }
                      onChange={(e) => filterChangeHandler(e, 'created_at')}
                    />
                  </Box>
                  <Box width='150px' marginX='2'>
                    <Input
                      type='date'
                      max={format(new Date(), 'yyyy-MM-dd')}
                      value={
                        queryParams.modified_at
                          ? format(
                              parse(queryParams.modified_at, 'dd/MM/yyyy', new Date()),
                              'yyyy-MM-dd',
                            )
                          : ''
                      }
                      onChange={(e) => filterChangeHandler(e, 'modified_at')}
                    />
                    {/* <TextInput /> */}
                  </Box>
                  <Box width='200px' marginX='2'>
                    {/* <TextInput /> */}
                  </Box>
                  <Box width='200px' marginX='2'>
                    {/* <TextInput /> */}
                  </Box>
                  <Box width='150px' marginX='2'>
                    {/* <TextInput /> */}
                  </Box>
                  <Box width='180px'>
                    <TextInput
                      value={queryParams.vehicle_number}
                      onChange={(e) => filterChangeHandler(e, 'vehicle_number')}
                    />
                  </Box>
                  <Box width='200px' marginX='2'>
                    {/* <TextInput /> */}
                  </Box>
                  <Box width='150px' marginX='2'>
                    <Select
                      value={queryParams.drop_off}
                      onChange={(e) => filterChangeHandler(e, 'drop_off')}
                    >
                      <option value=''>Select Drop Off</option>
                      <option value={'Proposal'}>Proposal (Motor)</option>
                      <option value={'SP Validation'}>SP Validation (Motor)</option>
                      <option value={'SP Validation Complete'}>
                        SP Validation Complete (Motor)
                      </option>
                      <option value={'Customer Consent'}>Customer Consent (Motor)</option>
                      <option value={'Payment Pending'}>Payment Pending (Motor)</option>
                      <option value={'Quotes'}>Quotes (Motor)</option>
                      <option value={'PreQuote'}>PreQuote (Motor)</option>
                      {queryParams.lead_state === 'closed' ? (
                        <option value={'Dropped Name Mismatch'}>
                          Dropped Name Mismatch (Motor)
                        </option>
                      ) : null}
                      <option value={'proposer-insurer-details'}>
                        proposer-insurer-details (Health)
                      </option>
                      <option value={'addons'}>Addons (Health)</option>
                      <option value={'cart'}>Cart (Health)</option>
                      <option value={'prequote'}>PreQuote (Health)</option>
                      <option value={'Suitability Assessment'}>
                        Suitability Assessment (Life)
                      </option>
                      <option value={'Pre-Quotation'}>Pre-Quotation (Life)</option>
                      <option value={'Quotation'}>Quotation (Life)</option>
                      <option value={'BI Generation'}>BI Generation (Life)</option>
                      <option value={'BI Approved'}>BI Approved (Life)</option>
                      <option value={'Partner Redirection'}>Partner Redirection (Life)</option>
                    </Select>
                  </Box>
                  <Box width='200px' marginX='2'>
                    <Select
                      value={queryParams.product_name}
                      onChange={(e) => filterChangeHandler(e, 'product_name')}
                    >
                      <option value=''>Select Product</option>
                      <option value='car'>CAR</option>
                      <option value='bike'>BIKE</option>
                      <option value='gcv'>GCV</option>
                      <option value='pcv'>PCV</option>
                      <option value='health'>HEALTH</option>
                      <option value='life'>LIFE</option>
                    </Select>
                  </Box>
                  <Box width='200px' marginX='2'>
                    <TextInput
                      value={queryParams.insurer_name}
                      onChange={(e) => filterChangeHandler(e, 'insurer_name')}
                    />
                  </Box>
                  <Box width='150px' marginX='2'>
                    {/* <TextInput /> */}
                  </Box>
                </Flex>
                <Box
                  height='60vh'
                  width={queryParams.lead_state === 'open' ? '2330px' : '2230px'}
                  overflowY='scroll'
                >
                  {isLoading ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : data.length === 0 ? (
                    <Text textAlign='center'>No Data Found</Text>
                  ) : (
                    data.map((lead: any, index) => (
                      <DataCell key={lead.id} leadData={lead} index={index} />
                    ))
                  )}
                </Box>
              </Box>
            </Table>
          </TableContainer>
          {totalPages > 1 && (
            <Flex justifyContent='center'>
              <ReactPaginate
                breakLabel='...'
                nextLabel={<ChevronRightIcon transform='scale(2)' />}
                onPageChange={(selectedPage) => {
                  setQueryParams((prev: any) => ({
                    ...prev,
                    page: selectedPage.selected + 1,
                  }))
                }}
                pageCount={totalPages}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                previousLabel={<ChevronLeftIcon transform='scale(2)' />}
                // forcePage={page && page - 1}
                forcePage={queryParams.page && queryParams.page - 1}
                className='paginate'
                pageClassName='page-item'
                nextLinkClassName='paginate-button'
                previousLinkClassName='paginate-button'
                activeClassName='active-page'
              />
            </Flex>
          )}
          <Modal
            isOpen={isModalOpen}
            onClose={() => {
              onModalClose()
              setSelectedSp(undefined)
            }}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Re-assign Lead</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <AsyncSelect
                  value={selectedSp}
                  loadOptions={loadSpDataOptions}
                  placeholder='Select Name'
                  onChange={handleChange}
                />
              </ModalBody>
              <Center>
                <ModalFooter>
                  <Button
                    variant='outline'
                    color='tertiary'
                    marginRight='2'
                    onClick={() => {
                      onModalClose()
                      setSelectedSp(undefined)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button isLoading={leadReassignLoading} onClick={handleSubmit}>
                    Submit
                  </Button>
                </ModalFooter>
              </Center>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose()
          setCloseLeadValues({
            lead_close_reason: '',
            lead_uid: '',
          })
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Close Lead
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text color='gray.800' m={1}>
                Reason
                <Text color='red' display='inline'>
                  {' *'}
                </Text>
              </Text>
              <Input
                onChange={(e: any) =>
                  setCloseLeadValues({
                    ...closeLeadValues,
                    lead_close_reason: e.target.value !== ' ' ? e.target.value : '',
                  })
                }
                placeholder='Reason'
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onClose()
                  setCloseLeadValues({
                    lead_close_reason: '',
                    lead_uid: '',
                  })
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!closeLeadValues.lead_close_reason}
                isLoading={isCloseLeadLoading}
                colorScheme='red'
                onClick={handleRemoveSubmit}
                ml={3}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export { Leads }
