import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STORAGE_CONSTANTS } from 'Constants'
import { IAuthenticatedUser } from 'Models/ResponseModels'
import { storageService } from 'Services/storage.service'

interface IState {
  authenticatedUser: IAuthenticatedUser
  isLoggedIn: boolean
  isRoleVerified: boolean
}

const initialState: IState = {
  // authenticatedUser:
  //   storageService.getValueFromLocalStorage(STORAGE_CONSTANTS.AUTHENTICATED_USER) ?? {},
  authenticatedUser: {},
  isLoggedIn: false,
  isRoleVerified: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateUserDetails: (state, action: PayloadAction<IAuthenticatedUser>) => {
      state.authenticatedUser = action.payload
      state.isLoggedIn = true
      // storageService.setValueInLocalStorage(STORAGE_CONSTANTS.AUTHENTICATED_USER, action.payload)
    },
    updateRoleVerified: (state, action: PayloadAction<boolean>) => {
      state.isRoleVerified = action.payload
    },
    resetUser: (state) => {
      console.log(state)
      // storageService.removeValueFromLocalStorage(STORAGE_CONSTANTS.AUTHENTICATED_USER)
      state.authenticatedUser = {}
      state.isLoggedIn = false
    },
  },
})

const { reducer } = userSlice
const { updateUserDetails, resetUser, updateRoleVerified } = userSlice.actions

export { reducer as userReducer, updateUserDetails, resetUser, updateRoleVerified }
