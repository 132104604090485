import { Box, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { IUseDeviceType } from 'app/hooks'
import { CommonImages } from 'Assets/Common'
import { TSelectedType } from 'features/Home'
import { HomeInsuranceCardItem, IHomeInsuranceCardItem } from './HomeInsuranceCardItem'

const {
  HomeImages: { privateCar, miscellaneous, commercial, health, life, sachet },
} = CommonImages

interface IHomeInsuranceCardSelector {
  deviceTypeObj: IUseDeviceType
  selectedType: TSelectedType
  updateSelectedType: ActionCreatorWithPayload<TSelectedType, string>
}

const HomeInsuranceCardSelector = (props: IHomeInsuranceCardSelector) => {
  const { deviceTypeObj, selectedType, updateSelectedType } = props

  const HomeInsuranceItems: IHomeInsuranceCardItem[] = [
    {
      label: 'private',
      selectedType: selectedType,
      updateSelectedType: updateSelectedType,
      // bgGradient:
      //   'linear-gradient(90deg, rgba(189,227,255,1) 0%, rgba(124,195,247,1) 57%, rgba(130,201,253,1) 100%)',
      bgGradient: 'primary.500',
      deviceTypeObj: deviceTypeObj,
      selectedImage: privateCar.white,
      unselectedImage: privateCar.colored,
    },
    {
      label: 'commercial',
      selectedType: selectedType,
      updateSelectedType: updateSelectedType,
      // bgGradient:
      //   'linear-gradient(315deg, rgba(244,220,158,1) 0%, rgba(248,208,101,1) 72%, rgba(251,245,220,1) 100%)',
      bgGradient: 'primary.500',
      deviceTypeObj: deviceTypeObj,
      selectedImage: commercial.white,
      unselectedImage: commercial.colored,
    },
    // {
    //   label: 'miscellaneous',
    //   selectedType: selectedType,
    //   updateSelectedType: updateSelectedType,
    //   bgGradient:
    //     'linear-gradient(315deg, rgba(240,82,129,1) 0%, rgba(247,135,168,1) 57%, rgba(238,158,182,1) 100%)',
    //   deviceTypeObj: deviceTypeObj,
    //   selectedImage: miscellaneous.white,
    //   unselectedImage: miscellaneous.colored,
    // },
    {
      label: 'health',
      selectedType: selectedType,
      updateSelectedType: updateSelectedType,
      // bgGradient:
      //   'linear-gradient(90deg, rgba(189,227,255,1) 0%, rgba(124,195,247,1) 57%, rgba(130,201,253,1) 100%)',
      bgGradient: 'primary.500',
      deviceTypeObj: deviceTypeObj,
      selectedImage: health.white,
      unselectedImage: health.colored,
    },
    {
      label: 'life',
      selectedType: selectedType,
      updateSelectedType: updateSelectedType,
      // bgGradient:
      //   'linear-gradient(90deg, rgba(189,227,255,1) 0%, rgba(124,195,247,1) 57%, rgba(130,201,253,1) 100%)',
      bgGradient: 'primary.500',
      deviceTypeObj: deviceTypeObj,
      selectedImage: life.white,
      unselectedImage: life.colored,
    },
    {
      label: 'sachet',
      selectedType: selectedType,
      updateSelectedType: updateSelectedType,
      // bgGradient:
      //   'linear-gradient(90deg, rgba(189,227,255,1) 0%, rgba(124,195,247,1) 57%, rgba(130,201,253,1) 100%)',
      bgGradient: 'primary.500',
      deviceTypeObj: deviceTypeObj,
      selectedImage: sachet.white,
      unselectedImage: sachet.colored,
    },
  ]

  const gridTemplateColumns = `repeat(${HomeInsuranceItems.length}, 1fr)`

  return (
    <Grid
      gridTemplateColumns={gridTemplateColumns}
      bgColor='white'
      rounded='full'
      boxShadow='DEFAULT'
    >
      {HomeInsuranceItems.map((item) => (
        <HomeInsuranceCardItem key={item.label} {...item} />
      ))}
    </Grid>
  )
}

export { HomeInsuranceCardSelector }
