import { Input } from '@chakra-ui/react'
import BimabossDropdown from 'BimabossDropdown'
import { AsyncSelect } from 'chakra-react-select'
import { PaymentSuccessDialog } from 'Core'
import MemberDetails from 'Pages/Health/MemberDetails'
import { useEffect, useState } from 'react'
// import { PaymentSuccessDialog } from './Proposal/Desktop/ProposalPaymentDesktop'
// import { PaymentSuccessDialog } from './Proposal/Desktop/ProposalPaymentDesktop'
import { ProposalSPTaggingDesktop } from './Proposal/Desktop/ProposalSPTaggingDesktop'

const Test = () => {
  return (
    <div>
      <MemberDetails />
    </div>
  )
}

export default Test
