import menuIcon from 'Assets/Common/menu.svg'
import about from 'Assets/Header/about-us.png'
import home from 'Assets/Header/home.svg'
import contact from 'Assets/Header/contact.png'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import logo from 'Assets/logo2.png'
import textStyles from 'Core/Styles/theme/foundations/textStyles'
import { FaUserPlus } from 'react-icons/fa'
import { useAppSelector } from 'app/hooks'
import { useLogoutMutation } from 'Services/API/authentication.api'

export const Mobile = () => {
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()
  const { isLoggedIn, authenticatedUser } = useAppSelector((state) => state.user)

  const [logout] = useLogoutMutation()

  return (
    <>
      <Flex justifyContent='space-between' shadow='DEFAULT'>
        <Flex>
          <Box onClick={() => setExpanded(true)} padding='4' cursor='pointer'>
            <img src={menuIcon} className='h-6 w-auto' />
          </Box>
          <Box gridColumn='2' padding='1' w='120px' h='45px' marginY='auto'>
            <Link to='/customer-account'>
              <img src={logo} alt='Equitas' />
            </Link>
          </Box>
        </Flex>
        {/* <Box marginY='auto' paddingRight='3'>
          <Button border='1px solid #3E8DF0' rounded='full' size='md' color='#3E8DF0'>
            <Flex gap='1'>
              <FaUserPlus color='#3E8DF0' />
              <Text>Save Lead</Text>
            </Flex>
          </Button>
        </Box> */}
      </Flex>
      {expanded && (
        <>
          <Box className='bg-white fixed left-0 h-full w-[70%] z-50 shadow-lg top-0 animate-LeftToRight py-[10px] px-6'>
            <Box w='120px' h='45px'>
              <Link to='/customer-account' className='logo'>
                <img alt='logo' className='w-40 my-auto py-2' src={logo} />
              </Link>
            </Box>
            {isLoggedIn && (
              <Flex color='primary.500' paddingY='4' justifyContent='center'>
                <Text fontWeight='semibold' fontSize='xl'>
                  Welcome, {authenticatedUser.firstname}
                </Text>
              </Flex>
            )}
            <Flex direction='column'>
              <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={home} className='w-6 h-6 my-auto ' />
                <Box zIndex='50' px='2' py='4'>
                  <Link to='/customer-account'>
                    <Text color='secondary'>Home</Text>
                  </Link>
                </Box>
              </Flex>
              <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={contact} className='w-6 h-6 my-auto ' />
                <Box zIndex='50' px='2' py='4'>
                  <Link to='/sales/motor'>
                    <Text color='secondary'>Motor Sales Report</Text>
                  </Link>
                </Box>
              </Flex>
              <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={contact} className='w-6 h-6 my-auto ' />
                <Box zIndex='50' px='2' py='4'>
                  <Link to='/sales-summary/motor'>
                    <Text color='secondary'>Motor Sales Summary</Text>
                  </Link>
                </Box>
              </Flex>
              <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={contact} className='w-6 h-6 my-auto ' />
                <Box zIndex='50' px='2' py='4'>
                  <Link to='/sales/health'>
                    <Text color='secondary'>Health Sales Report</Text>
                  </Link>
                </Box>
              </Flex>
              <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={contact} className='w-6 h-6 my-auto ' />
                <Box zIndex='50' px='2' py='4'>
                  <Link to='/my-leads'>
                    <Text color='secondary'>My Leads</Text>
                  </Link>
                </Box>
              </Flex>
              {/* <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={about} className='w-6 h-6 my-auto ' />
                <Box zIndex='50' px='2' py='4'>
                  <Link to='/'>
                    <Text color='secondary'> About Us</Text>
                  </Link>
                </Box>
              </Flex> */}
              {/* <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={contact} className='w-6 h-6 my-auto ' />
                <Box zIndex='50' px='2' py='4'>
                  <Link to='/'>
                    <Text variant='link' colorScheme='secondary'>
                      Contact
                    </Text>
                  </Link>
                </Box>
              </Flex> */}
              <Flex gap='1' py='2' zIndex='50' onClick={() => setExpanded(false)}>
                <img src={contact} className='w-6 h-6 my-auto ' />
                <Box cursor={'pointer'} zIndex='50' px='2' py='4'>
                  {isLoggedIn ? (
                    <>
                      <Text
                        onClick={() => {
                          logout(null)
                          navigate('/')
                        }}
                        color='secondary'
                      >
                        Sign Out
                      </Text>
                      {/* <Text
                      onClick={() => {
                        navigate('/my-leads')
                      }}
                    >
                      My Leads
                    </Text> */}
                    </>
                  ) : (
                    <Text onClick={() => navigate('/login')} colorScheme='black' variant='link'>
                      Login
                    </Text>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box
            className='bg-black z-40 fixed top-0 left-0 w-screen h-screen opacity-50'
            onClick={() => setExpanded(false)}
          ></Box>
        </>
      )}
    </>
  )
}
