// import {
//   Box,
//   Button,
//   Flex,
//   Input,
//   Select,
//   Spinner,
//   Table,
//   TableContainer,
//   Tbody,
//   Td,
//   Text,
//   Tfoot,
//   Th,
//   Thead,
//   Tr,
// } from '@chakra-ui/react'
// import ReactPaginate from 'react-paginate'
// import { DashboardHeading, ElevatedButton, Pagination, TextInput } from 'Core'
// import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons'
// import { Header } from 'Components'
// import { useMotorSalesBankQuery } from 'Services/API/motor.api'
// import { ChangeEvent, useEffect, useState } from 'react'
// import { format } from 'date-fns'
// import {
//   copyToClipboard,
//   onlyAllowTypingAlphabet,
//   onlyAllowTypingAlphabetNumeric,
// } from 'Services/general'
// import { debounce } from 'lodash'
// import { IHealthSalesBankParams } from 'Models/RequestModels/Health'
// import { useHealthSalesBankQuery } from 'Services/API/health.api'
// import axios from 'axios'
// import { API_ENDPOINTS, BASE_URL, BASE_URL_HEALTH } from 'Constants'
// import { getDefaultHeaders } from 'Services/baseQuery'
// import { useAppSelector } from 'app/hooks'
// import { ToastService } from 'Services/toast.service'
// import { IHealthSalesBankItem } from 'Models/ResponseModels/Health'

// const getHealthSalesActionItems = (rowItem: IHealthSalesBankItem) => {
//   if (rowItem.policy_status === 'pending') {
//     if (rowItem.payment_url) {
//       return (
//         <Flex direction='column'>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               window.open(rowItem.payment_url, '_blank')
//             }}
//           >
//             Complete Payment
//           </Button>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
//             }}
//           >
//             Copy Payment Link
//           </Button>
//         </Flex>
//       )
//     } else {
//       return <Text>-</Text>
//     }
//   } else if (rowItem.payment_status === 'failed') {
//     if (rowItem.payment_url) {
//       return (
//         <Flex direction='column'>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               window.open(rowItem.payment_url, '_blank')
//             }}
//           >
//             Complete Payment
//           </Button>
//           <Button
//             marginY='0.5rem'
//             size='xs'
//             onClick={() => {
//               if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
//             }}
//           >
//             Copy Payment Link
//           </Button>
//         </Flex>
//       )
//     } else {
//       return <Text>-</Text>
//     }
//   } else if (rowItem.policy_pdf) {
//     return (
//       <Button
//         size='xs'
//         marginY='0.5rem'
//         isDisabled={true}
//         onClick={() => {
//           window.open(rowItem.policy_pdf, '_blank')
//         }}
//         minWidth='4rem'
//       >
//         Download PDF
//       </Button>
//     )
//   }
//   return <Text>-</Text>
// }

// const HealthSalesReport = () => {
//   const { authtoken } = useAppSelector((state) => state.user.authenticatedUser)

//   const [queryParams, setQueryParams] = useState<IHealthSalesBankParams>({
//     limit: '10',
//     page_number: 1,
//     is_nstp: 0,
//   })

//   const [totalPages, setTotalPages] = useState(1)

//   const { data: reportData, isFetching: isReportLoading } = useHealthSalesBankQuery(queryParams, {
//     refetchOnMountOrArgChange: true,
//   })

//   useEffect(() => {
//     const pages = Math.ceil((reportData?.total_count ?? 0) / parseInt(queryParams.limit))
//     setTotalPages(pages)
//   }, [reportData])

//   const filterChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const timeoutId = setTimeout(() => {
//       const allParams = {
//         ...queryParams,
//         [e.target.name]: e.target.value,
//       }
//       const allParamsArray = Object.entries(allParams)
//       const filteredParams = allParamsArray.filter(([key, value]) => Boolean(value))
//       const newParams = Object.fromEntries(filteredParams)
//       setQueryParams((prev) => ({
//         ...newParams,
//         limit: prev.limit,
//         page_number: prev.page_number,
//         is_nstp: prev.is_nstp,
//       }))
//     }, 900)
//     return () => clearTimeout(timeoutId)
//   }

//   const [salesDownloadLoading, setSalesDownloadLoading] = useState(false)

//   return (
//     <>
//       <Box position='sticky' top='0' zIndex='999'>
//         <Header />
//         <DashboardHeading title='Health Sales Report' />
//         <Flex
//           paddingX='1rem'
//           paddingY='0.5rem'
//           border='1px'
//           alignItems='center'
//           justifyContent='space-between'
//           bgColor='#fff'
//         >
//           <Flex alignItems='center'>
//             <Text fontWeight='bold' whiteSpace='nowrap' fontSize='md'>
//               Items Per Page:
//             </Text>
//             <Select
//               value={queryParams.limit}
//               onChange={(e) => {
//                 setQueryParams((prev) => ({
//                   ...prev,
//                   limit: e.target.value,
//                   page_number: 1,
//                 }))
//               }}
//               marginX='4'
//             >
//               <option value={10}>10</option>
//               <option value={20}>20</option>
//               <option value={25}>25</option>
//               <option value={50}>50</option>
//             </Select>
//           </Flex>
//           <Button
//             isDisabled={salesDownloadLoading}
//             isLoading={salesDownloadLoading}
//             onClick={async () => {
//               setSalesDownloadLoading(true)
//               try {
//                 const resp = await axios({
//                   baseURL: `${BASE_URL_HEALTH + API_ENDPOINTS.HEALTH_SALES_BANK}`,
//                   headers: getDefaultHeaders(authtoken),
//                   params: {
//                     ...queryParams,
//                     download: true,
//                   },
//                 })
//                 const csvData = new Blob([resp.data], { type: 'text/csv' })
//                 const anchor = document.createElement('a')
//                 anchor.href = URL.createObjectURL(csvData)
//                 anchor.target = '_blank'
//                 anchor.download = 'sales_report.csv'
//                 anchor.click()
//               } catch (e) {
//                 ToastService.showErrorToast({
//                   title: 'Something went wrong!',
//                 })
//               } finally {
//                 setSalesDownloadLoading(false)
//               }
//             }}
//             leftIcon={<DownloadIcon />}
//             size='sm'
//           >
//             Download Report
//           </Button>
//         </Flex>
//       </Box>
//       <TableContainer border='1px' rounded='lg' margin='2'>
//         <Table size='md' variant='simple' colorScheme='appGrey'>
//           <Thead boxShadow='onlyBottom'>
//             <Tr>
//               <Th>Action</Th>
//               <Th>Proposal Date</Th>
//               <Th>Proposal No.</Th>
//               <Th>Transaction Date</Th>
//               <Th>SP Name</Th>
//               <Th>SP ID</Th>
//               <Th>Business Name</Th>
//               <Th>Customer Name</Th>
//               <Th>Plan Type</Th>
//               <Th>Insurer</Th>
//               <Th>Plan Name</Th>
//               <Th>Policy mode</Th>
//               <Th>U/w Status</Th>
//               <Th>Payment Status</Th>
//               <Th>Payment Date</Th>
//               <Th>Policy Status</Th>
//               <Th>Policy Number</Th>
//               <Th>Policy Issue Date</Th>
//               <Th>Rejection Reason</Th>
//               <Th>Proposal Premium</Th>
//               <Th>Additional Premium</Th>
//               <Th>Premium Collected</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             <Tr>
//               <Td></Td>
//               <Td>
//                 {/* <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='order_id'
//                 /> */}
//               </Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='proposal_number'
//                   onKeyDown={onlyAllowTypingAlphabetNumeric}
//                 />
//               </Td>
//               <Td></Td>
//               <Td></Td>
//               <Td>
//                 {/* <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='policy_number'
//                 /> */}
//               </Td>
//               <Td></Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='customer'
//                   onKeyDown={onlyAllowTypingAlphabet}
//                 />
//               </Td>
//               <Td>
//                 {/* <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='plan_type'
//                 /> */}
//               </Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='insurer_name'
//                   onKeyDown={onlyAllowTypingAlphabet}
//                 />
//               </Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='plan_name'
//                   onKeyDown={onlyAllowTypingAlphabet}
//                 />
//               </Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='policy_mode'
//                 />
//               </Td>
//               <Td></Td>
//               <Td>
//                 <Select variant='filled' name='payment_status' onChange={filterChangeHandler}>
//                   <option value=''>Select</option>
//                   <option value='pending'>Pending</option>
//                   <option value='completed'>Completed</option>
//                   <option value='failed'>Failed</option>
//                 </Select>
//               </Td>
//               <Td></Td>
//               <Td></Td>
//               <Td>
//                 <Input
//                   placeholder='Search'
//                   variant='filled'
//                   onChange={filterChangeHandler}
//                   name='policy_number'
//                   onKeyDown={onlyAllowTypingAlphabetNumeric}
//                 />
//               </Td>
//               <Td></Td>
//             </Tr>
//             {reportData && reportData.total_count === 0 && (
//               <Tr margin='0 auto' width='100%' display='block'>
//                 <Text as={'td'} p='5' fontSize='4xl' color='#939393' align='center'>
//                   No Data Found
//                 </Text>
//               </Tr>
//             )}
//             {reportData && reportData?.total_count > 0
//               ? reportData.result.map((item, idx) => {
//                   return (
//                     <Tr
//                       bgColor={idx % 2 === 0 ? 'appGrey.100' : 'white'}
//                       key={item.unique_identifier}
//                     >
//                       <Td>{getHealthSalesActionItems(item)}</Td>
//                       <Td>
//                         <Text>
//                           {item.proposal_date
//                             ? format(new Date(item.proposal_date), 'dd/MM/yyyy')
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.proposal_number ? item.proposal_number : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item.transaction_date
//                             ? format(new Date(item.transaction_date), 'dd/MM/yyyy')
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.sp_name ? item.sp_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.sp_id ? item.sp_id : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.business_name ? item.business_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.customer_name ? item.customer_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text> {item.plan_type ? item.plan_type : '-'} </Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.insurer_name ? item.insurer_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.insurance_name ? item.insurance_name : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item.policy_mode
//                             ? item.policy_mode === '1'
//                               ? 'Online'
//                               : item.policy_mode === '0'
//                               ? 'Offline'
//                               : 'Bulk Upload'
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item.uw_status != null
//                             ? item.uw_status === 1
//                               ? 'Approved'
//                               : 'Pending'
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.payment_status ? item.payment_status : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>-</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.policy_status ? item.policy_status : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>{item.policy_number ? item.policy_number : '-'}</Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item?.policy_start_date
//                             ? format(new Date(item.policy_start_date), 'dd/MM/yyyy')
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>-</Text>
//                       </Td>
//                       <Td>
//                         <Text>
//                           {item.policy_premium
//                             ? `₹ ${item.policy_premium.toLocaleString('en-IN')}`
//                             : '-'}
//                         </Text>
//                       </Td>
//                       <Td>
//                         <Text>-</Text>
//                       </Td>
//                       <Td>
//                         <Text>-</Text>
//                       </Td>
//                     </Tr>
//                   )
//                 })
//               : isReportLoading && (
//                   <Tr>
//                     <Spinner as='td' />
//                   </Tr>
//                 )}
//           </Tbody>
//           <Tfoot>
//             <Tr>
//               <Th>Action</Th>
//               <Th>Proposal Date</Th>
//               <Th>Proposal No.</Th>
//               <Th>Transaction Date</Th>
//               <Th>SP Name</Th>
//               <Th>SP ID</Th>
//               <Th>Business Name</Th>
//               <Th>Customer Name</Th>
//               <Th>Plan Type</Th>
//               <Th>Insurer</Th>
//               <Th>Plan Name</Th>
//               <Th>Policy mode</Th>
//               <Th>U/w Status</Th>
//               <Th>Payment Status</Th>
//               <Th>Payment Date</Th>
//               <Th>Policy Status</Th>
//               <Th>Policy Number</Th>
//               <Th>Policy Issue Date</Th>
//               <Th>Rejection Reason</Th>
//               <Th>Proposal Premium</Th>
//               <Th>Additional Premium</Th>
//               <Th>Premium Collected</Th>
//             </Tr>
//           </Tfoot>
//         </Table>
//       </TableContainer>
//       <Flex justifyContent='center'>
//         <ReactPaginate
//           breakLabel='...'
//           nextLabel={<ChevronRightIcon transform='scale(2)' />}
//           onPageChange={(selectedPage) => {
//             setQueryParams((prev) => ({
//               ...prev,
//               page_number: selectedPage.selected + 1,
//             }))
//           }}
//           pageCount={totalPages}
//           pageRangeDisplayed={2}
//           marginPagesDisplayed={2}
//           previousLabel={<ChevronLeftIcon transform='scale(2)' />}
//           // forcePage={page && page - 1}
//           forcePage={queryParams.page_number && queryParams.page_number - 1}
//           className='paginate'
//           pageClassName='page-item'
//           nextLinkClassName='paginate-button'
//           previousLinkClassName='paginate-button'
//           activeClassName='active-page'
//         />
//       </Flex>
//     </>
//   )
// }

// export { HealthSalesReport }

import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import { DashboardHeading, ElevatedButton, Pagination, TextInput } from 'Core'
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons'
import { Header } from 'Components'
import { useMotorSalesBankQuery } from 'Services/API/motor.api'
import { ChangeEvent, useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
  copyToClipboard,
  onlyAllowTypingAlphabet,
  onlyAllowTypingAlphabetNumeric,
} from 'Services/general'
import { debounce } from 'lodash'
import { IHealthSalesBankParams } from 'Models/RequestModels/Health'
import { useHealthSalesBankQuery } from 'Services/API/health.api'
import axios from 'axios'
import { API_ENDPOINTS, BASE_URL, BASE_URL_HEALTH } from 'Constants'
import { getDefaultHeaders } from 'Services/baseQuery'
import { useAppSelector } from 'app/hooks'
import { ToastService } from 'Services/toast.service'
import { IHealthSalesBankItem } from 'Models/ResponseModels/Health'

const getHealthSalesActionItems = (rowItem: IHealthSalesBankItem) => {
  if (rowItem.policy_status === 'pending') {
    if (rowItem.payment_url) {
      return (
        <Flex direction='column'>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              window.open(rowItem.payment_url, '_blank')
            }}
          >
            Complete Payment
          </Button>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
            }}
          >
            Copy Payment Link
          </Button>
        </Flex>
      )
    } else {
      return <Text>-</Text>
    }
  } else if (rowItem.payment_status === 'failed') {
    if (rowItem.payment_url) {
      return (
        <Flex direction='column'>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              window.open(rowItem.payment_url, '_blank')
            }}
          >
            Complete Payment
          </Button>
          <Button
            marginY='0.5rem'
            size='xs'
            onClick={() => {
              if (rowItem?.payment_url) copyToClipboard(rowItem?.payment_url)
            }}
          >
            Copy Payment Link
          </Button>
        </Flex>
      )
    } else {
      return <Text>-</Text>
    }
  } else if (rowItem.policy_pdf) {
    return (
      <Button
        size='xs'
        marginY='0.5rem'
        isDisabled={true}
        onClick={() => {
          window.open(rowItem.policy_pdf, '_blank')
        }}
        minWidth='4rem'
      >
        Download PDF
      </Button>
    )
  }
  return <Text>-</Text>
}

const HealthSalesReport = () => {
  const { authtoken } = useAppSelector((state) => state.user.authenticatedUser)

  const [queryParams, setQueryParams] = useState<IHealthSalesBankParams>({
    limit: '10',
    page_number: 1,
    is_nstp: 0,
  })

  const [totalPages, setTotalPages] = useState(1)

  const { data: reportData, isFetching: isReportLoading } = useHealthSalesBankQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    const pages = Math.ceil((reportData?.total_count ?? 0) / parseInt(queryParams.limit))
    setTotalPages(pages)
  }, [reportData])

  const filterChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const timeoutId = setTimeout(() => {
      const allParams = {
        ...queryParams,
        [e.target.name]: e.target.value,
      }
      const allParamsArray = Object.entries(allParams)
      const filteredParams = allParamsArray.filter(([key, value]) => Boolean(value))
      const newParams = Object.fromEntries(filteredParams)
      setQueryParams((prev) => ({
        ...newParams,
        limit: prev.limit,
        page_number: prev.page_number,
        is_nstp: prev.is_nstp,
      }))
    }, 900)
    return () => clearTimeout(timeoutId)
  }

  const [salesDownloadLoading, setSalesDownloadLoading] = useState(false)

  return (
    <>
      <Box position='sticky' top='0' zIndex='999'>
        <Header />
        <DashboardHeading title='Health Sales Report' />
        <Flex
          paddingX='1rem'
          paddingY='0.5rem'
          border='1px'
          alignItems='center'
          justifyContent='space-between'
          bgColor='#fff'
        >
          <Flex alignItems='center'>
            <Text fontWeight='bold' whiteSpace='nowrap' fontSize='md'>
              Items Per Page:
            </Text>
            <Select
              value={queryParams.limit}
              onChange={(e) => {
                setQueryParams((prev) => ({
                  ...prev,
                  limit: e.target.value,
                  page_number: 1,
                }))
              }}
              marginX='4'
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Select>
          </Flex>
          <Button
            isDisabled={salesDownloadLoading}
            isLoading={salesDownloadLoading}
            onClick={async () => {
              setSalesDownloadLoading(true)
              try {
                const resp = await axios({
                  baseURL: `${BASE_URL_HEALTH + API_ENDPOINTS.HEALTH_SALES_BANK}`,
                  headers: getDefaultHeaders(authtoken),
                  params: {
                    ...queryParams,
                    download: true,
                  },
                })
                const csvData = new Blob([resp.data], { type: 'text/csv' })
                const anchor = document.createElement('a')
                anchor.href = URL.createObjectURL(csvData)
                anchor.target = '_blank'
                anchor.download = 'sales_report.csv'
                anchor.click()
              } catch (e) {
                ToastService.showErrorToast({
                  title: 'Something went wrong!',
                })
              } finally {
                setSalesDownloadLoading(false)
              }
            }}
            leftIcon={<DownloadIcon />}
            size='sm'
          >
            Download Report
          </Button>
        </Flex>
      </Box>
      <TableContainer border='1px' rounded='lg' margin='2'>
        <Table size='md' variant='simple' colorScheme='appGrey'>
          <Thead boxShadow='onlyBottom'>
            <Tr>
              <Th>Action</Th>
              <Th>Proposal Date</Th>
              <Th>Proposal No.</Th>
              <Th>Transaction Date</Th>
              <Th>SP Name</Th>
              <Th>SP ID</Th>
              <Th>Sol ID</Th>
              <Th>UCIC ID</Th>
              <Th>Business Name</Th>
              <Th>Customer Name</Th>
              <Th>Plan Type</Th>
              <Th>Insurer</Th>
              <Th>Plan Name</Th>
              <Th>Policy mode</Th>
              <Th>U/w Status</Th>
              <Th>Payment Status</Th>
              <Th>Payment Date</Th>
              <Th>Policy Status</Th>
              <Th>Policy Number</Th>
              <Th>Policy Issue Date</Th>
              <Th>Rejection Reason</Th>
              <Th>Proposal Premium</Th>
              <Th>Additional Premium</Th>
              <Th>Premium Collected</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td></Td>
              <Td>
                <Input
                  type='date'
                  onChange={filterChangeHandler}
                  name='proposal_date'
                  variant='filled'
                />
              </Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='proposal_number'
                  onKeyDown={onlyAllowTypingAlphabetNumeric}
                />
              </Td>
              <Td></Td>
              <Td></Td>
              <Td>
                {/* <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='policy_number'
                /> */}
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='customer'
                  onKeyDown={onlyAllowTypingAlphabet}
                />
              </Td>
              <Td>
                {/* <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='plan_type'
                /> */}
              </Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='insurer_name'
                  onKeyDown={onlyAllowTypingAlphabet}
                />
              </Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='plan_name'
                  onKeyDown={onlyAllowTypingAlphabet}
                />
              </Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='policy_mode'
                />
              </Td>
              <Td></Td>
              <Td>
                <Select variant='filled' name='payment_status' onChange={filterChangeHandler}>
                  <option value=''>Select</option>
                  <option value='pending'>Pending</option>
                  <option value='completed'>Completed</option>
                  <option value='failed'>Failed</option>
                </Select>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td>
                <Input
                  placeholder='Search'
                  variant='filled'
                  onChange={filterChangeHandler}
                  name='policy_number'
                  onKeyDown={onlyAllowTypingAlphabetNumeric}
                />
              </Td>
              <Td></Td>
            </Tr>
            {reportData && reportData.total_count === 0 && (
              <Tr margin='0 auto' width='100%' display='block'>
                <Text as={'td'} p='5' fontSize='4xl' color='#939393' align='center'>
                  No Data Found
                </Text>
              </Tr>
            )}
            {reportData && reportData?.total_count > 0
              ? reportData.result.map((item, idx) => {
                  return (
                    <Tr
                      bgColor={idx % 2 === 0 ? 'appGrey.100' : 'white'}
                      key={item.unique_identifier}
                    >
                      <Td>{getHealthSalesActionItems(item)}</Td>
                      <Td>
                        <Text>
                          {item.proposal_date
                            ? format(new Date(item.proposal_date), 'dd/MM/yyyy')
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>{item.proposal_number ? item.proposal_number : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>
                          {item.transaction_date
                            ? format(new Date(item.transaction_date), 'dd/MM/yyyy')
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>{item.sp_name ? item.sp_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.sp_id ? item.sp_id : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.sol_id ? item.sol_id : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.ucic_id ? item.ucic_id : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.business_name ? item.business_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.customer_name ? item.customer_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text> {item.plan_type ? item.plan_type : '-'} </Text>
                      </Td>
                      <Td>
                        <Text>{item.insurer_name ? item.insurer_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.product_name ? item.product_name : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.policy_mode}</Text>
                      </Td>
                      <Td>
                        <Text>{item.uw_status}</Text>
                      </Td>
                      <Td>
                        <Text>{item.payment_status ? item.payment_status : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>
                          {item.payment_date
                            ? format(new Date(item.payment_date), 'dd/MM/yyyy')
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>{item.policy_status ? item.policy_status : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>{item.policy_number ? item.policy_number : '-'}</Text>
                      </Td>
                      <Td>
                        <Text>
                          {item?.policy_issue_date
                            ? format(new Date(item.policy_issue_date), 'dd/MM/yyyy')
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>-</Text>
                      </Td>
                      <Td>
                        <Text>
                          {item.policy_premium
                            ? `₹ ${item.policy_premium.toLocaleString('en-IN')}`
                            : '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text>-</Text>
                      </Td>
                      <Td>
                        <Text>-</Text>
                      </Td>
                    </Tr>
                  )
                })
              : isReportLoading && (
                  <Tr>
                    <Spinner as='td' />
                  </Tr>
                )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Action</Th>
              <Th>Proposal Date</Th>
              <Th>Proposal No.</Th>
              <Th>Transaction Date</Th>
              <Th>SP Name</Th>
              <Th>SP ID</Th>
              <Th>Sol ID</Th>
              <Th>UCIC ID</Th>
              <Th>Business Name</Th>
              <Th>Customer Name</Th>
              <Th>Plan Type</Th>
              <Th>Insurer</Th>
              <Th>Plan Name</Th>
              <Th>Policy mode</Th>
              <Th>U/w Status</Th>
              <Th>Payment Status</Th>
              <Th>Payment Date</Th>
              <Th>Policy Status</Th>
              <Th>Policy Number</Th>
              <Th>Policy Issue Date</Th>
              <Th>Rejection Reason</Th>
              <Th>Proposal Premium</Th>
              <Th>Additional Premium</Th>
              <Th>Premium Collected</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <Flex justifyContent='center'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<ChevronRightIcon transform='scale(2)' />}
          onPageChange={(selectedPage) => {
            setQueryParams((prev) => ({
              ...prev,
              page_number: selectedPage.selected + 1,
            }))
          }}
          pageCount={totalPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          previousLabel={<ChevronLeftIcon transform='scale(2)' />}
          // forcePage={page && page - 1}
          forcePage={queryParams.page_number && queryParams.page_number - 1}
          className='paginate'
          pageClassName='page-item'
          nextLinkClassName='paginate-button'
          previousLinkClassName='paginate-button'
          activeClassName='active-page'
        />
      </Flex>
    </>
  )
}

export { HealthSalesReport }
