import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import { ElevatedButton } from 'Core'
import { AccountDetailsCard } from 'Core/Cards/AccountDetailsCard'
import { setSelectedAccount, setSelectedAccountId } from 'features/Equitas'
import { useNavigate } from 'react-router-dom'
import { getAccountTypeBasedOnIndex } from 'Services/general'
import { getPgAndDirectDebitLogicFromSelectedAccount } from './CbsUtils'

const CustomerLinkedAccountsForm = () => {
  const { customerAccountStatus, accounts, selectedAccountId, customerDetailsStatus, customerAge } =
    useAppSelector((state) => state.customerDetails)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <>
      {customerAccountStatus === 'success' ? (
        <Box>
          <Flex direction='column' paddingX='0.8rem' paddingY='2.5rem' width='fit-content'>
            <Flex wrap='wrap' gap='0.7rem' justifyContent='center'>
              {accounts?.map((account) => {
                return (
                  <AccountDetailsCard
                    key={account.account_id}
                    accountNo={account.show_account_number}
                    customerName={account.account_title}
                    IFSCCode={account?.ifsc_code?.toString()}
                    accountType={getAccountTypeBasedOnIndex(account.show_account_number.charAt(0))}
                    isSelected={account.account_id === selectedAccountId}
                    onClick={() => {
                      console.log('------------selected account------------')
                      console.log(account)

                      getPgAndDirectDebitLogicFromSelectedAccount(account, dispatch)

                      dispatch(setSelectedAccountId(account.account_id))
                      dispatch(setSelectedAccount(account))
                    }}
                  />
                )
              })}
            </Flex>
          </Flex>
          <Flex justifyContent='center' marginY='6' fontSize='md'>
            <Text fontStyle='italic'>
              <Text as='span' fontWeight='bold'>
                Note:
              </Text>{' '}
              This account details will be used for payment purpose.
            </Text>
          </Flex>
          {customerDetailsStatus === 'loading' && (
            <Flex justifyContent='center' alignItems='center' gap='0.5rem' padding='1rem'>
              <Text fontSize='md'>Fetching Customer Details</Text>
              <Spinner />
            </Flex>
          )}
          {customerDetailsStatus === 'error' && (
            <Flex justifyContent='center' padding='1rem'>
              <Text fontSize='md' color='red.500'>
                Unable to fetch customer details
              </Text>
            </Flex>
          )}
          {customerAge < 18 && (
            <Flex marginBottom='3' justifyContent='center'>
              <Text fontSize='md' color='red.500'>
                Proposer age should be greater than 18
              </Text>
            </Flex>
          )}
          <Flex marginBottom='3' justifyContent='center'>
            <ElevatedButton
              isDisabled={
                selectedAccountId === -1 ||
                customerDetailsStatus === 'loading' ||
                customerDetailsStatus === 'not-fetched' ||
                customerAge < 18
              }
              rounded='lg'
              onClick={() => navigate('/')}
            >
              Proceed
            </ElevatedButton>
          </Flex>
        </Box>
      ) : customerAccountStatus === 'error' ? (
        <>
          <Flex h='full' justifyContent='center' alignItems='center'>
            <Text fontSize='xl'>No Accounts Found!</Text>
          </Flex>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export { CustomerLinkedAccountsForm }
