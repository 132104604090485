import {
  Button,
  Center,
  Checkbox,
  Divider,
  Drawer,
  DrawerOverlay,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  PinInput,
  PinInputField,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useDeviceType } from 'app/hooks'
import { BimabossDatePicker, DashboardHeading, ElevatedButton, ErrorText, TextInput } from 'Core'
import { setCustomerAccountsStatus } from 'features/Equitas'
import { PickOne } from 'Models/Common'
import {
  ICustomerConsentGenerationPayload,
  ICustomerDataForConsent,
} from 'Models/RequestModels/Equitas'
import { ICustomerConsentCheckBody } from 'Models/ResponseModels/Equitas'
import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { useSpTaggingSendOtpMutation } from 'Services/API/authentication.api'
import {
  useConsentUploadMutation,
  useLazyCheckForCustomerConsentSubmissionStatusQuery,
  useSubmitCustomerConsentFormMutation,
} from 'Services/API/motor.api'
import { customerConsentFormQuestionsMap } from 'Services/general'
import { ToastService } from 'Services/toast.service'
import { TCustomerConsentForm } from './FormModels'
import { ObjectTyped } from 'object-typed'
import { PremiumBreakup, PremiumBreakupBody } from 'Pages/Motor/Quotation/PremiumBreakup'
import { IPremiumBreakupDetails } from 'Models/RequestModels/Motor'
import { downloadImage, exportAsImage } from 'Pages/Health/Consent/exportAsImage'
import {
  useConsentSendSMSMutation,
  useSendEmailForOTPMutation,
  useVerifyOTPforConsentMutation,
} from 'Services/API/equitas.api'
import axios from 'axios'
import { API_ENDPOINTS, EQUITAS_URL } from 'Constants'

let interval: NodeJS.Timeout
const TIMER_LIMIT = 60
let tempTimeLeft = TIMER_LIMIT

const fieldsMapping: ICustomerDataForConsent = {
  ucic_id: 'UCIC ID',
  ifsc: 'IFSC Code',
  account_type: 'Account Type',
  vehicle_type: 'Vehicle Type',
  reg_no: 'Registration Number',
  make: 'Manufacturer',
  model: 'Model',
  variant: 'Variant',
  seating_capacity: 'Seating Capacity',
  manufacturing_year: 'Manufacturing Year',
  registration_date: 'Registration Date',
  has_previous_policy: 'Do you have previous years policy?',
  existingPolicyExpiryDate: 'Prev Policy Exp Date',
  prev_insurer: 'Previous Insurer',
  prev_policy_type: 'Previous Policy Type',
  ncb: 'Existing No Claim Bonus',
  claims_made_in_existing_policy: 'Claims Made in Existing Policy',
  permit_type: 'Permit Type',
  tp_prev_policy_number: 'TP previous Policy Number',
  tp_prev_insurer_name: 'TP Insurer Name',
  tp_policy_start_date: 'TP Policy Start Date',
  tp_policy_end_date: 'TP Policy End Date',
  package_type: 'Package Type',
  owned_by: 'Vehicle Owned By',
  puc: 'PUC Certificate',
  engine_no: 'Engine Number',
  chassis_no: 'Chassis Number',
  is_loan: 'Is Vehicle On Loan',
  bank_name: 'Bank Name',
  uses_type: 'Uses Type',
  prev_policy_no: 'Previous Policy Number',
  company_name: 'Company Name',
  gst_no: 'GST Number',
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  gender: 'Gender',
  marital_status: 'Marital Status',
  dob: 'DOB',
  occupation: 'Occupation',
  mobile_no: 'Mobile Number',
  email_id: 'Email',
  pincode: 'Pincode',
  city: 'City',
  state: 'State',
  address1: 'Address1',
  address2: 'Address2',
  pancard: 'Pan Card',
  landmark: 'Landmark',
  nominee_first_name: 'Nominee First Name',
  nominee_middle_name: 'Nominee Middle Name',
  nominee_last_name: 'Nominee Last Name',
  nominee_relationship: 'Nominee Relationship',
  nominee_dob: 'Nominee DOB',
  nominee_age: 'Nominee Nominee Age',
}

const CustomerConsentForm = () => {
  const exportRef = useRef<HTMLDivElement>(null)

  const { isDesktop, isTablet } = useDeviceType()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const params = useParams()

  const [reportDownloading, setReportDownloading] = useState(false)
  const [uniqueId, setUniqueId] = useState('')
  const [customerData, setCustomerData] = useState<ICustomerDataForConsent>()
  const [premiumBreakupDetails, setPremiumBreakupDetails] = useState<IPremiumBreakupDetails>()
  const [leaduid, setLeaduid] = useState('')
  const [custPhone, setCustPhone] = useState('')
  const [cisClicked, setCisClicked] = useState(false)

  useEffect(() => {
    const base64Data = Object.values(params)[0]

    const jsonStringData = atob(base64Data ?? '')

    const uniqueIdentifier = JSON.parse(jsonStringData).unique_identifier

    setUniqueId(uniqueIdentifier)
  }, [])

  const { register, handleSubmit, formState, trigger } = useForm<TCustomerConsentForm>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const [submitConsentForm] = useSubmitCustomerConsentFormMutation()

  const [checkForConsentSubmissionStatus] = useLazyCheckForCustomerConsentSubmissionStatusQuery()

  const [consentUpload] = useConsentUploadMutation()

  const [sendSMS, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
    useConsentSendSMSMutation()

  const [verifyOtp, { isLoading: isVerifyOtpLoading }] = useVerifyOTPforConsentMutation()

  const [sendOTPviaEmail] = useSendEmailForOTPMutation()

  const downloadReport = async () => {
    setReportDownloading(true)
    try {
      const resp = await axios({
        baseURL: `${EQUITAS_URL + API_ENDPOINTS.CIS_REPORT}`,
        params: {
          proposal_number: customerData?.proposal_number,
          unique_identifier: uniqueId,
          category: customerData?.vehicle_type === 'Car' ? 4 : 2,
          policy_type:
            customerData?.prev_policy_type === 'Stand Alone OD'
              ? 'OD'
              : premiumBreakupDetails?.quotationDetails.isComprehensive
              ? 'CO'
              : 'TP',
        },
      })
      downloadFileFromURL(resp.data.URL, 'cis_report.pdf')
      setCisClicked(true)
      setReportDownloading(false)
    } catch (e) {
      setReportDownloading(false)
      ToastService.showErrorToast({ title: 'Failure', message: 'Could not download report' })
      console.log(e)
    }
  }

  function downloadFileFromURL(url: string, fileName: string) {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`)
        }
        return response.blob()
      })
      .then((blob) => {
        const a = document.createElement('a')
        const url = URL.createObjectURL(blob)

        a.href = url
        a.download = fileName

        document.body.appendChild(a)
        a.click()

        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.error('Error downloading file:', error)
      })
  }

  const onSubmit: SubmitHandler<TCustomerConsentForm> = async (data) => {
    console.log(data)
    try {
      const resp: any = await submitConsentForm({
        unique_identifier: uniqueId ?? '',
        consent_data: {
          [customerConsentFormQuestionsMap['q1']]: 'true',
          [customerConsentFormQuestionsMap['q2']]: 'true',
          [customerConsentFormQuestionsMap['q3']]: 'true',
          [customerConsentFormQuestionsMap['q4']]: 'true',
          [customerConsentFormQuestionsMap['q5']]: 'true',
          payment_method: 'others',
        },
        customer: customerData,
      })
      const paymentLink = resp.data
      console.log(paymentLink)

      const image = await exportAsImage(exportRef.current, 'test')

      // call consent upload api
      await consentUpload({
        b64_image: image,
        consent_type: 'customer_consent',
        unique_identifier: uniqueId,
        email_id: customerData?.email_id ?? '',
        lead_uid: leaduid,
      })

      if (paymentLink) {
        window.open(paymentLink, '_self')
      } else {
        console.log(resp.error.message)
        throw Error(resp.error.message)
      }
    } catch (e: any) {
      ToastService.showErrorToast({
        title: 'Something went wrong!',
        message: e.message ?? '',
      })
    }
  }

  useEffect(() => {
    if (uniqueId) {
      checkForConsentSubmissionStatus({
        unique_identifier: uniqueId,
      }).then((data) => {
        console.log(data.data, 'data')
        if (data.data?.is_submited === true) {
          console.log(data?.data?.payment_link)
          window.open(data?.data?.payment_link ?? '', '_self')
        } else {
          console.log('data.data?.payload')
          console.log(data.data?.payload)
          console.log('data.data?.payload')
          setCustomerData(data.data?.payload?.customer.customer_data)
          setPremiumBreakupDetails(data.data?.payload?.customer.premium_breakup_details)
          setLeaduid(data.data?.payload?.customer.lead_uid ?? '')
          setCustPhone(data.data?.payload?.customer.phone ?? '')
        }
        // if (data?.data?.is_submited === true) {
        //   window.open(data?.data.payment_link, '_self')
        // }
      })
    }
  }, [uniqueId])

  // const [sendOtp, { isLoading: isSendOtpLoading, isSuccess: isOtpSent }] =
  //   useSpTaggingSendOtpMutation()

  const [otp, setOtp] = useState('')

  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [resendOtp, setResendOtp] = useState(false)

  const [isOtpVerified, setIsOtpVerified] = useState(false)

  const startTimer = () => {
    interval = setInterval(() => {
      tempTimeLeft--
      if (tempTimeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      } else {
        clearInterval(interval)
        setResendOtp(true)

        setTimeLeft(TIMER_LIMIT)
        tempTimeLeft = TIMER_LIMIT
      }
    }, 1000)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div ref={exportRef}>
          <DashboardHeading title='Insurance Application Form' />
          <Grid
            paddingX={isDesktop ? '2rem' : '0'}
            gridTemplateColumns={isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr'}
          >
            {customerData && Object.keys(customerData).length > 0 ? (
              ObjectTyped.keys(fieldsMapping)
                .filter((item) => Boolean(customerData[item]))
                .map((item, index) => {
                  const key = fieldsMapping[item]
                  const value = customerData[item]
                  return (
                    <GridItem key={index} marginY='4' marginX='6'>
                      <FormLabel>{key}</FormLabel>
                      <TextInput isReadOnly defaultValue={value ?? '-'} />
                    </GridItem>
                  )
                })
            ) : (
              <Text></Text>
            )}
          </Grid>
          {premiumBreakupDetails && (
            <>
              <Divider />
              <Center paddingY='0.5rem'>
                <Text fontSize='2xl' fontWeight='bold'>
                  Policy Details
                </Text>
              </Center>
              <Divider />
              <PremiumBreakupBody
                insurer_logo={premiumBreakupDetails?.selectedQuote.insurer_logo}
                idv={premiumBreakupDetails?.selectedQuote.idv}
                ownDamageCovers={premiumBreakupDetails?.ownDamageCovers ?? []}
                liabilityCovers={premiumBreakupDetails?.liabilityCovers ?? []}
                discountCovers={premiumBreakupDetails?.discountCovers ?? []}
                addOns={premiumBreakupDetails?.addOns ?? []}
                premium={premiumBreakupDetails?.selectedQuote.premium_total}
                premiumDict={premiumBreakupDetails?.selectedQuote.premium}
                addOnsDict={premiumBreakupDetails?.selectedQuote.add_ons}
                coversDict={premiumBreakupDetails?.selectedQuote.cover_discount}
                quote={premiumBreakupDetails?.selectedQuote}
                homePageDetails={premiumBreakupDetails?.homePageDetails}
                vehicleDetails={premiumBreakupDetails?.vehicleDetails}
                insuranceDetails={premiumBreakupDetails?.insuranceDetails}
                quotationDetails={premiumBreakupDetails?.quotationDetails}
              />
              <Divider />
            </>
          )}
          {(customerData?.vehicle_type === 'Car' || customerData?.vehicle_type === 'Bike') &&
            premiumBreakupDetails?.selectedQuote.insurance_name.toUpperCase().includes('HDFC') && (
              <Center marginY='4'>
                <Button onClick={downloadReport} isLoading={reportDownloading}>
                  Download Customer Information Sheet (CIS)
                </Button>
              </Center>
            )}
          <Flex gap='1rem' direction='column' marginY='4' marginX='6'>
            <Checkbox {...register('q1', { required: true })} value='true'>
              <Text dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q1 }} />
              {formState.errors.q1 && formState.errors.q1?.type === 'required' && (
                <ErrorText text='This field is required' />
              )}
            </Checkbox>
            <Checkbox {...register('q2', { required: true })} value='true'>
              <Text dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q2 }} />

              {formState.errors.q2 && formState.errors.q2?.type === 'required' && (
                <ErrorText text='This field is required' />
              )}
            </Checkbox>
            <Checkbox {...register('q3', { required: true })} value='true'>
              <Text dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q3 }} />

              {formState.errors.q3 && formState.errors.q3?.type === 'required' && (
                <ErrorText text='This field is required' />
              )}
            </Checkbox>
            <Checkbox {...register('q4', { required: true })} value='true'>
              <Text dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q4 }} />

              {formState.errors.q4 && formState.errors.q4?.type === 'required' && (
                <ErrorText text='This field is required' />
              )}
            </Checkbox>
            <Checkbox {...register('q5', { required: true })} value='true'>
              <Text dangerouslySetInnerHTML={{ __html: customerConsentFormQuestionsMap.q5 }} />

              {formState.errors.q5 && formState.errors.q5?.type === 'required' && (
                <ErrorText text='This field is required' />
              )}
            </Checkbox>
            <Flex direction='column' marginY='4' marginX='6'>
              <FormLabel>Select the Payment Method</FormLabel>
              <RadioGroup value={'others'}>
                <Radio marginX='0.7rem' value='equitas_account'>
                  My Equitas Account
                </Radio>
                <Radio marginX='0.7rem' value='others'>
                  Other payment options
                </Radio>
              </RadioGroup>
            </Flex>
          </Flex>
        </div>
        {!isOtpVerified && (
          <Center flexDirection='column'>
            {!isOtpSent && (
              <ElevatedButton
                onClick={async () => {
                  const isFormValid = await trigger()
                  if (isFormValid && cisClicked) {
                    sendSMS({
                      message_type: 'customer_otp',
                      phone_number: custPhone ? '91' + custPhone.slice(-10) : '',
                      //  phone_number: '919899004640',
                      unique_identifier: uniqueId ?? '',
                      lead_uid: leaduid,
                    })
                      .unwrap()
                      .then((res) => {
                        console.log(res)
                        ToastService.showSuccessTaost({
                          title: 'Success',
                          message: `OTP sent successfully to ${customerData?.mobile_no} and ${customerData?.email_id}`,
                        })
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                    sendOTPviaEmail({
                      message_type: 'customer_otp',
                      reciever_mail: customerData?.email_id,
                      unique_identifier: uniqueId ?? '',
                      lead_uid: leaduid,
                    })
                    startTimer()
                  } else if (!cisClicked) {
                    ToastService.showInfoToast({
                      title:
                        'Please download the customer information sheet to proceed with OTP verification',
                    })
                  }
                }}
                isLoading={isSendOtpLoading}
                isDisabled={isSendOtpLoading}
                marginY='1rem'
              >
                Send OTP
              </ElevatedButton>
            )}
            {isOtpSent && (
              <Flex paddingY='1rem' direction='column' alignItems='center' marginY='4' marginX='6'>
                <FormLabel>Enter OTP</FormLabel>
                <HStack>
                  <PinInput
                    onChange={(str) => {
                      setOtp(str)
                    }}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </Flex>
            )}
            {isOtpSent && (
              <Flex marginY='0.7rem'>
                <Button
                  isDisabled={otp.length !== 6}
                  isLoading={isVerifyOtpLoading}
                  onClick={() => {
                    try {
                      verifyOtp({
                        phone_no: custPhone ? '91' + custPhone.slice(-10) : '',
                        //  phone_no:919899004640,
                        otp: otp,
                        unique_identifier: uniqueId,
                      })
                        .unwrap()
                        .then((res) => {
                          console.log(res)
                          setIsOtpVerified(true)
                          ToastService.showSuccessTaost({
                            title: 'OTP Verified successfully',
                          })
                          setIsOtpVerified(true)
                        })
                        .catch((e) =>
                          ToastService.showErrorToast({
                            title: 'OTP Verification Failed',
                          }),
                        )
                    } catch (e) {
                      console.log(e)
                      ToastService.showErrorToast({
                        title: 'OTP Verification Failed',
                      })
                    }
                  }}
                  marginX='0.5rem'
                >
                  Verify OTP
                </Button>
                <Button
                  disabled={!resendOtp}
                  onClick={async () => {
                    sendSMS({
                      message_type: 'otp',
                      phone_number: custPhone ? '91' + custPhone.slice(-10) : '',
                      //  phone_number: '919899004640',
                      unique_identifier: uniqueId ?? '',
                      lead_uid: leaduid,
                    })
                      .unwrap()
                      .then((res) => {
                        console.log(res)
                        ToastService.showSuccessTaost({
                          title: 'Success',
                          message: `OTP sent successfully to ${customerData?.mobile_no} and ${customerData?.email_id}`,
                        })
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                    sendOTPviaEmail({
                      message_type: 'customer_otp',
                      reciever_mail: customerData?.email_id,
                      unique_identifier: uniqueId ?? '',
                      lead_uid: leaduid,
                    })
                    setResendOtp(false)
                    startTimer()
                  }}
                  marginX='0.5rem'
                >
                  {resendOtp ? 'Resend OTP' : `Resend OTP in ${timeLeft}`}
                </Button>
              </Flex>
            )}
          </Center>
        )}
        {isOtpVerified && (
          <Center padding='1.5rem 0.5rem '>
            <ElevatedButton type='submit'>Submit</ElevatedButton>
          </Center>
        )}
      </form>
    </>
  )
}

export { CustomerConsentForm }
