import { createApi } from '@reduxjs/toolkit/query/react'
import { store } from 'app/store'
import { API_ENDPOINTS } from 'Constants'
import { ISaveLead, ILeadReassign } from 'Models/RequestModels/Leads'
import { ISaveLeadResponse } from 'Models/ResponseModels'
import { leadsUrlQuery } from 'Services/baseQuery'
import { encryptData } from 'Services/general'

export const leadsApi = createApi({
  //   reducerPath: 'leadsApi',
  baseQuery: leadsUrlQuery,
  endpoints: (builder) => ({
    saveLeads: builder.mutation<ISaveLeadResponse, ISaveLead>({
      query: (payload) => ({
        url: API_ENDPOINTS.SAVE_LEAD,
        method: 'POST',
        body: { data: encryptData(JSON.stringify(payload)) },
      }),
    }),
    leadsListing: builder.query<any, any>({
      query: (param) => ({
        url: API_ENDPOINTS.LEAD_LISTING,
        method: 'GET',
        params: param,
      }),
    }),
    closeLead: builder.mutation<any, { lead_close_reason: string; lead_uid: string }>({
      query: (param: { lead_close_reason: string; lead_uid: string }) => ({
        url: API_ENDPOINTS.CLOSE_LEAD,
        method: 'POST',
        body: param,
      }),
    }),
    reassignLead: builder.mutation<any, ILeadReassign>({
      query: (payload: ILeadReassign) => ({
        url: API_ENDPOINTS.LEAD_REASSIGN,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
})

export const {
  useSaveLeadsMutation,
  useLazyLeadsListingQuery,
  useCloseLeadMutation,
  useReassignLeadMutation,
} = leadsApi
