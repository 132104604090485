import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
  Button,
  FormControl,
  Flex,
} from '@chakra-ui/react'
import { useVehicleType } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { updatePaOwnerReason } from 'features/Motor'
const CPADialog = ({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const navigate = useNavigate()
  const { name: vehicleName } = useVehicleType()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <FormControl>
          <ModalHeader>
            {
              'As per the insurer "PA to owner driver" cover is mandatory for the selected RTO. If not opted, owner confirms that "Owner has no valid driving license or Having CPA in another policy". Click "Yes" to proceed, "No" otherwise.'
            }
          </ModalHeader>
          <ModalBody>
            <Flex direction='row' justifyContent='center'>
              <Button
                colorScheme='primary'
                marginRight='2'
                onClick={() => {
                  navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                  onClose()
                }}
              >
                Yes
              </Button>
              <Button
                colorScheme='primary'
                onClick={() => {
                  // navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                  onClose()
                }}
              >
                No
              </Button>
            </Flex>
            {/* <RadioGroup onChange={(val) => updatePaOwnerReason({ paOwnerReason: val })}>
              <Stack>
                <Radio value='nvdl' size='sm'>
                  You do not have a valid driving license OR
                </Radio>
                <Radio value='apa' size='sm'>
                  You have alternate personal accident policy having cover for ₹ 15,00,000
                </Radio>
              </Stack>
            </RadioGroup> */}
          </ModalBody>
          {/* <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme='primary'
              onClick={() => {
                navigate(`/motor/${vehicleName.toLowerCase()}/proposal`)
                onClose()
              }}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter> */}
        </FormControl>
      </ModalContent>
    </Modal>
  )
}

export { CPADialog }
