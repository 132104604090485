import {
  Button,
  Center,
  Flex,
  Image,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import successImg from 'Assets/Common/Success.webp'
import React from 'react'
import { NotAllowedIcon } from '@chakra-ui/icons'

const JourneyBlockDialog = (props: { children?: React.ReactNode }) => {
  const navigate = useNavigate()

  return (
    <Flex paddingY='1rem' paddingX='1.5rem' gap='1rem' direction='column' alignItems='center'>
      <ModalContent>
        <ModalHeader>Details not found!</ModalHeader>
        <ModalCloseButton />
        <Flex justifyContent='center' marginY='1rem'>
          <NotAllowedIcon boxSize={'28'} color='red.500' />
        </Flex>
        {props.children}
        <ModalFooter justifyContent='center'>
          {/* <Button
            onClick={() => {
              navigate('/customer-account')
            }}
            marginX='0.5rem'
          >
            Back Home
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Flex>
  )
}

export { JourneyBlockDialog }
