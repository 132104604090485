import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector, useDeviceType, useVehicleType } from 'app/hooks'
import { IAdditionalCover, ITransformedAddons, Quotations } from 'Models/ResponseModels'
import { useState } from 'react'
import { updateSelectedQuote, updateInspectionRequired } from 'features/Motor'
import { getFallbackImageHtml } from 'Services/general'
import { IHomePageState } from 'features/Home/homePage.slice'
import {
  IVehicleDetails,
  IVehicleInsuranceDetails,
} from 'features/Motor/vehicleDetails.motor.slice'
import { IQuotationPageState } from 'features/Motor/quotationPage.motor.slice'

interface IPremiumBreakupProps {
  quote: Quotations
  addOnsDict: any
  coversDict: any
  premiumDict: any
  premium: number
  addOns: ITransformedAddons[]
  liabilityCovers: IAdditionalCover[]
  discountCovers: IAdditionalCover[]
  insurer_logo: string
  idv: number
  ownDamageCovers: IAdditionalCover[]
  buyPolicy?: any
  homePageDetails?: IHomePageState
  vehicleDetails?: IVehicleDetails
  insuranceDetails?: IVehicleInsuranceDetails
  quotationDetails?: IQuotationPageState
}

const PremiumBreakupHeader = ({
  insurer_logo,
  quote,
}: {
  quote: Quotations
  insurer_logo: string
}) => {
  const { isDesktop, isTablet, isMobile } = useDeviceType()

  return (
    <Flex alignItems='center'>
      <Image
        height='100px'
        width='180px'
        src={insurer_logo}
        border='1px solid #F1F3F4'
        display={!isDesktop ? 'block' : 'none'}
        marginRight='4'
        onError={({ currentTarget }) => {
          currentTarget.outerHTML = getFallbackImageHtml(quote.insurance_name)
        }}
      />
      <Text>Premium Breakup</Text>
    </Flex>
  )
}

export const PremiumBreakupBody = ({
  insurer_logo,
  idv,
  ownDamageCovers,
  liabilityCovers,
  discountCovers,
  addOns,
  premium,
  premiumDict,
  addOnsDict,
  coversDict,
  quote,
  buyPolicy,
  homePageDetails,
  vehicleDetails,
  insuranceDetails,
  quotationDetails,
}: IPremiumBreakupProps) => {
  const { isDesktop, isTablet, isMobile } = useDeviceType()

  const dispatch = useAppDispatch()

  return (
    <Flex direction='column' paddingY='2'>
      <Flex bgColor='#F6F6F6' alignItems='center' justifyContent='space-evenly'>
        <Flex flexBasis={isDesktop ? '28%' : '50%'} justifyContent='space-around'>
          <Image
            src={insurer_logo}
            height='auto'
            width='150px'
            bgColor='white'
            display={isDesktop ? 'block' : 'none'}
            border='1px solid #F1F3F4'
            onError={({ currentTarget }) => {
              currentTarget.outerHTML = getFallbackImageHtml(quote.insurance_name)
            }}
          />
          <Flex direction='column' justifyContent='center'>
            <Text fontWeight='semibold'>
              {vehicleDetails?.selectedManufacturer?.name.toUpperCase()}{' '}
              {vehicleDetails?.selectedModel?.name.toUpperCase()}
            </Text>
            <Text>IDV: ₹{idv.toLocaleString('en-IN')}</Text>
          </Flex>
        </Flex>
        <Divider orientation='vertical' color='black' height='100' />
        <Flex
          direction='column'
          flexBasis={isDesktop ? '28%' : '50%'}
          alignItems={isDesktop ? 'start' : 'center'}
        >
          <Text>Mfg. Year: {vehicleDetails?.manufacturingYear}</Text>
          <Text>RTO: {homePageDetails?.vehicleNumber.substring(0, 5).replaceAll('-', '')}</Text>
          <Text>Variant: {vehicleDetails?.selectedVariant?.name}</Text>
          <Text>Existing Policy Expiry Date: {insuranceDetails?.existingPolicyExpiryDate}</Text>
        </Flex>
        <Divider
          orientation='vertical'
          color='black'
          height='100'
          display={isDesktop ? 'flex' : 'none'}
        />
        <Flex direction='column' flexBasis='25%' display={isDesktop ? 'flex' : 'none'}>
          <Flex justifyContent='space-between'>
            <Text>Net Premium (A+B+C-D)</Text>
            <Text>₹ {Math.round(premiumDict['value']).toLocaleString('en-IN')}</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text>GST(18%)</Text>
            <Text>₹ {Math.round(premiumDict['gst_val']).toLocaleString('en-IN')}</Text>
          </Flex>
          <Flex fontWeight='bold' justifyContent='space-between' fontSize='24px'>
            <Text>Final Premium</Text>
            <Text>₹ {Math.round(premium).toLocaleString('en-IN')}</Text>
          </Flex>
        </Flex>
        {buyPolicy && (
          <Button
            // colorScheme='blue'
            variant='solid'
            flexBasis='10%'
            borderRadius='20px'
            disabled={!quotationDetails?.pucCertificate}
            // bgColor='#1C4589'
            display={isDesktop ? 'block' : 'none'}
            onClick={() => {
              dispatch(updateSelectedQuote({ selectedQuote: quote }))
              // navigate(`/motor/${vehicleType.toLowerCase()}/proposal`)
              buyPolicy(quote)
            }}
          >
            BUY
          </Button>
        )}
      </Flex>
      <Flex
        padding='4'
        direction='column'
        marginTop='4'
        display={isDesktop ? 'none' : 'flex'}
        bgColor='#F6F6F6'
      >
        <Flex justifyContent='space-between'>
          <Text>Net Premium (A+B+C-D)</Text>
          <Text>₹ {Math.round(premiumDict['value']).toLocaleString('en-IN')}</Text>
        </Flex>
        <Flex justifyContent='space-between'>
          <Text>GST(18%)</Text>
          <Text>₹ {Math.round(premiumDict['gst_val']).toLocaleString('en-IN')}</Text>
        </Flex>
        <Flex fontWeight='bold' justifyContent='space-between' fontSize='24px'>
          <Text>Final Premium</Text>
          <Text>₹ {Math.round(premium).toLocaleString('en-IN')}</Text>
        </Flex>
      </Flex>
      <Accordion display={isDesktop ? 'none' : 'block'} allowToggle marginTop='4'>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Flex justifyContent='space-between' width='100%' alignItems='center'>
                <Flex direction='column' alignItems='start'>
                  <Text fontWeight='semibold' fontSize='18'>
                    A. Own Damage
                  </Text>
                  <Text>₹ {Math.round(premiumDict['od_val']).toLocaleString('en-IN')}</Text>
                </Flex>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Box overflowY='scroll'>
              <Flex padding='1' justifyContent='space-between' bgColor='#F6F6F6'>
                <Text>Basic Own Damage</Text>
                <Text>
                  {quote.premium.od == -2
                    ? 'included'
                    : '₹ ' + Math.round(quote.premium.od).toLocaleString('en-IN')}
                </Text>
              </Flex>
              {ownDamageCovers.map((covers, index) => (
                <Flex
                  padding='1'
                  key={covers.code}
                  justifyContent='space-between'
                  bgColor={index % 2 != 0 ? '#F6F6F6' : 'white'}
                >
                  <Text>{covers.name}</Text>
                  {Math.round(coversDict[covers.code]) === -1 ||
                  !Math.round(coversDict[covers.code]) ? (
                    <Text>NA</Text>
                  ) : Math.round(coversDict[covers.code]) === -2 ? (
                    <Text>included</Text>
                  ) : (
                    <Text>₹ {Math.round(coversDict[covers.code]).toLocaleString('en-IN')}</Text>
                  )}
                </Flex>
              ))}
            </Box>
            <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
              <Text>Total OD Premium (A)</Text>
              <Text>₹ {Math.round(premiumDict['od_val']).toLocaleString('en-IN')}</Text>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Flex justifyContent='space-between' width='100%' alignItems='center'>
                <Flex direction='column' alignItems='start'>
                  <Text fontWeight='semibold' fontSize='18'>
                    B. Liability
                  </Text>
                  <Text>₹ {Math.round(premiumDict['tp_val']).toLocaleString('en-IN')}</Text>
                </Flex>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Box overflowY='scroll'>
              <Flex padding='1' justifyContent='space-between' bgColor='#F6F6F6'>
                <Text>Third Party Liability</Text>
                <Text>
                  {quote.premium.tp == -2
                    ? 'included'
                    : '₹ ' + Math.round(quote.premium.tp).toLocaleString('en-IN')}
                </Text>
              </Flex>
              {liabilityCovers.map((covers, index) => (
                <Flex
                  padding='1'
                  key={covers.code}
                  justifyContent='space-between'
                  bgColor={index % 2 != 0 ? '#F6F6F6' : 'white'}
                >
                  <Text>{covers.name}</Text>
                  {Math.round(coversDict[covers.code]) === -1 ||
                  !Math.round(coversDict[covers.code]) ? (
                    <Text>NA</Text>
                  ) : Math.round(coversDict[covers.code]) === -2 ? (
                    <Text>included</Text>
                  ) : (
                    <Text>₹ {Math.round(coversDict[covers.code]).toLocaleString('en-IN')}</Text>
                  )}
                </Flex>
              ))}
            </Box>
            <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
              <Text>Total LB Premium (B)</Text>
              <Text>₹ {Math.round(premiumDict['tp_val']).toLocaleString('en-IN')}</Text>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Flex justifyContent='space-between' width='100%' alignItems='center'>
                <Flex direction='column' alignItems='start'>
                  <Text fontWeight='semibold' fontSize='18'>
                    C. Add Ons
                  </Text>
                  <Text>₹ {Math.round(premiumDict['add_on_val']).toLocaleString('en-IN')}</Text>
                </Flex>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Box maxHeight='120px' overflowY='scroll'>
              {!addOnsDict || Object.keys(addOnsDict).length === 0 ? (
                <Text padding='1' bgColor='#F6F6F6'>
                  No Add-ons Selected
                </Text>
              ) : (
                addOns.map((addon, index) =>
                  Math.round(addOnsDict[addon.code!]) ? (
                    <Flex
                      padding='1'
                      key={addon.code}
                      justifyContent='space-between'
                      alignItems='center'
                      bgColor={index % 2 == 0 ? '#F6F6F6' : 'white'}
                    >
                      <Text>{addon.name}</Text>
                      {Math.round(addOnsDict[addon.code!]) === -1 ? (
                        <Text>NA</Text>
                      ) : Math.round(addOnsDict[addon.code!]) === -2 ? (
                        <Text>included</Text>
                      ) : (
                        <Text>₹ {Math.round(addOnsDict[addon.code!]).toLocaleString('en-IN')}</Text>
                      )}
                    </Flex>
                  ) : null,
                )
              )}
            </Box>
            <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
              <Text>Total Add-Ons Premium (C)</Text>
              <Text>₹ {Math.round(premiumDict['add_on_val']).toLocaleString('en-IN')}</Text>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Flex justifyContent='space-between' width='100%' alignItems='center'>
                <Flex direction='column' alignItems='start'>
                  <Text fontWeight='semibold' fontSize='18'>
                    D. Discount
                  </Text>
                  <Text>₹ {Math.round(premiumDict['discount_val']).toLocaleString('en-IN')}</Text>
                </Flex>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Box overflowY='scroll'>
              {discountCovers.map((covers, index) => (
                <Flex
                  padding='1'
                  key={covers.code}
                  justifyContent='space-between'
                  bgColor={index % 2 == 0 ? '#F6F6F6' : 'white'}
                >
                  <Text>{covers.name}</Text>
                  {Math.round(coversDict[covers.code]) === -1 ||
                  !Math.round(coversDict[covers.code]) ? (
                    <Text>NA</Text>
                  ) : Math.round(coversDict[covers.code]) === -2 ? (
                    <Text>included</Text>
                  ) : (
                    <Text>₹ {Math.round(coversDict[covers.code]).toLocaleString('en-IN')}</Text>
                  )}
                </Flex>
              ))}
            </Box>
            <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
              <Text>Total LB Premium (B)</Text>
              <Text>₹ {Math.round(premiumDict['tp_val']).toLocaleString('en-IN')}</Text>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Flex justifyContent='space-between' padding='6' display={isDesktop ? 'flex' : 'none'}>
        <Box flexBasis='22%'>
          <Text fontWeight='semibold' fontSize='18'>
            A. Own Damage
          </Text>
          <Box height='200px' overflowY='scroll' fontSize='md'>
            <Flex padding='1' justifyContent='space-between' bgColor='#F6F6F6'>
              <Text>Basic Own Damage</Text>
              <Text>
                {quote.premium.od == -2
                  ? 'included'
                  : '₹ ' + Math.round(quote.premium.od).toLocaleString('en-IN')}
              </Text>
            </Flex>
            {ownDamageCovers.map((covers, index) => (
              <Flex
                padding='1'
                key={covers.code}
                justifyContent='space-between'
                bgColor={index % 2 != 0 ? '#F6F6F6' : 'white'}
              >
                <Text>{covers.name}</Text>
                {Math.round(coversDict[covers.code]) === -1 ||
                !Math.round(coversDict[covers.code]) ? (
                  <Text>NA</Text>
                ) : Math.round(coversDict[covers.code]) === -2 ? (
                  <Text>included</Text>
                ) : (
                  <Text>₹ {Math.round(coversDict[covers.code]).toLocaleString('en-IN')}</Text>
                )}
              </Flex>
            ))}
          </Box>
          <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
            <Text>Total OD Premium (A)</Text>
            <Text>₹ {Math.round(premiumDict['od_val']).toLocaleString('en-IN')}</Text>
          </Flex>
        </Box>
        <Box flexBasis='22%'>
          <Text fontWeight='semibold' fontSize='18'>
            B. Liability
          </Text>
          <Box height='200px' overflowY='scroll' fontSize='md'>
            <Flex padding='1' justifyContent='space-between' bgColor='#F6F6F6'>
              <Text>Third Party Liability</Text>
              <Text>
                {quote.premium.tp == -2
                  ? 'included'
                  : '₹ ' + Math.round(quote.premium.tp).toLocaleString('en-IN')}
              </Text>
            </Flex>
            {liabilityCovers.map((covers, index) => (
              <Flex
                padding='1'
                key={covers.code}
                justifyContent='space-between'
                bgColor={index % 2 != 0 ? '#F6F6F6' : 'white'}
              >
                <Text>{covers.name}</Text>
                {Math.round(coversDict[covers.code]) === -1 ||
                !Math.round(coversDict[covers.code]) ? (
                  <Text>NA</Text>
                ) : Math.round(coversDict[covers.code]) === -2 ? (
                  <Text>included</Text>
                ) : (
                  <Text>₹ {Math.round(coversDict[covers.code]).toLocaleString('en-IN')}</Text>
                )}
              </Flex>
            ))}
          </Box>
          <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
            <Text>Total LB Premium (B)</Text>
            <Text>₹ {Math.round(premiumDict['tp_val']).toLocaleString('en-IN')}</Text>
          </Flex>
        </Box>
        <Box flexBasis='22%'>
          <Text fontWeight='semibold' fontSize='18'>
            C. Add Ons
          </Text>
          <Box height='200px' overflowY='scroll' fontSize='md'>
            {!addOnsDict || Object.keys(addOnsDict).length === 0 ? (
              <Text padding='1' bgColor='#F6F6F6'>
                No Add-ons Selected
              </Text>
            ) : (
              addOns.map((addon, index) =>
                Math.round(addOnsDict[addon.code!]) ? (
                  <Flex
                    padding='1'
                    key={addon.code}
                    justifyContent='space-between'
                    alignItems='center'
                    bgColor={index % 2 != 0 ? '#F6F6F6' : 'white'}
                  >
                    <Text>{addon.name}</Text>
                    {Math.round(addOnsDict[addon.code!]) === -1 ? (
                      <Text>NA</Text>
                    ) : Math.round(addOnsDict[addon.code!]) === -2 ? (
                      <Text>included</Text>
                    ) : (
                      <Text>₹ {Math.round(addOnsDict[addon.code!]).toLocaleString('en-IN')}</Text>
                    )}
                  </Flex>
                ) : null,
              )
            )}
          </Box>
          <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
            <Text>Total Add-Ons Premium (C)</Text>
            <Text>₹ {Math.round(premiumDict['add_on_val']).toLocaleString('en-IN')}</Text>
          </Flex>
        </Box>
        <Box flexBasis='22%'>
          <Text fontWeight='semibold' fontSize='18'>
            D. Discount
          </Text>
          <Box height='200px' overflowY='scroll' fontSize='md'>
            {discountCovers.map((covers, index) => (
              <Flex
                padding='1'
                key={covers.code}
                justifyContent='space-between'
                bgColor={index % 2 == 0 ? '#F6F6F6' : 'white'}
              >
                <Text>{covers.name}</Text>
                {Math.round(coversDict[covers.code]) === -1 ||
                !Math.round(coversDict[covers.code]) ? (
                  <Text>NA</Text>
                ) : Math.round(coversDict[covers.code]) === -2 ? (
                  <Text>included</Text>
                ) : (
                  <Text>₹ {Math.round(coversDict[covers.code]).toLocaleString('en-IN')}</Text>
                )}
              </Flex>
            ))}
          </Box>
          <Flex justifyContent='space-between' padding='1' fontWeight='semibold'>
            <Text>Total Discount (D)</Text>
            <Text>₹ {Math.round(premiumDict['discount_val']).toLocaleString('en-IN')}</Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

const PremiumBreakup = (props: IPremiumBreakupProps) => {
  const dispatch = useAppDispatch()
  const { isDesktop, isTablet, isMobile } = useDeviceType()
  const { name: vehicleType } = useVehicleType()
  const [imageLoadFail, setImageLoadFail] = useState(false)
  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>
        <PremiumBreakupHeader insurer_logo={props.insurer_logo} quote={props.quote} />
      </DrawerHeader>
      <DrawerBody padding='0'>
        <PremiumBreakupBody {...props} />
      </DrawerBody>
      <DrawerFooter display={isDesktop ? 'none' : 'block'}>
        <Center>
          <Button
            // colorScheme='blue'
            variant='solid'
            padding='4'
            disabled={!props.quotationDetails?.pucCertificate}
            // display={isDesktop ? 'block' : 'none'}
            onClick={() => {
              dispatch(updateSelectedQuote({ selectedQuote: props.quote }))
              // navigate(`/motor/${vehicleType.toLowerCase()}/proposal`)
              props.buyPolicy(props.quote)
            }}
          >
            BUY
          </Button>
        </Center>
      </DrawerFooter>
    </DrawerContent>
  )
}

export { PremiumBreakup }
